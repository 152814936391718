import React from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import CancellationFee from "../components/CancellationFee";
import PartnerLevel from "../components/PartnerLevel";
import PointSetting from "../components/PointSetting";
import MitraFee from "../components/MitraFee";
import ResultUploadTimeLimit from "../components/ResultUploadTimeLimit";
import CustomerCancellationFee from "../components/CustomerCancellationFee";
import MitraPointPage from "../../mitraPoint/containers/MitraPointPage";

const ProjectSettingPage = () => {
   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <h4 className="card-label mb-4">Pengaturan Event</h4>
               <Tabs defaultActiveKey="fee" id="uncontrolled-tab-example">
                  <Tab eventKey="fee" title="Biaya Pembatalan Event" className="p-4">
                     <h6 className="mb-4">Biaya Pembatalan Event</h6>
                     <CancellationFee />
                  </Tab>
                  <Tab eventKey="mitraFee" title="Biaya Persentase" className="p-4">
                     <h6 className="mb-4">Biaya Persentase</h6>
                     <MitraFee />
                  </Tab>
                  <Tab eventKey="point" title="Pengaturan Poin Event" className="p-4">
                     <h6 className="mb-4">Pengaturan Poin Event</h6>
                     <MitraPointPage />
                  </Tab>
                  <Tab eventKey="partnerLevel" title="Partner Level" className="p-4">
                     <h6 className="mb-4">Partner Level</h6>
                     <PartnerLevel />
                  </Tab>
                  <Tab eventKey="resultUploadTimeLimit" title="Batas Waktu Upload Hasil" className="p-4">
                     <h6 className="mb-4">Batas Waktu Upload Hasil</h6>
                     <ResultUploadTimeLimit />
                  </Tab>
                  <Tab eventKey="customerFee" title="Biaya Pembatalan Konsumen" className="p-4">
                     <h6 className="mb-4">Biaya Pembatalan Konsumen</h6>
                     <CustomerCancellationFee />
                  </Tab>
               </Tabs>
            </Col>
         </Row>
      </Container>
   );
};

export default ProjectSettingPage;
