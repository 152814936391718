import React from "react";
import {Container, Row, Col, Tabs, Tab} from "react-bootstrap";
import MitraProductTable from "../components/MitraProductTable";
import MitraPackageTable from "../components/MitraPackageTable";

const DeleteMitraServicePage = () => {
  return (
      <Container className="content-body" fluid>
          <Row>
              <Col md={12}>
                  <h4 className="card-label mb-4">Layanan Mitra</h4>
                  <Tabs defaultActiveKey="fee" id="uncontrolled-tab-example">
                      <Tab eventKey="fee" title="Layanan Utama" className="p-4">
                          <MitraPackageTable />
                      </Tab>
                      <Tab eventKey="mitraFee" title="Layanan Tambahan" className="p-4">
                          <MitraProductTable />
                      </Tab>
                  </Tabs>
              </Col>
          </Row>
      </Container>
  );
};

export default DeleteMitraServicePage;
