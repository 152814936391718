import React, { ChangeEvent, SetStateAction, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ImageUpload } from "../../../../@core/models/media/ImageUpload";
import FormFile from "../../../../components/FormField/FormFile";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import NumberFormat from "react-number-format";
import {
   defaultFeeTypeValue,
   feeTypeOption,
   fillPaymentMethodOption,
   midtransPaymentCategoryOption,
   paymentGatewayOption,
   xenditPaymentCategoryOption,
} from "../constants";
import {
   PaymentGatewayEnum,
   PaymentMethodFormModel,
   PaymentMethodModel,
} from "../models";
import { checkObjectIsNotEmpty } from "../../../../helpers/checkEmptyObject";

type OptionModel = {
   label: string;
   value: number;
};

const PaymentMethodForm = ({
   isEdit = false,
   data = {} as PaymentMethodModel,
   ...props
}: PaymentMethodFormProps) => {
   const history = useHistory();
   const editorRef = useRef();

   const [paymentGateway, setPaymentGateway] = useState<OptionModel | null>({
      label: "Midtrans",
      value: 0,
   } as OptionModel);
   const [paymentCategory, setPaymentCategory] = useState<OptionModel | null>({
      label: "Bank Transfer",
      value: 0,
   } as OptionModel);
   const [paymentMethod, setPaymentMethod] = useState<OptionModel | null>({
      label: "BRI",
      value: 0,
   } as OptionModel);

   const [description, setDescription] = useState<string>("");
   const [validated, setValidated] = useState<boolean>(false);
   const [image, setImage] = useState<ImageUpload>({} as ImageUpload);
   const [errorImage, setErrorImage] = useState<boolean>(false);
   const [selectedType, setSelectedType] = useState<number>(0);
   const [adminFee, setAdminFee] = useState<number>(5000);
   const [feePercentage, setFeePercentage] = useState<number>(0);
   const [isActive, setIsActive] = useState<boolean>(false);
   const [editorLoaded, setEditorLoaded] = useState<boolean>(false);

   const onPaymentGatewayChangeHandler = (data: OptionModel | null) => {
      setPaymentGateway(data);
   };

   const onPaymentCategoryChangeHandler = (data: OptionModel | null) => {
      setPaymentCategory(data);
      setPaymentMethod({} as OptionModel);
   };

   const onPaymentMethodChangeHandler = (data: OptionModel | null) => {
      setPaymentMethod(data);
   };

   // const onDescriptionChangeHandler = (
   //    event: any,
   //    editor: { getData: () => SetStateAction<string> }
   // ) => {
   //    setDescription(editor.getData());
   // };

   const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(e.target.value);
   };

   const onTypeChangeHandler = (e: any) => {
         console.log(e);
         setSelectedType(e.value);
   }

   const onIsActiveChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
      e.target.checked ? setIsActive(true) : setIsActive(false);
   };

   const onCancelHandler = () => {
      history.push("/master/payment-method/list");
   };

   const handleFileUpload = (name: string, fileFormat: string, fileValue: string) => {
      setImage({
         name,
         fileFormat,
         fileValue,
         fromApi: false,
      });
   };

   useEffect(() => {
      if (checkObjectIsNotEmpty(data)) {
         setIsActive(data.isActive);
         setImage({
            name: "",
            fileFormat: "",
            fileValue: data.media,
            fromApi: true,
         });
         setDescription(data.description);
         console.log(data.paymentGatewayName);
         setAdminFee(data.adminFee);
         setFeePercentage(data.feePercentage);
         setPaymentGateway({ label: data.paymentGatewayName, value: data.paymentGateway });
         setPaymentCategory({ label: data.paymentCategoryName, value: data.paymentCategory });
         setPaymentMethod({ label: data.paymentMethodName, value: data.paymentMethod });

      }
   }, [data]);

   useEffect(() => {
      editorRef.current = {
         // CKEditor: require('@ckeditor/ckeditor5-react'), // depricated in v3
         CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
         Editor: require("ckeditor5-custom-build/build/ckeditor"),
      } as any;
      setEditorLoaded(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const { CKEditor, Editor } = editorRef.current || ({} as any);

   const toolbarConfig = {
      toolbar: [
         "heading",
         "|",
         "bold",
         "italic",
         "blockQuote",
         "link",
         "numberedList",
         "bulletedList",
         "imageUpload",
         "insertTable",
         "tableColumn",
         "tableRow",
         "mergeTableCells",
         "mediaEmbed",
         "|",
         "undo",
         "redo",
      ],
   };

   return (
      <Form
         noValidate
         validated={validated}
         onSubmit={(e) => {
            const form = e.currentTarget;
            if (!form.checkValidity()) {
               e.preventDefault();
               e.stopPropagation();
            } else {
               e.preventDefault();
               e.stopPropagation();
               if (image.fileValue === undefined) {
                  alert("image can't be empty");
               } else {
                  props.onSubmit({
                     id: data.id,
                     paymentGateway: paymentGateway?.value,
                     paymentCategory: paymentCategory?.value,
                     adminFee: adminFee,
                     feePercentage: feePercentage,
                     paymentMethod: paymentMethod?.value,
                     media: data.media,
                     description: description,
                     isActive: isActive,
                     image,
                  } as PaymentMethodFormModel);
                  setDescription("");

               }
            }
            setValidated(true);
         }}>
         <Form.Group>
            <Form.Check
               type="switch"
               inline
               className="mr-5"
               id="isActive-switch"
               label="Aktifkan"
               checked={isActive}
               onChange={onIsActiveChangeHandler}
            />
         </Form.Group>

         <Form.Group className="mb-4">
            <Form.Label>Payment Gateway</Form.Label>
            <Select
               value={paymentGateway}
               className="basic-single"
               classNamePrefix="select"
               placeholder="Ketik atau pilih payment gateway"
               isSearchable={true}
               name="type"
               options={paymentGatewayOption}
               onChange={onPaymentGatewayChangeHandler}
               isDisabled={isEdit}
            />
         </Form.Group>

         <Form.Group className="mb-4">
            <Form.Label>Kategori Pembayaran</Form.Label>
            <Select
               value={paymentCategory}
               className="basic-single"
               classNamePrefix="select"
               placeholder="Ketik atau pilih payment category"
               isSearchable={true}
               name="type"
               options={
                  paymentGateway?.value === PaymentGatewayEnum.Midtrans
                     ? midtransPaymentCategoryOption
                     : paymentGateway?.value === PaymentGatewayEnum.Xendit
                     ? xenditPaymentCategoryOption
                     : undefined
               }
               onChange={onPaymentCategoryChangeHandler}
               isDisabled={isEdit}
            />
         </Form.Group>

         <Form.Group className="mb-4">
            <Form.Label>Metode Pembayaran</Form.Label>
            <Select
               value={paymentMethod}
               className="basic-single"
               classNamePrefix="select"
               placeholder="Ketik atau pilih payment method"
               isSearchable={true}
               name="type"
               options={fillPaymentMethodOption(paymentGateway?.value, paymentCategory?.value)}
               onChange={onPaymentMethodChangeHandler}
               isDisabled={isEdit}
            />
         </Form.Group>
         {/*<Form.Group className="mb-4">*/}
         {/*   <Form.Label>Deskripsi</Form.Label>*/}
         {/*   {editorLoaded ? (*/}
         {/*      <CKEditor*/}
         {/*         editor={Editor}*/}
         {/*         config={toolbarConfig}*/}
         {/*         data={description}*/}
         {/*         onChange={onDescriptionChangeHandler}*/}
         {/*      />*/}
         {/*   ) : (*/}
         {/*      <div>Editor loading</div>*/}
         {/*   )}*/}
         {/*</Form.Group>*/}
         <Form.Group className="mb-4">
            <Form.Label>Deskripsi</Form.Label>
            <Form.Control
                as="textarea"
                rows={3}
                onChange={onDescriptionChange}
                placeholder="Isi Nama Metode Pembayaran"
                value={description}
                required
            />
         </Form.Group>
         {/*<Form.Group className="mb-4">*/}
         {/*   <Form.Label>Fee Type</Form.Label>*/}
         {/*   <Select*/}
         {/*      className="basic-single"*/}
         {/*      classNamePrefix="select"*/}
         {/*      placeholder="Ketik atau pilih fee type"*/}
         {/*      isSearchable={true}*/}
         {/*      name="type"*/}
         {/*      options={feeTypeOption}*/}
         {/*      onChange={onTypeChangeHandler}*/}
         {/*      defaultValue={defaultFeeTypeValue}*/}
         {/*   />*/}
         {/*</Form.Group>*/}
         {/*{selectedType === 0 && (*/}
            <Form.Group className="mb-4">
               <Form.Label>Biaya Admin</Form.Label>
               <NumberFormat
                  value={adminFee}
                  className="form-control d-inline field_plaint_text"
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp. "}
                  onValueChange={(values) => {
                     setAdminFee(values.floatValue ?? 5000);
                  }}
               />
            </Form.Group>
         {/*)}*/}
         {/*{selectedType === 1 && (*/}
            <Form.Group className="mb-4">
               <Form.Label>Persentase Biaya</Form.Label>
               <NumberFormat
                  value={feePercentage}
                  className="form-control d-inline field_plaint_text"
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  suffix={" %"}
                  onValueChange={(values) => {
                     setFeePercentage(values.floatValue ?? 0);
                  }}
               />
            </Form.Group>
         {/*)}*/}

         <Form.Group className="mb-4">
            <FormFile
               name="icon"
               label="Ikon"
               defaultImage={image.fileValue}
               handleAcceptedFiles={handleFileUpload}
               height={200}
               required={!isEdit}
               onError={errorImage}
               defaultFromApi={image.fromApi}
            />
         </Form.Group>
         <div className="text-center mt-5">
            <button
               className="btn button-outline-primary mr-3"
               type="reset"
               onClick={onCancelHandler}>
               BATALKAN
            </button>
            <button className="btn button-primary" type="submit">
               {isEdit ? "EDIT METODE PEMBAYARAN" : "TAMBAH METODE PEMBAYARAN"}
            </button>
         </div>
      </Form>
   );
};

export interface PaymentMethodFormProps {
   isEdit: boolean;
   data: PaymentMethodModel;
   onSubmit: (formData: PaymentMethodFormModel) => void;
}

export default PaymentMethodForm;
