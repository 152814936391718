import React, {useEffect} from 'react';
import NumberFormat from "react-number-format";
import {Row, Col, Carousel, Badge} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store"
import {PackageModel} from "../models";
import {useParams} from "react-router-dom";
import {checkObjectIsNotEmpty} from "../../../helpers/checkEmptyObject";
import {
    getPackageAttachment,
    getPackageDetail,
    getPackageInclusion,
    getPackageSingle
} from "../deleteMitraService.reducer";
import {getMediaEndpoint} from "../../../helpers/imageManipulation";

const MitraPackageDetail = () => {
    const dispatch = useDispatch();
    let {id} = useParams<{ id: string }>();

    const singlePackage = useSelector((state: RootState) => state.deleteMitraService.singlePackage ?? {} as PackageModel);

    const packageAttachment = useSelector((state: RootState) => state.deleteMitraService.packageAttachment ?? []);

    const detailPackage = useSelector((state: RootState) => state.deleteMitraService.detailPackage ?? []);

    const packageInclusion = useSelector((state: RootState) => state.deleteMitraService.packageInclusion ?? []);

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getPackageSingle(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (checkObjectIsNotEmpty(singlePackage)) {
            dispatch(getPackageAttachment(singlePackage.id));
            dispatch(getPackageDetail(singlePackage.id));
            dispatch(getPackageInclusion(singlePackage.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singlePackage]);

    return (
        <Row>
            <Col md={12} className="product_box mt-4">
                <Row>
                    <Col md={6} className="product_picture">
                        <Carousel>
                            {packageAttachment.map((item) => {
                                return (
                                    <Carousel.Item key={item.id}>
                                            <img
                                                className="d-block w-100 product_image_slide"
                                                src={getMediaEndpoint(item.path)}
                                                alt="First slide"
                                            />
                                    </Carousel.Item>
                                );
                            })}
                        </Carousel>
                    </Col>
                    <Col md={6} className="product_content p-4 p-md-0 pt-md-3">
                        <p className="product_detail_category_text">
                            {/*{singleExpertiseState.name} */}
                        </p>
                        <h1 className="product_detail_title_text d-inline">
                            {singlePackage.name}{" "}
                        </h1>
                        {/*<Badge className="product_detail_badge ml-md-2 my-2 my-md-0">*/}
                        {/*    {singlePackage.baseType === 0 && "Produk"}*/}
                        {/*    {singlePackage.baseType === 1 && "Berbasis Waktu"}*/}
                        {/*    {singlePackage.baseType === 2 && "Berbasis Jumlah"}*/}
                        {/*</Badge>*/}
                        <div>
                            <Badge pill className="expertise-tag mr-2 mb-2">
                                {singlePackage?.baseType === 0 && "Produk"}
                                {singlePackage?.baseType === 1 && "Berbasis Waktu"}
                                {singlePackage?.baseType === 2 && "Berbasis Jumlah"}
                            </Badge>
                            <Badge pill className="expertise-tag mr-2 mb-2">
                                {singlePackage?.expertiseName}
                            </Badge>
                            <Badge pill className="expertise-tag mr-2 mb-2">
                                {singlePackage?.mitraTypeName}
                            </Badge>
                        </div>
                        <div className="d-block">
                           <span className="customer_product_rate mr-2">
                              <FontAwesomeIcon icon={faStar} color="#F1B613" width="16px" />
                               {singlePackage.totalRating?.toFixed(2)}
                           </span>
                            <span className="customer_product_rate_boundary mr-2"> | </span>
                            <span className="customer_product_rate">
                              {singlePackage.totalReview}
                           </span>
                            <span className="customer_product_rate_subtitle mr-2">Penilaian</span>
                            <span className="customer_product_rate_boundary mr-2"> | </span>
                            <span className="customer_product_rate">{singlePackage.totalSold}</span>
                            <span className="customer_product_rate_subtitle mr-2">Terjual</span>
                        </div>
                        <Row className="mt-4">
                            <Col md={4}>
                                <p className="product_detail_pricelabel_text">
                                    {singlePackage.baseType === 0 && "Harga "}
                                    {singlePackage.baseType === 1 && "Harga "}
                                    {singlePackage.baseType === 2 && "Harga "}
                                </p>
                            </Col>
                            <Col md={6} className="product_detail_price_text">
                                {detailPackage.length !== 0 && (
                                    detailPackage.map((itemPrice) => {
                                        if (singlePackage.baseType === 1) {
                                            return (
                                                <p key={itemPrice.id}>
                                                    {itemPrice.startValue} {" "}
                                                    {itemPrice.uom} Rp.{" "}
                                                    <NumberFormat
                                                        value={itemPrice.price}
                                                        displayType={"text"}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                    />
                                                </p>
                                            );
                                        } else {
                                            return (
                                                <p key={itemPrice.id}>
                                                    {itemPrice.startValue} - {itemPrice.endValue}{" "}
                                                    {itemPrice.uom} Rp.{" "}
                                                    <NumberFormat
                                                        value={itemPrice.price}
                                                        displayType={"text"}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                    />
                                                </p>
                                            );
                                        }

                                    })
                                )}
                            </Col>
                        </Row>
                        {packageInclusion.length !== 0 && (
                            <Row className="mt-4 mb-4">
                                <Col md={4}>
                                    <p className="product_detail_pricelabel_text">Inclusion</p>
                                </Col>
                                <Col md={8} className="product_detail_price_text">
                                    {packageInclusion.map((itemInclusion) => (
                                        <Badge key={itemInclusion.id} className="customer_product_detail_badge mr-2">
                                            {itemInclusion.productName}
                                        </Badge>
                                    ))}
                                </Col>
                            </Row>
                        )}

                        <p className="product_detail_pricelabel_text">Deskripsi</p>
                        <p className="product_detail_price_text">
                            {singlePackage.description}
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default MitraPackageDetail;
