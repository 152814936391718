import React, {useEffect} from "react";
import Moment from "react-moment";
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {RootState} from "../../../../app/store";
import {getBlogList} from "../blog.reducer";
import {BlogModel} from "../models";
import {Badge} from "react-bootstrap";
import {getMediaEndpoint} from "../../../../helpers/imageManipulation";

const BlogPage = ({}) => {
    const dispatch = useDispatch();
    let {id} = useParams<{ id: string }>();

    useEffect(() => {
        if (id !== "" || id !== undefined) {
            dispatch(getBlogList(id));
        }
    }, [id]);

    const blogList = useSelector(
        (state: RootState) => state.blog.list ?? ([] as BlogModel[])
    );

    return (
        <>
            <h5 className="mb-4 mt-4 data_diri_tab_menu_title">BLOG</h5>
            <div>
                {blogList.map((item) => {
                    return (
                        <div className="horizontal-card mb-3">
                            <div className="horizontal-card-image">
                                <img src={getMediaEndpoint(item.thumbnailPath)} alt="blog"/>
                            </div>
                            <div className="horizontal-card-body ml-4">
                                <h4 className="mb-3 blog-title">
                                    <Link to={`/mitra/blog/detail/${item.id}`}>{item.title}</Link>
                                </h4>
                                <span className="mr-2"><Moment format="DD MMMM YYYY">{new Date(item.createdDate)}</Moment></span>
                                {
                                    item.metaKeyword?.map((meta) => (
                                        <Badge
                                            pill
                                            className="badge-tag font-weight-normal ml-1 mr-1 badge-sm text-capitalize"
                                        >
                                            {meta}
                                        </Badge>
                                    ))
                                }
                                <p className="mt-3 b-3">
                                    {item.subtitle}
                                </p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default BlogPage;
