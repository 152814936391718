import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import IssueCategoryForm from "../components/IssueCategoryForm";

import {
  createIssueCategory,
  getIssueCategorySingleItem,
  updateIssueCategory,
  resetStatus,
} from "../issueCategory.reducer";
import { CreateIssueCategoryModel, IssueCategoryModel } from "../models";
import {useHistory, useParams} from "react-router-dom";
import { RootState } from "../../../../app/store";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiErrorResponse} from "../../../../index";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";

const IssueCategoryDetailPage = () => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();
  let history = useHistory();

  const singleDataState = useSelector(
    (state: RootState) =>
      state.issueCategory.single ?? ({} as IssueCategoryModel)
  );
  const status = useSelector((state: RootState) => state.issueCategory.status);
  const error = useSelector((state: RootState) => state.issueCategory.error ?? ({} as ApiErrorResponse<any>));

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (status !== undefined && status !== ApiResponseStatus.pending) {
      MySwal.fire(
          savedStatusOption(
              status === ApiResponseStatus.success,
              error.data?.message
          )
      ).then(() => {
        dispatch(resetStatus());
        if (status === ApiResponseStatus.success) {
          history.push("/master/issue-category/list");
        }
      });
    }
  }, [status, error]);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getIssueCategorySingleItem(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = (value: string, description: string) => {
    if (isEdit) {
      dispatch(
        updateIssueCategory({
          id: id,
          value,
          description,
        } as IssueCategoryModel)
      );
    } else {
      dispatch(
        createIssueCategory({
          value,
          description,
        } as CreateIssueCategoryModel)
      );
    }
  };

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <h4 className="card-label">
            {isEdit ? "Edit" : "Tambah"} - Master Kategori Isu
          </h4>
          <hr />
          <Row>
            <Col md={8} xl={5}>
              <IssueCategoryForm
                onSubmit={onSubmit}
                data={singleDataState}
                isEdit={isEdit}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default IssueCategoryDetailPage;
