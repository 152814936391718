import axios from "axios";
import { endpoint } from "../../../constants";
import { LoginBannerModel, CreateLoginBannerModel, UploadImageModel } from "./models";

export default class LoginBannerApi {
  static createLoginBanner(createLoginBanner: CreateLoginBannerModel) {
    return axios
      .post<any>(endpoint + "/api/LoginBanner/Create", createLoginBanner)
      .then((res) => res.data);
  }

  static getLoginBannerList() {
    return axios
      .get<any>(endpoint + "/api/LoginBanner/GetList")
      .then((res) => res.data.data);
  }

  static getSingleItem(bannerId: string) {
    return axios
      .get<any>(endpoint + "/api/LoginBanner/GetSingleItem?id=" + bannerId)
      .then((res) => res.data.data);
  }

  static updateLoginBanner(bannerData: LoginBannerModel) {
    return axios
      .post<any>(endpoint + "/api/LoginBanner/Update", bannerData)
      .then((res) => res.data.data);
  }

  static deleteLoginBanner(bannerData: LoginBannerModel) {
    return axios
      .post<any>(endpoint + "/api/LoginBanner/Delete", bannerData)
      .then((res) => [{ response: res.data.data }, { id: bannerData.id }]);
  }

  static uploadImage(imageData: UploadImageModel) {
    return axios
      .post<any>(endpoint + "/web/api/Upload/UploadImage", imageData)
      .then((res) => res);
  }
}
