import React, {Fragment, useEffect, useState} from 'react';
import DataTable, {TableColumn} from "react-data-table-component";
import {TableCustomStyles} from "../../../../components/DataTable/TableCustomStyles";
import {MAX_PAGING_OPTIONS} from "../../../../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import moment from "moment";
import NumberFormat from "react-number-format";
import {MarketingEventModel} from "../../models";
import {changePublishEvent, getMarketingEventList, reset} from "../../marketingEvent.reducer";
import {RootState} from "../../../../app/store";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiErrorResponse} from "../../../../index";

const EventTable = () => {
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);

    const [filterText, setFilterText] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [timer, setTimer] = useState(setTimeout(() => {
    }, 1000));

    const status = useSelector((state: RootState) => state.marketingEvent.publishStatus);
    const error = useSelector((state: RootState) => state.marketingEvent.error ?? ({} as ApiErrorResponse<any>));
    const marketingEventList = useSelector((state: RootState) => state.marketingEvent.list ?? []);
    const eventRows = useSelector((state: RootState) => state.marketingEvent.eventRows ?? 0);
    const isLoading = useSelector((state: RootState) => state.marketingEvent.isLoading);

    useEffect(() => {
        dispatch(getMarketingEventList({
            name: undefined,
            skip: 0,
            take: 10
        }));
    }, []);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === ApiResponseStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(reset());
                if (status === ApiResponseStatus.success) {
                    dispatch(getMarketingEventList({
                        name: undefined,
                        skip: 0,
                        take: 10
                    }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onSearchChange = (e: any) => {
        let filterValue = e.target.value;
        setFilterText(filterValue);
        clearTimeout(timer);
        setTimer(
            setTimeout((value = filterValue) => {
                dispatch(getMarketingEventList({
                    name: value === "" ? undefined : value,
                    skip: 0,
                    take: rowsPerPage,
                }));
            }, 1000)
        );
    }

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setRowsPerPage(newPerPage);
        dispatch(getMarketingEventList({
            name: filterText === "" ? undefined : filterText,
            skip: newPerPage * (page - 1),
            take: newPerPage,
        }));
    };

    const handlePageChange = (page: number) => {
        dispatch(getMarketingEventList({
            name: filterText === "" ? undefined : filterText,
            skip: rowsPerPage * (page - 1),
            take: rowsPerPage,
        }));
    };

    const onPublishClicked = (id: string) => {
        MySwal.fire({
            text: `Apakah kamu yakin akan mengubah publikasi event ini?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(changePublishEvent({
                    id: id,
                    isPublish: false,
                }));
            }
        });
    }

    const columns: TableColumn<MarketingEventModel>[] = [
        {
            name: "NOMOR",
            width: "60px",
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: "NAMA EVENT",
            selector: (row) => row.name ?? "-",
            sortable: true,
        },
        {
            name: "TANGGAL AVAILABLE",
            selector: (row) => row.openDate ?? "",
            format: (row: any) => moment(row.openDate).format("DD/MM/YYYY"),
            sortable: true,
        },
        {
            name: "TANGGAL EXPIRED",
            selector: (row) => row.closeDate ?? "",
            format: (row: any) => moment(row.closeDate).format("DD/MM/YYYY"),
            sortable: true,
        },
        {
            name: "JUMLAH DIAKSES (KUNJUNGAN FREQ)",
            selector: (row) => row.totalAksesFreq,
            format: (row) => <Fragment>
                <NumberFormat
                    value={row.totalAksesFreq}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                />
            </Fragment>,
            sortable: true,
        },
        {
            name: "JUMLAH PENGUNJUNG (UNIK)",
            selector: (row) => row.totalAksesUnique,
            format: (row) => <Fragment>
                <NumberFormat
                    value={row.totalAksesUnique}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                />
            </Fragment>,
            sortable: true,
        },
        {
            name: "JUMLAH DOWNLOAD",
            selector: (row) => row.totalDownload,
            format: (row) => <Fragment>
                <NumberFormat
                    value={row.totalDownload}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                />
            </Fragment>,
            sortable: true,
        },
        {
            name: "AKSI",
            width: "400px",
            cell: (row) => (
                <>
                    <Link
                        to={{
                            pathname: `/mitra/event/detail/${row.id}`,
                        }}
                    >
                        <button className="btn button-primary mx-1">EDIT</button>
                    </Link>
                    <Link
                        to={{
                            pathname: `/mitra/event/mitra-statistic/${row.id}`,
                        }}
                    >
                        <button className="btn button-primary mx-1">MITRA</button>
                    </Link>
                    <Link
                        to={{
                            pathname: `/mitra/event/event-statistic/${row.id}`,
                        }}
                    >
                        <button className="btn button-primary mx-1">EVENT</button>
                    </Link>
                    <button
                        className="btn button-primary mx-1" onClick={() => onPublishClicked(row.id)}>
                        BATALKAN PUBLIKASI
                    </button>
                </>
            )
        }
    ]

    return (
        <div>
            <div className="d-flex" style={{
                borderBottom: "1px solid #a0a0a0",
            }}>
                <div style={{width: "300px", paddingBottom: "15px"}}>
                    <div>
                        <div className="d-flex">
                            <div className="input-field-group">
                                <FontAwesomeIcon
                                    className="input-field-absolut-icon"
                                    icon={faSearch}
                                />
                                <input
                                    id="search"
                                    type="search"
                                    placeholder={"Filter berdasarkan nama"}
                                    className="input-field-group-text"
                                    style={{width: "18rem"}}

                                    value={filterText}
                                    onChange={onSearchChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-grow-1 d-flex justify-content-end align-items-end mb-3">
                    <div className="d-inline">
                        <Link
                            to={{
                                pathname: `/mitra/event/create`,
                            }}
                        >
                            <button className="btn button-primary mx-1">Buat Event</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={"my-3"}>
                <DataTable
                    columns={columns}
                    data={marketingEventList}
                    pagination
                    paginationServer
                    paginationTotalRows={eventRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    noHeader
                    customStyles={TableCustomStyles}
                    persistTableHead
                    paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
                    progressPending={isLoading}
                />
            </div>
        </div>
    );
};

export default EventTable;
