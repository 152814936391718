import React, {useEffect, useState} from "react";
import {Col, Form, Image, Row} from "react-bootstrap";
import {RootState} from "../../../../app/store";
import {Gender, PersonalAttachmentModel, ProfessionalModel} from "../models";
import {useSelector} from "react-redux";
import {UserModel} from "../../dataMitra/models";
import UpdateBasicAccountModal from "../../dataMitra/components/UpdateBasicAccountModal";
import {getMediaEndpoint} from "../../../../helpers/imageManipulation";

const DataPribadiList = (props: { personalData: any }) => {
    const singleUser = useSelector(
        (state: RootState) =>
            state.dataMitra.singleUser ?? ({} as UserModel)
    );
    const professionalState = useSelector(
        (state: RootState) =>
            state.dataDiri.professionalSingle ?? ({} as ProfessionalModel)
    );
    const personalAttachmentState = useSelector(
        (state: RootState) => state.dataDiri.personalAttachment ?? []
    );

    const [isModalUpdateShow, setIsModalUpdateShow] = useState(false);
    const [ktpAttachment, setKtpAttachment] = useState(
        {} as PersonalAttachmentModel
    );
    const [selfieAttachment, setSelfieAttachment] = useState(
        {} as PersonalAttachmentModel
    );

    useEffect(() => {
        if (personalAttachmentState.length !== 0) {
            const ktp =
                (personalAttachmentState.find(
                    (item) => item.type === 0
                ) as PersonalAttachmentModel) ?? ({} as PersonalAttachmentModel);
            const selfie =
                (personalAttachmentState.find(
                    (item) => item.type === 1
                ) as PersonalAttachmentModel) ?? ({} as PersonalAttachmentModel);
            setKtpAttachment(ktp);
            setSelfieAttachment(selfie);
        }
    }, [personalAttachmentState]);

    const onImageClicked = (path: string) => {
        const win = window.open(getMediaEndpoint(path), "_blank") as Window;
        win.focus();
    };

    const handleModalUpdate = () => {
        setIsModalUpdateShow(prevState => !prevState);
    };
    return (
        <>
            <h6 className="mb-4 data_diri_tab_body_title d-inline-block">
                Data Pribadi{" "}
            </h6>
            <Form.Group as={Row} controlId="formPlaintext">
                <Form.Label column sm="12" className="field_plaint_text">
                    E-Mail
                </Form.Label>
                <Col sm="12" className="d-flex">
                    <Form.Control
                        readOnly
                        defaultValue={singleUser.email}
                        className="field_plaint_text"
                    />
                    <button
                        className="btn button-primary mx-2"
                        onClick={handleModalUpdate}
                    >
                        Ubah
                    </button>
                </Col>
                <Col sm="12">
                    <hr className="tab_body_underline"/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintext">
                <Form.Label column sm="12" className="field_plaint_text">
                    Username
                </Form.Label>
                <Col sm="12">
                    <Form.Control
                        readOnly
                        defaultValue={singleUser.username}
                        className="field_plaint_text"
                    />
                </Col>
                <Col sm="12">
                    <hr className="tab_body_underline"/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintext">
                <Form.Label column sm="12" className="field_plaint_text">
                    Nama Lengkap Sesuai KTP
                </Form.Label>
                {professionalState.fullname !== "null" && (
                    <Col sm="12">
                        <Form.Control
                            readOnly
                            defaultValue={professionalState.fullname}
                            className="field_plaint_text"
                        />
                    </Col>
                )}
                <Col sm="12">
                    <hr className="tab_body_underline"/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintext">
                <Form.Label column sm="12" className="field_plaint_text">
                    Jenis Kelamin
                </Form.Label>
                <Col sm="12">
                    <Form.Control
                        readOnly
                        value={professionalState.gender === Gender.Pria ? "Pria" : "Wanita"}
                        className="field_plaint_text"
                    />
                </Col>
                <Col sm="12">
                    <hr className="tab_body_underline"/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintext">
                <Form.Label column sm="12" className="field_plaint_text">
                    Nomor KTP
                </Form.Label>
                {professionalState.identityNumber !== "null" && (
                    <Col sm="12">
                        <Form.Control
                            readOnly
                            defaultValue={professionalState.identityNumber}
                            className="field_plaint_text"
                        />
                    </Col>
                )}
                <Col sm="12">
                    <hr className="tab_body_underline"/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintext">
                <Form.Label column sm="12" className="field_plaint_text">
                    Alamat Sesuai KTP
                </Form.Label>
                {professionalState.address !== "null" && (
                    <Col sm="12">
                        <Form.Control
                            as="textarea"
                            rows={2}
                            readOnly
                            defaultValue={professionalState.address}
                            className="field_plaint_text"
                        />
                    </Col>
                )}
                <Col sm="12">
                    <hr className="tab_body_underline"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintext">
                <Col md="6" className="mb-3">
                    <Form.Label>Foto KTP</Form.Label>
                    <div
                        className="file-upload-frame w-100"
                        onClick={() =>
                            onImageClicked(
                                ktpAttachment.path !== undefined ? ktpAttachment.path : ""
                            )
                        }
                    >
                        <Image
                            src={
                                ktpAttachment.path !== undefined
                                    ? getMediaEndpoint(ktpAttachment.path)
                                    : ""
                            }
                            className="file-upload-image"
                        />
                    </div>
                </Col>
                <Col md="6" className="mb-3">
                    <Form.Label>Foto Selfie dengan KTP</Form.Label>
                    <div
                        className="file-upload-frame w-100"
                        onClick={() => onImageClicked(selfieAttachment.path ?? "")}
                    >
                        <Image
                            src={
                                selfieAttachment.path !== undefined
                                    ? getMediaEndpoint(selfieAttachment.path)
                                    : ""
                            }
                            className="file-upload-image"
                        />
                    </div>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintext">
                <Form.Label column sm="12" className="field_plaint_text">
                    Nomor Handphone
                </Form.Label>
                {professionalState.phoneNumber !== "null" && (
                    <Col sm="12" className="d-flex">
                        <Form.Control
                            readOnly
                            defaultValue={professionalState.phoneNumber}
                            className="field_plaint_text"
                        />
                        <button
                            className="btn button-primary mx-2"
                            onClick={handleModalUpdate}
                        >
                            Ubah
                        </button>
                    </Col>
                )}
                <Col sm="12">
                    <hr className="tab_body_underline"/>
                </Col>
            </Form.Group>
            <UpdateBasicAccountModal
                isShow={isModalUpdateShow}
                onCloseHandler={handleModalUpdate}
                data={
                    {
                        userId: singleUser.id,
                        email: singleUser.email,
                        phoneNumber: professionalState.phoneNumber,
                    }
                }
            />
        </>
    );
};

export default DataPribadiList;
