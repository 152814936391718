import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Container, Row, Col} from "react-bootstrap";
import RequestCancelProjectTable from "../components/RequestCancelProjectTable";
import {RootState} from "../../../app/store";
import ComponentLoader from "../../../components/ComponentLoader";
import {ApiErrorResponse} from "../../../index";
import {resetError} from "../requestCancelProject.reducer";
import {checkEmptyObject, checkObjectIsNotEmpty} from "../../../helpers/checkEmptyObject";

const RequestCancelProjectPage = () => {
    const dispatch = useDispatch();

    const isLoading = useSelector(
        (state: RootState) => state.requestCancelProject.isLoading
    );
    const error = useSelector(
        (state: RootState) => state.requestCancelProject.error ?? {} as ApiErrorResponse<any>
    );

    useEffect(() => {
        if (checkObjectIsNotEmpty(error)) {
            alert("Terjadi kesalahan ketika mengirim permintaan");
            dispatch(resetError());
        }
    }, [error]);

    // useEffect(() => {
    //   dispatch(getBlogCategoryList());
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [statusState]);

    // useEffect(() => {
    //   dispatch(resetSingle());
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <Container className="content-body" fluid>
            <Row>
                <Col md={12}>
                    <h4 className="card-label">Permintaan Pembatalan Event</h4>
                    <ComponentLoader loading={isLoading} loadingName="Mengirim Permintaan, Mohon Tunggu">
                        <RequestCancelProjectTable/>
                    </ComponentLoader>
                </Col>
            </Row>
        </Container>
    );
};

export default RequestCancelProjectPage;
