import React, { useEffect } from "react";
import {Container, Row, Col, Carousel, Badge} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import { RootState } from "../../../../app/store";
import { ProductModel } from "../models";
import { getSingleProduct, getProductAttachment } from "../product.reducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleLeft, faStar} from "@fortawesome/free-solid-svg-icons";
import {getMediaEndpoint} from "../../../../helpers/imageManipulation";

const ProductDetail = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();
  const history = useHistory();

  const singleProductState = useSelector(
    (state: RootState) => state.product.singleProduct ?? ({} as ProductModel)
  );

  const productAttachment = useSelector(
    (state: RootState) => state.product.productAttachment ?? []
  );

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getSingleProduct(id));
      dispatch(getProductAttachment(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Container className="content-body">
      <Row>
        <Col md={12}>
            <h6 className="mb-3"><FontAwesomeIcon icon={faArrowCircleLeft} color={'#692f90'} className='mr-3' style={{cursor: 'pointer'}} onClick={() => history.push(`/mitra/account/personal-data/detail/${singleProductState.userId}`)} />  Product / Jasa</h6>
            <Row>
              <Col md={12} className="product_box">
                <Row>
                  <Col md={6} className="product_picture">
                    <Carousel>
                      {productAttachment.map((item) => {
                        return (
                          <Carousel.Item>
                            <img
                              className="d-block w-100"
                              src={getMediaEndpoint(item.path)}
                              alt="First slide"
                            />
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  </Col>
                  <Col md={6} className="product_content">
                    <p className="product_category_text">
                      {/* {expertiseState.map((itemEx) => {
                      return (
                        <>
                          {itemEx.id === singleProductState.expertiseId
                            ? itemEx.name
                            : ""}
                        </>
                      );
                    })} */}
                    </p>
                    <h1 className="product_detail_title_text">
                      {singleProductState.name}
                    </h1>
                    <Row>
                      <Col md={12} className="product_content pt-0">
                        <div>
                          <Badge pill className="expertise-tag mr-2 mb-2">
                            {singleProductState?.baseType === 0 && "Produk"}
                            {singleProductState?.baseType === 1 && "Berbasis Waktu"}
                            {singleProductState?.baseType === 2 && "Berbasis Jumlah"}
                          </Badge>
                          <Badge pill className="expertise-tag mr-2 mb-2">
                            {singleProductState?.expertiseName}
                          </Badge>
                          <Badge pill className="expertise-tag mr-2 mb-2">
                            {singleProductState?.mitraTypeName}
                          </Badge>
                        </div>
                        <div className="d-block"><span
                            className="customer_product_rate mr-2"><FontAwesomeIcon
                            icon={faStar}
                            color="#F1B613"
                            width="16px"
                        />{" "}{singleProductState.totalRating?.toFixed(2)}</span><span
                            className="customer_product_rate_boundary mr-2">{" "}|{" "}</span>
                          <span
                              className="customer_product_rate">{singleProductState.totalReview}</span>
                          <span
                              className="customer_product_rate_subtitle mr-2">{" "}Penilaian</span>
                          <span className="customer_product_rate_boundary mr-2">{" "}|{" "}</span>
                          <span
                              className="customer_product_rate">{singleProductState.totalSold}</span>
                          <span
                              className="customer_product_rate_subtitle mr-2">{" "}Terjual</span>
                        </div>
                      </Col>
                      <Col md={4}>
                        <p className="product_detail_pricelabel_text">
                          Harga per quantity
                        </p>
                      </Col>
                      <Col md={6} className="product_detail_price_text">
                        <p>
                          {singleProductState.uom} - Rp.{" "}
                          {singleProductState.price}
                        </p>
                      </Col>
                    </Row>
                    <p className="product_detail_pricelabel_text">Deskripsi</p>
                    <p className="product_detail_price_text">
                      {singleProductState.description}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetail;
