import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userLoginSliceReducer, { UserSlice } from "../features/adminLogin/user.reducer";
import professionalTypeReducer, {
   CreateProfessionalTypeSlice,
} from "../features/Mitra/Professional/ProfessionalType/professionalType.reducer";
import socialMediaTypeReducer, {
   CreateSocialMediaTypeSlice,
} from "../features/master/socialMediaType/socialMediaType.reducer";
import { LSK_USER_SLICE } from "../constants";
import languageReducer, { CreateLanguageSlice } from "../features/master/language/language.reducer";
import cityReducer, { CreateCitySlice } from "../features/master/city/city.reducer";
import tagExpertiseReducer, {
   TagExpertiseTypeSlice,
} from "../features/master/tagExpertise/tagExpertise.reducer";
import expertiseReducer, {
   CreateExpertiseSlice,
} from "../features/dataMitra/tagExpertise/tagExpertise.reducer";
import dataDiriReducer, {
   CreateDataDiriSlice,
} from "../features/dataMitra/dataDiri/dataDiri.reducer";
import portfolioReducer, {
   CreatePortfolioSlice,
} from "../features/dataMitra/portfolio/portfolio.reducer";
import dataMitraReducer, {
   CreateDataMitraSlice,
} from "../features/dataMitra/dataMitra/dataMitra.reducer";
import productReducer, { CreateProductSlice } from "../features/dataMitra/product/product.reducer";
import bannerReducer, { CreateBannerSlice } from "../features/customer/banner/banner.reducer";
import blogReducer, { BlogSlice } from "../features/dataMitra/blog/blog.reducer";
import requestExpertiseReducer, {
   RequestExpertiseSlice,
} from "../features/dataMitra/expertiseRequest/expertiseRequest.reducer";
import revisionOptionReducer, {
   CreateRevisionOptionSlice,
} from "../features/master/revisionOption/revisionOption.reducer";
import blogCategoryReducer, {
   CreateBlogCategorySlice,
} from "../features/master/blogCategory/blogCategory.reducer";
import blogAdminReducer, { CreateBlogAdminSlice } from "../features/master/blog/blog.reducer";
import issueCategoryReducer, {
   CreateIssueCategorySlice,
} from "../features/master/issueCategory/issueCategory.reducer";
import reportIssueReducer, { ReportIssueSlice } from "../features/reportIssue/reportIssue.reducer";
import vendorTypeReducer, {
   CreateVendorTypeSlice,
} from "../features/Mitra/Vendor/VendorType/vendorType.reducer";
import discountReducer, { DiscountSlice } from "../features/master/discount/discount.reducer";
import paymentMethodReducer, {
   PaymentMethodSlice,
} from "../features/master/paymentMethod/paymentMethod.reducer";
import requestAdminReducer, {
   RequestAdminSlice,
} from "../features/requestAdmin/requestAdmin.reducer";
import chatReducer, { ChatSlice } from "../features/chat/chat.reducer";
import withdrawReducer, { WithdrawSlice } from "../features/withdraw/withdraw.reducer";
import requestCancelProjectReducer, {
   RequestCancelProjectSlice,
} from "../features/RequestCancelProject/requestCancelProject.reducer";
import projectSettingReducer, {
   ProjectSettingSlice,
} from "../features/projectSetting/projectSetting.reducer";
import loginBannerReducer, {
   CreateLoginBannerSlice,
} from "../features/master/loginBanner/loginBanner.reducer";
import dataCustomerReducer, {
   DataCustomerSlice,
} from "../features/customer/dataCustomer/dataCustomer.reducer";
import dataAdminReducer, { DataAdminSlice } from "../features/dataAdmin/dataAdmin.reducer";
import dashboardReducer, { DashboardSlice } from "../features/dashboard/dashboard.reducer";
import adminBalanceReducer, {AdminBalanceSlice} from "../features/adminBalance/adminBalance.reducer";
import mitraCancelProjectReducer, {
   MitraCancelProjectSlice
} from "../features/MitraCancelProject/mitraCancelProject.reducer";
import userBalanceReducer, {UserBalanceSlice} from "../features/userBalance/userBalance.reducer";
import walletReducer, {WalletSlice} from "../features/wallet/wallet.reducer";
import requestDeleteAccountReducer, {
   RequestDeleteAccountSlice
} from "../features/RequestDeleteAccount/requestDeleteAccount.reducer";
import deleteMitraServiceReducer, {
   DeleteMitraServiceSlice
} from "../features/deleteMitraService/deleteMitraService.reducer";
import dataProjectReducer, {ProjectSlice} from "../features/dataProject/dataProject.reducer";
import marketingEventReducer, {MarketingEventSlice} from "../features/marketingEvent/marketingEvent.reducer";
import customerCancelProjectReducer, {
   CustomerCancelProjectSlice
} from "../features/customerCancelProject/customerCancelProject.reducer";
import gearOptionReducer, {GearOptionSlice} from "../features/master/gearOption/gearOption.reducer";
import mitraTierReducer, {MitraTierSlice} from "../features/master/mitraTier/mitraTier.reducer";
import reasonOptionReducer, {ReasonOptionSlice} from "../features/master/reasonOption/reasonOption.reducer";
import mitraPointReducer, {MitraPointSlice} from "../features/mitraPoint/mitraPoint.reducer";
import storeProductReducer, {StoreProductSlice} from "../features/aramataStore/storeProduct/storeProduct.reducer";
import proSquadPortfolioReducer, {ProSquadPortfolioSlice} from "../features/proSquadPortfolio/proSquadPortfolio.reducer";
import storeReportReducer, { StoreReportSlice } from "../features/aramataStore/storeReport/storeReport.reducer";

export const store = configureStore({
   reducer: {
      user: userLoginSliceReducer,
      professionalType: professionalTypeReducer,
      vendorType: vendorTypeReducer,
      socialMediaType: socialMediaTypeReducer,
      language: languageReducer,
      city: cityReducer,
      tagExpertise: tagExpertiseReducer,
      expertise: expertiseReducer,
      revisionOption: revisionOptionReducer,
      gearOption: gearOptionReducer,
      reasonOption: reasonOptionReducer,
      mitraTier: mitraTierReducer,
      requestExpertise: requestExpertiseReducer,
      blogCategory: blogCategoryReducer,
      issueCategory: issueCategoryReducer,
      reportIssue: reportIssueReducer,
      requestAdmin: requestAdminReducer,
      dataDiri: dataDiriReducer,
      dataMitra: dataMitraReducer,
      dataCustomer: dataCustomerReducer,
      dataAdmin: dataAdminReducer,
      portfolio: portfolioReducer,
      product: productReducer,
      blog: blogReducer,
      blogAdmin: blogAdminReducer,
      banner: bannerReducer,
      loginBanner: loginBannerReducer,
      discount: discountReducer,
      paymentMethod: paymentMethodReducer,
      chat: chatReducer,
      withdraw: withdrawReducer,
      adminBalance: adminBalanceReducer,
      requestCancelProject: requestCancelProjectReducer,
      requestDeleteAccount: requestDeleteAccountReducer,
      mitraCancelProject: mitraCancelProjectReducer,
      customerCancelProject: customerCancelProjectReducer,
      projectSetting: projectSettingReducer,
      userBalance: userBalanceReducer,
      wallet: walletReducer,
      dashboard: dashboardReducer,
      deleteMitraService: deleteMitraServiceReducer,
      project: dataProjectReducer,
      marketingEvent: marketingEventReducer,
      mitraPoint: mitraPointReducer,
      storeProduct: storeProductReducer,
      proSquadPortfolio: proSquadPortfolioReducer,
      storeReport: storeReportReducer,
   },
});

export type RootState = {
   user: UserSlice;
   professionalType: CreateProfessionalTypeSlice;
   vendorType: CreateVendorTypeSlice;
   socialMediaType: CreateSocialMediaTypeSlice;
   language: CreateLanguageSlice;
   city: CreateCitySlice;
   tagExpertise: TagExpertiseTypeSlice;
   requestExpertise: RequestExpertiseSlice;
   expertise: CreateExpertiseSlice;
   revisionOption: CreateRevisionOptionSlice;
   gearOption: GearOptionSlice;
   reasonOption: ReasonOptionSlice;
   mitraTier: MitraTierSlice;
   blogCategory: CreateBlogCategorySlice;
   issueCategory: CreateIssueCategorySlice;
   reportIssue: ReportIssueSlice;
   requestAdmin: RequestAdminSlice;
   dataDiri: CreateDataDiriSlice;
   dataMitra: CreateDataMitraSlice;
   dataCustomer: DataCustomerSlice;
   dataAdmin: DataAdminSlice;
   portfolio: CreatePortfolioSlice;
   product: CreateProductSlice;
   blog: BlogSlice;
   blogAdmin: CreateBlogAdminSlice;
   banner: CreateBannerSlice;
   loginBanner: CreateLoginBannerSlice;
   discount: DiscountSlice;
   paymentMethod: PaymentMethodSlice;
   chat: ChatSlice;
   withdraw: WithdrawSlice;
   adminBalance: AdminBalanceSlice;
   requestCancelProject: RequestCancelProjectSlice;
   requestDeleteAccount: RequestDeleteAccountSlice;
   mitraCancelProject: MitraCancelProjectSlice;
   customerCancelProject: CustomerCancelProjectSlice;
   projectSetting: ProjectSettingSlice;
   userBalance: UserBalanceSlice;
   wallet: WalletSlice;
   dashboard: DashboardSlice;
   deleteMitraService: DeleteMitraServiceSlice;
   project: ProjectSlice;
   marketingEvent: MarketingEventSlice;
   mitraPoint: MitraPointSlice;
   storeProduct: StoreProductSlice;
   proSquadPortfolio: ProSquadPortfolioSlice;
   storeReport: StoreReportSlice;
};

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
   ReturnType,
   RootState,
   unknown,
   Action<string>
>;

store.subscribe(() => {
   const state = store.getState();
   localStorage.setItem(LSK_USER_SLICE, JSON.stringify(state.user));
});
