import React, {useEffect} from 'react';
import NumberFormat from "react-number-format";
import {Row, Col, Carousel, Badge} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {RootState} from "../../../app/store";
import {ProductModel} from "../models";
import {getProductAttachment, getProductSingle} from "../deleteMitraService.reducer";
import {checkObjectIsNotEmpty} from "../../../helpers/checkEmptyObject";
import {getMediaEndpoint} from "../../../helpers/imageManipulation";

const MitraProductDetail = () => {
    const dispatch = useDispatch();
    let {id} = useParams<{ id: string }>();

    const singleProduct = useSelector((state: RootState) => state.deleteMitraService.singleProduct ?? {} as ProductModel);

    const productAttachment = useSelector((state: RootState) => state.deleteMitraService.productAttachment ?? []);

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getProductSingle(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (checkObjectIsNotEmpty(singleProduct)) {
            dispatch(getProductAttachment(singleProduct.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleProduct]);

    return (
        <Row>
            <Col md={12} className="product_box">
                <Row>
                    <Col md={6} className="product_picture">
                        <Carousel>
                            {productAttachment.map((item) => {
                                return (
                                    <Carousel.Item key={item.id}>
                                        <img
                                            className="d-block w-100 product_image_slide"
                                            src={getMediaEndpoint(item.path)}
                                            alt="First slide"
                                        />
                                    </Carousel.Item>
                                );
                            })}
                        </Carousel>
                    </Col>
                    <Col md={6} className="product_content p-4 p-md-0 pt-md-3">
                        <p className="product_detail_category_text">

                        </p>
                        <h1 className="product_detail_title_text">
                            {singleProduct.name}
                        </h1>
                        <div>
                            <Badge pill className="expertise-tag mr-2 mb-2">
                                {singleProduct?.baseType === 0 && "Produk"}
                                {singleProduct?.baseType === 1 && "Berbasis Waktu"}
                                {singleProduct?.baseType === 2 && "Berbasis Jumlah"}
                            </Badge>
                            <Badge pill className="expertise-tag mr-2 mb-2">
                                {singleProduct?.expertiseName}
                            </Badge>
                            <Badge pill className="expertise-tag mr-2 mb-2">
                                {singleProduct?.mitraTypeName}
                            </Badge>
                        </div>
                        <div className="d-block">
                  <span className="customer_product_rate mr-2">
                    <FontAwesomeIcon
                        icon={faStar}
                        color="#F1B613"
                        width="16px"
                    />{" "}
                      {singleProduct.totalRating?.toFixed(2)}
                  </span>
                            <span className="customer_product_rate_boundary mr-2">
                    {" "}
                                |{" "}
                  </span>
                            <span className="customer_product_rate">
                    {singleProduct.totalReview}
                  </span>
                            <span className="customer_product_rate_subtitle mr-2">
                    {" "}
                                Penilaian
                  </span>
                            <span className="customer_product_rate_boundary mr-2">
                    {" "}
                                |{" "}
                  </span>
                            <span className="customer_product_rate">
                    {singleProduct.totalSold}
                  </span>
                            <span className="customer_product_rate_subtitle mr-2">
                    {" "}
                                Terjual
                  </span>
                        </div>
                        <Row className="mt-4">
                            <Col md={4}>
                                <p className="product_detail_pricelabel_text">
                                    Harga per quantity
                                </p>
                            </Col>
                            <Col md={4} className="product_detail_price_text">
                                <p>
                                    {singleProduct.uom} - Rp.{" "}
                                    <NumberFormat
                                        value={singleProduct.price}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                    />
                                </p>
                            </Col>
                        </Row>
                        <p className="product_detail_pricelabel_text">Deskripsi</p>
                        <p className="product_detail_price_text">
                            {singleProduct.description}
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default MitraProductDetail;
