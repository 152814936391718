import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { getRevisionOptionList } from "../../../master/revisionOption/revisionOption.reducer";
import { createRevisionLog } from "../dataMitra.reducer";
import { RevisionLogModel } from "../models";

const RevisionModal = ({ isShow = false, ...props }: RevisionModalProps) => {
   const dispatch = useDispatch();

   const revisionOptionState = useSelector((state: RootState) => state.revisionOption.list);

   const mitraId = useSelector((state: RootState) => state.dataDiri.professionalSingle?.id ?? "");

   const vendorId = useSelector((state: RootState) => state.dataDiri.vendorSingle?.id ?? "");

   const [checkedRevision, setCheckedRevision] = useState<string[]>([]);
   const [modalShow, setModalShow] = useState(false);

   useEffect(() => {
      dispatch(getRevisionOptionList());
   }, []);

   useEffect(() => {
      setModalShow(isShow);
   }, [isShow]);

   const submitClickedHandler = () => {
      dispatch(
         createRevisionLog({
            mitraId: mitraId !== "false" ? mitraId : vendorId,
            type: mitraId !== "false" ? 1 : 0,
            status: 0,
            revisionStatus: checkedRevision,
         } as RevisionLogModel)
      );
      alert("Saran perbaikan berhasil dikirim");
      setModalShow(false);
   };

   const onCheckBoxChangeHandler = (item: string) => {
      let newArr = [];
      if (!checkedRevision.includes(item)) {
         newArr = [...checkedRevision, item];
      } else {
         newArr = checkedRevision.filter((a) => a !== item);
      }
      setCheckedRevision(newArr);
   };

   return (
      <>
         <Modal
            show={modalShow}
            backdrop="static"
            keyboard={false}
            size="lg"
            centered
            className="profile_modal">
            <Modal.Body>
               <Row>
                  <Col md={12} className="mb-3">
                     <h5>
                        Kirim permintaan perbaikan
                        <div className="float-right" onClick={props.onCloseHandler}>
                           <span className="material-icons modal-close">close</span>
                        </div>
                     </h5>
                  </Col>
                  <Col md={12} className="mb-3">
                     {revisionOptionState?.map((item) => {
                        return (
                           <Row>
                              <Col md={3} className="revision-title-box">
                                 <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check
                                       type="checkbox"
                                       id={item.id}
                                       label={item.name}
                                       onClick={() => onCheckBoxChangeHandler(item.id)}
                                    />
                                 </Form.Group>
                              </Col>
                              <Col md={5}>
                                 <p>{item.description}</p>
                              </Col>
                              <Col md={12}>
                                 <hr />
                              </Col>
                           </Row>
                        );
                     })}
                  </Col>
                  <Col md={12} className="mb-3">
                     <Button
                        className="float-right button-primary mr-2"
                        onClick={submitClickedHandler}
                        // disabled={isAdminVerified}
                     >
                        Kirim
                     </Button>
                  </Col>
               </Row>
            </Modal.Body>
         </Modal>
      </>
   );
};

export interface RevisionModalProps {
   isShow: boolean;
   onCloseHandler: () => void;
}

export default RevisionModal;
