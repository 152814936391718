import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import DiscountTable from "../components/DiscountTable";
import { getDiscountList, resetSingle } from "../discount.reducer";

const DiscountPage = () => {
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(getDiscountList());
      dispatch(resetSingle());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <h4 className="card-label">Master Diskon</h4>
               <DiscountTable />
            </Col>
         </Row>
      </Container>
   );
};

export default DiscountPage;
