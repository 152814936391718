import React from "react";
import {Row, Col} from "react-bootstrap";
import StoreReportTable from "../components/StoreReportTable";

const StoreReportPage = () => {
    return (
        <Row>
            <Col md={12}>
                <StoreReportTable/>
            </Col>
        </Row>
    );
};

export default StoreReportPage;
