import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AdminLoginPage from "./features/adminLogin/containers/AdminLoginPage";
import AdminPanelPage from "./features/adminPanel/containers/AdminPanelPage";
import AdminForgotPasswordPage from "./features/adminLogin/containers/AdminForgotPasswordPage";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <AdminLoginPage />
        </Route>
        <Route path="/forgot-password">
          <AdminForgotPasswordPage />
        </Route>
        <Route path="/">
          <AdminPanelPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
