import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import {
   ApprovalStatusEnum,
   PackageAttachmentModel,
   PackageDetailModel, PackageInclusionModel,
   PackageModel,
   ProductAttachmentModel,
   ProductModel,
   ReplyModel, UpdateApprovalStatusModel
} from "./models";
import DeleteMitraServiceApi from "./deleteMitraService.api";
import {ApiResponseStatus} from "../../@core/models/apiResponseStatus/apiResponseStatus";

export interface DeleteMitraServiceSlice {
   packageList?: PackageModel[];
   productList?: ProductModel[];
   singlePackage?: PackageModel;
   detailPackage?: PackageDetailModel[];
   packageInclusion?: PackageInclusionModel[];
   packageAttachment?: PackageAttachmentModel[];
   productAttachment?: ProductAttachmentModel[];
   singleProduct?: ProductModel;
   isLoading?: boolean;
   isPackageApprovalLoading?: boolean;
   isProductApprovalLoading?: boolean;
   dateCreated?: string;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
   packageApprovalStatus?: ApiResponseStatus;
   productApprovalStatus?: ApiResponseStatus;
}

export const getPackageList = createAsyncThunk(
   "deleteMitraService/getPackageList",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await DeleteMitraServiceApi.getPackageList();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getPackageByApprovalList = createAsyncThunk(
    "deleteMitraService/getPackageByApprovalList",
    async (status: ApprovalStatusEnum | undefined, { rejectWithValue }) => {
       try {
          return await DeleteMitraServiceApi.getPackageByApprovalList(status);
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const getPackageAttachment = createAsyncThunk(
    "deleteMitraService/getPackageAttachment",
    async (packageId: string, { rejectWithValue }) => {
       try {
          return await DeleteMitraServiceApi.getPackageAttachment(packageId);
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const getPackageDetail = createAsyncThunk(
    "deleteMitraService/getPackageDetail",
    async (packageId: string, { rejectWithValue }) => {
       try {
          return await DeleteMitraServiceApi.getPackageDetail(packageId);
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const getPackageInclusion = createAsyncThunk(
    "deleteMitraService/getPackageInclusion",
    async (packageId: string, { rejectWithValue }) => {
       try {
          return await DeleteMitraServiceApi.getPackageInclusion(packageId);
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const getProductList = createAsyncThunk(
    "deleteMitraService/getProductList",
    async (_: string | undefined = undefined, { rejectWithValue }) => {
       try {
          return await DeleteMitraServiceApi.getProductList();
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const getProductByApprovalList = createAsyncThunk(
    "deleteMitraService/getProductByApprovalList",
    async (status: ApprovalStatusEnum | undefined, { rejectWithValue }) => {
       try {
          return await DeleteMitraServiceApi.getProductByApprovalList(status);
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const getProductAttachment = createAsyncThunk(
    "deleteMitraService/getProductAttachment",
    async (productId: string, { rejectWithValue }) => {
       try {
          return await DeleteMitraServiceApi.getProductAttachment(productId);
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const getPackageSingle = createAsyncThunk(
   "deleteMitraService/getPackageSingle",
   async (id: string, { rejectWithValue }) => {
      try {
         return await DeleteMitraServiceApi.getPackageSingle(id);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getProductSingle = createAsyncThunk(
    "deleteMitraService/getProductSingle",
    async (id: string, { rejectWithValue }) => {
       try {
          return await DeleteMitraServiceApi.getProductSingle(id);
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const deletePackage = createAsyncThunk(
   "deleteMitraService/deletePackage",
   async (id: string, { rejectWithValue }) => {
      try {
         return await DeleteMitraServiceApi.deletePackage(id);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const deleteProduct = createAsyncThunk(
    "deleteMitraService/deleteProduct",
    async (id: string, { rejectWithValue }) => {
       try {
          return await DeleteMitraServiceApi.deleteProduct(id);
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const replyToEmail = createAsyncThunk(
   "deleteMitraService/replyToEmail",
   async (reply: ReplyModel, { rejectWithValue }) => {
      try {
         return await DeleteMitraServiceApi.replyToEmail(reply);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const updatePackageApprovalStatus = createAsyncThunk(
    "deleteMitraService/updatePackageApprovalStatus",
    async (args: UpdateApprovalStatusModel, { rejectWithValue }) => {
       try {
          return await DeleteMitraServiceApi.updatePackageApprovalStatus(args);
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const updateProductApprovalStatus = createAsyncThunk(
    "deleteMitraService/updateProductApprovalStatus",
    async (args: UpdateApprovalStatusModel, { rejectWithValue }) => {
       try {
          return await DeleteMitraServiceApi.updateProductApprovalStatus(args);
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);


const deleteMitraServiceSlice = createSlice({
   name: "deleteMitraServiceState",
   initialState: {} as DeleteMitraServiceSlice,
   reducers: {
      setFilter: (state, action) => {
         state.packageList = action.payload;
         state.productList = action.payload;
      },
      resetSingle: (state) => {
         state.singlePackage = {} as PackageModel;
         state.singleProduct = {} as ProductModel;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
         state.packageApprovalStatus = ApiResponseStatus.pending;
         state.productApprovalStatus = ApiResponseStatus.pending;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(getPackageSingle.pending, (state) => {
         state.singlePackage = undefined;
         state.isLoading = true;
      });
      builder.addCase(getPackageSingle.fulfilled, (state, { payload }) => {
         state.singlePackage = payload;
         state.isLoading = false;
      });
      builder.addCase(getPackageSingle.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(getPackageList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getPackageList.fulfilled, (state, { payload }) => {
         state.packageList = payload;
         state.isLoading = false;
      });
      builder.addCase(getPackageList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(getPackageByApprovalList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getPackageByApprovalList.fulfilled, (state, { payload }) => {
         state.packageList = payload;
         state.isLoading = false;
      });
      builder.addCase(getPackageByApprovalList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(getPackageAttachment.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getPackageAttachment.fulfilled, (state, { payload }) => {
         state.packageAttachment = payload;
         state.isLoading = false;
      });
      builder.addCase(getPackageAttachment.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(getPackageDetail.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getPackageDetail.fulfilled, (state, { payload }) => {
         state.detailPackage = payload;
         state.isLoading = false;
      });
      builder.addCase(getPackageDetail.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(getPackageInclusion.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getPackageInclusion.fulfilled, (state, { payload }) => {
         state.packageInclusion = payload;
         state.isLoading = false;
      });
      builder.addCase(getPackageInclusion.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(getProductSingle.pending, (state) => {
         state.singleProduct = undefined;
         state.isLoading = true;
      });
      builder.addCase(getProductSingle.fulfilled, (state, { payload }) => {
         state.singleProduct = payload;
         state.isLoading = false;
      });
      builder.addCase(getProductSingle.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(getProductList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getProductList.fulfilled, (state, { payload }) => {
         state.productList = payload;
         state.isLoading = false;
      });
      builder.addCase(getProductList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(getProductByApprovalList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getProductByApprovalList.fulfilled, (state, { payload }) => {
         state.productList = payload;
         state.isLoading = false;
      });
      builder.addCase(getProductByApprovalList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(getProductAttachment.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getProductAttachment.fulfilled, (state, { payload }) => {
         state.productAttachment = payload;
         state.isLoading = false;
      });
      builder.addCase(getProductAttachment.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(deletePackage.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(deletePackage.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.dateCreated = new Date().toISOString();
      });
      builder.addCase(deleteProduct.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
      });
      builder.addCase(deleteProduct.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(deleteProduct.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.dateCreated = new Date().toISOString();
      });
      builder.addCase(deletePackage.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
      });
      builder.addCase(replyToEmail.pending, (state) => {
         state.status = ApiResponseStatus.pending;
         state.isLoading = true;
      });
      builder.addCase(replyToEmail.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(replyToEmail.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(updatePackageApprovalStatus.pending, (state) => {
         state.packageApprovalStatus = ApiResponseStatus.pending;
         state.isPackageApprovalLoading = true;
      });
      builder.addCase(updatePackageApprovalStatus.fulfilled, (state, { payload }) => {
         state.isPackageApprovalLoading = false;
         state.packageApprovalStatus = ApiResponseStatus.success;
      });
      builder.addCase(updatePackageApprovalStatus.rejected, (state, { payload }) => {
         state.isPackageApprovalLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.packageApprovalStatus = ApiResponseStatus.failed;
      });
      builder.addCase(updateProductApprovalStatus.pending, (state) => {
         state.productApprovalStatus = ApiResponseStatus.pending;
         state.isProductApprovalLoading = true;
      });
      builder.addCase(updateProductApprovalStatus.fulfilled, (state, { payload }) => {
         state.isProductApprovalLoading = false;
         state.productApprovalStatus = ApiResponseStatus.success;
      });
      builder.addCase(updateProductApprovalStatus.rejected, (state, { payload }) => {
         state.isProductApprovalLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.productApprovalStatus = ApiResponseStatus.failed;
      });
   },
});

export const { setFilter, resetSingle, resetStatus } = deleteMitraServiceSlice.actions;
export default deleteMitraServiceSlice.reducer;
