import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiErrorResponse} from "../../..";
import {
   BlogListModel,
   BlogModel,
   CreateBlogWithUploadModel,
   GetBlogRequestModel,
   PinBlogModel,
   SingleBlogModel,
   UpdateBlogWithUploadModel,
} from "./models";
import BlogApi from "./blog.api";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";

export interface CreateBlogAdminSlice {
   name?: string;
   list?: BlogModel[];
   listHomepage?: BlogListModel[];
   listAdmin?: BlogListModel[];
   listMitra?: BlogListModel[];
   adminRows?: number;
   mitraRows?: number;
   single?: SingleBlogModel;
   isLoading?: boolean;
   isMitraListLoading?: boolean;
   isAdminListLoading?: boolean;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
   pinStatus?: ApiResponseStatus;
   notificationStatus?: ApiResponseStatus;
}

// export const createBlog = createAsyncThunk(
//   "createBlogState/createBlog",
//   async (data: CreateBlogWithUploadModel, { rejectWithValue }) => {
//     try {
//       return await BlogApi.createBlog(blogData);
//     } catch (e) {
//       return rejectWithValue(e as ApiErrorResponse<any>);
//     }
//   }
// );

export const createBlog = createAsyncThunk(
   "createBlogState/createBlog",
   async (data: CreateBlogWithUploadModel, { rejectWithValue }) => {
      try {
         const response = await BlogApi.uploadImage(data.fileData);
         if (response.status === 200) {
            let formData = data.blogData;
            formData.thumbnailPath = response.data.imagepath;
            return await BlogApi.createBlog(formData);
         }
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getBlogList = createAsyncThunk(
   "blogState/blogList",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await BlogApi.getBlogList();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getBlogAutoComplete = createAsyncThunk(
    "blogState/getBlogAutoComplete",
    async (filter: string, { rejectWithValue }) => {
       try {
          return await BlogApi.getBlogAutoComplete(filter);
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const getBlogSingleItem = createAsyncThunk(
   "blogState/blogSingleItem",
   async (blogId: string, { rejectWithValue }) => {
      try {
         return await BlogApi.getSingleItem(blogId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getBlogByUserId = createAsyncThunk(
   "blogState/getBlogByUserId",
   async (userId: string, { rejectWithValue }) => {
      try {
         return await BlogApi.getBlogByUserId(userId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getAdminBlog = createAsyncThunk(
   "blogState/getAdminBlog",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await BlogApi.getBlogByType(true);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getMitraBlog = createAsyncThunk(
   "blogState/getMitraBlog",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await BlogApi.getBlogByType(false);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getBlogByTypeAdminNew = createAsyncThunk(
    "blogState/getBlogByTypeAdminNew",
    async (args: GetBlogRequestModel, { rejectWithValue }) => {
       try {
          return await BlogApi.getBlogByTypeNew({
                ...args,
                isAdmin: true
          });
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const getBlogByTypeMitraNew = createAsyncThunk(
    "blogState/getBlogByTypeMitraNew",
    async (args: GetBlogRequestModel, { rejectWithValue }) => {
       try {
          return await BlogApi.getBlogByTypeNew({
             ...args,
             isAdmin: false
          });
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const setHomepageBlog = createAsyncThunk(
   "createBlogState/setHomepageBlog",
   async (data: BlogModel, { rejectWithValue }) => {
      try {
         return await BlogApi.updateBlog(data);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const updateBlog = createAsyncThunk(
   "createBlogState/updateBlog",
   async (data: UpdateBlogWithUploadModel, { rejectWithValue }) => {
      try {
         if (data.fileData.base64url === undefined) {
            return await BlogApi.updateBlog(data.blogData);
         } else {
            const response = await BlogApi.uploadImage(data.fileData);
            if (response.status === 200) {
               let formData = data.blogData;
               formData.thumbnailPath = response.data.imagepath;
               return await BlogApi.updateBlog(formData);
            }
         }

      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const changePinBlog = createAsyncThunk(
   "createBlogState/changePinBlog",
   async (data: PinBlogModel, { rejectWithValue }) => {
      try {
         return await BlogApi.changePinBlog(data);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const deleteBlog = createAsyncThunk(
   "blogState/deleteBlog",
   async (blogData: BlogModel, { rejectWithValue }) => {
      try {
         return await BlogApi.deleteBlog(blogData);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const sendBlogNotification = createAsyncThunk(
    "blogState/sendBlogNotification",
    async (blogId: string, { rejectWithValue }) => {
       try {
          return await BlogApi.sendBlogNotification(blogId);
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

const createBlogAdminSlice = createSlice({
   name: "createBlogAdminState",
   initialState: {} as CreateBlogAdminSlice,
   reducers: {
      setFilter: (state, action) => {
         state.list = action.payload;
      },
      resetSingle: (state) => {
         state.single = {} as SingleBlogModel;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
         state.pinStatus = ApiResponseStatus.pending;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(createBlog.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(createBlog.fulfilled, (state) => {
         state.name = new Date().toISOString();
         state.isLoading = false;
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(createBlog.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(getBlogSingleItem.pending, (state) => {
         state.single = undefined;
         state.isLoading = true;
      });
      builder.addCase(getBlogSingleItem.fulfilled, (state, { payload }) => {
         state.single = payload;
         state.isLoading = false;
      });
      builder.addCase(getBlogSingleItem.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(getBlogByUserId.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getBlogByUserId.fulfilled, (state, { payload }) => {
         state.list = payload;
         state.isLoading = false;
      });
      builder.addCase(getBlogByUserId.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      //  get blog created by admin
      builder.addCase(getAdminBlog.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getAdminBlog.fulfilled, (state, { payload }) => {
         state.listAdmin = payload;
         state.isLoading = false;
      });
      builder.addCase(getAdminBlog.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      //  get blog created by mitra
      builder.addCase(getMitraBlog.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getMitraBlog.fulfilled, (state, { payload }) => {
         state.listMitra = payload.data;
         state.isLoading = false;
      });
      builder.addCase(getMitraBlog.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      //  get blog by type admin
      builder.addCase(getBlogByTypeAdminNew.pending, (state) => {
         state.isAdminListLoading = true;
      });
      builder.addCase(getBlogByTypeAdminNew.fulfilled, (state, { payload }) => {
         state.listAdmin = payload.data;
         state.adminRows = payload.total;
         state.isAdminListLoading = false;
      });
      builder.addCase(getBlogByTypeAdminNew.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isAdminListLoading = false;
      });
      //  get blog by type mitra new
      builder.addCase(getBlogByTypeMitraNew.pending, (state) => {
         state.isMitraListLoading = true;
      });
      builder.addCase(getBlogByTypeMitraNew.fulfilled, (state, { payload }) => {
         state.listMitra = payload.data;
         state.mitraRows = payload.total;
         state.isMitraListLoading = false;
      });
      builder.addCase(getBlogByTypeMitraNew.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isMitraListLoading = false;
      });
      builder.addCase(updateBlog.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(updateBlog.fulfilled, (state) => {
         state.isLoading = false;
         state.name = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(updateBlog.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });

      // change homepage blog

      builder.addCase(setHomepageBlog.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(setHomepageBlog.fulfilled, (state) => {
         state.isLoading = false;
         state.name = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(setHomepageBlog.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });

      builder.addCase(changePinBlog.pending, (state) => {
         state.isLoading = true;
         state.pinStatus = ApiResponseStatus.pending;
      });
      builder.addCase(changePinBlog.fulfilled, (state) => {
         state.isLoading = false;
         state.name = new Date().toISOString();
         state.pinStatus = ApiResponseStatus.success;
      });
      builder.addCase(changePinBlog.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.pinStatus = ApiResponseStatus.failed;
      });

      builder.addCase(deleteBlog.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(deleteBlog.fulfilled, (state) => {
         state.isLoading = false;
         state.name = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(deleteBlog.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });

      builder.addCase(sendBlogNotification.pending, (state) => {
         state.isLoading = true;
         state.notificationStatus = ApiResponseStatus.pending;
      });
      builder.addCase(sendBlogNotification.fulfilled, (state) => {
         state.isLoading = false;
         state.notificationStatus = ApiResponseStatus.success;
      });
      builder.addCase(sendBlogNotification.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.notificationStatus = ApiResponseStatus.failed;
      });

   },
});

export const { setFilter, resetSingle, resetStatus } = createBlogAdminSlice.actions;
export default createBlogAdminSlice.reducer;
