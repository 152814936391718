import blank_image from "../assets/images/no_image_available.png";
import blank_profile from "../assets/images/blank_profile.jpg";

export const addDefaultCardImage = (e: any) => {
  e.target.src = blank_image;
};

export const addDefaultProfileImage = (e: any) => {
  e.target.src = blank_profile;
};

export const addDefaultVideoImage = (e: any) => {
  e.target.src = blank_image;
};