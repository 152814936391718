import React from "react";
import { Container } from "react-bootstrap";
import MitraPackageDetail from "../components/MitraPackageDetail";
import {useParams} from "react-router-dom";
import MitraProductDetail from "../components/MitraProductDetail";

const DeleteMitraServiceDetailPage = () => {
    let {type} = useParams<{ type: string }>();

   return (
      <Container className="content-body" fluid>
          {type === 'package' && <MitraPackageDetail />}
          {type === 'product' && <MitraProductDetail />}
      </Container>
   );
};

export default DeleteMitraServiceDetailPage;
