import React, { Fragment, useEffect, useState } from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import DefaultSubHeader from "../../../components/DataTable/DefaultSubHeader";
import { ElementPropModel } from "../../../components/DataTable/models";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import {RequestDeleteEnum, RequestDeleteModel, UserTypeEnum} from "../models";
import {approveDeleteRequest, getListDeleteRequest, resetStatus} from "../requestDeleteAccount.reducer";
import moment from "moment";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../helpers/alertContent";
import {ApiErrorResponse} from "../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
const RequestDeleteAccountTable = () => {
   const dispatch = useDispatch();
   const MySwal = withReactContent(Swal);

   const requestDeleteAccountList = useSelector((state: RootState) => state.requestDeleteAccount.list ?? []);
   const [requestId, setRequestId] = useState<string>("");
   const [showDetail, setShowDetail] = useState<boolean>(false);

   const dateCreated = useSelector((state: RootState) => state.requestDeleteAccount.dateCreated);
   const status = useSelector((state: RootState) => state.requestDeleteAccount.status);
   const error = useSelector((state: RootState) => state.requestDeleteAccount.error ?? ({} as ApiErrorResponse<any>));

   const [filterText, setFilterText] = useState("");
   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

   const filteredItems = requestDeleteAccountList.filter(
      (item) =>
         item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
   );

   useEffect(() => {
      if (status !== undefined && status !== ApiResponseStatus.pending) {
         MySwal.fire(
             savedStatusOption(
                 status === ApiResponseStatus.success,
                 error.data?.message
             )
         ).then(() => {
            dispatch(resetStatus());
         });
      }
   }, [status, error]);

   const onSetterFilterText = (value: string) => {
      setFilterText(value);
   };

   // const onDetailShowHandler = (data: RequestCancelProjectModel) => {
   //    setShowDetail(true);
   //    setSingleWithdraw(data);
   // };

   const onDetailModalToggler = () => {
      setShowDetail((prevState) => !prevState);
      setRequestId("");
   };

   const elementProps = {
      placeholder: "cari berdasarkan nama",
      buttonName: "",
      createUrl: "/master/issue-category/create",
      subHeaderLine: true,
   } as ElementPropModel;

   const dateSort = (rowA: { createdDate: string }, rowB: { createdDate: string }) => {
      const a = rowA.createdDate.split("/").reverse().join("");
      const b = rowB.createdDate.split("/").reverse().join("");

      return a.localeCompare(b);
   };

   const columns: TableColumn<RequestDeleteModel>[] = [
      {
         name: "NO.",
         width: "60px",
         cell: (_d: any, index: number) => index + 1,
      },
      {
         name: "TANGGAL",
         selector: (row) => row.createDate,
         format: (row: any) => moment(row.createDate).format("DD/MM/YYYY"),
         sortable: true,
      },
      {
         name: "NAMA",
         selector: (row) => row.name,
         sortable: true,
      },
      {
         name: "USERNAME",
         selector: (row) => row.username,
         sortable: true,
      },
      {
         name: "TIPE AKUN",
         selector: (row) => row.type,
         format: (row: RequestDeleteModel) => (
             <Fragment>
               <span>
                  {row.type === UserTypeEnum.Admin && "Admin"}
                  {row.type === UserTypeEnum.Mitra && "Mitra"}
                  {row.type === UserTypeEnum.Customer && "Customer"}
               </span>
             </Fragment>
         ),
         sortable: true,
      },
      {
         name: "PROYEK AKTIF",
         selector: (row) => row.countProject,
         sortable: true,
      },
      {
         name: "SALDO",
         selector: (row) => row.totalSaldo,
         format: (row: RequestDeleteModel) => (
             <Fragment>
                <NumberFormat
                    value={row.totalSaldo}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"Rp"}
                />
             </Fragment>
         ),
         sortable: true,
      },
      {
         name: "STATUS",
         cell: (row: RequestDeleteModel) => (
            <Fragment>
               <span>
                  {row.status === RequestDeleteEnum.request && "Request"}
                  {row.status === RequestDeleteEnum.approve && "Approve"}
                  {row.status === RequestDeleteEnum.reject && "Rejected"}
               </span>
            </Fragment>
         ),
         sortable: true,
      },
      {
         name: "AKSI",
         width: "350px",
         cell: (row: RequestDeleteModel) => (
            <>
               {/* <button
                  className="btn button-outline-primary mr-2"
                  onClick={() => onDetailShowHandler(row)}>
                  Preview
               </button> */}

               {row.status !== RequestDeleteEnum.approve && (
                  <button
                     onClick={() => {
                        if (window.confirm("Apakah kamu yakin untuk menkonfimasi pembatalan ini?"))
                           onConfirm(row.id);
                     }}
                     className="btn button-primary">
                     TERIMA
                  </button>
               )}
            </>
         ),
      },
   ];

   // end of datatable components

   useEffect(() => {
      dispatch(getListDeleteRequest());
      dispatch(resetStatus());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      dispatch(getListDeleteRequest());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dateCreated]);

   const onConfirm = (id: string) => {
      dispatch(
          approveDeleteRequest({
            id: id,
            status: RequestDeleteEnum.approve,
         })
      );
   };

   return (
      <>
         <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            subHeader
            subHeaderComponent={DefaultSubHeader(
               filterText,
               resetPaginationToggle,
               onSetterFilterText,
               elementProps
            )}
            customStyles={TableCustomStyles}
            persistTableHead
         />
         {/* <WithdrawDetailModal
            singleWithdraw={singleWithdraw}
            show={showDetail}
            handleClose={onDetailModalToggler}
         /> */}
      </>
   );
};

export default RequestDeleteAccountTable;
