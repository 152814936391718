import React from "react";
import {Row, Col, Form, Badge} from "react-bootstrap";
import {ProfessionalTypeModel} from "../models";

const MitraTypeList = ({
                           isVendor = false,
                           professionalTypeList = [],
                           vendorType = "",
                       }: MitraTypeProps) => {

    return (
        <>
            <h6 className="mb-4 data_diri_tab_body_title d-inline-block">
                Tipe Mitra{" "}
            </h6>
            <Form.Group as={Row} controlId="formPlaintext">
                <Col sm="12">
                    {
                        isVendor ? <Badge pill className="profile-badge mr-2 mb-2">
                            {vendorType}
                        </Badge> : <>
                            {
                                professionalTypeList?.map((item) => (
                                    <Badge pill className="profile-badge mr-2 mb-2" key={item.id}>
                                        {item.name}
                                    </Badge>
                                ))
                            }

                        </>
                    }

                </Col>
            </Form.Group>
        </>
    );
};

export interface MitraTypeProps {
    isVendor: boolean;
    professionalTypeList?: ProfessionalTypeModel[];
    vendorType?: string;
}

export default MitraTypeList;