import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import { checkObjectIsNotEmpty } from "../../../../helpers/checkEmptyObject";
import DiscountForm from "../components/DiscountForm";
import {
   createDiscount,
   getSingleDiscount,
   updateDiscount,
   updateDiscountWithImage,
   resetStatus,
} from "../discount.reducer";
import { DiscountFormModel, VoucherModel } from "../models";
import {ApiErrorResponse} from "../../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";
import {useHistory} from "react-router-dom";

const DiscountDetailPage = () => {
   const MySwal = withReactContent(Swal);
   let history = useHistory();
   const [isEdit, setIsEdit] = useState<boolean>(false);
   let { id } = useParams<{ id: string }>();
   const dispatch = useDispatch();

   const singleDataState = useSelector(
      (state: RootState) => state.discount.single ?? ({} as VoucherModel)
   );
   const status = useSelector((state: RootState) => state.discount.status);
   const error = useSelector((state: RootState) => state.discount.error ?? ({} as ApiErrorResponse<any>));

   useEffect(() => {
      if (status !== undefined && status !== ApiResponseStatus.pending) {
         MySwal.fire(
             savedStatusOption(
                 status === ApiResponseStatus.success,
                 error.data?.message
             )
         ).then(() => {
            dispatch(resetStatus());
            if (status === ApiResponseStatus.success) {
               history.push("/master/discount/list");
            }
         });
      }
   }, [status, error]);

   const onSubmit = (formData: DiscountFormModel) => {
      console.log(formData.image.fromApi);
      if (isEdit) {
         console.log(formData);
         if (formData.image.fileValue === singleDataState.banner) {
            dispatch(updateDiscount(formData as VoucherModel));
         } else {
            dispatch(updateDiscountWithImage(formData));
         }
      } else {
         dispatch(createDiscount(formData));
      }
   };

   useEffect(() => {
      if (checkObjectIsNotEmpty(singleDataState)) {
         setIsEdit(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [singleDataState]);

   useEffect(() => {
      if (id !== undefined) {
         dispatch(getSingleDiscount(id));
         setIsEdit(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id]);

   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <h4 className="card-label">{isEdit ? "Edit" : "Tambah"} - Master Diskon</h4>
               <hr />
               <Row>
                  <Col md={8} xl={5}>
                     <DiscountForm isEdit={isEdit} onSubmit={onSubmit} data={singleDataState} />
                  </Col>
               </Row>
            </Col>
         </Row>
      </Container>
   );
};

export default DiscountDetailPage;
