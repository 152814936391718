import axios from "axios";
import { endpoint } from "../../constants";
import {ApproveDeleteRequest} from "./models";

export default class RequestDeleteAccountApi {
   static getListDeleteRequest() {
      return axios
         .post<any>(endpoint + "/api/User/GetDeleteRequestList")
         .then((res) => res.data.data);
   }

   static approveDeleteRequest(args: ApproveDeleteRequest) {
      return axios
         .post<any>(endpoint + `/api/User/ApprovalDeleteRequest`, args)
         .then((res) => res.data.data);
   }
}
