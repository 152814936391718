import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import {
  UserModel,
  ProfessionalModel,
  VendorModel,
  RevisionLogModel,
  CreateOfficialPartnerModel,
  ChangeSuspendedStatus,
  ChangePasswordAsyncRequest,
  UpdateEmailAndPhoneRequest, GetUserList,
} from "./models";
import DataMitraApi from "./dataMitra.api";
import {OptionModel} from "../../../@core/models/types";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";

export interface CreateDataMitraSlice {
  name?: string;
  userList?: UserModel[];
  rows?: number;
  singleUser?: UserModel;
  professionalSingle?: ProfessionalModel;
  vendorSingle?: VendorModel;
  userOptions?: OptionModel[];
  usernameOptions?: OptionModel[];
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: ApiResponseStatus;
  changePasswordStatus?: ApiResponseStatus;
  changePasswordError?: ApiErrorResponse<any>;
  updateEmailPhoneStatus?: ApiResponseStatus;
  updateEmailPhoneError?: ApiErrorResponse<any>;
  dateChange?: string;
}

export const getUserList = createAsyncThunk(
  "dataMitraState/dataMitraList",
  async (args: GetUserList, { rejectWithValue }) => {
    try {
      return await DataMitraApi.getUserList(args);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getUserSingle = createAsyncThunk(
    "dataMitraState/getUserSingle",
    async (userId: string, { rejectWithValue }) => {
      try {
        return await DataMitraApi.getUserSingle(userId);
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

export const getUserOptionList = createAsyncThunk(
    "dataMitraState/dataMitraOptionList",
    async (args: GetUserList, { rejectWithValue }) => {
      try {
        return await DataMitraApi.getUserList(args);
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

export const getProfessionalSingle = createAsyncThunk(
  "dataMitraState/dataMitraProfessionalSingle",
  async (userId: string, { rejectWithValue }) => {
    try {
      return await DataMitraApi.getProfessionalSingle(userId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getVendorSingle = createAsyncThunk(
  "dataMitraState/dataMitraVendorSingle",
  async (userId: string, { rejectWithValue }) => {
    try {
      return await DataMitraApi.getVendorSingle(userId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const createRevisionLog = createAsyncThunk(
  "dataMitraState/createRevisionLog",
  async (data: RevisionLogModel, { rejectWithValue }) => {
    try {
      return await DataMitraApi.createRevisionLog(data);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const registerOfficialPartner = createAsyncThunk(
    "dataMitraState/registerOfficialPartner",
    async (data: CreateOfficialPartnerModel, { rejectWithValue }) => {
      try {
        return await DataMitraApi.registerOfficialPartner(data);
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

export const changeProfessionalSuspendedStatus = createAsyncThunk(
    "dataMitraState/changeProfessionalSuspendedStatus",
    async (data: ChangeSuspendedStatus, { rejectWithValue }) => {
      try {
        return await DataMitraApi.changeProfessionalSuspendedStatus(data);
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

export const changeVendorSuspendedStatus = createAsyncThunk(
    "dataMitraState/changeVendorSuspendedStatus",
    async (data: ChangeSuspendedStatus, { rejectWithValue }) => {
      try {
        return await DataMitraApi.changeVendorSuspendedStatus(data);
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

export const changePasswordAsync = createAsyncThunk(
    "dataMitraState/changePasswordAsync",
    async (data: ChangePasswordAsyncRequest, { rejectWithValue }) => {
      try {
        return await DataMitraApi.changePasswordAsync(data);
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

export const updateEmailAndPhone = createAsyncThunk(
    "dataMitraState/updateEmailAndPhone",
    async (data: UpdateEmailAndPhoneRequest, { rejectWithValue }) => {
      try {
        return await DataMitraApi.updateEmailAndPhone(data);
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

const createDataMitraSlice = createSlice({
  name: "createDataMitraState",
  initialState: {} as CreateDataMitraSlice,
  reducers: {
    // setFilter: (state, action) => {
    //   state.list = action.payload;
    // },
    setSingleUser: (state, action) => {
      state.singleUser = action.payload;
    },
    resetSingleUser: (state) => {
      state.singleUser = {} as UserModel;
    },
    setEmptyProfessionalSingle: (state) => {
      state.professionalSingle = {} as ProfessionalModel;
      state.error = {} as ApiErrorResponse<any>;
      state.isLoading = false;
    },
    resetStatus: (state) => {
      state.status = ApiResponseStatus.pending;
      state.changePasswordStatus = ApiResponseStatus.pending;
      state.changePasswordError = {} as ApiErrorResponse<any>;
      state.updateEmailPhoneStatus = ApiResponseStatus.pending;
      state.updateEmailPhoneError = {} as ApiErrorResponse<any>;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserList.fulfilled, (state, { payload }) => {
      state.userList = payload.data;
      state.rows = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getUserList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getUserSingle.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserSingle.fulfilled, (state, { payload }) => {
      state.singleUser = payload.data;
      state.isLoading = false;
    });
    builder.addCase(getUserSingle.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getUserOptionList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserOptionList.fulfilled, (state, { payload }) => {
      state.userOptions = payload.data.map((item: UserModel) => {
        return {value: item.id, label: item.name + " - " + item.email}
      });
      state.usernameOptions = payload.data.map((item: UserModel) => {
        return {value: item.id, label: item.username}
      });
      state.isLoading = false;
    });
    builder.addCase(getUserOptionList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getProfessionalSingle.pending, (state) => {
      state.professionalSingle = {} as ProfessionalModel;
      state.error = {} as ApiErrorResponse<any>;
      state.isLoading = true;
    });
    builder.addCase(getProfessionalSingle.fulfilled, (state, { payload }) => {
      state.professionalSingle = payload;
      state.isLoading = false;
    });
    builder.addCase(getProfessionalSingle.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.professionalSingle = {
        id: "false",
        userId: "",
        professionalTypeList: [],
        fullname: "",
        identityNumber: "",
        phoneNumber: "",
        address: "",
        bio: "",
        website: "",
        bankAccount: "",
        bankName: "",
        bankOnBehalf: "",
        isVerified: false,
        username: "",
        aboutMeTitle: "",
        aboutMePage: "",
      };
      state.isLoading = false;
    });
    builder.addCase(getVendorSingle.pending, (state) => {
      state.vendorSingle = {} as VendorModel;
      state.error = {} as ApiErrorResponse<any>;
      state.isLoading = true;
    });
    builder.addCase(getVendorSingle.fulfilled, (state, { payload }) => {
      state.vendorSingle = payload;
      state.isLoading = false;
    });
    builder.addCase(getVendorSingle.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.vendorSingle = {
        id: "false",
        userId: "",
        vendorTypeId: "",
        picName: "",
        picIdentityNumber: "",
        picPhoneNumber: "",
        address: "",
        bio: "",
        website: "",
        city: "",
        bankAccount: "",
        bankName: "",
        bankOnBehalf: "",
        vendorType: "",
        isVerified: false,
        username: "",
        aboutMeTitle: "",
        aboutMePage: "",
        gender: 0,
      };
      state.isLoading = false;
    });
    builder.addCase(createRevisionLog.pending, (state) => {
      state.isLoading = true;
      state.status = ApiResponseStatus.pending;
    });
    builder.addCase(createRevisionLog.fulfilled, (state) => {
      state.status = ApiResponseStatus.success;
      state.isLoading = false;
    });
    builder.addCase(createRevisionLog.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = ApiResponseStatus.failed;
    });
    builder.addCase(registerOfficialPartner.pending, (state) => {
      state.isLoading = true;
      state.status = ApiResponseStatus.pending;
    });
    builder.addCase(registerOfficialPartner.fulfilled, (state) => {
      state.status = ApiResponseStatus.success;
      state.dateChange = new Date().toISOString();
      state.isLoading = false;
    });
    builder.addCase(registerOfficialPartner.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = ApiResponseStatus.failed;
    });
    builder.addCase(changeProfessionalSuspendedStatus.pending, (state) => {
      state.status = ApiResponseStatus.pending;
      state.isLoading = true;
    });
    builder.addCase(changeProfessionalSuspendedStatus.fulfilled, (state, { payload }) => {
      state.status = ApiResponseStatus.success;
      state.isLoading = false;
    });
    builder.addCase(changeProfessionalSuspendedStatus.rejected, (state, { payload }) => {
      state.status = ApiResponseStatus.failed;
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(changeVendorSuspendedStatus.pending, (state) => {
      state.status = ApiResponseStatus.pending;
      state.isLoading = true;
    });
    builder.addCase(changeVendorSuspendedStatus.fulfilled, (state, { payload }) => {
      state.status = ApiResponseStatus.success;
      state.isLoading = false;
    });
    builder.addCase(changeVendorSuspendedStatus.rejected, (state, { payload }) => {
      state.status = ApiResponseStatus.failed;
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(changePasswordAsync.pending, (state) => {
      state.changePasswordStatus = ApiResponseStatus.pending;
      state.isLoading = true;
    });
    builder.addCase(changePasswordAsync.fulfilled, (state, { payload }) => {
      state.changePasswordStatus = ApiResponseStatus.success;
      state.isLoading = false;
    });
    builder.addCase(changePasswordAsync.rejected, (state, { payload }) => {
      state.changePasswordStatus = ApiResponseStatus.failed;
      state.changePasswordError = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateEmailAndPhone.pending, (state) => {
      state.updateEmailPhoneStatus = ApiResponseStatus.pending;
      state.isLoading = true;
    });
    builder.addCase(updateEmailAndPhone.fulfilled, (state, { payload }) => {
      state.updateEmailPhoneStatus = ApiResponseStatus.success;
      state.isLoading = false;
    });
    builder.addCase(updateEmailAndPhone.rejected, (state, { payload }) => {
      state.updateEmailPhoneStatus = ApiResponseStatus.failed;
      state.updateEmailPhoneError = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
  },
});

export const { setSingleUser, resetSingleUser, setEmptyProfessionalSingle, resetStatus } = createDataMitraSlice.actions;
export default createDataMitraSlice.reducer;
