import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import {
  LoginBannerModel,
  CreateLoginBannerImageModel,
  CreateLoginBannerModel,
  UploadImageModel,
} from "./models";
import LoginBannerApi from "./loginBanner.api";

export interface CreateLoginBannerSlice {
  name?: string;
  date?: string;
  list?: LoginBannerModel[];
  single?: LoginBannerModel;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
  uploadImage?: UploadImageModel;
}

export const createLoginBanner = createAsyncThunk(
  "createLoginBannerState/createLoginBanner",
  async (bannerData: CreateLoginBannerModel, { rejectWithValue }) => {
    try {
      return await LoginBannerApi.createLoginBanner(bannerData);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getLoginBannerList = createAsyncThunk(
  "loginBannerState/loginBannerList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await LoginBannerApi.getLoginBannerList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getLoginBannerSingleItem = createAsyncThunk(
  "loginBannerState/loginBannerSingleItem",
  async (bannerId: string, { rejectWithValue }) => {
    try {
      return await LoginBannerApi.getSingleItem(bannerId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateLoginBanner = createAsyncThunk(
  "loginBannerState/updateLoginBanner",
  async (bannerData: LoginBannerModel, { rejectWithValue }) => {
    try {
      return await LoginBannerApi.updateLoginBanner(bannerData);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteLoginBanner = createAsyncThunk(
  "loginBannerState/deleteLoginBanner",
  async (bannerData: LoginBannerModel, { rejectWithValue }) => {
    try {
      return await LoginBannerApi.deleteLoginBanner(bannerData);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const uploadLoginBannerImage = createAsyncThunk(
  "uploadLoginBannerImageState/uploadLoginBannerImage",
  async (imageData: CreateLoginBannerImageModel, { rejectWithValue }) => {
    try {
      const response = await LoginBannerApi.uploadImage(imageData.fileData);
      if (response.status === 200) {
        let formData = imageData.formData;
        formData.url = response.data.imagepath;
        if (imageData.isEdit) {
          return await LoginBannerApi.updateLoginBanner(formData);
        } else {
          return await LoginBannerApi.createLoginBanner(formData);
        }

      }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const createLoginBannerSlice = createSlice({
  name: "createLoginBannerState",
  initialState: {} as CreateLoginBannerSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createLoginBanner.pending, (state) => {
      state.isLoading = true;
      state.status = "create";
    });
    builder.addCase(createLoginBanner.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "";
    });
    builder.addCase(createLoginBanner.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "";
    });
    builder.addCase(getLoginBannerSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getLoginBannerSingleItem.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getLoginBannerSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getLoginBannerList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLoginBannerList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getLoginBannerList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateLoginBanner.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateLoginBanner.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.name = new Date().toISOString();
    });
    builder.addCase(updateLoginBanner.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(deleteLoginBanner.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteLoginBanner.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.date = new Date().toISOString();
    });
    builder.addCase(deleteLoginBanner.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(uploadLoginBannerImage.pending, (state) => {
      state.isLoading = true;
      state.status = "uploaded";
    });
    builder.addCase(uploadLoginBannerImage.fulfilled, (state, { payload }) => {
      state.date = new Date().toISOString();
      state.isLoading = false;
      state.status = "";
    });
    builder.addCase(uploadLoginBannerImage.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";
    });
  },
});

export const { setFilter } = createLoginBannerSlice.actions;
export default createLoginBannerSlice.reducer;
