import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import EventForm from "../components/event/EventForm";
import {useDispatch, useSelector} from "react-redux";
import {getSingleEventItem, reset} from "../marketingEvent.reducer";
import {useHistory, useParams} from "react-router-dom";
import {RootState} from "../../../app/store";
import {ApiErrorResponse} from "../../../index";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../helpers/alertContent";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const EventDetailPage = ({isEdit = false}: EventDetailPageProps) => {
    const dispatch = useDispatch();
    let { id } = useParams<{ id: string }>();
    const MySwal = withReactContent(Swal);
    let history = useHistory();

    const status = useSelector((state: RootState) => state.marketingEvent.status);
    const error = useSelector((state: RootState) => state.marketingEvent.error ?? ({} as ApiErrorResponse<any>));

    useEffect(() => {
        if (isEdit) {
            dispatch(getSingleEventItem(id));
        }
    }, [isEdit, id]);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === ApiResponseStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(reset());
                if (status === ApiResponseStatus.success) {
                    history.push("/mitra/event/list");
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    return (
        <Container className="content-body" fluid>
            <Row>
                <Col md={12}>
                    <h4 className="card-label">Event</h4>
                    <hr/>
                    <EventForm isEdit={isEdit} />
                </Col>
            </Row>
        </Container>
    );
};

export interface EventDetailPageProps {
    isEdit?: boolean;
}

export default EventDetailPage;
