
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import moment from "moment/moment";
import {DataStatus} from "../../../../../../@core/models/types";
import useChatAttachment from "../../../../../../hooks/chat/useChatAttachment";
import {AttachmentType, MessageType} from "../../../../../../@core/models/chat/models";
import {FileUploadModel} from "../../../../models";
import UploadFile from "../../../../../../helpers/Upload/UploadFile";
import {IoIosClose} from "react-icons/io";

const FileAttachmentModal = ({
   showModal = false,
   roomId = "",
   userId = "",
   userName = "",
   ...props
}: FileAttachmentModalProps) => {
   const [selectedFile, setSelectedFile] = useState<FileUploadModel>({} as FileUploadModel);
   const { sendFileAttachment, isLoading, sendStatus } = useChatAttachment();

   const onFileChange = (file: FileUploadModel) => {
      setSelectedFile(file);
   };

   const onSendClicked = () => {
      const _message = {
         content: "",
         fileType: selectedFile.type,
         fileName: selectedFile.fileName,
         createdAt: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSSS[Z]'),
         readById: [],
         senderId: userId,
         senderName: userName,
         messageType: MessageType.file,
      };

      sendFileAttachment(selectedFile, roomId, _message, AttachmentType.file);
   };

   useEffect(() => {
      if (sendStatus === DataStatus.success) {
         props.onModalHide();
         setSelectedFile({} as FileUploadModel);
      }
   }, [sendStatus]);

   return (
      <Modal
         show={showModal}
         backdrop="static"
         keyboard={false}
         size="lg"
         centered
         className="discount_modal">
         <Modal.Body>
            <Row>
               <Col md={12} className={`request_admin_modal_header`}>
                  <h1 className={`d-inline request_admin_modal_title`}>Unggah Berkas</h1>
                  <span
                     className={`material-icons-outlined float-right request_admin_close`}
                     onClick={props.onModalHide}>
                     <IoIosClose size={32} />
                  </span>
               </Col>
               <Col md={12} className={`request_admin_modal_content`}>
                  {isLoading && (
                     <div className={"upload_loading"}>
                        <div className="lds-default">
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                        </div>
                     </div>
                  )}

                  <UploadFile selectedFile={selectedFile} uploadFileResult={onFileChange} />

                  <button className="mt-3 btn button-primary float-right" onClick={onSendClicked}>
                     Kirim
                  </button>
               </Col>
            </Row>
         </Modal.Body>
      </Modal>
   );
};

export interface FileAttachmentModalProps {
   showModal: boolean;
   onModalHide: () => void;
   roomId: string;
   userId: string;
   userName: string;
}

export default FileAttachmentModal;
