import React, {KeyboardEventHandler, useEffect, useState} from 'react';
import CreatableSelect from 'react-select/creatable';

const components = {
    DropdownIndicator: null,
};

interface Option {
    readonly label: string;
    readonly value: string;
}

const createOption = (label: string) => ({
    label,
    value: label,
});

const Keyword = ({value = [], ...props}: KeywordProps) => {
    const [inputValue, setInputValue] = useState<string>("");
    const [valueOption, setValueOption] = useState<Option[]>([]);

    useEffect(() => {
        let _value = value.map((item) => {
            return {
                label: item,
                value: item
            }
        });
        setValueOption(_value);
    }, [value]);


    const handleChange = (
        val: any
    ) => {
       const _value = val.map((item: { value: string }) =>
          item.value
       );
       setValueOption(val);
       // setValue(_value);
       props.getKeywordValue(_value);
    };

    const handleInputChange = (inputValue: string) => {
        setInputValue(inputValue);
    };

    const handleKeyDown: KeyboardEventHandler<any> = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setInputValue('');
                setValueOption([...valueOption, createOption(inputValue)]);
                // setValue([...value, inputValue]);
                props.getKeywordValue([...value, inputValue]);
                event.preventDefault();
        }
    };

    const handleOnBlur = () => {
        if (!inputValue) return;
        setInputValue('');
        setValueOption([...valueOption, createOption(inputValue)]);
        // setValue([...value, inputValue]);
        props.getKeywordValue([...value, inputValue]);
    };

    return (
        <CreatableSelect
            components={components}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleChange}
            onBlur={handleOnBlur}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Type something and press enter..."
            value={valueOption}
        />
    )
}

export interface KeywordProps {
    value: Array<string>;
    getKeywordValue: (value: Array<string>) => void;
}

export default Keyword;