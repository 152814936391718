import React, {useEffect, useState} from 'react';
import {Badge, Col, Form, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {getUserOptionList as getMitraOptionList} from "../../dataMitra/dataMitra/dataMitra.reducer";
import {UserTypeEnum} from "../../dataMitra/dataMitra/models";
import {OptionModel} from "../../../@core/models/types";
import {useHistory} from "react-router-dom";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {BsTrash} from "react-icons/bs";
import ProSquadPortfolioUploadModal from "./ProSquadPortfolioUpload";
import {InputProSquadPortfolioRequest, ProSquadPortfolio} from "../models";
import {getExpertiseChildCategoryList} from "../../master/tagExpertise/tagExpertise.reducer";
import {updatePortfolio, uploadPortfolioFile} from "../proSquadPortfolio.reducer";
import {checkObjectIsNotEmpty} from "../../../helpers/checkEmptyObject";

const ProSquadPortfolioForm = ({isEdit = false}: ProSquadPortfolioFormProps) => {
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const history = useHistory();
    const specialChars = ['\\', '/', ':', '*', '?', '"', '<', '>', '|'];

    const singlePortfolio = useSelector((state: RootState) => state.proSquadPortfolio.single ?? {} as ProSquadPortfolio);

    const expertiseCategoryOptions = useSelector((state: RootState) => state.tagExpertise.expertiseCategoryOptions ?? []);
    const isExpertiseOptionLoading = useSelector((state: RootState) => state.tagExpertise.isLoading);

    const mitraUsernameOptions = useSelector((state: RootState) => state.dataMitra.usernameOptions ?? []);
    const isMitraOptionLoading = useSelector((state: RootState) => state.dataMitra.isLoading);

    const [validated, setValidated] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<InputProSquadPortfolioRequest>({
        mitraUsername: [] as OptionModel[], expertiseList: [] as OptionModel[]
    } as InputProSquadPortfolioRequest);
    const [errors, setErrors] = useState<any>({
        name: '',
        openDate: '',
        closeDate: '',
        mitraUsername: '',
    });

    const [localFiles, setLocalFiles] = useState<Array<File>>([]);
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
    const [selectedFileIndex, setSelectedFileIndex] = useState<number[]>([]);
    const [selectUploadedFileIndex, setSelectUploadedFileIndex] = useState<number[]>([]);
    const [uploadedFile, setUploadedFile] = useState<string[]>([]);


    useEffect(() => {
        dispatch(getExpertiseChildCategoryList());
        dispatch(getMitraOptionList({
            userType: UserTypeEnum.Mitra,
            registerStatus: undefined,
            name: undefined,
            skip: 0,
            take: 100000
        }));
    }, []);

    useEffect(() => {
        if (isEdit && checkObjectIsNotEmpty(singlePortfolio)) {
            setInputValue({
                mitraUsername: [],
                expertiseList: singlePortfolio.expertiseList.map(item => ({
                    value: item.expertiseId,
                    label: item.expertiseName
                })),
                name: singlePortfolio.name,
                description: singlePortfolio.description
            });
            setUploadedFile(singlePortfolio.path);
        }
    }, [isEdit, singlePortfolio]);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        const newErrors = {...errors};
        const form = e.currentTarget;

        const containsSpecialChars = specialChars.some(char => inputValue.name?.includes(char));

        if (containsSpecialChars) {
            e.preventDefault();
            newErrors.name = `Nama tidak boleh mengandung spesial karakter \\ / : * ? " < > |`;
            setErrors(newErrors);
            return null;
        }

        if (!form.checkValidity()) {
            e.stopPropagation();
        } else {
            e.preventDefault();
            const {mitraUsername, ...rest} = inputValue;
            const mitraIds = inputValue.mitraUsername.map(item => item.value).toString();
            const expertiseIds = inputValue.expertiseList.map(item => item.value);

            if (isEdit) {
                if (localFiles.length > 0) {
                    dispatch(uploadPortfolioFile({
                        ...rest,
                        id: singlePortfolio.id,
                        local_files: localFiles,
                        userId: singlePortfolio.mitraId,
                        expertiseList: expertiseIds,
                        isEdit: true,
                        path: singlePortfolio.path,
                        fileType: 1,
                        projectId: null,
                        description: rest.name,
                        uploaded_files: uploadedFile
                    }))
                } else {
                    dispatch(updatePortfolio({
                        ...rest,
                        id: singlePortfolio.id,
                        path: uploadedFile,
                        fileType: 1,
                        expertiseList: expertiseIds,
                        mitraId: singlePortfolio.mitraId,
                        projectId: null,
                        description: rest.name
                    }))
                }
            } else {
                dispatch(uploadPortfolioFile({
                    ...rest,
                    local_files: localFiles,
                    userId: mitraIds,
                    expertiseList: expertiseIds,
                    isEdit: false,
                    path: [],
                    fileType: 1,
                    projectId: null,
                    description: rest.name
                }))
            }
        }
        setValidated(true);
    }

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "name") {
            const containsSpecialChars = specialChars.some(char => e.target.value.includes(char));
            if (containsSpecialChars) {
                const newErrors = {...errors};
                newErrors.name = `Nama tidak boleh mengandung spesial karakter \\ / : * ? " < > |`;
                setErrors(newErrors);
            } else {
                const newErrors = {...errors};
                newErrors.name = "";
                setErrors(newErrors);
            }
        }
        setInputValue((old) => {
            return {
                ...old,
                [e.target.name]: e.target.value
            }
        });
    }

    const onOptionUsernameChangeHandler = (e: OptionModel | null) => {
        let newArr: OptionModel[] = [];
        if (e && !inputValue.mitraUsername.includes(e)) {
            newArr = [...inputValue.mitraUsername, e];
        } else if (e) {
            newArr = inputValue.mitraUsername.filter((option) => option !== e);
        }
        setInputValue({
            ...inputValue,
            mitraUsername: newArr
        });
    };

    const onOptionExpertiseChangeHandler = (e: OptionModel | null) => {
        let newArr: OptionModel[] = [];
        if (e && !inputValue.expertiseList.includes(e)) {
            newArr = [...inputValue.expertiseList, e];
        } else if (e) {
            newArr = inputValue.expertiseList.filter((option) => option !== e);
        }
        setInputValue({
            ...inputValue,
            expertiseList: newArr
        });
    };

    const onUsernameTagCancelHandler = (option: OptionModel) => {
        if (inputValue.mitraUsername.includes(option)) {
            const newArr = inputValue.mitraUsername.filter((a) => a !== option);
            setInputValue({
                ...inputValue,
                mitraUsername: newArr
            });
        }
    };

    const onExpertiseTagCancelHandler = (option: OptionModel) => {
        if (inputValue.expertiseList.includes(option)) {
            const newArr = inputValue.expertiseList.filter((a) => a !== option);
            setInputValue({
                ...inputValue,
                expertiseList: newArr
            });
        }
    };

    const onCancelHandler = () => {
        history.push("/mitra/portfolio/list");
    };

    const MitraTag = () => {
        return (
            <>
                {inputValue.mitraUsername?.map((item: any) => (
                    <Badge pill className="grey-tag mb-2 mx-1 text-left" key={item.value}>
                        <div className="grey-tag-container">
                            <span className="grey-tag-label">{item.label}</span>
                            <span
                                className="material-icons grey-tag-close ml-1"
                                onClick={() => onUsernameTagCancelHandler(item)}
                            >cancel</span>
                        </div>
                    </Badge>
                ))}
            </>
        );
    };

    const ExpertiseTag = () => {
        return (
            <>
                {inputValue.expertiseList?.map((item: any) => (
                    <Badge pill className="grey-tag mb-2 mx-1 text-left" key={item.value}>
                        <div className="grey-tag-container">
                            <span className="grey-tag-label">{item.label}</span>
                            <span
                                className="material-icons grey-tag-close ml-1"
                                onClick={() => onExpertiseTagCancelHandler(item)}
                            >cancel</span>
                        </div>
                    </Badge>
                ))}
            </>
        );
    };

    const onUploadModalClose = () => {
        setShowUploadModal(false);
    };

    const onLocalFileChange = (files: File[]) => {
        setLocalFiles(prevState => [...prevState, ...files]);
    }

    const onFilePickedChange = (index: number) => {
        if (selectedFileIndex.includes(index)) {
            setSelectedFileIndex(prevState => prevState.filter(item => item !== index));
        } else {
            setSelectedFileIndex(prevState => [...prevState, index]);
        }
    }

    const onUploadedFilePickedChange = (index: number) => {
        if (selectUploadedFileIndex.includes(index)) {
            setSelectUploadedFileIndex(prevState => prevState.filter(item => item !== index));
        } else {
            setSelectUploadedFileIndex(prevState => [...prevState, index]);
        }
    }

    const onSelectAllClicked = () => {
        if (selectUploadedFileIndex.length === uploadedFile.length) {
            setSelectUploadedFileIndex([]);
        } else {
            setSelectUploadedFileIndex(uploadedFile.map((_, index) => index));
        }

        if (selectedFileIndex.length === localFiles.length) {
            setSelectedFileIndex([]);
        } else {
            setSelectedFileIndex(localFiles.map((_, index) => index));
        }
    }

    const onDeleteClicked = () => {
        setLocalFiles(prevState => prevState.filter((_, index) => !selectedFileIndex.includes(index)));
        setUploadedFile(prevState => prevState.filter((_, index) => !selectUploadedFileIndex.includes(index)));
        setSelectedFileIndex([]);
        setSelectUploadedFileIndex([]);
    }

    return (
        <Form
            noValidate
            validated={validated}
            onSubmit={onSubmit}>
            <Row>
                <Col md={6} className="mb-3">
                    <Form.Group className="mb-4">
                        <Form.Label>Nama Portfolio</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="Masukkan Nama Portfolio"
                            onChange={onInputChange}
                            value={inputValue.name}
                            required
                        />
                        <small className="text-danger">{errors.name}</small>
                    </Form.Group>

                    {
                        !isEdit && (
                            <Form.Group className="mb-4">
                                <Form.Label>
                                    Username Mitra
                                </Form.Label>

                                <div>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        placeholder="Ketik untuk mencari username mitra"
                                        isSearchable={true}
                                        name="color"
                                        options={mitraUsernameOptions}
                                        isLoading={isMitraOptionLoading}
                                        onChange={onOptionUsernameChangeHandler}
                                        hideSelectedOptions={true}
                                    />
                                    <div className="mt-2"><MitraTag/></div>
                                </div>
                            </Form.Group>
                        )
                    }

                    <Form.Group className="mb-4">
                        <Form.Label>
                            Keahlian
                        </Form.Label>

                        <div>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder="Ketik untuk mencari keahlian"
                                isSearchable={true}
                                name="expertise"
                                options={expertiseCategoryOptions}
                                isLoading={isExpertiseOptionLoading}
                                onChange={onOptionExpertiseChangeHandler}
                                hideSelectedOptions={true}
                            />
                            <div className="mt-2"><ExpertiseTag/></div>
                        </div>
                    </Form.Group>

                </Col>
                <Col
                    md={12}
                >
                    <div className={"d-flex"}>
                        <div className={"d-flex flex-grow-1"}>
                            <Form.Label>
                                Upload Foto
                            </Form.Label>
                        </div>
                        <div>
                            <button className="btn button-primary mx-1" type="button"
                                    onClick={() => setShowUploadModal(true)}>
                                Unggah Foto
                            </button>
                            <button
                                className={`btn button-outline-primary mr-1 mx-md-1 my-1 my-md-0 event_button`}
                                type="button" onClick={onSelectAllClicked}>
                                {selectUploadedFileIndex.length > 0 || selectedFileIndex.length > 0 ? "Batal Pilih Semua" : "Pilih Semua"}
                            </button>
                            <button
                                className={`btn button-outline-danger mr-1 mx-md-1 my-1 my-md-0 px-2 event_button`}
                                type="button" onClick={onDeleteClicked}>
                                <BsTrash fontSize={18}/> Hapus
                            </button>
                        </div>
                    </div>
                    {
                        ( uploadedFile.length === 0 && localFiles.length === 0) && (
                            <Row className={"d-flex justify-content-center"}>
                                <Col md={12} className="text-center">
                                    <img
                                        className={`empty_portfolio_image justify-content-center`}
                                        src="https://aramata.id/assets/images/empty_portfolio.svg"
                                        width="300"
                                    />
                                </Col>
                                <Col md={12} className="text-center mt-4 mb-4">
                                    <h6 className={`empty_title`}>Belum ada gambar yang ditambahkan</h6>
                                </Col>
                            </Row>
                        )
                    }
                    <div
                        className={`d-flex mt-3 result_album_content`}
                    >
                        {
                            isEdit && uploadedFile?.map((item, index) => (
                                <div className={`d-flex flex-column mb-3 result_album_image`}
                                     key={`localfile-${index}`}>
                                    <>
                                        <div className="d-flex">
                                            <div
                                                className={`m-1 position-relative result_media_item`}
                                                style={{
                                                    background: `url("${item}")`,
                                                }}
                                                // onClick={() => onImageClicked(index)}
                                            ></div>
                                            <div
                                                className={`position-absolute media-checkbox`}
                                            >
                                                <input
                                                    type="checkbox"
                                                    className={`custom-control-input`}
                                                    id={
                                                        index.toString()
                                                    }
                                                    checked={selectUploadedFileIndex.includes(index)}
                                                    onChange={() =>
                                                        onUploadedFilePickedChange(index)
                                                    }
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor={
                                                        index.toString()
                                                    }
                                                >
                                                    <div
                                                        className={`check_result_file`}
                                                    ></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="px-2">
                                            <div
                                                className={`text-truncate filename_label`}>{item}</div>
                                        </div>
                                    </>
                                </div>
                            ))
                        }
                        {localFiles.map((item, index) => (
                            <div className={`d-flex flex-column mb-3 result_album_image`}
                                 key={`localfile-${index}`}>
                                <>
                                    <div className="d-flex">
                                        <div
                                            className={`m-1 position-relative result_media_item`}
                                            style={{
                                                background: `url("${URL.createObjectURL(item)}")`,
                                            }}
                                            // onClick={() => onImageClicked(index)}
                                        ></div>
                                        <div
                                            className={`position-absolute media-checkbox`}
                                        >
                                            <input
                                                type="checkbox"
                                                className={`custom-control-input`}
                                                id={
                                                    index.toString()
                                                }
                                                checked={selectedFileIndex.includes(index)}
                                                onChange={() =>
                                                    onFilePickedChange(index)
                                                }
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor={
                                                    index.toString()
                                                }
                                            >
                                                <div
                                                    className={`check_result_file`}
                                                ></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="px-2">
                                        <div
                                            className={`text-truncate filename_label`}>{item.name}</div>
                                    </div>
                                </>
                                <>
                                </>
                            </div>
                        ))}
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mt-5">
                        <button
                            className="btn button-outline-primary mx-1"
                            type="reset"
                            onClick={onCancelHandler}>
                            Batalkan
                        </button>
                        <button className="btn button-primary mx-1" type="submit">
                            Simpan
                        </button>
                    </div>
                </Col>
                <ProSquadPortfolioUploadModal show={showUploadModal} handleClose={onUploadModalClose}
                                              onLocalFileChange={onLocalFileChange}/>
            </Row>
        </Form>
    );
};

export interface ProSquadPortfolioFormProps {
    isEdit?: boolean;
}

export default ProSquadPortfolioForm;
