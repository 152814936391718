import React, {useEffect, useState} from 'react';
import {Row, Container, Col} from "react-bootstrap";
import {BsArrowRightCircle} from "react-icons/bs";
import DataProjectTable from "../components/DataProjectTable";
import {useDispatch, useSelector} from "react-redux";
import {getTotalProject, setActiveTag} from "../dataProject.reducer";
import {RootState} from "../../../app/store";
import {ProjectStatusEnum, TotalProject} from "../models";

const DataProjectPage = () => {
    const dispatch = useDispatch();

    const totalProject = useSelector((state: RootState) => state.project.totalProject ?? {} as TotalProject);

    useEffect(() => {
        dispatch(getTotalProject());
    }, []);

    const onTabChangeHandler = (key: ProjectStatusEnum) => {
        dispatch(setActiveTag(key));
    }

    return (
        <Container className="content-body" fluid>
            <Row>
                <Col lg={3} xs={6} className="mb-3">
                    <div className="card-counter card-warning-color text-light">
                        <div className="flex-grow-1 p-3">
                            <div className="card-counter-value">
                                <h1 className="mb-0">{totalProject.totalPrepare}</h1>
                            </div>
                            <div className="card-counter-label">
                                <span>Mempersiapkan</span>
                            </div>
                        </div>
                        <div style={{cursor: "pointer"}} className="p-2" onClick={() => onTabChangeHandler(ProjectStatusEnum.Prepare)}>
                            <BsArrowRightCircle size={18}/>
                        </div>
                    </div>
                </Col>
                <Col lg={3} xs={6} className="mb-3">
                    <div className="card-counter card-info-color text-light">
                        <div className="flex-grow-1 p-3">
                            <div className="card-counter-value">
                                <h1 className="mb-0">{totalProject.totalActive}</h1>
                            </div>
                            <div className="card-counter-label">
                                <span>Sedang Berjalan</span>
                            </div>
                        </div>
                        <div  style={{cursor: "pointer"}} className="p-2"  onClick={() => onTabChangeHandler(ProjectStatusEnum.Active)}>
                            <BsArrowRightCircle size={18}/>
                        </div>
                    </div>
                </Col>
                <Col lg={3} xs={6} className="mb-3">
                    <div className="card-counter card-success-color text-light">
                        <div className="flex-grow-1 p-3">
                            <div className="card-counter-value">
                                <h1 className="mb-0">{totalProject.totalComplete}</h1>
                            </div>
                            <div className="card-counter-label">
                                <span>Selesai</span>
                            </div>
                        </div>
                        <div style={{cursor: "pointer"}} className="p-2" onClick={() => onTabChangeHandler(ProjectStatusEnum.Complete)}>
                            <BsArrowRightCircle size={18}/>
                        </div>
                    </div>
                </Col>
                <Col lg={3} xs={6} className="mb-3">
                    <div className="card-counter card-danger-color text-light">
                        <div className="flex-grow-1 p-3">
                            <div className="card-counter-value">
                                <h1 className="mb-0">{totalProject.totalCancel}</h1>
                            </div>
                            <div className="card-counter-label">
                                <span>Batal</span>
                            </div>
                        </div>
                        <div style={{cursor: "pointer"}} className="p-2" onClick={() => onTabChangeHandler(ProjectStatusEnum.Cancel)}>
                            <BsArrowRightCircle size={18}/>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className={"my-3"}>
                <Col md={12} className="mb-3">
                    <h4 className="card-label d-inline">Data Proyek</h4>
                </Col>
                <Col md={12}>
                    <DataProjectTable />
                </Col>
            </Row>
        </Container>
    );
};

export default DataProjectPage;
