import React, { Fragment } from "react";
import Moment from "react-moment";

const TextMessage = ({ content = "", createdAt = "" }: TextMessageProps) => {
   return (
      <Fragment>
         <div className={`message_list message_out`}>
            <div className={`message_list_item message_out`}>
               <div className={`message_box`}>
                  <span className={"message_text"}>{content}</span>
                  <span className={`message_time float-right`}>
                     <Moment format="HH:mm" withTitle>
                        {createdAt}
                     </Moment>
                  </span>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export interface TextMessageProps {
   content: string;
   createdAt: string;
}

export default TextMessage;
