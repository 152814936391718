import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import { ApiResponseStatus } from "../../@core/models/apiResponseStatus/apiResponseStatus";
import { RootState } from "../../app/store";
import { AddProfileChatModel, RequestAdminModel, RequestAdminStatus } from "./models";
import RequestAdminApi from "./requestAdmin.api";

export interface RequestAdminSlice {
   list?: RequestAdminModel[];
   single?: RequestAdminModel;
   isLoading?: boolean;
   dateCreated?: string;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
}

export const getRequestAdminList = createAsyncThunk(
   "requestAdminState/requestAdminList",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await RequestAdminApi.getRequestAdminList();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getRequestAdminSingleItem = createAsyncThunk(
   "requestAdminState/requestAdminSingleItem",
   async (issueId: string, { rejectWithValue }) => {
      try {
         return await RequestAdminApi.getRequestAdminSingleItem(issueId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const deleteRequestAdmin = createAsyncThunk(
   "requestAdminState/deleteRequestAdmin",
   async (data: RequestAdminModel, { rejectWithValue }) => {
      try {
         return await RequestAdminApi.deleteRequestAdmin(data);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const addAdminToChatRoom = createAsyncThunk(
   "requestAdminState/addAdminToChatRoom",
   async (data: AddProfileChatModel, { getState, rejectWithValue }) => {
      const { token } = (getState() as RootState).user;
      try {
         const _addAdminToChat = await RequestAdminApi.addAdminToChat(data.roomId, data.personal);
         if (_addAdminToChat.status) {
            return await RequestAdminApi.updateRequestAdmin({
               id: data.requestAdmin.id,
               roomId: data.roomId,
               projectId: data.requestAdmin.projectId,
               projectName: data.requestAdmin.projectName,
               reason: data.requestAdmin.reason,
               status: RequestAdminStatus.Accept,
            });
         } else {
            return await RequestAdminApi.updateRequestAdmin({} as RequestAdminModel);
         }
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const requestAdminSlice = createSlice({
   name: "createIssueState",
   initialState: {} as RequestAdminSlice,
   reducers: {
      setFilter: (state, action) => {
         state.list = action.payload;
      },
      resetSingle: (state) => {
         state.single = {} as RequestAdminModel;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(getRequestAdminSingleItem.pending, (state) => {
         state.single = undefined;
         state.isLoading = true;
      });
      builder.addCase(getRequestAdminSingleItem.fulfilled, (state, { payload }) => {
         state.single = payload;
         state.isLoading = false;
      });
      builder.addCase(getRequestAdminSingleItem.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(getRequestAdminList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getRequestAdminList.fulfilled, (state, { payload }) => {
         state.list = payload;
         state.isLoading = false;
      });
      builder.addCase(getRequestAdminList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(deleteRequestAdmin.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(deleteRequestAdmin.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.dateCreated = new Date().toISOString();
      });
      builder.addCase(deleteRequestAdmin.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
      });

      builder.addCase(addAdminToChatRoom.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(addAdminToChatRoom.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.dateCreated = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(addAdminToChatRoom.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
   },
});

export const { setFilter, resetSingle, resetStatus } = requestAdminSlice.actions;
export default requestAdminSlice.reducer;
