import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { CreateBlogCategoryModel, BlogCategoryModel } from "./models";
import BlogCategoryApi from "./blogCategory.api";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";

export interface CreateBlogCategorySlice {
   name?: string;
   list?: BlogCategoryModel[];
   single?: BlogCategoryModel;
   isLoading?: boolean;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
}

export const createBlogCategory = createAsyncThunk(
   "createBlogCategoryState/createBlogCategory",
   async (data: CreateBlogCategoryModel, { rejectWithValue }) => {
      try {
         return await BlogCategoryApi.createBlogCategory(data);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getBlogCategoryList = createAsyncThunk(
   "blogCategoryState/blogCategoryList",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await BlogCategoryApi.getBlogCategoryList();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getBlogCategorySingleItem = createAsyncThunk(
   "blogCategoryState/blogCategorySingleItem",
   async (blogId: string, { rejectWithValue }) => {
      try {
         return await BlogCategoryApi.getCategorySingleItem(blogId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const updateBlogCategory = createAsyncThunk(
   "blogCategoryState/updateBlogCategory",
   async (data: BlogCategoryModel, { rejectWithValue }) => {
      try {
         return await BlogCategoryApi.updateBlogCategory(data);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const deleteBlogCategory = createAsyncThunk(
   "blogCategoryState/deleteBlogCategory",
   async (data: BlogCategoryModel, { rejectWithValue }) => {
      try {
         return await BlogCategoryApi.deleteBlogCategory(data);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const createBlogCategorySlice = createSlice({
   name: "createBlogState",
   initialState: {} as CreateBlogCategorySlice,
   reducers: {
      setFilter: (state, action) => {
         state.list = action.payload;
      },
      resetSingle: (state) => {
         state.single = {} as BlogCategoryModel;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(createBlogCategory.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(createBlogCategory.fulfilled, (state) => {
         state.name = new Date().toISOString();
         state.isLoading = false;
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(createBlogCategory.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(getBlogCategorySingleItem.pending, (state) => {
         state.single = undefined;
         state.isLoading = true;
      });
      builder.addCase(getBlogCategorySingleItem.fulfilled, (state, { payload }) => {
         state.single = payload;
         state.isLoading = false;
      });
      builder.addCase(getBlogCategorySingleItem.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(getBlogCategoryList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getBlogCategoryList.fulfilled, (state, { payload }) => {
         state.list = payload;
         state.isLoading = false;
      });
      builder.addCase(getBlogCategoryList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(updateBlogCategory.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(updateBlogCategory.fulfilled, (state) => {
         state.isLoading = false;
         state.name = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(updateBlogCategory.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(deleteBlogCategory.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(deleteBlogCategory.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.name = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(deleteBlogCategory.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
   },
});

export const { setFilter, resetSingle, resetStatus } = createBlogCategorySlice.actions;
export default createBlogCategorySlice.reducer;
