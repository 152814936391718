import { createSlice } from "@reduxjs/toolkit";
import { MessageModel, RoomModel, UserModel } from "./models";
import db from "../../utils/firebaseInit";
import { UploadImageModel } from "../media/fileUpload/models";
import ChatApi from "./chat.api";
import {GroupMessageModel} from "../../@core/models/chat/models";
import moment from "moment";
const messageRef = db.collection("DIALOG_MESSAGES");

export interface ChatSlice {
   rooms?: RoomModel[];
   singleRoom?: RoomModel;
   messages?: MessageModel[];
   groupMessage?: GroupMessageModel[];
   isLoading?: boolean;
   dateCreated?: string;
   error?: any;
   status?: any;
}

export const getAllGroupRoom = () => async (dispatch: (arg0: any) => void) => {
   dispatch(fetchRoomPending());
   messageRef.where("roomName", "!=", "").onSnapshot({
      next: (querySnapshot) => {
         const updatedMessages = querySnapshot.docs.map((docSnapshot) => {
            return { ...docSnapshot.data(), id: docSnapshot.id };
         });
         dispatch(fetchRoomFulfilled(updatedMessages as any));
      },
      error: (err) => dispatch(fetchRoomError(err)),
   });
};

// get room chat by userId
export const getRooms = (userId: string) => async (dispatch: (arg0: any) => void) => {
   dispatch(fetchRoomPending());
   messageRef.where("userId", "array-contains", userId).onSnapshot({
      next: (querySnapshot) => {
         const updatedMessages = querySnapshot.docs.map((docSnapshot) => {
            return { ...docSnapshot.data(), id: docSnapshot.id };
         });
         dispatch(fetchRoomFulfilled(updatedMessages as any));
      },
      error: (err) => dispatch(fetchRoomError(err)),
   });
};

// get single room
export const getSingleRoom = (uid: string) => async (dispatch: (arg0: any) => void) => {
   dispatch(fetchSingleRoomPending());
   messageRef.doc(uid).onSnapshot({
      next: (querySnapshot) => {
         dispatch(fetchSingleRoomFulfilled(querySnapshot.data() as any));
      },
      error: (err) => dispatch(fetchSingleRoomError(err)),
   });
};

// get message list orderBy createdAt
export const getMessages = (uid: string) => async (dispatch: (arg0: any) => void) => {
   dispatch(fetchMessagePending());
   messageRef
       .doc(uid)
       .collection("MESSAGES")
       .orderBy("createdAt", "asc")
       .onSnapshot({
          next: (querySnapshot) => {
             const updatedMessages = querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
             dispatch(fetchMessageFulfilled(updatedMessages as any));
          },
          error: (err) => dispatch(fetchMessageError(err as any)),
       });
};

export const getGroupMessages = (uid: string) => async (dispatch: (arg0: any) => void) => {
   dispatch(fetchGroupMessagePending());
   messageRef
      .doc(uid)
      .collection("MESSAGES")
      .orderBy("createdAt", "asc")
      .onSnapshot({
         next: (querySnapshot) => {

            const updatedMessages = querySnapshot.docs.map((docSnapshot) =>
                docSnapshot.data()
            );
            // Grouping message by date
            // this gives an object with dates as keys
            const groups = updatedMessages.reduce((groups, message) => {
               const date = moment(message.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY');
               if (!groups[date]) {
                  groups[date] = [];
               }
               groups[date].push(message);
               return groups;
            }, {});

            // Edit: to add it in the array format instead
            const groupArrays = Object.keys(groups).map((date) => {
               return {
                  date,
                  messages: groups[date]
               };
            });
            groupArrays.sort(function (a, b) {
               return (
                   new Date(b.messages.createdAt).getTime() -
                   new Date(a.messages.createdAt).getTime()
               );
            });
            dispatch(fetchGroupMessageFulfilled(groupArrays as any));
         },
         error: (err) => dispatch(fetchGroupMessageError(err as any)),
      });
};

// send message and update last message
export const sendMessage =
   (uid: string, message: MessageModel) => async (dispatch: (arg0: any) => void) => {
      dispatch(sendMessagePending());
      messageRef
         .doc(uid)
         .collection("MESSAGES")
         .add(message)
         .then(() => dispatch(sendMessageFulfilled()))
         .catch((err) => sendMessageError(err as any));
      messageRef
         .doc(uid)
         .update({
            lastMessage: message.content,
            lastTimeMessage: new Date().toISOString(),
         })
         .then(() => console.log("success"));
   };

export const readMessage =
   (uid: string, userId: string) => async (dispatch: (arg0: any) => void) => {
      messageRef
         .doc(uid)
         .get()
         .then((querySnapshot) => {
            let _users: UserModel[] = querySnapshot.data()?.users;

            const _filteredUsers = _users.map((user) => {
               const _user = user;
               if (user.userId === userId) {
                  _user.unread = 0;
                  return _user;
               } else {
                  return _user;
               }
            });
            messageRef
               .doc(uid)
               .update({
                  users: _filteredUsers,
               })
               .then(() => console.log("success"));
         });
   };

export const uploadImageFunction = async ({
                                             file_name,
                                             base64url,
                                             imagepath,
                                             location,
                                          }: UploadImageModel) => {
   let result = {} as any;
   const response: any = await ChatApi.uploadImage({
      file_name: file_name,
      base64url: base64url,
      imagepath: imagepath,
      location: location,
   } as UploadImageModel);
   if (response.status === 200) {
      result.status = "success";
      result.data = response.data;
   } else {
      result.status = "failed";
      result.data = response.data;
   }
   return result;
};

const chatSlice = createSlice({
   name: "chatState",
   initialState: {} as ChatSlice,
   reducers: {
      fetchRoomPending: (state) => {
         state.isLoading = true;
      },
      fetchRoomFulfilled: (state, action) => {
         state.rooms = action.payload;
         state.isLoading = false;
      },
      fetchRoomError: (state, action) => {
         state.error = action.payload;
         state.isLoading = false;
      },
      fetchSingleRoomPending: (state) => {
         state.isLoading = true;
      },
      fetchSingleRoomFulfilled: (state, action) => {
         state.singleRoom = action.payload;
         state.isLoading = false;
      },
      fetchSingleRoomError: (state, action) => {
         state.error = action.payload;
         state.isLoading = false;
      },
      fetchMessagePending: (state) => {
         state.isLoading = true;
      },
      fetchMessageFulfilled: (state, action) => {
         state.messages = action.payload;
         state.isLoading = false;
      },
      fetchMessageError: (state, action) => {
         state.error = action.payload;
         state.isLoading = false;
      },
      fetchGroupMessagePending: (state) => {
         state.isLoading = true;
      },
      fetchGroupMessageFulfilled: (state, action) => {
         state.groupMessage = action.payload;
         state.isLoading = false;
      },
      fetchGroupMessageError: (state, action) => {
         state.error = action.payload;
         state.isLoading = false;
      },
      sendMessagePending: (state) => {
         state.isLoading = true;
      },
      sendMessageFulfilled: (state) => {
         state.isLoading = false;
      },
      sendMessageError: (state, action) => {
         state.error = action.payload;
         state.isLoading = false;
      },
   },
});

export const {
   fetchRoomPending,
   fetchRoomFulfilled,
   fetchRoomError,
   fetchSingleRoomPending,
   fetchSingleRoomFulfilled,
   fetchSingleRoomError,
   fetchMessagePending,
   fetchMessageFulfilled,
   fetchMessageError,
   fetchGroupMessagePending,
   fetchGroupMessageFulfilled,
   fetchGroupMessageError,
   sendMessagePending,
   sendMessageFulfilled,
   sendMessageError,
} = chatSlice.actions;
export default chatSlice.reducer;
