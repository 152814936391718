import React, {Fragment, useEffect} from 'react';
import "../../../assets/styles/project.css";
import {Alert, Badge, Col, Container, Dropdown, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {ProjectDetailModel} from "../models";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faEllipsisH, faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import ActivityCard from "./ActivityCard";
import iconNote from "../../../assets/icons/icon_note.svg";
import {getActivityByProject, getSingleProject} from "../dataProject.reducer";
import {useParams} from "react-router-dom";
const DetailDataProject = () => {
    const dispatch = useDispatch();

    let { id } = useParams<{ id: string }>();

    const singleProject = useSelector((state: RootState) => state.project.singleItem ?? {} as ProjectDetailModel);
    const activityList = useSelector((state: RootState) => state.project.activityList ?? []);

    const isMobile = window.innerWidth <= 768;

    const isPaidProject = false;

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getSingleProject(id));
        }
    }, [id]);


    useEffect(() => {
        if (singleProject.id !== undefined) {
            console.log("singleProject.id", singleProject.id);
            dispatch(getActivityByProject(singleProject.id));
        }
    }, [singleProject]);

    const refreshData = () => {
        dispatch(getActivityByProject(singleProject.id));
    };

    return (
        <Container className={`create_project_container p-4 p-md-5`}>
            <Row>
                <Col md={12}>
                    <h1 className={`d-inline-block project_vendors_title`}>
                        Project {singleProject.name}
                    </h1>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={12} className="mb-3">
                    {!singleProject.isExpired && singleProject.isTodayExpired && (
                        <Badge
                            pill
                            className="badge-tag badge-sm mr-2 mb-2"
                        >
                            Sedang Berlangsung
                        </Badge>
                    )}
                    <Badge
                        pill
                        className="badge-tag mr-1 badge-sm"
                    >
                        {singleProject.expertiseTypeName}
                    </Badge>
                    <Badge
                        pill
                        className="secondary-badge-tag badge-sm mr-2 mb-2"
                    >
                        {activityList.length} Kegiatan
                    </Badge>
                    <small className={`project_activity_small_label ${isMobile ? "my-1" : ""}`}>
                        <FontAwesomeIcon
                            icon={faCalendarAlt}
                            width="12"
                            className="mr-2"
                        />{' '}
                        <Moment
                            date={new Date(singleProject.activityDate)}
                            format="DD-MMM-YYYY"
                        />
                    </small>
                    <small className={`project_activity_small_label ${isMobile ? "my-1" : "ml-3"}`}>
                        {' '}
                        <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            width="12"
                            className="mr-2"
                        />
                        {singleProject.location}
                    </small>
                </Col>
                <Col md={12} className="mb-3">
                    <p className={`project_vendors_description`}>
                        {singleProject.description}
                    </p>
                </Col>
                {
                    singleProject.note !== null && (
                        <Col md={12} className="mb-3">
                            <Alert
                                variant="light"
                                className={"project_note_containers"}
                            >
                                <Alert.Heading className="mb-0">
                                    <img
                                        src={iconNote}
                                        alt="project_calendar_icon"
                                        className="d-inline-block"
                                        width="14px"
                                    />
                                    <h2
                                        className={`d-inline-block ml-2 detail_activity_title`}
                                    >
                                        Catatan Proyek
                                    </h2>
                                </Alert.Heading>
                                <p className={`project_activity_small_label mb-0`}>
                                    {singleProject.note}
                                </p>
                            </Alert>
                        </Col>
                    )
                }
                {/*<Col md={12} className="mb-3">*/}
                {/*   <img*/}
                {/*      src="/assets/icons/project-calendar-icon.svg"*/}
                {/*      alt="project_calendar_icon"*/}
                {/*      className="d-inline-block"*/}
                {/*   />*/}
                {/*   <h2*/}
                {/*      className={`d-inline-block ml-2 ${styles.detail_activity_title}`}*/}
                {/*   >*/}
                {/*      Kegiatan*/}
                {/*   </h2>*/}
                {/*</Col>*/}
                <Col md={12} className="pl-4 pr-4">
                    {activityList?.map((activity) => (
                        <Fragment key={activity.id}>
                            <ActivityCard
                                activity={activity}
                                expertiseId={singleProject.expertiseTypeId}
                                refreshData={refreshData}
                                projectStatus={singleProject.status}
                                projectId={singleProject.id}
                                isPaidProject={isPaidProject}
                            />
                        </Fragment>
                    ))}
                </Col>

                {/* <Col md={12} className="pl-4 pr-4 text-center">
               <button onClick={onAddToCartClicked} className="btn button-primary">
                  Lanjut ke Keranjang
               </button>
            </Col> */}
            </Row>
        </Container>
    );
};

export default DetailDataProject;
