export interface PenaltyCancellationFee {
   id: string;
   type: number;
   value: string;
}

export interface CustomerCancellationFeeModel {
   id: string;
   type: SettingValueEnum;
   value: number;
   mitraPercentage: number;
   adminPercentage: number;
}

export interface InputCustomerCancellationFee {
   id: string;
   type: SettingValueEnum;
   value: string;
   mitraPercentage: string;
   adminPercentage: string;
}

export enum SettingValueEnum {
   nol,satu, dua, tiga, empat
}

export interface PartnerPointModel {
   id: string;
   partnerLevelId: string;
   partnerLevel: string;
   point: number;
}

export interface PartnerLevelModel {
   id: string;
   name: string;
   description: string;
   order: number;
   minimumPoint: number;
   maximumPoint: number;
   logo: string;
   color: string;
}

export enum MitraTypeEnum {
   Mitra, OfficialPartner
}

export interface MitraFeeModel {
   id: string,
   mitraPercentage: number,
   adminPercentage: number,
   type: MitraTypeEnum
}

export enum SettingResultEnum
{
   UploadHasilAwal,
   UploadRevisi1,
   UploadRevisi2,
   UploadHasilAkhir,
   SelesaiLayanan
}

export interface UpdateResultLimitModel {
   id: string;
   timeLimit: number;
   reminder: number;
}

export interface ResultLimitModel {
   id: string;
   type: SettingResultEnum,
   name: string;
   timeLimit: number;
   reminder: number;
}