import axios from "axios";
import { endpoint } from "../../../constants";
import { CreatePaymentMethod, PaymentMethodModel, UpdatePaymentMethodModel } from "./models";

export default class PaymentMethodApi {
   static createPaymentMethod(token: string, voucherData: CreatePaymentMethod) {
      return axios
         .post<any>(endpoint + "/api/PaymentChannel/Create", voucherData)
         .then((res) => res.data.data);
   }

   static getPaymentMethodList(token: string) {
      return axios.get<any>(endpoint + "/api/PaymentChannel/GetList").then((res) => res.data.data);
   }

   static getPaymentMethodSingleItem(token: string, voucherId: string) {
      return axios
         .get<any>(endpoint + `/api/PaymentChannel/GetSingleItem?id=${voucherId}`)
         .then((res) => res.data.data);
   }

   static updatePaymentMethod(token: string, voucherData: UpdatePaymentMethodModel) {
      return axios
         .post<any>(endpoint + "/api/PaymentChannel/Update", voucherData)
         .then((res) => res.data.data);
   }

   static deletePaymentMethod(token: string, voucherId: string) {
      return axios
         .post<any>(endpoint + `/api/PaymentChannel/Delete?id=${voucherId}`)
         .then((res) => res.data);
   }
}
