import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Image } from "react-bootstrap";
import { RootState } from "../../../../app/store";
import {
  ProfessionalModel,
  PersonalAttachmentModel,
  VendorAttachmentModel, Gender,
} from "../models";
import { useSelector } from "react-redux";
import { getPersonalAttachment } from "../dataDiri.reducer";
import { useDispatch } from "react-redux";
import {UserModel, VendorModel} from "../../dataMitra/models";
import { endpoint } from "../../../../constants";
import UpdateBasicAccountModal from "../../dataMitra/components/UpdateBasicAccountModal";

const DataVendorList = (props: { personalData: any }) => {
  const dispatch = useDispatch();
  const singleUser = useSelector(
      (state: RootState) =>
          state.dataMitra.singleUser ?? ({} as UserModel)
  );
  const vendorState = useSelector(
    (state: RootState) => state.dataDiri.vendorSingle ?? ({} as VendorModel)
  );
  const vendorAttachmentState = useSelector(
    (state: RootState) => state.dataDiri.vendorAttachment ?? []
  );

  const [ktpAttachment, setKtpAttachment] = useState(
    {} as VendorAttachmentModel
  );
  const [selfieAttachment, setSelfieAttachment] = useState(
    {} as VendorAttachmentModel
  );
  const [isModalUpdateShow, setIsModalUpdateShow] = useState(false);

  useEffect(() => {
    if (vendorAttachmentState.length !== 0) {
      const ktp =
        (vendorAttachmentState.find(
          (item) => item.type === 0
        ) as VendorAttachmentModel) ?? ({} as VendorAttachmentModel);
      const selfie =
        (vendorAttachmentState.find(
          (item) => item.type === 1
        ) as VendorAttachmentModel) ?? ({} as VendorAttachmentModel);
      setKtpAttachment(ktp);
      setSelfieAttachment(selfie);
    }
  }, [vendorAttachmentState]);

  const onImageClicked = (path: string) => {
    const win = window.open(endpoint + path, "_blank") as Window;
    win.focus();
  };

  const handleModalUpdate = () => {
    setIsModalUpdateShow(prevState => !prevState);
  };

  return (
    <>
      <h6 className="mb-4 mt-4 data_diri_tab_body_title d-inline-block">
        Data Pribadi{" "}
      </h6>
      <Form.Group as={Row} controlId="formPlaintext">
        <Form.Label column sm="12" className="field_plaint_text">
          E-Mail
        </Form.Label>
        <Col sm="12" className="d-flex">
          <Form.Control
              readOnly
              defaultValue={singleUser.email}
              className="field_plaint_text"
          />
          <button
              className="btn button-primary mx-2"
              onClick={handleModalUpdate}
          >
            Ubah
          </button>
        </Col>
        <Col sm="12">
          <hr className="tab_body_underline"/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formPlaintext">
        <Form.Label column sm="12" className="field_plaint_text">
          Username
        </Form.Label>
        <Col sm="12">
          <Form.Control
              readOnly
              defaultValue={singleUser.username}
              className="field_plaint_text"
          />
        </Col>
        <Col sm="12">
          <hr className="tab_body_underline"/>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formPlaintext">
        <Form.Label column sm="12" className="field_plaint_text">
          Nama Lengkap PIC Sesuai KTP
        </Form.Label>
        {vendorState.picName !== "null" && (
          <Col sm="12">
            <Form.Control
              plaintext
              readOnly
              defaultValue={vendorState.picName}
              className="field_plaint_text"
            />
          </Col>
        )}
        <Col sm="12">
          <hr className="tab_body_underline" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formPlaintext">
        <Form.Label column sm="12" className="field_plaint_text">
          Jenis Kelamin
        </Form.Label>
        <Col sm="12">
          <Form.Control
              readOnly
              value={vendorState.gender === Gender.Pria ? "Pria" : "Wanita"}
              className="field_plaint_text"
          />
        </Col>
        <Col sm="12">
          <hr className="tab_body_underline" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formPlaintext">
        <Form.Label column sm="12" className="field_plaint_text">
          Nomor KTP PIC
        </Form.Label>
        {vendorState.picIdentityNumber !== "null" && (
          <Col sm="12">
            <Form.Control
              plaintext
              readOnly
              defaultValue={vendorState.picIdentityNumber}
              className="field_plaint_text"
            />
          </Col>
        )}
        <Col sm="12">
          <hr className="tab_body_underline" />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formPlaintext">
        <Col md="6" className="mb-3">
          <Form.Label>Foto KTP</Form.Label>
          <div
            className="file-upload-frame w-100"
            onClick={() =>
              onImageClicked(
                ktpAttachment.path !== undefined ? ktpAttachment.path : ""
              )
            }
          >
            <Image
              src={
                ktpAttachment.path !== undefined
                  ? endpoint + ktpAttachment.path
                  : ""
              }
              className="file-upload-image"
            />
          </div>
        </Col>
        <Col md="6" className="mb-3">
          <Form.Label>Foto Selfie dengan KTP</Form.Label>
          <div
            className="file-upload-frame w-100"
            onClick={() => onImageClicked(selfieAttachment.path ?? "")}
          >
            <Image
              src={
                selfieAttachment.path !== undefined
                  ? endpoint + selfieAttachment.path
                  : ""
              }
              className="file-upload-image"
            />
          </div>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formPlaintext">
        <Form.Label column sm="12" className="field_plaint_text">
          Nomor Handphone PIC
        </Form.Label>
        {vendorState.picPhoneNumber !== "null" && (
          <Col sm="12" className="d-flex">
            <Form.Control
              plaintext
              readOnly
              defaultValue={vendorState.picPhoneNumber}
              className="field_plaint_text"
            />
            <button
                className="btn button-primary mx-2"
                onClick={handleModalUpdate}
            >
              Ubah
            </button>
          </Col>
        )}
        <Col sm="12">
          <hr className="tab_body_underline" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formPlaintext">
        <Form.Label column sm="12" className="field_plaint_text">
          No. Rekening PIC
        </Form.Label>
        {vendorState.bankAccount !== "null" && (
          <Col sm="12">
            <Form.Control
              plaintext
              readOnly
              defaultValue={vendorState.bankAccount}
              className="field_plaint_text"
            />
          </Col>
        )}
        <Col sm="12">
          <hr className="tab_body_underline" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formPlaintext">
        <Form.Label column sm="12" className="field_plaint_text">
          Alamat Sesuai KTP PIC
        </Form.Label>
        {vendorState.address !== "null" && (
          <Col sm="12">
            <Form.Control
              as="textarea"
              rows={2}
              plaintext
              readOnly
              defaultValue={vendorState.address}
              className="field_plaint_text"
            />
          </Col>
        )}
        <Col sm="12">
          <hr className="tab_body_underline" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formPlaintext">
        <Form.Label column sm="12" className="field_plaint_text">
          Kota
        </Form.Label>
        {vendorState.city !== "null" && (
          <Col sm="12">
            <Form.Control
              plaintext
              readOnly
              defaultValue={vendorState.city}
              className="field_plaint_text"
              style={{ resize: "none" }}
            />
          </Col>
        )}
        <Col sm="12">
          <hr className="tab_body_underline" />
        </Col>
      </Form.Group>
      <UpdateBasicAccountModal
          isShow={isModalUpdateShow}
          onCloseHandler={handleModalUpdate}
          data={
            {
              userId: singleUser.id,
              email: singleUser.email,
              phoneNumber: vendorState.picPhoneNumber,
            }
          }
      />
    </>
  );
};

export default DataVendorList;
