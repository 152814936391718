import React, { useEffect, useState } from "react";
import BannerForm from "../components/BannerForm";
import { Container, Row, Col } from "react-bootstrap";
import {
   createBanner,
   deleteBanner,
   getBannerSingleItem,
   updateBanner,
   uploadBannerImage,
} from "../banner.reducer";
import {
   BannerModel,
   CreateBannerImageModel,
   CreateBannerModel,
   UploadImageModel,
} from "../models";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { useParams } from "react-router-dom";

const BannerDetailPage = () => {
   const dispatch = useDispatch();
   let { id } = useParams<{ id: string }>();

   const [isEdit, setIsEdit] = useState<boolean>(false);

   const uploadImageState = useSelector(
      (state: RootState) => state.banner.uploadImage ?? ({} as UploadImageModel)
   );

   const [formData, setFormData] = useState({
      title: "",
      subTitle: "",
   });

   useEffect(() => {
      if (id !== undefined) {
         dispatch(getBannerSingleItem(id));
         setIsEdit(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id]);

   //   Function
   const onSubmit = (
      id: string,
      defaultBanner: string,
      title: string,
      subTitle: string,
      linkBanner: string,
      bannerFile: string,
      fileFormat: string,
      blogId: string | null,
      isShowBeranda: boolean,
        isShowHome: boolean,
   ) => {
      setFormData({ title: title, subTitle: subTitle });
      if (isEdit) {
         if (bannerFile !== "") {
            dispatch(
               uploadBannerImage({
                  fileData: {
                     file_name: "banner" + "." + fileFormat,
                     base64url: (bannerFile as string).split(",")[1],
                     imagepath: "banner",
                     location: "banner",
                  },
                  formData: {
                     id: id,
                     title: title,
                     subTitle: subTitle,
                     url: "",
                     linkBanner: linkBanner,
                     isShowBeranda: isShowBeranda,
                     isShowHome: isShowHome
                  },
                  isEdit,
               } as CreateBannerImageModel)
            );
         } else {
            dispatch(
               updateBanner({
                  id: id,
                  title: title,
                  subTitle: subTitle,
                  url: defaultBanner,
                  linkBanner: linkBanner,
                  blogId: blogId,
                  isShowBeranda: isShowBeranda,
                  isShowHome: isShowHome
               })
            );
         }
      } else {
         dispatch(
            uploadBannerImage({
               fileData: {
                  file_name: "banner" + "." + fileFormat,
                  base64url: (bannerFile as string).split(",")[1],
                  imagepath: "banner",
                  location: "banner",
               },
               formData: {
                  title: title,
                  subTitle: subTitle,
                  url: "",
                  linkBanner: linkBanner,
                  blogId: blogId,
                  isShowBeranda: isShowBeranda,
                  isShowHome: isShowHome
               },
               isEdit,
            } as CreateBannerImageModel)
         );
      }
   };

   return (
      <>
         <Container className="content-body" fluid>
            <Row>
               <Col md={12}>
                  <h4 className="card-label">
                     {isEdit ? "Edit" : "Tambah"} - Banner Beranda
                  </h4>
                  <hr />
                  <Row>
                     <Col md={8} xl={5}>
                        <BannerForm onSubmit={onSubmit} isEdit={isEdit} />
                     </Col>
                  </Row>
               </Col>
            </Row>
         </Container>
      </>
   );
};

export default BannerDetailPage;
