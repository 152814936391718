import React, {useEffect} from "react";
import {Container, Row, Col, Carousel, Badge} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {RootState} from "../../../../app/store";
import {PackageModel} from "../models";
import {
    getSinglePackage,
    getPackageAttachment,
    getPackageDetail,
    getPackageInclusion,
} from "../product.reducer";
import NumberFormat from "react-number-format";
import {faArrowCircleLeft, faStar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getMediaEndpoint} from "../../../../helpers/imageManipulation";

const PackageDetail = () => {
    const dispatch = useDispatch();
    let {id} = useParams<{ id: string }>();
    const history = useHistory();

    const singlePackageState = useSelector(
        (state: RootState) => state.product.singlePackage ?? ({} as PackageModel)
    );

    const packageAttachment = useSelector(
        (state: RootState) => state.product.packageAttachment ?? []
    );

    const singlePackageDetailState = useSelector(
        (state: RootState) => state.product.packageDetail ?? []
    );

    const singlePackageInclusionState = useSelector(
        (state: RootState) => state.product.packageInclusion ?? []
    );

    const productState = useSelector(
        (state: RootState) => state.product.productList ?? []
    );

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getSinglePackage(id));
            dispatch(getPackageAttachment(id));
            dispatch(getPackageDetail(id));
            dispatch(getPackageInclusion(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <Container className="content-body">
            <Row>
                <Col md={12}>
                    <h5 className="mb-3"><FontAwesomeIcon icon={faArrowCircleLeft} color={'#692f90'} className='mr-3'
                                                          style={{cursor: 'pointer'}}
                                                          onClick={() => history.push(`/mitra/account/personal-data/detail/${singlePackageState.userId}?tab=product`)}/> Product
                        / Jasa</h5>
                    <Row>
                        <Col md={12} className="product_box">
                            <Row>
                                <Col md={6} className="product_picture">
                                    <Carousel>
                                        {packageAttachment.map((item) => {
                                            return (
                                                <Carousel.Item>
                                                    <img
                                                        className="d-block w-100"
                                                        src={getMediaEndpoint(item.path)}
                                                        alt="First slide"
                                                    />
                                                </Carousel.Item>
                                            );
                                        })}
                                    </Carousel>
                                </Col>
                                <Col md={6} className="product_content">
                                    {/* <p className="product_detail_category_text">
                {singleExpertiseState.name}{" "}
              </p> */}
                                    <h1 className="product_detail_title_text">
                                        {singlePackageState.name}
                                    </h1>

                                    <Row>
                                        <Col md={12} className="product_content pt-0">
                                            <div>
                                                <Badge pill className="expertise-tag mr-2 mb-2">
                                                    {singlePackageState?.baseType === 0 && "Produk"}
                                                    {singlePackageState?.baseType === 1 && "Berbasis Waktu"}
                                                    {singlePackageState?.baseType === 2 && "Berbasis Jumlah"}
                                                </Badge>
                                                <Badge pill className="expertise-tag mr-2 mb-2">
                                                    {singlePackageState?.expertiseName}
                                                </Badge>
                                                <Badge pill className="expertise-tag mr-2 mb-2">
                                                    {singlePackageState?.mitraTypeName}
                                                </Badge>
                                            </div>
                                            <div className="d-block"><span
                                                className="customer_product_rate mr-2"><FontAwesomeIcon
                                                icon={faStar}
                                                color="#F1B613"
                                                width="16px"
                                            />{" "}{singlePackageState.totalRating?.toFixed(2)}</span><span
                                                className="customer_product_rate_boundary mr-2">{" "}|{" "}</span>
                                                <span
                                                    className="customer_product_rate">{singlePackageState.totalReview}</span>
                                                <span
                                                    className="customer_product_rate_subtitle mr-2">{" "}Penilaian</span>
                                                <span className="customer_product_rate_boundary mr-2">{" "}|{" "}</span>
                                                <span
                                                    className="customer_product_rate">{singlePackageState.totalSold}</span>
                                                <span
                                                    className="customer_product_rate_subtitle mr-2">{" "}Terjual</span>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <p className="product_detail_pricelabel_text">
                                                {singlePackageState.baseType === 0 &&
                                                    "Harga per produk"}
                                                {singlePackageState.baseType === 1 && "Harga per jam"}
                                                {singlePackageState.baseType === 2 &&
                                                    "Harga per quantity"}
                                            </p>
                                        </Col>

                                        <Col md={6} className="product_detail_price_text">
                                            {singlePackageDetailState.map((itemPrice) => {
                                                return (
                                                    <p>
                                                        {itemPrice.startValue} - {itemPrice.endValue}{" "}
                                                        <NumberFormat
                                                            value={itemPrice.price}
                                                            displayType={"text"}
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"Rp. "}
                                                        />
                                                    </p>
                                                );
                                            })}
                                        </Col>
                                    </Row>
                                    <Row className="mt-4 mb-4">
                                        <Col md={4}>
                                            <p className="product_detail_pricelabel_text">
                                                Inclusion
                                            </p>
                                        </Col>
                                        <Col md={8} className="product_detail_price_text">
                                            {singlePackageInclusionState.map((itemInclusion) => {
                                                {
                                                    return (
                                                        <>
                                                            {productState.map((itemProduct) => {
                                                                if (
                                                                    itemInclusion.productId === itemProduct.id
                                                                ) {
                                                                    return (
                                                                        <Badge className="product_detail_badge mr-2">
                                                                            {itemProduct.name}
                                                                        </Badge>
                                                                    );
                                                                }
                                                            })}
                                                        </>
                                                    );
                                                }
                                            })}
                                        </Col>
                                    </Row>
                                    <p className="product_detail_pricelabel_text">Deskripsi</p>
                                    <p className="product_detail_price_text">
                                        {singlePackageState.description}
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default PackageDetail;
