import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import PaymentMethodForm from "../components/PaymentMethodForm";
import { PaymentMethodFormModel, PaymentMethodModel, UpdatePaymentMethodModel } from "../models";
import {
   createPaymentMethod,
   getSinglePaymentMethod, resetStatus,
   updatePaymentMethod,
   updatePaymentMethodWithImage,
} from "../paymentMethod.reducer";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiErrorResponse} from "../../../../index";
import {useHistory} from "react-router-dom";

const PaymentMethodDetailPage = () => {
   const MySwal = withReactContent(Swal);
   const [isEdit, setIsEdit] = useState<boolean>(false);
   let { id } = useParams<{ id: string }>();
   const dispatch = useDispatch();
   let history = useHistory();

   const singleDataState = useSelector(
      (state: RootState) => state.paymentMethod.single ?? ({} as PaymentMethodModel)
   );
   const status = useSelector((state: RootState) => state.paymentMethod.status);
   const error = useSelector((state: RootState) => state.paymentMethod.error ?? ({} as ApiErrorResponse<any>));

   useEffect(() => {
      if (status !== undefined && status !== ApiResponseStatus.pending) {
         MySwal.fire(
             savedStatusOption(
                 status === ApiResponseStatus.success,
                 error.data?.message
             )
         ).then(() => {
            dispatch(resetStatus());
            if (status === ApiResponseStatus.success) {
               history.push("/master/payment-method/list");
            }
         });
      }
   }, [status, error]);

   const onSubmit = (formData: PaymentMethodFormModel) => {
      console.log(formData.image.fromApi);
      if (isEdit) {
         if (formData.image.fileValue === singleDataState.media) {
            dispatch(updatePaymentMethod(formData as UpdatePaymentMethodModel));
         } else {
            dispatch(updatePaymentMethodWithImage(formData));
         }
      } else {
         dispatch(createPaymentMethod(formData));
      }
   };

   useEffect(() => {
      if (id !== undefined) {
         dispatch(getSinglePaymentMethod(id));
         setIsEdit(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id]);

   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <h4 className="card-label">
                  {isEdit ? "Edit" : "Tambah"} - Master Metode Pembayaran
               </h4>
               <hr />
               <Row>
                  <Col md={8} xl={5}>
                     <PaymentMethodForm isEdit={isEdit} onSubmit={onSubmit} data={singleDataState} />
                  </Col>
               </Row>
            </Col>
         </Row>
      </Container>
   );
};

export default PaymentMethodDetailPage;
