export interface UserModel {
   name: string;
   isAdminVerified: boolean;
   id: string;
   email: string;
   emailConfirmed: boolean;
   username: string;
   submitDate: string;
   createdDate: string;
   registerStatus: number;
   isActive: boolean;
   isSuspended: boolean;
   professionalId: string;
   vendorId: string;
}

export interface ProfessionalModel {
   id: string;
   userId: string;
   professionalTypeList: any[];
   fullname: string;
   identityNumber: string;
   phoneNumber: string;
   address: string;
   bio: string;
   website: string;
   bankAccount: string;
   bankName: string;
   bankOnBehalf: string;
   isVerified: boolean;
   username: string;
   aboutMeTitle: string;
   aboutMePage: string;
}

export interface VendorModel {
   id: string;
   userId: string;
   vendorTypeId: string;
   vendorType: string;
   picName: string;
   picIdentityNumber: string;
   picPhoneNumber: string;
   address: string;
   city: string;
   bio: string;
   website: string;
   bankAccount: string;
   bankName: string;
   bankOnBehalf: string;
   isVerified: boolean;
   username: string;
   aboutMeTitle: string;
   aboutMePage: string;
   gender: Gender;
}

export enum Gender {
   Pria, Wanita
}

export interface RevisionLogModel {
   mitraId: string;
   type: number;
   status: number;
   revisionStatus: Array<string>;
   description: string;
}

export interface CreateOfficialPartnerModel {
   name: string;
   email: string;
   userName: string;
   password: string;
   confirmPassword: string;
}

export interface ChangeSuspendedStatus {
   id: string;
   isSuspended: boolean;
}
export interface ChangePasswordAsyncRequest {
   id: string;
   password: string;
}

export interface UpdateEmailAndPhoneRequest {
   userId: string;
   phoneNumber: string;
   email: string;
}

export enum UserTypeEnum
{
   Mitra,
   Customer,
   Admin
}

export enum RegistrationStatus
{
Pending,
Submit,
Verified,
Revision,
All
}

export interface GetUserList {
   userType?: UserTypeEnum;
   registerStatus?: RegistrationStatus;
   name?: string;
   skip: number;
   take: number;
}