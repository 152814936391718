import React, { Fragment, useEffect, useState } from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../app/store";
import DefaultSubHeader from "../../../components/DataTable/DefaultSubHeader";
import { ElementPropModel } from "../../../components/DataTable/models";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import { WithdrawModel, WithdrawSaldoStatusEnum } from "../models";
import {
   changeWithdrawSaldoStatus,
   getListAllWithdrawSaldo,
   resetStatus,
} from "../withdraw.reducer";
import WithdrawDetailModal from "./WithdrawDetailModal";
import {BannerModel} from "../../customer/banner/models";

const WithdrawTable = () => {
   const dispatch = useDispatch();

   const withdrawList = useSelector((state: RootState) => state.withdraw.list ?? []);
   const [requestId, setRequestId] = useState<string>("");
   const [showDetail, setShowDetail] = useState<boolean>(false);

   const dateCreated = useSelector((state: RootState) => state.withdraw.dateCreated);
   const [singleWithdraw, setSingleWithdraw] = useState<WithdrawModel>({} as WithdrawModel);

   const [filterText, setFilterText] = useState("");
   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

   const filteredItems = withdrawList.filter(
      (item) =>
         (item.customerName &&
            item.customerName.toLowerCase().includes(filterText.toLowerCase())) ||
         (item.professionalName &&
            item.professionalName.toLowerCase().includes(filterText.toLowerCase())) ||
         (item.vendorName &&
            item.vendorName.toLowerCase().includes(filterText.toLowerCase()))
   );

   const onSetterFilterText = (value: string) => {
      setFilterText(value);
   };

   const onDetailShowHandler = (data: WithdrawModel) => {
      setShowDetail(true);
      setSingleWithdraw(data);
   };

   const onDetailModalToggler = () => {
      setShowDetail((prevState) => !prevState);
      setRequestId("");
   };

   const onCloseAction = () => {
      setShowDetail(false);
      setRequestId("");
   }

   const elementProps = {
      placeholder: "cari berdasarkan nama",
      buttonName: "",
      createUrl: "/master/issue-category/create",
      subHeaderLine: true,
   } as ElementPropModel;

   const dateSort = (rowA: { createdDate: string }, rowB: { createdDate: string }) => {
      const a = rowA.createdDate.split("/").reverse().join("");
      const b = rowB.createdDate.split("/").reverse().join("");

      return a.localeCompare(b);
   };

   const columns: TableColumn<WithdrawModel>[] = [
      {
         name: "NO.",
         width: "60px",
         cell: (_d: any, index: number) => index + 1,
      },
      {
         name: "NAMA",
         cell: (row: WithdrawModel) => (
            <Fragment>
               <span>
                  {row.customerName !== null ? row.customerName : ""}
                  {row.vendorName !== null ? row.vendorName : ""}
                  {row.professionalName !== null ? row.professionalName : ""}
               </span>
            </Fragment>
         ),
         sortable: true,
      },
      {
         name: "NOMINAL",
         cell: (row: WithdrawModel) => (
            <Fragment>
               <NumberFormat
                  value={row.value}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp"}
               />
            </Fragment>
         ),
      },
      {
         name: "TIPE AKUN",
         cell: (row: WithdrawModel) => (
            <Fragment>
               <span>
                  {row.customerId !== null && "Customer"}
                  {row.vendorId !== null && "Mitra - Vendor"}
                  {row.professionalId !== null && "Mitra - Professional"}
               </span>
            </Fragment>
         ),
         sortable: true,
      },
      {
         name: "STATUS",
         cell: (row: WithdrawModel) => (
            <Fragment>
               <span>
                  {row.status === WithdrawSaldoStatusEnum.request && "Request"}
                  {row.status === WithdrawSaldoStatusEnum.approve && "Approve"}
                  {row.status === WithdrawSaldoStatusEnum.reject && "Rejected"}
               </span>
            </Fragment>
         ),
         sortable: true,
      },
      {
         name: "TANGGAL PERMINTAAN",
         selector: (row) => row.createdDateFormatted,
         sortable: true,
         sortFunction: dateSort,
      },
      {
         name: "TANGGAL PERSETUJUAN",
         selector: (row) => row.approveDateFormatted,
         sortable: true,
         sortFunction: dateSort,
      },
      {
         name: "AKSI",
         width: "350px",
         cell: (row: WithdrawModel) => (
            <>
               <button
                  className="btn button-outline-primary mr-2"
                  onClick={() => onDetailShowHandler(row)}>
                  PRATINJAU
               </button>

               {row.status !== WithdrawSaldoStatusEnum.approve && (
                  <button
                     onClick={() => {
                        if (window.confirm("Apakah kamu yakin untuk menkonfimasi pembayaran ini?"))
                           onConfirm(row.id);
                     }}
                     className="btn button-primary">
                     TERIMA
                  </button>
               )}
            </>
         ),
      },
   ];

   // end of datatable components

   useEffect(() => {
      dispatch(getListAllWithdrawSaldo());
      dispatch(resetStatus());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      dispatch(getListAllWithdrawSaldo());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dateCreated]);

   const onConfirm = (id: string) => {
      dispatch(
         changeWithdrawSaldoStatus({
            withdrawSaldoId: id,
            status: WithdrawSaldoStatusEnum.approve,
         })
      );
   };

   return (
      <>
         <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            subHeader
            subHeaderComponent={DefaultSubHeader(
               filterText,
               resetPaginationToggle,
               onSetterFilterText,
               elementProps
            )}
            customStyles={TableCustomStyles}
            persistTableHead
         />
         <WithdrawDetailModal
            singleWithdraw={singleWithdraw}
            show={showDetail}
            handleClose={onDetailModalToggler}
            onCloseAction={onCloseAction}
         />
      </>
   );
};

export default WithdrawTable;
