import axios from "axios";
import { endpoint } from "../../../constants";
import { CreateVoucherModel, VoucherModel } from "./models";

export default class DiscountApi {
   static createDiscount(token: string, voucherData: CreateVoucherModel) {
      return axios
         .post<any>(endpoint + "/api/Voucher/Create", voucherData)
         .then((res) => res.data.data);
   }

   static getDiscountList(token: string) {
      return axios.get<any>(endpoint + "/api/Voucher/GetList").then((res) => res.data.data);
   }

   static getDiscountSingleItem(token: string, voucherId: string) {
      return axios
         .get<any>(endpoint + `/api/Voucher/GetSingleItem?id=${voucherId}`)
         .then((res) => res.data.data);
   }

   static updateDiscount(token: string, voucherData: VoucherModel) {
      return axios
         .post<any>(endpoint + "/api/Voucher/Update", voucherData)
         .then((res) => res.data.data);
   }

   static deleteDiscount(token: string, voucherId: string) {
      return axios
         .post<any>(endpoint + `/api/Voucher/Delete?id=${voucherId}`)
         .then((res) => res.data);
   }
}
