import axios from "axios";
import { ChangeWithdrawStatus, WithdrawSaldoStatusEnum } from "./models";
import { endpoint } from "../../constants";

export default class WithdrawApi {
   static getListAllWithdrawSaldo() {
      return axios
         .get<any>(endpoint + "/api/ResultReview/GetListAllWithdrawSaldo")
         .then((res) => res.data.data);
   }

   static changeWithdrawSaldoStatus(changeWithdraw: ChangeWithdrawStatus) {
      return axios
         .post<any>(
            endpoint +
               `/api/ResultReview/ChangeWithdrawSaldoStatus?withdrawSaldoId=${changeWithdraw.withdrawSaldoId}&status=${changeWithdraw.status}`
         )
         .then((res) => res.data.data);
   }
}
