import {Col, Container, Row} from "react-bootstrap";
import React, {Fragment, useEffect, useState} from "react";
import NumberFormat from "react-number-format";
import "../adminBalance.css";
import {CreateWithdrawModel, DateFilterFormat, SaldoType} from "../models";
import DatePicker from "react-datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {getListSaldoHistory, getTotalSaldo} from "../adminBalance.reducer";
import "react-datepicker/dist/react-datepicker.css";

const AdminBalancePage = () => {
    const dispatch = useDispatch();

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [showWithdrawModal, setShowWithdrawModal] = useState<boolean>(false);
    const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
    const [showWithdrawProcessModal, setShowWithdrawProcessModal] = useState<boolean>(false);

    const [filterType, setFilterType] = useState<SaldoType>(SaldoType.None);
    const [withdraw, setWithdraw] = useState<CreateWithdrawModel>({} as CreateWithdrawModel);

    const toggleWithdrawModal = () => {
        setShowWithdrawModal((prevState) => !prevState);
    };

    const balanceTotal = useSelector((state: RootState) => state.adminBalance.balanceTotal);
    const balanceHistory = useSelector((state: RootState) => state.adminBalance.balanceHistory ?? []);

    const openWithdrawModal = () => {
        setShowWithdrawModal(true);
    }

    useEffect(() => {
        dispatch(getListSaldoHistory(
            {
                mitraId: "e9a0b82b-4c79-4476-846b-aed4e64c3943",
                saldoType: SaldoType.None,
                startDate: null,
                endDate: null
            }
        ))
        dispatch(getTotalSaldo());
    }, []);

    const changeDateTime = (startDate: Date, endDate: Date) => {
        let _startDate = new Date(startDate);
        let _endDate = new Date(endDate);
        _startDate.setHours(0);
        _startDate.setMinutes(1);
        _startDate.setSeconds(1);
        _endDate.setHours(23);
        _endDate.setMinutes(59);
        _endDate.setSeconds(59);
        return {startDate: _startDate, endDate: _endDate};
    };

    const onFilterByTypeClicked = (type: SaldoType) => {
        setFilterType(type);
        let dateFilter = {} as DateFilterFormat;
        if (startDate !== null && endDate !== null) {
            dateFilter = changeDateTime(startDate ?? new Date(), endDate ?? new Date());
        }

        dispatch(getListSaldoHistory(
            {
                mitraId: "e9a0b82b-4c79-4476-846b-aed4e64c3943",
                saldoType: type,
                startDate: startDate !== null ? (dateFilter.startDate as Date).toISOString() : startDate,
                endDate: endDate !== null ? (dateFilter.endDate as Date).toISOString() : endDate
            }
        ));

        // getListSaldoHistory(
        //     customerId,
        //     type,
        //     startDate !== null ? (dateFilter.startDate as Date).toISOString() : startDate,
        //     endDate !== null ? (dateFilter.endDate as Date).toISOString() : endDate
        // );
    };

    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            let dateFilter: DateFilterFormat;
            dateFilter = changeDateTime(startDate ?? new Date(), endDate ?? new Date());
            dispatch(getListSaldoHistory(
                {
                    mitraId: "e9a0b82b-4c79-4476-846b-aed4e64c3943",
                    saldoType: filterType,
                    startDate: (dateFilter.startDate as Date).toISOString(),
                    endDate: (dateFilter.endDate as Date).toISOString()
                }
            ))
        }
    }, [startDate, endDate]);

    return (
        <Container className="content-body" style={{backgroundColor: '#f4f5fa', minHeight: '100vh'}} fluid>
            <Row>
                <Fragment>
                    <Container className="mt-3 mb-3">
                        <Row>
                            <Col md={12}>
                                <h1 className={`d-inline balance_title`}>Detail Saldo</h1>
                            </Col>
                        </Row>
                    </Container>
                </Fragment>
            </Row>
            <Container className={`create_project_container p-3`}>
                <Row>
                    <Col md={12}>
                        <div className="d-flex">
                            <img
                                src="https://aramata.id/assets/icons/balance_icon.svg"
                                alt="project_calendar_icon"
                                className="d-inline-block"
                                width="48"
                            />
                            <div className="ml-3">
                                <span className={"active_balance_label"}>Total Saldo Aktif</span>
                                <h1 className={"active_balance"}>
                                    <NumberFormat
                                        value={balanceTotal}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={"Rp"}
                                    />
                                </h1>
                            </div>
                            {/*<div className={"withdraw_button_container"}>*/}
                            {/*    <button*/}
                            {/*        onClick={openWithdrawModal}*/}
                            {/*        className="btn button-primary float-right">*/}
                            {/*        Tarik Saldo*/}
                            {/*    </button>*/}
                            {/*</div>*/}
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className={`create_project_container p-3`}>
                <Row>
                    <Col md={12}>
                        <h1 className={`d-inline balance_title`}>Riwayat Saldo</h1>
                        <hr/>
                    </Col>
                    <Col md={12} className="mb-5">
                        <button
                            className={`btn ${
                                filterType === SaldoType.None ? "button-pill-primary" : "button-pill-outline-secondary"
                            } mr-2`}
                            onClick={() => onFilterByTypeClicked(SaldoType.None)}>
                            Semua
                        </button>
                        <button
                            className={`btn ${
                                filterType === SaldoType.In ? "button-pill-primary" : "button-pill-outline-secondary"
                            } mr-2`}
                            onClick={() => onFilterByTypeClicked(SaldoType.In)}>
                            Saldo Masuk
                        </button>
                        <button
                            className={`btn ${
                                filterType === SaldoType.Out ? "button-pill-primary" : "button-pill-outline-secondary"
                            } mr-2`}
                            onClick={() => onFilterByTypeClicked(SaldoType.Out)}>
                            Saldo Keluar
                        </button>
                        <div className="d-inline-block">
                            <div className="position-relative">
                                <DatePicker
                                    className="form-control pl-5"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update as any);
                                    }}
                                />
                                <FontAwesomeIcon
                                    icon={faCalendarAlt}
                                    width="18"
                                    style={{
                                        position: "absolute",
                                        top: "8px",
                                        color: "#F79F24",
                                        left: "10px",
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={12}>
                        {balanceHistory.length === 0 && (
                            <Row>
                                <Col md={12} className="text-center">
                                    <img
                                        className={`empty_portfolio_image justify-content-center`}
                                        src="https://aramata.id/assets/images/empty_portfolio.svg"
                                        width="300"
                                    />
                                </Col>
                                <Col md={12} className="text-center mt-4 mb-4">
                                    <h6 className={`empty_title`}>Tidak ada riwayat saldo</h6>
                                </Col>
                            </Row>
                        )}
                        {balanceHistory.map((item) => (
                            <div className={`d-flex mb-3 balance_history_list`} key={item.createdDate}>
                                <div>
                                    <h2 className={`balance_history_title`}>
                                        {item.type !== SaldoType.Request &&
                                            item.type !== SaldoType.Reject && (
                                                <Fragment>
                                                    {item.packageName !== null && item.packageName !== ""
                                                        ? item.packageName
                                                        : item.productName !== null && item.productName !== ""
                                                            ? item.productName
                                                            : "Pemasukan Lainnya"}
                                                </Fragment>
                                            )}

                                        {item.type === SaldoType.Request && "Memproses Permintaan Withdraw"}
                                    </h2>
                                    <span className={`balance_history_date`}>
                              <Moment format="DD MMMM YYYY HH:mm">{item.createdDate}</Moment>
                           </span>
                                </div>
                                <div className={`balance_history_nominal_container`}>
                                    <h2
                                        className={
                                            item.type === SaldoType.In
                                                ? "balance_history_nominal_positive"
                                                : item.type === SaldoType.Out
                                                    ? "balance_history_nominal_negative"
                                                    : "balance_history_nominal_other"
                                        }>
                                        <NumberFormat
                                            value={item.value}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix={"Rp"}
                                        />
                                    </h2>
                                </div>
                            </div>
                        ))}
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default AdminBalancePage;