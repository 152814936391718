import React, { useEffect, useState } from "react";
import LoginBannerForm from "../components/LoginBannerForm";
import { Container, Row, Col } from "react-bootstrap";
import {
   createLoginBanner,
   deleteLoginBanner,
   getLoginBannerSingleItem,
   updateLoginBanner,
   uploadLoginBannerImage,
} from "../loginBanner.reducer";
import {
   LoginBannerModel,
   CreateLoginBannerImageModel,
   CreateLoginBannerModel,
   UploadImageModel,
} from "../models";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { useParams } from "react-router-dom";

const LoginBannerDetailPage = () => {
   const dispatch = useDispatch();
   let { id } = useParams<{ id: string }>();

   const [isEdit, setIsEdit] = useState<boolean>(false);

   const uploadImageState = useSelector(
      (state: RootState) => state.loginBanner.uploadImage ?? ({} as UploadImageModel)
   );

   const [formData, setFormData] = useState({
      title: "",
      subTitle: "",
   });

   useEffect(() => {
      if (id !== undefined) {
         dispatch(getLoginBannerSingleItem(id));
         setIsEdit(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id]);

   //   Function
   const onSubmit = (
      id: string,
      defaultBanner: string,
      title: string,
      subTitle: string,
      bannerFile: string,
      fileFormat: string
   ) => {
      setFormData({ title: title, subTitle: subTitle });
      if (isEdit) {
         if (bannerFile !== "") {
            dispatch(
               uploadLoginBannerImage({
                  fileData: {
                     file_name: "banner" + "." + fileFormat,
                     base64url: (bannerFile as string).split(",")[1],
                     imagepath: "loginBanner",
                     location: "loginBanner",
                  },
                  formData: {
                     id: id,
                     title: title,
                     subTitle: subTitle,
                     url: "",
                  },
                  isEdit,
               } as CreateLoginBannerImageModel)
            );
         } else {
            dispatch(
               updateLoginBanner({
                  id: id,
                  title: title,
                  subTitle: subTitle,
                  url: defaultBanner,
               })
            );
         }
      } else {
         dispatch(
            uploadLoginBannerImage({
               fileData: {
                  file_name: "banner" + "." + fileFormat,
                  base64url: (bannerFile as string).split(",")[1],
                  imagepath: "loginBanner",
                  location: "loginBanner",
               },
               formData: {
                  title: title,
                  subTitle: subTitle,
                  url: "",
               },
               isEdit,
            } as CreateLoginBannerImageModel)
         );
      }
   };

   return (
      <>
         <Container className="content-body" fluid>
            <Row>
               <Col md={12}>
                  <h4 className="card-label">
                     {isEdit ? "Edit" : "Tambah"} Banner Login Mitra
                  </h4>
                  <hr />
                  <Row>
                     <Col md={8} xl={5}>
                        <LoginBannerForm onSubmit={onSubmit} isEdit={isEdit} />
                     </Col>
                  </Row>
               </Col>
            </Row>
         </Container>
      </>
   );
};

export default LoginBannerDetailPage;
