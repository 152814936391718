import React, { useEffect, useState } from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import DefaultSubHeader from "../../../components/DataTable/DefaultSubHeader";
import { ElementPropModel } from "../../../components/DataTable/models";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import { CustomerCancelProjectModel } from "../models";
import { getList, resetStatus } from "../customerCancelProject.reducer";
import CustomerCancelProjectModal from "./CustomerCancelProjectModal";

const CustomerCancelProjectTable = () => {
   const dispatch = useDispatch();

   const customerCancelProjectState = useSelector((state: RootState) => state.customerCancelProject.list ?? []);
   const [singleData, setSingleData] = useState({} as CustomerCancelProjectModel);
   const [showDetail, setShowDetail] = useState<boolean>(false);

   const dateCreated = useSelector((state: RootState) => state.mitraCancelProject.dateCreated);
   // const [singleWithdraw, setSingleWithdraw] = useState<RequestCancelProjectModel>({} as RequestCancelProjectModel);

   const [filterText, setFilterText] = useState("");
   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

   const filteredItems = customerCancelProjectState.filter(
      (item) =>
         item.projectName && item.projectName.toLowerCase().includes(filterText.toLowerCase())
   );

   const onSetterFilterText = (value: string) => {
      setFilterText(value);
   };

   // const onDetailShowHandler = (data: RequestCancelProjectModel) => {
   //    setShowDetail(true);
   //    setSingleWithdraw(data);
   // };

   const onDetailModalToggler = (single: CustomerCancelProjectModel) => {
      setShowDetail((prevState) => !prevState);
      setSingleData(single);
   };

   const onDetailModalClose = () => {
      setShowDetail(false);
   };

   const elementProps = {
      placeholder: "cari berdasarkan nama",
      buttonName: "",
      createUrl: "/master/issue-category/create",
      subHeaderLine: true,
   } as ElementPropModel;

   const dateSort = (rowA: { createdDate: string }, rowB: { createdDate: string }) => {
      const a = rowA.createdDate.split("/").reverse().join("");
      const b = rowB.createdDate.split("/").reverse().join("");

      return a.localeCompare(b);
   };

   const columns: TableColumn<CustomerCancelProjectModel>[] = [
      {
         name: "NO.",
         width: "60px",
         cell: (_d: any, index: number) => index + 1,
      },
      {
         name: "TANGGAL PEMBATALAN",
         selector: (row) => row.createdDate,
         format: (row: any) => row.createdDate.substr(0, 10),
         sortable: true,
      },
      {
         name: "NAMA PROYEK",
         selector: (row) => row.projectName,
         sortable: true,
      },
      {
         name: "NAMA LAYANAN",
         selector: (row) => row.activityName,
         sortable: true,
      },
      {
         name: "NAMA MITRA",
         selector: (row) => row.mitraName,
         sortable: true,
      },
      {
         name: "ALASAN",
         selector: (row) => row.reason,
         sortable: true,
      },
      {
         name: "AKSI",
         width: "350px",
         cell: (row: CustomerCancelProjectModel) => (
            <>
               <button
                  className="btn button-outline-primary mr-2"
                  onClick={() => onDetailModalToggler(row)}>
                  Preview
               </button>

            </>
         ),
      },
   ];

   // end of datatable components

   useEffect(() => {
      dispatch(getList());
      dispatch(resetStatus());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      dispatch(getList());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dateCreated]);

   return (
      <>
         <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            subHeader
            subHeaderComponent={DefaultSubHeader(
               filterText,
               resetPaginationToggle,
               onSetterFilterText,
               elementProps
            )}
            customStyles={TableCustomStyles}
            persistTableHead
         />
         <CustomerCancelProjectModal show={showDetail} data={singleData} handleClose={onDetailModalClose}  />
      </>
   );
};

export default CustomerCancelProjectTable;
