import React from "react";
import { Row, Col, Form, Badge } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";

const KotaLayananList = () => {
  const personalMainCityState = useSelector(
    (state: RootState) => state.dataDiri.personalMainCity ?? []
  );
  const personalOtherCityState = useSelector(
    (state: RootState) => state.dataDiri.personalOtherCity ?? []
  );
  return (
    <>
      <h6 className="mb-4 data_diri_tab_body_title d-inline-block">
        Kota Layanan{" "}
      </h6>
      <Form.Group as={Row} controlId="formPlaintext">
        <Form.Label column sm="12" className="field_plaint_text">
          Kota Layanan Utama
        </Form.Label>
        <Col sm="12">
          {personalMainCityState.map((item) => {
            return (
              <Badge pill className="profile-badge mr-2 mb-2" key={item.id}>
                {item.name}
              </Badge>
            );
          })}
        </Col>
        <Col sm="12">
          <hr className="tab_body_underline" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formPlaintext">
        <Form.Label column sm="12" className="field_plaint_text">
          Ketersediaan di Kota Lain
        </Form.Label>
        {personalOtherCityState.map((item) => {
          return (
            <Col sm="12">
              <Badge
                pill
                className="other-city-tag mb-2 text-left "
                key={item.id}
              >
                <div className="other-city-tag-container">
                  <span className="other-city-tag-label ml-3">
                    {item.name}
                  </span>
                  <div className="other-city-tag-container mr-3">
                    <div>
                      <NumberFormat
                        value={item.travelFee}
                        className="form-control d-inline field_plaint_text"
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"Rp. "}
                        style={{ width: "inherit", padding: "5px" }}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </Badge>
            </Col>
          );
        })}
        <Col sm="12">
          <hr className="tab_body_underline" />
        </Col>
      </Form.Group>
    </>
  );
};

export default KotaLayananList;
