import React from 'react';
import {Form, Modal} from "react-bootstrap";
import FileDropzone from "../../../components/FileUpload/FileDropzone";
import {convertBase64} from "../../../helpers/Base64";

const ProSquadPortfolioUploadModal = ({
                                        show = false,
                                        ...props
                                 }: ProSquadPortfolioUploadProps) => {
    const [localFiles, setLocalFiles] = React.useState<Array<File>>([]);
    const onFileChange = (files: Array<File>) => {
        setLocalFiles([...files]);
        // convertBase64(files[0], (result: any) => {
        //     setBase64Local(result);
        // });
    };

    const onSaveClicked = () => {
        props.onLocalFileChange(localFiles);
        props.handleClose();
        setLocalFiles([]);
    }

    return (
        <Modal
            show={show}
            onHide={props.handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title className="h6" >Upload Foto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FileDropzone
                    onChange={onFileChange}
                    files={localFiles}
                    isSingleFile={false}
                    disabledPreview
                    disabledThumbnail
                />

                <div className={"d-flex mt-3"}>
                    {localFiles.map((item, index) => (
                        <div className={`d-flex flex-column mb-3 result_album_image`}
                             key={`localfile-${index}`}>
                            <>
                                <div className="d-flex">
                                    <div
                                        className={`m-1 position-relative result_media_item`}
                                        style={{
                                            background: `url("${URL.createObjectURL(item)}")`,
                                        }}
                                    ></div>
                                </div>
                                <div className="px-2">
                                    <div
                                        className={`text-truncate filename_label`}>{item.name}</div>
                                </div>

                            </>
                            <>

                            </>
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn button-outline-primary"
                    type="button"
                    onClick={props.handleClose}>
                    TUTUP
                </button>
                <button className="btn button-primary" onClick={onSaveClicked}>SIMPAN</button>
            </Modal.Footer>
        </Modal>
    );
};

export interface ProSquadPortfolioUploadProps {
    show: boolean;
    handleClose: () => void;
    onLocalFileChange: (files: Array<File>) => void;
}

export default ProSquadPortfolioUploadModal;