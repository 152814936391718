import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { VendorModel } from "../../dataMitra/models";
import { ProfessionalModel } from "../models";

const BankAccount = () => {
  const professionalState = useSelector(
    (state: RootState) =>
      state.dataDiri.professionalSingle ?? ({} as ProfessionalModel)
  );

  const vendorState = useSelector(
    (state: RootState) => state.dataDiri.vendorSingle ?? ({} as VendorModel)
  );

  return (
    <>
      <h6 className="mb-4 data_diri_tab_body_title d-inline-block">
        Akun Bank{" "}
      </h6>
      <Form.Group as={Row} controlId="formPlaintext">
        <Form.Label column sm="12" className="field_plaint_text">
          Nama Bank
        </Form.Label>
        {(professionalState.bankName !== "null" ||
          vendorState.bankName !== "null") && (
          <Col sm="12">
            <Form.Control
              readOnly
              defaultValue={
                professionalState.id === "false"
                  ? vendorState.bankName
                  : professionalState.bankName
              }
              className="field_plaint_text"
              style={{ resize: "none" }}
            />
          </Col>
        )}
        <Col sm="12">
          <hr className="tab_body_underline" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formPlaintext">
        <Form.Label column sm="12" className="field_plaint_text">
          Nomor Rekening
        </Form.Label>
        {professionalState.bankAccount !== "null" && (
          <Col sm="12">
            <Form.Control
              readOnly
              defaultValue={
                professionalState.id === "false"
                  ? vendorState.bankAccount
                  : professionalState.bankAccount
              }
              className="field_plaint_text"
              style={{ resize: "none" }}
            />
          </Col>
        )}
        <Col sm="12">
          <hr className="tab_body_underline" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formPlaintext">
        <Form.Label column sm="12" className="field_plaint_text">
          Nama Pemilik Rekening
        </Form.Label>
        {professionalState.bankOnBehalf !== "null" && (
          <Col sm="12">
            <Form.Control
              readOnly
              defaultValue={
                professionalState.id === "false"
                  ? vendorState.bankOnBehalf
                  : professionalState.bankOnBehalf
              }
              className="field_plaint_text"
              style={{ resize: "none" }}
            />
          </Col>
        )}
        <Col sm="12">
          <hr className="tab_body_underline" />
        </Col>
      </Form.Group>
    </>
  );
};

export default BankAccount;
