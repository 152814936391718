import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import {
  getProfessionalTypeList,
  deleteProfessionalType,
  resetStatus,
} from "../professionalType.reducer";
import { Link } from "react-router-dom";
import { ProfessionalTypeModel } from "../models";
import DefaultSubHeader from "../../../../../components/DataTable/DefaultSubHeader";
import { TableCustomStyles } from "../../../../../components/DataTable/TableCustomStyles";
import DataTable, {TableColumn} from "react-data-table-component";
import { ElementPropModel } from "../../../../../components/DataTable/models";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiErrorResponse} from "../../../../../index";
import {ApiResponseStatus} from "../../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {deletedStatusOption, deletedWarnOption} from "../../../../../helpers/alertContent";

const ProfessionalTypeTable = () => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();

  const [filterText, setFilterText] = useState("");

  const professionalTypeState = useSelector(
    (state: RootState) => state.professionalType.list ?? []
  );
  const status = useSelector((state: RootState) => state.professionalType.status);
  const error = useSelector((state: RootState) => state.professionalType.error ?? ({} as ApiErrorResponse<any>));

  const filteredItems = professionalTypeState.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    dispatch(getProfessionalTypeList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status !== undefined && status !== ApiResponseStatus.pending) {
      MySwal.fire(deletedStatusOption(status === ApiResponseStatus.success, error.data?.message)).then(() => {
        dispatch(resetStatus());
        dispatch(getProfessionalTypeList());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error]);

  const onSetterFilterText = (value: string) => {
    setFilterText(value);
  };

  const onDelete = (id: string) => {
    MySwal.fire(deletedWarnOption).then((result) => {
      if (result.isConfirmed) {
        dispatch(
            deleteProfessionalType({
              id: id,
              name: "deleted",
              description: "deleted",
            } as ProfessionalTypeModel)
        );
      }
    });
  };

  const elementProps = {
    placeholder: "cari berdasarkan nama",
    buttonName: "tambah tipe profesional",
    createUrl: "/mitra/professional/type/create",
    subHeaderLine: true,
  } as ElementPropModel;

  const columns: TableColumn<ProfessionalTypeModel>[] = [
    {
      name: "NO.",
      width: "60px",
      cell: (_d: any, index: number) => index + 1,
    },
    {
      name: "NAMA TIPE PROFESIONAL",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "DESKRIPSI",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "AKSI",
      cell: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/mitra/professional/type/edit/${d.id}`,
            }}
          >
            <button className="btn button-primary mr-2">EDIT</button>
          </Link>
          <button
            onClick={() => onDelete(d.id)}
            className="btn button-danger"
          >
            HAPUS
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={false}
        noHeader
        subHeader
        subHeaderComponent={DefaultSubHeader(
          filterText,
          false,
          onSetterFilterText,
          elementProps
        )}
        customStyles={TableCustomStyles}
        persistTableHead
      />
    </>
  );
};

export default ProfessionalTypeTable;
