import React, {ChangeEvent, useState, Fragment, useEffect, FormEvent} from "react";
import {Modal, Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    updatePackageApprovalStatus,
    updateProductApprovalStatus
} from "../deleteMitraService.reducer";
import ComponentLoader from "../../../components/ComponentLoader";
import {RootState} from "../../../app/store";
import {ApprovalStatusEnum, RevisionModel} from "../models";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {checkObjectIsNotEmpty} from "../../../helpers/checkEmptyObject";

const Revision = ({
                        revisionRequest = {} as RevisionModel,
                        show = false,
                        isPackage = false,
                        ...props
                    }: RevisionProps) => {
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);

    const [revisionNote, setRevisionNote] = useState<string>("");
    const [validated, setValidated] = useState(false);

    const isPackageApprovalLoading = useSelector((state: RootState) => state.deleteMitraService.isPackageApprovalLoading);
    const isProductApprovalLoading = useSelector((state: RootState) => state.deleteMitraService.isProductApprovalLoading);

    useEffect(() => {
        if (checkObjectIsNotEmpty(revisionRequest)) {
            setRevisionNote(revisionRequest.revisionNote);
        }
    }, [revisionRequest]);

    const onRevisionNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setRevisionNote(e.target.value);
    };

    const onSubmitClicked = (e: FormEvent<HTMLFormElement>) => {
        const form = e.currentTarget;
        if (!form.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
            MySwal.fire({
                text: `Apakah kamu yakin akan mengubah status layanan ini?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
            }).then((result) => {
                if (result.isConfirmed) {
                    if (isPackage) {
                        dispatch(
                            updatePackageApprovalStatus({
                                id: revisionRequest.serviceId,
                                approvalStatus: ApprovalStatusEnum.Revisi,
                                revisiNote: revisionRequest.revisionNote
                            })
                        );
                    } else {
                        dispatch(
                            updateProductApprovalStatus({
                                id: revisionRequest.serviceId,
                                approvalStatus: ApprovalStatusEnum.Revisi,
                                revisiNote: revisionRequest.revisionNote
                            })
                        );
                    }
                    setRevisionNote("");
                }
            });
        }
        setValidated(true);
    }

    return (
        <Modal
            show={show}
            onHide={props.handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Permintaan Perbaikan</Modal.Title>
            </Modal.Header>
            <Form
                noValidate
                validated={validated}
                onSubmit={onSubmitClicked}>
                <ComponentLoader loading={isPackage ? isPackageApprovalLoading : isProductApprovalLoading} loadingName="Mengirim Balasan">
                    <Fragment>
                        <Modal.Body>
                            <Form.Group className="mb-4">
                                <Form.Label>Alasan Perbaikan</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    onChange={onRevisionNoteChange}
                                    placeholder="Masukkan isi pesan, contoh: perbaiki kesalahan..."
                                    value={revisionNote}
                                    required
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn button-outline-primary"
                                type="button"
                                onClick={props.handleClose}>
                                TUTUP
                            </button>
                            <button className="btn button-primary">
                                KIRIM
                            </button>
                        </Modal.Footer>
                    </Fragment>
                </ComponentLoader>

            </Form>
        </Modal>
    );
};

export interface RevisionProps {
    revisionRequest: RevisionModel;
    show: boolean;
    isPackage: boolean;
    handleClose: () => void;
}

export default Revision;
