import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import ReasonOptionApi from "./reasonOption.api";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {ReasonOptionModel} from "./models";

export interface ReasonOptionSlice {
  date?: string;
  list?: ReasonOptionModel[];
  single?: ReasonOptionModel;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: ApiResponseStatus;
}

export const createReasonOption = createAsyncThunk(
  "reasonOption/createReasonOption",
  async (data: ReasonOptionModel, { rejectWithValue }) => {
    try {
      return await ReasonOptionApi.create(data);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getReasonOptionList = createAsyncThunk(
  "reasonOption/getReasonOptionList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await ReasonOptionApi.getList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSingleReasonOption = createAsyncThunk(
  "reasonOption/getSingleReasonOption",
  async (id: string, { rejectWithValue }) => {
    try {
      return await ReasonOptionApi.getSingleItem(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateReasonOption = createAsyncThunk(
  "reasonOption/updateReasonOption",
  async (data: ReasonOptionModel, { rejectWithValue }) => {
    try {
      return await ReasonOptionApi.update(data);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteReasonOption = createAsyncThunk(
  "reasonOption/deleteReasonOption",
  async (data: ReasonOptionModel, { rejectWithValue }) => {
    try {
      return await ReasonOptionApi.delete(data);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const reasonOptionSlice = createSlice({
  name: "reasonOptionState",
  initialState: {} as ReasonOptionSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as ReasonOptionModel;
    },
    resetStatus: (state) => {
      state.status = ApiResponseStatus.pending;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createReasonOption.pending, (state) => {
      state.isLoading = true;
      state.status = ApiResponseStatus.pending;
    });
    builder.addCase(createReasonOption.fulfilled, (state) => {
      state.date = new Date().toISOString();
      state.isLoading = false;
      state.status = ApiResponseStatus.success;
    });
    builder.addCase(createReasonOption.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = ApiResponseStatus.failed;
    });
    builder.addCase(getSingleReasonOption.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(
        getSingleReasonOption.fulfilled,
      (state, { payload }) => {
        state.single = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(
        getSingleReasonOption.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
      }
    );
    builder.addCase(getReasonOptionList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getReasonOptionList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getReasonOptionList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateReasonOption.pending, (state) => {
      state.isLoading = true;
      state.status = ApiResponseStatus.pending;
    });
    builder.addCase(updateReasonOption.fulfilled, (state) => {
      state.isLoading = false;
      state.date = new Date().toISOString();
      state.status = ApiResponseStatus.success;
    });
    builder.addCase(updateReasonOption.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
      state.status = ApiResponseStatus.failed;
    });
    builder.addCase(deleteReasonOption.pending, (state) => {
      state.isLoading = true;
      state.status = ApiResponseStatus.pending;
    });
    builder.addCase(deleteReasonOption.fulfilled, (state) => {
      state.isLoading = false;
      state.date = new Date().toISOString();
      state.status = ApiResponseStatus.success;
    });
    builder.addCase(deleteReasonOption.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
      state.status = ApiResponseStatus.failed;
    });
  },
});

export const { resetSingle, resetStatus } = reasonOptionSlice.actions;
export default reasonOptionSlice.reducer;
