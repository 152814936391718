import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {deleteReasonOption, getReasonOptionList, resetStatus} from "../reasonOption.reducer";
import { Link } from "react-router-dom";
import { ReasonOptionModel } from "../models";
import DataTable, {TableColumn} from "react-data-table-component";
import DefaultSubHeader from "../../../../components/DataTable/DefaultSubHeader";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { ElementPropModel } from "../../../../components/DataTable/models";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {deletedStatusOption, deletedWarnOption} from "../../../../helpers/alertContent";
import {ApiErrorResponse} from "../../../../index";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import moment from "moment";

const ReasonOptionTable = () => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const reasonOptionState = useSelector(
    (state: RootState) => state.reasonOption.list ?? []
  );
  const status = useSelector((state: RootState) => state.reasonOption.status);
  const error = useSelector((state: RootState) => state.reasonOption.error ?? ({} as ApiErrorResponse<any>));

  // datatable components

  const [filterText, setFilterText] = useState("");

  const filteredItems = reasonOptionState.filter(
    (item) =>
      item.value && item.value.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    dispatch(getReasonOptionList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status !== undefined && status !== ApiResponseStatus.pending) {
      MySwal.fire(deletedStatusOption(status === ApiResponseStatus.success, error.data?.message)).then(() => {
        dispatch(resetStatus());
        dispatch(getReasonOptionList());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error]);

  const onSetterFilterText = (value: string) => {
    setFilterText(value);
  };

  const onDelete = (id: string) => {
    MySwal.fire(deletedWarnOption).then((result) => {
      if (result.isConfirmed) {
        dispatch(
            deleteReasonOption({
              id: id,
              value: "deleted",
            } as ReasonOptionModel)
        );
      }
    });
  };

  const elementProps = {
    placeholder: "cari berdasarkan nama",
    buttonName: "tambah opsi alasan",
    createUrl: "/master/reason-option/create",
    subHeaderLine: true,
  } as ElementPropModel;
  const columns: TableColumn<ReasonOptionModel>[] = [
    {
      name: "NO.",
      width: "60px",
      cell: (_d: any, index: number) => index + 1,
    },
    {
      name: "NAMA OPSI",
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "TANGGAL DIBUAT",
      selector: (row) => row.createdDate ?? "",
      format: (row: any) => moment(row.createdDate).format("DD/MM/YYYY"),
      sortable: true,
    },
    {
      name: "AKSI",
      cell: (d: { id?: string }) => (
        <>
          <Link
            to={{
              pathname: `/master/reason-option/edit/${d.id ?? ""}`,
            }}
          >
            <button className="btn button-primary mr-2">EDIT</button>
          </Link>
          <button
            onClick={() => onDelete(d.id ?? "")}
            className="btn button-danger"
          >
            HAPUS
          </button>
        </>
      ),
    },
  ];
  // end of datatable components
  return (
    <>
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={false}
        noHeader
        subHeader
        subHeaderComponent={DefaultSubHeader(
          filterText,
          false,
          onSetterFilterText,
          elementProps
        )}
        customStyles={TableCustomStyles}
        persistTableHead
      />
    </>
  );
};

export default ReasonOptionTable;
