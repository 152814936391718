import React, {Fragment, useEffect, useState} from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../../app/store";
import DefaultSubHeader from "../../../../components/DataTable/DefaultSubHeader";
import { ElementPropModel } from "../../../../components/DataTable/models";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import {deletePaymentMethod, getPaymentMethodList, resetStatus} from "../paymentMethod.reducer";
import {deletedStatusOption, deletedWarnOption} from "../../../../helpers/alertContent";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiErrorResponse} from "../../../../index";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {BannerModel} from "../../../customer/banner/models";
import {PaymentMethodModel} from "../models";

const PaymentMethodTable = () => {
   const dispatch = useDispatch();
   const MySwal = withReactContent(Swal);

   const [filterText, setFilterText] = useState<string>("");

   const paymentMethodList = useSelector((state: RootState) => state.paymentMethod.list ?? []);
   const status = useSelector((state: RootState) => state.paymentMethod.status);
   const error = useSelector((state: RootState) => state.paymentMethod.error ?? ({} as ApiErrorResponse<any>));

   const elementProps = {
      placeholder: "cari berdasarkan nama",
      buttonName: "tambah metode pembayaran",
      createUrl: "/master/payment-method/create",
      subHeaderLine: true,
   } as ElementPropModel;

   useEffect(() => {
      if (status !== undefined && status !== ApiResponseStatus.pending) {
         MySwal.fire(deletedStatusOption(status === ApiResponseStatus.success, error.data?.message)).then(() => {
            dispatch(resetStatus());
            dispatch(getPaymentMethodList());
         });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [status, error]);

   const onDelete = (paymentMethodId: string) => {
      MySwal.fire(deletedWarnOption).then((result) => {
         if (result.isConfirmed) {
            dispatch(deletePaymentMethod(paymentMethodId));
         }
      });
   };

   const onSetterFilterText = (value: string) => {
      setFilterText(value);
   };

   const filteredPaymentMethodItems = paymentMethodList.filter(
      (item) =>
         (item.paymentGatewayName &&
            item.paymentGatewayName.toLowerCase().includes(filterText.toLowerCase())) ||
         (item.paymentMethodName &&
            item.paymentMethodName.toLowerCase().includes(filterText.toLowerCase()))
   );

   const columns: TableColumn<PaymentMethodModel>[] = [
      {
         name: "NO.",
         width: "60px",
         cell: (_d: any, index: number) => index + 1,
      },
      {
         name: "PAYMENT GATEWAY",
         selector: (row) => row.paymentGatewayName,
         sortable: true,
      },
      {
         name: "METODE PEMBAYARAN",
         selector: (row) => row.paymentMethodName,
         sortable: true,
      },
      {
         name: "STATUS",
         cell: (d: { isActive: boolean }) => (
            <>
               {d.isActive && <span>Aktif</span>}
               {!d.isActive && <span>Tidak Aktif</span>}
            </>
         ),
      },
      {
         name: "AKSI",
         cell: (d: { id: any }) => (
            <>
               <Link
                  to={{
                     pathname: `/master/payment-method/edit/${d.id}`,
                  }}>
                  <button className="btn button-primary mr-2">EDIT</button>
               </Link>
               <button
                  onClick={() => onDelete(d.id)}
                  className="btn button-danger">
                  HAPUS
               </button>
            </>
         ),
      },
   ];

   return (
      <Fragment>
         <DataTable
            columns={columns}
            data={filteredPaymentMethodItems}
            pagination
            paginationResetDefaultPage={false}
            noHeader
            subHeader
            subHeaderComponent={DefaultSubHeader(
               filterText,
               false,
               onSetterFilterText,
               elementProps
            )}
            customStyles={TableCustomStyles}
            persistTableHead
         />
      </Fragment>
   );
};

export default PaymentMethodTable;
