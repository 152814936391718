import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Nav, Tab, OverlayTrigger, Tooltip } from "react-bootstrap";
import TagExpertiseTypeTable from "../components/TagExpertiseTypeTable";
import { RootState } from "../../../../app/store";
import {
   getTagExpertiseTypeProfessionalList,
   getTagExpertiseTypeVendorList,
   resetSingle,
} from "../tagExpertise.reducer";
import { TagExpertiseTypeEnum, TagExpertiseTypeModel } from "../models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEdit, faPlus} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

const TagExpertiseTypePage = () => {
   const dispatch = useDispatch();
   const history = useHistory();
   const professionalTypeState = useSelector(
      (state: RootState) => state.tagExpertise.professionalList ?? []
   );
   const vendorTypeState = useSelector((state: RootState) => state.tagExpertise.vendorList ?? []);

   const dateCreated = useSelector((state: RootState) => state.tagExpertise.dateCreated ?? "");

   const [tagExpertisePersonal, setTagExpertisePersonal] = useState<TagExpertiseTypeModel[]>([]);
   const [tagExpertiseKomersial, setTagExpertiseKomersial] = useState<TagExpertiseTypeModel[]>([]);
   const [tagExpertiseVendor, setTagExpertiseVendor] = useState<TagExpertiseTypeModel[]>([]);
   const [tagExpertisePersonalChild, setTagExpertisePersonalChild] = useState<
      TagExpertiseTypeModel[]
   >([]);
   const [tagExpertiseKomersialChild, setTagExpertiseKomersialChild] = useState<
      TagExpertiseTypeModel[]
   >([]);
   const [tagExpertiseVendorChild, setTagExpertiseVendorChild] = useState<TagExpertiseTypeModel[]>(
      []
   );

   useEffect(() => {
      const filteredTagExpertisePersonal = professionalTypeState.filter(
         (item) => item.parentId === "e3a2cb6b-fdda-4a65-bf7e-952445c94f61"
      );
      setTagExpertisePersonal(filteredTagExpertisePersonal);
      const filteredTagExpertiseKomersial = professionalTypeState.filter(
         (item) => item.parentId === "ef962335-7ee6-4204-9d7e-384cfd7bd289"
      );
      setTagExpertiseKomersial(filteredTagExpertiseKomersial);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [professionalTypeState]);

   useEffect(() => {
      const filteredTagExpertiseVendor = vendorTypeState.filter(
         (item) => item.parentId === "f89d4259-e2b2-41a3-b38d-42a3e4aa5b70"
      );
      setTagExpertiseVendor(filteredTagExpertiseVendor);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [vendorTypeState]);

   const onPersonalTabClick = (parentId: string) => {
      const filteredTagExpertisePersonalChild = professionalTypeState.filter(
         (item) => item.parentId === parentId
      );
      setTagExpertisePersonalChild(filteredTagExpertisePersonalChild);
   };

   const onKomersialTabClick = (parentId: string) => {
      const filteredTagExpertiseKomersialChild = professionalTypeState.filter(
         (item) => item.parentId === parentId
      );
      setTagExpertiseKomersialChild(filteredTagExpertiseKomersialChild);
   };

   const onVendorTabClick = (parentId: string) => {
      const filteredTagExpertiseVendorChild = vendorTypeState.filter(
         (item) => item.parentId === parentId
      );
      setTagExpertiseVendorChild(filteredTagExpertiseVendorChild);
   };

   useEffect(() => {
      dispatch(getTagExpertiseTypeProfessionalList());
      dispatch(getTagExpertiseTypeVendorList());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dateCreated]);

   useEffect(() => {
      dispatch(resetSingle());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const onEditClicked = (type: TagExpertiseTypeEnum, id: string) => {
      history.push(`/master/tag-expertise/edit/${type}/${id}`);
   };

   const onCreateClicked = () => {
        history.push(`/master/tag-expertise/create`);
   }

   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <h4 className="card-label d-inline">Master Expertise</h4>
               <button
                   className="btn button-primary text-uppercase d-inline float-right"
                   onClick={() => onCreateClicked()}
               >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />{" "}
                  Tambah
               </button>
               <hr />
            </Col>
            <Col md={12}>
               <div className="content-box">
                  <Row>
                     <Col md={12}>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                           <Row>
                              <Col sm={3}>
                                 <h6 className="mt-2 mb-3 expertise-tab-title">
                                    Professional (Personal)
                                 </h6>
                                 <Nav variant="pills" className="flex-column">
                                    {tagExpertisePersonal.map((item) => {
                                       return (
                                          <Nav.Item>
                                             <Nav.Link
                                                eventKey={item.name}
                                                onClick={() => {
                                                   onPersonalTabClick(item.id);
                                                }}>
                                                <span>{item.name}</span>
                                                <OverlayTrigger
                                                   placement="bottom"
                                                   overlay={
                                                      <Tooltip id={`tooltip-personal`}>
                                                         Edit Induk Expertise
                                                      </Tooltip>
                                                   }>
                                                   <span
                                                      className="float-right mt-1"
                                                      onClick={() =>
                                                         onEditClicked(item.type, item.id)
                                                      }>
                                                      <FontAwesomeIcon
                                                         icon={faEdit}
                                                         width="48"
                                                         className="file-upload-icon mb-3"
                                                      />
                                                   </span>
                                                </OverlayTrigger>
                                             </Nav.Link>
                                          </Nav.Item>
                                       );
                                    })}
                                 </Nav>
                              </Col>
                              <Col sm={9}>
                                 <Tab.Content>
                                    {tagExpertisePersonal.map((item) => {
                                       return (
                                          <Tab.Pane eventKey={item.name}>
                                             <TagExpertiseTypeTable
                                                data={
                                                   tagExpertisePersonalChild as TagExpertiseTypeModel[]
                                                }
                                             />
                                          </Tab.Pane>
                                       );
                                    })}
                                 </Tab.Content>
                              </Col>
                           </Row>
                        </Tab.Container>
                     </Col>
                  </Row>
               </div>
            </Col>
            <Col md={12}>
               <div className="content-box">
                  <Row>
                     <Col md={12}>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                           <Row>
                              <Col sm={3}>
                                 <h6 className="mt-2 mb-3 expertise-tab-title">
                                    Professional (Komersial)
                                 </h6>
                                 <Nav variant="pills" className="flex-column">
                                    {tagExpertiseKomersial.map((item) => {
                                       return (
                                          <Nav.Item>
                                             <Nav.Link
                                                eventKey={item.name}
                                                onClick={() => {
                                                   onKomersialTabClick(item.id);
                                                }}>
                                                <span>{item.name}</span>
                                                <OverlayTrigger
                                                   placement="bottom"
                                                   overlay={
                                                      <Tooltip id={`tooltip-personal`}>
                                                         Edit Parent Expertise
                                                      </Tooltip>
                                                   }>
                                                   <span
                                                      className="float-right mt-1"
                                                      onClick={() =>
                                                         onEditClicked(item.type, item.id)
                                                      }>
                                                      <FontAwesomeIcon
                                                         icon={faEdit}
                                                         width="48"
                                                         className="file-upload-icon mb-3"
                                                      />
                                                   </span>
                                                </OverlayTrigger>
                                             </Nav.Link>
                                          </Nav.Item>
                                       );
                                    })}
                                 </Nav>
                              </Col>
                              <Col sm={9}>
                                 <Tab.Content>
                                    {tagExpertiseKomersial.map((item) => {
                                       return (
                                          <Tab.Pane eventKey={item.name}>
                                             <TagExpertiseTypeTable
                                                data={
                                                   tagExpertiseKomersialChild as TagExpertiseTypeModel[]
                                                }
                                             />
                                          </Tab.Pane>
                                       );
                                    })}
                                 </Tab.Content>
                              </Col>
                           </Row>
                        </Tab.Container>
                     </Col>
                  </Row>
               </div>
            </Col>
            <Col md={12}>
               <div className="content-box">
                  <Row>
                     <Col md={12}>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                           <Row>
                              <Col sm={3}>
                                 <h6 className="mt-2 mb-3 expertise-tab-title">Vendor</h6>
                                 <Nav variant="pills" className="flex-column">
                                    {tagExpertiseVendor.map((item) => {
                                       return (
                                          <Nav.Item>
                                             <Nav.Link
                                                eventKey={item.name}
                                                onClick={() => {
                                                   onVendorTabClick(item.id);
                                                }}>
                                                <span>{item.name}</span>
                                                <OverlayTrigger
                                                   placement="bottom"
                                                   overlay={
                                                      <Tooltip id={`tooltip-personal`}>
                                                         Edit Parent Expertise
                                                      </Tooltip>
                                                   }>
                                                   <span
                                                      className="float-right mt-1"
                                                      onClick={() =>
                                                         onEditClicked(item.type, item.id)
                                                      }>
                                                      <FontAwesomeIcon
                                                         icon={faEdit}
                                                         width="48"
                                                         className="file-upload-icon mb-3"
                                                      />
                                                   </span>
                                                </OverlayTrigger>
                                             </Nav.Link>
                                          </Nav.Item>
                                       );
                                    })}
                                 </Nav>
                              </Col>
                              <Col sm={9}>
                                 <Tab.Content>
                                    {tagExpertiseVendor.map((item) => {
                                       return (
                                          <Tab.Pane eventKey={item.name}>
                                             <TagExpertiseTypeTable
                                                data={
                                                   tagExpertiseVendorChild as TagExpertiseTypeModel[]
                                                }
                                             />
                                          </Tab.Pane>
                                       );
                                    })}
                                 </Tab.Content>
                              </Col>
                           </Row>
                        </Tab.Container>
                     </Col>
                  </Row>
               </div>
            </Col>
         </Row>
      </Container>
   );
};

export default TagExpertiseTypePage;
