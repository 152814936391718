import React from 'react';
import {UploadProgress} from "../../@core/models/types";
import {ApiErrorResponse} from "../../index";
import Dropzone from "react-dropzone";
import {faFileImage, faReply, faRetweet, faTimesCircle, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {convertFileSize} from "../../helpers/convert";
import {ProgressBar} from "react-bootstrap";
import {UploadStatus} from "../../features/media/fileAttachment/models";
import {ApiResponseStatus} from "../../@core/models/apiResponseStatus/apiResponseStatus";
import {endpoint} from "../../constants";

interface CustomDropzoneProps {
    label?: string;
    message?: string;
    onChange: (file: Array<File>) => void;
    files: Array<File>;
    uploadedFiles?: Array<string>;
    uploadProgress?: UploadProgress[];
    uploadError?: ApiErrorResponse<undefined>;
    uploadStatus?: UploadStatus[];
    isSingleFile?: boolean;
    disabledPreview?: boolean;
    disabledThumbnail?: boolean;
}

const FileDropzone = ({
                          label = '',
                          uploadProgress = [],
                          files = [],
                          uploadStatus = [],
                          uploadedFiles = [],
                          isSingleFile = false,
                          disabledPreview = false,
                          disabledThumbnail = false,
                          ...rest
                      }: CustomDropzoneProps) => {
    const handleAcceptedProfileFiles = (localFile: Array<File>) => {
        isSingleFile ? rest.onChange([...localFile]) :
            rest.onChange([...files, ...localFile]);
    };

    const checkProgress = (index: number) => {
        return uploadProgress[index] ? uploadProgress[index].progress : 0;
    }

    const onRemoveFile = (index: number) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        rest.onChange(newFiles);
    }

    console.log(uploadStatus);

    return (
        <div>
            {label && (
                <label
                    htmlFor={label}
                    className="d-block mb-1"
                >
                    {label}
                </label>
            )}
            <Dropzone
                onDrop={(acceptedFiles) =>
                    handleAcceptedProfileFiles(acceptedFiles)
                }
                multiple={!isSingleFile}
            >
                {({getRootProps, getInputProps}) => (
                    <>
                        <section className="dropzone file-upload-frame w-100">
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{minHeight: '150px'}}
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} id={label}/>
                                <div>
                                    <div className='d-flex justify-content-center'>
                                        <FontAwesomeIcon
                                            className="mb-3"
                                            icon={faUpload}
                                            style={{fontSize: '48px', color: '#692f90'}}
                                        />
                                    </div>
                                    <div>
                                        <p className="text-sm text-secondary">
                                            Drag & drop some files here, or
                                            click to select files
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                )}
            </Dropzone>

            <>
                {!disabledPreview && (
                    <>
                        {
                            isSingleFile ? (
                                <>
                                    {uploadedFiles.length > 0 && (
                                        <img
                                            src={uploadedFiles[0]}
                                            alt="event"
                                            className="w-100 mt-3"
                                        />
                                    )}
                                </>

                            ) : (
                                <>
                                    {uploadedFiles.length > 0 && (
                                        <section className="mt-3">
                                            {uploadedFiles.map((fileName: string, index) => (
                                                <div key={`local-file-${index}`}
                                                     style={{border: "1px solid #e0e0e0", borderRadius: "10px"}}>
                                                    <div className="d-flex p-3">
                                                        <div className='d-flex align-items-center'>
                                                            <FontAwesomeIcon
                                                                icon={faFileImage}
                                                                style={{fontSize: '48px', color: '#692f90'}}
                                                            />
                                                        </div>
                                                        <div className="ml-3 flex-grow-1 w-75">
                                                            <div className="text-truncate">
                                                    <span className="text-sm">
                                                        {fileName}
                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {files.length > 1 && <hr/>}
                                                </div>
                                            ))}
                                        </section>
                                    )}
                                </>
                            )
                        }
                    </>
                )}
            </>

            {
                !disabledThumbnail && (
                    <>
                        {files.length > 0 && (
                            <section className="mt-3">
                                {files.map((file: File, index) => (
                                    <div key={`local-file-${index}`}
                                         style={{border: "1px solid #e0e0e0", borderRadius: "10px"}}>
                                        <div className="d-flex p-3">
                                            <div className='d-flex align-items-center'>
                                                <FontAwesomeIcon
                                                    icon={faFileImage}
                                                    style={{fontSize: '48px', color: '#692f90'}}
                                                />
                                            </div>
                                            <div className="ml-3 flex-grow-1">
                                                <div className="text-truncate">
                                                    <span className="text-sm">
                                                        {file.name}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className="text-xs text-secondary">
                                                        {convertFileSize(
                                                            file.size
                                                        )}{' '}
                                                        MB
                                                    </span>
                                                    {
                                                        rest.uploadError && <small
                                                            className="text-danger font-weight-bold ml-2">{rest.uploadError?.message}</small>
                                                    }
                                                </div>
                                                <div className='mt-1'>
                                                    {
                                                        uploadStatus[index] && uploadStatus[index].status === ApiResponseStatus.pending &&
                                                        <ProgressBar animated now={checkProgress(index)}/>
                                                    }
                                                    {
                                                        uploadStatus[index] && uploadStatus[index].status === ApiResponseStatus.failed &&
                                                        <small className="text-danger">Terjadi kesalahan ketika upload file,
                                                            silahkan coba lagi nanti</small>
                                                    }
                                                </div>
                                            </div>
                                            <div onClick={() => onRemoveFile(index)}
                                                 className='d-flex justify-content-center align-items-center px-2'>
                                                <FontAwesomeIcon
                                                    icon={faTimesCircle}
                                                    style={{fontSize: '24px', cursor: 'pointer'}}
                                                    color="#ff6961"
                                                />
                                            </div>
                                        </div>
                                        {files.length > 1 && <hr/>}
                                    </div>
                                ))}
                            </section>
                        )}
                    </>
                )
            }

        </div>
    )
}

export default FileDropzone;
