import firebase from "firebase/app";
import "firebase/firestore";

// live server
let config = {
   apiKey: "AIzaSyBLCD75EONxwsCGlJEkyeBkFMj_NhIgc3w",
   authDomain: "aramata-703ac.firebaseapp.com",
   projectId: "aramata-703ac",
   storageBucket: "aramata-703ac.appspot.com",
   messagingSenderId: "473742985049",
   appId: "1:473742985049:web:9eef85380b87c0f4c9ee48",
};

// firebase init
const base = !firebase.apps.length ? firebase.initializeApp(config) : firebase;

export default base.firestore();
