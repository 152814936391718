import axios from "axios";
import {endpoint} from "../../../constants";
import {StoreSalesDetailStatisticRequestModel, StoreSalesStatisticRequestModel} from "./models";

export default class StoreReportApi {
    static getList(args: StoreSalesStatisticRequestModel) {
        return axios
            .get<any>(endpoint + "/api/StoreProduct/GetListSalesStatistik", {
                params: {
                    filter: args.filter,
                    skip: args.skip,
                    take: args.take,
                    sort: args.sort,
                    column: args.column,
                },
            })
            .then((res) => res.data.data);
    }

    static getDetailList(args: StoreSalesDetailStatisticRequestModel) {
        return axios
            .get<any>(endpoint + "/api/StoreProduct/GetListSalesStatistikDetail", {
                params: {
                    storeProductId: args.storeProductId,
                    filter: args.filter,
                    skip: args.skip,
                    take: args.take,
                },
            })
            .then((res) => res.data.data);
    }
}
