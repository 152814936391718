import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import RoomSection from "../components/RoomSection";
import "../../../assets/styles/chat.css";
import { useDispatch, useSelector } from "react-redux";
import {getAllGroupRoom, getRooms} from "../chat.reducer";
import { RootState } from "../../../app/store";

const ChatPage = () => {
   const dispatch = useDispatch();

   const userId = useSelector((state: RootState) => state.user.id ?? "");

   useEffect(() => {
         dispatch(getAllGroupRoom());
   }, []);

   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <h4 className="card-label">Chat</h4>
               <hr />
               <RoomSection isReadOnly={true} />
            </Col>
         </Row>
      </Container>
   );
};

export default ChatPage;
