import React, {Fragment, useEffect, useState} from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import {Button, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import NumberFormat from "react-number-format";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {TableCustomStyles} from "../../../components/DataTable/TableCustomStyles";
import {MitraFeeModel, MitraTypeEnum, PenaltyCancellationFee, SettingValueEnum} from "../models";
import {
    getListCancellationFee, getListMitraFee,
    resetStatus,
    updateCancellationFee, updateMitraFee,
} from "../projectSetting.reducer";
import {BannerModel} from "../../customer/banner/models";

const MitraFee = () => {
    const dispatch = useDispatch();

    const [validated, setValidated] = useState<boolean>(false);
    const [updatedFee, setUpdatedFee] = useState<MitraFeeModel[]>([]);
    const [isInvalidPercentage, setIsInvalidPercentage] = useState<Array<boolean>>([]);

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const mitraFeeList = useSelector(
        (state: RootState) => state.projectSetting.mitraFeeList ?? []
    );
    const dateCreated = useSelector((state: RootState) => state.projectSetting.dateCreated);

    useEffect(() => {
        if (mitraFeeList.length !== 0) {
            setUpdatedFee(mitraFeeList);
        }
    }, [mitraFeeList]);

    useEffect(() => {
        if (updatedFee.length !== 0) {
            const isInvalid = updatedFee.map((item) => {
                return item.adminPercentage + item.mitraPercentage !== 100
            });
            setIsInvalidPercentage(isInvalid);
        }
    }, [updatedFee]);


    const onFormSubmit = (e: {
        currentTarget: any;
        preventDefault: () => void;
        stopPropagation: () => void;
    }) => {
    };

    const checkAdminFeeValue = (id: string) => {
        const _updatedFee = updatedFee.find((item) => item.id === id);
        return _updatedFee?.adminPercentage ?? 0;
    };

    const checkMitraFeeValue = (id: string) => {
        const _updatedFee = updatedFee.find((item) => item.id === id);
        return _updatedFee?.mitraPercentage ?? 0;
    };


    const InputAdminFee = (data: MitraFeeModel) => {
        const [fee, setFee] = useState<number>(checkAdminFeeValue(data.id));
        return (
            <NumberFormat
                value={fee}
                defaultValue={data.adminPercentage}
                className="form-control"
                thousandSeparator={"."}
                decimalSeparator={","}
                suffix={"%"}
                allowNegative={false}
                onBlur={() => {
                    let _updatedFee = updatedFee.map((item) => {
                        if (item.id === data.id) {
                            return {
                                id: item.id,
                                type: item.type,
                                adminPercentage: fee ?? "0",
                                mitraPercentage: item.mitraPercentage,
                            } as MitraFeeModel;
                        } else {
                            return item;
                        }
                    });
                    setUpdatedFee(_updatedFee);
                }}
                onValueChange={(values) => {
                    let val = values.floatValue ?? 0;
                    setFee(val);
                }}
            />
        );
    };

    const InputMitraFee = (data: MitraFeeModel) => {
        const [fee, setFee] = useState<number>(checkMitraFeeValue(data.id));
        return (
            <div className="d-flex">
                <NumberFormat
                    value={fee}
                    defaultValue={data.mitraPercentage}
                    className="form-control"
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    suffix={"%"}
                    allowNegative={false}
                    onBlur={() => {
                        let _updatedFee = updatedFee.map((item) => {
                            if (item.id === data.id) {
                                return {
                                    id: item.id,
                                    type: item.type,
                                    adminPercentage: item.adminPercentage,
                                    mitraPercentage: fee ?? "0",
                                } as MitraFeeModel;
                            } else {
                                return item;
                            }
                        });
                        setUpdatedFee(_updatedFee);
                    }}
                    onValueChange={(values) => {
                        let val = values.floatValue ?? 0;
                        setFee(val);
                    }}
                />
            </div>
        );
    };

    const onUpdateClicked = (data: MitraFeeModel) => {
        let _adminValue = checkAdminFeeValue(data.id);
        let _mitraValue = checkMitraFeeValue(data.id);
        dispatch(
            updateMitraFee({
                id: data.id,
                type: data.type,
                adminPercentage: _adminValue,
                mitraPercentage: _mitraValue
            })
        );
    };

    const columns: TableColumn<MitraFeeModel>[] = [
        {
            name: "NO.",
            width: "60px",
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: "Tipe Partner",
            cell: (row: MitraFeeModel) => (
                <Fragment>
               <span>
                  {row.type === MitraTypeEnum.Mitra && "Mitra"}
                   {row.type === MitraTypeEnum.OfficialPartner && "Official Partner"}
               </span>
                </Fragment>
            ),
            sortable: true,
        },
        {
            name: "PERSENTASE MITRA (%)",
            selector: (row) => row.mitraPercentage,
            sortable: true,
        },
        {
            name: "PERSENTASE ADMIN (%)",
            selector: (row) => row.adminPercentage,
            sortable: true,
        },
        {
            name: "UBAH PERSENTASE MITRA (%)",
            cell: (row: MitraFeeModel) => InputMitraFee(row),
            sortable: true,
        },
        {
            name: "UBAH PERSENTASE ADMIN (%)",
            cell: (row: MitraFeeModel) => InputAdminFee(row),
            sortable: true,
        },
        {
            name: "AKSI",
            width: "350px",
            cell: (row: MitraFeeModel, index: number) => (
                <>
                    {(checkAdminFeeValue(row.id) > row.adminPercentage || checkAdminFeeValue(row.id) < row.adminPercentage || checkMitraFeeValue(row.id) > row.mitraPercentage || checkMitraFeeValue(row.id) < row.mitraPercentage) && (
                        <OverlayTrigger overlay={isInvalidPercentage[index] ?
                            <Tooltip id="tooltip-disabled">Total Persentase Mitra + Admin harus menjadi 100%</Tooltip> :
                            <div></div>}>
  <span className="d-inline-block">
    <button className="btn button-outline-primary mr-2"
            onClick={() => onUpdateClicked(row)} disabled={isInvalidPercentage[index]}
            style={isInvalidPercentage[index] ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}>
      UBAH
    </button>
  </span>
                        </OverlayTrigger>
                    )}
                </>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getListMitraFee());
        dispatch(resetStatus());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(getListMitraFee());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateCreated]);

    return (
        <DataTable
            columns={columns}
            data={mitraFeeList}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
        />
    );
};

export default MitraFee;
