export interface RequestCancelProjectModel {
   id: string;
   projectId: string;
   projectName: string;
   reason: string;
   status: number;
}

export enum RequestProjectStatusEnum {
   request,
   approve,
   reject,
}

export interface RequestProjectStatus {
   id: string;
   status: RequestProjectStatusEnum;
}
