import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import {
    CreateMarketingEventFileModel,
    CreateMarketingEventModel, DetailFileEventModel,
    EventStatisticByDateModel,
    EventStatisticByUserModel,
    EventSummaryModel, FileEventModel,
    GetListUploadedFileReq,
    GetMarketingEventReq,
    GetMitraStatisticReq, MarketingEventImageTypeEnum,
    MarketingEventModel,
    MitraStatisticModel,
    PublishEventReq
} from "./models";
import {ApiErrorResponse} from "../../index";
import {ApiResponseStatus} from "../../@core/models/apiResponseStatus/apiResponseStatus";
import MarketingEventApi from "./marketingEvent.api";
import {UploadProgress, UploadStatus} from "../media/fileAttachment/models";
import FileAttachmentApi from "../media/fileAttachment/fileAttachment.api";
import {OptionModel} from "../../@core/models/types";
import {UserModel} from "../dataMitra/dataMitra/models";

export interface MarketingEventSlice {
    list?: MarketingEventModel[];
    eventOptions?: OptionModel[];
    singleEvent?: MarketingEventModel;
    mitraStatisticList?: MitraStatisticModel[];
    eventStatisticByUserList?: EventStatisticByUserModel[];
    eventStatisticByDateList?: EventStatisticByDateModel[];
    eventSummary?: EventSummaryModel;
    listUploadedFile?: FileEventModel[];
    listDetailFile?: DetailFileEventModel[];
    eventRows?: number;
    eventTotalDownload?: number;
    eventTotalView?: number;
    uploadProgress?: Array<UploadProgress>;
    uploadStatus?: Array<UploadStatus>;
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: ApiResponseStatus;
    eventDeleteStatus?: ApiResponseStatus;
    fileDeleteStatus?: ApiResponseStatus;
    publishStatus?: ApiResponseStatus;
}

export const uploadMarketingEventFile = createAsyncThunk(
    'marketingEvent/uploadMarketingEventFile',
    async (args: CreateMarketingEventFileModel, {getState, rejectWithValue, dispatch}) => {
        try {
            dispatch(
                setUploadProgress(
                    args.local_files.map((file, index) => {
                        return {index: index, progress: 0};
                    })
                )
            );
            dispatch(
                setUploadStatus(
                    args.local_files.map((attachment, index) => {
                        return {
                            index: index,
                            filename: attachment.file.name,
                            status: ApiResponseStatus.pending,
                            createStatus: ApiResponseStatus.pending,
                            message: "",
                        };
                    })
                )
            );

            const uploadResponse = args.local_files.map(async (attachment, index) => {
                let formData = new FormData();
                formData.append('file', attachment.file);
                formData.append('location', 'marketing_event')
                const config = {
                    // params: {
                    //     location: 'marketing_event',
                    // },
                    onUploadProgress: (progressEvent: ProgressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        dispatch(
                            updateUploadProgress({
                                index: index,
                                progress: percentCompleted,
                            })
                        );
                    },
                };
                return await FileAttachmentApi.uploadFile(formData, config).then((data) => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: ApiResponseStatus.success,
                            message: "",
                        })
                    );
                    return {
                        name: attachment.file.name,
                        path: data[0].filePath,
                        type: attachment.imageType,
                    }
                }).catch((e: any) => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: ApiResponseStatus.failed,
                            message: e.message,
                        })
                    );
                    return {
                        name: "",
                        path: "",
                        type: undefined
                    }
                });
            });

            Promise.all(uploadResponse).then(async (attachment) => {
                const {local_files, ...rest} = args;
                if (attachment[0].path !== "") {
                    let bannerImage = attachment.find((item) => item.type === MarketingEventImageTypeEnum.Banner);
                    let watermarkImage = attachment.find((item) => item.type === MarketingEventImageTypeEnum.Watermark);

                    if (args.isEdit) {
                        dispatch(updateMarketingEvent({
                            ...rest,
                            imageUrl: bannerImage?.path ?? args.imageUrl,
                            watermarkUrl: watermarkImage?.path ?? args.watermarkUrl
                        }));
                    } else {
                        dispatch(createMarketingEvent({
                            ...rest,
                            imageUrl: bannerImage?.path ?? "",
                            watermarkUrl: watermarkImage?.path ?? "",
                        }));
                    }
                }
            });
        } catch (e) {
            return rejectWithValue(e as ApiResponseStatus);
        }
    }
);

export const createMarketingEvent = createAsyncThunk(
    "marketingEvent/createMarketingEvent",
    async (args: CreateMarketingEventModel, {rejectWithValue}) => {
        try {
            return await MarketingEventApi.createMarketingEvent(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getMarketingEventList = createAsyncThunk(
    "marketingEvent/getMarketingEventList",
    async (args: GetMarketingEventReq, {rejectWithValue}) => {
        try {
            return await MarketingEventApi.getMarketingEventList(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getSingleEventItem = createAsyncThunk(
    "marketingEvent/getSingleItem",
    async (id: string, {rejectWithValue}) => {
        try {
            return await MarketingEventApi.getSingleItem(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const updateMarketingEvent = createAsyncThunk(
    "marketingEvent/updateMarketingEvent",
    async (args: CreateMarketingEventModel, {rejectWithValue}) => {
        try {
            return await MarketingEventApi.updateMarketingEvent(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const deleteMarketingEvent = createAsyncThunk(
    "marketingEvent/deleteMarketingEvent",
    async (id: string, {rejectWithValue}) => {
        try {
            return await MarketingEventApi.deleteMarketingEvent(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getMitraStatistic = createAsyncThunk(
    "marketingEvent/getMitraStatistic",
    async (args: GetMitraStatisticReq, {rejectWithValue}) => {
        try {
            return await MarketingEventApi.getMitraStatistic(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getEventSummary = createAsyncThunk(
    "marketingEvent/getEventSummary",
    async (marketingEventId: string, {rejectWithValue}) => {
        try {
            return await MarketingEventApi.getEventSummary(marketingEventId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getStatistikEventByUser = createAsyncThunk(
    "marketingEvent/getStatistikEventByUser",
    async (marketingEventId: string, {rejectWithValue}) => {
        try {
            return await MarketingEventApi.getStatistikEventByUser(marketingEventId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const changePublishEvent = createAsyncThunk(
    "marketingEvent/changePublishEvent",
    async (args: PublishEventReq, {rejectWithValue}) => {
        try {
            return await MarketingEventApi.changePublishEvent(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getStatistikEventByDate = createAsyncThunk(
    "marketingEvent/getStatistikEventByDate",
    async (marketingEventId: string, {rejectWithValue}) => {
        try {
            return await MarketingEventApi.getStatistikEventByDate(marketingEventId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getListUploadedFile = createAsyncThunk(
    "marketingEvent/getListUploadedFile",
    async (args: GetListUploadedFileReq, {rejectWithValue}) => {
        try {
            return await MarketingEventApi.getListUploadedFile(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const deleteUploadedFile = createAsyncThunk(
    "marketingEvent/deleteUploadedFile",
    async (id: string, {rejectWithValue}) => {
        try {
            return await MarketingEventApi.deleteUploadedFile(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const deleteMultipleUploadedFile = createAsyncThunk(
    "marketingEvent/deleteMultipleUploadedFile",
    async (ids: string[], {rejectWithValue, fulfillWithValue}) => {
        try {
            const response = ids.map(async (id) => {
                const resp = await MarketingEventApi.deleteUploadedFile(id);
                return resp.status;
            });
            Promise.all(response).then((res) => {
                if (res.every((item) => item === true)) {
                    return fulfillWithValue(true);
                } else {
                    return rejectWithValue({} as ApiErrorResponse<any>);
                }
            })
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

const marketingEventSlice = createSlice({
    name: "marketingEventState",
    initialState: {} as MarketingEventSlice,
    reducers: {
        setFilter: (state, action) => {
            state.list = action.payload;
        },
        resetSingle: (state) => {
            state.singleEvent = {} as MarketingEventModel;
        },
        reset: (state) => {
            state.status = ApiResponseStatus.pending;
            state.error = {} as ApiErrorResponse<any>;
            state.uploadStatus = [];
            state.uploadProgress = [];
            state.eventDeleteStatus = ApiResponseStatus.pending;
            state.fileDeleteStatus = ApiResponseStatus.pending;
            state.publishStatus = ApiResponseStatus.pending;
        },
        setUploadProgress: (state, payload: PayloadAction<Array<UploadProgress>>) => {
            state.uploadProgress = payload.payload;
        },
        setUploadStatus: (state, payload: PayloadAction<Array<UploadStatus>>) => {
            state.uploadStatus = payload.payload;
        },
        updateUploadProgress: (state, payload: PayloadAction<UploadProgress>) => {
            let progress = state.uploadProgress ?? [];
            let objIndex = state.uploadProgress?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            progress[objIndex as number].progress = payload.payload.progress;
            state.uploadProgress = progress;
        },
        updateUploadStatus: (state, payload: PayloadAction<UploadStatus>) => {
            let status = state.uploadStatus ?? [];
            let objIndex = state.uploadStatus?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            status[objIndex as number].status = payload.payload.status;
            state.uploadStatus = status;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createMarketingEvent.pending, (state) => {
            state.isLoading = true;
            state.status = ApiResponseStatus.pending;
        });
        builder.addCase(createMarketingEvent.fulfilled, (state) => {
            state.isLoading = false;
            state.status = ApiResponseStatus.success;
        });
        builder.addCase(createMarketingEvent.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = ApiResponseStatus.failed;
        });
        builder.addCase(getSingleEventItem.pending, (state) => {
            state.singleEvent = undefined;
            state.isLoading = true;
        });
        builder.addCase(getSingleEventItem.fulfilled, (state, {payload}) => {
            state.singleEvent = payload;
            state.isLoading = false;
        });
        builder.addCase(getSingleEventItem.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getMarketingEventList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMarketingEventList.fulfilled, (state, {payload}) => {
            state.list = payload.list;
            state.eventOptions = payload.list?.map((item: MarketingEventModel) => {
                return {value: item.id, label: item.name}
            });
            state.eventRows = payload.total;
            state.eventTotalDownload = payload.totalDownload;
            state.eventTotalView = payload.totalView;
            state.isLoading = false;
        });
        builder.addCase(getMarketingEventList.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(updateMarketingEvent.pending, (state) => {
            state.isLoading = true;
            state.status = ApiResponseStatus.pending;
        });
        builder.addCase(updateMarketingEvent.fulfilled, (state) => {
            state.isLoading = false;
            state.status = ApiResponseStatus.success;
        });
        builder.addCase(updateMarketingEvent.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
            state.status = ApiResponseStatus.failed;
        });
        builder.addCase(deleteMarketingEvent.pending, (state) => {
            state.isLoading = true;
            state.eventDeleteStatus = ApiResponseStatus.pending;
        });
        builder.addCase(deleteMarketingEvent.fulfilled, (state) => {
            state.isLoading = false;
            state.eventDeleteStatus = ApiResponseStatus.success;
        });
        builder.addCase(deleteMarketingEvent.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
            state.eventDeleteStatus = ApiResponseStatus.failed;
        });
        builder.addCase(getMitraStatistic.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMitraStatistic.fulfilled, (state, {payload}) => {
            state.mitraStatisticList = payload;
            state.isLoading = false;
        });
        builder.addCase(getMitraStatistic.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getEventSummary.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getEventSummary.fulfilled, (state, {payload}) => {
            state.eventSummary = payload;
            state.isLoading = false;
        });
        builder.addCase(getEventSummary.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getStatistikEventByUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getStatistikEventByUser.fulfilled, (state, {payload}) => {
            state.eventStatisticByUserList = payload;
            state.isLoading = false;
        });
        builder.addCase(getStatistikEventByUser.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getStatistikEventByDate.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getStatistikEventByDate.fulfilled, (state, {payload}) => {
            state.eventStatisticByDateList = payload;
            state.isLoading = false;
        });
        builder.addCase(getStatistikEventByDate.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(changePublishEvent.pending, (state) => {
            state.isLoading = true;
            state.publishStatus = ApiResponseStatus.pending;
        });
        builder.addCase(changePublishEvent.fulfilled, (state) => {
            state.isLoading = false;
            state.publishStatus = ApiResponseStatus.success;
        });
        builder.addCase(changePublishEvent.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
            state.publishStatus = ApiResponseStatus.failed;
        });
        builder.addCase(getListUploadedFile.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getListUploadedFile.fulfilled, (state, {payload}) => {
            state.listUploadedFile = payload;
            state.listDetailFile = payload?.map((item: FileEventModel) => {
                return {
                    id: item.id,
                    src: item.path,
                    alt: item.fileName
                }
            });
            state.isLoading = false;
        });
        builder.addCase(getListUploadedFile.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(deleteUploadedFile.pending, (state) => {
            state.isLoading = true;
            state.fileDeleteStatus = ApiResponseStatus.pending;
        });
        builder.addCase(deleteUploadedFile.fulfilled, (state) => {
            state.isLoading = false;
            state.fileDeleteStatus = ApiResponseStatus.success;
        });
        builder.addCase(deleteUploadedFile.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
            state.fileDeleteStatus = ApiResponseStatus.failed;
        });
        builder.addCase(deleteMultipleUploadedFile.pending, (state) => {
            state.isLoading = true;
            state.fileDeleteStatus = ApiResponseStatus.pending;
        });
        builder.addCase(deleteMultipleUploadedFile.fulfilled, (state) => {
            state.isLoading = false;
            state.fileDeleteStatus = ApiResponseStatus.success;
        });
        builder.addCase(deleteMultipleUploadedFile.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
            state.fileDeleteStatus = ApiResponseStatus.failed;
        });
    },
});

export const {
    setFilter,
    resetSingle,
    reset,
    setUploadProgress,
    setUploadStatus,
    updateUploadProgress,
    updateUploadStatus,
} = marketingEventSlice.actions;
export default marketingEventSlice.reducer;
