import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Container, Row, Col} from "react-bootstrap";
import StoreProductForm from "../components/StoreProductForm";
import {useHistory, useParams} from "react-router-dom";
import {RootState} from "../../../../app/store";
import {ApiErrorResponse} from "../../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";
import {CreateStoreProductModel, StoreProductModel} from "../models";
import {createStoreProduct, getSingleStoreProduct, resetStatus, updateStoreProduct} from "../storeProduct.reducer";
import ComponentLoader from "../../../../components/ComponentLoader";

const StoreProductDetailPage = () => {
    const MySwal = withReactContent(Swal);
    let history = useHistory();
    const dispatch = useDispatch();
    let {id} = useParams<{ id: string }>();

    const [isEdit, setIsEdit] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    const singleDataState = useSelector(
        (state: RootState) => state.storeProduct.single ?? ({} as StoreProductModel)
    );
    const status = useSelector((state: RootState) => state.storeProduct.status);
    const isGetLoading = useSelector((state: RootState) => state.storeProduct.isGetLoading);
    const error = useSelector((state: RootState) => state.storeProduct.error ?? ({} as ApiErrorResponse<any>));

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getSingleStoreProduct(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (
            singleDataState &&
            Object.keys(singleDataState).length !== 0 &&
            singleDataState.constructor === Object
        ) {
            setIsEdit(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleDataState]);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === ApiResponseStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(resetStatus());
                if (status === ApiResponseStatus.success) {
                    history.push("/store/product/list");
                }
                setIsSaveLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onSubmit = (args: CreateStoreProductModel) => {
        setIsSaveLoading(true);
        if (isEdit) {
            dispatch(
                updateStoreProduct(args)
            );
        } else {
            dispatch(
                createStoreProduct(args)
            );
        }
    };

    return (
        <Container className="content-body" fluid>
            <Row>
                <Col md={12}>
                    <h4 className="card-label">{isEdit ? "Edit" : "Tambah"} - Produk</h4>
                    <hr/>
                    <Row>
                        <Col md={8} xl={5}>
                            <ComponentLoader loading={isSaveLoading || isGetLoading} loadingName={`${isSaveLoading ? "Saving Product..." : "Getting Product Info..."}`}>
                                <StoreProductForm
                                    onSubmit={onSubmit}
                                    data={singleDataState}
                                    isEdit={isEdit}
                                />
                            </ComponentLoader>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default StoreProductDetailPage;
