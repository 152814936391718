import React from "react";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload, faUpload } from "@fortawesome/free-solid-svg-icons";
import {FileUploadModel} from "../../features/chat/models";
import {checkObjectIsNotEmpty} from "../checkEmptyObject";

const UploadFile = ({ selectedFile = {} as FileUploadModel, ...props }: UploadFileProps) => {
   const handleAcceptedProfileFiles = (files: any[]) => {
      props.uploadFileResult({
         file: files[0],
         type: files[0].type.split("/")[1],
         localPath: URL.createObjectURL(files[0]),
         fileName: files[0].name,
      });
   };
   
   return (
      <Dropzone onDrop={(acceptedFiles) => handleAcceptedProfileFiles(acceptedFiles)}>
         {({ getRootProps, getInputProps }) => (
            <div className="dropzone file-upload-frame w-100" style={{ minHeight: "250px" }}>
               <div className="dz-message needsclick" {...getRootProps()}>
                  <input {...getInputProps()} />

                  <div className="text-center file-upload-label">
                     <div>
                        {selectedFile.file !== undefined && (
                           <>
                              <FontAwesomeIcon
                                 icon={faFileUpload}
                                 width="48"
                                 className="file-upload-icon mb-3"
                              />
                              <br />
                           </>
                        )}
                        {selectedFile.file === undefined && (
                           <>
                              <FontAwesomeIcon
                                 icon={faUpload}
                                 width="48"
                                 className="file-upload-icon mb-3"
                              />
                              <br />
                           </>
                        )}
                        <span>
                           {checkObjectIsNotEmpty(selectedFile)
                              ? selectedFile.fileName
                              : "Unggah Berkas"}
                        </span>
                     </div>
                  </div>
               </div>
            </div>
         )}
      </Dropzone>
   );
};

export interface UploadFileProps {
   selectedFile: FileUploadModel;
   uploadFileResult: (file: FileUploadModel) => void;
}

export default UploadFile;
