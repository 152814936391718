import { ImageUpload } from "../../../@core/models/media/ImageUpload";

export enum StoreProductTypeEnum {
   Product,
   Class
}

export interface CreateStoreProductModel {
   id?: string;
   name: string;
   type: StoreProductTypeEnum;
   price: number;
   link: string;
   location: string;
   description: string;
   information: string;
   path: string;
   image: ImageUpload;
   classDate: string | null;
}

export interface StoreProductRequest {
   id?: string;
   name: string;
   type: StoreProductTypeEnum;
   price: number;
   link: string;
   location: string;
   description: string;
   path: string;
}

export interface StoreProductModel {
   id: string;
   name: string;
   type: StoreProductTypeEnum;
   price: number;
   link: string;
   location: string;
   description: string;
   information: string;
   path: string;
   classDate: string;
}