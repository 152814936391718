import React, { Fragment } from "react";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import {endpoint} from "../../../../../../../constants";
import {getIcon} from "../../../../../../../helpers/getIcon";
import {addDefaultProfileImage} from "../../../../../../../helpers/defaultBrokenImage";

const FileMessage = ({
   content = "",
   createdAt = "",
   fileName = "",
   fileType = "",
}: FileMessageProps) => {
   return (
      <Fragment>
         <div className={`message_list message_out`}>
            <div className={`message_list_item message_out`}>
               <div className={`message_box`}>
                  <div className={"message_video"}>
                     <Link to={endpoint + content}>
                        <a download target="_blank">
                           <div className="d-flex">
                              <div>
                                 <img
                                    src={getIcon(fileType)}
                                    onError={addDefaultProfileImage}
                                    alt="file"
                                    width={48}
                                 />
                              </div>
                              <div className={"video_title"}>
                                 <span>{fileName}</span>
                              </div>
                           </div>
                        </a>
                     </Link>
                  </div>
                  <span className={`message_time float-right`}>
                     <Moment format="HH:mm" withTitle>
                        {createdAt}
                     </Moment>
                  </span>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export interface FileMessageProps {
   content: string;
   createdAt: string;
   fileName: string;
   fileType: string;
}

export default FileMessage;
