import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Image} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {DataStatus} from "../../../../../../@core/models/types";
import useChatAttachment from "../../../../../../hooks/chat/useChatAttachment";
import {MessageType} from "../../../../../../@core/models/chat/models";
import {ImageUploadModel} from "../../../../models";
import UploadImage from "../../../../../../helpers/Upload/UploadImage";
import {convertToMB} from "../../../../../../helpers/convertFile";
import {IoIosClose} from "react-icons/io";

const PictureAttachmentModal = ({
   showModal = false,
   roomId = "",
   userId = "",
   userName = "",
   ...props
}: PictureAttachmentModalProps) => {
   const [selectedImage, setSelectedImage] = useState<ImageUploadModel[]>([]);


   const { sendImageAttachment, sendMultipleImageAttachment, isLoading, sendStatus } = useChatAttachment();

   const onImageChange = (file: ImageUploadModel) => {
      setSelectedImage(prevState => [...prevState, file]);
   };

   const handleRemoveImage = (index: any) => {
      const list = [...selectedImage];
      list.splice(index, 1);
      setSelectedImage(list);
   };

   const onSendClicked = () => {
      if (selectedImage.length > 3) {
         sendMultipleImageAttachment(selectedImage, roomId, {
            content: "",
            createdAt: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSSS[Z]'),
            readById: [],
            senderId: userId,
            senderName: userName,
            messageType: MessageType.image,
         });
      } else {
         selectedImage.map((image) => {
            sendImageAttachment(image, roomId, {
               content: "",
               createdAt: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSSS[Z]'),
               readById: [],
               senderId: userId,
               senderName: userName,
               messageType: MessageType.image,
            });
         });
      }
   };

   useEffect(() => {
      if (sendStatus === DataStatus.success) {
         props.onModalHide();
         setSelectedImage([]);
      }
   }, [sendStatus]);

   return (
      <Modal
         show={showModal}
         backdrop="static"
         keyboard={false}
         centered
         className="discount_modal">
         <Modal.Body>
            <Row>
               <Col md={12} className={`request_admin_modal_header`}>
                  <h1 className={`d-inline request_admin_modal_title`}>Upload Picture</h1>
                  <span
                     className={`material-icons-outlined float-right request_admin_close`}
                     onClick={props.onModalHide}>
                     <IoIosClose size={32} />
                  </span>
               </Col>
               <Col md={12} className={`request_admin_modal_content`}>
                  {isLoading && (
                     <div className={"upload_loading"}>
                        <div className="lds-default">
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                        </div>
                     </div>
                  )}

                  <UploadImage uploadImageResult={onImageChange} />

                  {selectedImage.map((image, index) => (
                      <div className={`mt-3 multiple_picture_list`}>
                         <div className='mr-3'>
                            <Image src={image.fileValue} width={60} />
                         </div>
                         <div className='flex-grow-1'>
                            <div>
                               <span className={"file_name"}>{image.name}</span>
                            </div>
                            <div>
                               <span className={"file_size"}>{convertToMB(image.size ?? 0) + ' MB'}</span>
                            </div>
                         </div>
                         <div className='align-self-center' onClick={() => handleRemoveImage(index)}>
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                width="16"
                                className={"trash"}
                            />
                         </div>
                      </div>
                  ))}

                  <button className="mt-3 btn button-primary float-right" onClick={onSendClicked}>
                     Kirim
                  </button>
               </Col>
            </Row>
         </Modal.Body>
      </Modal>
   );
};

export interface PictureAttachmentModalProps {
   showModal: boolean;
   onModalHide: () => void;
   roomId: string;
   userId: string;
   userName: string;
}

export default PictureAttachmentModal;
