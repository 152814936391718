import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ApiErrorResponse} from "../../..";
import {CityModel, IslandModel, ProvinceModel, RequestCityModel} from "./models";
import CityApi from "./city.api";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {OptionModel} from "../../../@core/models/types";

export interface CreateCitySlice {
    name?: string;
    list?: CityModel[];
    provinceList?: ProvinceModel[];
    provinceOptions?: OptionModel[];
    islandList?: IslandModel[];
    islandOptions?: OptionModel[];
    single?: CityModel;
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: ApiResponseStatus;
}

export const createCity = createAsyncThunk(
    "createCityState/createCity",
    async (cityModel: RequestCityModel, {rejectWithValue}) => {
        try {
            return await CityApi.createCity(cityModel);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getCityList = createAsyncThunk(
    "CityState/cityList",
    async (_: string | undefined = undefined, {rejectWithValue}) => {
        try {
            return await CityApi.getCityList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getIslandList = createAsyncThunk(
    "CityState/getIslandList",
    async (_: string | undefined = undefined, {rejectWithValue}) => {
        try {
            return await CityApi.getIslandList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getProvinceList = createAsyncThunk(
    "CityState/getProvinceList",
    async (islandId: string, {rejectWithValue}) => {
        try {
            return await CityApi.getProvinceList(islandId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getCitySingleItem = createAsyncThunk(
    "cityState/citySingleItem",
    async (cityId: string, {rejectWithValue}) => {
        try {
            return await CityApi.getSingleItem(cityId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const updateCity = createAsyncThunk(
    "cityState/updatecity",
    async (cityModel: RequestCityModel, {rejectWithValue}) => {
        try {
            return await CityApi.updateCity(cityModel);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const deleteCity = createAsyncThunk(
    "cityState/deletecity",
    async (cityModel: CityModel, {rejectWithValue}) => {
        try {
            return await CityApi.deleteCity(cityModel);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

const createCitySlice = createSlice({
    name: "createCityState",
    initialState: {} as CreateCitySlice,
    reducers: {
        setFilter: (state, action) => {
            state.list = action.payload;
        },
        resetSingle: (state) => {
            state.single = {} as CityModel;
        },
        resetStatus: (state) => {
            state.status = ApiResponseStatus.pending;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createCity.pending, (state) => {
            state.isLoading = true;
            state.status = ApiResponseStatus.pending;
        });
        builder.addCase(createCity.fulfilled, (state) => {
            state.name = new Date().toISOString();
            state.isLoading = false;
            state.status = ApiResponseStatus.success;
        });
        builder.addCase(createCity.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = ApiResponseStatus.failed;
        });
        builder.addCase(getCitySingleItem.pending, (state) => {
            state.single = undefined;
            state.isLoading = true;
        });
        builder.addCase(getCitySingleItem.fulfilled, (state, {payload}) => {
            state.single = payload;
            state.isLoading = false;
        });
        builder.addCase(getCitySingleItem.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getCityList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getCityList.fulfilled, (state, {payload}) => {
            state.list = payload;
            state.isLoading = false;
        });
        builder.addCase(getCityList.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        // province list
        builder.addCase(getProvinceList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getProvinceList.fulfilled, (state, {payload}) => {
            state.provinceList = payload;
            state.provinceOptions = payload.map((item: ProvinceModel) => {
                return {
                    label: item.name,
                    value: item.id,
                };
            });
            state.isLoading = false;
        });
        builder.addCase(getProvinceList.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        // island list
        builder.addCase(getIslandList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getIslandList.fulfilled, (state, {payload}) => {
            state.islandList = payload;
            state.islandOptions = payload.map((item: IslandModel) => {
                return {
                    label: item.name,
                    value: item.id,
                };
            });
            state.isLoading = false;
        });
        builder.addCase(getIslandList.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(updateCity.pending, (state) => {
            state.isLoading = true;
            state.status = ApiResponseStatus.pending;
        });
        builder.addCase(updateCity.fulfilled, (state) => {
            state.isLoading = false;
            state.name = new Date().toISOString();
            state.status = ApiResponseStatus.success;
        });
        builder.addCase(updateCity.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
            state.status = ApiResponseStatus.failed;
        });
        builder.addCase(deleteCity.pending, (state) => {
            state.isLoading = true;
            state.status = ApiResponseStatus.pending;
        });
        builder.addCase(deleteCity.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.name = new Date().toISOString();
            state.status = ApiResponseStatus.success;
        });
        builder.addCase(deleteCity.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
            state.status = ApiResponseStatus.failed;
        });
    },
});

export const {setFilter, resetSingle, resetStatus} = createCitySlice.actions;
export default createCitySlice.reducer;
