import React, { useState, ChangeEvent, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ImageUpload } from "../../../../../@core/models/media/ImageUpload";
import FormFile from "../../../../../components/FormField/FormFile";
import { checkEmptyObject } from "../../../../../helpers/checkEmptyObject";
import { VendorTypeModel } from "../models";

const VendorTypeForm = ({
   data = {} as VendorTypeModel,
   isEdit = false,
   ...props
}: VendorTypeFormProps) => {
   let history = useHistory();
   const [name, setName] = useState("");
   const [description, setDescription] = useState("");
   const [validated, setValidated] = useState(false);
   const [image, setImage] = useState<ImageUpload>({} as ImageUpload);
   const [errorImage, setErrorImage] = useState<boolean>(false);
   const [canUploadFile, setCanUploadFile] = useState<boolean>(false);

   const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
   };

   const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(e.target.value);
   };

   const onCancelHandler = () => {
      history.push("/mitra/vendor/type/list");
   };

   const handleFileUpload = (name: string, fileFormat: string, fileValue: string) => {
      setImage({
         name,
         fileFormat,
         fileValue,
         fromApi: false,
      });
   };

   useEffect(() => {
      if (isEdit) {
         setName(data.name);
         setDescription(data.description);
         setCanUploadFile(data.canUploadFile);
         setImage({
            name: "",
            fileFormat: "",
            fileValue: data.iconURL,
            fromApi: true,
         });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data, isEdit]);

   const onCanUploadFileHandler = (e: ChangeEvent<HTMLInputElement>) => {
      e.target.checked ? setCanUploadFile(true) : setCanUploadFile(false);
   };

   return (
      <Form
         noValidate
         validated={validated}
         onSubmit={(e) => {
            const form = e.currentTarget;
            if (!form.checkValidity()) {
               e.preventDefault();
               e.stopPropagation();
            } else {
               if (checkEmptyObject(image) && !isEdit) {
                  setErrorImage(true);
                  e.preventDefault();
               } else {
                  setErrorImage(false);
                  e.preventDefault();
                  props.onSubmit(name, description, image, data.iconURL, canUploadFile);
                  setName("");
                  setDescription("");
                  // history.replace("/mitra/vendor/type/list");
               }
            }
            setValidated(true);
         }}>
         <Form.Group>
            <Form.Label>Nama Tipe</Form.Label>
            <Form.Control
               type="text"
               placeholder="Masukkan Nama Tipe"
               onChange={onNameChange}
               value={name}
               required
            />
         </Form.Group>
         <Form.Group>
            <Form.Label>Deskripsi</Form.Label>
            <Form.Control
               as="textarea"
               rows={3}
               placeholder="Isi detail vendor type ( contoh : studio dan kelengkapannya)"
               onChange={onDescriptionChange}
               value={description}
               required
            />
         </Form.Group>
         <Form.Group>
            <Form.Check
               type="switch"
               inline
               className="mr-5"
               id="publish-switch"
               label="Dapat Upload Hasil"
               checked={canUploadFile}
               onChange={onCanUploadFileHandler}
            />
         </Form.Group>
         <Row>
            <Col md={6}>
               <FormFile
                  name="iconUrl"
                  label="Ikon"
                  defaultImage={image.fileValue}
                  handleAcceptedFiles={handleFileUpload}
                  height={200}
                  required={!isEdit}
                  onError={errorImage}
                  defaultFromApi={image.fromApi}
               />
            </Col>
         </Row>

         <div className="text-center mt-5">
            <button
               className="btn button-outline-primary mr-3"
               type="reset"
               onClick={onCancelHandler}>
               BATALKAN
            </button>
            <button className="btn button-primary" type="submit">
               {isEdit ? "EDIT TIPE VENDOR" : "TAMBAH TIPE VENDOR"}
            </button>
         </div>
      </Form>
   );
};

export interface VendorTypeFormProps {
   onSubmit: (
      name: string,
      description: string,
      image: ImageUpload,
      iconUrl: string,
      canUploadFile: boolean
   ) => void;
   data: VendorTypeModel;
   isEdit: boolean;
}

export default VendorTypeForm;
