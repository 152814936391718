import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

import BlogTable from "../components/BlogTable";
import {
  resetSingle,
  resetStatus,
} from "../blog.reducer";

const BlogPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetSingle());
    dispatch(resetStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <h4 className="card-label">Blog Admin</h4>
          <BlogTable />
        </Col>
      </Row>
    </Container>
  );
};

export default BlogPage;
