export interface UploadImageModel {
   file_name: string;
   base64url: string;
   imagepath: string;
   location: string;
}

export enum uploadStatus {
   success,
   failed,
}
