import React, {useState, ChangeEvent, useEffect} from "react";
import {Form, Badge} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {ImageUpload} from "../../../../@core/models/media/ImageUpload";
import FormFile from "../../../../components/FormField/FormFile";
import {checkEmptyObject, checkObjectIsNotEmpty} from "../../../../helpers/checkEmptyObject";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import {OptionModel} from "../../../../@core/models/types";
import {getUserOptionList as getMitraOptionList} from "../../../dataMitra/dataMitra/dataMitra.reducer";
import {UserTypeEnum} from "../../../dataMitra/dataMitra/models";
import {CreateMitraTierModel, MitraTierModel} from "../models";

const MitraTierForm = ({
                           data = {} as MitraTierModel,
                           isEdit = false,
                           ...props
                       }: ProfessionalTypeFormProps) => {
    const dispatch = useDispatch();
    let history = useHistory();

    const mitraUsernameOptions = useSelector((state: RootState) => state.dataMitra.usernameOptions ?? []);
    const isMitraOptionLoading = useSelector((state: RootState) => state.dataMitra.isLoading);

    const [name, setName] = useState("");
    const [mitraUsername, setMitraUsername] = useState<OptionModel[]>([]);
    const [validated, setValidated] = useState(false);
    const [image, setImage] = useState<ImageUpload>({} as ImageUpload);
    const [errorImage, setErrorImage] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getMitraOptionList({
            userType: UserTypeEnum.Mitra,
            registerStatus: undefined,
            name: undefined,
            skip: 0,
            take: 100000
        }));
    }, []);

    const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const onCancelHandler = () => {
        history.push("/master/tier/list");
    };

    const handleFileUpload = (name: string, fileFormat: string, fileValue: string) => {
        setImage({
            name,
            fileFormat,
            fileValue,
            fromApi: false,
        });
    };

    useEffect(() => {
        if (isEdit && checkObjectIsNotEmpty(data)) {
            setName(data.name);
            setImage({
                name: "",
                fileFormat: "",
                fileValue: data.icon,
                fromApi: true,
            });
            const mitraUsernameList = data.mitraList?.split(",").map((item: string, index: number) => {
                return {
                    value: item,
                    label: data.mitraListName?.split(",")[index]
                }
            })
            setMitraUsername(mitraUsernameList)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isEdit]);

    const onOptionUsernamChangeHandler = (e: OptionModel | null) => {
        let newArr: OptionModel[] = [];
        if (e && !mitraUsername.includes(e)) {
            newArr = [...mitraUsername, e];
        } else if (e) {
            newArr = mitraUsername.filter((option) => option !== e);
        }
        setMitraUsername(newArr);
    };

    const onUsernameTagCancelHandler = (option: OptionModel) => {
        if (mitraUsername.includes(option)) {
            const newArr = mitraUsername.filter((a) => a !== option);
            setMitraUsername(newArr);
        }
    };

    const MitraTag = () => {
        return (
            <>
                {mitraUsername?.map((item: any) => (
                    <Badge pill className="grey-tag mb-2 mx-1 text-left" key={item.value}>
                        <div className="grey-tag-container">
                            <span className="grey-tag-label">{item.label}</span>
                            <span
                                className="material-icons grey-tag-close ml-1"
                                onClick={() => onUsernameTagCancelHandler(item)}
                            >cancel</span>
                        </div>
                    </Badge>
                ))}
            </>
        );
    };

    return (
        <Form
            noValidate
            validated={validated}
            onSubmit={(e) => {
                const form = e.currentTarget;
                if (!form.checkValidity()) {
                    e.preventDefault();
                    e.stopPropagation();
                } else {
                    if (checkEmptyObject(image) && !isEdit) {
                        setErrorImage(true);
                        e.preventDefault();
                    } else {
                        setErrorImage(false);
                        e.preventDefault();
                        props.onSubmit({
                            id: data.id,
                            name,
                            image: image,
                            icon: data.icon,
                            mitraList: mitraUsername.map(item => item.value).toString(),
                        });
                        setName("");
                        // history.replace("/mitra/professional/type/list");
                    }
                }
                setValidated(true);
            }}>
            <Form.Group>
                <Form.Label>Nama Emblem</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Masukkan Nama Emblem"
                    onChange={onNameChange}
                    value={name}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>
                    Username Mitra
                </Form.Label>

                <div>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Ketik untuk mencari username mitra"
                        isSearchable={true}
                        name="color"
                        options={mitraUsernameOptions}
                        isLoading={isMitraOptionLoading}
                        onChange={onOptionUsernamChangeHandler}
                        hideSelectedOptions={true}
                    />
                    <div className="mt-2"><MitraTag/></div>
                </div>
            </Form.Group>

            <Form.Group>
                <FormFile
                    name="iconUrl"
                    label="Upload Gambar Ikon"
                    defaultImage={image.fileValue}
                    handleAcceptedFiles={handleFileUpload}
                    height={200}
                    required={!isEdit}
                    onError={errorImage}
                    defaultFromApi={image.fromApi}
                />
            </Form.Group>

            <div className="text-center mt-5">
                <button
                    className="btn button-outline-primary mr-3"
                    type="reset"
                    onClick={onCancelHandler}>
                    BATALKAN
                </button>
                <button className="btn button-primary" type="submit">
                    {isEdit ? "EDIT EMBLEM" : "TAMBAH EMBLEM"}
                </button>
            </div>
        </Form>
    );
};

export interface ProfessionalTypeFormProps {
    onSubmit: (args: CreateMitraTierModel) => void;
    data: MitraTierModel;
    isEdit: boolean;
}

export default MitraTierForm;
