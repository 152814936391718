import React, { useEffect } from "react";
import { Container, Row, Col, Button, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { faUserClock } from "@fortawesome/free-solid-svg-icons";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import BarChart from "../components/BarChart";
import { useDispatch, useSelector } from "react-redux";
import {
   getDashboardData,
   getListBestMitra,
   getListBestPackage,
   getListBestProduct,
   getReportDay,
   getReportMonth,
   getReportWeek,
} from "../dashboard.reducer";
import { RootState } from "../../../app/store";
import NumberFormat from "react-number-format";
function DashboardPage() {
   const dispatch = useDispatch();

   const graphMonthState = useSelector((state: RootState) => state.dashboard.graphMonth ?? []);
   const graphWeekState = useSelector((state: RootState) => state.dashboard.graphWeek ?? []);
   const graphDayState = useSelector((state: RootState) => state.dashboard.graphDay ?? []);
   const dashboardState = useSelector((state: RootState) => state.dashboard.dashboardData ?? []);
   const bestPackageState = useSelector((state: RootState) => state.dashboard.bestPackage ?? []);
   const bestProductState = useSelector((state: RootState) => state.dashboard.bestProduct ?? []);
   const bestMitraState = useSelector((state: RootState) => state.dashboard.bestMitra ?? []);

   useEffect(() => {
      dispatch(getDashboardData());
      dispatch(getReportMonth());
      dispatch(getReportWeek());
      dispatch(getReportDay());
      dispatch(getListBestPackage());
      dispatch(getListBestProduct());
      dispatch(getListBestMitra());
   }, []);

   return (
      <Container className="content-body" fluid>
         <Row>
            <Col lg={3} xs={6} className="mb-3">
               <div className="content-small color-danger">
                  <Row>
                     <Col xs={7}>
                        <h1>{dashboardState.totalAdmin}</h1>
                        <span>
                           Pengguna <br /> Admin
                        </span>
                     </Col>
                     <Col xs={5}>
                        <i style={{ fontSize: "4em" }} className="float-right icon-color">
                           <FontAwesomeIcon icon={faUserLock} />
                        </i>
                     </Col>
                  </Row>
               </div>
               <Link to="/admin/account/list">
                  <div className="text-center content-small-link link-color-danger">
                     <span>
                        More Info <FontAwesomeIcon icon={faArrowCircleRight} />
                     </span>
                  </div>
               </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-3">
               <div className="content-small color-info">
                  <Row>
                     <Col xs={7}>
                        <h1>{dashboardState.totalCustomer}</h1>
                        <span>
                           Pengguna <br /> Konsumen
                        </span>
                     </Col>
                     <Col xs={5}>
                        <i style={{ fontSize: "4em" }} className="float-right icon-color">
                           <FontAwesomeIcon icon={faUserCheck} />
                        </i>
                     </Col>
                  </Row>
               </div>
               <Link to="/customer/account/list">
                  <div className="text-center content-small-link link-color-info">
                     <span>
                        More Info <FontAwesomeIcon icon={faArrowCircleRight} />
                     </span>
                  </div>
               </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-3">
               <div className="content-small color-success">
                  <Row>
                     <Col xs={7}>
                        <h1>{dashboardState.totalMitraVerified}</h1>
                        <span>
                           Mitra <br /> (Terverifikasi)
                        </span>
                     </Col>
                     <Col xs={5}>
                        <i style={{ fontSize: "4em" }} className="float-right icon-color">
                           <FontAwesomeIcon icon={faUserCheck} />
                        </i>
                     </Col>
                  </Row>
               </div>
               <Link to="/mitra/account/personal-data/list">
                  <div className="text-center content-small-link link-color-success">
                     <span>
                        More Info <FontAwesomeIcon icon={faArrowCircleRight} />
                     </span>
                  </div>
               </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-3">
               <div className="content-small color-warning">
                  <Row>
                     <Col xs={7}>
                        <h1>{dashboardState.totalMitraUnVerified}</h1>
                        <span>
                           Mitra <br /> (Menunggu)
                        </span>
                     </Col>
                     <Col xs={5}>
                        <i style={{ fontSize: "4em" }} className="float-right icon-color">
                           <FontAwesomeIcon icon={faUserClock} />
                        </i>
                     </Col>
                  </Row>
               </div>
               <Link to="/mitra/account/personal-data/list">
                  <div className="text-center content-small-link link-color-warning">
                     <span>
                        More Info <FontAwesomeIcon icon={faArrowCircleRight} />
                     </span>
                  </div>
               </Link>
            </Col>
            <Col md={12}>
               <Row>
                  <Col md={6}>
                     <div className="Content-Box">
                        <h6>Paket Terlaris</h6>
                        <div>
                           <ListGroup variant="flush">
                              {bestPackageState.map((item) => (
                                 <ListGroup.Item>
                                    {item.name}{" "}
                                    <span className="float-right">
                                       <NumberFormat
                                          value={item.total}
                                          displayType={"text"}
                                          thousandSeparator={"."}
                                          decimalSeparator={","}
                                       />
                                    </span>
                                 </ListGroup.Item>
                              ))}
                           </ListGroup>
                        </div>
                     </div>
                  </Col>
                  <Col md={6}>
                     <div className="Content-Box">
                        <h6>Produk Terlaris</h6>
                        <div>
                           <ListGroup variant="flush">
                              {bestProductState.map((item) => (
                                 <ListGroup.Item>
                                    {item.name}{" "}
                                    <span className="float-right">
                                       <NumberFormat
                                          value={item.total}
                                          displayType={"text"}
                                          thousandSeparator={"."}
                                          decimalSeparator={","}
                                       />
                                    </span>
                                 </ListGroup.Item>
                              ))}
                           </ListGroup>
                        </div>
                     </div>
                  </Col>
                  <Col md={6}>
                     <div className="Content-Box">
                        <h6>Mitra Terlaris</h6>
                        <div>
                           <ListGroup variant="flush">
                              {bestMitraState.map((item) => (
                                 <ListGroup.Item>
                                    {item.name}{" "}
                                    <span className="float-right">
                                       <NumberFormat
                                          value={item.total}
                                          displayType={"text"}
                                          thousandSeparator={"."}
                                          decimalSeparator={","}
                                       />
                                    </span>
                                 </ListGroup.Item>
                              ))}
                           </ListGroup>
                        </div>
                     </div>
                  </Col>
               </Row>
            </Col>
            <Col md={4}>
               <div className="Content-Box">
                  <h6>Grafik Bulanan</h6>
                  <BarChart data={graphMonthState} />
               </div>
            </Col>
            <Col md={3}>
               <div className="Content-Box">
                  <h6>Grafik Mingguan</h6>
                  <BarChart data={graphWeekState} />
               </div>
            </Col>
            <Col md={5}>
               <div className="Content-Box">
                  <h6>Grafik Harian</h6>
                  <BarChart data={graphDayState} />
               </div>
            </Col>
         </Row>
      </Container>
   );
}

export default DashboardPage;
