import React, {Fragment, useEffect, useState} from 'react';
import DataTable, {TableColumn} from "react-data-table-component";
import {TableCustomStyles} from "../../../../components/DataTable/TableCustomStyles";
import {MAX_PAGING_OPTIONS} from "../../../../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import NumberFormat from "react-number-format";
import {MitraStatisticModel} from "../../models";
import {getMitraStatistic} from "../../marketingEvent.reducer";
import {RootState} from "../../../../app/store";

const MitraStatisticTable = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    let { id } = useParams<{ id: string }>();

    const mitraStatisticList = useSelector((state: RootState) => state.marketingEvent.mitraStatisticList ?? []);

    const [filterText, setFilterText] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [timer, setTimer] = useState(setTimeout(() => {
    }, 1000));

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getMitraStatistic({
                marketingEventId: id,
                username: undefined,
            }));
        }
    }, [id]);

    const onSearchChange = (e: any) => {
        let filterValue = e.target.value;
        setFilterText(filterValue);
        clearTimeout(timer);
        setTimer(
            setTimeout((value = filterValue) => {
                dispatch(getMitraStatistic({
                    marketingEventId: id,
                    username: value,
                }));
            }, 1000)
        );
    }

    const columns: TableColumn<MitraStatisticModel>[] = [
        {
            name: "NOMOR",
            width: "60px",
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: "USERNAME",
            selector: (row) => row.username ?? "-",
            sortable: true,
        },
        {
            name: "JUMLAH FOTO DIUPLOAD",
            selector: (row) => row.uploadedPhotoCount ?? "0",
            format: (row) => <Fragment>
                <NumberFormat
                    value={row.uploadedPhotoCount}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                />
            </Fragment>,
            sortable: true,
        },
        {
            name: "JUMLAH FOTO DIDOWNLOAD",
            selector: (row) => row.downloadedPhotoCount ?? "0",
            format: (row) => <Fragment>
                <NumberFormat
                    value={row.downloadedPhotoCount}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                />
            </Fragment>,
            sortable: true,
        },
        {
            name: "JUMLAH VIEW",
            selector: (row) => row.viewedPhotoCount ?? "0",
            format: (row) => <Fragment>
                <NumberFormat
                    value={row.viewedPhotoCount}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                />
            </Fragment>,
            sortable: true,
        },
        {
            name: "AKSI",
            width: "400px",
            cell: (row) => (
                <>
                    <Link
                        to={{
                            pathname: `/mitra/event/photo-list/${id}/${row.userId}`,
                        }}
                    >
                        <button className="btn button-primary mx-1">EDIT FOTO</button>
                    </Link>
                </>
            )
        }
    ]

    return (
        <div>
            <div className="d-flex" style={{
                borderBottom: "1px solid #a0a0a0",
            }}>
                <div style={{width: "300px", paddingBottom: "15px"}}>
                    <div>
                        <div className="d-flex">
                            <div className="input-field-group">
                                <FontAwesomeIcon
                                    className="input-field-absolut-icon"
                                    icon={faSearch}
                                />
                                <input
                                    id="search"
                                    type="search"
                                    placeholder={"Filter berdasarkan nama"}
                                    className="input-field-group-text"
                                    style={{width: "18rem"}}
                                    value={filterText}
                                    onChange={onSearchChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"my-3"}>
                <DataTable
                    columns={columns}
                    data={mitraStatisticList}
                    pagination
                    paginationResetDefaultPage={true}
                    noHeader
                    customStyles={TableCustomStyles}
                    progressPending={false}
                    persistTableHead
                    paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
                />
            </div>
        </div>
    );
};

export default MitraStatisticTable;
