import React, {useEffect, useState} from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import DefaultSubHeader from "../../../../components/DataTable/DefaultSubHeader";
import {ElementPropModel} from "../../../../components/DataTable/models";
import {TableCustomStyles} from "../../../../components/DataTable/TableCustomStyles";
import {StoreSalesDetailStatisticModel} from "../models";
import {getDetailList} from "../storeReport.reducer";
import {useParams} from "react-router-dom";

const StoreReportTable = () => {
    const dispatch = useDispatch();
    let {id} = useParams<{ id: string }>();

    const storeDetailList = useSelector((state: RootState) => state.storeReport.detailList ?? []);
    const rows = useSelector((state: RootState) => state.storeReport.detailRows ?? 0);
    const isLoading = useSelector((state: RootState) => state.storeReport.isLoading);

    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [timer, setTimer] = useState(setTimeout(() => {
    }, 1000));

    const [filterText, setFilterText] = useState("");

    useEffect(() => {
        if (id) {
            dispatch(getDetailList({
                storeProductId: id,
                skip: 0,
                take: 10,
                filter: undefined,
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const onSetterFilterText = (filterValue: string) => {
        setFilterText(filterValue);
        clearTimeout(timer);
        setTimer(
            setTimeout((value = filterValue) => {
                dispatch(getDetailList({
                    storeProductId: id,
                    skip: 0,
                    take: 10,
                    filter: value === "" ? undefined : value,
                }));
            }, 1000)
        );
    };

    const elementProps = {
        placeholder: "cari berdasarkan nama",
        buttonName: "",
        createUrl: "/master/issue-category/create",
        subHeaderLine: true,
    } as ElementPropModel;

    const dateSort = (rowA: { createdDate: string }, rowB: { createdDate: string }) => {
        const a = rowA.createdDate.split("/").reverse().join("");
        const b = rowB.createdDate.split("/").reverse().join("");

        return a.localeCompare(b);
    };

    const columns: TableColumn<StoreSalesDetailStatisticModel>[] = [
        {
            name: "NO.",
            width: "60px",
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: "NAMA USER",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "TANGGAL BELI",
            selector: (row) => row.createdDate,
            format: (row: any) => row.createdDate.substr(0, 10),
            sortable: true,
            sortFunction: dateSort,
        },
    ];

    // end of datatable components

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setRowsPerPage(newPerPage);
        dispatch(getDetailList({
            storeProductId: id,
            skip: newPerPage * (page - 1),
            take: newPerPage,
            filter: filterText === "" ? undefined : filterText,
        }));
    };

    const handlePageChange = (page: number) => {
        dispatch(getDetailList({
            storeProductId: id,
            skip: rowsPerPage * (page - 1),
            take: rowsPerPage,
            filter: filterText === "" ? undefined : filterText,
        }));
    };

    return (
        <>
            <DataTable
                columns={columns}
                data={storeDetailList}
                pagination
                paginationServer
                paginationTotalRows={rows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                noHeader
                subHeader
                subHeaderComponent={DefaultSubHeader(
                    filterText,
                    false,
                    onSetterFilterText,
                    elementProps
                )}
                customStyles={TableCustomStyles}
                persistTableHead
                progressPending={isLoading}
            />
        </>
    );
};

export default StoreReportTable;
