import React, { useEffect, useState } from "react";
import { Form, Row, Col, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { endpoint } from "../../../../constants";
import { VendorAttachmentModel } from "../models";

const LegalitasList = () => {
  const vendorAttachmentState = useSelector(
    (state: RootState) => state.dataDiri.vendorAttachment ?? []
  );

  const onImageClicked = (path: string) => {
    const win = window.open(endpoint + path, "_blank") as Window;
    win.focus();
  };

  const [siupDocument, setSiupDocument] = useState<VendorAttachmentModel[]>([]);
  const [otherDocument, setOtherDocument] = useState<VendorAttachmentModel[]>(
    []
  );

  useEffect(() => {
    if (vendorAttachmentState.length !== 0) {
      const siup =
        vendorAttachmentState.filter((item) => item.type === 3) ??
        ({} as VendorAttachmentModel);
      const other =
        vendorAttachmentState.filter((item) => item.type === 4) ??
        ({} as VendorAttachmentModel);
      setSiupDocument(siup);
      setOtherDocument(other);
    }
  }, [vendorAttachmentState]);

  return (
    <>
      <h6 className="mb-4 mt-4 data_diri_tab_body_title d-inline-block">
        Legalitas{" "}
      </h6>

      <Col md={12}>
        <Form.Label>SIUP</Form.Label>
        <Row>
          {siupDocument.map((item) => {
            return (
              <Col md="4" className="mb-3">
                <div
                  className="file-upload-frame w-100"
                  onClick={() => onImageClicked(item.path ?? "")}
                >
                  <Image
                    src={endpoint + item.path}
                    className="file-upload-image"
                  />
                </div>
              </Col>
            );
          })}
        </Row>
        {siupDocument.length === 0 && (
          <div className="alert alert-warning" role="alert">
            Belum ada SIUP yang ditambahkan
          </div>
        )}
      </Col>
      <Col md={12}>
        <Form.Label>Dokumen Pendukung</Form.Label>
        <Row>
          {otherDocument.map((item) => {
            return (
              <Col md="4" className="mb-3">
                <div
                  className="file-upload-frame w-100"
                  onClick={() => onImageClicked(item.path ?? "")}
                >
                  <Image
                    src={endpoint + item.path}
                    className="file-upload-image"
                  />
                </div>
              </Col>
            );
          })}
        </Row>
        {otherDocument.length === 0 && (
          <div className="alert alert-warning" role="alert">
            Belum ada dokumen pendukung lainnya yang ditambahkan
          </div>
        )}
      </Col>
    </>
  );
};

export default LegalitasList;
