import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import LanguageForm from "../components/LanguageForm";

import {
  createLanguage,
  getLanguageSingleItem,
  updateLanguage,
  resetStatus,
} from "../language.reducer";
import { LanguageModel } from "../models";
import {useHistory, useParams} from "react-router-dom";
import { RootState } from "../../../../app/store";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiErrorResponse} from "../../../../index";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";

const LanguageDetailPage = () => {
  const MySwal = withReactContent(Swal);
  let history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const [isEdit, setIsEdit] = useState(false);

  const singleDataState = useSelector(
    (state: RootState) => state.language.single ?? ({} as LanguageModel)
  );
  const status = useSelector((state: RootState) => state.language.status);
  const error = useSelector((state: RootState) => state.language.error ?? ({} as ApiErrorResponse<any>));

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getLanguageSingleItem(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (status !== undefined && status !== ApiResponseStatus.pending) {
      MySwal.fire(
          savedStatusOption(
              status === ApiResponseStatus.success,
              error.data?.message
          )
      ).then(() => {
        dispatch(resetStatus());
        if (status === ApiResponseStatus.success) {
          history.push("/master/language/list");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error]);

  const onSubmit = (name: string, description: string) => {
    if (isEdit) {
      dispatch(
        updateLanguage({
          id: id,
          name,
          description,
        } as LanguageModel)
      );
    } else {
      dispatch(
        createLanguage({
          name,
          description,
        } as LanguageModel)
      );
    }
  };

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <h4 className="card-label">
            {isEdit ? "Edit" : "Tambah"} - Master Bahasa
          </h4>
          <hr />
          <Row>
            <Col md={8} xl={5}>
              <LanguageForm
                onSubmit={onSubmit}
                data={singleDataState}
                isEdit={isEdit}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default LanguageDetailPage;
