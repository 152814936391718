export enum TagExpertiseTypeEnum {
   Vendor,
   Professional,
}

export interface TagExpertiseTypeModel {
   id: string;
   name: string;
   description: string;
   type: TagExpertiseTypeEnum;
   parentId: string;
   iconUrl: string;
}

export interface CreateTagExpertiseTypeModel {
   fileData: {
      file_name: string;
      base64url: string;
      imagepath: string;
      location: string;
   };
   formData: {
      id: string;
      name: string;
      description: string;
      type: number;
      parentId: string;
      iconUrl: string;
   };
}

export interface CreateTagExpertiseTypeSuccessResponse {
   name: string;
   description: string;
   type: number;
   parentId: string;
   id: string;
   createdById: string;
   createdBy: string;
   createdDate: Date;
   modifiedById: string;
   modifiedBy: string;
   modifiedDate: Date;
   isActive: boolean;
   createdByName: string;
}

export interface ExpertiseChildCategoryModel {
   id: string;
   name: string;
   description: string;
   iconUrl: string;
   type: number;
   parentId: string;
}