import React, { ChangeEvent, useState, Fragment } from "react";
import { Modal, Form } from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import { ReportIssueModel } from "../models";
import { replyToEmail } from "../reportIssue.reducer";
import ComponentLoader from "../../../components/ComponentLoader";
import {RootState} from "../../../app/store";

const ReplyReportIssue = ({
   singleReportIssue = {} as ReportIssueModel,
   show = false,
   ...props
}: ReplyReportIssueProps) => {
   const dispatch = useDispatch();

   const [name, setName] = useState<string>("");
   const [subject, setSubject] = useState<string>("");
   const [description, setDescription] = useState<string>("");
   const [validated, setValidated] = useState(false);

   const isLoading = useSelector((state: RootState) => state.reportIssue.isLoading);

   const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
   };

   const onSubjectChange = (e: ChangeEvent<HTMLInputElement>) => {
      setSubject(e.target.value);
   };

   const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(e.target.value);
   };

   const onSendClicked = () => {
      dispatch(
         replyToEmail({
            id: singleReportIssue.id,
            name: name,
            email: singleReportIssue.email,
            subject: subject,
            description: description,
         })
      );
   };

   return (
      <Modal
         show={show}
         onHide={props.handleClose}
         size="lg"
         aria-labelledby="contained-modal-title-vcenter"
         centered>
         <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Balas melalui E-Mail</Modal.Title>
         </Modal.Header>
         <Form
            noValidate
            validated={validated}
            onSubmit={(e) => {
               const form = e.currentTarget;
               if (!form.checkValidity()) {
                  e.preventDefault();
                  e.stopPropagation();
               } else {
                  e.preventDefault();
                  onSendClicked();
                  setSubject("");
                  setDescription("");
               }
               setValidated(true);
            }}>
            <ComponentLoader loading={isLoading} loadingName="Mengirim Balasan">
               <Fragment>
                  <Modal.Body>
                     <Form.Group className="mb-4">
                        <Form.Label>Nama</Form.Label>
                        <Form.Control onChange={onNameChange} placeholder="Nama" value={name} required />
                     </Form.Group>
                     <Form.Group className="mb-4">
                        <Form.Label>Subjek</Form.Label>
                        <Form.Control
                            onChange={onSubjectChange}
                            placeholder="Masukkan subjek email"
                            value={subject}
                            required
                        />
                     </Form.Group>
                     <Form.Group className="mb-4">
                        <Form.Label>Isi Pesan</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            onChange={onDescriptionChange}
                            placeholder="Masukkan isi pesan email, contoh: kesalahan telah diperbaiki."
                            value={description}
                            required
                        />
                     </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                     <button
                         className="btn button-outline-primary"
                         type="button"
                         onClick={props.handleClose}>
                        TUTUP
                     </button>
                     <button className="btn button-primary">
                        KIRIM
                     </button>
                  </Modal.Footer>
               </Fragment>
            </ComponentLoader>

         </Form>
      </Modal>
   );
};

export interface ReplyReportIssueProps {
   singleReportIssue: ReportIssueModel;
   show: boolean;
   handleClose: () => void;
}

export default ReplyReportIssue;
