import React, {ChangeEvent, useState, Fragment, useEffect} from "react";
import {Modal, Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {replyToEmail, resetStatus} from "../deleteMitraService.reducer";
import ComponentLoader from "../../../components/ComponentLoader";
import {RootState} from "../../../app/store";
import {EmailBodyModel} from "../models";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiErrorResponse} from "../../../index";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {customStatusOption, savedStatusOption} from "../../../helpers/alertContent";
import {checkObjectIsNotEmpty} from "../../../helpers/checkEmptyObject";

const ReplyMitra = ({
                        emailRequest = {} as EmailBodyModel,
                        show = false,
                        ...props
                    }: ReplyMitraProps) => {
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);

    const [name, setName] = useState<string>("");
    const [subject, setSubject] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [validated, setValidated] = useState(false);

    const isLoading = useSelector((state: RootState) => state.deleteMitraService.isLoading);
    const status = useSelector((state: RootState) => state.deleteMitraService.status);
    const error = useSelector((state: RootState) => state.deleteMitraService.error ?? ({} as ApiErrorResponse<any>));

    useEffect(() => {
        if (checkObjectIsNotEmpty(emailRequest)) {
            setName(emailRequest.name);
            setSubject(emailRequest.subject);
            setDescription(emailRequest.description);
        }
    }, [emailRequest]);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(customStatusOption(status === ApiResponseStatus.success, error.data?.message, 'Berhasil Mengirim E-Mail!', 'Gagal Mengirim E-Mail: ' + error.data?.message)).then(() => {
                  dispatch(resetStatus());
            });
        }
    }, [status, error]);

    const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const onSubjectChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSubject(e.target.value);
    };

    const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.target.value);
    };

    const onSendClicked = () => {
        dispatch(
            replyToEmail({
                packageProductId: emailRequest.serviceId,
                subject: subject,
                description: description,
            })
        );
    };

    return (
        <Modal
            show={show}
            onHide={props.handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Balas melalui E-Mail</Modal.Title>
            </Modal.Header>
            <Form
                noValidate
                validated={validated}
                onSubmit={(e) => {
                    const form = e.currentTarget;
                    if (!form.checkValidity()) {
                        e.preventDefault();
                        e.stopPropagation();
                    } else {
                        e.preventDefault();
                        onSendClicked();
                        setSubject("");
                        setDescription("");
                    }
                    setValidated(true);
                }}>
                <ComponentLoader loading={isLoading} loadingName="Mengirim Balasan">
                    <Fragment>
                        <Modal.Body>
                            <Form.Group className="mb-4">
                                <Form.Label>Nama</Form.Label>
                                <Form.Control onChange={onNameChange} placeholder="Nama" value={name} required/>
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label>Subjek</Form.Label>
                                <Form.Control
                                    onChange={onSubjectChange}
                                    placeholder="Masukkan subjek email"
                                    value={subject}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label>Isi Pesan</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    onChange={onDescriptionChange}
                                    placeholder="Masukkan isi pesan email, contoh: kesalahan telah diperbaiki."
                                    value={description}
                                    required
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn button-outline-primary"
                                type="button"
                                onClick={props.handleClose}>
                                TUTUP
                            </button>
                            <button className="btn button-primary">
                                KIRIM
                            </button>
                        </Modal.Footer>
                    </Fragment>
                </ComponentLoader>

            </Form>
        </Modal>
    );
};

export interface ReplyMitraProps {
    emailRequest: EmailBodyModel;
    show: boolean;
    handleClose: () => void;
}

export default ReplyMitra;
