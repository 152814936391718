import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { GraphReportModel } from "../models";

const BarChart = ({ data = [] }: BarChartProps) => {
   return (
      <div className="w-100 " style={{ height: "300px" }}>
         <ResponsiveBar
            data={data}
            margin={{ top: 20, right: 10, bottom: 40, left: 10 }}
            indexBy={"name"}
            keys={["total"]}
            labelTextColor="inherit:darker(1.4)"
            enableGridX={true}
            theme={{
               axis: {
                  ticks: {
                     line: {
                        stroke: "green",
                     },
                     text: {
                        fill: "red",
                     },
                  },
               },
               grid: {
                  line: {
                     stroke: "pink",
                     strokeWidth: 1,
                     strokeDasharray: "4 4",
                  },
               },
            }}
         />
      </div>
   );
};

export interface BarChartProps {
   data: GraphReportModel[];
}

export default BarChart;
