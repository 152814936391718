import axios from "axios";
import {endpoint} from "../../constants";
import {CreateProSquadPortfolioRequest, UpdatePortfolioRequest} from "./models";


export default class ProSquadPortfolioApi {
    static createProSquadPortfolio(args: CreateProSquadPortfolioRequest) {
        return axios
            .post<any>(
                endpoint + "/api/Portofolio/CreateProSquadPortofolio",
                args
            )
            .then((res) => res.data.data);
    }

    static updatePortfolio(args: UpdatePortfolioRequest) {
        return axios
            .post<any>(
                endpoint + "/api/Portofolio/Update",
                args
            )
            .then((res) => res.data.data);
    }

    static deletePortfolio(args: UpdatePortfolioRequest) {
        return axios
            .post<any>(
                endpoint + "/api/Portofolio/Delete",
                args
            )
            .then((res) => res.data.data);
    }

    static getProSquadPortfolio() {
        return axios
            .get<any>(endpoint + "/api/Portofolio/GetListProSquad")
            .then((res) => res.data.data);
    }

    static getSinglePortfolio(id: string) {
        return axios
            .get<any>(endpoint + "/api/Portofolio/GetSingleItem", {
                params: {
                    id
                }
            })
            .then((res) => res.data.data);
    }
}