import axios from "axios";
import { endpoint } from "../../constants";

export default class MitraCancelProjectApi {
   static getListAll() {
      return axios
         .get<any>(endpoint + "/api/Activity/GetListMitraCancel")
         .then((res) => res.data.data);
   }
}
