import axios from "axios";
import {
  TagExpertiseTypeModel,
  CreateTagExpertiseTypeSuccessResponse,
} from "./models";
import { endpoint } from "../../../constants";
import { UploadImageModel } from "../../customer/banner/models";

export default class TagExpertiseTypeApi {
  static createTagExpertiseType(createTagExpertiseType: TagExpertiseTypeModel) {
    return axios
      .post<any>(endpoint + "/api/Expertise/CreateType", createTagExpertiseType)
      .then((res) => res.data.data);
  }

  static getTagExpertiseTypeList(type: string) {
    return axios
      .get<any>(
        endpoint + "/api/Expertise/GetListExpertiseTypebyType?type=" + type
      )
      .then((res) => res.data.data);
  }

  static updateTagExpertiseType(tagExpertiseType: TagExpertiseTypeModel) {
    return axios
      .post<any>(endpoint + "/api/Expertise/UpdateType", tagExpertiseType)
      .then((res) => res.data.data);
  }

  static uploadImage(imageData: UploadImageModel) {
    return axios
      .post<any>(endpoint + "/web/api/Upload/UploadImage", imageData)
      .then((res) => res);
  }
  static deleteExpertiseType(id: string) {
    return axios
      .post<any>(endpoint + `/api/Expertise/DeleteType?id=${id}`)
      .then((res) => res);
  }

  static getExpertiseChildCategoryList() {
    return axios
        .get<any>(
            endpoint + "/api/Home/GetExpertiseChildCategory"
        )
        .then((res) => res.data.data);
  }

}
