import axios from "axios";
import {endpoint} from "../../constants";
import {
    CreateMarketingEventModel,
    GetListUploadedFileReq,
    GetMarketingEventReq,
    GetMitraStatisticReq,
    PublishEventReq
} from "./models";


export default class MarketingEventApi {
    static createMarketingEvent(args: CreateMarketingEventModel) {
        return axios
            .post<any>(
                endpoint + "/api/MarketingEvent/Create",
                args
            )
            .then((res) => res.data.data);
    }

    static getMarketingEventList(args: GetMarketingEventReq) {
        return axios
            .get<any>(endpoint + "/api/MarketingEvent/GetList", {
                params: args,
            })
            .then((res) => res.data.data);
    }

    static getSingleItem(id: string) {
        return axios
            .get<any>(
                endpoint + "/api/MarketingEvent/GetSingleItem", {
                    params: {
                        id: id,
                    }
                }
            )
            .then((res) => res.data.data);
    }

    static updateMarketingEvent(args: CreateMarketingEventModel) {
        return axios
            .post<any>(endpoint + "/api/MarketingEvent/Update", args)
            .then((res) => res.data.data);
    }

    static deleteMarketingEvent(id: string) {
        return axios
            .post<any>(endpoint + "/api/MarketingEvent/Delete", {}, {
                params: {
                    id: id,
                }
            })
            .then((res) => [{ response: res.data.data }]);
    }

    static getMitraStatistic(args: GetMitraStatisticReq) {
        return axios
            .get<any>(endpoint + "/api/MarketingEvent/GetStatistikMitra", {
                params: args,
            })
            .then((res) => res.data.data);
    }

    static getEventSummary(marketingEventId: string) {
        return axios
            .get<any>(endpoint + "/api/MarketingEvent/GetEventSummary", {
                params: {
                    marketingEventId: marketingEventId,
                },
            })
            .then((res) => res.data.data);
    }

    static getStatistikEventByUser(marketingEventId: string) {
        return axios
            .get<any>(endpoint + "/api/MarketingEvent/GetStatistikEventByUser", {
                params: {
                    marketingEventId: marketingEventId,
                },
            })
            .then((res) => res.data.data);
    }

    static getStatistikEventByDate(marketingEventId: string) {
        return axios
            .get<any>(endpoint + "/api/MarketingEvent/GetStatistikEventByDate", {
                params: {
                    marketingEventId: marketingEventId,
                },
            })
            .then((res) => res.data.data);
    }

    static changePublishEvent(args: PublishEventReq) {
        return axios
            .post<any>(endpoint + "/api/MarketingEvent/Publish", {}, {
                params: args
            })
            .then((res) => [{ response: res.data.data }]);
    }

    static getListUploadedFile(args: GetListUploadedFileReq) {
        return axios
            .get<any>(endpoint + "/api/MarketingEvent/GetListUploadedFile", {
                params: args,
            })
            .then((res) => res.data.data);
    }

    static deleteUploadedFile(id: string) {
        return axios
            .post<any>(endpoint + "/api/MarketingEvent/DeleteMarketingFileLog", {}, {
                params: {
                    id: id,
                }
            })
            .then((res) => res.data);
    }
}