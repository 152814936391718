import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import { ApiResponseStatus } from "../../@core/models/apiResponseStatus/apiResponseStatus";
import {
   CustomerCancellationFeeModel,
   MitraFeeModel,
   PartnerLevelModel,
   PartnerPointModel,
   PenaltyCancellationFee,
   ResultLimitModel,
   UpdateResultLimitModel
} from "./models";
import ProjectSettingApi from "./projectSetting.api";
import {hideSettingPointId} from "./constants";

export interface ProjectSettingSlice {
   cancellationFeeList?: PenaltyCancellationFee[];
   storeFee?: PenaltyCancellationFee;
   customerCancellationFeeList?: CustomerCancellationFeeModel[];
   mitraFeeList?: MitraFeeModel[];
   pointSettingList?: PartnerPointModel[];
   partnerLevelList?: PartnerLevelModel[];
   resultTimeLimitList?: ResultLimitModel[];
   isLoading?: boolean;
   dateCreated?: string;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
   updateLimitStatus?: ApiResponseStatus;
   updateCustomerCancelStatus?: ApiResponseStatus;
}

export const getListCancellationFee = createAsyncThunk(
   "projectSettingState/getListCancellationFee",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await ProjectSettingApi.getListCancellationFee();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getStoreCommission = createAsyncThunk(
    "projectSettingState/getStoreCommission",
    async (_: string | undefined = undefined, { rejectWithValue }) => {
       try {
          return await ProjectSettingApi.getStoreCommission();
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const updateCancellationFee = createAsyncThunk(
   "projectSettingState/updateCancellationFee",
   async (fee: PenaltyCancellationFee, { rejectWithValue }) => {
      try {
         return await ProjectSettingApi.updateCancellationFee(fee);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getListCustomerCancellationFee = createAsyncThunk(
    "projectSettingState/getListCustomerCancellationFee",
    async (_: string | undefined = undefined, { rejectWithValue }) => {
       try {
          return await ProjectSettingApi.getListCustomerCancellationFee();
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const updateCustomerCancellationFee = createAsyncThunk(
    "projectSettingState/updateCustomerCancellationFee",
    async (fee: CustomerCancellationFeeModel, { rejectWithValue }) => {
       try {
          return await ProjectSettingApi.updateCustomerCancellationFee(fee);
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const getListMitraFee = createAsyncThunk(
    "projectSettingState/getListMitraFee",
    async (_: string | undefined = undefined, { rejectWithValue }) => {
       try {
          return await ProjectSettingApi.getListMitraFee();
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const updateMitraFee = createAsyncThunk(
    "projectSettingState/updateMitraFee",
    async (fee: MitraFeeModel, { rejectWithValue }) => {
       try {
          return await ProjectSettingApi.updateMitraFee(fee);
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const getListPartnerPointSetting = createAsyncThunk(
   "projectSettingState/getListPartnerPointSetting",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await ProjectSettingApi.getListPartnerPointSetting();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const updatePartnerPointSetting = createAsyncThunk(
   "projectSettingState/updatePartnerPointSetting",
   async (point: PartnerPointModel, { rejectWithValue }) => {
      try {
         return await ProjectSettingApi.updatePartnerPointSetting(point);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getListPartnerLevel = createAsyncThunk(
   "projectSettingState/getListPartnerLevel",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await ProjectSettingApi.getListPartnerLevel();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const updatePartnerLevel = createAsyncThunk(
   "projectSettingState/updatePartnerLevel",
   async (level: PartnerLevelModel, { rejectWithValue }) => {
      try {
         return await ProjectSettingApi.updatePartnerLevel(level);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getListResultTimeLimit = createAsyncThunk(
    "projectSettingState/getListResultTimeLimit",
    async (_: string | undefined = undefined, { rejectWithValue }) => {
       try {
          return await ProjectSettingApi.getListResultTimeLimit();
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

export const updateResultTimeLimit = createAsyncThunk(
    "projectSettingState/updateResultTimeLimit",
    async (limit: UpdateResultLimitModel, { rejectWithValue }) => {
       try {
          return await ProjectSettingApi.updateResultTimeLimit(limit);
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

const projectSettingSlice = createSlice({
   name: "projectSettingState",
   initialState: {} as ProjectSettingSlice,
   reducers: {
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
         state.updateLimitStatus = ApiResponseStatus.pending;
         state.updateCustomerCancelStatus = ApiResponseStatus.pending;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(getListCancellationFee.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getListCancellationFee.fulfilled, (state, { payload }) => {
         state.cancellationFeeList = payload;
         state.isLoading = false;
      });
      builder.addCase(getListCancellationFee.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(getStoreCommission.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getStoreCommission.fulfilled, (state, { payload }) => {
         state.storeFee = payload;
         state.isLoading = false;
      });
      builder.addCase(getStoreCommission.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(updateCancellationFee.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(updateCancellationFee.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.dateCreated = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(updateCancellationFee.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });

      builder.addCase(getListCustomerCancellationFee.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getListCustomerCancellationFee.fulfilled, (state, { payload }) => {
         state.customerCancellationFeeList = payload;
         state.isLoading = false;
      });
      builder.addCase(getListCustomerCancellationFee.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(updateCustomerCancellationFee.pending, (state) => {
         state.isLoading = true;
         state.updateCustomerCancelStatus = ApiResponseStatus.pending;
      });
      builder.addCase(updateCustomerCancellationFee.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.updateCustomerCancelStatus = ApiResponseStatus.success;
      });
      builder.addCase(updateCustomerCancellationFee.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.updateCustomerCancelStatus = ApiResponseStatus.failed;
      });

      builder.addCase(getListMitraFee.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getListMitraFee.fulfilled, (state, { payload }) => {
         state.mitraFeeList = payload;
         state.isLoading = false;
      });
      builder.addCase(getListMitraFee.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(updateMitraFee.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(updateMitraFee.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.dateCreated = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(updateMitraFee.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });

      builder.addCase(getListPartnerPointSetting.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getListPartnerPointSetting.fulfilled, (state, { payload }) => {
         let _pointSettingList = payload.filter((item: PartnerPointModel) => item.id !== hideSettingPointId);

         state.pointSettingList = _pointSettingList;
         state.isLoading = false;
      });
      builder.addCase(getListPartnerPointSetting.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(updatePartnerPointSetting.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(updatePartnerPointSetting.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.dateCreated = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(updatePartnerPointSetting.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });

      builder.addCase(getListPartnerLevel.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getListPartnerLevel.fulfilled, (state, { payload }) => {
         state.partnerLevelList = payload;
         state.isLoading = false;
      });
      builder.addCase(getListPartnerLevel.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(updatePartnerLevel.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(updatePartnerLevel.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.dateCreated = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(updatePartnerLevel.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });

      builder.addCase(getListResultTimeLimit.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getListResultTimeLimit.fulfilled, (state, { payload }) => {
         state.resultTimeLimitList = payload;
         state.isLoading = false;
      });
      builder.addCase(getListResultTimeLimit.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(updateResultTimeLimit.pending, (state) => {
         state.isLoading = true;
         state.updateLimitStatus = ApiResponseStatus.pending;
      });
      builder.addCase(updateResultTimeLimit.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.dateCreated = new Date().toISOString();
         state.updateLimitStatus = ApiResponseStatus.success;
      });
      builder.addCase(updateResultTimeLimit.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.updateLimitStatus = ApiResponseStatus.failed;
      });
   },
});

export const { resetStatus } = projectSettingSlice.actions;
export default projectSettingSlice.reducer;
