import React, { Fragment } from "react";
import Moment from "react-moment";
import {endpoint} from "../../../../../../../constants";
const VideoMessage = ({
   profileImage = "",
   senderName = "",
   content = "",
   createdAt = "",
   ...props
}: VideoMessageProps) => {
   return (
      <Fragment>
         <div className={`message_list message_in`}>
            <div className={`message_list_item message_in`}>
               <div className={`message_box`}>
                  <div>
                     <div className={`d-block mb-2 mitra_profile`}>
                        {/*<div className={`d-inline-block mr-1 image_frame}`}>*/}
                        {/*   <img*/}
                        {/*      className={`profile_image}`}*/}
                        {/*      src="https://placeimg.com/640/480/people"*/}
                        {/*      onError={addDefaultProfileImage}*/}
                        {/*      alt="profile"></img>*/}
                        {/*</div>*/}
                        <span>{senderName}</span>
                     </div>
                  </div>
                  <div>
                     <div className={"message_image"}>
                        {/*<img*/}
                        {/*   onClick={props.onMessageClick}*/}
                        {/*   className={`profile_image}`}*/}
                        {/*   src="/assets/images/default_video_thumbnail.png"*/}
                        {/*   onError={addDefaultVideoImage}*/}
                        {/*   alt="profile"*/}
                        {/*   width={220}></img>*/}
                        <video id="playVideoPreview" onClick={props.onMessageClick} className={`profile_image`} style={{width: "100%"}}>
                           <source
                               src={`${endpoint + content}#t=2.4`}
                               type="video/mp4"
                           />
                        </video>
                     </div>
                  </div>
                  <span className={"message_time"}>
                     <Moment format="HH:mm" withTitle>
                        {createdAt}
                     </Moment>
                  </span>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export interface VideoMessageProps {
   profileImage: string;
   senderName: string;
   content: string;
   createdAt: string;
   onMessageClick: () => void;
}

export default VideoMessage;
