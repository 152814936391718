import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import { ApiResponseStatus } from "../../@core/models/apiResponseStatus/apiResponseStatus";
import {CustomerCancelProjectModel} from "./models";
import CustomerCancelProjectApi from "./customerCancelProject.api";

export interface CustomerCancelProjectSlice {
   list?: CustomerCancelProjectModel[];
   isLoading?: boolean;
   dateCreated?: string;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
}

export const getList = createAsyncThunk(
   "customerCancelProject/getList",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await CustomerCancelProjectApi.getListAll();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const customerCancelProjectSlice = createSlice({
   name: "customerCancelProjectState",
   initialState: {} as CustomerCancelProjectSlice,
   reducers: {
      setFilter: (state, action) => {
         state.list = action.payload;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
      },
      resetError: (state) => {
         state.error = {} as ApiErrorResponse<any>;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(getList.fulfilled, (state, { payload }) => {
         state.list = payload;
      });
      builder.addCase(getList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
      });
   },
});

export const { setFilter, resetStatus, resetError } = customerCancelProjectSlice.actions;
export default customerCancelProjectSlice.reducer;
