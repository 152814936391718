import React, { Fragment, useEffect, useState } from "react";
import {Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import DataTable, {TableColumn} from "react-data-table-component";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import { PenaltyCancellationFee, SettingValueEnum } from "../models";
import {
   getListCancellationFee,
   resetStatus,
   updateCancellationFee,
} from "../projectSetting.reducer";

const CancellationFee = () => {
   const dispatch = useDispatch();

   const [validated, setValidated] = useState<boolean>(false);
   const [updatedFee, setUpdatedFee] = useState<PenaltyCancellationFee[]>([]);

   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
   const cancellationFeeList = useSelector(
      (state: RootState) => state.projectSetting.cancellationFeeList ?? []
   );
   const dateCreated = useSelector((state: RootState) => state.projectSetting.dateCreated);
   const [isInvalidPercentage, setIsInvalidPercentage] = useState<Array<boolean>>([]);

   useEffect(() => {
      if (cancellationFeeList.length !== 0) {
         setUpdatedFee(cancellationFeeList);
      }
   }, [cancellationFeeList]);

   useEffect(() => {
      if (updatedFee.length !== 0) {
         const isInvalid = updatedFee.map((item) => {
            return parseInt(item.value) > 100
         });
         setIsInvalidPercentage(isInvalid);
      }
   }, [updatedFee]);

   const onFormSubmit = (e: {
      currentTarget: any;
      preventDefault: () => void;
      stopPropagation: () => void;
   }) => {};

   const checkFeeValue = (id: string) => {
      const _updatedFee = updatedFee.find((item) => item.id === id);
      return _updatedFee?.value ?? "0";
   };

   const InputCancellationFee = (data: PenaltyCancellationFee) => {
      const [fee, setFee] = useState<string>(checkFeeValue(data.id));
      return (
         <NumberFormat
            value={fee}
            defaultValue={data.value}
            className="form-control"
            thousandSeparator={"."}
            decimalSeparator={","}
            allowNegative={false}
            suffix={"%"}
            onBlur={() => {
               let _updatedFee = updatedFee.map((item) => {
                  if (item.id === data.id) {
                     return {
                        id: item.id,
                        type: item.type,
                        value: fee ?? "0",
                     } as PenaltyCancellationFee;
                  } else {
                     return item;
                  }
               });

               setUpdatedFee(_updatedFee);
            }}
            onValueChange={(values) => {
               let val = values.floatValue ?? 0;
               setFee(val.toString());
            }}
         />
      );
   };

   const onUpdateClicked = (data: PenaltyCancellationFee) => {
      let _value = checkFeeValue(data.id);
      dispatch(
         updateCancellationFee({
            id: data.id,
            type: data.type,
            value: _value,
         })
      );
   };

   const columns: TableColumn<PenaltyCancellationFee>[] = [
      {
         name: "NO.",
         width: "60px",
         cell: (_d: any, index: number) => index + 1,
      },
      {
         name: "DURASI PEMBATALAN",
         cell: (row: PenaltyCancellationFee) => (
            <Fragment>
               <span>
                  {row.type === SettingValueEnum.satu && "1 Hari"}
                  {row.type === SettingValueEnum.dua && "2 Hari"}
                  {row.type === SettingValueEnum.tiga && "3 Hari"}
               </span>
            </Fragment>
         ),
         sortable: true,
      },
      {
         name: "PERSENTASE PEMOTONGAN (%)",
         selector: (row) => row.value,
         sortable: true,
      },
      {
         name: "UBAH BIAYA (%)",
         cell: (row) => InputCancellationFee(row),
         sortable: true,
      },
      {
         name: "AKSI",
         width: "350px",
         cell: (row: PenaltyCancellationFee, index: number) => (
            <>
               {(checkFeeValue(row.id) > row.value || checkFeeValue(row.id) < row.value) && (
                   <OverlayTrigger overlay={isInvalidPercentage[index] ?
                       <Tooltip id="tooltip-disabled">Persentase Tidak boleh melebihi 100%</Tooltip> :
                       <div></div>}>
  <span className="d-inline-block">
    <button className="btn button-outline-primary mr-2"
            onClick={() => onUpdateClicked(row)} disabled={isInvalidPercentage[index]}
            style={isInvalidPercentage[index] ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}>
      UBAH
    </button>
  </span>
                   </OverlayTrigger>
               )}
            </>
         ),
      },
   ];

   useEffect(() => {
      dispatch(getListCancellationFee());
      dispatch(resetStatus());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      dispatch(getListCancellationFee());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dateCreated]);

   return (
      //   <Form noValidate validated={validated} onSubmit={onFormSubmit}>
      //      <Form.Group className="mb-4">
      //         <Form.Label>Fee Project 2 Minggu</Form.Label>
      //         <NumberFormat
      //            value={twoWeekPercentage}
      //            className="form-control"
      //            thousandSeparator={"."}
      //            decimalSeparator={","}
      //            suffix={"%"}
      //            onValueChange={(values) => {
      //               setTwoWeekPercentage(values.floatValue ?? 0);
      //            }}
      //         />
      //      </Form.Group>
      //      <Form.Group className="mb-4">
      //         <Form.Label>Fee Project 1 Bulan</Form.Label>
      //         <NumberFormat
      //            value={monthPercentage}
      //            className="form-control"
      //            thousandSeparator={"."}
      //            decimalSeparator={","}
      //            suffix={"%"}
      //            onValueChange={(values) => {
      //               setMonthPercentage(values.floatValue ?? 0);
      //            }}
      //         />
      //      </Form.Group>
      //      <Form.Group className="mb-4">
      //         <Form.Label>Fee Project 2 Bulan</Form.Label>
      //         <NumberFormat
      //            value={twoMonthPercentage}
      //            className="form-control"
      //            thousandSeparator={"."}
      //            decimalSeparator={","}
      //            suffix={"%"}
      //            onValueChange={(values) => {
      //               setTwoMonthPercentage(values.floatValue ?? 0);
      //            }}
      //         />
      //      </Form.Group>
      //   </Form>
      <DataTable
         columns={columns}
         data={cancellationFeeList}
         pagination
         paginationResetDefaultPage={resetPaginationToggle}
         noHeader
         customStyles={TableCustomStyles}
      />
   );
};

export default CancellationFee;
