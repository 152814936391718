import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LSK_USER_SLICE } from "../../constants";
import { ApiErrorResponse } from "../..";
import { LoginCredential } from "./models";
import UserApi from "./user.api";

export interface UserSlice {
   id?: string;
   username?: string;
   expanded?: boolean;
   token?: string;
   role?: string;
   isLoading?: boolean;
   error?: ApiErrorResponse<any>;
   sendForgotStatus?: string;
}

// - IF EXISTS ON LOCAL STORAGE -
// getting initial slice from local storage
const initialSlice = localStorage.getItem(LSK_USER_SLICE)
   ? (JSON.parse(localStorage.getItem(LSK_USER_SLICE) as string) as UserSlice)
   : {};

export const logUserIn = createAsyncThunk(
   "userLoginState/logUserIn",
   async (loginCredential: LoginCredential, { rejectWithValue }) => {
      try {
         return await UserApi.login(loginCredential);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const resetPassword = createAsyncThunk(
   "userLoginState/resetPassword",
   async (email: string, { rejectWithValue }) => {
      try {
         return await UserApi.resetPassword(email);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const userSlice = createSlice({
   name: "userLoginState",
   initialState: initialSlice,
   reducers: {
      setId: (state, action) => {
         state.id = action.payload;
      },
      setUsername: (state, action) => {
         state.username = action.payload;
      },
      setToken: (state, action) => {
         state.token = action.payload;
      },
      setRole: (state, action) => {
         state.role = action.payload;
      },
      setLoading: (state, action) => {
         state.isLoading = action.payload;
      },
      setExpanded: (state, action) => {
         state.expanded = action.payload;
      },
      logoutUser: (state) => {
         state.username = "";
         state.id = "";
         state.token = "";
         state.role = "";
         state.isLoading = false;
      },
      resetError: (state) => {
         state.error = {} as ApiErrorResponse<any>;
      },
      resetStatus: (state) => {
         state.sendForgotStatus = "";
      },
   },
   extraReducers: (builder) => {
      builder.addCase(logUserIn.pending, (state) => {
         state.error = {} as ApiErrorResponse<any>;
         state.isLoading = true;
      });

      builder.addCase(logUserIn.fulfilled, (state, { payload }) => {
         state.username = payload.username;
         state.id = payload.id;
         state.token = payload.token;
         state.role = payload.role;
         state.isLoading = false;
      });

      builder.addCase(logUserIn.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(resetPassword.pending, (state) => {
         state.isLoading = true;
         state.sendForgotStatus = "pending";
      });

      builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.sendForgotStatus = "success";
      });

      builder.addCase(resetPassword.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.sendForgotStatus = "failed";
      });
   },
});

export const {
   setUsername,
   setToken,
   setId,
   setRole,
   setLoading,
   logoutUser,
   setExpanded,
   resetError,
   resetStatus,
} = userSlice.actions;

export default userSlice.reducer;
