import React, { ChangeEvent, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { checkObjectIsNotEmpty } from "../../../helpers/checkEmptyObject";
import { PartnerLevelModel } from "../models";
import { updatePartnerLevel } from "../projectSetting.reducer";

const EditPartnerLevel = ({
   singlePartnerLevel = {} as PartnerLevelModel,
   show = false,
   ...props
}: EditPartnerLevelProps) => {
   const dispatch = useDispatch();

   const [name, setName] = useState<string>("");
   const [subject, setSubject] = useState<string>("");
   const [description, setDescription] = useState<string>("");

   const [minimalPoint, setMinimalPoint] = useState<number>(0);
   const [maximalPoint, setMaximalPoint] = useState<number>(0);
   const [validated, setValidated] = useState(false);

   const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
   };

   useEffect(() => {
      if (checkObjectIsNotEmpty(singlePartnerLevel)) {
         setMinimalPoint(singlePartnerLevel.minimumPoint);
         setMaximalPoint(singlePartnerLevel.maximumPoint);
      }
   }, [singlePartnerLevel]);

   const onSendClicked = () => {
      dispatch(
         updatePartnerLevel({
            id: singlePartnerLevel.id,
            name: singlePartnerLevel.name,
            description: singlePartnerLevel.description,
            order: singlePartnerLevel.order,
            minimumPoint: minimalPoint,
            maximumPoint: maximalPoint,
            logo: singlePartnerLevel.logo,
            color: singlePartnerLevel.color,
         })
      );
   };

   return (
      <Modal
         show={show}
         onHide={props.handleClose}
         aria-labelledby="contained-modal-title-vcenter"
         centered>
         <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Ubah Partner Level</Modal.Title>
         </Modal.Header>
         <Form
            noValidate
            validated={validated}
            onSubmit={(e) => {
               const form = e.currentTarget;
               if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
               } else {
                  e.preventDefault();
                  onSendClicked();
                  setSubject("");
                  setDescription("");
                  props.handleClose();
               }
               setValidated(true);
            }}>
            <Modal.Body>
               <Form.Group className="mb-4">
                  <Form.Label>Nama Level</Form.Label>
                  <Form.Control
                     onChange={onNameChange}
                     placeholder="Nama"
                     value={singlePartnerLevel.name}
                     readOnly
                  />
               </Form.Group>
               <Form.Group className="mb-4">
                  <Form.Label>Minimal Poin</Form.Label>
                  <NumberFormat
                     value={minimalPoint}
                     className="form-control d-inline field_plaint_text"
                     thousandSeparator={"."}
                     decimalSeparator={","}
                     suffix={" Point"}
                     onValueChange={(values) => {
                        setMinimalPoint(values.floatValue ?? 0);
                     }}
                  />
               </Form.Group>
               <Form.Group className="mb-4">
                  <Form.Label>Maksimal Poin</Form.Label>
                  <NumberFormat
                     value={maximalPoint}
                     className="form-control d-inline field_plaint_text"
                     thousandSeparator={"."}
                     decimalSeparator={","}
                     suffix={" Point"}
                     onValueChange={(values) => {
                        setMaximalPoint(values.floatValue ?? 0);
                     }}
                  />
               </Form.Group>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn button-outline-primary"
                  type="button"
                  onClick={props.handleClose}>
                  TUTUP
               </button>
               <button className="btn button-primary">UBAH</button>
            </Modal.Footer>
         </Form>
      </Modal>
   );
};

export interface EditPartnerLevelProps {
   singlePartnerLevel: PartnerLevelModel;
   show: boolean;
   handleClose: () => void;
}

export default EditPartnerLevel;
