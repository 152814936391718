import React, {useEffect, useState} from 'react';
import "../../assets/styles/image_viewer.css"
import {ImageList} from "./models";
import {BiChevronLeftCircle, BiChevronRightCircle} from "react-icons/bi";

enum Direction { left, right }

const ImageViewer = ({images = [], visible = false, defaultIndex = 0, onClose}: ImageViewerProps) => {
    const [imageIndex, setImageIndex] = useState<number>(0);
    const [currentImage, setCurrentImage] = useState<ImageList>({} as ImageList);

    useEffect(() => {
        if (images.length > 0) {
            setCurrentImage(images[defaultIndex]);
            setImageIndex(defaultIndex);
        }
    }, [images, defaultIndex]);

    const onIndexChange = (direction: Direction) => {
        if (direction === Direction.left && imageIndex > 0) {
            setImageIndex(imageIndex - 1);
            setCurrentImage(images[imageIndex - 1]);
        } else if (direction === Direction.right && imageIndex < images.length - 1) {
            setImageIndex(imageIndex + 1);
            setCurrentImage(images[imageIndex + 1]);
        }
    }

    const resetIndex = () => {
        setImageIndex(0);
        setCurrentImage(images[0]);
    }

    const onCloseAction = () => {
        resetIndex();
        onClose && onClose();
    }

    return (
        <>
            {
                visible && (
                    <div className={`image-viewer`}>
                        <span className="close" onClick={onCloseAction}>&times;</span>
                        <div className="image-viewer-container">
                            <div className="viewer-left-button">
                                <span onClick={() => onIndexChange(Direction.left)}>
                                    <BiChevronLeftCircle color="#fff" fontSize={42}/>
                                 </span>
                            </div>
                            <div className="d-flex align-items-center">
                                <img className="viewer-modal-content" id="full-image" src={currentImage.src}
                                     alt={currentImage.alt}/>
                            </div>
                            <div className="viewer-right-button">
                                <span onClick={() => onIndexChange(Direction.right)}>
                                    <BiChevronRightCircle color="#fff" fontSize={42}/>
                                </span>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export interface ImageViewerProps {
    images: ImageList[];
    visible: boolean;
    defaultIndex?: number;
    onClose?: () => void;
}

export default ImageViewer;
