import React, {useEffect, useMemo, useState} from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Tabs,
  Nav,
  Alert,
  Button,
} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";
import AboutMePage from "../../aboutMe/containers/AboutMePage";
import BlogPage from "../../blog/containers/BlogPage";
import DataDiriPage from "../../dataDiri/containers/DataDiriPage";
import {resetVerifyUser, verifyUser} from "../../dataDiri/dataDiri.reducer";
import OperationalPage from "../../operational/containers/OperationalPage";
import PortfolioPage from "../../portfolio/containers/PortfolioPage";
import ProductPage from "../../product/containers/ProductPage";
import ExpertisePage from "../../tagExpertise/containers/ExpertisePage";
import RevisionModal from "../components/RevisionModal";
import {RootState} from "../../../../app/store";
import {UserModel} from "../models";
import ComponentCircleLoader from "../../../../components/ComponentCircleLoader";
import {ApiErrorResponse} from "../../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";
import ChangePasswordModal from "../components/ChangePasswordModal";
import {getUserSingle} from "../dataMitra.reducer";

interface ReadOnlyURLSearchParams extends URLSearchParams {
  append: never;
  set: never;
  delete: never;
  sort: never;
}

const DataMitraDetailPage = () => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  let history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(
      () => new URLSearchParams(search) as ReadOnlyURLSearchParams,
      [search]
  );
  let { id } = useParams<{ id: string }>();
  const [isAdminVerified, setIsAdminVerified] = useState(false);
  const [isModalRevisionShow, setIsModalRevisionShow] = useState(false);
  const [isModalPasswordShow, setIsModalPasswordShow] = useState(false);

  const singleUserState = useSelector(
      (state: RootState) => state.dataMitra.singleUser ?? {} as UserModel
  );
  const isVerifyLoading = useSelector((state: RootState) => state.dataDiri.isVerifyLoading);
  const verifyStatus = useSelector((state: RootState) => state.dataDiri.verifyStatus);
  const verifyError = useSelector((state: RootState) => state.dataDiri.verifyError ?? ({} as ApiErrorResponse<any>));

  useEffect(() => {
    setIsAdminVerified(localStorage.getItem("__usr_verif") === "true");
  }, []);

  useEffect(() => {
    if (verifyStatus !== undefined && verifyStatus !== ApiResponseStatus.pending) {
      MySwal.fire(
          savedStatusOption(
              verifyStatus === ApiResponseStatus.success,
              verifyError.data?.message
          )
      ).then(() => {
        dispatch(resetVerifyUser());
        if (verifyStatus === ApiResponseStatus.success) {
          history.push("/mitra/account/personal-data/list");
        }
      });
    }
  }, [verifyStatus, verifyError]);

  useEffect(() => {
    if (id) {
      dispatch(getUserSingle(id));
    }
  }, [id]);


  const onVerifyClicked = () => {
    MySwal.fire({
      text: `Apakah kamu yakin akan memverifikasi akun ini?`,
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(verifyUser(id));
      }
    });
  };

  const handleModalRevision = () => {
    isModalRevisionShow
      ? setIsModalRevisionShow(false)
      : setIsModalRevisionShow(true);
  };
  const handleModalPassword = () => {
    setIsModalPasswordShow(prevState => !prevState);
  };
  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <ComponentCircleLoader loading={isVerifyLoading} >
          <>
          <hr />
          <button
            className="float-right btn button-primary"
            onClick={onVerifyClicked}
            disabled={singleUserState.registerStatus === 2}
          >
            {singleUserState.registerStatus === 2 ? "Verified" : "Verify This Account"}
          </button>
          <button
            className="float-right btn button-primary mr-2"
            onClick={handleModalRevision}
          >
            Revisi
          </button>
            <button
                className="float-right btn button-primary mr-2"
                onClick={handleModalPassword}
            >
              Ganti Password
            </button>
          <Tabs
            className="profile_tab_style"
            defaultActiveKey={searchParams.get('tab') ?? "dataDiri"}
            id="uncontrolled-tab-example"
          >
            <Tab
              eventKey="dataDiri"
              title="DATA DIRI"
              className="profile_tab_body"
            >
              <DataDiriPage />
            </Tab>
            <Tab
              eventKey="expertise"
              title="EXPERTISE"
              className="profile_tab_body"
            >
              <ExpertisePage />
            </Tab>
            <Tab
              eventKey="portfolio"
              title="PORTFOLIO"
              className="profile_tab_body"
            >
              <PortfolioPage />
            </Tab>
            <Tab
              eventKey="product"
              title="PAKET & PRODUK"
              className="profile_tab_body"
            >
              <ProductPage />
            </Tab>
            <Tab eventKey="blog" title="BLOG" className="profile_tab_body">
              <BlogPage />
            </Tab>
            <Tab
              eventKey="aboutme"
              title="TENTANG SAYA"
              className="profile_tab_body"
            >
              <AboutMePage />
            </Tab>
            {/* <Tab
              eventKey="operational"
              title="OPERATIONAL"
              className="profile_tab_body"
            >
              <OperationalPage />
            </Tab> */}
          </Tabs>
          </>
          </ComponentCircleLoader>
        </Col>
      </Row>
      <RevisionModal
        isShow={isModalRevisionShow}
        onCloseHandler={handleModalRevision}
      />
      <ChangePasswordModal
          isShow={isModalPasswordShow}
          onCloseHandler={handleModalPassword}
          userId={id}
      />
    </Container>
  );
};

export default DataMitraDetailPage;
