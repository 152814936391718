import React from 'react';
import {Container, Row} from "react-bootstrap";
import DetailDataProject from "../components/DetailDataProject";

const DataProjectDetailPage = () => {
    return (
        <Container className="content-body" style={{backgroundColor: "#f9f9f8", height: "100vh"}} fluid>
            <Row>
                <DetailDataProject />
            </Row>
        </Container>
    );
};

export default DataProjectDetailPage;
