import React, {useEffect, useState} from 'react';
import {Row, Container, Col} from "react-bootstrap";
import {BsArrowRightCircle} from "react-icons/bs";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {EventStaticEnum, EventSummaryModel} from "../models";
import EventStatisticByDateTable from "../components/eventStatistic/EventStatisticByDateTable";
import EventStatisticByUserTable from "../components/eventStatistic/EventStatisticByUserTable";
import {useParams} from "react-router-dom";
import {getEventSummary} from "../marketingEvent.reducer";

const MitraStatisticPage = () => {
    const dispatch = useDispatch();
    let { id } = useParams<{ id: string }>();

    const eventSummary = useSelector((state: RootState) => state.marketingEvent.eventSummary ?? {} as EventSummaryModel);

    const [activeTag, setActiveTag] = useState<EventStaticEnum>(EventStaticEnum.Date);

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getEventSummary(id));
        }
    }, [id]);

    return (
        <Container className="content-body" fluid>
            <Row>
                <Col lg={3} xs={6} className="mb-3">
                    <div className="card-counter card-warning-color text-light">
                        <div className="flex-grow-1 p-3">
                            <div className="card-counter-value">
                                <h1 className="mb-0">{eventSummary.totalView}</h1>
                            </div>
                            <div className="card-counter-label">
                                <span>Viewers</span>
                            </div>
                        </div>
                        <div style={{cursor: "pointer"}} className="p-2">
                            <BsArrowRightCircle size={18}/>
                        </div>
                    </div>
                </Col>
                <Col lg={3} xs={6} className="mb-3">
                    <div className="card-counter card-success-color text-light">
                        <div className="flex-grow-1 p-3">
                            <div className="card-counter-value">
                                <h1 className="mb-0">{eventSummary.totalDownload}</h1>
                            </div>
                            <div className="card-counter-label">
                                <span>Download</span>
                            </div>
                        </div>
                        <div  style={{cursor: "pointer"}} className="p-2">
                            <BsArrowRightCircle size={18}/>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className={"my-3"}>
                <Col md={12} className="mb-3">
                    <h4 className="card-label d-inline">Statistik Event</h4>
                </Col>
                <Col md={12}>
                    <div>
                        <div className="d-flex" style={{
                            borderBottom: "1px solid #a0a0a0",
                        }}>
                            <div className="flex-grow-1 d-flex justify-content-start align-items-end">
                                <div className="d-inline">
                                    <button
                                        onClick={() => {
                                            setActiveTag(EventStaticEnum.Date);
                                        }}
                                        className={`btn mr-1 tab-button ${
                                            activeTag === EventStaticEnum.Date ? "active" : ""
                                        }`}
                                    >
                                        TANGGAL
                                    </button>
                                    <button
                                        onClick={() => {
                                            setActiveTag(EventStaticEnum.User);
                                        }}
                                        className={`btn mr-1 tab-button ${
                                            activeTag === EventStaticEnum.User ? "active" : ""
                                        }`}
                                    >
                                        PENGGUNA
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"my-3"}>
                        {
                            activeTag === EventStaticEnum.Date &&
                            <EventStatisticByDateTable />
                        }
                        {
                            activeTag === EventStaticEnum.User &&
                            <EventStatisticByUserTable />
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default MitraStatisticPage;
