import React, { ChangeEvent, SetStateAction, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ImageUpload } from "../../../../@core/models/media/ImageUpload";
import FormFile from "../../../../components/FormField/FormFile";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import NumberFormat from "react-number-format";
import { DiscountFormModel, discountType, VoucherModel } from "../models";
import { checkObjectIsNotEmpty } from "../../../../helpers/checkEmptyObject";

type VoucherTypeOptionModel = {
   label: string;
   value: number;
};

const DiscountForm = ({
   isEdit = false,
   data = {} as VoucherModel,
   ...props
}: DiscountFormProps) => {
   const history = useHistory();
   const editorRef = useRef();

   const [name, setName] = useState<string>("");
   const [description, setDescription] = useState<string>("");
   const [code, setCode] = useState<string>("");
   const [isActive, setIsActive] = useState<boolean>(false);
   const [isUnlimited, setIsUnlimited] = useState<boolean>(false);
   const [validated, setValidated] = useState<boolean>(false);
   const [editorLoaded, setEditorLoaded] = useState<boolean>(false);
   const [termAndCondition, setTermAndCondition] = useState<string>("");
   const [image, setImage] = useState<ImageUpload>({} as ImageUpload);
   // const [errorImage, setErrorImage] = useState<boolean>(false);
   const [expiredDate, setExpiredDate] = useState(new Date());
   const [selectedType, setSelectedType] = useState<number>(0);
   const [selectedTypeOption, setSelectedTypeOption] = useState({
      label: "Percentage",
      value: 0,
   });
   const [nominal, setNominal] = useState<number>(0);
   const [percentage, setPercentage] = useState<number>(0);
   const [minimumTransaction, setMinimumTransaction] = useState<number>(0);
   const [quota, setQuota] = useState<number>(0);
   const [voucherTypeOption] = useState<VoucherTypeOptionModel[]>([
      {
         label: "Percentage",
         value: 0,
      },
      {
         label: "Nominal",
         value: 1,
      },
   ]);
   console.log(isEdit);
   useEffect(() => {
      if (isEdit) {
         if (checkObjectIsNotEmpty(data)) {
            setIsActive(data.isActive);
            console.log(data);
            setSelectedTypeOption(
               data.type === 0
                  ? {
                       label: "Percentage",
                       value: 0,
                    }
                  : {
                       label: "Nominal",
                       value: 1,
                    }
            );
         }
      }
   }, [isEdit, data]);

   const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
   };

   const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(e.target.value);
   };

   const onCodeChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setCode(e.target.value);
   };

   const onContentChangeHandler = (
      event: any,
      editor: { getData: () => SetStateAction<string> }
   ) => {
      setTermAndCondition(editor.getData());
   };

   const onTypeChangeHandler = (data: any) => {
      setSelectedType(data.value);
      setSelectedTypeOption(data);
   };

   const onIsActiveChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
      e.target.checked ? setIsActive(true) : setIsActive(false);
   };

   const onUnlimitedChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
      e.target.checked ? setIsUnlimited(true) : setIsUnlimited(false);
   };

   const onCancelHandler = () => {
      history.push("/master/discount/list");
   };

   const handleFileUpload = (name: string, fileFormat: string, fileValue: string) => {
      setImage({
         name,
         fileFormat,
         fileValue,
         fromApi: false,
      });
   };

   useEffect(() => {
      editorRef.current = {
         // CKEditor: require('@ckeditor/ckeditor5-react'), // depricated in v3
         CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
         Editor: require("ckeditor5-custom-build/build/ckeditor"),
      } as any;
      setEditorLoaded(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (checkObjectIsNotEmpty(data)) {
         setIsActive(data.isActive);
         setIsUnlimited(data.isUnlimited);
         setName(data.name);
         setDescription(data.description);
         setTermAndCondition(data.termAndCondition);
         setImage({
            name: "",
            fileFormat: "",
            fileValue: data.banner,
            fromApi: true,
         });
         setCode(data.code);
         setExpiredDate(new Date(data.expiredDate));
         setSelectedType(data.type);
         setPercentage(data.percentage);
         setNominal(data.nominal);
         setMinimumTransaction(data.minimumTransaction);
         setQuota(data.quota);
         // setDefaultCategoryValue({
         //    label: data.blogCategory[0].name,
         //    value: data.blogCategory[0].id,
         // });
      }
   }, [data]);

   const { CKEditor, Editor } = editorRef.current || ({} as any);

   const toolbarConfig = {
      toolbar: [
         "heading",
         "|",
         "bold",
         "italic",
         "blockQuote",
         "link",
         "numberedList",
         "bulletedList",
         "imageUpload",
         "insertTable",
         "tableColumn",
         "tableRow",
         "mergeTableCells",
         "mediaEmbed",
         "|",
         "undo",
         "redo",
      ],
   };

   return (
      <Form
         noValidate
         validated={validated}
         onSubmit={(e) => {
            const form = e.currentTarget;
            if (!form.checkValidity()) {
               e.preventDefault();
               e.stopPropagation();
            } else {
               e.preventDefault();
               if (image.fileValue === undefined) {
                  alert("image can't be empty");
               } else {
                  props.onSubmit({
                     id: data.id,
                     name,
                     description,
                     termAndCondition,
                     banner: data.banner,
                     code,
                     expiredDate: expiredDate.toISOString(),
                     isUnlimited,
                     type: selectedType,
                     nominal,
                     percentage,
                     minimumTransaction,
                     quota,
                     image,
                     isActive,
                  } as DiscountFormModel);
                  setName("");
                  setDescription("");
                  // history.replace("/master/discount/list");
               }
            }
            setValidated(true);
         }}>
         <Form.Group>
            <Form.Check
               type="switch"
               inline
               className="mr-5"
               id="isActive-switch"
               label="Aktifkan"
               checked={isActive}
               onChange={onIsActiveChangeHandler}
            />
            <Form.Check
               type="switch"
               inline
               id="unlimited-switch"
               label="Voucher Tak Terbatas"
               checked={isUnlimited}
               onChange={onUnlimitedChangeHandler}
            />
         </Form.Group>
         <Form.Group className="mb-4">
            <Form.Label>Nama Voucher</Form.Label>
            <Form.Control
               type="text"
               placeholder="Masukkan Nama Voucher"
               onChange={onNameChange}
               value={name}
               required
            />
         </Form.Group>
         <Form.Group className="mb-4">
            <Form.Label>Deskripsi Voucher</Form.Label>
            <Form.Control
               as="textarea"
               rows={3}
               onChange={onDescriptionChange}
               placeholder="Isi detail diskon ( contoh : voucher event a dibulan maret)"
               value={description}
               required
            />
         </Form.Group>
         <Form.Group className="mb-4">
            <Form.Label>Syarat dan Ketentuan</Form.Label>
            {editorLoaded ? (
               <CKEditor
                  editor={Editor}
                  config={toolbarConfig}
                  data={termAndCondition}
                  onChange={onContentChangeHandler}
               />
            ) : (
               <div>Editor loading</div>
            )}
         </Form.Group>
         <Form.Group className="mb-4">
            <FormFile
               name="banner"
               label="Banner"
               defaultImage={image.fileValue}
               handleAcceptedFiles={handleFileUpload}
               height={200}
               required={!isEdit}
               // onError={errorImage}
               defaultFromApi={image.fromApi}
            />
         </Form.Group>
         <Form.Group className="mb-4">
            <Form.Label>Kode Voucher</Form.Label>
            <Form.Control
               type="text"
               placeholder="Masukkan Kode Voucher"
               onChange={onCodeChange}
               value={code}
               required
            />
         </Form.Group>
         <Form.Group className="mb-4">
            <Form.Label>Tanggal Kadaluarsa Voucher</Form.Label>
            <DatePicker
               className="form-control"
               selected={expiredDate}
               onChange={(date) => setExpiredDate(date as Date)}
            />
         </Form.Group>
         <Form.Group className="mb-4">
            <Form.Label>Tipe Voucher</Form.Label>
            <Select
               className="basic-single"
               classNamePrefix="select"
               placeholder="Ketik untuk mencari Tipe Voucher"
               isSearchable={true}
               name="type"
               options={voucherTypeOption}
               onChange={onTypeChangeHandler}
               value={selectedTypeOption}
            />
         </Form.Group>
         {selectedType === discountType.percentage && (
            <Form.Group className="mb-4">
               <Form.Label>Persentase</Form.Label>
               <NumberFormat
                  value={percentage}
                  className="form-control d-inline field_plaint_text"
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  suffix={" %"}
                  onValueChange={(values) => {
                     setPercentage(values.floatValue ?? 0);
                  }}
               />
            </Form.Group>
         )}
         {selectedType === discountType.nominal && (
            <Form.Group className="mb-4">
               <Form.Label>Nominal</Form.Label>
               <NumberFormat
                  value={nominal}
                  className="form-control d-inline field_plaint_text"
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp. "}
                  onValueChange={(values) => {
                     setNominal(values.floatValue ?? 0);
                  }}
               />
            </Form.Group>
         )}

         <Form.Group className="mb-4">
            <Form.Label>Minimal Transaksi</Form.Label>
            <NumberFormat
               value={minimumTransaction}
               className="form-control d-inline field_plaint_text"
               thousandSeparator={"."}
               decimalSeparator={","}
               prefix={"Rp. "}
               onValueChange={(values) => {
                  setMinimumTransaction(values.floatValue ?? 0);
               }}
            />
         </Form.Group>
         <Form.Group className="mb-4">
            <Form.Label>Kuota {isUnlimited && "(Tidak Terbatas)"}</Form.Label>
            <NumberFormat
               value={quota}
               className="form-control d-inline field_plaint_text"
               thousandSeparator={"."}
               decimalSeparator={","}
               disabled={isUnlimited}
               onValueChange={(values) => {
                  setQuota(values.floatValue ?? 0);
               }}
            />
         </Form.Group>
         <div className="text-center mt-5">
            <button
               className="btn button-outline-primary mr-3"
               type="reset"
               onClick={onCancelHandler}>
               BATALKAN
            </button>
            <button className="btn button-primary" type="submit">
               {isEdit ? "EDIT VOUCHER" : "TAMBAH VOUCHER"}
            </button>
         </div>
      </Form>
   );
};

export interface DiscountFormProps {
   isEdit: boolean;
   data: VoucherModel;
   onSubmit: (formData: DiscountFormModel) => void;
}

export default DiscountForm;
