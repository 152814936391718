import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button } from "react-bootstrap";
import { RootState } from "../../../../app/store";
import { getLanguageList, deleteLanguage, resetStatus } from "../language.reducer";
import { Link } from "react-router-dom";
import { LanguageModel } from "../models";
import DataTable, {TableColumn} from "react-data-table-component";
import DefaultSubHeader from "../../../../components/DataTable/DefaultSubHeader";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { ElementPropModel } from "../../../../components/DataTable/models";
import {ApiErrorResponse} from "../../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {deletedStatusOption, deletedWarnOption} from "../../../../helpers/alertContent";

const LanguageTable = () => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const languageState = useSelector(
    (state: RootState) => state.language.list ?? []
  );
  const status = useSelector((state: RootState) => state.language.status);
  const error = useSelector((state: RootState) => state.language.error ?? ({} as ApiErrorResponse<any>));

  const filteredItems = languageState.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    dispatch(getLanguageList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status !== undefined && status !== ApiResponseStatus.pending) {
      MySwal.fire(deletedStatusOption(status === ApiResponseStatus.success, error.data?.message)).then(() => {
        dispatch(resetStatus());
        dispatch(getLanguageList());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error]);

  const onSetterFilterText = (value: string) => {
    setFilterText(value);
  };

  const onDelete = (id: string) => {
    MySwal.fire(deletedWarnOption).then((result) => {
      if (result.isConfirmed) {
        dispatch(
            deleteLanguage({
              id: id,
              name: "deleted",
              description: "deleted",
            } as LanguageModel)
        );
      }
    });
  };

  const elementProps = {
    placeholder: "cari berdasarkan nama",
    buttonName: "tambah bahasa",
    createUrl: "/master/language/create",
    subHeaderLine: true,
  } as ElementPropModel;

  const columns: TableColumn<LanguageModel>[] = [
    {
      name: "NO.",
      width: "60px",
      cell: (_d: any, index: number) => index + 1,
    },
    {
      name: "NAMA BAHASA",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "DESKRIPSI",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "AKSI",
      cell: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/master/language/edit/${d.id}`,
            }}
          >
            <button className="btn button-primary mr-2">EDIT</button>
          </Link>
          <button
            onClick={() => onDelete(d.id)}
            className="btn button-danger"
          >
            HAPUS
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        noHeader
        subHeader
        subHeaderComponent={DefaultSubHeader(
          filterText,
          resetPaginationToggle,
          onSetterFilterText,
          elementProps
        )}
        customStyles={TableCustomStyles}
        persistTableHead
      />
    </>
  );
};

export default LanguageTable;
