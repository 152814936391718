export enum SortEnum {
    asc,
    desc
}

export enum StoreProductSortByEnum
{
   Name,
   Date,
   TotalSold,
   Commision
}

export interface StoreSalesStatisticModel {
   id: string;
   name: string;
   totalSold: number;
   price: number;
   komisi: number;
   classDate: string;
}

export interface StoreSalesDetailStatisticModel {
   name: string;
   createdDate: string;
}

export interface StoreSalesStatisticRequestModel {
   filter?: string;
   skip: number;
   take: number;
   sort: SortEnum;
   column: StoreProductSortByEnum;
}

export interface StoreSalesDetailStatisticRequestModel {
   storeProductId: string;
   filter?: string;
   skip: number;
   take: number;
}