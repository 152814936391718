import React, {Fragment, useEffect} from 'react';
import DataTable, {TableColumn} from "react-data-table-component";
import {TableCustomStyles} from "../../../../components/DataTable/TableCustomStyles";
import {MAX_PAGING_OPTIONS} from "../../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import moment from "moment";
import NumberFormat from "react-number-format";
import {EventStatisticByDateModel} from "../../models";
import {getStatistikEventByDate} from "../../marketingEvent.reducer";
import {RootState} from "../../../../app/store";

const EventStatisticByDateTable = () => {
    const dispatch = useDispatch();
    let { id } = useParams<{ id: string }>();

    const eventStatisticByDateList = useSelector((state: RootState) => state.marketingEvent.eventStatisticByDateList ?? []);

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getStatistikEventByDate(id));
        }
    }, [id]);

    const columns: TableColumn<EventStatisticByDateModel>[] = [
        {
            name: "NOMOR",
            width: "60px",
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: "TANGGAL",
            selector: (row) => row.date ?? "",
            format: (row: any) => moment(row.date).format("DD/MM/YYYY"),
            sortable: true,
        },
        {
            name: "JUMLAH VIEW (UNIK)",
            selector: (row) => row.totalView ?? "0",
            format: (row) => <Fragment>
                <NumberFormat
                    value={row.totalView}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                />
            </Fragment>,
            sortable: true,
        },
        {
            name: "JUMLAH KUNJUNGAN (FREQ)",
            selector: (row) => row.totalVisit ?? "0",
            format: (row) => <Fragment>
                <NumberFormat
                    value={row.totalVisit}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                />
            </Fragment>,
            sortable: true,
        },
        {
            name: "JUMLAH DOWNLOAD (FREQ)",
            selector: (row) => row.totalDownload ?? "0",
            format: (row) => <Fragment>
                <NumberFormat
                    value={row.totalDownload}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                />
            </Fragment>,
            sortable: true,
        },
    ]

    return (
        <div>
            <div className={"my-3"}>
                <DataTable
                    columns={columns}
                    data={eventStatisticByDateList}
                    pagination
                    paginationResetDefaultPage={false}
                    noHeader
                    customStyles={TableCustomStyles}
                    progressPending={false}
                    persistTableHead
                    paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
                />
            </div>
        </div>
    );
};

export default EventStatisticByDateTable;
