import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { ExpertiseModel, UserModel, TagExpertiseTypeModel } from "./models";
import ExpertiseApi from "./tagExpertise.api";

export interface CreateExpertiseSlice {
  name?: string;
  list?: ExpertiseModel[];
  personalTypeList?: TagExpertiseTypeModel[];
  commercialTypeList?: TagExpertiseTypeModel[];
  vendorTypeList?: TagExpertiseTypeModel[];
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

export const getExpertise = createAsyncThunk(
  "expertiseState/expertiseList",
  async (mitraId: string, { rejectWithValue }) => {
    try {
      return await ExpertiseApi.getExpertise(mitraId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getProfessionalExpertiseType = createAsyncThunk(
  "expertiseState/expertiseTypeList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await ExpertiseApi.getProfessionalExpertiseList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getVendorExpertiseType = createAsyncThunk(
  "expertiseState/expertiseVendorTypeList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await ExpertiseApi.getVendorExpertiseList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const createExpertiseSlice = createSlice({
  name: "createExpertiseState",
  initialState: {} as CreateExpertiseSlice,
  reducers: {
    // setFilter: (state, action) => {
    //   state.list = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfessionalExpertiseType.pending, (state) => {
      state.personalTypeList = [];
      state.commercialTypeList = [];
      state.isLoading = true;
    });
    builder.addCase(
      getProfessionalExpertiseType.fulfilled,
      (state, { payload }) => {
        const personal = payload.filter(
          (item: { parentId: string }) =>
            item.parentId === "e3a2cb6b-fdda-4a65-bf7e-952445c94f61"
        );
        const commercial = payload.filter(
          (item: { parentId: string }) =>
            item.parentId === "ef962335-7ee6-4204-9d7e-384cfd7bd289"
        );
        state.personalTypeList = personal;
        state.commercialTypeList = commercial;
        state.isLoading = false;
      }
    );
    builder.addCase(
      getProfessionalExpertiseType.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
      }
    );
    builder.addCase(getVendorExpertiseType.pending, (state) => {
      state.vendorTypeList = [];
      state.isLoading = true;
    });
    builder.addCase(getVendorExpertiseType.fulfilled, (state, { payload }) => {
      const vendor = payload.filter(
        (item: { parentId: string }) =>
          item.parentId === "f89d4259-e2b2-41a3-b38d-42a3e4aa5b70"
      );
      state.vendorTypeList = vendor;
      state.isLoading = false;
    });
    builder.addCase(getVendorExpertiseType.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getExpertise.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getExpertise.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getExpertise.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
  },
});

// export const { setFilter } = createProfessionalTypeSlice.actions;
export default createExpertiseSlice.reducer;
