import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import {CreateTagExpertiseTypeModel, ExpertiseChildCategoryModel, TagExpertiseTypeModel} from "./models";
import TagExpertiseTypeApi from "./tagExpertise.api";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {OptionModel} from "../../../@core/models/types";

export interface TagExpertiseTypeSlice {
   name?: string;
   list?: TagExpertiseTypeModel[];
   professionalList?: TagExpertiseTypeModel[];
   vendorList?: TagExpertiseTypeModel[];
   expertiseCategoryOptions?: OptionModel[];
   dateCreated?: string;
   single?: object;
   isLoading?: boolean;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
}

export const createTagExpertiseType = createAsyncThunk(
   "createTagExpertiseTypeState/createTagExpertiseType",
   async (imageData: CreateTagExpertiseTypeModel, { rejectWithValue }) => {
      try {
         const response = await TagExpertiseTypeApi.uploadImage(imageData.fileData);
         if (response.status === 200) {
            let formData = imageData.formData;
            formData.iconUrl = response.data.imagepath;
            return await TagExpertiseTypeApi.createTagExpertiseType(formData);
         }
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getTagExpertiseTypeList = createAsyncThunk(
   "tagExpertiseTypeState/tagExpertiseTypeList",
   async (type: string, { rejectWithValue }) => {
      try {
         return await TagExpertiseTypeApi.getTagExpertiseTypeList(type);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getTagExpertiseTypeProfessionalList = createAsyncThunk(
   "tagExpertiseTypeState/professionalTypeList",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await TagExpertiseTypeApi.getTagExpertiseTypeList("1");
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getTagExpertiseTypeVendorList = createAsyncThunk(
   "tagExpertiseTypeState/vendorTypeList",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await TagExpertiseTypeApi.getTagExpertiseTypeList("0");
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getTagExpertiseTypeSingle = createAsyncThunk(
   "tagExpertiseTypeState/tagExpertiseTypeSingle",
   async (singleData: { type: string; id: string }, { rejectWithValue }) => {
      try {
         const response = await TagExpertiseTypeApi.getTagExpertiseTypeList(singleData.type);
         const filteredData = response.find((item: { id: string }) => item.id === singleData.id);
         console.log(filteredData);
         return filteredData;
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const updateExpertise = createAsyncThunk(
   "createTagExpertiseTypeState/updateExpertise",
   async (tagExpertiseTypeModel: TagExpertiseTypeModel, { rejectWithValue }) => {
      try {
         return await TagExpertiseTypeApi.updateTagExpertiseType(tagExpertiseTypeModel);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const updateTagExpertiseType = createAsyncThunk(
   "createTagExpertiseTypeState/updateTagExpertiseType",
   async (imageData: CreateTagExpertiseTypeModel, { rejectWithValue }) => {
      try {
         const response = await TagExpertiseTypeApi.uploadImage(imageData.fileData);
         if (response.status === 200) {
            let formData = imageData.formData;
            formData.iconUrl = response.data.imagepath;
            return await TagExpertiseTypeApi.updateTagExpertiseType(formData);
         }
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const deleteExpertiseType = createAsyncThunk(
   "createTagExpertiseTypeState/deleteExpertiseType",
   async (id: string, { rejectWithValue }) => {
      try {
         return await TagExpertiseTypeApi.deleteExpertiseType(id);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getExpertiseChildCategoryList = createAsyncThunk(
    "tagExpertiseTypeState/getExpertiseChildCategoryList",
    async (_: string | undefined = undefined, { rejectWithValue }) => {
       try {
          return await TagExpertiseTypeApi.getExpertiseChildCategoryList();
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

const createTagExpertiseTypeSlice = createSlice({
   name: "tagExpertiseTypeState",
   initialState: {} as TagExpertiseTypeSlice,
   reducers: {
      setFilter: (state, action) => {
         console.log(action.payload);
         const filteredTagExpertiseVendor = action.payload.filter(
            (item: { type: number }) => item.type === 0
         );
         const filteredTagExpertiseProfessional = action.payload.filter(
            (item: { type: number }) => item.type === 1
         );
         state.vendorList = filteredTagExpertiseVendor;
         state.professionalList = filteredTagExpertiseProfessional;
      },
      resetSingle: (state) => {
         state.single = {} as TagExpertiseTypeModel;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(createTagExpertiseType.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(createTagExpertiseType.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.status = ApiResponseStatus.success;
         state.dateCreated = new Date().toISOString();
      });
      builder.addCase(createTagExpertiseType.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(getTagExpertiseTypeSingle.pending, (state) => {
         state.single = undefined;
         state.isLoading = true;
      });
      builder.addCase(getTagExpertiseTypeSingle.fulfilled, (state, { payload }) => {
         state.single = payload;
         state.isLoading = false;
      });
      builder.addCase(getTagExpertiseTypeSingle.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(getTagExpertiseTypeList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getTagExpertiseTypeList.fulfilled, (state, { payload }) => {
         state.list = payload;
         state.isLoading = false;
      });
      builder.addCase(getTagExpertiseTypeList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(getTagExpertiseTypeProfessionalList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getTagExpertiseTypeProfessionalList.fulfilled, (state, { payload }) => {
         state.professionalList = payload;
         state.isLoading = false;
      });
      builder.addCase(getTagExpertiseTypeProfessionalList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(getTagExpertiseTypeVendorList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getTagExpertiseTypeVendorList.fulfilled, (state, { payload }) => {
         state.vendorList = payload;
         state.isLoading = false;
      });
      builder.addCase(getTagExpertiseTypeVendorList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(updateTagExpertiseType.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(updateTagExpertiseType.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.dateCreated = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(updateTagExpertiseType.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(updateExpertise.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(updateExpertise.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.dateCreated = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(updateExpertise.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(deleteExpertiseType.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(deleteExpertiseType.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.dateCreated = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(deleteExpertiseType.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(getExpertiseChildCategoryList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getExpertiseChildCategoryList.fulfilled, (state, { payload }) => {
         state.expertiseCategoryOptions = payload.map((item: ExpertiseChildCategoryModel) => {
            return {
                label: item.name,
                value: item.id,
                text: item.name
            }
         })
         state.isLoading = false;
      });
      builder.addCase(getExpertiseChildCategoryList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

   },
});

export const { setFilter, resetSingle, resetStatus } = createTagExpertiseTypeSlice.actions;
export default createTagExpertiseTypeSlice.reducer;
