import axios from "axios";
import {BlogModel, GetBlogTypeRequestModel, PinBlogModel} from "./models";
import { endpoint } from "../../../constants";
import { UploadImageModel } from "../../customer/banner/models";
import { CreateBlogModel } from "../../dataMitra/blog/models";

export default class BlogApi {
   static createBlog(blogData: CreateBlogModel) {
      return axios.post<any>(endpoint + "/api/Blog/Create", blogData).then((res) => res.data.data);
   }

   static getBlogList() {
      return axios.get<any>(endpoint + "/api/Blog/GetList").then((res) => res.data.data);
   }

   static getBlogAutoComplete(filter: string) {
      return axios.get<any>(endpoint + "/api/Blog/GetListAutoComplete", {
            params: {
                filter: filter,
            },
      }).then((res) => res.data.data);
   }

   static getSingleItem(blogId: string) {
      return axios
         .get<any>(endpoint + "/api/Blog/GetSingleItem?id=" + blogId)
         .then((res) => res.data.data);
   }

   static getBlogByUserId(userId: string) {
      return axios
         .get<any>(endpoint + "/api/Blog/GetListByUser?userId=" + userId)
         .then((res) => res.data.data);
   }

   static getBlogByType(type: boolean) {
      return axios
         .get<any>(endpoint + "/api/Blog/GetListByType?isAdmin=" + type)
         .then((res) => res.data.data);
   }

   static getBlogByTypeNew(args: GetBlogTypeRequestModel) {
      return axios
          .get<any>(endpoint + "/api/Blog/GetListByTypeNew", {
                params: args
          })
          .then((res) => res.data);
   }

   static updateBlog(data: BlogModel) {
      return axios.post<any>(endpoint + "/api/Blog/Update", data).then((res) => res.data.data);
   }

   static changePinBlog(data: PinBlogModel) {
      return axios
         .post<any>(endpoint + "/api/Blog/ChangeBlogPinStatus", data)
         .then((res) => res.data.data);
   }

   static deleteBlog(data: BlogModel) {
      return axios
         .post<any>(endpoint + "/api/Blog/Delete", data)
         .then((res) => [{ response: res.data.data }, { id: data.id }]);
   }

   static uploadImage(imageData: UploadImageModel) {
      return axios
         .post<any>(endpoint + "/web/api/Upload/UploadImage", imageData)
         .then((res) => res);
   }

   static sendBlogNotification(blogId: string) {
      return axios
          .post<any>(endpoint + "/api/Blog/SendBlogNotif", null, {
             params: {
                blogId: blogId,
             }
          })
          .then((res) => res.data.data);
   }
}
