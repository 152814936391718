export interface ChangeStatusModal {
   userId: string;
   isActive: boolean;
}

export enum UserType {Mitra, Customer, All}

export interface  UserBalance {
   id: string;
   name: string;
   value: number;
   type: UserType;
}

export interface WalletModel {
   id: string;
   type: UserType;
   userId: string;
   name: string;
   nominal: number;
   createdDate: string;
   note: string;
}

export interface CurrentBalance {
   totalSaldo: number,
   list: Array<UserBalance>
}

export interface GetWalletRequest {
   name: string;
   type: UserType | null;
   startDate: string | null;
   endDate: string | null;
   skip: number,
   take: number
}

export interface CreateWalletModel {
   id?: string;
   type: UserType;
   userId: string;
   note: string;
   nominal: number;
   adminId: string;
}

