import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import {
  BannerModel,
  CreateBannerImageModel,
  CreateBannerModel,
  UploadImageModel,
} from "./models";
import BannerApi from "./banner.api";

export interface CreateBannerSlice {
  name?: string;
  date?: string;
  list?: BannerModel[];
  single?: BannerModel;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
  uploadImage?: UploadImageModel;
}

export const createBanner = createAsyncThunk(
  "createBannerState/createBanner",
  async (bannerData: CreateBannerModel, { rejectWithValue }) => {
    try {
      return await BannerApi.createBanner(bannerData);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getBannerList = createAsyncThunk(
  "bannerState/bannerList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await BannerApi.getBannerList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getBannerSingleItem = createAsyncThunk(
  "bannerState/bannerSingleItem",
  async (bannerId: string, { rejectWithValue }) => {
    try {
      return await BannerApi.getSingleItem(bannerId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateBanner = createAsyncThunk(
  "bannerState/updateBanner",
  async (bannerData: BannerModel, { rejectWithValue }) => {
    try {
      return await BannerApi.updateBanner(bannerData);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteBanner = createAsyncThunk(
  "bannerState/deleteBanner",
  async (bannerData: BannerModel, { rejectWithValue }) => {
    try {
      return await BannerApi.deleteBanner(bannerData);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const uploadBannerImage = createAsyncThunk(
  "uploadBannerImageState/uploadBannerImage",
  async (imageData: CreateBannerImageModel, { rejectWithValue }) => {
    try {
      const response = await BannerApi.uploadImage(imageData.fileData);
      if (response.status === 200) {
        let formData = imageData.formData;
        formData.url = response.data.imagepath;
        if (imageData.isEdit) {
          return await BannerApi.updateBanner(formData);
        } else {
          return await BannerApi.createBanner(formData);
        }

      }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const createBannerSlice = createSlice({
  name: "createBannerState",
  initialState: {} as CreateBannerSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createBanner.pending, (state) => {
      state.isLoading = true;
      state.status = "create";
    });
    builder.addCase(createBanner.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "";
    });
    builder.addCase(createBanner.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "";
    });
    builder.addCase(getBannerSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getBannerSingleItem.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getBannerSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getBannerList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBannerList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getBannerList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateBanner.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateBanner.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.name = new Date().toISOString();
    });
    builder.addCase(updateBanner.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(deleteBanner.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteBanner.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.date = new Date().toISOString();
    });
    builder.addCase(deleteBanner.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(uploadBannerImage.pending, (state) => {
      state.isLoading = true;
      state.status = "uploaded";
    });
    builder.addCase(uploadBannerImage.fulfilled, (state, { payload }) => {
      state.date = new Date().toISOString();
      state.isLoading = false;
      state.status = "";
    });
    builder.addCase(uploadBannerImage.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed";
    });
  },
});

export const { setFilter } = createBannerSlice.actions;
export default createBannerSlice.reducer;
