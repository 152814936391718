import {ApiErrorResponse} from "../../index";
import {ApiResponseStatus} from "../../@core/models/apiResponseStatus/apiResponseStatus";
import {CreateWalletModel, GetWalletRequest, WalletModel} from "./models";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import WalletApi from "./wallet.api";
export interface WalletSlice {
    list?: WalletModel[];
    total?: number;
    single?: WalletModel;
    isLoading?: boolean;
    dateCreated?: string;
    error?: ApiErrorResponse<any>;
    status?: ApiResponseStatus;
}

export const getWalletList = createAsyncThunk(
    "walletState/getWalletList",
    async (args: GetWalletRequest, { rejectWithValue }) => {
        try {
            return await WalletApi.getWalletList(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getSingleWallet = createAsyncThunk(
    "walletState/getSingleWallet",
    async (id: string, { rejectWithValue }) => {
        try {
            return await WalletApi.getSingleWallet(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const createWallet = createAsyncThunk(
    "walletState/createWallet",
    async (args: CreateWalletModel, { rejectWithValue }) => {
        try {
            return await WalletApi.createWallet(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const updateWallet = createAsyncThunk(
    "walletState/updateWallet",
    async (args: CreateWalletModel, { rejectWithValue }) => {
        try {
            return await WalletApi.updateWallet(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const deleteWallet = createAsyncThunk(
    "walletState/deleteWallet",
    async (args: CreateWalletModel, { rejectWithValue }) => {
        try {
            return await WalletApi.deleteWallet(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

const walletSlice = createSlice({
    name: "walletState",
    initialState: {} as WalletSlice,
    reducers: {
        resetStatus: (state) => {
            state.status = ApiResponseStatus.pending;
            state.error = {} as ApiErrorResponse<any>;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getWalletList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getWalletList.fulfilled, (state, { payload }) => {
            state.list = payload.list;
            state.total = payload.total;
            state.isLoading = false;
        });
        builder.addCase(getWalletList.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getSingleWallet.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSingleWallet.fulfilled, (state, { payload }) => {
            state.single = payload;
            state.isLoading = false;
        });
        builder.addCase(getSingleWallet.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(createWallet.pending, (state) => {
            state.isLoading = true;
            state.status = ApiResponseStatus.pending;
        });
        builder.addCase(createWallet.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.dateCreated = new Date().toISOString();
            state.status = ApiResponseStatus.success;
        });
        builder.addCase(createWallet.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
            state.status = ApiResponseStatus.failed;
        });
        builder.addCase(updateWallet.pending, (state) => {
            state.isLoading = true;
            state.status = ApiResponseStatus.pending;
        });
        builder.addCase(updateWallet.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.dateCreated = new Date().toISOString();
            state.status = ApiResponseStatus.success;
        });
        builder.addCase(updateWallet.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
            state.status = ApiResponseStatus.failed;
        });
        builder.addCase(deleteWallet.pending, (state) => {
            state.isLoading = true;
            state.status = ApiResponseStatus.pending;
        });
        builder.addCase(deleteWallet.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.dateCreated = new Date().toISOString();
            state.status = ApiResponseStatus.success;
        });
        builder.addCase(deleteWallet.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
            state.status = ApiResponseStatus.failed;
        });
    }
});

export const { resetStatus } = walletSlice.actions;
export default walletSlice.reducer;
