import {ApprovalStatusEnum, BaseTypeEnum} from "./models";

export const baseType = [
    { text: 'Produk', value: BaseTypeEnum.Product.toString(), label: 'Produk', },
    { text: 'Berbasis Jumlah', value: BaseTypeEnum.QuantityBased.toString(), label: 'Berbasis Jumlah', },
    { text: 'Berbasis Waktu', value: BaseTypeEnum.TimeBased.toString(), label: 'Berbasis Waktu', },
];

export const getServiceApprovalStatus = (status: number) => {
    switch (status) {
        case ApprovalStatusEnum.Ditinjau:
            return "Ditinjau";
        case ApprovalStatusEnum.Diterima:
            return "Diterima";
        case ApprovalStatusEnum.Revisi:
            return "Revisi";
        default:
            return "";
    }
}