import React, {Fragment, useEffect, useState} from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import DefaultSubHeader from "../../../../components/DataTable/DefaultSubHeader";
import {ElementPropModel} from "../../../../components/DataTable/models";
import {TableCustomStyles} from "../../../../components/DataTable/TableCustomStyles";
import {getList, resetStatus} from "../storeReport.reducer";
import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import {SortEnum, StoreProductSortByEnum, StoreSalesStatisticModel} from "../models";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const StoreReportTable = () => {
    const dispatch = useDispatch();

    const storeStatisticList = useSelector((state: RootState) => state.storeReport.list ?? []);
    const rows = useSelector((state: RootState) => state.storeReport.rows ?? 0);
    const isLoading = useSelector((state: RootState) => state.storeReport.isLoading);

    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [timer, setTimer] = useState(setTimeout(() => {
    }, 1000));
    const [currentSortDirection, setCurrentSortDirection] = useState<SortEnum>(SortEnum.asc);
    const [currentSortColumn, setCurrentSortColumn] = useState<StoreProductSortByEnum>(StoreProductSortByEnum.Date);
    const [filterText, setFilterText] = useState("");

    useEffect(() => {
        dispatch(getList({
            filter: "",
            skip: 0,
            take: 10,
            sort: currentSortDirection,
            column: currentSortColumn,
        }));
        dispatch(resetStatus());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSetterFilterText = (filterValue: string) => {
        setFilterText(filterValue);
        clearTimeout(timer);
        setTimer(
            setTimeout((value = filterValue) => {
                dispatch(getList({
                    filter: value === "" ? undefined : value,
                    skip: 0,
                    take: rowsPerPage,
                    sort: currentSortDirection,
                    column: currentSortColumn,
                }));
            }, 1000)
        );
    };

    const elementProps = {
        placeholder: "cari berdasarkan nama",
        buttonName: "",
        createUrl: "/master/issue-category/create",
        subHeaderLine: true,
    } as ElementPropModel;

    const columns: TableColumn<StoreSalesStatisticModel>[] = [
        {
            name: "NO.",
            width: "60px",
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: "NAMA KELAS",
            selector: (row) => row.name,
            sortable: true,
            sortField: "Name",
        },
        {
            name: "TANGGAL KELAS",
            selector: (row) => row.classDate,
            format: (row: any) => row.classDate?.substr(0, 10),
            sortable: true,
            sortField: "Date"
        },
        {
            name: "TOTAL PEMBELI",
            selector: (row) => row.totalSold,
            sortable: true,
            sortField: "TotalSold"
        },
        {
            name: "KOMISI ARAMATA",
            selector: (row) => row.komisi,
            format: (row) => <Fragment>
                <NumberFormat
                    value={row.komisi}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                />
            </Fragment>,
            sortable: true,
            sortField: "Commision"
        },
        {
            name: "AKSI",
            width: "350px",
            cell: (row: StoreSalesStatisticModel) => (
                <>
                    <Link
                        to={{
                            pathname: `/store/report/detail/${row.id}`,
                        }}
                    >
                        <button className="btn button-primary mr-2">DETAIL</button>
                    </Link>
                </>
            ),
        },
    ];

    // end of datatable components

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setRowsPerPage(newPerPage);
        dispatch(getList({
            filter: filterText === "" ? undefined : filterText,
            skip: newPerPage * (page - 1),
            take: newPerPage,
            sort: currentSortDirection,
            column: currentSortColumn,
        }));
    };

    const handlePageChange = (page: number) => {
        dispatch(getList({
            filter: filterText === "" ? undefined : filterText,
            skip: rowsPerPage * (page - 1),
            take: rowsPerPage,
            sort: currentSortDirection,
            column: currentSortColumn,
        }));
    };

    const handleSort = async (column: any, sortDirection: any) => {
        let sort = SortEnum[sortDirection as keyof typeof SortEnum];
        let columnName = StoreProductSortByEnum[column.sortField as keyof typeof StoreProductSortByEnum];
        setCurrentSortDirection(sort);
        setCurrentSortColumn(columnName);
        dispatch(getList({
            filter: filterText === "" ? undefined : filterText,
            skip: 0,
            take: rowsPerPage,
            sort: sort,
            column: columnName,
        }));
    };

    return (
        <>
            <DataTable
                columns={columns}
                data={storeStatisticList}
                pagination
                paginationServer
                paginationTotalRows={rows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                noHeader
                subHeader
                subHeaderComponent={DefaultSubHeader(
                    filterText,
                    false,
                    onSetterFilterText,
                    elementProps
                )}
                customStyles={TableCustomStyles}
                persistTableHead
                paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
                progressPending={isLoading}
                onSort={handleSort}
                sortServer
            />
        </>
    );
};

export default StoreReportTable;
