import axios from "axios";
import { endpoint } from "../../../constants";
import { BannerModel, CreateBannerModel, UploadImageModel } from "./models";

export default class BannerApi {
  static createBanner(createBanner: CreateBannerModel) {
    return axios
      .post<any>(endpoint + "/api/Banner/Create", createBanner)
      .then((res) => res.data);
  }

  static getBannerList() {
    return axios
      .get<any>(endpoint + "/api/Banner/GetList")
      .then((res) => res.data.data);
  }

  static getSingleItem(bannerId: string) {
    return axios
      .get<any>(endpoint + "/api/Banner/GetSingleItem?id=" + bannerId)
      .then((res) => res.data.data);
  }

  static updateBanner(bannerData: BannerModel) {
    return axios
      .post<any>(endpoint + "/api/Banner/Update", bannerData)
      .then((res) => res.data.data);
  }

  static deleteBanner(bannerData: BannerModel) {
    return axios
      .post<any>(endpoint + "/api/Banner/Delete", bannerData)
      .then((res) => [{ response: res.data.data }, { id: bannerData.id }]);
  }

  static uploadImage(imageData: UploadImageModel) {
    return axios
      .post<any>(endpoint + "/web/api/Upload/UploadImage", imageData)
      .then((res) => res);
  }
}
