import React, { Fragment, useEffect, useState } from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../app/store";
import DefaultSubHeader from "../../../components/DataTable/DefaultSubHeader";
import { ElementPropModel } from "../../../components/DataTable/models";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import {
   completeIssue,
   deleteReportIssue,
   getReportIssueList,
   resetStatus,
} from "../reportIssue.reducer";
import Moment from "react-moment";
import {ReportIssueModel} from "../models";
import {statusOptions} from "../constants";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {customSavedOption} from "../../../helpers/alertContent";
import {ApiErrorResponse} from "../../../index";

const ReportIssueTable = () => {
   const MySwal = withReactContent(Swal);
   const dispatch = useDispatch();

   const reportIssueState = useSelector((state: RootState) => state.reportIssue.list ?? []);
   const dateCreated = useSelector((state: RootState) => state.reportIssue.dateCreated);
   const completeStatus = useSelector((state: RootState) => state.reportIssue.completeStatus);
   const error = useSelector((state: RootState) => state.reportIssue.error ?? {} as ApiErrorResponse<any>);

   const [filterText, setFilterText] = useState("");
   const [resetPaginationToggle] = useState(false);

   const filteredItems = reportIssueState.filter(
      (item) => item.title && item.title.toLowerCase().includes(filterText.toLowerCase())
   );

   useEffect(() => {
      if (completeStatus !== undefined && completeStatus !== '') {
         MySwal.fire(customSavedOption(completeStatus === 'success', error.data?.message, 'Berhasil Mengubah Status Issue', 'Gagal Mengubah Status Issue: ' + error.data?.message)).then(() => {
            dispatch(resetStatus());
            dispatch(getReportIssueList());
         });
      }
   }, [completeStatus, error]);

   const onSetterFilterText = (value: string) => {
      setFilterText(value);
   };

   const elementProps = {
      placeholder: "cari berdasarkan nama",
      buttonName: "",
      createUrl: "/master/issue-category/create",
      subHeaderLine: true,
   } as ElementPropModel;

   const onCompleteClicked = (id: string) => {
      MySwal.fire({
         text: `Apakah kamu yakin akan mengubah status issue ini?`,
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Ya',
      }).then((result) => {
         if (result.isConfirmed) {
            dispatch(completeIssue(id));
         }
      });
   }

   const columns: TableColumn<ReportIssueModel>[] = [
      {
         name: "NO.",
         width: "60px",
         cell: (_d: any, index: number) => index + 1,
      },
      {
         name: "Tanggal",
         selector: (row) => row.createdDate ?? "-",
         format: (d: any) =>  <Moment format="DD MMMM YYYY">{new Date(d.createdDate)}</Moment>,
         sortable: true,
         sortField: 'createdDate',
      },
      {
         name: "JUDUL ISU",
         selector: (row) => row.title,
         sortable: true,
      },
      {
         name: "E-MAIL PENGIRIM",
         selector: (row) => row.email,
         sortable: true,
      },
      {
         name: "DESKRIPSI",
         selector: (row) => row.description,
         sortable: true,
         width: "250px",
      },
      {
         name: "TIPE AKUN",
         cell: (d: { customerId: string | null; mitraId: string | null }) => (
            <Fragment>
               {d.customerId !== null && <span>Customer</span>}
               {d.mitraId !== null && <span>Mitra</span>}
            </Fragment>
         ),
      },
      {
         name: "STATUS",
         selector: (row) => row.status ?? "-",
         format: (d: any) => {
            return statusOptions.map((item) => {
                if (item.value === d.status) {
                    return item.label;
                }
                return "";
            })
         },
         sortable: true,
      },
      {
         name: "AKSI",
         width: "350px",
         cell: (d: ReportIssueModel) => (
             <>
                {/*{*/}
                {/*   d.status ===ReportIssueTypeEnum.Replied && (*/}
                        <button className="btn button-primary mr-2" onClick={() => onCompleteClicked(d.id)}>SELESAI</button>
                {/*    )*/}
                {/*}*/}
                <Link
                    to={{
                       pathname: `/master/report-issue/detail/${d.id}`,
                    }}>
                   <button className="btn button-primary mr-2">PRATINJAU</button>
                </Link>
                <button
                    onClick={() => {
                       if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
                          onDelete(d.id);
                    }}
                    className="btn button-danger">
                   HAPUS
                </button>
             </>
         ),
      },
   ];

   // end of datatable components

   useEffect(() => {
      dispatch(getReportIssueList());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      dispatch(getReportIssueList());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dateCreated]);

   const onDelete = (id: string) => {
      dispatch(
         deleteReportIssue({
            id: id,
            title: "string",
            description: "string",
            reportIssueCategoryId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            customerId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            mitraId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            email: "",
         })
      );
   };

   return (
      <>
         <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            subHeader
            subHeaderComponent={DefaultSubHeader(
               filterText,
               resetPaginationToggle,
               onSetterFilterText,
               elementProps
            )}
            customStyles={TableCustomStyles}
            persistTableHead
            defaultSortFieldId={"createdDate"}
         />
      </>
   );
};

export default ReportIssueTable;
