import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../../app/store";
import { createBanner, getBannerList } from "../banner.reducer";
import BannerTable from "../components/BannerTable";
import { CreateBannerModel, UploadImageModel } from "../models";

const BannerPage = () => {
  const dispatch = useDispatch();

  const uploadImageState = useSelector(
    (state: RootState) => state.banner.uploadImage ?? ({} as UploadImageModel)
  );
  const ChangesState = useSelector((state: RootState) => state.banner.date);

  useEffect(() => {
    dispatch(getBannerList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getBannerList());
  }, [ChangesState]);

  return (
    <>
      <Container className="content-body" fluid>
        <Row>
          <Col md={12}>
            <h4 className="card-label">Banner Beranda</h4>
            <BannerTable />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BannerPage;
