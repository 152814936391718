export enum SaldoType {
    In,
    Out,
    Request,
    Reject,
    None,
}

export interface BalanceHistoryModel {
    projectId: string;
    productId: string;
    productName: string;
    packageDetailId: string;
    packageName: string;
    vendorId: string;
    professionalId: string;
    value: number;
    type: SaldoType;
    createdDate: string;
}

export interface CreateWithdrawModel {
    vendorId?: string | null;
    customerId?: string | null;
    professionalId?: string | null;
    value: number;
    accountBankNo: string;
    bankName: string;
    ownerName: string;
}

export interface CheckPasswordModal {
    userId: string;
    password: string;
}

export type DateFilterFormat = {
    startDate: Date;
    endDate: Date;
};

export type GetSaldoHistoryModel = {
    mitraId: string;
    saldoType: SaldoType;
    startDate: string | null;
    endDate: string | null;
}