import React from "react";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload, faUpload } from "@fortawesome/free-solid-svg-icons";
import {FileUploadModel} from "../../features/chat/models";

const UploadAlbum = ({ selectedFile = {} as FileUploadModel, ...props }: UploadAlbumProps) => {
   const handleAcceptedProfileFiles = (files: any[]) => {
      props.uploadFileResult({
         file: files[0],
         type: files[0].type.split("/")[1],
         localPath: URL.createObjectURL(files[0]),
         fileName: files[0].name,
      });
   };

   return (
      <Dropzone onDrop={(acceptedFiles) => handleAcceptedProfileFiles(acceptedFiles)}>
         {({ getRootProps, getInputProps }) => (
            <div className={`dropzone file-upload-frame upload_album`}>
               <div className="dz-message needsclick" {...getRootProps()}>
                  <input {...getInputProps()} />

                  <div className={`text-center file-upload-label file_upload_label`}>
                     <div>
                        {selectedFile.file !== undefined && (
                           <>
                              <FontAwesomeIcon
                                 icon={faFileUpload}
                                 width="38"
                                 className="file-upload-icon mb-3"
                              />
                              <br />
                           </>
                        )}
                        {selectedFile.file === undefined && (
                           <>
                              <FontAwesomeIcon
                                 icon={faUpload}
                                 width="38"
                                 className="file-upload-icon mb-3"
                              />
                           </>
                        )}
                        <small className="d-block">Unggah Berkas</small>
                     </div>
                  </div>
               </div>
            </div>
         )}
      </Dropzone>
   );
};

export interface UploadAlbumProps {
   selectedFile?: FileUploadModel;
   uploadFileResult: (file: FileUploadModel) => void;
}

export default UploadAlbum;
