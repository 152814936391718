import React, {ChangeEvent, useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {UserType} from "../models";
import NumberFormat from "react-number-format";
import Select from "react-select";
import {getUserOptionList as getMitraOptionList } from "../../dataMitra/dataMitra/dataMitra.reducer";
import {getUserOptionList as getCustomerOptionList} from "../../customer/dataCustomer/dataCustomer.reducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {OptionModel} from "../../../@core/models/types";
import {createWallet, resetStatus} from "../wallet.reducer";
import Swal from 'sweetalert2'
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {ApiErrorResponse} from "../../../index";
import {savedStatusOption} from "../../../helpers/alertContent";
import withReactContent from "sweetalert2-react-content";
import {RegistrationStatus, UserTypeEnum} from "../../dataMitra/dataMitra/models";


const WalletForm = () => {
    const MySwal = withReactContent(Swal);
    let history = useHistory();
    const dispatch = useDispatch();

    const [validated, setValidated] = useState<boolean>(false);

    const [userType, setUserType] = useState<UserType>(UserType.Mitra);
    const [selectedUser, setSelectedUser] = useState<OptionModel | undefined>(undefined);
    const [nominal, setNominal] = useState<number>(0);
    const [note, setNote] = useState<string>("");

    const isMitraOptionLoading = useSelector((state: RootState) => state.dataMitra.isLoading);
    const mitraOptions = useSelector((state: RootState) => state.dataMitra.userOptions ?? []);
    const isCustomerOptionLoading = useSelector((state: RootState) => state.dataCustomer.isLoading);
    const customerOptions = useSelector((state: RootState) => state.dataCustomer.userOptions ?? []);
    const adminId = useSelector((state: RootState) => state.user.id);
    const status = useSelector((state: RootState) => state.wallet.status);
    const error = useSelector((state: RootState) => state.wallet.error ?? ({} as ApiErrorResponse<any>));

    const onNoteChange = (e: ChangeEvent<HTMLInputElement>) => {
        setNote(e.target.value);
    };

    useEffect(() => {
        if (userType === UserType.Mitra) {
            dispatch(getMitraOptionList({
                userType: UserTypeEnum.Mitra,
                registerStatus: undefined,
                name: undefined,
                skip: 0,
                take: 10000
            }));
        } else if (userType === UserType.Customer) {
            dispatch(getCustomerOptionList({
                userType: UserTypeEnum.Customer,
                registerStatus: undefined,
                name: undefined,
                skip: 0,
                take: 10000
            }));
        }
    }, [userType]);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === ApiResponseStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(resetStatus());
                if (status === ApiResponseStatus.success) {
                    history.push("/admin/send-balance");
                }
            });
        }
    }, [status, error]);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        const form = e.currentTarget;
        if (selectedUser === undefined) {
            e.preventDefault();
            return alert("Pilih user terlebih dahulu");
        }
        if (!form.checkValidity()) {
            e.stopPropagation();
        } else {
            e.preventDefault();
            dispatch(createWallet({
                type: userType,
                userId: selectedUser.value,
                note: note,
                nominal: nominal,
                adminId: adminId as string,
            }));
        }
        setValidated(true);
    }

    const onCancelHandler = () => {
        history.push("/admin/send-balance");
    };

    const onUserChangeHandler = (selectedOption: any) => {
        setSelectedUser(selectedOption);
    }

    return (
        <Form
            noValidate
            validated={validated}
            onSubmit={onSubmit}>

            <Form.Group className="mb-4">
                <Form.Label>Tipe Akun</Form.Label>
                <div className="mb-3">
                    <Form.Check
                        type={'radio'}
                        id={`type-mitra`}
                        name={`type`}
                        label={`Mitra`}
                        checked={userType === UserType.Mitra}
                        onChange={() => setUserType(UserType.Mitra)}
                    />
                    <Form.Check
                        type={'radio'}
                        id={`type-customer`}
                        name={`type`}
                        label={`Customer`}
                        checked={userType === UserType.Customer}
                        onChange={() => setUserType(UserType.Customer)}
                    />
                </div>
            </Form.Group>

            <Form.Group className="mb-4">
                <Form.Label>Akun</Form.Label>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Ketik untuk mencari partner"
                    isSearchable={true}
                    name="type"
                    isLoading={userType === UserType.Mitra ? isMitraOptionLoading : isCustomerOptionLoading}
                    options={userType === UserType.Mitra  ? mitraOptions : customerOptions}
                    onChange={onUserChangeHandler}
                    value={selectedUser}
                />
            </Form.Group>

            <Form.Group className="mb-4">
                <Form.Label>Nominal</Form.Label>
                <NumberFormat
                    value={nominal}
                    className="form-control d-inline field_plaint_text"
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"Rp. "}
                    onValueChange={(values) => {
                        setNominal(values.floatValue ?? 0);
                    }}
                />
            </Form.Group>

            <Form.Group className="mb-4">
                <Form.Label>Catatan</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={onNoteChange}
                    placeholder="catatan pengiriman saldo"
                    value={note}
                    required
                />
            </Form.Group>

            <div className="text-center mt-5">
                <button
                    className="btn button-outline-primary mr-3"
                    type="reset"
                    onClick={onCancelHandler}>
                    CANCEL
                </button>
                <button className="btn button-primary" type="submit">
                    KIRIM SALDO
                </button>
            </div>

        </Form>
    )
};

export default WalletForm;