import React from "react";
import { Row, Col, Form, Image, Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { VendorModel } from "../../dataMitra/models";
import { MitraType, ProfessionalModel } from "../models";
import {getMediaEndpoint} from "../../../../helpers/imageManipulation";

const ProfilUmumList = ({ mitraType = 0 }: ProfilUmumListProps) => {
   const personalCertificateState = useSelector(
      (state: RootState) => state.dataDiri.personalCertificate ?? []
   );
   const personalLanguageState = useSelector(
      (state: RootState) => state.dataDiri.personalLanguage ?? []
   );

   const professionalState = useSelector(
      (state: RootState) => state.dataDiri.professionalSingle ?? ({} as ProfessionalModel)
   );

   const vendorState = useSelector(
      (state: RootState) => state.dataDiri.vendorSingle ?? ({} as VendorModel)
   );

   const onImageClicked = (path: string) => {
      const win = window.open(getMediaEndpoint(path), "_blank") as Window;
      win.focus();
   };

   return (
      <>
         <h6 className="mb-4 data_diri_tab_body_title d-inline-block">Profil Umum </h6>
         <Form.Group as={Row} controlId="formPlaintext">
            <Form.Label column sm="12" className="field_plaint_text">
               Website
            </Form.Label>
            {professionalState.website !== "null" && (
               <Col sm="12">
                  <Form.Control
                     readOnly
                     value={
                        mitraType === MitraType.professional
                           ? professionalState.website
                           : mitraType === MitraType.vendor
                           ? vendorState.website
                           : ""
                     }
                     className="field_plaint_text"
                     style={{ resize: "none" }}
                  />
               </Col>
            )}
            <Col sm="12">
               <hr className="tab_body_underline" />
            </Col>
         </Form.Group>
         <Form.Group as={Row} controlId="formPlaintext">
            <Form.Label column sm="12" className="field_plaint_text">
               Bahasa yang dikuasai
            </Form.Label>
            <Col sm="12">
               {personalLanguageState.map((item) => {
                  return (
                     <Badge pill className="profile-badge mr-2">
                        {item.name}
                     </Badge>
                  );
               })}
            </Col>
         </Form.Group>
         <Form.Group as={Row} controlId="formPlaintext">
            <Form.Label column sm="12" className="field_plaint_text">
               Sertifikat & Penghargaan
            </Form.Label>
            {personalCertificateState.map((item) => {
               return (
                  <Col md="6" className="mb-3">
                     <div
                        className="file-upload-frame w-100"
                        onClick={() => onImageClicked(item.path)}>
                        <Image src={getMediaEndpoint(item.path)} className="file-upload-image" />
                     </div>
                  </Col>
               );
            })}
            <Col sm="12">
               <hr className="tab_body_underline" />
            </Col>

            <Col sm="12">
               <hr className="tab_body_underline" />
            </Col>
         </Form.Group>
      </>
   );
};

export interface ProfilUmumListProps {
   mitraType: MitraType;
}

export default ProfilUmumList;
