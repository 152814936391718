// export const imageResize = (img: string) => {
//     // create an off-screen canvas
//     let canvas = document.createElement('canvas'),
//         ctx = canvas.getContext('2d');
//
//     // draw source image into the off-screen canvas:
//     let image = new Image();
//     image.onload = function() {
//         canvas.height=1024 * image.height / image.width;
//         canvas.width=1024;
//         ctx?.drawImage(image, 0, 0, 1024, 1024 * image.height / image.width);
//     };
//     image.src = img;
//     // encode image to data-uri with base64 version of compressed image
//     return canvas.toDataURL();
// }

export function resizeImage(
   srcBase64: string,
   callback: (arg0: string) => void
) {
    console.log(srcBase64);
   var img = new Image();

   img.onload = function () {
      var width = img.width,
         height = img.height,
         canvas = document.createElement('canvas'),
         ctx = canvas.getContext('2d');
       canvas.height=1024 * height / width;
       canvas.width=1024;

      // draw image
       // @ts-ignore
      ctx.drawImage(img, 0, 0, 1024, 1024 * height / width);

      // export base64s
      callback(canvas.toDataURL());
      //  return canvas.toDataURL();
   };

   img.src = srcBase64;
}

// resizeImage(certificateFile, async (img) => {
//
// });