import axios from "axios";
import {endpoint} from "../../constants";
import {GetProjectRequest} from "./models";


export default class DataProjectApi {
    static getProjectList() {
        return axios
            .get<any>(endpoint + "/api/Project/GetList")
            .then((res) => res.data.data);
    }
    static getProjectListWithFilter(args: GetProjectRequest) {
        return axios
            .get<any>(endpoint + "/api/Project/GetListDataProject", {
                params: args,
            })
            .then((res) => res.data);
    }
    static getTotalProject() {
        return axios
            .get<any>(endpoint + "/api/Project/GetTotalDataProject")
            .then((res) => res.data.data);
    }
    static getSingleProject(id: string) {
        return axios
            .get<any>(endpoint + "/api/Project/GetSingleItem", {
                params: {
                    id: id,
                }
            })
            .then((res) => res.data.data);
    }
    static getSingleDetail(id: string) {
        return axios
            .get<any>(endpoint + "/api/Activity/GetSingleDetail", {
                params: {
                    id: id,
                }
            })
            .then((res) => res.data.data);
    }

    static getActivityByProject(projectId: string) {
        return axios
            .get<any>(endpoint + "/api/Activity/GetListByProject", {
                params: {
                    projectId: projectId,
                }
            })
            .then((res) => res.data.data);
    }

    static getResultByDetailActivity(detailActivityId: string) {
        return axios
            .get<any>(endpoint + "/api/Result/GetListByActivityDetail", {
                params: {
                    activityDetailId: detailActivityId,
                }
            })
            .then((res) => res.data.data);
    }

    static getListFinalFile(resultId: string) {
        return axios
            .get<any>(endpoint + "/api/Result/GetListFileFinalByMaster", {
                params: {
                    resultId: resultId,
                }
            })
            .then((res) => res.data.data);
    }

    static getResultReview(resultId: string) {
        return axios
            .get<any>(endpoint + "/api/ResultReview/GetListByResult", {
                params: {
                    resultId: resultId,
                }
            })
            .then((res) => res.data.data);
    }
    static getCommentResult(resultId: string) {
        return axios
            .get<any>(endpoint + "/api/Result/GetListCommentByMaster", {
                params: {
                    resultId: resultId,
                }
            })
            .then((res) => res.data.data);
    }
}