import React, {ChangeEvent, Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {useHistory} from "react-router-dom";
import DataTable, {TableColumn} from "react-data-table-component";
import DefaultSubHeader from "../../../components/DataTable/DefaultSubHeader";
import {TableCustomStyles} from "../../../components/DataTable/TableCustomStyles";
import {ElementPropModel} from "../../../components/DataTable/models";
import NumberFormat from "react-number-format";
import {UserType, WalletModel} from "../models";
import {getWalletList} from "../wallet.reducer";
import {Col, Form, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";

export type UserTypeOptionModel = {
    value: UserType;
    label: string;
}
const UserBalanceTable = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const walletState = useSelector((state: RootState) => state.wallet.list ?? []);
    const totalData = useSelector((state: RootState) => state.wallet.total ?? 0);

    // datatable components

    const [filterText, setFilterText] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const [name, setName] = useState<string>("");
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [selectedType, setSelectedType] = useState<number>(UserType.All);
    const [selectedTypeOption, setSelectedTypeOption] = useState({
        label: "Semua",
        value: UserType.All,
    });
    const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const onTypeChangeHandler = (data: any) => {
        setSelectedType(data.value);
        setSelectedTypeOption(data);
    };

    const onSubmitFilter = () => {
        dispatch(getWalletList({
            name: name,
            type: selectedType === UserType.All ? null : selectedType,
            startDate: startDate === undefined ? null : startDate.toISOString(),
            endDate: endDate === undefined ? null : endDate.toISOString(),
            skip: 0,
            take: 10
        }));
    }

    const onResetFilter = () => {
        setName("");
        setStartDate(undefined);
        setEndDate(undefined);
        setSelectedType(UserType.All);
        setSelectedTypeOption({
            label: "Semua",
            value: UserType.All,
        });
        dispatch(getWalletList({
            name: "",
            type: null,
            startDate: null,
            endDate: null,
            skip: 0,
            take: 10
        }));
    }

    const userTypeOption: UserTypeOptionModel[] = [
        {
            label: "Mitra",
            value: UserType.Mitra,
        },
        {
            label: "Customer",
            value: UserType.Customer,
        },
        {
            label: "Semua",
            value: UserType.All,
        },
    ];

    const columns: TableColumn<WalletModel>[] = [
        {
            name: "NO.",
            width: "60px",
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: "NAMA",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "TOTAL SALDO (Rp)",
            cell: (data: WalletModel) => <Fragment>
                <NumberFormat
                    value={data.nominal}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                />
            </Fragment>,
            sortable: true,
        },
        {
            name: "TIPE AKUN",
            cell: (data: WalletModel) => <Fragment>
                {data.type === UserType.Mitra && <span>Mitra</span>}
                {data.type === UserType.Customer && <span>Customer</span>}
            </Fragment>,
            sortable: true,
        },
        {
            name: "TANGGAL",
            selector: (row) => row.createdDate,
            format: (row: any) => row.createdDate.substr(0, 10),
            sortable: true,
        },
        {
            name: "CATATAN",
            selector: (row) => row.note,
            sortable: true,
        },
        // {
        //    name: "AKSI",
        //    selector: (d: UserModel) => (
        //       <>
        //          <button className="btn button-primary mr-2" onClick={() => onDetailClicked(d)}>
        //             DETAIL
        //          </button>
        //
        //          <button
        //             onClick={() => {
        //                if (window.confirm("Apakah kamu yakin akan mengubah status akun ini?"))
        //                   onChangeStatus(d.id, d.isActive);
        //             }}
        //             className={`btn ${d.isActive ? "button-danger" : "btn-success"} `}>
        //             {d.isActive ? "NON AKTIFKAN" : "AKTIFKAN"}
        //          </button>
        //       </>
        //    ),
        //    width: "400px",
        // },
    ];

    // end of datatable components

    useEffect(() => {
        dispatch(getWalletList({
                name: "",
                type: null,
                startDate: null,
                endDate: null,
                skip: 0,
                take: 10
            }
        ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setRowsPerPage(newPerPage);
        dispatch(getWalletList({
                name: name,
                type: selectedType === UserType.All ? null : selectedType,
                startDate: startDate === undefined ? null : startDate.toISOString(),
                endDate: endDate === undefined ? null : endDate.toISOString(),
                skip: newPerPage * (page - 1),
                take: newPerPage,
            }
        ));
    };

    const handlePageChange = (page: number) => {
        dispatch(getWalletList({
                name: name,
                type: selectedType === UserType.All ? null : selectedType,
                startDate: startDate === undefined ? null : startDate.toISOString(),
                endDate: endDate === undefined ? null : endDate.toISOString(),
                skip: rowsPerPage * (page - 1),
                take: rowsPerPage,
            }
        ));
    };

    return (
        <>
            <Row>
                <Col md={12} className="my-3">
                    <h6 className="d-inline">Filter Berdasarkan</h6>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>Nama</Form.Label>
                        <Form.Control type="text" value={name} onChange={onNameChange} required/>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-4">
                        <Form.Label>Tipe Akun</Form.Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder="Ketik untuk mencari Tipe Voucher"
                            isSearchable={true}
                            name="type"
                            options={userTypeOption}
                            onChange={onTypeChangeHandler}
                            value={selectedTypeOption}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Range Tanggal (Mulai Dari)</Form.Label>
                        <DatePicker
                            className="form-control"
                            selected={startDate}
                            onChange={(date) => setStartDate(date as Date)}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Range Tanggal (Hingga)</Form.Label>
                        <DatePicker
                            className="form-control"
                            selected={endDate}
                            onChange={(date) => setEndDate(date as Date)}
                        />
                    </Form.Group>
                </Col>
                <Col md={12} className="mb-3 d-flex justify-content-end">
                    <button
                        className="btn button-outline-primary mr-1"
                        type="reset"
                        onClick={onResetFilter}
                    >
                        RESET
                    </button>
                    <button className="btn button-primary" onClick={onSubmitFilter}>
                        FILTER
                    </button>
                </Col>
            </Row>
            <DataTable
                columns={columns}
                data={walletState}
                pagination
                paginationServer
                paginationTotalRows={totalData}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                noHeader
                selectableRows
                customStyles={TableCustomStyles}
                persistTableHead
            />
        </>
    );
};

export default UserBalanceTable;
