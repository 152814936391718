import React from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import WithdrawTable from "../components/WithdrawTable";

const WithdrawPage = () => {
   const dispatch = useDispatch();

   // const statusState = useSelector(
   //   (state: RootState) => state.blogCategory.name
   // );

   // useEffect(() => {
   //   dispatch(getBlogCategoryList());
   //   // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [statusState]);

   // useEffect(() => {
   //   dispatch(resetSingle());
   //   // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, []);

   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <h4 className="card-label">Permintaan Penarikan</h4>
               <WithdrawTable />
            </Col>
         </Row>
      </Container>
   );
};

export default WithdrawPage;
