import axios from "axios";
import { endpoint } from "../../../constants";

export default class PortfolioApi {
  static getPortfolio(mitraId: string) {
    return axios
      .get<any>(
        endpoint + "/api/Portofolio/GetListByMitraId?mitraId=" + mitraId
      )
      .then((res) => res.data.data);
  }

  static getUserList() {
    return axios
      .get<any>(endpoint + "/api/User/GetAll")
      .then((res) => res.data.data);
  }
}
