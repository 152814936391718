import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import GearOptionForm from "../components/GearOptionForm";

import {
  createGearOption,
  getGearOptionSingleItem,
  updateGearOption,
  resetStatus,
} from "../gearOption.reducer";
import { GearOptionModel } from "../models";
import {useHistory, useParams} from "react-router-dom";
import { RootState } from "../../../../app/store";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiErrorResponse} from "../../../../index";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";

const GearOptionDetailPage = () => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();
  let history = useHistory();

  const [isEdit, setIsEdit] = useState(false);

  const singleDataState = useSelector(
    (state: RootState) =>
      state.gearOption.single ?? ({} as GearOptionModel)
  );
  const status = useSelector((state: RootState) => state.gearOption.status);
  const error = useSelector((state: RootState) => state.gearOption.error ?? ({} as ApiErrorResponse<any>));

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getGearOptionSingleItem(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (status !== undefined && status !== ApiResponseStatus.pending) {
      MySwal.fire(
          savedStatusOption(
              status === ApiResponseStatus.success,
              error.data?.message
          )
      ).then(() => {
        dispatch(resetStatus());
        if (status === ApiResponseStatus.success) {
          history.push("/master/gear-option/list");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error]);

  const onSubmit = (name: string) => {
    if (isEdit) {
      dispatch(
          updateGearOption({
          id: id,
          name,
        } as GearOptionModel)
      );
    } else {
      dispatch(
          createGearOption({
          name,
        } as GearOptionModel)
      );
    }
  };

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <h4 className="card-label">
            {isEdit ? "Edit" : "Tambah"} Master Opsi Gear
          </h4>
          <hr />
          <Row>
            <Col md={8} xl={5}>
              <GearOptionForm
                onSubmit={onSubmit}
                data={singleDataState}
                isEdit={isEdit}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default GearOptionDetailPage;
