import React, { Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import DataTable, {TableColumn} from "react-data-table-component";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import {
   PartnerLevelModel,
   PartnerPointModel,
   PenaltyCancellationFee,
   SettingValueEnum,
} from "../models";
import {
   getListCancellationFee,
   getListPartnerLevel,
   getListPartnerPointSetting,
   resetStatus,
   updateCancellationFee,
   updatePartnerPointSetting,
} from "../projectSetting.reducer";
import EditPartnerLevel from "./EditPartnerLevel";

const PartnerLevel = () => {
   const dispatch = useDispatch();

   const [singlePartnerLevel, setSinglePartnerLevel] = useState<PartnerLevelModel>(
      {} as PartnerLevelModel
   );
   const [updatedLevel, setUpdatedLevel] = useState<PartnerLevelModel[]>([]);
   const [showEditModal, setShowEditModal] = useState<boolean>(false);

   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
   const partnertLevelList = useSelector(
      (state: RootState) => state.projectSetting.partnerLevelList ?? []
   );
   const dateCreated = useSelector((state: RootState) => state.projectSetting.dateCreated);

   useEffect(() => {
      if (partnertLevelList.length !== 0) {
         let filteredPartnerLevel = partnertLevelList.filter(
            (item) => item.order === 1 || item.order === 2 || item.order === 3
         );
         setUpdatedLevel(filteredPartnerLevel);
      }
   }, [partnertLevelList]);

   const onEditClicked = (data: PartnerLevelModel) => {
      setSinglePartnerLevel(data);
      showEditModalHandler();
   };

   const showEditModalHandler = () => {
      setShowEditModal((prevState) => !prevState);
   };

   const columns: TableColumn<PartnerLevelModel>[] = [
      {
         name: "NO.",
         width: "60px",
         cell: (_d: any, index: number) => index + 1,
      },
      {
         name: "NAMA LEVEL",
         selector: (row) => row.name,
         sortable: true,
      },
      {
         name: "MINIMAL POIN",
         selector: (row) => row.minimumPoint,
         sortable: true,
      },
      {
         name: "MAKSIMAL POIN",
         selector: (row) => row.maximumPoint,
         sortable: true,
      },
      {
         name: "AKSI",
         width: "350px",
         cell: (row: PartnerLevelModel) => (
            <>
               <button className="btn button-primary" onClick={() => onEditClicked(row)}>
                  UBAH
               </button>
            </>
         ),
      },
   ];

   useEffect(() => {
      dispatch(getListPartnerLevel());
      dispatch(resetStatus());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      dispatch(getListPartnerLevel());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dateCreated]);

   return (
      <Fragment>
         <DataTable
            columns={columns}
            data={updatedLevel}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            selectableRows
            customStyles={TableCustomStyles}
         />
         <EditPartnerLevel
            singlePartnerLevel={singlePartnerLevel}
            show={showEditModal}
            handleClose={showEditModalHandler}
         />
      </Fragment>
   );
};

export default PartnerLevel;
