import React, {useEffect, useMemo, useState} from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {RootState} from "../../../app/store";
import {TableCustomStyles} from "../../../components/DataTable/TableCustomStyles";
import {
    deleteProduct,
    getProductByApprovalList,
    resetStatus, updateProductApprovalStatus
} from "../deleteMitraService.reducer";
import {ApprovalStatusEnum, EmailBodyModel, ProductModel, RevisionModel} from "../models";
import {baseType, getServiceApprovalStatus} from "../constants";
import ReplyMitra from "./ReplyMitra";
import Revision from "./Revision";
import {ApiErrorResponse} from "../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {customSavedOption} from "../../../helpers/alertContent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import all_active from "../../../assets/images/icons/all_active.svg";
import all_disabled from "../../../assets/images/icons/all_disabled.svg";
import unverified_active from "../../../assets/images/icons/unverified_active.svg";
import unverified_disabled from "../../../assets/images/icons/unverified_disabled.svg";
import verified_active from "../../../assets/images/icons/verified_active.svg";
import verified_disabled from "../../../assets/images/icons/verified_disabled.svg";
import {MAX_PAGING_OPTIONS} from "../../../constants";

const MitraProductTable = () => {
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();

    const productList = useSelector((state: RootState) => state.deleteMitraService.productList ?? []);

    const productApprovalStatus = useSelector((state: RootState) => state.deleteMitraService.productApprovalStatus);
    const error = useSelector((state: RootState) => state.deleteMitraService.error ?? {} as ApiErrorResponse<any>);

    const [activeTag, setActiveTag] = useState<ApprovalStatusEnum>(ApprovalStatusEnum.All);
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle] = useState(false);
    const [showReplyModal, setShowReplyModal] = useState<boolean>(false);
    const [showRevisionModal, setShowRevisionModal] = useState<boolean>(false);
    const [emailRequest, setEmailRequest] = useState<EmailBodyModel>({} as EmailBodyModel);
    const [revisionRequest, setRevisionRequest] = useState<RevisionModel>({} as RevisionModel);

    const filteredItems = productList.filter(
        (item) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()) ||
            item.mitraName && item.mitraName.toLowerCase().includes(filterText.toLowerCase()) ||
            item.expertiseName && item.expertiseName.toLowerCase().includes(filterText.toLowerCase()) ||
            item.mitraTypeName && item.mitraTypeName.toLowerCase().includes(filterText.toLowerCase())
    );

    useEffect(() => {
        if (productApprovalStatus !== undefined && productApprovalStatus !== ApiResponseStatus.pending) {
            MySwal.fire(customSavedOption(productApprovalStatus === ApiResponseStatus.success, error.data?.message, 'Berhasil Mengubah Status Layanan', 'Gagal Mengubah Status Layanan: ' + error.data?.message)).then(() => {
                dispatch(resetStatus());
                dispatch(getProductByApprovalList(activeTag === ApprovalStatusEnum.All ? undefined : activeTag));
                setRevisionRequest({} as RevisionModel);
                setShowRevisionModal(false);
            });
        }
    }, [productApprovalStatus, error, activeTag]);

    const onSetterFilterText = (value: string) => {
        setFilterText(value);
    };

    const onReplyClicked = (arg: EmailBodyModel) => {
        setEmailRequest(arg);
        setShowReplyModal(true);
    };

    const onRevisionClicked = (arg: RevisionModel) => {
        setRevisionRequest(arg);
        setShowRevisionModal(true);
    };

    const showReplyModalHandler = () => {
        setEmailRequest({} as EmailBodyModel);
        setShowReplyModal((prevState) => !prevState);
    };

    const showRevisionModalHandler = () => {
        setRevisionRequest({} as RevisionModel);
        setShowRevisionModal((prevState) => !prevState);
    };

    const onAcceptClicked = (id: string) => {
        MySwal.fire({
            text: `Apakah kamu yakin akan mengubah status layanan ini?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(
                    updateProductApprovalStatus({
                        id: id,
                        approvalStatus: ApprovalStatusEnum.Diterima,
                        revisiNote: ""
                    })
                );
            }
        });
    }

    const columns: TableColumn<ProductModel>[] = [
        {
            name: "NO.",
            width: "60px",
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: "NAMA MITRA",
            selector: (row) => row.mitraName,
            sortable: true,
        },
        {
            name: "NAMA LAYANAN",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "KATEGORI KEAHLIAN",
            selector: (row) => row.expertiseName,
            sortable: true,
        },
        {
            name: "OPSI LAYANAN",
            selector: (row) => row.mitraTypeName,
            sortable: true,
        },
        {
            name: "JENIS LAYANAN",
            cell: (item: ProductModel) => {
                const option = baseType.find(
                    (option) => parseInt(option.value) === item.baseType
                );
                return option ? option.label : '';
            },
        },
        {
            name: "STATUS LAYANAN",
            selector: (row) => row.approvalStatus,
            format: (row) => (
                <span>{getServiceApprovalStatus(row.approvalStatus)}</span>
            )
        },
        {
            name: "AKSI",
            width: "450px",
            cell: (item: ProductModel) => (
                <>
                    <Link
                        to={{
                            pathname: `/service/delete-mitra-service/detail/product/${item.slug}`,
                        }}>
                        <button className="btn button-primary mr-2">PRATINJAU</button>
                    </Link>
                    <button className="btn button-primary mr-2" onClick={() => onReplyClicked({
                        serviceId: item.id,
                        name: item.mitraName,
                        subject: item.name,
                        description: `Nama Layanan: ${item.name} \n Jenis Layanan: ${item.mitraTypeName} \n Kategori Keahlian: ${item.expertiseName}`
                    })}>REPLY
                    </button>
                    <div className={"mr-2"}> |</div>
                    <button
                        onClick={() => onAcceptClicked(item.id)}
                        className="btn button-primary mr-2">
                        TERIMA
                    </button>
                    <button
                        onClick={() => {
                            onRevisionClicked({
                                serviceId: item.id,
                                revisionNote: `Nama Layanan: ${item.name} \n Jenis Layanan: ${item.mitraTypeName} \n Kategori Keahlian: ${item.expertiseName}`
                            })
                        }}
                        className="btn button-primary mr-2">
                        REVISI
                    </button>
                    <div className={"mr-2"}> |</div>
                    <button
                        onClick={() => {
                            if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
                                onDelete(item.id);
                        }}
                        className="btn button-danger">
                        HAPUS
                    </button>
                </>
            ),
        },
    ];

    // end of datatable components

    useEffect(() => {
        dispatch(getProductByApprovalList(activeTag === ApprovalStatusEnum.All ? undefined : activeTag));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTag]);

    // useEffect(() => {
    //     dispatch(getProductList());
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [dateCreated]);

    const onDelete = (id: string) => {
        dispatch(
            deleteProduct(id)
        );
    };

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <div className="w-100">
                <hr/>
                <div className="d-inline w-50">
                    <div className="input-field-group">
                        <FontAwesomeIcon
                            className="input-field-group-icon"
                            icon={faSearch}
                            // style={{ color: "#160E25" }}
                        />
                        <input
                            id="search"
                            type="search"
                            placeholder="Filter berdasarkan nama"
                            className="input-field-group-text"
                            value={filterText}
                            onChange={(e) => {
                                onSetterFilterText(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="d-inline float-right">
                    <button
                        onClick={() => {
                            // setFilterText("");
                            setActiveTag(ApprovalStatusEnum.All);
                        }}
                        className={`btn mr-1 button-filter-tag ${
                            activeTag === ApprovalStatusEnum.All ? "active" : ""
                        }`}
                    >
                        <img
                            src={activeTag === ApprovalStatusEnum.All ? all_active : all_disabled}
                            alt="all"
                            className="mr-1"
                        />{" "}
                        SEMUA
                        {/*<Badge className="tag-badge">1</Badge>*/}
                    </button>
                    <button
                        onClick={() => {
                            // setFilterText("false");
                            setActiveTag(ApprovalStatusEnum.Ditinjau);
                        }}
                        className={`btn mr-1 button-filter-tag ${
                            activeTag === ApprovalStatusEnum.Ditinjau ? "active" : ""
                        }`}
                    >
                        <img
                            src={
                                activeTag === ApprovalStatusEnum.Ditinjau
                                    ? unverified_active
                                    : unverified_disabled
                            }
                            alt="unverified"
                            className="mr-1"
                        />{" "}
                        DITINJAU
                        {/*<Badge className="tag-badge">1</Badge>*/}
                    </button>
                    <button
                        // ref={ref}
                        onClick={() => {
                            // setFilterText("true");
                            setActiveTag(ApprovalStatusEnum.Diterima);
                        }}
                        className={`btn mr-1 button-filter-tag ${
                            activeTag === ApprovalStatusEnum.Diterima ? "active" : ""
                        }`}
                    >
                        <img
                            src={
                                activeTag === ApprovalStatusEnum.Diterima ? verified_active : verified_disabled
                            }
                            alt="verified"
                            className="mr-1"
                        />{" "}
                        DITERIMA
                        {/*<Badge className="tag-badge">1</Badge>*/}
                    </button>
                    <button
                        onClick={() => {
                            // setFilterText("Revision");
                            setActiveTag(ApprovalStatusEnum.Revisi);
                        }}
                        className={`btn mr-1 button-filter-tag ${
                            activeTag === ApprovalStatusEnum.Revisi ? "active" : ""
                        }`}
                    >
                        <img
                            src={
                                activeTag === ApprovalStatusEnum.Revisi
                                    ? unverified_active
                                    : unverified_disabled
                            }
                            alt="revision"
                            className="mr-1"
                        />{" "}
                        REVISI
                        {/*<Badge className="tag-badge">1</Badge>*/}
                    </button>
                </div>
                <hr/>
            </div>
        );
    }, [filterText, resetPaginationToggle, activeTag]);

    return (
        <>
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={false}
                noHeader
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                customStyles={TableCustomStyles}
                persistTableHead
                defaultSortFieldId={"createdDate"}
                paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
            />
            <ReplyMitra emailRequest={emailRequest} show={showReplyModal} handleClose={showReplyModalHandler} />
            <Revision revisionRequest={revisionRequest} isPackage={false} show={showRevisionModal} handleClose={showRevisionModalHandler} />
        </>
    );
};

export default MitraProductTable;
