import axios from "axios";
import { BlogCategoryModel, CreateBlogCategoryModel } from "./models";
import { endpoint } from "../../../constants";

export default class BlogCategoryApi {
  static createBlogCategory(blogData: CreateBlogCategoryModel) {
    return axios
      .post<any>(endpoint + "/api/Blog/CreateCategory", blogData)
      .then((res) => res.data.data);
  }

  static getBlogCategoryList() {
    return axios
      .get<any>(endpoint + "/api/Blog/GetListCategory")
      .then((res) => res.data.data);
  }

  static getCategorySingleItem(blogId: string) {
    return axios
      .get<any>(endpoint + "/api/Blog/GetSingleCategory?id=" + blogId)
      .then((res) => res.data.data);
  }

  static updateBlogCategory(data: BlogCategoryModel) {
    return axios
      .post<any>(endpoint + "/api/Blog/UpdateCategory", data)
      .then((res) => res.data.data);
  }

  static deleteBlogCategory(data: BlogCategoryModel) {
    return axios
      .post<any>(endpoint + "/api/Blog/DeleteCategory", data)
      .then((res) => [{ response: res.data.data }, { id: data.id }]);
  }
}
