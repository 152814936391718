import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import { ReplyModel, ReportIssueAttachmentModel, ReportIssueModel } from "./models";
import ReportIssueApi from "./reportIssue.api";

export interface ReportIssueSlice {
   name?: string;
   list?: ReportIssueModel[];
   listAttachment?: ReportIssueAttachmentModel[];
   single?: ReportIssueModel;
   isLoading?: boolean;
   dateCreated?: string;
   error?: ApiErrorResponse<any>;
   status?: string;
   completeStatus?: string;
}

export const getReportIssueList = createAsyncThunk(
   "reportIssueState/reportIssueList",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await ReportIssueApi.getReportIssueList();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getReportIssueSingleItem = createAsyncThunk(
   "reportIssueState/reportIssueSingleItem",
   async (issueId: string, { rejectWithValue }) => {
      try {
         return await ReportIssueApi.getReportIssueSingleItem(issueId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getIssueAttachmentItem = createAsyncThunk(
   "reportIssueState/reportIssueAttachment",
   async (issueId: string, { rejectWithValue }) => {
      try {
         return await ReportIssueApi.getAttachment(issueId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const deleteReportIssue = createAsyncThunk(
   "reportIssueState/deleteReportIssue",
   async (data: ReportIssueModel, { rejectWithValue }) => {
      try {
         return await ReportIssueApi.deleteReportIssue(data);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const replyToEmail = createAsyncThunk(
   "reportIssueState/replyToEmail",
   async (reply: ReplyModel, { rejectWithValue }) => {
      try {
         return await ReportIssueApi.replyToEmail(reply);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const completeIssue = createAsyncThunk(
    "reportIssueState/completeIssue",
    async (id: string, { rejectWithValue }) => {
       try {
          return await ReportIssueApi.completeIssue(id);
       } catch (e) {
          return rejectWithValue(e as ApiErrorResponse<any>);
       }
    }
);

const reportIssueSlice = createSlice({
   name: "createIssueState",
   initialState: {} as ReportIssueSlice,
   reducers: {
      setFilter: (state, action) => {
         state.list = action.payload;
      },
      resetSingle: (state) => {
         state.single = {} as ReportIssueModel;
      },
      resetStatus: (state) => {
         state.status = "";
         state.completeStatus = "";
      },
   },
   extraReducers: (builder) => {
      builder.addCase(getReportIssueSingleItem.pending, (state) => {
         state.single = undefined;

      });
      builder.addCase(getReportIssueSingleItem.fulfilled, (state, { payload }) => {
         state.single = payload;

      });
      builder.addCase(getReportIssueSingleItem.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;

      });

      builder.addCase(getReportIssueList.pending, () => {

      });
      builder.addCase(getReportIssueList.fulfilled, (state, { payload }) => {
         state.list = payload;
      });
      builder.addCase(getReportIssueList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;

      });

      builder.addCase(getIssueAttachmentItem.pending, () => {
      });
      builder.addCase(getIssueAttachmentItem.fulfilled, (state, { payload }) => {
         state.listAttachment = payload;
      });
      builder.addCase(getIssueAttachmentItem.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
      });
      builder.addCase(deleteReportIssue.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(deleteReportIssue.fulfilled, (state) => {
         state.isLoading = false;
         state.dateCreated = new Date().toISOString();
      });
      builder.addCase(deleteReportIssue.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
      });
      builder.addCase(replyToEmail.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(replyToEmail.fulfilled, (state) => {
         state.isLoading = false;
         state.status = "success";
      });
      builder.addCase(replyToEmail.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = "failed";
      });
      builder.addCase(completeIssue.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(completeIssue.fulfilled, (state) => {
         state.isLoading = false;
         state.completeStatus = "success";
      });
      builder.addCase(completeIssue.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.completeStatus = "failed";
      });

   },
});

export const { resetSingle, resetStatus } = reportIssueSlice.actions;
export default reportIssueSlice.reducer;
