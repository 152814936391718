import axios from "axios";
import { endpoint } from "../../../constants";

export default class BlogApi {
  static getBlogList() {
    return axios
      .get<any>(endpoint + "/api/Blog/GetList")
      .then((res) => res.data.data);
  }

  static getBlogByUser(userId: string) {
    return axios
      .get<any>(endpoint + "/api/Blog/GetListByUser?userId=" + userId)
      .then((res) => res.data.data);
  }

  static getSingleBlog(id: string) {
    return axios
      .get<any>(endpoint + "/api/Blog/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }
}
