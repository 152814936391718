import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { SocialMediaTypeModel } from "./models";
import SocialMediaTypeApi from "./socialMediaType.api";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";

export interface CreateSocialMediaTypeSlice {
   name?: string;
   list?: SocialMediaTypeModel[];
   single?: SocialMediaTypeModel;
   isLoading?: boolean;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
}

export const createSocialMediaType = createAsyncThunk(
   "createSocialMediaTypeState/createSocialMediaType",
   async (socialMediaTypeModel: SocialMediaTypeModel, { rejectWithValue }) => {
      try {
         return await SocialMediaTypeApi.createSocialMediaType(socialMediaTypeModel);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getSocialMediaTypeList = createAsyncThunk(
   "socialMediaTypeState/socialMediaTypeList",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await SocialMediaTypeApi.getSocialMediaTypeList();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getSocialMediaTypeSingleItem = createAsyncThunk(
   "socialMediaTypeState/socialMediaTypeSingleItem",
   async (SocialMediaTypeId: string, { rejectWithValue }) => {
      try {
         return await SocialMediaTypeApi.getSingleItem(SocialMediaTypeId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const updateSocialMediaType = createAsyncThunk(
   "socialMediaTypeState/updatesocialMediaType",
   async (socialMediaTypeModel: SocialMediaTypeModel, { rejectWithValue }) => {
      try {
         return await SocialMediaTypeApi.updateSocialMediaType(socialMediaTypeModel);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const deleteSocialMediaType = createAsyncThunk(
   "socialMediaTypeState/deleteSocialMediaType",
   async (socialMediaTypeModel: SocialMediaTypeModel, { rejectWithValue }) => {
      try {
         return await SocialMediaTypeApi.deleteSocialMediaType(socialMediaTypeModel);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const createSocialMediaTypeSlice = createSlice({
   name: "createSocialMediaTypeState",
   initialState: {} as CreateSocialMediaTypeSlice,
   reducers: {
      setFilter: (state, action) => {
         state.list = action.payload;
      },
      resetSingle: (state) => {
         state.single = {} as SocialMediaTypeModel;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(createSocialMediaType.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(createSocialMediaType.fulfilled, (state, { payload }) => {
         state.name = new Date().toISOString();
         state.isLoading = false;
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(createSocialMediaType.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(getSocialMediaTypeSingleItem.pending, (state) => {
         state.single = undefined;
         state.isLoading = true;
      });
      builder.addCase(getSocialMediaTypeSingleItem.fulfilled, (state, { payload }) => {
         state.single = payload;
         state.isLoading = false;
      });
      builder.addCase(getSocialMediaTypeSingleItem.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(getSocialMediaTypeList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getSocialMediaTypeList.fulfilled, (state, { payload }) => {
         state.list = payload;
         state.isLoading = false;
      });
      builder.addCase(getSocialMediaTypeList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(updateSocialMediaType.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(updateSocialMediaType.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.name = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(updateSocialMediaType.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(deleteSocialMediaType.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(deleteSocialMediaType.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.name = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(deleteSocialMediaType.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
   },
});

export const { setFilter, resetSingle, resetStatus } = createSocialMediaTypeSlice.actions;
export default createSocialMediaTypeSlice.reducer;
