export interface UserModel {
   name: string;
   isAdminVerified: boolean;
   id: string;
   email: string;
   emailConfirmed: boolean;
}

export interface ProfessionalModel {
   id: string;
   userId: string;
   professionalTypeList: any[];
   fullname: string;
   identityNumber: string;
   phoneNumber: string;
   address: string;
   bio: string;
   website: string;
   isVerified: boolean;
   bankAccount: string;
   bankName: string;
   bankOnBehalf: string;
   username: string;
   aboutMeTitle: string;
   aboutMePage: string;
   gender: Gender;
}

export interface PersonalAttachmentModel {
   id: string;
   type: number;
   path: string;
   professionalId: string;
}

export enum Gender {
   Pria, Wanita
}

export interface VendorAttachmentModel {
   id: string;
   type: number;
   path: string;
   note: string;
   vendorId: string;
}

export interface PersonalCertificateModel {
   name: string;
   description: string;
   path: string;
   professionalId: string;
}

export interface PersonalLanguageModel {
   professionalId: string;
   LanguageList: any[];
   note: string;
}

export interface PersonalCityModel {
   professionalId: string;
   cityList: [
      {
         cityId: string;
         isMainCity: boolean;
         travelFee: number;
         note: string;
      }
   ];
}

export interface CityModel {
   id: string;
   name: string;
   description: string;
}

export interface OtherCityModel {
   id: string;
   name: string;
   description: string;
   travelFee: string;
}

export interface LanguageModel {
   id: string;
   name: string;
   description: string;
}

export interface SocialMediaModel {
   description: string;
   id: string;
   link: string;
   name: string;
   username: string;
}

export interface PersonalSocialMediaModel {
   mitraId: string;
   listSocialMediaType: [
      {
         mitraId: string;
         username: string;
         link: string;
         socialMediaTypeId: string;
      }
   ];
}

export enum MitraType {
   vendor,
   professional,
   none,
}

export interface ProfessionalTypeModel {
   canUploadFile: boolean;
   description: string;
   iconURL: string;
   id: string;
   name: string;
}