import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { CreateIssueCategoryModel, IssueCategoryModel } from "./models";
import IssueCategoryApi from "./issueCategory.api";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";

export interface CreateIssueCategorySlice {
  name?: string;
  list?: IssueCategoryModel[];
  single?: IssueCategoryModel;
  isLoading?: boolean;
  dateCreated?: string;
  error?: ApiErrorResponse<any>;
  status?: ApiResponseStatus;
}

export const createIssueCategory = createAsyncThunk(
  "createIssueCategoryState/createIssueCategory",
  async (data: CreateIssueCategoryModel, { rejectWithValue }) => {
    try {
      return await IssueCategoryApi.createIssueCategory(data);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getIssueCategoryList = createAsyncThunk(
  "issueCategoryState/issueCategoryList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await IssueCategoryApi.getIssueCategoryList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getIssueCategorySingleItem = createAsyncThunk(
  "issueCategoryState/issueCategorySingleItem",
  async (issueId: string, { rejectWithValue }) => {
    try {
      return await IssueCategoryApi.getCategorySingleItem(issueId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateIssueCategory = createAsyncThunk(
  "issueCategoryState/updateIssueCategory",
  async (data: IssueCategoryModel, { rejectWithValue }) => {
    try {
      return await IssueCategoryApi.updateIssueCategory(data);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteIssueCategory = createAsyncThunk(
  "issueCategoryState/deleteIssueCategory",
  async (data: IssueCategoryModel, { rejectWithValue }) => {
    try {
      return await IssueCategoryApi.deleteIssueCategory(data);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const createIssueCategorySlice = createSlice({
  name: "createIssueState",
  initialState: {} as CreateIssueCategorySlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as IssueCategoryModel;
    },
    resetStatus: (state) => {
      state.status = ApiResponseStatus.pending;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createIssueCategory.pending, (state) => {
      state.isLoading = true;
      state.status = ApiResponseStatus.pending;
    });
    builder.addCase(createIssueCategory.fulfilled, (state) => {
      state.name = new Date().toISOString();
      state.isLoading = false;
      state.status = ApiResponseStatus.success;
    });
    builder.addCase(createIssueCategory.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = ApiResponseStatus.failed;
    });
    builder.addCase(getIssueCategorySingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(
      getIssueCategorySingleItem.fulfilled,
      (state, { payload }) => {
        state.single = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(
      getIssueCategorySingleItem.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
      }
    );
    builder.addCase(getIssueCategoryList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getIssueCategoryList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getIssueCategoryList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateIssueCategory.pending, (state) => {
      state.isLoading = true;
      state.status = ApiResponseStatus.pending;
    });
    builder.addCase(updateIssueCategory.fulfilled, (state) => {
      state.isLoading = false;
      state.name = new Date().toISOString();
      state.status = ApiResponseStatus.success;
    });
    builder.addCase(updateIssueCategory.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
      state.status = ApiResponseStatus.failed;
    });
    builder.addCase(deleteIssueCategory.pending, (state) => {
      state.isLoading = true;
      state.status = ApiResponseStatus.pending;
    });
    builder.addCase(deleteIssueCategory.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.name = new Date().toISOString();
      state.status = ApiResponseStatus.success;
    });
    builder.addCase(deleteIssueCategory.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
      state.status = ApiResponseStatus.failed;
    });
  },
});

export const { setFilter, resetSingle, resetStatus } = createIssueCategorySlice.actions;
export default createIssueCategorySlice.reducer;
