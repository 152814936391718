import { ImageUpload } from "../../../@core/models/media/ImageUpload";
import UploadFileApi from "./fileUpload.api";
import { uploadStatus } from "./models";

export const uploadSingleImage = async (token: string, image: ImageUpload, path: string) => {
   const response = await UploadFileApi.uploadImage({
      file_name: "image" + "." + image.fileFormat,
      base64url: image.fileValue.split(",")[1],
      imagepath: path,
      location: path,
   });
   if (response.status === 200) {
      return { status: uploadStatus.success, path: response.data.imagepath };
   } else {
      return { status: uploadStatus.failed, path: "" };
   }
};

// export const uploadMultipleImage = () => {

// }
