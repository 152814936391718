import axios from "axios";
import {endpoint} from "../../constants";
import {ChangeWithdrawStatus} from "../withdraw/models";
import {CreateWalletModel, GetWalletRequest} from "./models";

export default class WalletApi {
    static getWalletList(args: GetWalletRequest) {
        return axios
            .post<any>(endpoint + "/api/AddSaldoLog/GetListWithPaging", args)
            .then((res) => res.data.data);
    }

    static getSingleWallet(id: string) {
        return axios
            .get<any>(endpoint + `/api/AddSaldoLog/GetSingleItem?id=${id}`)
            .then((res) => res.data.data);
    }

    static createWallet(args: CreateWalletModel) {
        return axios
            .post<any>(
                endpoint + `/api/AddSaldoLog/Create`, args
            )
            .then((res) => res.data.data);
    }

    static updateWallet(args: CreateWalletModel) {
        return axios
            .post<any>(
                endpoint +
                `/api/AddSaldoLog/Update`, args
            )
            .then((res) => res.data.data);
    }
    static deleteWallet(args: CreateWalletModel) {
        return axios
            .post<any>(
                endpoint +
                `/api/AddSaldoLog/Delete`, args
            )
            .then((res) => res.data.data);
    }
}