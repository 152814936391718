
export interface CustomerModel {
   id: string;
   userId: string;
   fullname: string;
   identityNumber: string;
   phoneNumber: string;
   address: string;
   bio: string;
   website: string;
   bankAccount: string;
   bankName: string;
   bankOnBehalf: string;
   isVerified: boolean;
   type: number;
   customerInfo: string;
   customerPicture: string | null;
}
export interface RatingModel {
   packageId: string;
   customer: CustomerModel;
   description: string;
   rating: number;
   date: string;
}

export interface MitraDetailModel {
   id: string;
   name: string;
   photo: string;
   follower: number;
   totalProduct: number;
   rating: number;
   joinTime: string;
   type: number;
}

export interface PackageAttachmentModel {
   id: string;
   path: string;
   packageId: string;
}

export interface PackageDetailModel {
   id: string;
   packageId: string;
   startValue: number;
   endValue: number;
   price: number;
   description: string;
   uom: string;
}

export interface PackageInclusionModel {
   id: string;
   packageId: string;
   productId: string;
   productName?: string;
}

export interface PackageModel {
   id: string;
   userId: string;
   userName: string;
   expertiseId: string;
   expertiseName: string;
   mitraName: string;
   baseType: BaseTypeEnum;
   priceType: number;
   name: string;
   description: string;
   muaPax: string;
   image: string;
   price: number;
   ratingList: RatingModel[];
   totalRating: number;
   totalRating5: number;
   totalRating4: number;
   totalRating3: number;
   totalRating2: number;
   totalRating1: number;
   totalSold: number;
   location: string;
   totalReview: number;
   totalFavorite: number;
   mitraDetail: MitraDetailModel;
   slug: string;
   lastLogin: string;
   mitraTypeId: string;
   mitraTypeName: string;
   approvalStatus: ApprovalStatusEnum;
}

export interface ProductAttachmentModel {
   id: string;
   path: string;
   packageId: string;
}

export interface ProductModel {
   id: string;
   userId: string;
   userName: string;
   mitraName: string;
   expertiseId: string;
   expertiseName: string;
   mitraTypeId: string;
   mitraTypeName: string;
   baseType: BaseTypeEnum;
   name: string;
   description: string;
   uom: string;
   price: number;
   image: string;
   ratingList: RatingModel[];
   totalFavorite: number;
   totalRating: number;
   totalRating5: number;
   totalRating4: number;
   totalRating3: number;
   totalRating2: number;
   totalRating1: number;
   totalSold: number;
   location: string;
   totalReview: number;
   mitraDetail: MitraDetailModel;
   slug: string;
   lastLogin: string;
   approvalStatus: ApprovalStatusEnum;
}

export interface ReplyModel {
   packageProductId: string;
   subject: string;
   description: string;
}

export enum ApprovalStatusEnum { Ditinjau, Diterima, Revisi, All }
export enum BaseTypeEnum { Product, TimeBased = 1, QuantityBased = 2 }

export interface EmailBodyModel {
   serviceId: string;
   name: string;
   subject: string;
   description: string;
}

export interface RevisionModel {
   serviceId: string;
   revisionNote: string;
}

export interface UpdateApprovalStatusModel {
   id: string;
   approvalStatus: ApprovalStatusEnum;
   revisiNote: string;
}
