import axios from "axios";
import { ReasonOptionModel } from "./models";
import { endpoint } from "../../../constants";

export default class ReasonOptionApi {
  static create(data: ReasonOptionModel) {
    return axios
      .post<any>(endpoint + "/api/CancelReason/Create", data)
      .then((res) => res.data.data);
  }

  static getList() {
    return axios
      .get<any>(endpoint + "/api/CancelReason/GetList")
      .then((res) => res.data.data);
  }

  static getSingleItem(id: string) {
    return axios
      .get<any>(endpoint + "/api/CancelReason/GetSingleItem", {
        params: {
          id: id,
        },
      })
      .then((res) => res.data.data);
  }

  static update(data: ReasonOptionModel) {
    return axios
      .post<any>(endpoint + "/api/CancelReason/Update", data)
      .then((res) => res.data.data);
  }

  static delete(data: ReasonOptionModel) {
    return axios
      .post<any>(endpoint + "/api/CancelReason/Delete", data)
      .then((res) => res.data.data);
  }
}
