import axios from "axios";
import {endpoint} from "../../constants";
import {AttachmentType, ChatAttachmentModel} from "../../@core/models/chat/models";
import {UploadImageModel} from "../media/fileUpload/models";

export default class ChatApi {
    static createChatAttachment(chatAttachment: ChatAttachmentModel) {
        return axios
            .post<any>(endpoint + "/api/ChatAttachment/Create", chatAttachment)
            .then((res) => res);
    }

    static getListAlbumByRoom(uid: string) {
        return axios
            .get<any>(endpoint + "/api/ChatAttachment/Create", {
                params: {
                    roomId: uid,
                }
            })
            .then((res) => res.data);
    }

    static getListByAlbum(uid: string, albumName: string) {
        return axios
            .get<any>(endpoint + "/api/ChatAttachment/GetListByAlbum", {
                params: {
                    roomId: uid,
                    album: albumName,
                }
            })
            .then((res) => res.data);
    }

    static getListByType(uid: string, type: AttachmentType) {
        return axios
            .get<any>(endpoint + "/api/ChatAttachment/GetListByType", {
                params: {
                    roomId: uid,
                    type: type,
                }
            })
            .then((res) => res.data);
    }

    static uploadFile(fileData: any) {
        const formData = new FormData();
        formData.append("files", fileData.file, fileData.file.name);
        return axios
            .post<any>(
                endpoint + "/web/api/Upload/UploadFile",
                fileData
            )
            .then((res) => res);
    }

    static uploadImage(imageData: UploadImageModel) {
        return axios
            .post<any>(
                endpoint + "/web/api/Upload/UploadImage",
                imageData
            )
            .then((res) => res);
    }
}