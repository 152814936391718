import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import { getBlogSingleItem } from "../blog.reducer";
import BlogDetail from "../components/BlogDetail";
import { SingleBlogModel } from "../models";

const BlogPreviewPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const singleDataState = useSelector(
    (state: RootState) => state.blogAdmin.single ?? ({} as SingleBlogModel)
  );

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getBlogSingleItem(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return <BlogDetail singleBlog={singleDataState}/>;
};

export default BlogPreviewPage;
