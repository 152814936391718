import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import {
  PackageAttachmentModel,
  PackageDetailModel,
  PackageFacilityModel,
  PackageInclusionModel,
  PackageModel,
  ProductAttachmentModel,
  ProductModel,
} from "./models";
import ProductApi from "./product.api";

export interface CreateProductSlice {
  name?: string;
  productList?: ProductModel[];
  productAttachment?: ProductAttachmentModel[];
  singleProduct?: ProductModel;
  packageList?: PackageModel[];
  packageAttachment?: PackageAttachmentModel[];
  singlePackage?: PackageModel;
  packageDetail?: PackageDetailModel[];
  packageFacility?: PackageFacilityModel;
  packageInclusion?: PackageInclusionModel[];
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
}

// Product Function

export const getProduct = createAsyncThunk(
  "productState/productList",
  async (mitraId: string, { rejectWithValue }) => {
    try {
      return await ProductApi.getProduct(mitraId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getProductAttachment = createAsyncThunk(
  "productState/productAttachment",
  async (productId: string, { rejectWithValue }) => {
    try {
      return await ProductApi.getProductAttachment(productId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSingleProduct = createAsyncThunk(
  "productState/singleProduct",
  async (productId: string, { rejectWithValue }) => {
    try {
      return await ProductApi.getSingleProduct(productId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

// Package Function

export const getPackage = createAsyncThunk(
  "productState/packageList",
  async (mitraId: string, { rejectWithValue }) => {
    try {
      return await ProductApi.getPackage(mitraId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSinglePackage = createAsyncThunk(
  "productState/singlePackage",
  async (packageId: string, { rejectWithValue }) => {
    try {
      return await ProductApi.getSinglePackage(packageId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getPackageAttachment = createAsyncThunk(
  "productState/packageAttachment",
  async (packageId: string, { rejectWithValue }) => {
    try {
      return await ProductApi.getPackageAttachment(packageId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getPackageDetail = createAsyncThunk(
  "productState/packageDetail",
  async (packageId: string, { rejectWithValue }) => {
    try {
      return await ProductApi.getPackageDetail(packageId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getPackageFacility = createAsyncThunk(
  "productState/packageFacility",
  async (packageId: string, { rejectWithValue }) => {
    try {
      return await ProductApi.getPackageFacility(packageId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getPackageInclusion = createAsyncThunk(
  "productState/packageInclusion",
  async (packageId: string, { rejectWithValue }) => {
    try {
      return await ProductApi.getPackageInclusion(packageId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const createProductSlice = createSlice({
  name: "createProductState",
  initialState: {} as CreateProductSlice,
  reducers: {
    // setFilter: (state, action) => {
    //   state.list = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProduct.fulfilled, (state, { payload }) => {
      state.productList = payload;
      state.isLoading = false;
    });
    builder.addCase(getProduct.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getProductAttachment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProductAttachment.fulfilled, (state, { payload }) => {
      state.productAttachment = payload;
      state.isLoading = false;
    });
    builder.addCase(getProductAttachment.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getSingleProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSingleProduct.fulfilled, (state, { payload }) => {
      state.singleProduct = payload;
      state.isLoading = false;
    });
    builder.addCase(getSingleProduct.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getPackage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPackage.fulfilled, (state, { payload }) => {
      state.packageList = payload;
      state.isLoading = false;
    });
    builder.addCase(getPackage.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getSinglePackage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSinglePackage.fulfilled, (state, { payload }) => {
      state.singlePackage = payload;
      state.isLoading = false;
    });
    builder.addCase(getSinglePackage.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getPackageAttachment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPackageAttachment.fulfilled, (state, { payload }) => {
      state.packageAttachment = payload;
      state.isLoading = false;
    });
    builder.addCase(getPackageAttachment.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getPackageDetail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPackageDetail.fulfilled, (state, { payload }) => {
      state.packageDetail = payload;
      state.isLoading = false;
    });
    builder.addCase(getPackageDetail.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getPackageInclusion.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPackageInclusion.fulfilled, (state, { payload }) => {
      state.packageInclusion = payload;
      state.isLoading = false;
    });
    builder.addCase(getPackageInclusion.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
  },
});

// export const { setFilter } = createProfessionalTypeSlice.actions;
export default createProductSlice.reducer;
