import React, {useState, ChangeEvent, useEffect} from "react";
import {Form} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {ImageUpload} from "../../../../@core/models/media/ImageUpload";
import FormFile from "../../../../components/FormField/FormFile";
import {checkEmptyObject, checkObjectIsNotEmpty} from "../../../../helpers/checkEmptyObject";
import Select from "react-select";
import {OptionModel} from "../../../../@core/models/types";
import {CreateStoreProductModel, StoreProductModel, StoreProductTypeEnum} from "../models";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import {dateSetTimeToNow} from "../../../../helpers/dateManipulation";

type InputForm = {
    name: string;
    type: OptionModel;
    price: number;
    link: string;
    location: string;
    description: string;
    information: string;
    classDate: string;
}

const StoreProductForm = ({
                           data = {} as StoreProductModel,
                           isEdit = false,
                           ...props
                       }: StoreProductFormProps) => {
    let history = useHistory();

    const [inputData, setInputData] = useState<InputForm>({} as InputForm);
    const [validated, setValidated] = useState(false);
    const [image, setImage] = useState<ImageUpload>({} as ImageUpload);
    const [errorImage, setErrorImage] = useState<boolean>(false);

    const [productTypeOption] = useState<OptionModel[]>([
        {
            label: "Produk",
            value: StoreProductTypeEnum.Product.toString(),
        },
        {
            label: "Kelas",
            value: StoreProductTypeEnum.Class.toString(),
        },
    ]);

    useEffect(() => {

    }, []);

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputData({
            ...inputData,
            [e.target.name]: e.target.value
        });
    };

    const onCancelHandler = () => {
        history.push("/store/product/list");
    };

    const handleFileUpload = (name: string, fileFormat: string, fileValue: string) => {
        setImage({
            name,
            fileFormat,
            fileValue,
            fromApi: false,
        });
    };

    useEffect(() => {
        if (isEdit && checkObjectIsNotEmpty(data)) {
            setInputData({
                name: data.name,
                type: {
                    value: data.type.toString(),
                    label: data.type === StoreProductTypeEnum.Product ? "Produk" : "Kelas"
                },
                price: data.price,
                link: data.link,
                location: data.location,
                description: data.description,
                information: data.information,
                classDate: data.classDate,
            });
            setImage({
                name: "",
                fileFormat: "",
                fileValue: data.path,
                fromApi: true,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isEdit]);

    const onTypeChangeHandler = (data: any) => {
        setInputData({
            ...inputData,
            ['type']: data
        });
    };

    const onInputDateChange = (name: string, value: string) => {
        setInputData((old) => {
            return {
                ...old,
                [name]: value
            }
        });
    }

    return (
        <Form
            noValidate
            validated={validated}
            onSubmit={(e) => {
                const form = e.currentTarget;
                if (!form.checkValidity()) {
                    e.preventDefault();
                    e.stopPropagation();
                } else {
                    if (checkEmptyObject(image) && !isEdit) {
                        setErrorImage(true);
                        e.preventDefault();
                    } else {
                        setErrorImage(false);
                        e.preventDefault();
                        props.onSubmit({
                            id: data.id,
                            name: inputData.name,
                            type: parseInt(inputData.type.value) as StoreProductTypeEnum,
                            price: inputData.price,
                            link: inputData.link,
                            location: inputData.location,
                            description: inputData.description,
                            information: inputData.information,
                            path: data.path,
                            image: image,
                            classDate: inputData.type.value === StoreProductTypeEnum.Class.toString() ? inputData.classDate : null
                        });
                        setInputData({} as InputForm);
                        // history.replace("/mitra/professional/type/list");
                    }
                }
                setValidated(true);
            }}>
            <Form.Group>
                <Form.Label>Nama Produk</Form.Label>
                <Form.Control
                    type="text"
                    name={"name"}
                    placeholder="Masukkan Nama Produk"
                    onChange={onInputChange}
                    value={inputData.name}
                    required
                />
            </Form.Group>

            <Form.Group className="mb-4">
                <Form.Label>Jenis Produk</Form.Label>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Ketik untuk mencari jenis produk"
                    isSearchable={true}
                    name="type"
                    options={productTypeOption}
                    onChange={onTypeChangeHandler}
                    value={inputData.type}
                />
            </Form.Group>

            {
                inputData.type?.value === StoreProductTypeEnum.Class.toString() && (
                    <Form.Group className="mb-4">
                        <Form.Label>Tanggal Kelas</Form.Label>
                        <DatePicker
                            className="form-control"
                            selected={inputData.classDate !== undefined ? new Date(inputData.classDate) : new Date()}
                            onChange={(date) => onInputDateChange('classDate', dateSetTimeToNow((date as Date).toISOString()) ?? new Date().toISOString())}
                        />
                    </Form.Group>
                )
            }

            <Form.Group className="mb-4">
                <Form.Label>Harga</Form.Label>
                <NumberFormat
                    value={inputData.price}
                    className="form-control d-inline field_plaint_text"
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"Rp. "}
                    onValueChange={(values) => {
                        setInputData({
                            ...inputData,
                            ['price']: values.floatValue ?? 0
                        });
                    }}
                />
            </Form.Group>

            <Form.Group>
                <Form.Label>Link</Form.Label>
                <Form.Control
                    type="text"
                    name={"link"}
                    placeholder="Masukkan Link"
                    onChange={onInputChange}
                    value={inputData.link}
                    required
                />
            </Form.Group>

            <Form.Group>
                <Form.Label>Lokasi</Form.Label>
                <Form.Control
                    type="text"
                    name={"location"}
                    placeholder="Masukkan Lokasi"
                    onChange={onInputChange}
                    value={inputData.location}
                    required
                />
            </Form.Group>

            <Form.Group>
                <Form.Label>Deskripsi</Form.Label>
                <Form.Control
                    as="textarea"
                    name="description"
                    rows={3}
                    placeholder="Isi deskripsi produk"
                    onChange={onInputChange}
                    value={inputData.description}
                    required
                />
            </Form.Group>

            <Form.Group>
                <Form.Label>Informasi</Form.Label>
                <Form.Control
                    as="textarea"
                    name="information"
                    rows={3}
                    placeholder="Isi informasi produk"
                    onChange={onInputChange}
                    value={inputData.information}
                    required
                />
            </Form.Group>


            <Form.Group>
                <FormFile
                    name="path"
                    defaultImage={image.fileValue}
                    handleAcceptedFiles={handleFileUpload}
                    height={200}
                    required={!isEdit}
                    onError={errorImage}
                    defaultFromApi={image.fromApi}
                />
            </Form.Group>

            <div className="text-center mt-5">
                <button
                    className="btn button-outline-primary mr-3"
                    type="reset"
                    onClick={onCancelHandler}>
                    BATALKAN
                </button>
                <button className="btn button-primary" type="submit">
                    {isEdit ? "EDIT PRODUK" : "TAMBAH PRODUK"}
                </button>
            </div>
        </Form>
    );
};

export interface StoreProductFormProps {
    onSubmit: (args: CreateStoreProductModel) => void;
    data: StoreProductModel;
    isEdit: boolean;
}

export default StoreProductForm;
