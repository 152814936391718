import React, {Fragment} from "react";
import {Container, Row, Col} from "react-bootstrap";
import MitraPointTable from "../components/MitraPointTable";

const ProjectSettingPage = () => {
    return (
        <Fragment>
            <MitraPointTable/>
        </Fragment>
    );
};

export default ProjectSettingPage;
