import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, ChangeEvent, useEffect } from "react";
import { Form } from "react-bootstrap";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Viewer from "react-viewer";
import { RootState } from "../../../app/store";
import { endpoint } from "../../../constants";
import { checkEmptyObject, checkObjectIsNotEmpty } from "../../../helpers/checkEmptyObject";
import { addDefaultProfileImage } from "../../../helpers/defaultBrokenImage";
import { CustomerGender, CustomerModel, UserModel } from "../models";

const DataAdminForm = ({
   isEdit = false,
   data = {} as UserModel,
   ...props
}: DataAdminFormProps) => {
   let history = useHistory();

   const [name, setName] = useState<string>("");
   const [email, setEmail] = useState<string>("");
   const [password, setPassword] = useState<string>("");
   const [confirmPassword, setConfirmPassword] = useState<string>("");
   const [validated, setValidated] = useState<boolean>(false);
   const [passwordShown, setPasswordShown] = useState<boolean>(false);
   const [confirmPasswordShown, setConfirmPasswordShown] = useState<boolean>(false);

   const singleAdminState = useSelector(
      (state: RootState) => state.dataAdmin.single ?? ({} as UserModel )
   );

   const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
   };

   const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
   };

   const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
   };

   const onConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
      setConfirmPassword(e.target.value);
   };

   const onCancelHandler = () => {
      history.push("/admin/account/list");
   };

   const togglePasswordVisiblity = () => {
      setPasswordShown((prevState) => !prevState);
   };

   const toggleConfirmPasswordVisiblity = () => {
      setConfirmPasswordShown((prevState) => !prevState);
   };

   useEffect(() => {
      if (checkObjectIsNotEmpty(singleAdminState) && isEdit) {
         setName(singleAdminState.name);
         setEmail(singleAdminState.email);
      } else if (checkEmptyObject(singleAdminState) && isEdit) {
         history.push("/admin/account/list")
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [singleAdminState, isEdit]);

   return (
      <Form
         noValidate
         validated={validated}
         onSubmit={(e) => {
            const form = e.currentTarget;
            if (form.checkValidity() === false) {
               e.preventDefault();
               e.stopPropagation();
            } else {
               e.preventDefault();
               props.onSubmit(name, email, password, confirmPassword);
               // setName("");
               // setEmail("");
               // setPassword("");
               // setConfirmPassword("");
               // history.replace("/admin/account/list");
            }
            setValidated(true);
         }}>
         <Form.Group className="mb-4">
            <Form.Label>Nama</Form.Label>
            <Form.Control type="Nama" value={name} onChange={onNameChange} required />
         </Form.Group>
         <Form.Group className="mb-4">
            <Form.Label>E-Mail</Form.Label>
            <Form.Control type="email" value={email} onChange={onEmailChange} required />
         </Form.Group>

         <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
               placeholder="Password"
               type={passwordShown ? "text" : "password"}
               name="password"
               required
               onChange={onPasswordChange}
            />
            <FontAwesomeIcon
               icon={faEye}
               width="22"
               onClick={togglePasswordVisiblity}
               className="float-right"
               style={{
                  marginTop: "-28px",
                  color: passwordShown ? "#F37334" : "#DFE3E6",
                  marginRight: "10px",
               }}
            />
            <Form.Control.Feedback type="invalid">Masukkan password</Form.Control.Feedback>
         </Form.Group>

         <Form.Group controlId="formBasicPassword">
            <Form.Label>Konfirm Password</Form.Label>
            <Form.Control
               placeholder="Konfirm Password"
               type={confirmPasswordShown ? "text" : "password"}
               name="confirmPassword"
               required
               onChange={onConfirmPasswordChange}
            />
            <FontAwesomeIcon
               icon={faEye}
               width="22"
               onClick={toggleConfirmPasswordVisiblity}
               className="float-right"
               style={{
                  marginTop: "-28px",
                  color: confirmPasswordShown ? "#F37334" : "#DFE3E6",
                  marginRight: "10px",
               }}
            />
            <Form.Control.Feedback type="invalid">
               Masukkan konfirmasi password
            </Form.Control.Feedback>
         </Form.Group>

         <div className="text-center mt-5">
            <button
               className="btn button-outline-primary mr-3"
               type="reset"
               onClick={onCancelHandler}>
               CANCEL
            </button>
            <button className="btn button-primary" type="submit">
               {isEdit ? "UPDATE ADMIN" : "CREATE ADMIN"}
            </button>
         </div>
      </Form>
   );
};

export interface DataAdminFormProps {
   onSubmit: (name: string, email: string, password: string, confirmPassword: string) => void;
   data: UserModel;
   isEdit: boolean;
}

export default DataAdminForm;
