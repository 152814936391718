import React, {Fragment, useEffect, useState} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import DataTable, {TableColumn} from "react-data-table-component";
import NumberFormat from "react-number-format";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import {TableCustomStyles} from "../../../../components/DataTable/TableCustomStyles";
import {
    getStoreCommission, resetStatus,
    updateCancellationFee
} from "../../../projectSetting/projectSetting.reducer";
import {PenaltyCancellationFee, SettingValueEnum} from "../../../projectSetting/models";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {customSavedOption} from "../../../../helpers/alertContent";
import {ApiErrorResponse} from "../../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const CancellationFee = () => {
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();

    const [updatedFee, setUpdatedFee] = useState<PenaltyCancellationFee>({} as PenaltyCancellationFee);

    const storeFee = useSelector(
        (state: RootState) => state.projectSetting.storeFee ?? {} as PenaltyCancellationFee
    );
    const status = useSelector((state: RootState) => state.projectSetting.status);
    const error = useSelector((state: RootState) => state.projectSetting.error ?? {} as ApiErrorResponse<any>);

    const [isInvalidPercentage, setIsInvalidPercentage] = useState<boolean>(false);

    useEffect(() => {
        if (storeFee) {
            setUpdatedFee(storeFee);
        }
    }, [storeFee]);

    useEffect(() => {
        if (updatedFee) {
            const isInvalid = parseInt(updatedFee.value) > 100;
            setIsInvalidPercentage(isInvalid);
        }
    }, [updatedFee]);

    useEffect(() => {
        dispatch(getStoreCommission());
        dispatch(resetStatus());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(customSavedOption(status === ApiResponseStatus.success, error.data?.message, 'Berhasil Mengubah Fee', 'Gagal Mengubah Fee: ' + error.data?.message)).then(() => {
                dispatch(resetStatus());
                dispatch(getStoreCommission());
            });
        }
    }, [status, error]);


    const InputCancellationFee = (data: PenaltyCancellationFee) => {
        const [fee, setFee] = useState<string>(updatedFee.value?.toString() ?? "0");
        return (
            <NumberFormat
                value={fee}
                defaultValue={data.value}
                className="form-control"
                thousandSeparator={"."}
                decimalSeparator={","}
                allowNegative={false}
                suffix={"%"}
                onBlur={() => {
                    let _updatedFee = {
                        id: updatedFee.id,
                        type: updatedFee.type,
                        value: fee ?? "0",
                    } as PenaltyCancellationFee;

                    setUpdatedFee(_updatedFee);
                }}
                onValueChange={(values) => {
                    let val = values.floatValue ?? 0;
                    setFee(val.toString());
                }}
            />
        );
    };

    const onUpdateClicked = (data: PenaltyCancellationFee) => {
        let _value = updatedFee?.value ?? "0";
        dispatch(
            updateCancellationFee({
                id: data.id,
                type: SettingValueEnum.empat,
                value: _value,
            })
        );
    };

    const columns: TableColumn<PenaltyCancellationFee>[] = [
        {
            name: "NO.",
            width: "60px",
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: "Tipe Produk",
            cell: (row: PenaltyCancellationFee) => (
                <Fragment>
               <span>
                  {row.type === SettingValueEnum.empat && "Kelas"}
               </span>
                </Fragment>
            ),
            sortable: true,
        },
        {
            name: "PERSENTASE PEMOTONGAN (%)",
            selector: (row) => row.value,
            sortable: true,
        },
        {
            name: "UBAH BIAYA (%)",
            cell: (row) => InputCancellationFee(row),
            sortable: true,
        },
        {
            name: "AKSI",
            width: "350px",
            cell: (row: PenaltyCancellationFee, index: number) => (
                <>
                    {(updatedFee.value > row.value || updatedFee.value < row.value) && (
                        <OverlayTrigger overlay={isInvalidPercentage ?
                            <Tooltip id="tooltip-disabled">Persentase Tidak boleh melebihi 100%</Tooltip> :
                            <div></div>}>
  <span className="d-inline-block">
    <button className="btn button-outline-primary mr-2"
            onClick={() => onUpdateClicked(row)} disabled={isInvalidPercentage}
            style={isInvalidPercentage ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}>
      UBAH
    </button>
  </span>
                        </OverlayTrigger>
                    )}
                </>
            ),
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={storeFee ? [storeFee] : []}
            pagination
            paginationResetDefaultPage={false}
            noHeader
            customStyles={TableCustomStyles}
        />
    );
};

export default CancellationFee;
