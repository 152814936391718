import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import imageIcon from "../../../../../assets/icons/chat_attachment_image.svg";
import videoIcon from "../../../../../assets/icons/chat_attachment_video.svg";
import fileIcon from "../../../../../assets/icons/chat_attachment_file.svg";

export enum MediaType {
   None,
   Album,
   Picture,
   Video,
   File,
}

const ChooseAttachmentModal = ({ showModal = false, ...props }: ChooseAttachmentModalProps) => {
   const selectedMedia = (media: MediaType) => {
      props.selectedMedia(media);
   };

   return (
      <Modal
         show={showModal}
         onHide={props.onModalHide}
         className="discount_modal"
         size="sm"
         centered>
         <Modal.Body>
            <Row>
               <Col md={12} className={`p-2 request_admin_modal_content`}>
                  <div className={"add_attachment_body"}>
                     {/*<button*/}
                     {/*   className={`m-1 ${styles.add_attachment_button}`}*/}
                     {/*   onClick={() => selectedMedia(MediaType.Album)}>*/}
                     {/*   <img*/}
                     {/*      src="/assets/icons/chat_attachment_album.svg"*/}
                     {/*      alt="attachment"*/}
                     {/*      width={48}*/}
                     {/*   />*/}
                     {/*   <br />*/}
                     {/*   <span className={`${styles.add_attachment_button_label}`}>Album</span>*/}
                     {/*</button>*/}
                     <button
                        className={`m-1 add_attachment_button`}
                        onClick={() => selectedMedia(MediaType.Picture)}>
                        <img
                           src={imageIcon}
                           alt="attachment"
                           width={48}
                        />
                        <br />
                        <span className={`add_attachment_button_label`}>Picture</span>
                     </button>
                     <button
                        className={`m-1 add_attachment_button`}
                        onClick={() => selectedMedia(MediaType.Video)}>
                        <img
                           src={videoIcon}
                           alt="attachment"
                           width={48}
                        />
                        <br />
                        <span className={`add_attachment_button_label`}>Video</span>
                     </button>
                     <button
                        className={`m-1 add_attachment_button`}
                        onClick={() => selectedMedia(MediaType.File)}>
                        <img
                           src={fileIcon}
                           alt="attachment"
                           width={48}
                        />
                        <br />
                        <span className={`add_attachment_button_label`}>File</span>
                     </button>
                  </div>
               </Col>
            </Row>
         </Modal.Body>
      </Modal>
   );
};

export interface ChooseAttachmentModalProps {
   showModal: boolean;
   onModalHide: () => void;
   selectedMedia: (media: MediaType) => void;
}

export default ChooseAttachmentModal;
