import React from "react";
import {BrowserRouter as Router, Redirect, Route, useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faChevronDown} from "@fortawesome/free-solid-svg-icons";

import SideNav, {NavItem, NavIcon, NavText} from "../components/StyledSideNav";

import {Navbar, Nav, Dropdown} from "react-bootstrap";

import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import {useDispatch, useSelector} from "react-redux";
import {logoutUser, setExpanded} from "../../adminLogin/user.reducer";

import Dashboard from "../../dashboard/containers/DashboardPage";
import ProfessionalTypePage from "../../Mitra/Professional/ProfessionalType/containers/ProfessionalTypePage";
import ProfessionalTypeDetailPage
    from "../../Mitra/Professional/ProfessionalType/containers/ProfessionalTypeDetailPage";
import SocialMediaTypePage from "../../master/socialMediaType/containers/SocialMediaTypePage";
import SocialMediaTypeDetailPage from "../../master/socialMediaType/containers/SocialMediaTypeDetailPage";
import LanguagePage from "../../master/language/containers/LanguagePage";
import LanguageDetailPage from "../../master/language/containers/LanguageDetailPage";
import CityPage from "../../master/city/containers/CityPage";
import CityDetailPage from "../../master/city/containers/CityDetailPage";
import requiredAuth from "../../requiredAuth/requiredAuth";
import TagExpertiseTypePage from "../../master/tagExpertise/containers/TagExpertiseTypePage";
import TagExpertiseTypeDetailPage from "../../master/tagExpertise/containers/TagExpertiseTypeDetailPage";
import DataMitraPage from "../../dataMitra/dataMitra/containers/DataMitraPage";
import DataMitraDetailPage from "../../dataMitra/dataMitra/containers/DataMitraDetailPage";
import ProductDetail from "../../dataMitra/product/containers/ProductDetail";
import PackageDetail from "../../dataMitra/product/containers/PackageDetail";
import BannerPage from "../../customer/banner/containers/BannerPage";
import BannerDetailPage from "../../customer/banner/containers/BannerDetailPage";
import BlogDetailPage from "../../dataMitra/blog/containers/BlogDetailPage";
import ExpertiseRequestPage from "../../dataMitra/expertiseRequest/containers/ExpertiseRequestPage";
import RevisionOptionPage from "../../master/revisionOption/containers/RevisionOptionPage";
import RevisionOptionDetailPage from "../../master/revisionOption/containers/RevisionOptionDetailPage";
import AdminBlogDetailPage from "../../master/blog/containers/BlogDetailPage";
import BlogPage from "../../master/blog/containers/BlogPage";
import BlogCategoryPage from "../../master/blogCategory/containers/BlogCategoryPage";
import BlogCategoryDetailPage from "../../master/blogCategory/containers/BlogCategoryDetailPage";
import BlogPreviewPage from "../../master/blog/containers/BlogPreviewPage";
import IssueCategoryPage from "../../master/issueCategory/containers/IssueCategoryPage";
import IssueCategoryDetailPage from "../../master/issueCategory/containers/IssueCategoryDetailPage";
import ReportIssuePage from "../../reportIssue/containers/ReportIssuePage";
import ReportIssueDetailPage from "../../reportIssue/containers/ReportIssueDetailPage";
import VendorTypePage from "../../Mitra/Vendor/VendorType/containers/VendorTypePage";
import VendorTypeDetailPage from "../../Mitra/Vendor/VendorType/containers/VendorTypeDetailPage";
import DiscountPage from "../../master/discount/containers/DiscountPage";
import DiscountDetailPage from "../../master/discount/containers/DiscountDetailPage";
import PaymentMethodPage from "../../master/paymentMethod/containers/PaymentMethodPage";
import PaymentMethodDetailPage from "../../master/paymentMethod/containers/PaymentMethodDetailPage";

import logo_aramata_text from "../../../assets/images/logo_aramata_text.png";
import logo_aramata from "../../../assets/images/logo_aramata.png";

import dashboard_icon from "../../../assets/images/icons/panel/icon_dashboard.svg";

import socialmedia_icon from "../../../assets/images/icons/panel/icon_social_media_list.svg";
import language_icon from "../../../assets/images/icons/panel/icon_language_list.svg";
import city_icon from "../../../assets/images/icons/panel/icon_city_list.svg";
import expertise_icon from "../../../assets/images/icons/panel/icon_expertise_list.svg";
import professional_icon from "../../../assets/images/icons/panel/icon_professional_type.svg";
import revision_option_icon from "../../../assets/images/icons/panel/icon_revision_option.svg";
import expertise_request_icon from "../../../assets/images/icons/panel/icon_expertise_request.svg";
import blog_category_icon from "../../../assets/images/icons/panel/icon_blog_category.svg";
import issue_category_icon from "../../../assets/images/icons/panel/icon_category_issue.svg";
import report_issue_icon from "../../../assets/images/icons/panel/icon_issue.svg";

import data_akun_icon from "../../../assets/images/icons/panel/icon_data_akun_mitra.svg";
import customer_icon from "../../../assets/images/icons/panel/icon_customer_banner.svg";
import bell_icon from "../../../assets/images/icons/bell-icon.svg";
import message_icon from "../../../assets/images/icons/message-icon.svg";
import blog_icon from "../../../assets/images/icons/panel/icon_blog.svg";
import dicount_icon from "../../../assets/images/icons/panel/icon_discount.svg";
import payment_method_icon from "../../../assets/images/icons/panel/icon_payment_method.png";
import RequestAdminPage from "../../requestAdmin/containers/RequestAdminPage";
import ChatPage from "../../chat/containers/ChatPage";
import ChatDetailPage from "../../chat/containers/ChatDetailPage";
import WithdrawPage from "../../withdraw/containers/WithdrawPage";
import RequestCancelProjectPage from "../../RequestCancelProject/containers/RequestCancelProjectPage";
import ProjectSettingPage from "../../projectSetting/containers/ProjectSettingPage";
import {RootState} from "../../../app/store";
import LoginBannerPage from "../../master/loginBanner/containers/LoginBannerPage";
import LoginBannerDetailPage from "../../master/loginBanner/containers/LoginBannerDetailPage";
import DataCustomerPage from "../../customer/dataCustomer/containers/DataCustomerPage";
import DataCustomerDetailPage from "../../customer/dataCustomer/containers/DataCustomerDetailPage";
import DataAdminPage from "../../dataAdmin/containers/DataAdminPage";
import DataAdminDetailPage from "../../dataAdmin/containers/DataAdminDetailPage";
import CreateOfficialPartnerPage from "../../dataMitra/dataMitra/containers/CreateOfficialPartnerPage";
import AdminBalancePage from "../../adminBalance/containers/AdminBalancePage";
import MitraCancelProjectPage from "../../MitraCancelProject/containers/MitraCancelProjectPage";
import UserBalancePage from "../../userBalance/containers/UserBalancePage";
import WalletPage from "../../wallet/containers/WalletPage";
import SendWalletPage from "../../wallet/containers/SendWalletPage";
import RequestDeleteAccountPage from "../../RequestDeleteAccount/containers/RequestDeleteAccountPage";
import DeleteMitraServicePage from "../../deleteMitraService/containers/DeleteMitraServicePage";
import DeleteMitraServiceDetailPage from "../../deleteMitraService/containers/DeleteMitraServiceDetailPage";
import blankProfile from "../../../assets/images/blank_profile.jpg";
import DataProjectPage from "../../dataProject/containers/DataProjectPage";
import DataProjectDetailPage from "../../dataProject/containers/DataProjectDetailPage";
import ProjectChatPage from "../../chat/containers/ProjectChatPage";
import EventPage from "../../marketingEvent/containers/EventPage";
import EventDetailPage from "../../marketingEvent/containers/EventDetailPage";
import MitraStatisticPage from "../../marketingEvent/containers/MitraStatisticPage";
import EventStatisticPage from "../../marketingEvent/containers/EventStatisticPage";
import EventPhotoPage from "../../marketingEvent/containers/EventPhotoPage";
import DataProjectResultPage from "../../dataProject/containers/DataProjectResultPage";
import CustomerCancelProjectPage from "../../customerCancelProject/containers/CustomerCancelProjectPage";
import GearOptionPage from "../../master/gearOption/containers/GearOptionPage";
import GearOptionDetailPage from "../../master/gearOption/containers/GearOptionDetailPage";
import MitraTierPage from "../../master/mitraTier/containers/MitraTierPage";
import MitraTierDetailPage from "../../master/mitraTier/containers/MitraTierDetailPage";
import MitraPointTable from "../../mitraPoint/components/MitraPointTable";
import MitraPointPage from "../../mitraPoint/containers/MitraPointPage";
import ReasonOptionPage from "../../master/reasonOption/containers/ReasonOptionPage";
import ReasonOptionDetailPage from "../../master/reasonOption/containers/ReasonOptionDetailPage";
import StoreProductPage from "../../aramataStore/storeProduct/containers/StoreProductPage";
import StoreProductDetailPage from "../../aramataStore/storeProduct/containers/StoreProductDetailPage";
import StoreReportPage from "../../aramataStore/storeReport/containers/StoreReportPage";
import StoreReportDetailPage from "../../aramataStore/storeReport/containers/StoreReportDetailPage";
import ProSquadPortfolioPage from "../../proSquadPortfolio/containers/ProSquadPortfolioPage";
import ProSquadPortfolioDetailPage from "../../proSquadPortfolio/containers/ProSquadPortfolioDetailPage";
import StoreSettingPage from "../../aramataStore/storeReport/containers";

function AdminPanelPage() {
    // const [expanded, setExpanded] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const expanded = useSelector((state: RootState) => state.user.expanded ?? false);
    const username = useSelector((state: RootState) => state.user.username);

    const onToggle = () => (expanded ? dispatch(setExpanded(false)) : dispatch(setExpanded(true)));


    type Props = {
        onClick: (e: any) => void;
    };

    type RefType = number;

    const CustomToggle = React.forwardRef<RefType, Props>(({children, onClick}, ref: any) => (
        <a
            href="/"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}>
            {children}
        </a>
    ));

    const logoutAction = () => {
        dispatch(logoutUser());
        history.push("/login");
    };

    return (
        <Router>
            <Route
                render={({location, history}) => (
                    <React.Fragment>
                        <Navbar expand="lg" style={{background: "#FEFAF6"}}>
                            <button
                                style={expanded ? {marginLeft: "270px"} : {marginLeft: "70px"}}
                                onClick={onToggle}
                                className="topbar-menu">
                                <FontAwesomeIcon icon={faBars}/>
                            </button>

                            <Nav className="ml-auto">
                                {/* <Nav.Link href="#home" className="mr-2">
                  <div>
                    <img src={bell_icon} alt="bell-icon" width="20"></img>
                    <Badge className="notification-badge">4</Badge>
                  </div>
                </Nav.Link>
                <Nav.Link href="#link" className="mr-3">
                  <img src={message_icon} alt="message-icon" width="22"></img>
                  <Badge className="notification-badge">1</Badge>
                </Nav.Link> */}
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        <div className="d-flex mt-1">
                                            <div className="admin-image-frame d-inline-block">
                                                <img
                                                    className="admin-image"
                                                    src={blankProfile}
                                                    alt="profile"></img>
                                            </div>
                                            <div className="pl-3">
                                                <small className="d-block admin-name">
                                                    Admin Aramata <br/>
                                                    <small className="admin-username">{username}</small>
                                                </small>
                                            </div>
                                            <div className="pl-3 pt-1">
                                                <FontAwesomeIcon
                                                    icon={faChevronDown}
                                                    style={{color: "#160E25"}}
                                                />
                                            </div>
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={logoutAction}>Keluar</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Nav.Link href="#link"></Nav.Link>
                            </Nav>
                        </Navbar>
                        <SideNav
                            style={{
                                position: "fixed",
                                overflowX: "hidden",
                                overflowY: "scroll",
                                width: expanded ? "270px" : "70px",
                            }}
                            id="style-4"
                            className="pb-5"
                            onSelect={(selected: string) => {
                                const to = "/" + selected;
                                if (location.pathname !== to) {
                                    history.push(to);
                                }
                            }}
                            onToggle={onToggle}
                            expanded={expanded}>
                            <div className="pt-4 pl-3 pr-3 pb-5 text-center">
                                <img
                                    src={expanded ? logo_aramata_text : logo_aramata}
                                    alt="logo"
                                    width={expanded ? "152" : "32"}></img>
                            </div>
                            <SideNav.Nav defaultSelected="dashboard">
                                <NavItem eventKey="dashboard">
                                    <NavIcon>
                                        <img src={dashboard_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Dashboard</NavText>
                                </NavItem>

                                <NavItem className="pl-4" disabled>
                                    <NavText className="aaa">{expanded ? "Data Master" : " "}</NavText>
                                </NavItem>

                                <NavItem eventKey="master/socialmedia/list">
                                    <NavIcon>
                                        <img src={socialmedia_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText className="nav-text">Sosial Media</NavText>
                                </NavItem>

                                <NavItem eventKey="master/language/list">
                                    <NavIcon>
                                        <img src={language_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Bahasa</NavText>
                                </NavItem>

                                <NavItem eventKey="master/city/list">
                                    <NavIcon>
                                        <img src={city_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Kota</NavText>
                                </NavItem>

                                <NavItem eventKey="master/tag-expertise/list">
                                    <NavIcon>
                                        <img src={expertise_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Expertise</NavText>
                                </NavItem>

                                <NavItem eventKey="mitra/professional/type/list">
                                    <NavIcon>
                                        <img src={professional_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Tipe Profesional</NavText>
                                </NavItem>

                                <NavItem eventKey="mitra/vendor/type/list">
                                    <NavIcon>
                                        <img src={professional_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Tipe Vendor</NavText>
                                </NavItem>

                                <NavItem eventKey="master/tier/list">
                                    <NavIcon>
                                        <img src={revision_option_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Mitra Emblem</NavText>
                                </NavItem>

                                <NavItem eventKey="master/revision-option/list">
                                    <NavIcon>
                                        <img src={revision_option_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Opsi Revisi</NavText>
                                </NavItem>

                                <NavItem eventKey="master/gear-option/list">
                                    <NavIcon>
                                        <img src={revision_option_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Opsi Gear</NavText>
                                </NavItem>

                                <NavItem eventKey="master/reason-option/list">
                                    <NavIcon>
                                        <img src={revision_option_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Opsi Alasan</NavText>
                                </NavItem>

                                <NavItem eventKey="master/blog-category/list">
                                    <NavIcon>
                                        <img src={blog_category_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Kategori Blog</NavText>
                                </NavItem>

                                <NavItem eventKey="master/issue-category/list">
                                    <NavIcon>
                                        <img src={issue_category_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Kategori Isu</NavText>
                                </NavItem>

                                <NavItem eventKey="master/discount/list">
                                    <NavIcon>
                                        <img src={dicount_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Diskon</NavText>
                                </NavItem>

                                <NavItem eventKey="master/payment-method/list">
                                    <NavIcon>
                                        <img src={payment_method_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Metode Pembayaran</NavText>
                                </NavItem>

                                <NavItem className="pl-4" disabled>
                                    <NavText>{expanded ? "Toko Aramata" : " "}</NavText>
                                </NavItem>

                                <NavItem eventKey="store/product/list">
                                    <NavIcon>
                                        <img src={revision_option_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Produk</NavText>
                                </NavItem>
                                <NavItem eventKey="store/report/list">
                                    <NavIcon>
                                        <img src={revision_option_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Penjualan</NavText>
                                </NavItem>

                                <NavItem className="pl-4" disabled>
                                    <NavText>{expanded ? "Data Mitra" : " "}</NavText>
                                </NavItem>

                                <NavItem eventKey="mitra/account/personal-data/list">
                                    <NavIcon>
                                        <img src={data_akun_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Data Akun Mitra</NavText>
                                </NavItem>

                                <NavItem eventKey="mitra/event/list">
                                    <NavIcon>
                                        <img src={customer_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Data Event</NavText>
                                </NavItem>

                                <NavItem eventKey="mitra/portfolio/list">
                                    <NavIcon>
                                        <img src={customer_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Data Portfolio</NavText>
                                </NavItem>

                                {/*<NavItem eventKey="mitra/point">*/}
                                {/*    <NavIcon>*/}
                                {/*        <img src={customer_icon} alt="icon"></img>*/}
                                {/*    </NavIcon>*/}
                                {/*    <NavText>Poin Mitra</NavText>*/}
                                {/*</NavItem>*/}

                                <NavItem className="pl-4" disabled>
                                    <NavText>{expanded ? "Data Konsumen" : " "}</NavText>
                                </NavItem>

                                <NavItem eventKey="customer/account/list">
                                    <NavIcon>
                                        <img src={data_akun_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Data Akun Konsumen</NavText>
                                </NavItem>

                                <NavItem className="pl-4" disabled>
                                    <NavText>{expanded ? "Data Admin" : " "}</NavText>
                                </NavItem>

                                <NavItem eventKey="admin/account/list">
                                    <NavIcon>
                                        <img src={data_akun_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Data Akun Admin</NavText>
                                </NavItem>

                                <NavItem className="pl-4" disabled>
                                    <NavText>{expanded ? "Chat" : " "}</NavText>
                                </NavItem>

                                <NavItem eventKey="master/request-admin/list">
                                    <NavIcon>
                                        <img src={message_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Permintaan Chat Admin</NavText>
                                </NavItem>

                                <NavItem eventKey="chat">
                                    <NavIcon>
                                        <img src={message_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Chat Admin</NavText>
                                </NavItem>

                                <NavItem className="pl-4" disabled>
                                    <NavText>{expanded ? "Pengaturan Admin" : " "}</NavText>
                                </NavItem>

                                <NavItem eventKey="master/blog/list">
                                    <NavIcon>
                                        <img src={blog_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Blog Admin</NavText>
                                </NavItem>

                                <NavItem eventKey="mitra/expertise/request-other/list">
                                    <NavIcon>
                                        <img src={expertise_request_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Permintaan Expertise</NavText>
                                </NavItem>

                                <NavItem eventKey="master/report-issue/list">
                                    <NavIcon>
                                        <img src={report_issue_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Laporan Isu</NavText>
                                </NavItem>

                                <NavItem eventKey="customer/banner/list">
                                    <NavIcon>
                                        <img src={customer_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Banner Beranda</NavText>
                                </NavItem>

                                <NavItem eventKey="master/login-banner/list">
                                    <NavIcon>
                                        <img src={customer_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Banner Login Mitra</NavText>
                                </NavItem>

                                <NavItem className="pl-4" disabled>
                                    <NavText>{expanded ? "Layanan" : " "}</NavText>
                                </NavItem>

                                <NavItem eventKey="service/delete-mitra-service/list">
                                    <NavIcon>
                                        <img src={bell_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Layanan Mitra</NavText>
                                </NavItem>

                                <NavItem eventKey="service/cancel-mitra-service/list">
                                    <NavIcon>
                                        <img src={bell_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Mitra Pembatalan Layanan</NavText>
                                </NavItem>

                                <NavItem eventKey="service/cancel-customer-service/list">
                                    <NavIcon>
                                        <img src={bell_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Customer Pembatalan Lay...</NavText>
                                </NavItem>

                                <NavItem className="pl-4" disabled>
                                    <NavText>{expanded ? "Proyek" : " "}</NavText>
                                </NavItem>

                                <NavItem eventKey="project/list">
                                    <NavIcon>
                                        <img src={customer_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Data Proyek</NavText>
                                </NavItem>

                                <NavItem eventKey="project/project-setting">
                                    <NavIcon>
                                        <img src={customer_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Pengaturan Proyek</NavText>
                                </NavItem>

                                <NavItem eventKey="master/withdraw/list">
                                    <NavIcon>
                                        <img src={bell_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Permintaan Penarikan</NavText>
                                </NavItem>

                                <NavItem eventKey="withdraw/balance">
                                    <NavIcon>
                                        <img src={bell_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Saldo Admin</NavText>
                                </NavItem>

                                <NavItem eventKey="user/balance">
                                    <NavIcon>
                                        <img src={bell_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Saldo Pengguna</NavText>
                                </NavItem>

                                <NavItem eventKey="admin/send-balance">
                                    <NavIcon>
                                        <img src={bell_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Kirim Saldo</NavText>
                                </NavItem>

                                <NavItem eventKey="master/requestCancelProject/list">
                                    <NavIcon>
                                        <img src={bell_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Pembatalan Proyek</NavText>
                                </NavItem>

                                <NavItem eventKey="master/requestDeleteAccount/list">
                                    <NavIcon>
                                        <img src={bell_icon} alt="icon"></img>
                                    </NavIcon>
                                    <NavText>Permintaan Hapus Akun</NavText>
                                </NavItem>
                            </SideNav.Nav>
                        </SideNav>

                        <main className={`${expanded ? "container-expanded" : ""}`}>
                            {/*<Route path="/">*/}
                            {/*   <Redirect to="/dashboard" />*/}
                            {/*</Route>*/}
                            <Route path="/dashboard" component={() => <Dashboard/>}/>
                            <Route
                                path="/master/socialmedia/list"
                                component={() => <SocialMediaTypePage/>}
                            />
                            <Route
                                path="/master/socialmedia/create"
                                component={() => <SocialMediaTypeDetailPage/>}
                            />
                            <Route
                                path="/master/socialmedia/edit/:id"
                                component={() => <SocialMediaTypeDetailPage/>}
                            />
                            <Route
                                path="/master/language/list"
                                component={() => <LanguagePage/>}
                            />
                            <Route
                                path="/master/language/create"
                                component={() => <LanguageDetailPage/>}
                            />
                            <Route
                                path="/master/language/edit/:id"
                                component={() => <LanguageDetailPage/>}
                            />
                            <Route path="/master/city/list" component={() => <CityPage/>}/>
                            <Route
                                path="/master/city/create"
                                component={() => <CityDetailPage/>}
                            />
                            <Route
                                path="/master/city/edit/:id"
                                component={() => <CityDetailPage/>}
                            />
                            <Route
                                path="/master/tag-expertise/list"
                                component={() => <TagExpertiseTypePage/>}
                            />
                            <Route
                                path="/master/tag-expertise/create"
                                component={() => <TagExpertiseTypeDetailPage/>}
                            />
                            <Route
                                path="/master/tag-expertise/edit/:type/:id"
                                component={() => <TagExpertiseTypeDetailPage/>}
                            />
                            <Route
                                path="/master/revision-option/list"
                                component={() => <RevisionOptionPage/>}
                            />
                            <Route
                                path="/master/revision-option/create"
                                component={() => <RevisionOptionDetailPage/>}
                            />
                            <Route
                                path="/master/revision-option/edit/:id"
                                component={() => <RevisionOptionDetailPage/>}
                            />
                            <Route
                                path="/master/gear-option/list"
                                component={() => <GearOptionPage/>}
                            />
                            <Route
                                path="/master/gear-option/create"
                                component={() => <GearOptionDetailPage/>}
                            />
                            <Route
                                path="/master/gear-option/edit/:id"
                                component={() => <GearOptionDetailPage/>}
                            />
                            <Route
                                path="/master/reason-option/list"
                                component={() => <ReasonOptionPage/>}
                            />
                            <Route
                                path="/master/reason-option/create"
                                component={() => <ReasonOptionDetailPage/>}
                            />
                            <Route
                                path="/master/reason-option/edit/:id"
                                component={() => <ReasonOptionDetailPage/>}
                            />
                            <Route path="/master/blog/list" component={() => <BlogPage/>}/>
                            <Route
                                path="/master/blog/create"
                                component={() => <AdminBlogDetailPage/>}
                            />
                            <Route
                                path="/master/blog/edit/:id"
                                component={() => <AdminBlogDetailPage/>}
                            />
                            <Route
                                path="/master/blog/preview/:id"
                                component={() => <BlogPreviewPage/>}
                            />
                            <Route
                                path="/master/blog-category/list"
                                component={() => <BlogCategoryPage/>}
                            />
                            <Route
                                path="/master/issue-category/list"
                                component={() => <IssueCategoryPage/>}
                            />
                            <Route
                                path="/master/issue-category/create"
                                component={() => <IssueCategoryDetailPage/>}
                            />
                            <Route
                                path="/master/issue-category/edit/:id"
                                component={() => <IssueCategoryDetailPage/>}
                            />
                            <Route
                                path="/master/report-issue/list"
                                component={() => <ReportIssuePage/>}
                            />
                            <Route
                                path="/master/request-admin/list"
                                component={() => <RequestAdminPage/>}
                            />
                            <Route
                                path="/master/withdraw/list"
                                component={() => <WithdrawPage/>}
                            />
                            <Route
                                path="/master/requestCancelProject/list"
                                component={() => <RequestCancelProjectPage/>}
                            />
                            <Route
                                path="/master/mitraCancelProject/list"
                                component={() => <MitraCancelProjectPage/>}
                            />
                            <Route
                                path="/master/requestDeleteAccount/list"
                                component={() => <RequestDeleteAccountPage />}
                            />

                            <Route
                                path="/service/delete-mitra-service/list"
                                component={() => <DeleteMitraServicePage />}
                            />

                            <Route
                                path="/service/delete-mitra-service/detail/:type/:id"
                                component={() => <DeleteMitraServiceDetailPage />}
                            />

                            <Route
                                path="/service/cancel-mitra-service/list"
                                component={() => <MitraCancelProjectPage/>}
                            />

                            <Route
                                path="/service/cancel-customer-service/list"
                                component={() => <CustomerCancelProjectPage/>}
                            />

                            <Route path="/chat" component={() => <ChatPage/>}/>
                            <Route path="/project/chat/:projectId" component={() => <ProjectChatPage />}/>
                            <Route path="/chat/detail" component={() => <ChatDetailPage/>}/>
                            <Route
                                path="/master/report-issue/detail/:id"
                                component={() => <ReportIssueDetailPage/>}
                            />
                            <Route
                                path="/master/blog-category/create"
                                component={() => <BlogCategoryDetailPage/>}
                            />
                            <Route
                                path="/master/blog-category/edit/:id"
                                component={() => <BlogCategoryDetailPage/>}
                            />
                            <Route
                                path="/master/discount/list"
                                component={() => <DiscountPage/>}
                            />
                            <Route
                                path="/master/discount/create"
                                component={() => <DiscountDetailPage/>}
                            />
                            <Route
                                path="/master/discount/edit/:id"
                                component={() => <DiscountDetailPage/>}
                            />
                            <Route
                                path="/master/payment-method/list"
                                component={() => <PaymentMethodPage/>}
                            />
                            <Route
                                path="/master/payment-method/create"
                                component={() => <PaymentMethodDetailPage/>}
                            />
                            <Route
                                path="/master/payment-method/edit/:id"
                                component={() => <PaymentMethodDetailPage/>}
                            />
                            <Route
                                path="/mitra/professional/type/list"
                                component={() => <ProfessionalTypePage/>}
                            />
                            <Route
                                path="/mitra/professional/type/create"
                                component={() => <ProfessionalTypeDetailPage/>}
                            />
                            <Route
                                path="/mitra/professional/type/edit/:id"
                                component={() => <ProfessionalTypeDetailPage/>}
                            />
                            <Route
                                path="/mitra/vendor/type/list"
                                component={() => <VendorTypePage/>}
                            />
                            <Route
                                path="/mitra/vendor/type/create"
                                component={() => <VendorTypeDetailPage/>}
                            />
                            <Route
                                path="/mitra/vendor/type/edit/:id"
                                component={() => <VendorTypeDetailPage/>}
                            />
                            <Route
                                path="/master/tier/list"
                                component={() => <MitraTierPage/>}
                            />
                            <Route
                                path="/master/tier/create"
                                component={() => <MitraTierDetailPage/>}
                            />
                            <Route
                                path="/master/tier/edit/:id"
                                component={() => <MitraTierDetailPage/>}
                            />
                            <Route
                                path="/mitra/account/personal-data/list"
                                component={() => <DataMitraPage/>}
                            />
                            <Route
                                path="/mitra/account/personal-data/create-official-partner"
                                component={() => <CreateOfficialPartnerPage/>}
                            />
                            <Route
                                path="/mitra/account/personal-data/detail/:id"
                                component={() => <DataMitraDetailPage/>}
                            />
                            <Route
                                path="/mitra/product/detail/:id"
                                component={() => <ProductDetail/>}
                            />
                            <Route
                                path="/mitra/package/detail/:id"
                                component={() => <PackageDetail/>}
                            />
                            <Route
                                path="/mitra/blog/detail/:id"
                                component={() => <BlogDetailPage/>}
                            />
                            <Route
                                path="/mitra/expertise/request-other/list"
                                component={() => <ExpertiseRequestPage/>}
                            />
                            <Route
                                path="/mitra/event/list"
                                component={() => <EventPage />}
                            />
                            <Route
                                path="/mitra/event/create"
                                component={() => <EventDetailPage isEdit={false} />}
                            />
                            <Route
                                path="/mitra/event/detail/:id"
                                component={() => <EventDetailPage isEdit={true} />}
                            />
                            <Route
                                path="/mitra/portfolio/list"
                                component={() => <ProSquadPortfolioPage />}
                            />
                            <Route
                                path="/mitra/portfolio/create"
                                component={() => <ProSquadPortfolioDetailPage isEdit={false} />}
                            />
                            <Route
                                path="/mitra/portfolio/detail/:id"
                                component={() => <ProSquadPortfolioDetailPage isEdit={true} />}
                            />
                            <Route
                                path="/mitra/event/mitra-statistic/:id"
                                component={() => <MitraStatisticPage />}
                            />
                            <Route
                                path="/mitra/event/event-statistic/:id"
                                component={() => <EventStatisticPage />}
                            />
                            <Route
                                path="/mitra/event/photo-list/:id/:userId"
                                component={() => <EventPhotoPage />}
                            />
                            {/*<Route*/}
                            {/*    path="/mitra/point"*/}
                            {/*    component={() => <MitraPointPage />}*/}
                            {/*/>*/}
                            <Route
                                path="/customer/banner/list"
                                component={() => <BannerPage/>}
                            />
                            <Route
                                path="/customer/banner/create"
                                component={() => <BannerDetailPage/>}
                            />
                            <Route
                                path="/customer/banner/detail/:id"
                                component={() => <BannerDetailPage/>}
                            />
                            <Route
                                path="/customer/account/list"
                                component={() => <DataCustomerPage/>}
                            />
                            <Route
                                path="/customer/account/detail/:id"
                                component={() => <DataCustomerDetailPage/>}
                            />
                            <Route
                                path="/master/login-banner/list"
                                component={() => <LoginBannerPage/>}
                            />
                            <Route
                                path="/master/login-banner/create"
                                component={() => <LoginBannerDetailPage/>}
                            />
                            <Route
                                path="/master/login-banner/detail/:id"
                                component={() => <LoginBannerDetailPage/>}
                            />
                            <Route
                                path="/project/list"
                                component={() => <DataProjectPage/>}
                            />
                            <Route
                                path="/project/detail/:id"
                                component={() => <DataProjectDetailPage />}
                            />
                            <Route
                                path="/project/result/:id"
                                component={() => <DataProjectResultPage />}
                            />
                            <Route
                                path="/project/project-setting"
                                component={() => <ProjectSettingPage/>}
                            />
                            <Route
                                path="/admin/account/list"
                                component={() => <DataAdminPage/>}
                            />
                            <Route
                                path="/admin/account/create"
                                component={() => <DataAdminDetailPage/>}
                            />
                            <Route
                                path="/admin/account/edit/:id"
                                component={() => <DataAdminDetailPage/>}
                            />
                            <Route
                                path="/withdraw/balance"
                                component={() => <AdminBalancePage/>}
                            />
                            <Route
                                path="/user/balance"
                                component={() => <UserBalancePage/>}
                            />
                            <Route
                                path="/admin/send-balance"
                                component={() => <WalletPage />}
                            />
                            <Route
                                path="/admin/create-send-balance"
                                component={() => <SendWalletPage />}
                            />
                            <Route
                                path="/store/product/list"
                                component={() => <StoreProductPage/>}
                            />
                            <Route
                                path="/store/product/create"
                                component={() => <StoreProductDetailPage/>}
                            />
                            <Route
                                path="/store/product/edit/:id"
                                component={() => <StoreProductDetailPage/>}
                            />
                            <Route
                                path="/store/report/list"
                                component={() => <StoreSettingPage />}
                            />
                            <Route
                                path="/store/report/detail/:id"
                                component={() => <StoreReportDetailPage />}
                            />
                            {/* <Route path="/">
                <Redirect to="/dashboard" />
              </Route> */}
                        </main>
                    </React.Fragment>
                )}
            />
        </Router>
    );
}

export default requiredAuth(AdminPanelPage);
