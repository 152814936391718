import axios from "axios";
import { RequestExpertiseModel } from "./models";
import { endpoint } from "../../../constants";

export default class requestExpertiseApi {
  static getRequestExpertiseList() {
    return axios
      .get<any>(endpoint + "/api/Expertise/GetOtherList")
      .then((res) => res.data.data);
  }

  static deleteRequestExpertise(id: string) {
    return axios
      .post<any>(endpoint + "/api/Expertise/DeleteOther?id=" + id)
      .then((res) => res.data.data);
  }
}
