import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import moment from "moment/moment";
import {DataStatus} from "../../../../../../@core/models/types";
import useChatAttachment from "../../../../../../hooks/chat/useChatAttachment";
import {AttachmentType, MessageType} from "../../../../../../@core/models/chat/models";
import {VideoUploadModel} from "../../../../models";
import UploadVideo from "../../../../../../helpers/Upload/UploadVideo";
import {IoIosClose} from "react-icons/io";

const VideoAttachmentModal = ({
   showModal = false,
   roomId = "",
   userId = "",
   userName = "",
   ...props
}: VideoAttachmentModalProps) => {
   const [selectedVideo, setSelectedVideo] = useState<VideoUploadModel>({} as VideoUploadModel);

   const { sendFileAttachment, isLoading, sendStatus } = useChatAttachment();

   const onVideoChange = (file: VideoUploadModel) => {
      setSelectedVideo(file);
   };

   const onSendClicked = () => {
      const _message = {
         content: "",
         fileType: selectedVideo.type,
         fileName: selectedVideo.fileName,
         createdAt: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSSS[Z]'),
         readById: [],
         senderId: userId,
         senderName: userName,
         messageType: MessageType.video,
      };

      sendFileAttachment(selectedVideo, roomId, _message, AttachmentType.video);
   };

   useEffect(() => {
      if (sendStatus === DataStatus.success) {
         props.onModalHide();
         setSelectedVideo({} as VideoUploadModel);
      }
   }, [sendStatus]);

   return (
      <Modal
         show={showModal}
         backdrop="static"
         keyboard={false}
         size="lg"
         centered
         className="discount_modal">
         <Modal.Body>
            <Row>
               <Col md={12} className={`request_admin_modal_header`}>
                  <h1 className={`d-inline request_admin_modal_title`}>Upload Video</h1>
                  <span
                     className={`material-icons-outlined float-right request_admin_close`}
                     onClick={props.onModalHide}>
                     <IoIosClose size={32} />
                  </span>
               </Col>
               <Col md={12} className={`request_admin_modal_content`}>
                  {isLoading && (
                     <div className={"upload_loading"}>
                        <div className="lds-default">
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                        </div>
                     </div>
                  )}

                  <UploadVideo selectedVideo={selectedVideo} uploadVideoResult={onVideoChange} />

                  <button className="mt-3 btn button-primary float-right" onClick={onSendClicked}>
                     Kirim
                  </button>
               </Col>
            </Row>
         </Modal.Body>
      </Modal>
   );
};

export interface VideoAttachmentModalProps {
   showModal: boolean;
   onModalHide: () => void;
   roomId: string;
   userId: string;
   userName: string;
}

export default VideoAttachmentModal;
