import axios from "axios";
import { VendorTypeModel } from "./models";
import { endpoint } from "../../../../constants";

export default class VendorTypeApi {
  static createVendorType(vendorType: VendorTypeModel) {
    return axios
      .post<any>(endpoint + "/api/VendorType/Create", vendorType)
      .then((res) => res.data.data);
  }

  static getVendorTypeList() {
    return axios
      .get<any>(endpoint + "/api/VendorType/GetList")
      .then((res) => res.data.data);
  }

  static getSingleItem(vendorTypeId: string) {
    return axios
      .get<any>(endpoint + "/api/VendorType/GetSingleItem?id=" + vendorTypeId)
      .then((res) => res.data.data);
  }

  static updateVendorType(vendorType: VendorTypeModel) {
    return axios
      .post<any>(endpoint + "/api/VendorType/Update", vendorType)
      .then((res) => res.data.data);
  }

  static deleteVendorType(vendorType: VendorTypeModel) {
    return axios
      .post<any>(endpoint + "/api/VendorType/Delete", vendorType)
      .then((res) => res.data.data);
  }
}
