import React, { useState, ChangeEvent, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { CityModel } from "../models";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {getIslandList, getProvinceList} from "../city.reducer";
import {RootState} from "../../../../app/store";
import {OptionModel} from "../../../../@core/models/types";
import {checkObjectIsNotEmpty} from "../../../../helpers/checkEmptyObject";

const CityForm = ({
  isEdit = false,
  data = {} as CityModel,
  ...props
}: CityFormProps) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [validated, setValidated] = useState(false);
  const [selectedIsland, setSelectedIsland] = useState<OptionModel>({} as OptionModel);
  const [selectedProvince, setSelectedProvince] = useState<OptionModel>({} as OptionModel);

  const islandOptions = useSelector((state: RootState) => state.city.islandOptions ?? []);
  const provinceOptions = useSelector((state: RootState) => state.city.provinceOptions ?? []);

  useEffect(() => {
    dispatch(getIslandList());
  }, []);

  useEffect(() => {
    if (checkObjectIsNotEmpty(data)) {
      setSelectedIsland({value: data.islandId, label: data.islandName});
      setSelectedProvince({value: data.provinceId, label: data.provinceName});
    }
  }, [data, islandOptions]);

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const onCancelHandler = () => {
    history.push("/master/city/list");
  };

  useEffect(() => {
    setName(data.name);
    setDescription(data.description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onIslandChangeHandler = (data: any) => {
    setSelectedIsland(data);
    dispatch(getProvinceList(data.value));
  };

  const onProvinceChangeHandler = (data: any) => {
    setSelectedProvince(data);
  };

  console.log(selectedIsland);

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={(e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();
        } else {
          e.preventDefault();
          e.preventDefault();
          props.onSubmit(name, description, selectedIsland.value, selectedProvince.value);
          setName("");
          setDescription("");
          // history.replace("/master/city/list");
        }
        setValidated(true);
      }}
    >
      <Form.Group>
        <Form.Label as="legend">
          Nama Pulau
        </Form.Label>
        <Select
            value={selectedIsland}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Ketik untuk mencari nama pulau"
            isSearchable={true}
            name="islandId"
            options={islandOptions}
            onChange={onIslandChangeHandler
         }
        />
      </Form.Group>
      <Form.Group>
        <Form.Label as="legend">
          Nama Provinsi
        </Form.Label>
        <Select
            value={selectedProvince}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Ketik untuk mencari nama provinsi"
            isSearchable={true}
            name="provinceId"
            options={provinceOptions}
            onChange={onProvinceChangeHandler
            }
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>Nama Kota</Form.Label>
        <Form.Control
          type="text"
          placeholder="Masukkan Nama Kota"
          onChange={onNameChange}
          value={name}
          required
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>Deskripsi</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          onChange={onDescriptionChange}
          placeholder="Isi detail kota layanan ( contoh : Kota Bandung Barat)"
          value={description}
          required
        />
      </Form.Group>
      <div className="text-center mt-5">
        <button
          className="btn button-outline-primary mr-3"
          type="reset"
          onClick={onCancelHandler}
        >
          BATALKAN
        </button>
        <button className="btn button-primary" type="submit">
          {isEdit ? "EDIT KOTA" : "TAMBAH KOTA"}
        </button>
      </div>
    </Form>
  );
};

export interface CityFormProps {
  onSubmit: (name: string, description: string, islandId: string, provinceId: string) => void;
  data: CityModel;
  isEdit: boolean;
}

export default CityForm;
