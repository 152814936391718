import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { PortfolioModel, UserModel } from "./models";
import PortfolioApi from "./portfolio.api";

export interface CreatePortfolioSlice {
   name?: string;
   list?: PortfolioModel[];
   userList?: UserModel[];
   isLoading?: boolean;
   error?: ApiErrorResponse<any>;
   status?: string;
}

export const getUserList = createAsyncThunk(
   "portfolioState/portfolioUserList",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await PortfolioApi.getUserList();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getPortfolio = createAsyncThunk(
   "portfolioState/portfolioList",
   async (mitraId: string, { rejectWithValue }) => {
      try {
         return await PortfolioApi.getPortfolio(mitraId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const createPortfolioSlice = createSlice({
   name: "createPortfolioState",
   initialState: {} as CreatePortfolioSlice,
   reducers: {
      // setFilter: (state, action) => {
      //   state.list = action.payload;
      // },
      resetPortfolio: (state) => {
         state.list = [];
      },
   },
   extraReducers: (builder) => {
      builder.addCase(getUserList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getUserList.fulfilled, (state, { payload }) => {
         state.userList = payload.data;
         state.isLoading = false;
      });
      builder.addCase(getUserList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(getPortfolio.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getPortfolio.fulfilled, (state, { payload }) => {
         state.list = payload;
         state.isLoading = false;
      });
      builder.addCase(getPortfolio.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
   },
});

export const { resetPortfolio } = createPortfolioSlice.actions;
export default createPortfolioSlice.reducer;
