import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import MitraTierForm from "../components/MitraTierForm";
import {
    createMitraTier,
    getSingleMitraTier,
    resetStatus, updateMitraTier,
} from "../mitraTier.reducer";
import {useHistory, useParams} from "react-router-dom";
import { RootState } from "../../../../app/store";
import {ApiErrorResponse} from "../../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";
import {CreateMitraTierModel, MitraTierModel} from "../models";

const MitraTierDetailPage = () => {
   const MySwal = withReactContent(Swal);
   let history = useHistory();
   const dispatch = useDispatch();
   let { id } = useParams<{ id: string }>();

   const [isEdit, setIsEdit] = useState(false);

   const singleDataState = useSelector(
      (state: RootState) => state.mitraTier.single ?? ({} as MitraTierModel)
   );
    const status = useSelector((state: RootState) => state.mitraTier.status);
    const error = useSelector((state: RootState) => state.mitraTier.error ?? ({} as ApiErrorResponse<any>));

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getSingleMitraTier(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (
            singleDataState &&
            Object.keys(singleDataState).length !== 0 &&
            singleDataState.constructor === Object
        ) {
            setIsEdit(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleDataState]);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === ApiResponseStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(resetStatus());
                if (status === ApiResponseStatus.success) {
                    history.push("/master/tier/list");
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

   const onSubmit = (args: CreateMitraTierModel) => {
      if (isEdit) {
         dispatch(
            updateMitraTier(args)
         );
      } else {
         dispatch(
            createMitraTier(args)
         );
      }
   };

   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <h4 className="card-label">{isEdit ? "Edit" : "Tambah"} - Mitra Emblem</h4>
               <hr />
               <Row>
                  <Col md={8} xl={5}>
                     <MitraTierForm
                        onSubmit={onSubmit}
                        data={singleDataState}
                        isEdit={isEdit}
                     />
                  </Col>
               </Row>
            </Col>
         </Row>
      </Container>
   );
};

export default MitraTierDetailPage;
