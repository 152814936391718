import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../../..";
import FileUploadApi from "../../../media/fileUpload/fileUpload.api";
import { CreateVendorTypeModel, VendorTypeModel } from "./models";
import VendorTypeApi from "./vendorType.api";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";

export interface CreateVendorTypeSlice {
  name?: string;
  list?: VendorTypeModel[];
  single?: VendorTypeModel;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: ApiResponseStatus;
}

export const createVendorType = createAsyncThunk(
  "createVendorTypeState/createVendorType",
  async (vendorType: CreateVendorTypeModel, { rejectWithValue }) => {
    try {
      const response = await FileUploadApi.uploadImage({
        file_name: new Date().toString() + "." + vendorType.image.fileFormat,
        base64url: (vendorType.image.fileValue as string).split(",")[1],
        imagepath: "vendor-type",
        location: "vendor-type",
      });
      if (response.status === 200) {
        let formData = vendorType;
        formData.iconURL = response.data.imagepath;
        return await VendorTypeApi.createVendorType(formData);
      }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getVendorTypeList = createAsyncThunk(
  "vendorTypeState/vendorTypeList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await VendorTypeApi.getVendorTypeList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getVendorTypeSingleItem = createAsyncThunk(
  "vendorTypeState/vendorTypeSingleItem",
  async (vendorTypeId: string, { rejectWithValue }) => {
    try {
      return await VendorTypeApi.getSingleItem(vendorTypeId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateVendorType = createAsyncThunk(
  "createVendorTypeState/updateVendorType",
  async (vendorType: CreateVendorTypeModel, { rejectWithValue }) => {
    try {
      if (vendorType.image.fromApi) {
        return await VendorTypeApi.updateVendorType(vendorType);
      } else {
        const response = await FileUploadApi.uploadImage({
          file_name: new Date().toString() + "." + vendorType.image.fileFormat,
          base64url: (vendorType.image.fileValue as string).split(",")[1],
          imagepath: "vendor-type",
          location: "vendor-type",
        });
        if (response.status === 200) {
          let formData = vendorType;
          formData.iconURL = response.data.imagepath;
          return await VendorTypeApi.updateVendorType(formData);
        }
      }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteVendorType = createAsyncThunk(
  "vendorTypeState/deleteVendorType",
  async (vendorTypeModel: VendorTypeModel, { rejectWithValue }) => {
    try {
      return await VendorTypeApi.deleteVendorType(vendorTypeModel);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const createVendorTypeSlice = createSlice({
  name: "createVendorTypeState",
  initialState: {} as CreateVendorTypeSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as VendorTypeModel;
    },
    resetStatus: (state) => {
      state.status = ApiResponseStatus.pending;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createVendorType.pending, (state) => {
      state.isLoading = true;
      state.status = ApiResponseStatus.pending;
    });
    builder.addCase(createVendorType.fulfilled, (state, { payload }) => {
      state.name = new Date().toISOString();
      state.isLoading = false;
      state.status = ApiResponseStatus.success;
    });
    builder.addCase(createVendorType.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = ApiResponseStatus.failed;
    });
    builder.addCase(getVendorTypeSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getVendorTypeSingleItem.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getVendorTypeSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getVendorTypeList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getVendorTypeList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getVendorTypeList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateVendorType.pending, (state) => {
      state.isLoading = true;
      state.status = ApiResponseStatus.pending;
    });
    builder.addCase(updateVendorType.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.name = new Date().toISOString();
      state.status = ApiResponseStatus.success;
    });
    builder.addCase(updateVendorType.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
      state.status = ApiResponseStatus.failed;
    });
    builder.addCase(deleteVendorType.pending, (state) => {
      state.isLoading = true;
      state.status = ApiResponseStatus.pending;
    });
    builder.addCase(deleteVendorType.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.name = new Date().toISOString();
      state.status = ApiResponseStatus.success;
    });
    builder.addCase(deleteVendorType.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
      state.status = ApiResponseStatus.failed;
    });
  },
});

export const { setFilter, resetSingle, resetStatus } = createVendorTypeSlice.actions;
export default createVendorTypeSlice.reducer;
