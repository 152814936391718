import React, { Fragment, useState } from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { deleteBanner } from "../banner.reducer";
import { BannerModel } from "../models";
import { ElementPropModel } from "../../../../components/DataTable/models";
import DefaultSubHeader from "../../../../components/DataTable/DefaultSubHeader";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { Link } from "react-router-dom";
import {getMediaEndpoint} from "../../../../helpers/imageManipulation";

const BannerTable = () => {
   const dispatch = useDispatch();

   //   Redux States
   const DataBannerState = useSelector((state: RootState) => state.banner.list ?? []);

   // datatable components

   const [filterText, setFilterText] = useState("");
   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

   const filteredItems = DataBannerState.filter(
      (item) =>
         (item.title && item.title.toLowerCase().includes(filterText.toLowerCase())) ||
         (item.subTitle && item.subTitle.toLowerCase().includes(filterText.toLowerCase()))
   );

   const onSetterFilterText = (value: string) => {
      setFilterText(value);
   };

   const elementProps = {
      placeholder: "cari berdasarkan title / sub title",
      buttonName: "tambah banner",
      createUrl: "/customer/banner/create",
      subHeaderLine: true,
   } as ElementPropModel;
   // : TableColumn<>[]
   const columns: TableColumn<BannerModel>[] = [
      {
         name: "NO.",
         width: "60px",
         cell: (_d: any, index: number) => index + 1,
      },
      {
         name: "JUDUL",
         selector: (row) => row.title,
         sortable: true,
      },
      {
         name: "SUB JUDUL",
         selector: (row) => row.subTitle,
         sortable: true,
      },
      {
         name: "URL",
         selector: (row) => row.linkBanner,
         sortable: true,
      },
      {
         name: "GAMBAR",
         cell: (d: { url: string }) => <Image src={getMediaEndpoint(d.url)} width="25%" />,
         sortable: false,
      },
      {
         name: "AKSI",
         width: "400px",
         cell: (d: { id: string }, index: number) => (
            <Fragment>
               <Link
                  to={{
                     pathname: `/customer/banner/detail/${d.id}`,
                  }}>
                  <button className="btn button-primary mr-2">EDIT</button>
               </Link>
               <button
                  className="btn button-danger"
                  onClick={() => {
                     if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
                        onDelete(d.id);
                  }}>
                  HAPUS
               </button>
            </Fragment>
         ),
      },
   ];

   // end of datatable components

   const onDelete = (id: string) => {
      dispatch(
         deleteBanner({
            id: id,
            title: "deleted",
            subTitle: "deleted",
            url: "deleted",
         } as BannerModel)
      );
   };

   return (
      <>
         <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            noHeader
            subHeader
            subHeaderComponent={DefaultSubHeader(
               filterText,
               resetPaginationToggle,
               onSetterFilterText,
               elementProps
            )}
            customStyles={TableCustomStyles}
            persistTableHead
         />
      </>
   );
};

export default BannerTable;
