import axios from "axios";
import {endpoint} from "../../../constants";
import {StoreProductRequest} from "./models";

export default class StoreProductApi {
    static createStoreProduct(args: StoreProductRequest) {
        return axios
            .post<any>(
                endpoint + "/api/StoreProduct/Create",
                args
            )
            .then((res) => res.data.data);
    }

    static getStoreProductList() {
        return axios
            .get<any>(endpoint + "/api/StoreProduct/GetList")
            .then((res) => res.data.data);
    }

    static getSingleItem(id: string) {
        return axios
            .get<any>(
                endpoint +
                "/api/StoreProduct/GetSingleItem", {
                    params: {
                        id: id
                    }
                }
            )
            .then((res) => res.data.data);
    }

    static updateStoreProduct(args: StoreProductRequest) {
        return axios
            .post<any>(endpoint + "/api/StoreProduct/Update", args)
            .then((res) => res.data.data);
    }

    static deleteStoreProduct(args: StoreProductRequest) {
        return axios
            .post<any>(endpoint + "/api/StoreProduct/Delete", args)
            .then((res) => [
                {response: res.data.data},
                {id: args.id},
            ]);
    }
}
