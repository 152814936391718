import React, {useEffect, useState} from 'react';
import {Row, Container, Col} from "react-bootstrap";
import {BsArrowRightCircle} from "react-icons/bs";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import EventTable from "../components/event/EventTable";
import MitraStatisticTable from "../components/mitraStatistic/MitraStatisticTable";
import PhotoList from "../components/photoList/PhotoList";
import {getSingleEventItem} from "../marketingEvent.reducer";
import {useParams} from "react-router-dom";

const MitraStatisticPage = () => {
    const dispatch = useDispatch();

    let { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getSingleEventItem(id));
        }
    }, [id]);

    return (
        <Container className="content-body" fluid>
            <Row className={"my-3"}>
                <Col md={12}>
                    <PhotoList />
                </Col>
            </Row>
        </Container>
    );
};

export default MitraStatisticPage;
