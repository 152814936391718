import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import VendorTypeForm from "../components/VendorTypeForm";
import { createVendorType, getVendorTypeSingleItem, updateVendorType, resetStatus } from "../vendorType.reducer";
import { CreateVendorTypeModel, VendorTypeModel } from "../models";
import {useHistory, useParams} from "react-router-dom";
import { RootState } from "../../../../../app/store";
import { ImageUpload } from "../../../../../@core/models/media/ImageUpload";
import {ApiErrorResponse} from "../../../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiResponseStatus} from "../../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../../helpers/alertContent";

const VendorTypeDetailPage = () => {
   const MySwal = withReactContent(Swal);
   const dispatch = useDispatch();
   let { id } = useParams<{ id: string }>();
   let history = useHistory();

   const [isEdit, setIsEdit] = useState(false);

   const singleDataState = useSelector(
      (state: RootState) => state.vendorType.single ?? ({} as VendorTypeModel)
   );
    const status = useSelector((state: RootState) => state.vendorType.status);
    const error = useSelector((state: RootState) => state.vendorType.error ?? ({} as ApiErrorResponse<any>));

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getVendorTypeSingleItem(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
      if (
         singleDataState &&
         Object.keys(singleDataState).length !== 0 &&
         singleDataState.constructor === Object
      ) {
         setIsEdit(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [singleDataState]);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === ApiResponseStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(resetStatus());
                if (status === ApiResponseStatus.success) {
                    history.push("/mitra/vendor/type/list");
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onSubmit = (
      name: string,
      description: string,
      image: ImageUpload,
      iconURL: string,
      canUploadFile: boolean
   ) => {
      if (isEdit) {
         dispatch(
            updateVendorType({
               id: id,
               name,
               description,
               image,
               iconURL: iconURL,
               canUploadFile,
            } as CreateVendorTypeModel)
         );
      } else {
         dispatch(
            createVendorType({
               name,
               description,
               image,
               iconURL: "",
               canUploadFile,
            } as CreateVendorTypeModel)
         );
      }
   };

   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <h4 className="card-label">{isEdit ? "Edit" : "Tambah"} - Master Tipe Vendor </h4>
               <hr />
               <Row>
                  <Col md={8} xl={5}>
                     <VendorTypeForm onSubmit={onSubmit} data={singleDataState} isEdit={isEdit} />
                  </Col>
               </Row>
            </Col>
         </Row>
      </Container>
   );
};

export default VendorTypeDetailPage;
