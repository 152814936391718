import axios from "axios";
import { RevisionStatusModel } from "./models";
import { endpoint } from "../../../constants";

export default class RevisionOptionApi {
  static create(data: RevisionStatusModel) {
    return axios
      .post<any>(endpoint + "/api/RevisionStatus/Create", data)
      .then((res) => res.data.data);
  }

  static getList() {
    return axios
      .get<any>(endpoint + "/api/RevisionStatus/GetList")
      .then((res) => res.data.data);
  }

  static getSingleItem(id: string) {
    return axios
      .get<any>(endpoint + "/api/RevisionStatus/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static update(data: RevisionStatusModel) {
    return axios
      .post<any>(endpoint + "/api/RevisionStatus/Update", data)
      .then((res) => res.data.data);
  }

  static delete(data: RevisionStatusModel) {
    return axios
      .post<any>(endpoint + "/api/RevisionStatus/Delete", data)
      .then((res) => res.data.data);
  }
}
