import axios from "axios";
import { endpoint } from "../../../constants";

export default class ExpertiseApi {
  static getProfessionalExpertiseList() {
    return axios
      .get<any>(endpoint + "/api/Expertise/GetListExpertiseTypebyType?type=1")
      .then((res) => res.data.data);
  }

  static getVendorExpertiseList() {
    return axios
      .get<any>(endpoint + "/api/Expertise/GetListExpertiseTypebyType?type=0")
      .then((res) => res.data.data);
  }

  static getSingleItem(mitraId: string) {
    return axios
      .get<any>(endpoint + "/api/Expertise/GetSingleItem?id=" + mitraId)
      .then((res) => res.data.data);
  }

  static getUserList() {
    return axios
      .get<any>(endpoint + "/api/User/GetAll")
      .then((res) => res.data.data);
  }

  static getExpertise(mitraId: string) {
    return axios
      .get<any>(
        endpoint + "/api/Expertise/GetListExpertiseByMitra?mitraId=" + mitraId
      )
      .then((res) => res.data.data);
  }
}
