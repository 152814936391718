import React, {useEffect} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {RootState} from "../../../app/store";
import {ApiErrorResponse} from "../../../index";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../helpers/alertContent";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ProSquadPortfolioForm from "../components/ProSquadPortfolioForm";
import {getSinglePortfolio, reset} from "../proSquadPortfolio.reducer";

const ProSquadPortfolioDetailPage = ({isEdit = false}: ProSquadPortfolioDetailPageProps) => {
    const dispatch = useDispatch();
    let { id } = useParams<{ id: string }>();
    const MySwal = withReactContent(Swal);
    let history = useHistory();

    const status = useSelector((state: RootState) => state.proSquadPortfolio.status);
    const error = useSelector((state: RootState) => state.proSquadPortfolio.error ?? ({} as ApiErrorResponse<any>));

    useEffect(() => {
        if (isEdit) {
            dispatch(getSinglePortfolio(id));
        }
    }, [isEdit, id]);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === ApiResponseStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(reset());
                if (status === ApiResponseStatus.success) {
                    history.push("/mitra/portfolio/list");
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    return (
        <Container className="content-body" fluid>
            <Row>
                <Col md={12}>
                    <h4 className="card-label">Data Portfolio</h4>
                    <hr/>
                    <ProSquadPortfolioForm isEdit={isEdit} />
                </Col>
            </Row>
        </Container>
    );
};

export interface ProSquadPortfolioDetailPageProps {
    isEdit?: boolean;
}

export default ProSquadPortfolioDetailPage;
