import React, {Fragment, useEffect, useState} from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import NumberFormat from "react-number-format";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {TableCustomStyles} from "../../../components/DataTable/TableCustomStyles";
import {CustomerCancellationFeeModel, InputCustomerCancellationFee, SettingValueEnum} from "../models";
import {
   getListCustomerCancellationFee, resetStatus, updateCustomerCancellationFee,
} from "../projectSetting.reducer";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../helpers/alertContent";
import {ApiErrorResponse} from "../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const CustomerCancellationFee = () => {
   const dispatch = useDispatch();
   const MySwal = withReactContent(Swal);

    const [updatedFee, setUpdatedFee] = useState<InputCustomerCancellationFee[]>([]);

    const [resetPaginationToggle] = useState(false);
    const customerCancellationFeeList = useSelector(
        (state: RootState) => state.projectSetting.customerCancellationFeeList ?? []
    );
    const [isInvalidPercentage, setIsInvalidPercentage] = useState<Array<boolean>>([]);

   const status = useSelector((state: RootState) => state.projectSetting.updateCustomerCancelStatus);
   const error = useSelector((state: RootState) => state.projectSetting.error ?? ({} as ApiErrorResponse<any>));

   useEffect(() => {
      dispatch(getListCustomerCancellationFee());
      dispatch(resetStatus());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

    useEffect(() => {
        if (customerCancellationFeeList.length !== 0) {
            const _defaultFee = customerCancellationFeeList.map((item) => {
                return {
                    id: item.id,
                    type: item.type,
                    value: item.value.toString(),
                    mitraPercentage: item.mitraPercentage.toString(),
                    adminPercentage: item.adminPercentage.toString(),
                }
            })
            setUpdatedFee(_defaultFee);
        }
    }, [customerCancellationFeeList]);

    useEffect(() => {
        if (updatedFee.length !== 0) {
            const isInvalid = updatedFee.map((item) => {
                return parseInt(item.value) > 100
            });
            setIsInvalidPercentage(isInvalid);
        }
    }, [updatedFee]);

   useEffect(() => {
      if (status !== undefined && status !== ApiResponseStatus.pending) {
         MySwal.fire(savedStatusOption(status === ApiResponseStatus.success, error.data?.message)).then(() => {
            dispatch(resetStatus());
            dispatch(getListCustomerCancellationFee());
         });
      }
   }, [status, error]);

    const checkFeeValue = (id: string) => {
        const _updatedFee = updatedFee.find((item) => item.id === id);
        return parseFloat(_updatedFee?.value ?? "0") ?? 0;
    }

    const checkMitraValue = (id: string) => {
        const _updatedFee = updatedFee.find((item) => item.id === id);
        return parseFloat(_updatedFee?.mitraPercentage ?? "0") ?? 0;
    }

    const checkAdminValue = (id: string) => {
        const _updatedFee = updatedFee.find((item) => item.id === id);
        return parseFloat(_updatedFee?.adminPercentage ?? "0") ?? 0;
    }

    const InputCancellationFee = (data: CustomerCancellationFeeModel) => {
        const [fee, setFee] = useState<string>(checkFeeValue(data.id).toString());
        return (
            <NumberFormat
                value={fee}
                defaultValue={data.value}
                className="form-control"
                thousandSeparator={"."}
                decimalSeparator={","}
                allowNegative={false}
                suffix={"%"}
                onBlur={() => {
                    let _updatedFee = updatedFee.map((item) => {
                        if (item.id === data.id) {
                            return {
                                id: item.id,
                                type: item.type,
                                value: fee ?? "0",
                                mitraPercentage: item.mitraPercentage,
                                adminPercentage: item.adminPercentage,
                            } as InputCustomerCancellationFee;
                        } else {
                            return item;
                        }
                    });

                    setUpdatedFee(_updatedFee);
                }}
                onValueChange={(values) => {
                    let val = values.floatValue ?? 0;
                    setFee(val.toString());
                }}
            />
        );
    };

    const InputMitraFee = (data: CustomerCancellationFeeModel) => {
        const [mitraPercentage, setMitraPercentage] = useState<string>(checkMitraValue(data.id).toString());
        return (
            <NumberFormat
                value={mitraPercentage}
                defaultValue={data.mitraPercentage}
                className="form-control"
                thousandSeparator={"."}
                decimalSeparator={","}
                allowNegative={false}
                suffix={"%"}
                onBlur={() => {
                    let _updatedFee = updatedFee.map((item) => {
                        if (item.id === data.id) {
                            return {
                                id: item.id,
                                type: item.type,
                                value: item.value,
                                mitraPercentage: mitraPercentage ?? "0",
                                adminPercentage: item.adminPercentage,
                            } as InputCustomerCancellationFee;
                        } else {
                            return item;
                        }
                    });

                    setUpdatedFee(_updatedFee);
                }}
                onValueChange={(values) => {
                    let val = values.floatValue ?? 0;
                    setMitraPercentage(val.toString());
                }}
            />
        );
    };

    const InputAdminFee = (data: CustomerCancellationFeeModel) => {
        const [adminPercentage, setAdminPercentage] = useState<string>(checkAdminValue(data.id).toString());
        return (
            <NumberFormat
                value={adminPercentage}
                defaultValue={data.adminPercentage}
                className="form-control"
                thousandSeparator={"."}
                decimalSeparator={","}
                allowNegative={false}
                suffix={"%"}
                onBlur={() => {
                    let _updatedFee = updatedFee.map((item) => {
                        if (item.id === data.id) {
                            return {
                                id: item.id,
                                type: item.type,
                                value: item.value,
                                mitraPercentage: item.mitraPercentage,
                                adminPercentage: adminPercentage ?? "0",
                            } as InputCustomerCancellationFee;
                        } else {
                            return item;
                        }
                    });

                    setUpdatedFee(_updatedFee);
                }}
                onValueChange={(values) => {
                    let val = values.floatValue ?? 0;
                    setAdminPercentage(val.toString());
                }}
            />
        );
    };

    const onUpdateClicked = (data: CustomerCancellationFeeModel) => {
        let _value = checkFeeValue(data.id);
        let _mitraPercentage = checkMitraValue(data.id);
        let _adminPercentage = checkAdminValue(data.id);
        dispatch(
            updateCustomerCancellationFee({
                id: data.id,
                type: data.type,
                value: _value,
                mitraPercentage: _mitraPercentage,
                adminPercentage: _adminPercentage,
            })
        );
    };

    const columns: TableColumn<CustomerCancellationFeeModel>[] = [
        {
            name: "NO.",
            width: "60px",
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: "DURASI PEMBATALAN",
            cell: (row: CustomerCancellationFeeModel) => (
                <Fragment>
               <span>
                  {row.type === SettingValueEnum.nol && "0 Hari"}
                   {row.type === SettingValueEnum.satu && "1 Hari"}
                   {row.type === SettingValueEnum.dua && "2 Hari"}
                   {row.type === SettingValueEnum.tiga && "3 Hari"}
               </span>
                </Fragment>
            ),
            sortable: true,
        },
        {
            name: "PERSENTASE PEMOTONGAN (%)",
            selector: (row) => row.value,
            sortable: true,
        },
        {
            name: "PERSENTASE MITRA (%)",
            selector: (row) => row.mitraPercentage,
            sortable: true,
        },
        {
            name: "PERSENTASE ADMIN (%)",
            selector: (row) => row.adminPercentage,
            sortable: true,
        },
        {
            name: "UBAH BIAYA (%)",
            cell: (row) => InputCancellationFee(row),
            sortable: true,
        },
        {
            name: "UBAH MITRA (%)",
            cell: (row) => InputMitraFee(row),
            sortable: true,
        },
        {
            name: "UBAH ADMIN (%)",
            cell: (row) => InputAdminFee(row),
            sortable: true,
        },
        {
            name: "AKSI",
            width: "350px",
            cell: (row: CustomerCancellationFeeModel, index: number) => (
                <>
                   {/*{(checkFeeValue(row.id) > row.value || checkFeeValue(row.id) < row.value) && (*/}
                   {/*    <OverlayTrigger overlay={isInvalidPercentage[index] ?*/}
                   {/*        <Tooltip id="tooltip-disabled">Persentase Tidak boleh melebihi 100%</Tooltip> :*/}
                   {/*        <div></div>}>*/}
                   {/*             <span className="d-inline-block">*/}
                   {/*                 <button className="btn button-outline-primary mr-2"*/}
                   {/*                   onClick={() => onUpdateClicked(row)} disabled={isInvalidPercentage[index]}*/}
                   {/*                   style={isInvalidPercentage[index] ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}>*/}
                   {/*                   UBAH*/}
                   {/*                 </button>*/}
                   {/*             </span>*/}
                   {/*    </OverlayTrigger>*/}
                   {/*)}*/}

                   <button className="btn button-outline-primary mr-2"
                           onClick={() => onUpdateClicked(row)} disabled={isInvalidPercentage[index]}
                           style={isInvalidPercentage[index] ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}>
                      UBAH
                   </button>
                </>
            ),
        },
    ];

   return (
       //   <Form noValidate validated={validated} onSubmit={onFormSubmit}>
        //      <Form.Group className="mb-4">
        //         <Form.Label>Fee Project 2 Minggu</Form.Label>
        //         <NumberFormat
        //            value={twoWeekPercentage}
        //            className="form-control"
        //            thousandSeparator={"."}
        //            decimalSeparator={","}
        //            suffix={"%"}
        //            onValueChange={(values) => {
        //               setTwoWeekPercentage(values.floatValue ?? 0);
        //            }}
        //         />
        //      </Form.Group>
        //      <Form.Group className="mb-4">
        //         <Form.Label>Fee Project 1 Bulan</Form.Label>
        //         <NumberFormat
        //            value={monthPercentage}
        //            className="form-control"
        //            thousandSeparator={"."}
        //            decimalSeparator={","}
        //            suffix={"%"}
        //            onValueChange={(values) => {
        //               setMonthPercentage(values.floatValue ?? 0);
        //            }}
        //         />
        //      </Form.Group>
        //      <Form.Group className="mb-4">
        //         <Form.Label>Fee Project 2 Bulan</Form.Label>
        //         <NumberFormat
        //            value={twoMonthPercentage}
        //            className="form-control"
        //            thousandSeparator={"."}
        //            decimalSeparator={","}
        //            suffix={"%"}
        //            onValueChange={(values) => {
        //               setTwoMonthPercentage(values.floatValue ?? 0);
        //            }}
        //         />
        //      </Form.Group>
        //   </Form>
        <DataTable
            columns={columns}
            data={customerCancellationFeeList}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
        />
    );
};

export default CustomerCancellationFee;
