import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Badge } from "react-bootstrap";
import { RootState } from "../../../../app/store";
import {
  changeProfessionalSuspendedStatus,
  changeVendorSuspendedStatus,
  getUserList,
  resetStatus,
} from "../dataMitra.reducer";
import {Link, useHistory} from "react-router-dom";
import {RegistrationStatus, UserModel, UserTypeEnum} from "../models";
import DataTable, {TableColumn} from "react-data-table-component";

import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import all_disabled from "../../../../assets/images/icons/all_disabled.svg";
import verified_disabled from "../../../../assets/images/icons/verified_disabled.svg";
import unverified_disabled from "../../../../assets/images/icons/unverified_disabled.svg";

import all_active from "../../../../assets/images/icons/all_active.svg";
import verified_active from "../../../../assets/images/icons/verified_active.svg";
import unverified_active from "../../../../assets/images/icons/unverified_active.svg";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {inActiveAdmin, resetStatus as resetAdminStatus} from "../../../dataAdmin/dataAdmin.reducer";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";
import {ApiErrorResponse} from "../../../../index";
import {BannerModel} from "../../../customer/banner/models";
import {getWalletList} from "../../../wallet/wallet.reducer";
import {UserType} from "../../../wallet/models";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const DataMitraTable = () => {
  const history = useHistory();
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const DataMitraState = useSelector(
    (state: RootState) => state.dataMitra.userList ?? []
  );
  const rows = useSelector(
      (state: RootState) => state.dataMitra.rows
  );

  const dateChange = useSelector(
      (state: RootState) => state.dataMitra.dateChange
  );

  const isLoading = useSelector(
      (state: RootState) => state.dataMitra.isLoading
  );

  const [convertedDataMitra, setConvertedDataMitra] = useState<UserModel[]>([]);

  const suspendStatus = useSelector(
      (state: RootState) => state.dataMitra.status
  );
  const suspendError = useSelector((state: RootState) => state.dataMitra.error ?? ({} as ApiErrorResponse<any>));
  const isActiveStatus = useSelector(
      (state: RootState) => state.dataAdmin.status
  );
  const isActiveError = useSelector((state: RootState) => state.dataAdmin.error ?? ({} as ApiErrorResponse<any>));
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [activeTag, setActiveTag] = useState<RegistrationStatus>(RegistrationStatus.All);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [timer, setTimer] = useState(setTimeout(() => {}, 1000));

  useEffect(() => {
    if (suspendStatus !== undefined && suspendStatus !== ApiResponseStatus.pending) {
      MySwal.fire(
          savedStatusOption(
              suspendStatus === ApiResponseStatus.success,
              suspendError.data?.message
          )
      ).then(() => {
        dispatch(resetStatus());
        if (suspendStatus === ApiResponseStatus.success) {
          dispatch(getUserList({
            userType: UserTypeEnum.Mitra,
            registerStatus: activeTag === RegistrationStatus.All ? undefined : activeTag,
            skip: 0,
            take: rowsPerPage,
          }));
        }
      });
    }
  }, [suspendStatus, suspendError, activeTag, rowsPerPage]);

  useEffect(() => {
    if (isActiveStatus !== undefined && isActiveStatus !== ApiResponseStatus.pending) {
      MySwal.fire(
          savedStatusOption(
              isActiveStatus === ApiResponseStatus.success,
              isActiveError.data?.message
          )
      ).then(() => {
        dispatch(resetAdminStatus());
        if (isActiveStatus === ApiResponseStatus.success) {
          dispatch(getUserList({
            userType: UserTypeEnum.Mitra,
            registerStatus: activeTag === RegistrationStatus.All ? undefined : activeTag,
            skip: 0,
            take: rowsPerPage,
          }));
        }
      });
    }
  }, [isActiveStatus, isActiveError, activeTag, rowsPerPage]);

  useEffect(() => {
    if (DataMitraState.length !== 0) {
      const converted = DataMitraState.map((item) => {
        const date = new Date(item.submitDate);
        const createDate = new Date(item.createdDate);
        return {
          name: item.name,
          isAdminVerified: item.isAdminVerified,
          id: item.id,
          email: item.email,
          username: item.username,
          registerStatus: item.registerStatus,
          isActive: item.isActive,
          isSuspended: item.isSuspended,
          professionalId: item.professionalId,
          vendorId: item.vendorId,
          submitDate:
          item.submitDate === '0001-01-01T00:00:00' ? "-" :
            date.getDate() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getFullYear(),
          createdDate:
              item.createdDate === '0001-01-01T00:00:00' ? "-" :
                  createDate.getDate() +
                  "-" +
                  (createDate.getMonth() + 1) +
                  "-" +
                  createDate.getFullYear(),
        } as UserModel;
      });
      setConvertedDataMitra(converted);
    }
  }, [DataMitraState]);


  const filteredItems = convertedDataMitra.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.isAdminVerified.toString() &&
        item.isAdminVerified
          .toString()
          .toLowerCase()
          .includes(filterText.toLowerCase())) ||
      (item.submitDate &&
        item.submitDate.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.registerStatus &&
        item.registerStatus === (filterText === "Submitted" && 1)) ||
      (item.registerStatus &&
        item.registerStatus === (filterText === "Revision" && 3))
  );

  const onChangeSuspended = (professionalId: string, vendorId: string, isSuspend: boolean, isProfessional: boolean) => {
    MySwal.fire({
      text: `Ubah penangguhan akun ini?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ya',
    }).then((result) => {
      if (result.isConfirmed) {
        if (isProfessional) {
          dispatch(
              changeProfessionalSuspendedStatus({
                id: professionalId,
                isSuspended: !isSuspend,
              })
          );
        } else {
          dispatch(
              changeVendorSuspendedStatus({
                id: vendorId,
                isSuspended: !isSuspend,
              })
          );
        }
      }
    })
  };

  const onChangeStatus = (id: string, isActive: boolean) => {
    MySwal.fire({
      text: `Apakah kamu yakin akan mengubah status akun ini?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ya',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
            inActiveAdmin({
              userId: id,
              isActive: !isActive,
            })
        );
      }
    });
  };

  const onSetterFilterText = (value: string) => {
    let filterValue = value;
    setFilterText(filterValue);
    clearTimeout(timer);
    setTimer(
        setTimeout((value = filterValue) => {
          dispatch(getUserList({
            userType: UserTypeEnum.Mitra,
            registerStatus: activeTag === RegistrationStatus.All ? undefined : activeTag,
            name: value === "" ? undefined : value,
            skip: 0,
            take: rowsPerPage,
          }));
        }, 1000)
    );
  };

  const columns: TableColumn<UserModel>[] = [
    {
      name: "NO.",
      width: "60px",
      cell: (_d: any, index: number) => index + 1,
    },
    {
      name: "NAMA",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "EMAIL",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "USERNAME",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "VERIFIKASI",
      width: "120px",
      cell: (d: { isAdminVerified: { toString: () => any } }) =>
        d.isAdminVerified === true ? (
          <Badge className="custom-badge-success">Verified</Badge>
        ) : (
          <Badge className="custom-badge-danger">Unverified</Badge>
        ),
    },
    {
      name: "STATUS",
      cell: (d: { registerStatus: number }) => (
        <>
          {d.registerStatus === 0 && <span>Not Submitted</span>}
          {d.registerStatus === 1 && <span>Submitted</span>}
          {d.registerStatus === 2 && <span>Verified</span>}
          {d.registerStatus === 3 && <span>Revision</span>}
        </>
      ),
    },
    {
      name: "TANGGAL DAFTAR",
      selector: (row) => row.createdDate,
      sortable: true,
      format: (row: any) => row.createdDate === "0001-01-01T00:00:00" ? "-" : row.createdDate.substr(0, 10),
    },
    {
      name: "TANGGAL SUBMIT",
      selector: (row) => row.submitDate,
      sortable: true,
      format: (row: any) => row.submitDate === "0001-01-01T00:00:00" ? "-" : row.submitDate.substr(0, 10),
    },
    {
      name: "AKSI",
      width: "400px",
      cell: (d: UserModel) => (
          <>
        {/*<Link*/}
        {/*  to={{*/}
        {/*    pathname: `/mitra/account/personal-data/detail/${d.id}`,*/}
        {/*  }}*/}
        {/*>*/}
          <button
            className="btn button-primary"
            onClick={() => {
              localStorage.setItem("__usr_verif", d.isAdminVerified.toString());
              console.log(d);
              history.push(`/mitra/account/personal-data/detail/${d.id}`);
            }}
          >
            DETAIL
          </button>
        {/*</Link>*/}
            <button
                onClick={() => onChangeSuspended(d.professionalId, d.vendorId, d.isSuspended, d.professionalId === null ? false : true)}
                className={`btn mx-2 ${d.isSuspended ? "btn-success" : "button-danger" } `}>
              {d.isSuspended ? "IJINKAN" : "TANGGUHKAN"}
            </button>
            <button
                onClick={() => onChangeStatus(d.id, d.isActive)}
                className={`btn ${d.isActive ? "button-danger" : "btn-success"} `}>
              {d.isActive ? "NON AKTIFKAN" : "AKTIFKAN"}
            </button>
            </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getUserList({
      userType: UserTypeEnum.Mitra,
      registerStatus: activeTag === RegistrationStatus.All ? undefined : activeTag,
      skip: 0,
      take: rowsPerPage,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dateChange !== '') {
      dispatch(getUserList({
        userType: UserTypeEnum.Mitra,
        registerStatus: activeTag === RegistrationStatus.All ? undefined : activeTag,
        skip: 0,
        take: rowsPerPage,
      }));
    }
  }, [dateChange, activeTag, rowsPerPage]);

  const ref = useRef<HTMLButtonElement>(null);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div className="w-100">
        <hr />
        <div className="d-inline w-50">
          <div className="input-field-group">
            <FontAwesomeIcon
              className="input-field-group-icon"
              icon={faSearch}
              // style={{ color: "#160E25" }}
            />
            <input
              id="search"
              type="search"
              placeholder="Filter berdasarkan email"
              className="input-field-group-text"
              value={filterText}
              onChange={(e) => {
                onSetterFilterText(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="d-inline float-right">
          <button
            onClick={() => {
              // setFilterText("");
              setActiveTag(RegistrationStatus.All);
            }}
            className={`btn mr-1 button-filter-tag ${
              activeTag === RegistrationStatus.All ? "active" : ""
            }`}
          >
            <img
              src={activeTag === RegistrationStatus.All ? all_active : all_disabled}
              alt="all"
              className="mr-1"
            />{" "}
            SEMUA
            {/*<Badge className="tag-badge">1</Badge>*/}
          </button>
          <button
            ref={ref}
            onClick={() => {
              // setFilterText("true");
              setActiveTag(RegistrationStatus.Verified);
            }}
            className={`btn mr-1 button-filter-tag ${
              activeTag === RegistrationStatus.Verified ? "active" : ""
            }`}
          >
            <img
              src={
                activeTag === RegistrationStatus.Verified ? verified_active : verified_disabled
              }
              alt="verified"
              className="mr-1"
            />{" "}
            VERIFIED
            {/*<Badge className="tag-badge">1</Badge>*/}
          </button>
          <button
            onClick={() => {
              // setFilterText("false");
              setActiveTag(RegistrationStatus.Pending);
            }}
            className={`btn mr-1 button-filter-tag ${
              activeTag === RegistrationStatus.Pending ? "active" : ""
            }`}
          >
            <img
              src={
                activeTag === RegistrationStatus.Pending
                  ? unverified_active
                  : unverified_disabled
              }
              alt="unverified"
              className="mr-1"
            />{" "}
            UNVERIFIED
            {/*<Badge className="tag-badge">1</Badge>*/}
          </button>
          <button
            onClick={() => {
              // setFilterText("Submitted");
              setActiveTag(RegistrationStatus.Submit);
            }}
            className={`btn mr-1 button-filter-tag ${
              activeTag === RegistrationStatus.Submit ? "active" : ""
            }`}
          >
            <img
              src={
                activeTag === RegistrationStatus.Submit ? verified_active : verified_disabled
              }
              alt="submitted"
              className="mr-1"
            />{" "}
            SUBMITTED
            {/*<Badge className="tag-badge">1</Badge>*/}
          </button>
          <button
            onClick={() => {
              // setFilterText("Revision");
              setActiveTag(RegistrationStatus.Revision);
            }}
            className={`btn mr-1 button-filter-tag ${
              activeTag === RegistrationStatus.Revision ? "active" : ""
            }`}
          >
            <img
              src={
                activeTag === RegistrationStatus.Revision
                  ? unverified_active
                  : unverified_disabled
              }
              alt="revision"
              className="mr-1"
            />{" "}
            REVISION
            {/*<Badge className="tag-badge">1</Badge>*/}
          </button>
        </div>
        <hr />
      </div>
    );
  }, [filterText, resetPaginationToggle, activeTag]);

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setRowsPerPage(newPerPage);
    dispatch(getUserList({
      userType: UserTypeEnum.Mitra,
      registerStatus: activeTag === RegistrationStatus.All ? undefined : activeTag,
      skip: newPerPage * (page - 1),
      take: newPerPage,
    }));
  };

  const handlePageChange = (page: number) => {
    dispatch(getUserList({
      userType: UserTypeEnum.Mitra,
      registerStatus: activeTag === RegistrationStatus.All ? undefined : activeTag,
      skip: rowsPerPage * (page - 1),
      take: rowsPerPage,
    }));
  };

  return (
    <>
      {/*<DataTable*/}
      {/*  columns={columns}*/}
      {/*  data={filteredItems}*/}
      {/*  pagination*/}
      {/*  paginationResetDefaultPage={resetPaginationToggle}*/}
      {/*  noHeader*/}
      {/*  subHeader*/}
      {/*  selectableRows*/}
      {/*  subHeaderComponent={subHeaderComponentMemo}*/}
      {/*  customStyles={TableCustomStyles}*/}
      {/*  progressPending={isLoading}*/}
      {/*/>*/}
      <DataTable
          columns={columns}
          data={DataMitraState}
          pagination
          paginationServer
          paginationTotalRows={rows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          selectableRows
            noHeader
            subHeader
          subHeaderComponent={subHeaderComponentMemo}
          customStyles={TableCustomStyles}
          persistTableHead
          progressPending={isLoading}
          paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
      />
    </>
  );
};

export default DataMitraTable;
