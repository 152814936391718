import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Nav, Tab, Badge } from "react-bootstrap";
import {
   getExpertise,
   getProfessionalExpertiseType,
   getVendorExpertiseType,
} from "../tagExpertise.reducer";
import { ExpertiseModel, TagExpertiseTypeModel } from "../models";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import { ProfessionalModel, VendorModel } from "../../dataMitra/models";
import { MitraType } from "../../dataDiri/models";
import { checkObjectIsNotEmpty } from "../../../../helpers/checkEmptyObject";

const ExpertisePage = () => {
   const dispatch = useDispatch();
   let { id } = useParams<{ id: string }>();

   const [mitraType, setMitraType] = useState<MitraType>(3);

   const professionalState = useSelector(
      (state: RootState) => state.dataDiri.professionalSingle ?? ({} as ProfessionalModel)
   );
   const vendorState = useSelector(
      (state: RootState) => state.dataDiri.vendorSingle ?? ({} as VendorModel)
   );
   const expertiseList = useSelector((state: RootState) => state.expertise.list ?? []);
   const personalTagList = useSelector(
      (state: RootState) => state.expertise.personalTypeList ?? []
   );
   const commercialTagList = useSelector(
      (state: RootState) => state.expertise.commercialTypeList ?? []
   );
   const vendorTagList = useSelector((state: RootState) => state.expertise.vendorTypeList ?? []);

   useEffect(() => {
      if (vendorState.id === "false") {
         setMitraType(MitraType.professional);
      } else if (professionalState.id === "false") {
         setMitraType(MitraType.vendor);
      }
   }, [vendorState, professionalState]);

   useEffect(() => {
      if (mitraType === MitraType.professional) {
         if (checkObjectIsNotEmpty(professionalState)) {
            dispatch(getExpertise(professionalState.id));
            dispatch(getProfessionalExpertiseType());
         }
      } else if (mitraType === MitraType.vendor) {
         if (checkObjectIsNotEmpty(vendorState)) {
            dispatch(getExpertise(vendorState.id));
            dispatch(getVendorExpertiseType());
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [professionalState, vendorState, mitraType]);

   const filterSelectedTag = (id: string) => {
      const arr = expertiseList.filter((item) => item.parentId === id);
      const tagElement = arr.map((item) => {
         return (
            <>
               <Badge pill className="profile-badge mr-2 mb-2">
                  {item.name}{" "}
               </Badge>
            </>
         );
      });
      return tagElement;
   };

   const filterSelectedLabel = (id: string, labelName: string) => {
      const obj = expertiseList.some((item) => item.parentId === id);

      if (obj) {
         return <Form.Label className="mb-3">{labelName}</Form.Label>;
      } else {
         return null;
      }
   };

   return (
      <>
         <Tab.Container id="left-tabs-example">
            <Row>
               {professionalState.id === "false" ? (
                  <>
                     <Col sm={12} className="data_diri_tab_menu pb-3">
                        <h5 className=" data_diri_tab_menu_title">VENDOR EXPERTISE</h5>
                     </Col>
                     <Col sm={12} className="data_diri_tab_menu pb-5">
                        {vendorTagList.map((item) => {
                           return (
                              <>
                                 {filterSelectedLabel(item.id, item.name)}
                                 <div className="mb-3">{filterSelectedTag(item.id)}</div>
                              </>
                           );
                        })}
                     </Col>
                  </>
               ) : (
                  <>
                     <Col sm={4} className="data_diri_tab_menu pb-5">
                        <h5 className="mb-4 data_diri_tab_menu_title">EXPERTISE</h5>
                        <Nav variant="pills" className="flex-column">
                           <Nav.Item className="data_diri_tab_title">
                              <Nav.Link eventKey="first">Personal</Nav.Link>
                           </Nav.Item>
                           <Nav.Item className="data_diri_tab_title">
                              <Nav.Link eventKey="second">Komersial</Nav.Link>
                           </Nav.Item>
                        </Nav>
                     </Col>
                     <Col sm={8} className="data_diri_tab_body pb-3">
                        <Tab.Content>
                           <Tab.Pane eventKey="first">
                              <h6 className="mb-4 data_diri_tab_body_title">PERSONAL</h6>
                              {personalTagList.map((item) => {
                                 return (
                                    <>
                                       {filterSelectedLabel(item.id, item.name)}
                                       <div className="mb-3">{filterSelectedTag(item.id)}</div>
                                    </>
                                 );
                              })}
                           </Tab.Pane>
                           <Tab.Pane eventKey="second">
                              <h6 className="mb-4 data_diri_tab_body_title">KOMERSIAL</h6>
                              {commercialTagList.map((item) => {
                                 return (
                                    <>
                                       {filterSelectedLabel(item.id, item.name)}
                                       <div className="mb-3">{filterSelectedTag(item.id)}</div>
                                    </>
                                 );
                              })}
                           </Tab.Pane>
                        </Tab.Content>
                     </Col>
                  </>
               )}
            </Row>
         </Tab.Container>
      </>
   );
};

export default ExpertisePage;
