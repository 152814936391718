import React, { useState, ChangeEvent, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logUserIn, resetError } from "../user.reducer";
import { LoginCredential } from "../models";
import "./LoginForm.css";
import { RootState } from "../../../app/store";
import { ApiErrorResponse } from "../../..";
import { checkObjectIsNotEmpty } from "../../../helpers/checkEmptyObject";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const LoginForm = () => {
   const [validated, setValidated] = useState(false);
   const [username, setUsername] = useState("");
   const [password, setPassword] = useState("");
   const [errorMessage, setErrorMessage] = useState<string>("");
   const [passwordShown, setPasswordShown] = useState(false);

   const history = useHistory();

   const dispatch = useDispatch();

   const error = useSelector(
      (state: RootState) => state.user.error ?? ({} as ApiErrorResponse<any>)
   );

   const onUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
      setUsername(e.target.value);
   };

   const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
   };

   useEffect(() => {
      if (checkObjectIsNotEmpty(error)) {
         setErrorMessage(error?.data.message ?? "");
      }
   }, [error]);

   useEffect(() => {
      dispatch(resetError());
      setErrorMessage("")
   }, []);

   const handleSubmit = async (event: {
      currentTarget: any;
      preventDefault: () => void;
      stopPropagation: () => void;
   }) => {
      setErrorMessage("");
      dispatch(resetError());
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
      } else {
         event.preventDefault();
         dispatch(
            logUserIn({
               username,
               password,
            } as LoginCredential)
         );
      }
      setValidated(true);
   };

   const onForgotClicked = () => {
      history.push("/forgot-password");
   };

   const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
   };

   return (
      <div className="centered_element">
         <Container>
            <Row className="justify-content-center">
               <Col md={6} className="box_element">
                  <h6 className="text-center mb-3">Login Admin</h6>
                  <Form noValidate validated={validated} onSubmit={handleSubmit}>
                     <Form.Group controlId="formBasicEmail">
                        <Form.Label className="d-inline">Email address </Form.Label>
                        <small className="text-danger float-right">{errorMessage}</small>
                        <Form.Control
                           type="email"
                           name="userEmail"
                           placeholder="Enter email"
                           required
                           onChange={onUsernameChange}
                        />

                        <Form.Control.Feedback type="invalid">
                           Masukkan format email yang benar
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                           placeholder="Password"
                           type={passwordShown ? "text" : "password"}
                           name="password"
                           required
                           onChange={onPasswordChange}
                        />
                        <FontAwesomeIcon
                           icon={faEye}
                           width="22"
                           onClick={togglePasswordVisiblity}
                           className="float-right"
                           style={{
                              marginTop: "-28px",
                              color: passwordShown ? "#F37334" : "#DFE3E6",
                              marginRight: "10px",
                           }}
                        />
                        <Form.Control.Feedback type="invalid">
                           Masukkan password
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Button className="button-primary mr-2" type="submit">
                        Login
                     </Button>
                     <Button className="button-primary" type="button" onClick={onForgotClicked}>
                        Lupa Password?
                     </Button>
                  </Form>
               </Col>
            </Row>
         </Container>
      </div>
   );
};

export default LoginForm;
