import React, { ChangeEvent, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { ApiResponseStatus } from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import { RootState } from "../../../app/store";
import { WithdrawModel, WithdrawSaldoStatusEnum } from "../models";
import { changeWithdrawSaldoStatus, resetStatus } from "../withdraw.reducer";

const WithdrawDetailModal = ({
   show = false,
   singleWithdraw = {} as WithdrawModel,
   ...props
}: WithdrawDetailModalProps) => {
   const dispatch = useDispatch();

   const userId = useSelector((state: RootState) => state.user.id ?? "");

   const username = useSelector((state: RootState) => state.user.username ?? "");
   const status = useSelector((state: RootState) => state.withdraw.status);

   // useEffect(() => {
   //    if (requestId !== "") {
   //       dispatch(getRequestAdminSingleItem(requestId));
   //    }
   // }, [requestId]);

   useEffect(() => {
      if (status === ApiResponseStatus.success) {
         alert("Berhasil Mengirim Permintaan");
         dispatch(resetStatus());
         props.onCloseAction();
      } else if (status === ApiResponseStatus.failed) {
         alert("Gagal Mengirim Permintaan");
         dispatch(resetStatus());
      }
   }, [status]);

   const getDefaultStatus = (status: WithdrawSaldoStatusEnum) => {
      if (status === WithdrawSaldoStatusEnum.request) {
         return "Request";
      } else if (status === WithdrawSaldoStatusEnum.approve) {
         return "Approve";
      } else if (status === WithdrawSaldoStatusEnum.reject) {
         return "Rejected";
      } else {
         return "status";
      }
   };

   const onAcceptClicked = () => {
      if (window.confirm("Apakah kamu yakin untuk menkonfimasi pembayaran ini?"))
         dispatch(
            changeWithdrawSaldoStatus({
               withdrawSaldoId: singleWithdraw.id,
               status: WithdrawSaldoStatusEnum.approve,
            })
         );
   };

   return (
      <Modal
         show={show}
         onHide={props.handleClose}
         size="lg"
         aria-labelledby="contained-modal-title-vcenter"
         centered>
         <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Detail Permintaan Penarikan</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <Form.Group className="mb-4">
               <Form.Label>Nama Pengaju</Form.Label>
               {singleWithdraw.customerName !== "" && (
                  <Form.Control plaintext readOnly value={singleWithdraw.customerName} />
               )}
               {singleWithdraw.vendorName !== "" && (
                  <Form.Control plaintext readOnly value={singleWithdraw.vendorName} />
               )}
               {singleWithdraw.professionalName !== "" && (
                  <Form.Control plaintext readOnly value={singleWithdraw.professionalName} />
               )}
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Nominal</Form.Label>
               <NumberFormat
                  value={singleWithdraw.value}
                  className="form-control"
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"Rp"}
               />
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Nomor Rekening</Form.Label>
               <Form.Control plaintext readOnly value={singleWithdraw.accountBankNo} />
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Nama Pemilik Rekening</Form.Label>
               <Form.Control plaintext readOnly value={singleWithdraw.ownerName} />
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Nama Bank</Form.Label>
               <Form.Control plaintext readOnly value={singleWithdraw.bankName} />
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Tipe Akun</Form.Label>
               {singleWithdraw.customerId !== null && (
                  <Form.Control plaintext readOnly value={"Customer"} />
               )}
               {singleWithdraw.vendorId !== null && (
                  <Form.Control plaintext readOnly value={"Mitra - Vendor"} />
               )}
               {singleWithdraw.professionalId !== null && (
                  <Form.Control plaintext readOnly value={"Mitra - Professional"} />
               )}
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Status</Form.Label>
               {singleWithdraw.status === WithdrawSaldoStatusEnum.request && (
                  <Form.Control plaintext readOnly value={"Request"} />
               )}
               {singleWithdraw.status === WithdrawSaldoStatusEnum.approve && (
                  <Form.Control plaintext readOnly value={"Approved"} />
               )}
               {singleWithdraw.status === WithdrawSaldoStatusEnum.reject && (
                  <Form.Control plaintext readOnly value={"Rejected"} />
               )}
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Tanggal Permintaan</Form.Label>
               <Form.Control plaintext readOnly value={singleWithdraw.createdDateFormatted} />
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Tanggal Persetujuan</Form.Label>
               <Form.Control plaintext readOnly value={singleWithdraw.approveDateFormatted} />
            </Form.Group>
         </Modal.Body>
         <Modal.Footer>
            <button className="btn button-outline-primary" onClick={props.handleClose}>
               TUTUP
            </button>
            {singleWithdraw.status !== WithdrawSaldoStatusEnum.approve && (
               <button className="btn button-primary" onClick={onAcceptClicked}>
                  TERIMA
               </button>
            )}
         </Modal.Footer>
      </Modal>
   );
};

export interface WithdrawDetailModalProps {
   show: boolean;
   singleWithdraw: WithdrawModel;
   handleClose: () => void;
   onCloseAction: () => void;
}

export default WithdrawDetailModal;
