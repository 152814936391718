import axios from "axios";
import {ApprovalStatusEnum, ReplyModel, UpdateApprovalStatusModel} from "./models";
import { endpoint } from "../../constants";

export default class DeleteMitraServiceApi {
   static getPackageList() {
      return axios.get<any>(endpoint + "/api/Package/GetList").then((res) => res.data.data);
   }

    static getPackageByApprovalList(status: ApprovalStatusEnum | undefined) {
        return axios.get<any>(endpoint + "/api/Package/GetListByApprovalStatus", {
            params: {
                status: status,
            },
        }).then((res) => res.data.data);
    }

   static getProductList() {
      return axios.get<any>(endpoint + "/api/Product/GetList").then((res) => res.data.data);
   }

    static getProductByApprovalList(status: ApprovalStatusEnum | undefined) {
        return axios.get<any>(endpoint + "/api/Product/GetListByStatus", {
            params: {
                ApprovalStatus: status,
            },
        }).then((res) => res.data.data);
    }

    static getPackageAttachment(packageId: string) {
        return axios.post<any>(endpoint + `/api/PackageAttachment/GetListByPackage?packageId=${packageId}`).then((res) => res.data.data);
    }

    static getProductAttachment(productId: string) {
        return axios.post<any>(endpoint + `/api/ProductAttachment/GetListByProduct?productId=${productId}`).then((res) => res.data.data);
    }

    static getPackageInclusion(packageId: string) {
        return axios.get<any>(endpoint + `/api/PackageInclusion/GetListByPackage?packageId=${packageId}`).then((res) => res.data.data);
    }

    static getPackageDetail(packageId: string) {
        return axios.get<any>(endpoint + `/api/PackageDetail/GetListByPackage?packageId=${packageId}`).then((res) => res.data.data);
    }

   static getPackageSingle(id: string) {
      return axios
         .get<any>(endpoint + "/api/Package/GetSingleBySlug?slug=" + id)
         .then((res) => res.data.data);
   }

   static getProductSingle(id: string) {
      return axios
          .get<any>(endpoint + "/api/Product/GetSingleBySlug?slug=" + id)
          .then((res) => res.data.data);
   }

   static deletePackage(id: string) {
      return axios
         .post<any>(endpoint + "/api/Package/Delete", null, {
            params: {
                Id: id,
            }
         })
         .then((res) => res.data.data);
   }

   static deleteProduct(id: string) {
      return axios
          .post<any>(endpoint + "/api/Product/Delete", null, {
             params: {
                Id: id,
             }
          })
          .then((res) => res.data.data);
   }

   static replyToEmail(reply: ReplyModel) {
      return axios
         .post<any>(endpoint + "/api/Activity/RemoveLayananReply", reply)
         .then((res) => res.data.data);
   }

    static updatePackageApprovalStatus(args: UpdateApprovalStatusModel) {
        return axios
            .post<any>(endpoint + "/api/Package/UpdateApprovalStatus", args)
            .then((res) => res.data.data);
    }
    static updateProductApprovalStatus(args: UpdateApprovalStatusModel) {
        return axios
            .post<any>(endpoint + "/api/Product/UpdateApprovalStatus", args)
            .then((res) => res.data.data);
    }
}
