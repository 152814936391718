import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import { ApiResponseStatus } from "../../@core/models/apiResponseStatus/apiResponseStatus";
import { MitraCancelProjectModel } from "./models";
import MitraCancelProjectApi from "./mitraCancelProject.api";

export interface MitraCancelProjectSlice {
   list?: MitraCancelProjectModel[];
   isLoading?: boolean;
   dateCreated?: string;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
}

export const getList = createAsyncThunk(
   "mitraCancelProject/getList",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await MitraCancelProjectApi.getListAll();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const mitraCancelProjectSlice = createSlice({
   name: "mitraCancelProjectState",
   initialState: {} as MitraCancelProjectSlice,
   reducers: {
      setFilter: (state, action) => {
         state.list = action.payload;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
      },
      resetError: (state) => {
         state.error = {} as ApiErrorResponse<any>;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(getList.fulfilled, (state, { payload }) => {
         state.list = payload;
      });
      builder.addCase(getList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
      });
   },
});

export const { setFilter, resetStatus, resetError } = mitraCancelProjectSlice.actions;
export default mitraCancelProjectSlice.reducer;
