export enum MessageType {
   text,
   image,
   file,
   album,
   info,
   video,
}

export interface UserModel {
   name: string;
   profileImage: string;
   unread: number;
   userId: string;
}

export interface RoomModel {
   id: string;
   lastMessage: string;
   lastTimeMessage: string;
   roomName: string;
   projectId: string;
   userId: string[];
   users: UserModel[];
   isSingleChat: boolean;
}

export interface MessageModel {
   content: string;
   createdAt: string;
   readById: string[];
   senderId: string;
   senderName: string;
   messageType: MessageType;
}

export type FileUploadModel = {
   file: any;
   type: string;
   localPath: string;
   fileName: string;
};

export type ImageUploadModel = {
   name?: string;
   size?: number;
   fileFormat: string;
   fileValue: string;
};

export type VideoUploadModel = {
   file: any;
   type: string;
   localPath: string;
   fileName: string;
};

export enum AttachmentType {
   video,
   image,
   file,
   album,
}