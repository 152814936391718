import React, { Fragment } from "react";
import Moment from "react-moment";
import {addDefaultProfileImage} from "../../../../../../../helpers/defaultBrokenImage";
const TextMessage = ({
   profileImage = "",
   senderName = "",
   content = "",
   createdAt = "",
}: TextMessageProps) => {
   return (
      <Fragment>
         <div className={`message_list message_in`}>
            <div className={`message_list_item message_in`}>
               <div className={`message_box`}>
                  <div>
                     <div className={`d-block mb-2 mitra_profile`}>
                        <div className={`d-inline-block mr-1 image_frame`}>
                           <img
                              className={`profile_image`}
                              src="https://placeimg.com/640/480/people"
                              onError={addDefaultProfileImage}
                              alt="profile"></img>
                        </div>
                        <span>{senderName}</span>
                     </div>
                  </div>
                  <span className={"message_text"}>{content}</span>
                  <span className={"message_time"}>
                     <Moment format="HH:mm" withTitle>
                        {createdAt}
                     </Moment>
                  </span>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export interface TextMessageProps {
   profileImage: string;
   senderName: string;
   content: string;
   createdAt: string;
}

export default TextMessage;
