import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
// import { useForm } from "react-hook-form";
// import { uploadFile } from "../../../../../lib/uploadFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { DetailAttachmentType } from "../DetailAttachmentModal";
import moment from "moment/moment";
import UploadAlbum from "../../../../../../helpers/Upload/UploadAlbum";
import {AttachmentType, FileUploadModel, MessageType} from "../../../../models";
import useChatAttachment from "../../../../../../hooks/chat/useChatAttachment";
import {DataStatus} from "../../../../../../@core/models/types";
import {endpoint} from "../../../../../../constants";
import {IoIosClose} from "react-icons/io";

enum AlbumModalTypeEnum {
   New,
   Upload,
}

// type Inputs = {
//    albumTitle: string;
// };

const AlbumAttachmentModal = ({
   showModal = false,
   roomId = "",
   userId = "",
   userName = "",
   defaultAlbumName = "",
   isExistedAlbum = false,
   ...props
}: AlbumAttachmentProps) => {
   const [albumModalType, setAlbumModalType] = useState<AlbumModalTypeEnum>(AlbumModalTypeEnum.New);
   const [albumName, setAlbumName] = useState<string>("");

   const {
      sendAlbumFileAttachment,
      loadListAttachmentByAlbum,
      attachmentList,
      resetAttachmentList,
      isLoading,
      sendStatus,
   } = useChatAttachment();

   const [selectedFile, setSelectedFile] = useState<FileUploadModel>({} as FileUploadModel);
   const [tempAlbum, setTempAlbum] = useState<FileUploadModel[]>([]);

   const [hoverId, setHoverId] = useState<string>("");
   const [trashHover, setTrashHover] = useState<boolean>(false);

   useEffect(() => {
      if (isExistedAlbum) {
         setAlbumModalType(AlbumModalTypeEnum.Upload);
      } else {
         resetAttachmentList();
         setAlbumModalType(AlbumModalTypeEnum.New);
         setAlbumName("");
      }
   }, [isExistedAlbum]);

   useEffect(() => {
      if (isExistedAlbum && defaultAlbumName !== "") {
         loadListAttachmentByAlbum(roomId, defaultAlbumName);
         setAlbumName(defaultAlbumName);
      }
   }, [isExistedAlbum, defaultAlbumName]);

   useEffect(() => {
      if (sendStatus === DataStatus.success) {
         setTempAlbum([]);
         setAlbumName("");
         props.onModalHide();
      }
   }, [sendStatus]);

   // react hook form

   const onCreateAlbumClicked = () => {
      if (albumName !== "") {
         setAlbumModalType(AlbumModalTypeEnum.Upload);
      }
   };

   const onSaveAlbumClicked = () => {
      const _message = {
         content: "",
         albumName: albumName,
         totalLastAdded: tempAlbum.length,
         createdAt: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSSS[Z]'),
         readById: [],
         senderId: userId,
         senderName: userName,
         messageType: MessageType.album,
      };

      tempAlbum.map((item, index) => {
         if (index + 1 >= tempAlbum.length) {
            sendAlbumFileAttachment(item, albumName, roomId, _message, AttachmentType.album, true);
         } else {
            sendAlbumFileAttachment(item, albumName, roomId, _message, AttachmentType.album, false);
         }
      });
   };

   const onFileChange = (file: FileUploadModel) => {
      setSelectedFile(file);
      setTempAlbum((prevState) => [...prevState, file]);
   };

   const onAlbumNameChange = (e: { target: { value: React.SetStateAction<string> } }) => {
      setAlbumName(e.target.value);
   };

   const onDeleteClicked = (index: number) => {
      const list = [...tempAlbum];
      list.splice(index, 1);
      setTrashHover(false);
      setTempAlbum(list);
   };

   const getMediaType = (path: string) => {
      let fileFormat = path.substring(path.lastIndexOf(".") + 1, path.length);
      if (fileFormat === "webm" || fileFormat === "mp4") {
         return DetailAttachmentType.Video;
      } else {
         return DetailAttachmentType.Picture;
      }
   };

   const onMediaClicked = (path: string, type: DetailAttachmentType) => {
      if (!trashHover) {
         props.onMediaClicked(true, path, type);
      }
   };

   return (
      <Modal
         show={showModal}
         backdrop="static"
         keyboard={false}
         centered
         size={albumModalType === AlbumModalTypeEnum.Upload ? "lg" : undefined}
         className="discount_modal">
         <Modal.Body>
            {albumModalType === AlbumModalTypeEnum.New && (
               <Row>
                  <Col md={12} className={`request_admin_modal_header`}>
                     <h1 className={`d-inline request_admin_modal_title`}>
                        Buat Album Baru
                     </h1>
                     <span
                        className={`material-icons-outlined float-right request_admin_close`}
                        onClick={props.onModalHide}>
                        <IoIosClose size={32} />
                     </span>
                  </Col>
                  <Col md={12} className={`request_admin_modal_content`}>
                     <div className="mb-5">
                        <Form.Label className="d-block">
                           Album Title{" "}
                           {albumName === "" && (
                              <small className="float-right text-danger">Wajib Diisi</small>
                           )}
                        </Form.Label>
                        <Form.Control
                           type="text"
                           placeholder="Title Album"
                           className="d-inline-block mr-2"
                           value={albumName}
                           onChange={onAlbumNameChange}
                        />
                     </div>
                     <div className="text-center">
                        <button className="btn button-outline-primary mr-2">Batalkan</button>
                        <button className="btn button-primary" onClick={onCreateAlbumClicked}>
                           Buat Album
                        </button>
                     </div>
                  </Col>
               </Row>
            )}
            {albumModalType === AlbumModalTypeEnum.Upload && (
               <Row>
                  <Col md={12} className={`request_admin_modal_header`}>
                     <h1 className={`d-inline request_admin_modal_title`}>{albumName}</h1>
                     <span
                        className={`material-icons-outlined float-right request_admin_close`}
                        onClick={props.onModalHide}>
                        close
                     </span>
                  </Col>
                  <Col md={12} className="justify-content-center">
                     {isLoading && (
                        <div className={"upload_loading"}>
                           <div className="lds-default">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                           </div>
                        </div>
                     )}
                     <div className={`d-flex album_media_modal_content`}>
                        <div className="m-1">
                           <UploadAlbum
                              selectedFile={selectedFile}
                              uploadFileResult={onFileChange}
                           />
                        </div>
                        {tempAlbum.map((item, index) => (
                           <div
                              className={`m-1 album_media`}
                              onClick={() =>
                                 onMediaClicked(
                                    item.localPath,
                                    item.type === "webm" || item.type === "mp4"
                                       ? DetailAttachmentType.Video
                                       : DetailAttachmentType.Picture
                                 )
                              }
                              onMouseOver={() => {
                                 setHoverId(item.localPath);
                              }}
                              onMouseLeave={() => {
                                 setHoverId("");
                              }}
                              style={
                                 item.type === "webm" || item.type === "mp4"
                                    ? {
                                         background: `url(/assets/images/default_video_thumbnail.png)`,
                                      }
                                    : {
                                         background: `url(${item.localPath})`,
                                      }
                              }>
                              <div className="d-block position-relative">
                                 <button
                                    onMouseOver={() => {
                                       setTrashHover(true);
                                    }}
                                    onMouseLeave={() => {
                                       setTrashHover(false);
                                    }}
                                    onClick={() => onDeleteClicked(index)}
                                    className={`album_media_delete_button d-${
                                       hoverId === item.localPath ? "block" : "none"
                                    }`}>
                                    <FontAwesomeIcon
                                       icon={faMinusCircle}
                                       width="20"
                                       style={
                                          trashHover === true
                                             ? { color: "#ffffff" }
                                             : { color: "#f93154" }
                                       }
                                    />
                                 </button>
                              </div>
                           </div>
                        ))}
                        {attachmentList.map((item) => (
                           <div
                              className={`m-1 album_media`}
                              onMouseOver={() => {
                                 setHoverId(item.id);
                              }}
                              onMouseLeave={() => {
                                 setHoverId("");
                              }}
                              onClick={() =>
                                 props.onMediaClicked(
                                    true,
                                    endpoint + item.url,
                                    getMediaType(item.url)
                                 )
                              }
                              style={
                                 getMediaType(item.url) === DetailAttachmentType.Video
                                    ? {
                                         background: `url(/assets/images/default_video_thumbnail.png)`,
                                      }
                                    : {
                                         background: `url(${endpoint + item.url})`,
                                      }
                              }>
                              {/* <div className="d-block position-relative">
                                 <button
                                    onMouseOver={() => {
                                       setTrashHover(true);
                                    }}
                                    onMouseLeave={() => {
                                       setTrashHover(false);
                                    }}
                                    onClick={() => onDeleteClicked(index)}
                                    className={`${styles.album_media_delete_button} d-${
                                       hoverId === item.id ? "block" : "none"
                                    }`}>
                                    <FontAwesomeIcon
                                       icon={faMinusCircle}
                                       width="20"
                                       style={
                                          trashHover === true
                                             ? { color: "#ffffff" }
                                             : { color: "#f93154" }
                                       }
                                    />
                                 </button>
                              </div> */}
                           </div>
                        ))}
                     </div>
                  </Col>
                  <Col md="12">
                     {tempAlbum.length !== 0 && (
                        <div className="mb-3 float-right" style={{ padding: "15px" }}>
                           <button className="btn button-primary" onClick={onSaveAlbumClicked}>
                              {isExistedAlbum ? "Perbarui Album" : "Buat Album"}
                           </button>
                        </div>
                     )}
                  </Col>
               </Row>
            )}
         </Modal.Body>
      </Modal>
   );
};

export interface AlbumAttachmentProps {
   showModal: boolean;
   onModalHide: () => void;
   roomId: string;
   userId: string;
   userName: string;
   defaultAlbumName: string;
   isExistedAlbum: boolean;
   onMediaClicked: (showDetail: boolean, path: string, type: DetailAttachmentType) => void;
}

export default AlbumAttachmentModal;
