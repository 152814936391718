import { ImageUpload } from "../../../@core/models/media/ImageUpload";

export type CreatePaymentMethod = {
   paymentGateway: PaymentGatewayEnum;
   paymentCategory: PaymentMethodEnum;
   adminFee: number;
   feePercentage: number;
   paymentMethod: PaymentMethodCategoryEnum;
   media: string;
   description: string;
};

export type PaymentMethodModel = {
   id: string;
   paymentGateway: PaymentGatewayEnum;
   paymentGatewayName: string;
   paymentCategory: PaymentMethodCategoryEnum;
   paymentCategoryName: string;
   adminFee: number;
   feePercentage: number;
   paymentMethod: PaymentMethodEnum;
   paymentMethodName: string;
   media: string;
   description: string;
   isActive: boolean;
};

export type UpdatePaymentMethodModel = {
   id: string;
   paymentGateway: PaymentGatewayEnum;
   paymentCategory: PaymentMethodEnum;
   adminFee: number;
   feePercentage: number;
   paymentMethod: PaymentMethodCategoryEnum;
   media: string;
   description: string;
   isActive: boolean;
};

export interface PaymentMethodFormModel {
   id: string;
   paymentGateway: PaymentGatewayEnum;
   paymentCategory: PaymentMethodEnum;
   adminFee: number;
   feePercentage: number;
   paymentMethod: PaymentMethodCategoryEnum;
   media: string;
   description: string;
   isActive: boolean;
   image: ImageUpload;
}

export enum PaymentGatewayEnum {
   Midtrans,
   Xendit,
}

export enum PaymentMethodCategoryEnum {
   BankTransfer,
   EWallet,
   RetailOutlet,
   Card,
   QRCode,
   CardlessCredit,
   DirectDebit,
}

export enum PaymentMethodEnum {
   BRI,
   BNI,
   Mandiri,
   Permata,
   BCA,
   Sampoerna,
   Danamon,
   BJB,
   BSI,
   GoPay = 11,
   OVO = 12,
   DANA = 13,
   LinkAja = 14,
   Shopeepay = 15,
   Alfamart = 21,
   Indomaret = 22,
   QRIS = 31,
   AkuLaku = 41,
   Kredivo = 42,
   Visa = 51,
   MasterCard = 52,
   JCB = 53,
}
