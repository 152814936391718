import React from 'react'
import {Col, Container, Row} from "react-bootstrap";
import WalletForm from "../components/WalletForm";

const SendWalletPage = () => {

    return (
        <Container className="content-body" fluid>
            <Row>
                <Col md={12}>
                    <h4 className="card-label">Kirim Saldo</h4>
                    <hr/>
                    <Row>
                        <Col md={8} xl={5}>
                            <WalletForm />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default SendWalletPage