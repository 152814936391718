import React, { ChangeEvent, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ApiResponseStatus } from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import { RootState } from "../../../app/store";
import { RequestAdminModel, RequestAdminStatus } from "../models";
import { addAdminToChatRoom, getRequestAdminSingleItem } from "../requestAdmin.reducer";

const RequestAdminDetailModel = ({
   show = false,
   requestId = "",
   ...props
}: RequestAdminDetailModelProps) => {
   const dispatch = useDispatch();
   const history = useHistory();

   const singleRequestAdmin = useSelector(
      (state: RootState) => state.requestAdmin.single ?? ({} as RequestAdminModel)
   );

   const userId = useSelector((state: RootState) => state.user.id ?? "");

   const username = useSelector((state: RootState) => state.user.username ?? "");
   const status = useSelector((state: RootState) => state.requestAdmin.status);

   useEffect(() => {
      if (requestId !== "") {
         dispatch(getRequestAdminSingleItem(requestId));
      }
   }, [requestId]);

   useEffect(() => {
      if (status === ApiResponseStatus.success) {
         alert("Berhasil Menerima Permintaan");
         props.handleClose();
      } else if (status === ApiResponseStatus.failed) {
         alert("Gagal Menerima Permintaan");
      }
   }, [status]);

   const getDefaultStatus = (status: RequestAdminStatus) => {
      if (status === RequestAdminStatus.Pending) {
         return "Menunggu";
      } else if (status === RequestAdminStatus.Accept) {
         return "Diterima";
      } else if (status === RequestAdminStatus.Reject) {
         return "Ditolak";
      } else {
         return "status";
      }
   };

   const onAcceptClicked = () => {
      dispatch(
         addAdminToChatRoom({
            roomId: singleRequestAdmin.roomId,
            personal: {
               name: username,
               profileImage: "/Media/profile/2021/12//6acf108a-7db8-4044-adc0-8f89a5ab8511.jpg",
               unread: 0,
               userId: userId,
            },
            requestAdmin: singleRequestAdmin,
         })
      );
   };

   const onChatClicked = () => {
      history.push("/chat")
   };

   return (
      <Modal
         show={show}
         onHide={props.handleClose}
         size="lg"
         aria-labelledby="contained-modal-title-vcenter"
         centered>
         <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Detail Permintaan</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <Form.Group className="mb-4">
               <Form.Label>Nama Event</Form.Label>
               <Form.Control plaintext readOnly defaultValue={singleRequestAdmin.projectName} />
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Room ID Chat</Form.Label>
               <Form.Control plaintext readOnly value={singleRequestAdmin.roomId} />
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Status</Form.Label>
               <Form.Control
                  plaintext
                  readOnly
                  value={getDefaultStatus(singleRequestAdmin.status)}
               />
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Alasan</Form.Label>
               <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Masukkan Pesan"
                  value={singleRequestAdmin.reason}
                  required
                  readOnly
               />
            </Form.Group>
         </Modal.Body>
         <Modal.Footer>
            <button className="btn button-outline-primary" onClick={props.handleClose}>
               Tutup
            </button>
            {singleRequestAdmin.status !== RequestAdminStatus.Accept && (
               <button className="btn button-primary" onClick={onAcceptClicked}>
                  Terima
               </button>
            )}
            {singleRequestAdmin.status === RequestAdminStatus.Accept && (
               <button className="btn button-primary" onClick={onChatClicked}>
                  Buka Chat
               </button>
            )}
         </Modal.Footer>
      </Modal>
   );
};

export interface RequestAdminDetailModelProps {
   show: boolean;
   requestId: string;
   handleClose: () => void;
}

export default RequestAdminDetailModel;
