import axios from "axios";
import { endpoint } from "../../constants";
import { ChangeStatusModal, RegisterAdminModel, UpdateAdminModel } from "./models";

export default class DataAdminApi {
   static getSingleCustomer(userId: string) {
      return axios
         .post<any>(endpoint + `/api/Customer/GetSingleItem?userId=${userId}`)
         .then((res) => res.data);
   }
   static registerAdmin(userData: RegisterAdminModel) {
      return axios
         .post<any>(endpoint + `/api/User/RegisterAdmin`, userData)
         .then((res) => res.data);
   }
   static updateAdmin(userData: UpdateAdminModel) {
      return axios.post<any>(endpoint + `/api/User/UpdateAdmin`, userData).then((res) => res.data);
   }
   static inActiveAdmin(data: ChangeStatusModal) {
      return axios.post<any>(endpoint + `/api/User/InActiveAdmin?userId=${data.userId}&isActive=${data.isActive}`).then((res) => res.data);
   }
}
