import React, {useEffect} from "react";
import Viewer from "react-viewer";

export type MediaPathModel = {
    src: string;
    alt: string;
};

const DetailPhotoAttachment = ({
                                   showDetail = false,
                                   mediaPath = "",
                                   multiplePath = [],
                                   isMultipleImage = false,
                                   ...props
                               }: DetailPhotoAttachmentProps) => {
    useEffect(() => {

    }, [multiplePath]);

    return (
        <Viewer
            visible={showDetail}
            zIndex={99999}
            onClose={() => {
                props.toggleShowDetail();
            }}
            images={isMultipleImage ? multiplePath : [
                {
                    src: mediaPath,
                    alt: mediaPath,
                },
            ]}
        />
    );
};

export interface DetailPhotoAttachmentProps {
    showDetail: boolean;
    toggleShowDetail: () => void;
    mediaPath: string;
    multiplePath: MediaPathModel[];
    isMultipleImage: boolean;
}

export default DetailPhotoAttachment;
