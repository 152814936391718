import React, { Fragment } from "react";
import Moment from "react-moment";
import {addDefaultProfileImage} from "../../../../../../../helpers/defaultBrokenImage";
import {Link} from "react-router-dom";
import {endpoint} from "../../../../../../../constants";
import {getIcon} from "../../../../../../../helpers/getIcon";

const FileMessage = ({
   profileImage = "",
   senderName = "",
   content = "",
   createdAt = "",
   fileName = "",
   fileType = "",
   ...props
}: FileMessageProps) => {
   return (
      <Fragment>
         <div className={`message_list message_in`}>
            <div className={`message_list_item message_in`}>
               <div className={`message_box}`}>
                  <div>
                     <div className={`d-block mb-2 mitra_profile`}>
                        <div className={`d-inline-block mr-1 image_frame`}>
                           <img
                              className={`profile_image`}
                              src="https://placeimg.com/640/480/people"
                              onError={addDefaultProfileImage}
                              alt="profile"></img>
                        </div>
                        <span>{senderName}</span>
                     </div>
                  </div>
                  <div className={"message_video"}>
                     <Link to={endpoint + content}>
                        <a download target="_blank">
                           <div className="d-flex">
                              <div>
                                 <img
                                    src={getIcon(fileType)}
                                    onError={addDefaultProfileImage}
                                    alt="file"
                                    width={48}
                                 />
                              </div>
                              <div className={"video_title"}>
                                 <span>{fileName}</span>
                              </div>
                           </div>
                        </a>
                     </Link>
                  </div>
                  <span className={"message_time"}>
                     <Moment format="HH:mm" withTitle>
                        {createdAt}
                     </Moment>
                  </span>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export interface FileMessageProps {
   profileImage: string;
   senderName: string;
   content: string;
   createdAt: string;
   fileName: string;
   fileType: string;
}

export default FileMessage;
