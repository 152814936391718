import React, { useState, ChangeEvent, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { SocialMediaTypeModel } from "../models";

const SocialMediaTypeForm = ({
  isEdit = false,
  data = {} as SocialMediaTypeModel,
  ...props
}: SocialMediaTypeFormProps) => {
  let history = useHistory();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [validated, setValidated] = useState(false);

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const onLinkChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLink(e.target.value);
  };

  const onCancelHandler = () => {
    history.push("/master/socialmedia/list");
  };

  useEffect(() => {
    setName(data.name);
    setDescription(data.description);
    setLink(data.link);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={(e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();
        } else {
          e.preventDefault();
          e.preventDefault();
          props.onSubmit(name, description, link);
          setName("");
          setDescription("");
          setLink("");
          // history.replace("/master/socialmedia/list");
        }
        setValidated(true);
      }}
    >
      <Form.Group className="mb-4">
        <Form.Label>Nama Sosial Media</Form.Label>
        <Form.Control
          type="text"
          placeholder="Masukkan nama sosial media"
          onChange={onNameChange}
          value={name}
          required
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>Tautan Sosial Media</Form.Label>
        <Form.Control
          type="text"
          placeholder="Masukkan Tautan sosial media"
          onChange={onLinkChange}
          value={link}
          required
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>Deskripsi</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          onChange={onDescriptionChange}
          value={description}
          placeholder="Isi dengan sosial media pengguna (contoh : Instagram untuk mitra)"
          required
        />
      </Form.Group>
      <div className="text-center mt-5">
        <button
          className="btn button-outline-primary mr-3"
          type="reset"
          onClick={onCancelHandler}
        >
          BATALKAN
        </button>
        <button className="btn button-primary" type="submit">
          {isEdit ? "EDIT SOSIAL MEDIA" : "TAMBAH SOSIAL MEDIA"}
        </button>
      </div>
    </Form>
  );
};

export interface SocialMediaTypeFormProps {
  onSubmit: (name: string, description: string, link: string) => void;
  data: SocialMediaTypeModel;
  isEdit: boolean;
}

export default SocialMediaTypeForm;
