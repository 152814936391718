import React, {useEffect, useState} from 'react';
import {Row, Container, Col} from "react-bootstrap";
import {BsArrowRightCircle} from "react-icons/bs";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import ProSquadPortfolioTable from "../components/ProSquadPortfolioTable";

const ProSquadPortfolioPage = () => {
    return (
        <Container className="content-body" fluid>
            <Row className={"my-3"}>
                <Col md={12} className="mb-3">
                    <h4 className="card-label d-inline">Data Portfolio</h4>
                </Col>
                <Col md={12}>
                    <ProSquadPortfolioTable />
                </Col>
            </Row>
        </Container>
    );
};

export default ProSquadPortfolioPage;
