import axios from "axios";
import { endpoint } from "../../../constants";
import { UploadImageModel } from "./models";

export default class FileUploadApi {
  static uploadImage(imageData: UploadImageModel) {
    return axios
      .post<any>(endpoint + "/web/api/Upload/UploadImage", imageData)
      .then((res) => res);
  }
}
