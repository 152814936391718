import axios, {AxiosRequestConfig} from "axios";
import {UploadResponse} from "./models";
import {endpoint} from "../../../constants";
import {ApiResponse} from "../../../index";

export default class FileAttachmentApi {
    static uploadFile(form: FormData, config: AxiosRequestConfig) {
        return axios
            .post<ApiResponse<UploadResponse[]>>(`${endpoint}/web/api/Upload/UploadFileNew`, form, config)
            .then((res) => res.data.data);
    }
}