import {BaseTypeEnum} from "../deleteMitraService/models";

export interface ProjectModel {
    id: string;
    customerId: string;
    expertiseTypeId: string;
    expertiseTypeName: string;
    name: string;
    status: number;
    activityDate: string;
    createdDate: string;
    customerName: string | null;
    customerPicture: string | null;
    description: string;
    totalActivity: number;
    startActivityDate: string | null;
    endActivityDate: string | null;
    completeDate: string;
    roomId: string;
    paymentStatus: number;
    location: string;
    note: string;
    placeId: string;
    latitude: string;
    longitude: string;
    isExpired: boolean;
    isTodayExpired: boolean;
    isPortofolioDone: boolean;
    isRejected: boolean;
    cityId: string;
    cityName: string | null;
}

export interface ProjectDetailModel {
    id: string;
    customerId: string;
    customerName: string;
    customerPicture: string;
    expertiseTypeId: string;
    expertiseTypeName: string;
    name: string;
    isExpired: boolean;
    isTodayExpired: boolean;
    isRejected: boolean;
    status: ProjectStatusEnum;
    activityDate: string;
    description: string;
    totalActivity: number;
    startActivityDate: string | null;
    endActivityDate: string | null;
    completeDate: string;
    roomId: string;
    paymentStatus: ActivityStatusEnum;
    location: string;
    note: string;
    placeId: string;
    latitude: string;
    longitude: string;
    cityId: string;
    cityName: string;
    // temporary local types
    isSomeRejected?: boolean;
}

export enum ProjectStatusEnum {
    Prepare,
    Active,
    Complete,
    Cancel,
    None,
}

export enum ActivityStatusEnum {
    waitingpayment,
    confirmpayment,
    waitingforactivity,
    onprogress,
    waitingresult,
    confirmedresult,
    done,
}

export interface ActivityModel {
    id: string;
    name: string;
    activityDate: string;
    location: string;
    placeId: string;
    startActivity: string;
    endActivity: string;
    note: string;
    latitude: string;
    longitude: string;
    cityId: string;
    projectId: string;
    activityDetailList: ActivityDetailModel[];
    canAccess: boolean;
    isExpired: boolean;
}

export interface ActivityDetailModel {
    id: string;
    activityId: string;
    packageProductId: string;
    status: ActivityDetailRequestStatus;
    type: number;
    qty: number;
    itemDetail: {
        id: string;
        name: string;
        picture: string;
        lokasi: string;
        mitraId: string;
        mitraName: string;
        price: number;
        startValue: string;
        endValue: string;
        profile: string;
        expertiseName: string;
    };
    isCheckedOut: boolean;
    isPaid: boolean;
    expiredApproval: string;
    expiredPayment: string;
}

export enum ActivityDetailRequestStatus {
    Pending,
    Accept,
    Reject,
    Completed,
    Cancel,
    All,
}

export interface GetProjectRequest {
    name: string | undefined;
    status: ProjectStatusEnum | undefined;
    skip: number;
    take: number;
}

export interface TotalProject {
    totalPrepare: number;
    totalActive: number;
    totalComplete: number;
    totalCancel: number;
    totalBiayaPrepare: number;
    totalBiayaActive: number;
    totalBiayaComplete: number;
    totalBiayaCancel: number;
    totalKomisiPrepare: number;
    totalKomisiActive: number;
    totalKomisiComplete: number;
    totalKomisiCancel: number;
}

export interface AdminProjectModel {
    id: string;
    name: string;
    customerName: string;
    activityDate: string;
    mitraName: string;
    totalBiaya: number;
    komisi: number;
    status: ProjectStatusEnum;
}

export interface SingleDetailActivityModel {
    id: string;
    activityId: string;
    packageProductId: string;
    status: ActivityDetailRequestStatus;
    type: number;
    qty: number;
    itemDetail: null;
    isCheckedOut: boolean;
    isPaid: boolean;
    baseType: BaseTypeEnum;
    travelFee: number;
}

export interface ResultProjectModel {
    id: string;
    projectId: string;
    activityDetailId: string;
    mitraId: string;
    customerId: string;
    mitraName: string;
    customerName: string;
    customerPicture: string;
    projectName: string;
    projectDescription: string;
    expertiseId: string;
    mitraPicture: string;
    activityDate: string;
    startActivity: string;
    endActivity: string;
    location: string;
    packageProductName: string;
    image: string;
    endValue: number;
    isPortofolioDone: boolean;
    canUploadFile: boolean;
}

export interface FileResultModel {
    id?: string;
    resultId: string;
    url: string;
    isPick: boolean;
    isFinal: boolean;
    isExternalLink: boolean;
}

export enum DetailAttachmentType {
    Picture,
    Video,
}

export interface ResultReview {
    id: string;
    resultId: string;
    userId: string;
    score: number;
    comment: string;
    createdDate: string;
}

export interface CommentResultModel {
    id?: string;
    resultId: string;
    message: string;
    userId: string;
    name: string;
    createdDate: string;
    profileImage: string;
}