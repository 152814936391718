import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import {
   UserModel,
   ProfessionalModel,
   PersonalAttachmentModel,
   PersonalCertificateModel,
   PersonalLanguageModel,
   PersonalSocialMediaModel,
   CityModel,
   LanguageModel,
   SocialMediaModel,
   VendorAttachmentModel,
   OtherCityModel,
} from "./models";
import DataDiriApi from "./dataDiri.api";
import { VendorModel } from "../dataMitra/models";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";

export interface CreateDataDiriSlice {
   name?: string;
   userList?: UserModel[];
   professionalSingle?: ProfessionalModel;
   vendorSingle?: VendorModel;
   personalAttachment?: PersonalAttachmentModel[];
   vendorAttachment?: VendorAttachmentModel[];
   personalCertificate?: PersonalCertificateModel[];
   personalLanguage?: LanguageModel[];
   personalMainCity?: CityModel[];
   personalOtherCity?: OtherCityModel[];
   socialMedia?: SocialMediaModel[];
   verifyUser?: object;
   isLoading?: boolean;
   error?: ApiErrorResponse<any>;
   status?: string;
   verifyStatus?: ApiResponseStatus;
   verifyError?: ApiErrorResponse<any>;
   isVerifyLoading?: boolean;
}

export const getUserList = createAsyncThunk(
   "dataDiriState/dataDiriList",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await DataDiriApi.getUserList();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getProfessionalSingle = createAsyncThunk(
   "dataDiriState/dataDiriProfessionalSingle",
   async (userId: string, { rejectWithValue }) => {
      try {
         return await DataDiriApi.getProfessionalSingle(userId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getVendorSingle = createAsyncThunk(
   "dataMitraState/dataMitraVendorSingle",
   async (userId: string, { rejectWithValue }) => {
      try {
         return await DataDiriApi.getVendorSingle(userId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getPersonalAttachment = createAsyncThunk(
   "dataDiriState/dataDiriPersonalAttachment",
   async (personalId: string, { rejectWithValue }) => {
      try {
         return await DataDiriApi.getPersonalAttachment(personalId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getVendorAttachment = createAsyncThunk(
   "dataDiriState/dataDiriVendorAttachment",
   async (personalId: string, { rejectWithValue }) => {
      try {
         return await DataDiriApi.getVendorAttachment(personalId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getPersonalCertificate = createAsyncThunk(
   "dataDiriState/dataDiriPersonalCertificate",
   async (personalId: string, { rejectWithValue }) => {
      try {
         return await DataDiriApi.getPersonalCertificate(personalId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getPersonalLanguage = createAsyncThunk(
   "dataDiriState/dataDiriPersonalLanguage",
   async (personalId: string, { rejectWithValue }) => {
      try {
         return await DataDiriApi.getPersonalLanguage(personalId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getPersonalCity = createAsyncThunk(
   "dataDiriState/dataDiriPersonalCity",
   async (personalId: string, { rejectWithValue }) => {
      try {
         return await DataDiriApi.getPersonalCity(personalId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getSocialMedia = createAsyncThunk(
   "dataDiriState/dataDiriSocialMedia",
   async (personalId: string, { rejectWithValue }) => {
      try {
         return await DataDiriApi.getSocialMedia(personalId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const verifyUser = createAsyncThunk(
   "dataDiriState/dataDiriverifyUser",
   async (userId: string, { rejectWithValue }) => {
      try {
         return await DataDiriApi.verifyUser(userId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const createDataDiriSlice = createSlice({
   name: "createDataDiriState",
   initialState: {} as CreateDataDiriSlice,
   reducers: {
      setEmptyDataDiri: (state) => {
         state.professionalSingle = {} as ProfessionalModel;
         state.vendorSingle = {} as VendorModel;
         state.error = {} as ApiErrorResponse<any>;
         state.personalAttachment = [];
         state.vendorAttachment = [];
         state.personalCertificate = [];
         state.personalLanguage = [];
         state.personalMainCity = [];
         state.personalOtherCity = [];
         state.socialMedia = [];
         state.isLoading = false;
      },
      resetVerifyUser: (state) => {
         state.verifyStatus = ApiResponseStatus.pending;
         state.isVerifyLoading = false;
         state.verifyError = {} as ApiErrorResponse<any>;
      }
   },
   extraReducers: (builder) => {
      builder.addCase(getUserList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getUserList.fulfilled, (state, { payload }) => {
         state.userList = payload.data;
         state.isLoading = false;
      });
      builder.addCase(getUserList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(getProfessionalSingle.pending, (state) => {
         state.professionalSingle = {} as ProfessionalModel;
         state.error = {} as ApiErrorResponse<any>;
         state.isLoading = true;
      });
      builder.addCase(getProfessionalSingle.fulfilled, (state, { payload }) => {
         state.professionalSingle = payload;
         state.isLoading = false;
      });
      builder.addCase(getProfessionalSingle.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.professionalSingle = {
            id: "false",
            userId: "",
            professionalTypeList: [],
            fullname: "",
            identityNumber: "",
            phoneNumber: "",
            address: "",
            bio: "",
            website: "",
            bankAccount: "",
            bankName: "",
            bankOnBehalf: "",
            isVerified: false,
            username: "",
            aboutMeTitle: "",
            aboutMePage: "",
            gender: 0,
         };
         state.isLoading = false;
      });
      builder.addCase(getVendorSingle.pending, (state) => {
         state.vendorSingle = {} as VendorModel;
         state.error = {} as ApiErrorResponse<any>;
         state.isLoading = true;
      });
      builder.addCase(getVendorSingle.fulfilled, (state, { payload }) => {
         state.vendorSingle = payload;
         state.isLoading = false;
      });
      builder.addCase(getVendorSingle.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.vendorSingle = {
            id: "false",
            userId: "",
            vendorTypeId: "",
            picName: "",
            picIdentityNumber: "",
            picPhoneNumber: "",
            address: "",
            bio: "",
            website: "",
            city: "",
            bankAccount: "",
            bankName: "",
            bankOnBehalf: "",
            vendorType: "",
            isVerified: false,
            username: "",
            aboutMeTitle: "",
            aboutMePage: "",
            gender: 0,
         };
         state.isLoading = false;
      });
      builder.addCase(getPersonalAttachment.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getPersonalAttachment.fulfilled, (state, { payload }) => {
         state.personalAttachment = payload;
         state.isLoading = false;
      });
      builder.addCase(getPersonalAttachment.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(getVendorAttachment.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getVendorAttachment.fulfilled, (state, { payload }) => {
         state.vendorAttachment = payload;
         state.isLoading = false;
      });
      builder.addCase(getVendorAttachment.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(getPersonalCertificate.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getPersonalCertificate.fulfilled, (state, { payload }) => {
         state.personalCertificate = payload;
         state.isLoading = false;
      });
      builder.addCase(getPersonalCertificate.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(getPersonalLanguage.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getPersonalLanguage.fulfilled, (state, { payload }) => {
         state.personalLanguage = payload;
         state.isLoading = false;
      });
      builder.addCase(getPersonalLanguage.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(getPersonalCity.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getPersonalCity.fulfilled, (state, { payload }) => {
         let mainCityData = payload.filter((item: { isMainCity: boolean }) => {
            return item.isMainCity === true;
         });
         let otherCityData = payload.filter((item: { isMainCity: boolean }) => {
            return item.isMainCity === false;
         });
         state.personalMainCity = mainCityData;
         state.personalOtherCity = otherCityData;
         state.isLoading = false;
      });
      builder.addCase(getPersonalCity.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(getSocialMedia.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getSocialMedia.fulfilled, (state, { payload }) => {
         state.socialMedia = payload;
         state.isLoading = false;
      });
      builder.addCase(getSocialMedia.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(verifyUser.pending, (state) => {
         state.isVerifyLoading = true;
         state.verifyStatus = ApiResponseStatus.pending;
      });
      builder.addCase(verifyUser.fulfilled, (state, { payload }) => {
         state.verifyUser = payload;
         state.isVerifyLoading = false;
         state.verifyStatus = ApiResponseStatus.success;
      });
      builder.addCase(verifyUser.rejected, (state, { payload }) => {
         state.verifyError = payload as ApiErrorResponse<any>;
         state.isVerifyLoading = false;
         state.verifyStatus = ApiResponseStatus.failed;
      });
   },
});

export const { resetVerifyUser, setEmptyDataDiri } = createDataDiriSlice.actions;
export default createDataDiriSlice.reducer;
