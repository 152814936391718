import axios from "axios";
import {
  ProfessionalTypeModel,
  CreateProfessionalTypeSuccessResponse,
} from "./models";
import { endpoint } from "../../../../constants";

export default class ProfessionalTypeApi {
  static createProfessionalType(createProfessionalType: ProfessionalTypeModel) {
    return axios
      .post<any>(
        endpoint + "/api/ProfessionalType/Create",
        createProfessionalType
      )
      .then((res) => res.data.data);
  }

  static getProfessionalTypeList() {
    return axios
      .get<any>(endpoint + "/api/ProfessionalType/GetList")
      .then((res) => res.data.data);
  }

  static getSingleItem(professionalTypeId: string) {
    return axios
      .get<any>(
        endpoint +
          "/api/ProfessionalType/GetSingleItem?id=" +
          professionalTypeId
      )
      .then((res) => res.data.data);
  }

  static updateProfessionalType(professionalType: ProfessionalTypeModel) {
    return axios
      .post<any>(endpoint + "/api/ProfessionalType/Update", professionalType)
      .then((res) => res.data.data);
  }

  static deleteProfessionalType(professionalType: ProfessionalTypeModel) {
    return axios
      .post<any>(endpoint + "/api/ProfessionalType/Delete", professionalType)
      .then((res) => [
        { response: res.data.data },
        { id: professionalType.id },
      ]);
  }
}
