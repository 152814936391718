import React, { useState, ChangeEvent, useEffect, Fragment } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logUserIn, resetError, resetPassword, resetStatus } from "../user.reducer";
import { LoginCredential } from "../models";
import "./LoginForm.css";
import { RootState } from "../../../app/store";
import { ApiErrorResponse } from "../../..";
import { checkObjectIsNotEmpty } from "../../../helpers/checkEmptyObject";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

const ForgotPasswordForm = () => {
   const [validated, setValidated] = useState(false);
   const [username, setUsername] = useState("");
   const [errorMessage, setErrorMessage] = useState<string>("");

   const dispatch = useDispatch();

   const history = useHistory();

   const error = useSelector(
      (state: RootState) => state.user.error ?? ({} as ApiErrorResponse<any>)
   );

   const status = useSelector((state: RootState) => state.user.sendForgotStatus ?? "");

   //  useEffect(() => {
   //     if (status === "success") {

   //        dispatch(resetStatus());
   //     } else if (status === "failed") {
   //        dispatch(resetStatus());
   //     }
   //  }, [status]);

   const onUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
      setUsername(e.target.value);
   };

   const handleSubmit = async (event: {
      currentTarget: any;
      preventDefault: () => void;
      stopPropagation: () => void;
   }) => {
      setErrorMessage("");
      dispatch(resetError());
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.preventDefault();

         event.stopPropagation();
      } else {
         event.preventDefault();
         dispatch(resetPassword(username));
      }
      setValidated(true);
   };

   const onBackToLogin = () => {
      dispatch(resetStatus());
      history.push("/login");
   };

   return (
      <div className="centered_element">
         <Container>
            <Row className="justify-content-center">
               <Col md={6} className="box_element">
                  {status === "success" ? (
                     <Fragment>
                        <h6 className="text-center mb-4">Berhasil Mengirim Permintaan</h6>
                        <div className="text-center mb-4">
                           <FontAwesomeIcon
                              icon={faPaperPlane}
                              className="mr-2"
                              style={{ fontSize: "84px", color: "#6a308b" }}
                           />
                        </div>
                        <p className="text-center mb-4">
                           Kami telah mengirimkan anda tautan ke email {username} untuk mengatur
                           ulang kata sandi.
                        </p>
                        <div className="text-center">
                           <Button className="button-primary" onClick={onBackToLogin}>
                              Kembali ke Login
                           </Button>
                        </div>
                     </Fragment>
                  ) : (
                     <Fragment>
                        <h6 className="text-center mb-3">Lupa Password</h6>
                        <p className="text-center">
                           Lupa Password? Masukkan email anda untuk mengganti password
                        </p>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                           <Form.Group controlId="formBasicEmail">
                              <Form.Label className="d-inline">Email address </Form.Label>
                              <small className="text-danger float-right">{errorMessage}</small>
                              <Form.Control
                                 type="email"
                                 name="userEmail"
                                 placeholder="Enter email"
                                 required
                                 onChange={onUsernameChange}
                              />

                              <Form.Control.Feedback type="invalid">
                                 Masukkan format email yang benar
                              </Form.Control.Feedback>
                           </Form.Group>
                           <Button className="btn button-primary" type="submit">
                              Submit
                           </Button>
                        </Form>
                     </Fragment>
                  )}
               </Col>
            </Row>
         </Container>
      </div>
   );
};

export default ForgotPasswordForm;
