import React, {useEffect, useState} from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import NumberFormat from "react-number-format";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {TableCustomStyles} from "../../../components/DataTable/TableCustomStyles";
import {getMitraPointList, multipleUpdateMitraPoint, resetStatus,} from "../mitraPoint.reducer";
import {MitraPointModel, PointTierEnum} from "../models";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {customSavedOption} from "../../../helpers/alertContent";

const MitraPointTable = () => {
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);

    const [updatedPoint, setUpdatedPoint] = useState<MitraPointModel[]>([]);

    const [resetPaginationToggle] = useState(false);
    const pointSettingList = useSelector(
        (state: RootState) => state.mitraPoint.list ?? []
    );
    const [sortedPointSettingList, setSortedPointSettingList] = useState<MitraPointModel[]>([]);
    const status = useSelector((state: RootState) => state.mitraPoint.status);
    const error = useSelector((state: RootState) => state.mitraPoint.error);

    useEffect(() => {
    if (pointSettingList.length !== 0) {
        setUpdatedPoint(pointSettingList);
        const pointTierOrder = [PointTierEnum.Verified, PointTierEnum.Experienced, PointTierEnum.Professional, PointTierEnum.Award];

        // Create a copy of the array and sort it
        const sortedList = [...pointSettingList].sort((a, b) => {
            return pointTierOrder.indexOf(a.tier) - pointTierOrder.indexOf(b.tier);
        });
        setSortedPointSettingList(sortedList);
    }
}, [pointSettingList]);

    const onFormSubmit = () => {
        console.log(updatedPoint);
        MySwal.fire({
            text: `Apakah kamu yakin akan mengubah point level?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(multipleUpdateMitraPoint(updatedPoint));
            }
        });
    };
    const InputPoint = (data: MitraPointModel, isNominal: boolean) => {

        const changeUpdatePoint = (value: number) => {
            let _updatedPoint = updatedPoint.map((item) => {
                if (item.id === data.id) {
                    return {
                        id: item.id,
                        tier: item.tier,
                        totalTransaction: isNominal ? item.totalTransaction : value,
                        totalNominal: isNominal ? value : item.totalNominal,
                    } as MitraPointModel;
                } else {
                    return item;
                }
            });
            setUpdatedPoint(_updatedPoint);
        }

        return (
            <NumberFormat
                className="form-control"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={isNominal ? "Rp " : ""}
                defaultValue={isNominal ? data.totalNominal : data.totalTransaction}
                onValueChange={(values) => {
                    let val = values.floatValue ?? 0;
                    changeUpdatePoint(val);
                }}
            />
        );
    };

    const pointTierOptions = [
        {label: "Verified", value: PointTierEnum.Verified},
        {label: "Experienced", value: PointTierEnum.Experienced},
        {label: "Professional", value: PointTierEnum.Professional},
        {label: "Award", value: PointTierEnum.Award},
    ]

    const columns: TableColumn<MitraPointModel>[] = [
        {
            selector: (row) => pointTierOptions.find((item) => item.value === row.tier)?.label ?? "-",
            sortable: true,
        },
        {
            name: "JUMLAH TRANSAKSI",
            cell: (row: MitraPointModel) => InputPoint(row, false),
            sortable: true,
        },
        {
            name: "NOMINAL TRANSAKSI",
            cell: (row: MitraPointModel) => InputPoint(row, true),
            sortable: true,
        },
    ];

    useEffect(() => {
        dispatch(getMitraPointList());
        dispatch(resetStatus());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(customSavedOption(status === ApiResponseStatus.success, error?.data?.message ?? "", 'Berhasil Mengubah Poin Mitra', 'Gagal Mengubah Poin Mitra: ' + error?.data?.message ?? "-")).then(() => {
                dispatch(getMitraPointList());
                dispatch(resetStatus());
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return (
        <div>
            <div>
                <DataTable
                    columns={columns}
                    data={sortedPointSettingList}
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle}
                    noHeader
                    customStyles={TableCustomStyles}
                />
            </div>

            <div>
                <button className="btn button-primary" onClick={onFormSubmit}>
                    UBAH
                </button>
            </div>
        </div>

    );
};

export default MitraPointTable;
