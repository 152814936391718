import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import {Link} from "react-router-dom";
import DataTable, {TableColumn} from "react-data-table-component";
import DefaultSubHeader from "../../../../components/DataTable/DefaultSubHeader";
import {TableCustomStyles} from "../../../../components/DataTable/TableCustomStyles";
import {ElementPropModel} from "../../../../components/DataTable/models";
import {
    deleteBlog,
    getBlogByTypeAdminNew, getBlogByTypeMitraNew,
    resetStatus, sendBlogNotification,
    setHomepageBlog
} from "../blog.reducer";
import {BlogListModel} from "../models";
import {ApiErrorResponse} from "../../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {customSavedOption, deletedWarnOption, savedStatusOption} from "../../../../helpers/alertContent";
import {changePublishEvent} from "../../../marketingEvent/marketingEvent.reducer";
import {getPackageByApprovalList} from "../../../deleteMitraService/deleteMitraService.reducer";
import {ApprovalStatusEnum, RevisionModel} from "../../../deleteMitraService/models";

const BlogTable = () => {
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);

    const blogAdminState = useSelector((state: RootState) => state.blogAdmin.listAdmin ?? []);
    const blogMitraState = useSelector((state: RootState) => state.blogAdmin.listMitra ?? []);
    const blogAdminRowsState = useSelector((state: RootState) => state.blogAdmin.adminRows ?? 0);
    const blogMitraRowsState = useSelector((state: RootState) => state.blogAdmin.mitraRows ?? 0);
    const isAdminListLoading = useSelector((state: RootState) => state.blogAdmin.isAdminListLoading);
    const isMitraListLoading = useSelector((state: RootState) => state.blogAdmin.isMitraListLoading);
    const status = useSelector((state: RootState) => state.blogAdmin.status);
    const notificationStatus = useSelector((state: RootState) => state.blogAdmin.notificationStatus);
    const error = useSelector((state: RootState) => state.blogAdmin.error ?? ({} as ApiErrorResponse<any>));

    const [timer, setTimer] = useState(setTimeout(() => {
    }, 1000));

    // const [blogHomepageState, setBlogHomepageState] = useState<BlogListModel[]>([]);

    const [filterAdminText, setFilterAdminText] = useState("");
    const [filterMitraText, setFilterMitraText] = useState("");
    const [rowsAdminPerPage, setRowsAdminPerPage] = useState<number>(10);
    const [rowsMitraPerPage, setRowsMitraPerPage] = useState<number>(10);

    const onSetterAdminFilterText = (value: string) => {
        let filterValue = value;
        setFilterAdminText(filterValue);
        clearTimeout(timer);
        setTimer(
            setTimeout((value = filterValue) => {
                dispatch(getBlogByTypeAdminNew({
                    name: value === "" ? undefined : value,
                    skip: 0,
                    take: rowsAdminPerPage,
                }));
            }, 1000)
        );
    };

    const onSetterMitraFilterText = (value: string) => {
        let filterValue = value;
        setFilterMitraText(filterValue);
        clearTimeout(timer);
        setTimer(
            setTimeout((value = filterValue) => {
                dispatch(getBlogByTypeMitraNew({
                    name: value === "" ? undefined : value,
                    skip: 0,
                    take: rowsAdminPerPage,
                }));
            }, 1000)
        );
    };

    useEffect(() => {
        dispatch(getBlogByTypeAdminNew({skip: 0, take: 10}));
        dispatch(getBlogByTypeMitraNew({skip: 0, take: 10}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(savedStatusOption(status === ApiResponseStatus.success, error.data?.message)).then(() => {
                dispatch(resetStatus());
                dispatch(getBlogByTypeAdminNew({skip: 0, take: 10}));
                dispatch(getBlogByTypeMitraNew({skip: 0, take: 10}));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    useEffect(() => {
        if (notificationStatus !== undefined && notificationStatus !== ApiResponseStatus.pending) {
            MySwal.fire(customSavedOption(notificationStatus === ApiResponseStatus.success, error.data?.message, 'Berhasil mengirim notifikasi', 'Gagal mengirim notifikasi: ' + error.data?.message)).then(() => {
                dispatch(resetStatus());
            });
        }
    }, [notificationStatus, error]);

    // useEffect(() => {
    //     if (blogAdminState.length !== 0 && blogMitraState.length !== 0) {
    //         const filteredArr = blogMitraState.filter((item) => item.showAtHome);
    //         setBlogHomepageState([...blogAdminState, ...filteredArr]);
    //     }
    // }, [blogAdminState, blogMitraState]);

    const elementProps = {
        placeholder: "cari berdasarkan nama",
        buttonName: "tambah blog",
        createUrl: "/master/blog/create",
        subHeaderLine: true,
    } as ElementPropModel;

    const blogMitraElementProps = {
        placeholder: "cari berdasarkan nama",
        buttonName: "",
        createUrl: "",
        subHeaderLine: true,
    } as ElementPropModel;

    const setAsHomepageClicked = (data: BlogListModel) => {
        const _blogCategoryArray = data.blogCategory.map((item) => {
            return item.id;
        });

        dispatch(
            setHomepageBlog({
                id: data.id,
                blogCategory: _blogCategoryArray,
                metaKeyword: data.metaKeyword,
                thumbnailPath: data.thumbnailPath,
                title: data.title,
                subtitle: data.subtitle,
                content: data.content,
                isPublished: data.isPublished,
                isAdmin: false,
                showAtHome: true,
                userId: data.userId,
                marketingEventList: data.marketingEventList,
            })
        );
    };

    const setPublishClicked = (data: BlogListModel) => {
        const _blogCategoryArray = data.blogCategory.map((item) => {
            return item.id;
        });

        dispatch(
            setHomepageBlog({
                id: data.id,
                blogCategory: _blogCategoryArray,
                metaKeyword: data.metaKeyword,
                thumbnailPath: data.thumbnailPath,
                title: data.title,
                subtitle: data.subtitle,
                content: data.content,
                isPublished: !data.isPublished,
                isAdmin: false,
                showAtHome: data.showAtHome,
                userId: data.userId,
                marketingEventList: data.marketingEventList,
            })
        );
    };

    const onNotificationSend = (id: string) => {
        MySwal.fire({
            text: `Apakah kamu yakin akan mengirim notifikasi ke user?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(sendBlogNotification(id));
            }
        });
    }

    const columns: TableColumn<BlogListModel>[] = [
        {
            name: "NO.",
            width: "60px",
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: "JUDUL BLOG",
            selector: (row) => row.title,
            sortable: true,
        },
        {
            name: "KATEGORI",
            selector: (row) => row.blogCategory[0].name ?? "-",
        },
        {
            name: "PENERBIT",
            selector: (row) => row.isAdmin,
            format: (row) => (row.isAdmin ? "Admin" : "Mitra"),
            sortable: true,
        },
        {
            name: "PEMBUAT",
            selector: (row) => row.userName,
            sortable: true,
        },
        {
            name: "STATUS",
            selector: (row) => row.showAtHome,
            format: (row) => (row.showAtHome ? "Show" : "Hide"),
            sortable: true,
        },
        {
            name: "AKSI",
            cell: (d: { id: any; isAdmin: boolean }) => (
                <>
                    {" "}
                    <Link
                        to={{
                            pathname: `/master/blog/preview/${d.id}`,
                        }}>
                        <button className="btn button-primary mr-2">PRATINJAU</button>
                    </Link>
                    {d.isAdmin && (
                        <>
                            <button className="btn button-primary mr-2" onClick={() => onNotificationSend(d.id)}>KIRIM NOTIFIKASI</button>
                            <Link
                                to={{
                                    pathname: `/master/blog/edit/${d.id}`,
                                }}>
                                <button className="btn button-primary mr-2">EDIT</button>
                            </Link>
                            <button
                                onClick={() => onDelete(d.id)}
                                className="btn button-danger">
                                HAPUS
                            </button>
                        </>
                    )}
                </>
            ),
            width: "500px",
        },
    ];

    const mitraBlogColumns: TableColumn<BlogListModel>[] = [
        {
            name: "NO.",
            width: "60px",
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: "JUDUL BLOG",
            selector: (row) => row.title,
            sortable: true,
        },
        {
            name: "PEMBUAT",
            selector: (row) => row.mitraName ?? "-",
            sortable: true,
        },
        {
            name: "KATEGORI",
            selector: (row) => row.blogCategory[0].name ?? "-",
        },
        {
            name: "AKSI",
            width: "500px",
            cell: (data: BlogListModel) => (
                <>
                    {" "}
                    <Link
                        to={{
                            pathname: `/master/blog/preview/${data.id}`,
                        }}>
                        <button className="btn button-primary mr-2">PRATINJAU</button>
                    </Link>
                    <button
                        className="btn button-primary mr-2"
                        onClick={() => setAsHomepageClicked(data)}>
                        TAMPILKAN DI BERANDA
                    </button>
                    <button
                        className="btn button-primary mr-2"
                        onClick={() => setPublishClicked(data)}>
                        {data.isPublished ? "BATALKAN PUBLIKASI" : "PUBLIKASIKAN"}
                    </button>
                    {/* <button
            onClick={() => {
              if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
                onDelete(d.id);
            }}
            className="btn button-danger"
          >
            Delete
          </button> */}
                </>
            ),
        },
    ];

    // end of datatable components

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDelete = (id: string) => {
        MySwal.fire(deletedWarnOption).then((result) => {
            if (result.isConfirmed) {
                dispatch(
                    deleteBlog({
                        id: id,
                        blogCategory: [],
                        metaKeyword: [],
                        title: "",
                        subtitle: "",
                        content: "",
                        thumbnailPath: "",
                        isPublished: false,
                        isAdmin: true,
                        showAtHome: false,
                        userId: "",
                        marketingEventList: "",
                    })
                );
            }
        });
    };

    const handlePerRowsAdminChange = async (newPerPage: number, page: number) => {
        setRowsAdminPerPage(newPerPage);
        dispatch(getBlogByTypeAdminNew({skip: newPerPage * (page - 1),
            take: newPerPage}));
    };

    const handlePageAdminChange = (page: number) => {
        dispatch(getBlogByTypeAdminNew({skip: rowsAdminPerPage * (page - 1),
            take: rowsAdminPerPage}));
    };

    const handlePerRowsMitraChange = async (newPerPage: number, page: number) => {
        setRowsMitraPerPage(newPerPage);
        dispatch(getBlogByTypeMitraNew({skip: newPerPage * (page - 1),
            take: newPerPage}));
    };

    const handlePageMitraChange = (page: number) => {
        dispatch(getBlogByTypeMitraNew({skip: rowsMitraPerPage * (page - 1),
            take: rowsMitraPerPage}));
    };

    return (
        <>
            <div className="mt-5">
                <h6>Beranda Blog</h6>
                <DataTable
                    columns={columns}
                    data={blogAdminState}
                    pagination
                    paginationServer
                    paginationTotalRows={blogAdminRowsState}
                    progressPending={isAdminListLoading}
                    onChangeRowsPerPage={handlePerRowsAdminChange}
                    onChangePage={handlePageAdminChange}
                    noHeader
                    subHeader
                    subHeaderComponent={DefaultSubHeader(
                        filterAdminText,
                        false,
                        onSetterAdminFilterText,
                        elementProps
                    )}
                    customStyles={TableCustomStyles}
                    persistTableHead
                />
            </div>
            <div className="mt-3">
                <h6>Mitra Blog</h6>
                <DataTable
                    columns={mitraBlogColumns}
                    data={blogMitraState}
                    pagination
                    paginationServer
                    paginationTotalRows={blogMitraRowsState}
                    onChangeRowsPerPage={handlePerRowsMitraChange}
                    onChangePage={handlePageMitraChange}
                    progressPending={isMitraListLoading}
                    noHeader
                    subHeader
                    subHeaderComponent={DefaultSubHeader(
                        filterMitraText,
                        false,
                        onSetterMitraFilterText,
                        blogMitraElementProps
                    )}
                    customStyles={TableCustomStyles}
                    persistTableHead
                />
            </div>
        </>
    );
};

export default BlogTable;
