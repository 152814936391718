import blankProfile from "../assets/images/blank_profile.jpg";
import blank_image from "../assets/images/no_image_available.png";
import {mediaEndpoint, mediaEndpointAlias} from "../constants";

export const getThumbnailImage = (url: string) => {
    let fileName = url.replace(/:\d+ /, ".").split(".")[0];
    return fileName + ".webp";
};

export const getMediaEndpoint = (url: string, isProfile?: boolean) => {
    if (url === undefined || url === null || url === "") {
        return isProfile ? blankProfile : blank_image;
    }

    if (url.includes('data:image') || url.includes(mediaEndpointAlias))
        return url;

    return `${mediaEndpoint}${url}`;
}