import React, { forwardRef, Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Badge, Dropdown, Form } from "react-bootstrap";
import sendIcon from "../../../../../assets/icons/chat_sender_icon.svg";
import emoticonIcon from "../../../../../assets/icons/chat_emoticon_icon.svg";
import attachmentIcon from "../../../../../assets/icons/chat_attachment_icon.svg";
import { addDefaultProfileImage } from "../../../../../helpers/defaultBrokenImage";
import { useDispatch, useSelector } from "react-redux";
import {getGroupMessages, getMessages, getSingleRoom, sendMessage} from "../../../chat.reducer";
import { RootState } from "../../../../../app/store";
import { MessageType, RoomModel } from "../../../models";
import Picker from "emoji-picker-react";
import ChooseAttachmentModal, {MediaType} from "../Attachment/ChooseAttachmentModal";

import SenderTextMessage from './MessageComponents/Sender/TextMessage';
import ReceiverTextMessage from './MessageComponents/Receiver/TextMessage';
import SenderImageMessage from './MessageComponents/Sender/ImageMessage';
import ReceiverImageMessage from './MessageComponents/Receiver/ImageMessage';
import SenderVideoMessage from './MessageComponents/Sender/VideoMessage';
import ReceiverVideoMessage from './MessageComponents/Receiver/VideoMessage';
import SenderFileMessage from './MessageComponents/Sender/FileMessage';
import ReceiverFileMessage from './MessageComponents/Receiver/FileMessage';
import SenderAlbumMessage from './MessageComponents/Sender/AlbumMessage';
import ReceiverAlbumMessage from './MessageComponents/Receiver/AlbumMessage';
import SenderMultipleImageMessage from './MessageComponents/Sender/MultipleImageMessage';
import ReceiverMultipleImageMessage from './MessageComponents/Receiver/MultipleImageMessage';

import moment from "moment";
import {DetailAttachmentType} from "../Attachment/DetailAttachmentModal";
import {getMediaEndpoint} from "../../../../../helpers/imageManipulation";
import {MediaPathModel} from "../Attachment/DetailPhotoAttachment";

const MessageList = ({ userId = "", name = "", roomId = "", isReadOnly = false, ...props }: MessageListProps) => {
   const dispatch = useDispatch();
   const messagesEndRef = React.createRef<any>();

   const [messageContent, setMessageContent] = useState<string>("");
    const [_showEmoji, setShowEmoji] = useState<boolean>(false);
    const [showAddAttachment, setShowAddAttachment] = useState<boolean>(false);

   const groupMessage = useSelector((state: RootState) => state.chat.groupMessage ?? []);
   const singleRoom = useSelector((state: RootState) => state.chat.singleRoom ?? ({} as RoomModel));

   useEffect(() => {
      if (userId !== "" && roomId !== "") {
         dispatch(getGroupMessages(roomId));
         dispatch(getSingleRoom(roomId));
      }
   }, [userId, roomId]);

   const onMessageChange = (e: { target: { value: React.SetStateAction<string> } }) => {
      setMessageContent(e.target.value);
   };

   const onMessageSend = () => {
      dispatch(
         sendMessage(roomId, {
            content: messageContent,
            createdAt: new Date().toISOString(),
            readById: [],
            senderId: userId,
            senderName: name,
            messageType: MessageType.text,
         })
      );
      setMessageContent("");
   };

   const onKeyUp = (event: { charCode: number }) => {
      if (event.charCode === 13) {
         dispatch(
            sendMessage(roomId, {
               content: messageContent,
               createdAt: new Date().toISOString(),
               readById: [],
               senderId: userId,
               senderName: name,
               messageType: MessageType.text,
            })
         );
         setMessageContent("");
      }
   };

   type Props = {
      onClick: (e: any) => void;
   };

   type RefType = number;

   const CustomToggle = forwardRef<RefType, Props>(({ children, onClick }, ref: any) => (
      <a
         href=""
         ref={ref}
         onClick={(e) => {
            e.preventDefault();
            onClick(e);
         }}>
         {children}
      </a>
   ));

    const toggleAddAttachment = () => {
        props.resetAddAlbum();
        setShowAddAttachment((prevState) => !prevState);
    };

    const toggleEmoji = () => {
        setShowEmoji((prevState) => !prevState);
    };

    const onEmojiClick = (_event: any, emojiObject: any) => {
        setMessageContent((prevState) => prevState + emojiObject.emoji);
    };

    const onSubmitHandler = (event: { preventDefault: () => void }) => {
        event.preventDefault();
        if (messageContent !== '') {
            onMessageSend();
        }
    };

    const onKeyDown = (
        event: React.KeyboardEvent<HTMLTextAreaElement>
    ): void => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter' && event.shiftKey) {
        } else if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            if (messageContent !== '') {
                onMessageSend();
            }
        }
    };

    const onSelectedAttachment = (media: MediaType) => {
        props.resetAddAlbum();
        props.showAddAttachment(media);
        setShowAddAttachment(false);
    };

    const onMessageClicked = (path: string, type: DetailAttachmentType) => {
        props.onMessageClicked(true, path, type);
    };

    const onMessageAlbumClicked = (albumName: string) => {
        props.onMessageAlbumClicked(albumName);
    };

    return (
      <div className="chat_message">
         <div className="header">
            <div className={`mr-3 d-inline`}>
               <img
                  className="profile_picture"
                  src="https://placeimg.com/640/480/people"
                  onError={addDefaultProfileImage}
                  alt="profile"></img>
            </div>
            <div className={`room_name`}>
               <div className="project_name_group">
                  <div className="project_name">
                     <span>{singleRoom.roomName}</span>
                  </div>
               </div>
               <span className={`user_name`}>
                  {singleRoom.users?.map((user, index) => (
                     <Fragment>
                        {singleRoom.users?.length === index + 1 ? user.name : user.name + ", "}
                     </Fragment>
                  ))}
               </span>
            </div>
            {!isReadOnly && (
                <div>
                   <div className="menu_button">
                      <Dropdown alignRight>
                         <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                            <FontAwesomeIcon
                                icon={faEllipsisV}
                                width="6"
                                style={{ color: "#a0a0a0" }}
                            />
                         </Dropdown.Toggle>

                         <Dropdown.Menu>
                            <Dropdown.Item className="profile-dropdown-menu">
                               Keluar dari Group
                            </Dropdown.Item>
                         </Dropdown.Menu>
                      </Dropdown>
                   </div>
                </div>
            )}
         </div>
          <div className={"content"}>
              <div className={"content_container"}>
                  <div className={"message_content"} ref={messagesEndRef}>
                      <div
                          className={`message_info_list justify-content-center mb-3`}
                      >
                          <div className={`message_list_item text-center`}>
                        <span className={"message_info"}>
                           Hati-hati penipuan! Mohon tidak bertransaksi di luar
                           Aramata & hindari menghubungi selain melalui fitur
                           Chat di aplikasi Aramata. Baca{' '}
                            <span className={`chat_extra_link`}>
                              Panduan Keamanan
                           </span>
                           .
                        </span>
                          </div>
                      </div>

                      {
                          groupMessage.map((messageList) => (
                              <Fragment>
                                  <Fragment>
                                      <div
                                          className={`message_info_list justify-content-center mb-3`}
                                      >
                                          <Badge pill className={"info_badge"}>
                                              {messageList.date ===
                                              moment().format('DD/MM/YYYY') ? (
                                                  "HARI INI"
                                              ) : (
                                                  <>{messageList.date}</>
                                              )}
                                          </Badge>
                                      </div>
                                  </Fragment>
                                  {messageList.messages.map((message) => {
                                      if (message.messageType === MessageType.text) {
                                          return (
                                              <Fragment>
                                                  {message.senderId === userId ? (
                                                      <SenderTextMessage
                                                          content={message.content}
                                                          createdAt={message.createdAt}
                                                      />
                                                  ) : (
                                                      <ReceiverTextMessage
                                                          profileImage=""
                                                          senderName={message.senderName}
                                                          content={message.content}
                                                          createdAt={message.createdAt}
                                                      />
                                                  )}
                                              </Fragment>
                                          );
                                      } else if (message.messageType === MessageType.image) {
                                          return (
                                              <Fragment>
                                                  {message.isGroupedChat ? (
                                                      <>
                                                          {message.senderId === userId ? (
                                                              <SenderMultipleImageMessage
                                                                  content={message.groupedChat}
                                                                  createdAt={message.createdAt}
                                                                  onMessageClick={(path) =>
                                                                      onMessageClicked(
                                                                          getMediaEndpoint(path),
                                                                          DetailAttachmentType.Picture
                                                                      )
                                                                  }
                                                                  onOtherMessageClick={(path) => props.onOtherMessageClicked(path)}
                                                              />
                                                          ) : (
                                                              <ReceiverMultipleImageMessage
                                                                  profileImage=""
                                                                  senderName={message.senderName}
                                                                  content={message.groupedChat}
                                                                  createdAt={message.createdAt}
                                                                  onMessageClick={(path) =>
                                                                      onMessageClicked(
                                                                          getMediaEndpoint(path),
                                                                          DetailAttachmentType.Picture
                                                                      )
                                                                  }
                                                                  onOtherMessageClick={(path) => props.onOtherMessageClicked(path)}
                                                              />
                                                          )}
                                                      </>
                                                  ) : (
                                                      <>
                                                          {message.senderId === userId ? (
                                                              <SenderImageMessage
                                                                  content={message.content}
                                                                  createdAt={message.createdAt}
                                                                  onMessageClick={() =>
                                                                      onMessageClicked(
                                                                          getMediaEndpoint(message.content),
                                                                          DetailAttachmentType.Picture
                                                                      )
                                                                  }
                                                              />
                                                          ) : (
                                                              <ReceiverImageMessage
                                                                  profileImage=""
                                                                  senderName={message.senderName}
                                                                  content={message.content}
                                                                  createdAt={message.createdAt}
                                                                  onMessageClick={() =>
                                                                      onMessageClicked(
                                                                          getMediaEndpoint(message.content),
                                                                          DetailAttachmentType.Picture
                                                                      )
                                                                  }
                                                              />
                                                          )}
                                                      </>
                                                  )}
                                              </Fragment>
                                          );
                                      } else if (message.messageType === MessageType.video) {
                                          return (
                                              <Fragment>
                                                  {message.senderId === userId ? (
                                                      <SenderVideoMessage
                                                          content={message.content}
                                                          createdAt={message.createdAt}
                                                          onMessageClick={() =>
                                                              onMessageClicked(
                                                                  getMediaEndpoint(message.content),
                                                                  DetailAttachmentType.Video
                                                              )
                                                          }
                                                      />
                                                  ) : (
                                                      <ReceiverVideoMessage
                                                          profileImage=""
                                                          senderName={message.senderName}
                                                          content={message.content}
                                                          createdAt={message.createdAt}
                                                          onMessageClick={() =>
                                                              onMessageClicked(
                                                                  getMediaEndpoint(message.content),
                                                                  DetailAttachmentType.Video
                                                              )
                                                          }
                                                      />
                                                  )}
                                              </Fragment>
                                          );
                                      } else if (message.messageType === MessageType.file) {
                                          return (
                                              <Fragment>
                                                  {message.senderId === userId ? (
                                                      <SenderFileMessage
                                                          content={message.content}
                                                          createdAt={message.createdAt}
                                                          fileName={message.fileName}
                                                          fileType={message.fileType}
                                                      />
                                                  ) : (
                                                      <ReceiverFileMessage
                                                          profileImage=""
                                                          senderName={message.senderName}
                                                          content={message.content}
                                                          createdAt={message.createdAt}
                                                          fileName={message.fileName}
                                                          fileType={message.fileType}
                                                      />
                                                  )}
                                              </Fragment>
                                          );
                                      } else if (message.messageType === MessageType.album) {
                                          return (
                                              <Fragment>
                                                  {message.senderId === userId ? (
                                                      <SenderAlbumMessage
                                                          content={message.content}
                                                          createdAt={message.createdAt}
                                                          onMessageClick={() =>
                                                              onMessageAlbumClicked(message.albumName)
                                                          }
                                                          senderName="Anda"
                                                          totalLastAdded={message.totalLastAdded}
                                                          albumName={message.albumName}
                                                      />
                                                  ) : (
                                                      <ReceiverAlbumMessage
                                                          profileImage=""
                                                          senderName={message.senderName}
                                                          content={message.content}
                                                          createdAt={message.createdAt}
                                                          onMessageClick={() =>
                                                              onMessageAlbumClicked(message.albumName)
                                                          }
                                                          totalLastAdded={message.totalLastAdded}
                                                          albumName={message.albumName}
                                                      />
                                                  )}
                                              </Fragment>
                                          );
                                      } else if (message.messageType === MessageType.info) {
                                          return (
                                              <Fragment>
                                                  <div
                                                      className={`message_info_list justify-content-center mb-3`}
                                                  >
                                                      <Badge pill className={"info_badge"}>
                                                          {message.content}
                                                      </Badge>
                                                  </div>
                                              </Fragment>
                                          );
                                      }
                                  })}
                              </Fragment>
                          ))
                      }

                  </div>
              </div>
          </div>
         {!isReadOnly && (
             <div className={"footer"}>
                 <div className={"footer_section"}>
                     <div className={"footer_separator"}>
                         <div className={"message_sender"}>
                             <div className={"sending_box"}>
                                 <Dropdown drop="up">
                                     <Dropdown.Toggle
                                         as={CustomToggle}
                                         id="dropdown-custom-components"
                                     >
                                         <button
                                             className={"sending_button"}
                                             onClick={toggleEmoji}
                                         >
                                             <img
                                                 src={emoticonIcon}
                                                 alt="send"
                                             />
                                         </button>
                                     </Dropdown.Toggle>

                                     <Dropdown.Menu>
                                         <Picker native onEmojiClick={onEmojiClick} />
                                     </Dropdown.Menu>
                                 </Dropdown>
                             </div>
                             <form
                                 onSubmit={onSubmitHandler}
                                 className={"chat_form"}
                             >
                                 <div className={"typing_box"}>
                                     <Form.Control
                                         as="textarea"
                                         value={messageContent}
                                         className={"typing_input"}
                                         onChange={onMessageChange}
                                         onKeyDown={onKeyDown}
                                     />
                                 </div>
                                 <div className={"sending_box"}>
                                     <button
                                         className={`mr-2 sending_button`}
                                         onClick={toggleAddAttachment}
                                     >
                                         <img
                                             src={attachmentIcon}
                                             alt="attachment"
                                         />
                                     </button>
                                 </div>
                                 <div className={"sending_box"}>
                                     <button
                                         className={"sending_button"}
                                         type="submit"
                                     >
                                         <img
                                             src={sendIcon}
                                             alt="send"
                                         />
                                     </button>
                                 </div>
                             </form>
                         </div>
                     </div>
                 </div>
             </div>
         )}
          <ChooseAttachmentModal
              showModal={showAddAttachment}
              onModalHide={toggleAddAttachment}
              selectedMedia={onSelectedAttachment}
          />
      </div>
   );
};

export interface MessageListProps {
   userId: string;
   name: string;
   roomId: string;
   isReadOnly?: boolean;
   resetAddAlbum: () => void;
   showAddAttachment: (media: MediaType) => void;
   onMessageClicked: (showDetail: boolean, path: string, type: DetailAttachmentType) => void;
   onMessageAlbumClicked: (albumName: string) => void;
   onOtherMessageClicked: (path: MediaPathModel[]) => void;
}

export default MessageList;
