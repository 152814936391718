export interface ReportIssueModel {
   id: string;
   title: string;
   description: string;
   reportIssueCategoryId: string;
   createdDate?: string;
   customerId: string;
   mitraId: string;
   email: string;
   status?: ReportIssueTypeEnum;
}

export interface ReportIssueAttachmentModel {
   id: string;
   path: string;
   reportIssueId: string;
}

export interface ReplyModel {
   id: string;
   name: string;
   email: string;
   subject: string;
   description: string;
}

export enum ReportIssueTypeEnum
{
   New,
   Replied,
   Complete
}