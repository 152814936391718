import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { RootState } from "../../../app/store";
import { uploadSingleImage } from "../../media/fileUpload/fileUpload";
import { uploadStatus } from "../../media/fileUpload/models";
import PaymentMethodApi from "./paymentMethod.api";
import {
   CreatePaymentMethod,
   PaymentMethodFormModel,
   PaymentMethodModel,
   UpdatePaymentMethodModel,
} from "./models";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";

export interface PaymentMethodSlice {
   list?: PaymentMethodModel[];
   single?: PaymentMethodModel;
   dateCreated?: string;
   isLoading?: boolean;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
}

export const getPaymentMethodList = createAsyncThunk(
   "masterPaymentMethodState/getMasterPaymentMethodList",
   async (id: string | undefined = undefined, { getState, rejectWithValue }) => {
      const { token } = (getState() as RootState).user;
      try {
         return await PaymentMethodApi.getPaymentMethodList(token as string);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getSinglePaymentMethod = createAsyncThunk(
   "masterPaymentMethodState/getSinglePaymentMethod",
   async (voucherId: string, { getState, rejectWithValue }) => {
      const { token } = (getState() as RootState).user;
      try {
         return await PaymentMethodApi.getPaymentMethodSingleItem(token as string, voucherId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const createPaymentMethod = createAsyncThunk(
   "masterIconRewardState/createMasterIconReward",
   async (formData: PaymentMethodFormModel, { getState, rejectWithValue }) => {
      const { token } = (getState() as RootState).user;
      try {
         const response = await uploadSingleImage(token as string, formData.image, "payment");
         if (response.status === uploadStatus.success) {
            const _formData = formData;
            _formData.media = response.path;
            return await PaymentMethodApi.createPaymentMethod(token as string, _formData);
         }
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const updatePaymentMethod = createAsyncThunk(
   "masterPaymentMethodState/updateMasterPaymentMethod",
   async (formData: UpdatePaymentMethodModel, { getState, rejectWithValue }) => {
      const { token } = (getState() as RootState).user;
      try {
         return await PaymentMethodApi.updatePaymentMethod(token as string, formData);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const updatePaymentMethodWithImage = createAsyncThunk(
   "masterPaymentMethodState/updateMasterPaymentMethodWithImage",
   async (formData: PaymentMethodFormModel, { getState, rejectWithValue }) => {
      const { token } = (getState() as RootState).user;
      try {
         try {
            const response = await uploadSingleImage(token as string, formData.image, "voucher");
            if (response.status === uploadStatus.success) {
               const _formData = formData;
               _formData.media = response.path;
               return await PaymentMethodApi.updatePaymentMethod(token as string, _formData);
            }
         } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
         }
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const deletePaymentMethod = createAsyncThunk(
   "masterPaymentMethodState/deleteMasterPaymentMethod",
   async (voucherId: string, { getState, rejectWithValue }) => {
      const { token } = (getState() as RootState).user;
      try {
         return await PaymentMethodApi.deletePaymentMethod(token as string, voucherId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const paymentMethodSlice = createSlice({
   name: "masterPaymentMethodState",
   initialState: {} as PaymentMethodSlice,
   reducers: {
      resetSingle: (state) => {
         state.single = {} as PaymentMethodModel;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
      },
   },
   extraReducers: (builder) => {
      // get list reducer
      builder.addCase(getPaymentMethodList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getPaymentMethodList.fulfilled, (state, { payload }) => {
         state.list = payload;
         state.isLoading = false;
      });
      builder.addCase(getPaymentMethodList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      // get single reducer
      builder.addCase(getSinglePaymentMethod.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getSinglePaymentMethod.fulfilled, (state, { payload }) => {
         state.single = payload;
         state.isLoading = false;
      });
      builder.addCase(getSinglePaymentMethod.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      // create reducer
      builder.addCase(createPaymentMethod.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(createPaymentMethod.fulfilled, (state) => {
         state.dateCreated = new Date().toString();
         state.isLoading = false;
         state.status = ApiResponseStatus.success
      });
      builder.addCase(createPaymentMethod.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = ApiResponseStatus.failed;
      });

      // update reducer
      builder.addCase(updatePaymentMethod.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(updatePaymentMethod.fulfilled, (state) => {
         state.dateCreated = new Date().toString();
         state.isLoading = false;
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(updatePaymentMethod.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = ApiResponseStatus.failed;
      });

      // update with upload image reducer
      builder.addCase(updatePaymentMethodWithImage.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(updatePaymentMethodWithImage.fulfilled, (state) => {
         state.dateCreated = new Date().toString();
         state.isLoading = false;
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(updatePaymentMethodWithImage.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = ApiResponseStatus.failed;
      });

      // delete reducer
      builder.addCase(deletePaymentMethod.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(deletePaymentMethod.fulfilled, (state) => {
         state.dateCreated = new Date().toString();
         state.isLoading = false;
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(deletePaymentMethod.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = ApiResponseStatus.failed;
      });
   },
});

export const { resetSingle, resetStatus } = paymentMethodSlice.actions;
export default paymentMethodSlice.reducer;
