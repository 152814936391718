import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ApiErrorResponse} from "../..";
import {ApiResponseStatus} from "../../@core/models/apiResponseStatus/apiResponseStatus";
import MitraPointApi from "./mitraPoint.api";
import {MitraPointModel} from "./models";

export interface MitraPointSlice {
    list?: MitraPointModel[];
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: ApiResponseStatus;
}

export const getMitraPointList = createAsyncThunk(
    "mitraPoint/getMitraPointList",
    async (_: string | undefined = undefined, {rejectWithValue}) => {
        try {
            return await MitraPointApi.getMitraPointList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const updateMitraPoint = createAsyncThunk(
    "mitraPoint/updateMitraPoint",
    async (args: MitraPointModel, {rejectWithValue}) => {
        try {
            return await MitraPointApi.updateMitraPoint(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const multipleUpdateMitraPoint = createAsyncThunk(
    "mitraPoint/multipleUpdateMitraPoint",
    async (args: MitraPointModel[], {rejectWithValue}) => {
        try {
            // using same api as updateMitraPoint but loop it
            return await Promise.all(args.map(async (arg) => {
                return await MitraPointApi.updateMitraPoint(arg);
            }));

        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    });

const mitraPointSlice = createSlice({
    name: "mitraPointState",
    initialState: {} as MitraPointSlice,
    reducers: {
        resetStatus: (state) => {
            state.status = ApiResponseStatus.pending;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMitraPointList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMitraPointList.fulfilled, (state, {payload}) => {
            state.list = payload;
            state.isLoading = false;
        });
        builder.addCase(getMitraPointList.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });

        builder.addCase(updateMitraPoint.pending, (state) => {
            state.isLoading = true;
            state.status = ApiResponseStatus.pending;
        });
        builder.addCase(updateMitraPoint.fulfilled, (state) => {
            state.isLoading = false;
            state.status = ApiResponseStatus.success;
        });
        builder.addCase(updateMitraPoint.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
            state.status = ApiResponseStatus.failed;
        });

        builder.addCase(multipleUpdateMitraPoint.pending, (state) => {
            state.isLoading = true;
            state.status = ApiResponseStatus.pending;
        });
        builder.addCase(multipleUpdateMitraPoint.fulfilled, (state) => {
            state.isLoading = false;
            state.status = ApiResponseStatus.success;
        });
        builder.addCase(multipleUpdateMitraPoint.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
            state.status = ApiResponseStatus.failed;
        });
    },
});

export const {resetStatus} = mitraPointSlice.actions;
export default mitraPointSlice.reducer;
