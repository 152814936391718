import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import TagExpertiseTypeForm from "../components/TagExpertiseTypeForm";
import {
   createTagExpertiseType,
   getTagExpertiseTypeProfessionalList,
   getTagExpertiseTypeSingle,
   getTagExpertiseTypeVendorList, resetStatus,
   updateExpertise,
   updateTagExpertiseType,
} from "../tagExpertise.reducer";
import { CreateTagExpertiseTypeModel, TagExpertiseTypeModel } from "../models";
import {useHistory, useParams} from "react-router-dom";
import { RootState } from "../../../../app/store";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiErrorResponse} from "../../../../index";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";

const TagExpertiseTypeDetailPage = () => {
   const MySwal = withReactContent(Swal);
   let history = useHistory();
   const dispatch = useDispatch();
   let param = useParams<{ type: string; id: string }>();

   const [isEdit, setIsEdit] = useState(false);

   const singleTagExpertise = useSelector(
      (state: RootState) =>
         (state.tagExpertise.single as TagExpertiseTypeModel) ?? ({} as TagExpertiseTypeModel)
   );
   const status = useSelector((state: RootState) => state.tagExpertise.status);
   const error = useSelector((state: RootState) => state.tagExpertise.error ?? ({} as ApiErrorResponse<any>));

   useEffect(() => {
      dispatch(getTagExpertiseTypeProfessionalList());
      dispatch(getTagExpertiseTypeVendorList());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (
         singleTagExpertise &&
         Object.keys(singleTagExpertise).length !== 0 &&
         singleTagExpertise.constructor === Object
      ) {
         setIsEdit(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [singleTagExpertise]);

   useEffect(() => {
      if (param.id !== undefined) {
         dispatch(getTagExpertiseTypeSingle(param));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [param]);

   useEffect(() => {
      if (status !== undefined && status !== ApiResponseStatus.pending) {
         MySwal.fire(
             savedStatusOption(
                 status === ApiResponseStatus.success,
                 error.data?.message
             )
         ).then(() => {
            dispatch(resetStatus());
            if (status === ApiResponseStatus.success) {
               history.push("/master/tag-expertise/list");
            }
         });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [status, error]);

   const onSubmit = (
      id: string,
      name: string,
      description: string,
      type: string,
      parentId: string,
      iconFile: string,
      fileFormat: string,
      defaultIcon: string
   ) => {
      if (isEdit) {
         if (iconFile !== "") {
            dispatch(
               updateTagExpertiseType({
                  fileData: {
                     file_name: "tagExpertiseIcon" + "." + fileFormat,
                     base64url: (iconFile as string).split(",")[1],
                     imagepath: "tagExpertise",
                     location: "tagExpertise",
                  },
                  formData: {
                     id,
                     name,
                     description,
                     type: parseInt(type.split("&")[0]),
                     parentId: parentId,
                  },
               } as CreateTagExpertiseTypeModel)
            );
         } else {
            dispatch(
               updateExpertise({
                  id,
                  name,
                  description,
                  type: parseInt(type.split("&")[0]),
                  parentId: parentId,
                  iconUrl: defaultIcon,
               })
            );
         }
      } else {
         if (parentId === "none") {
            dispatch(
               createTagExpertiseType({
                  fileData: {
                     file_name: "tagExpertiseIcon" + "." + fileFormat,
                     base64url: (iconFile as string).split(",")[1],
                     imagepath: "tagExpertise",
                     location: "tagExpertise",
                  },
                  formData: {
                     name,
                     description,
                     type: parseInt(type.split("&")[0]),
                     parentId: type.split("&")[1],
                  },
               } as CreateTagExpertiseTypeModel)
            );
         } else {
            dispatch(
               createTagExpertiseType({
                  fileData: {
                     file_name: "tagExpertiseIcon" + "." + fileFormat,
                     base64url: (iconFile as string).split(",")[1],
                     imagepath: "tagExpertise",
                     location: "tagExpertise",
                  },
                  formData: {
                     name,
                     description,
                     type: parseInt(type.split("&")[0]),
                     parentId,
                  },
               } as CreateTagExpertiseTypeModel)
            );
         }
      }
   };

   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <h4 className="card-label">{isEdit ? "Edit" : "Tambah"} - Master Expertise</h4>
               <hr />
               <Row>
                  <Col md={8} xl={8}>
                     <TagExpertiseTypeForm
                        onSubmit={onSubmit}
                        data={singleTagExpertise}
                        isEdit={isEdit}
                     />
                  </Col>
               </Row>
            </Col>
         </Row>
      </Container>
   );
};

export default TagExpertiseTypeDetailPage;
