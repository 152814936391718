import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import DataCustomerTable from "../components/DataCustomerTable";
import { RootState } from "../../../../app/store";
import { getUserList } from "../dataCustomer.reducer";
import {UserTypeEnum} from "../../../dataMitra/dataMitra/models";

const DataCustomerPage = () => {
  const dispatch = useDispatch();
  const statusState = useSelector(
    (state: RootState) => state.revisionOption.date
  );
  const dateCreated = useSelector(
      (state: RootState) => state.dataAdmin.dateCreated
  );

  const customerDateCreated = useSelector(
      (state: RootState) => state.dataCustomer.dateCreated
  );

  useEffect(() => {
    dispatch(getUserList({
      userType: UserTypeEnum.Customer,
      registerStatus: undefined,
      skip: 0,
      take: 10,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusState]);

  // useEffect(() => {
  //   if (dateCreated !== undefined || customerDateCreated !== undefined) {
  //       dispatch(getUserList());
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dateCreated, customerDateCreated]);

  useEffect(() => {
    // dispatch(resetSingle());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <h4 className="card-label">Data Konsumen</h4>
          <DataCustomerTable />
        </Col>
      </Row>
    </Container>
  );
};

export default DataCustomerPage;
