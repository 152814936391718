import React, {useState} from 'react';
import {Row, Container, Col} from "react-bootstrap";
import StoreReportPage from "./StoreReportPage";
import StoreFee from "../components/StoreFee";

enum StoreTabEnum {
    StoreReport = "StoreReport",
    StoreSetting = "StoreSetting",
}

const StoreSettingPage = () => {

    const [activeTag, setActiveTag] = useState<StoreTabEnum>(StoreTabEnum.StoreReport);

    return (
        <Container className="content-body" fluid>
            <Row className={"my-3"}>
                <Col md={12} className="mb-3">
                    <h4 className="card-label d-inline">{activeTag === StoreTabEnum.StoreSetting ? "Pengaturan Komisi" : "Penjualan"}</h4>
                </Col>
                <Col md={12}>
                    <div>
                        <div className="d-flex" style={{
                            borderBottom: "1px solid #a0a0a0",
                        }}>
                            <div className="flex-grow-1 d-flex justify-content-start align-items-end">
                                <div className="d-inline">
                                    <button
                                        onClick={() => {
                                            setActiveTag(StoreTabEnum.StoreReport)
                                        }}
                                        className={`btn mr-1 tab-button ${
                                            activeTag === StoreTabEnum.StoreReport ? "active" : ""
                                        }`}
                                    >
                                        Penjualan
                                    </button>
                                    <button
                                        onClick={() => {
                                            setActiveTag(StoreTabEnum.StoreSetting);
                                        }}
                                        className={`btn mr-1 tab-button ${
                                            activeTag === StoreTabEnum.StoreSetting ? "active" : ""
                                        }`}
                                    >
                                        Pengaturan Komisi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"my-3"}>
                        {
                            activeTag === StoreTabEnum.StoreReport &&
                            <StoreReportPage />
                        }
                        {
                            activeTag === StoreTabEnum.StoreSetting &&
                            <StoreFee />
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default StoreSettingPage;
