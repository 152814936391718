import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import PaymentMethodTable from "../components/PaymentMethodTable";
import { getPaymentMethodList, resetSingle } from "../paymentMethod.reducer";

const PaymentMethodPage = () => {
   const dispatch = useDispatch();

   const dateCreated = useSelector((state: RootState) => state.paymentMethod.dateCreated);

   useEffect(() => {
      dispatch(getPaymentMethodList());
      dispatch(resetSingle());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      dispatch(getPaymentMethodList());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dateCreated]);

   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <h4 className="card-label">Master Metode Pembayaran</h4>
               <PaymentMethodTable />
            </Col>
         </Row>
      </Container>
   );
};

export default PaymentMethodPage;
