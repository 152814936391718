import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import BlogApi from "./blog.api";
import { BlogModel } from "./models";

export interface BlogSlice {
  list?: BlogModel[];
  single?: BlogModel;
  isLoading?: boolean;
  status?: string;
  error?: ApiErrorResponse<any>;
}

export const getBlogList = createAsyncThunk(
  "blogState/blogList",
  async (userId: string, { rejectWithValue }) => {
    try {
      return await BlogApi.getBlogByUser(userId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getBlogSingle = createAsyncThunk(
  "blogState/blogSingle",
  async (id: string, { rejectWithValue }) => {
    try {
      return await BlogApi.getSingleBlog(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const blogSlice = createSlice({
  name: "blogState",
  initialState: {} as BlogSlice,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBlogList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBlogList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getBlogList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getBlogSingle.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBlogSingle.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.isLoading = false;
    });
    builder.addCase(getBlogSingle.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
  },
});

export default blogSlice.reducer;
