import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Image,
  Card,
  Badge,
} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import { getProduct, getPackage } from "../product.reducer";
import NumberFormat from "react-number-format";
import EmptyPortfolioImage from "../../../../assets/images/empty_portfolio.svg";
import {getMediaEndpoint} from "../../../../helpers/imageManipulation";

const ProductPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const productList = useSelector(
    (state: RootState) => state.product.productList ?? []
  );

  const packageList = useSelector(
    (state: RootState) => state.product.packageList ?? []
  );

  const expertiseList = useSelector(
    (state: RootState) => state.expertise.list ?? []
  );

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getProduct(id));
      dispatch(getPackage(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleCardClick = (
    e: { preventDefault: () => void },
    productId: string
  ) => {
    e.preventDefault();
    history.push(`/mitra/product/detail/${productId}`);
  };

  const handlePackageClick = (
    e: { preventDefault: () => void },
    packageId: string
  ) => {
    e.preventDefault();
    history.push(`/mitra/package/detail/${packageId}`);
  };

  return (
    <>
      <h5 className="mb-4 mt-4 data_diri_tab_menu_title">PAKET</h5>
      {packageList.length !== 0 && (
        <Row className="justify-content-center">
          {/* <Col md={12} style={{ padding: "0 30px" }}>
            <h6 className="mb-4 mt-3 data_diri_tab_body_title d-inline-block">
              Paket Utama{" "}
            </h6>
          </Col> */}
          <Col md={12} >
            {packageList.map((item) => {
              return (
                <Card
                  key={item.id}
                  className="product_card d-inline-block"
                  onClick={(e: { preventDefault: () => void }) => {
                    handlePackageClick(e, item.id);
                  }}
                >
                  <Card.Img
                    variant="top"
                    src={
                      item.image === ""
                        ? "/assets/images/no_image_available.jpg"
                        : getMediaEndpoint(item.image)
                    }
                    className="product_card_image"
                  />
                  <Card.Body className="product_card_body">

                    <Card.Title className="product_title_text">
                      {item.name}
                    </Card.Title>
                    <div className="card-facility mt-2 mb-1">
                      <div className="card-facility">
                        <Badge pill className="expertise-tag mr-2" key={item.id}>
                          {item.expertiseName.split(",")[0]}
                          {item.expertiseName.split(",").length > 1 && ` +${item.expertiseName.split(",").length}`}
                        </Badge>
                      </div>
                    </div>
                    {
                        item.mitraTypeName && (
                            <Badge
                                pill
                                className="mr-2 badge-outline-primary text-capitalize"
                                style={{fontSize: "11px"}}
                            >
                              {item.mitraTypeName}
                            </Badge>
                        )
                    }
                    <span className="product_starts_text d-block mt-2">Mulai Dari </span>
                    <Card.Text className="product_price_text">
                      Rp.{" "}
                      <NumberFormat
                        value={item.price}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                      />
                    </Card.Text>
                  </Card.Body>
                </Card>
              );
            })}
          </Col>
        </Row>
      )}
      <h5 className="mb-4 mt-4 data_diri_tab_menu_title">PRODUK</h5>
      {productList.length !== 0 && (
          <Row className="justify-content-center">
            {/* <Col md={12} style={{ padding: "0 30px" }}>
            <h6 className="mb-4 mt-3 data_diri_tab_body_title d-inline-block">
              Paket Utama{" "}
            </h6>
          </Col> */}
            <Col md={12} >
              {productList.map((item) => {
                return (
                    <Card
                        key={item.id}
                        className="product_card d-inline-block"
                        onClick={(e: { preventDefault: () => void }) => {
                          handleCardClick(e, item.id);
                        }}
                    >
                      <Card.Img
                          variant="top"
                          src={
                            item.image === ""
                                ? "/assets/images/no_image_available.jpg"
                                : getMediaEndpoint(item.image)

                          }
                          className="product_card_image"
                      />
                      <Card.Body className="product_card_body">
                        {expertiseList.map((itemEx) => {
                          return (
                              <p className="product_category_text">
                                {itemEx.id === item.expertiseId ? itemEx.name : ""}
                              </p>
                          );
                        })}
                        <Card.Title className="product_title_text">
                          {item.name}
                        </Card.Title>
                        <div className="card-facility mt-2 mb-1">
                          <div className="card-facility">
                            <Badge pill className="expertise-tag mr-2" key={item.id}>
                              {item.expertiseName.split(",")[0]}
                              {item.expertiseName.split(",").length > 1 && ` +${item.expertiseName.split(",").length}`}
                            </Badge>
                          </div>
                        </div>
                        {
                            item.mitraTypeName && (
                                <Badge
                                    pill
                                    className="mr-2 badge-outline-primary text-capitalize"
                                    style={{fontSize: "11px"}}
                                >
                                  {item.mitraTypeName}
                                </Badge>
                            )
                        }
                        <span className="product_starts_text d-block mt-2">Harga </span>
                        <Card.Text className="product_price_text">
                        Rp.{" "}
                          <NumberFormat
                              value={item.price}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                          />
                        </Card.Text>
                      </Card.Body>
                    </Card>
                );
              })}
            </Col>
          </Row>
      )}
      {productList.length === 0 && (
        <Row className="empty_portfolio">
          <Col md={12} className="text-center">
            <Image
              className="empty_portfolio_image justify-content-center"
              src={EmptyPortfolioImage}
            />
          </Col>
          <Col md={12} className="text-center mt-4 mb-4">
            <h6 className="empty_portfolio_title">
              Kamu belum mempunyai produk/jasa apapun
            </h6>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ProductPage;
