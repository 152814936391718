import React, {ChangeEvent, useEffect, useState} from "react";
import {Modal, Row, Col, Button, Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {resetStatus, updateEmailAndPhone} from "../dataMitra.reducer";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";
import {RootState} from "../../../../app/store";
import {ApiErrorResponse} from "../../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ComponentCircleLoader from "../../../../components/ComponentCircleLoader";
import {checkObjectIsNotEmpty} from "../../../../helpers/checkEmptyObject";

type InputForm = {
    phoneNumber: string;
    email: string;
}

type DataType = {
    userId: string;
    phoneNumber: string;
    email: string;
}

// type ErrorForm = {
//     phoneNumber: {
//         isError: boolean;
//         message: string;
//     };
//     email: {
//         isError: boolean;
//         message: string;
//     }
// }

const UpdateBasicAccountModal = ({isShow = false, data = {} as DataType, ...props}: UpdateBasicAccountModalProps) => {
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();

    const status = useSelector((state: RootState) => state.dataMitra.updateEmailPhoneStatus);
    const error = useSelector((state: RootState) => state.dataMitra.updateEmailPhoneError ?? ({} as ApiErrorResponse<any>));
    const isLoading = useSelector((state: RootState) => state.dataMitra.isLoading ?? false);

    const [modalShow, setModalShow] = useState(false);
    const [validated, setValidated] = useState<boolean>(false);

    const [inputForm, setInputForm] = useState<InputForm>({
        phoneNumber: '',
        email: ''
    });

    useEffect(() => {
        setModalShow(isShow);
    }, [isShow]);

    useEffect(() => {
        if (checkObjectIsNotEmpty(data)) {
            setInputForm(data);
        }
    }, [data]);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === ApiResponseStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(resetStatus());
                if (status === ApiResponseStatus.success) {
                    setModalShow(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onFormChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputForm((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value
            }
        });
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
            dispatch(updateEmailAndPhone({
                userId: data.userId,
                phoneNumber: inputForm.phoneNumber,
                email: inputForm.email
            }));
        }
        setValidated(true);
    }

    return (
        <>
            <Modal
                show={modalShow}
                backdrop="static"
                keyboard={false}
                centered
                className="profile_modal">
                <Modal.Body>
                    <ComponentCircleLoader loading={isLoading} loadingName={`Menyimpan Perubahan`}>
                        <Row>
                            <Col md={12} className="mb-3">
                                <h5>
                                    Ubah Email / Nomor Telepon
                                    <div className="float-right" onClick={props.onCloseHandler}>
                                        <span className="material-icons modal-close">close</span>
                                    </div>
                                </h5>
                            </Col>
                            <Col md={12} className="mb-3">
                                <Form
                                    noValidate
                                    id="changePhoneEmail"
                                    validated={validated}
                                    onSubmit={onSubmit}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>E-Mail</Form.Label>
                                        <Form.Control name="email" type="email" value={inputForm.email} onChange={onFormChange}/>
                                        <Form.Control.Feedback type="invalid">Masukkan E-Mail</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Nomor Telepon</Form.Label>
                                        <Form.Control name="phoneNumber" type="text" value={inputForm.phoneNumber} onChange={onFormChange} required/>
                                        <Form.Control.Feedback type="invalid">
                                            Masukkan Nomor Telepon
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col md={12} className="mb-3">
                                <Button
                                    className="float-right button-primary mr-2"
                                    type="submit" form="changePhoneEmail"
                                >
                                    Simpan
                                </Button>
                            </Col>
                        </Row>
                    </ComponentCircleLoader>
                </Modal.Body>
            </Modal>
        </>
    );
};

export interface UpdateBasicAccountModalProps {
    isShow: boolean;
    onCloseHandler: () => void;
    data: DataType;
}

export default UpdateBasicAccountModal;
