import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../../..";
import { checkEmptyObject } from "../../../../helpers/checkEmptyObject";
import FileUploadApi from "../../../media/fileUpload/fileUpload.api";
import { CreateProfessionalTypeModel, ProfessionalTypeModel } from "./models";
import ProfessionalTypeApi from "./professionalType.api";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";

export interface CreateProfessionalTypeSlice {
   name?: string;
   list?: ProfessionalTypeModel[];
   single?: ProfessionalTypeModel;
   isLoading?: boolean;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
}

export const createProfessionalType = createAsyncThunk(
   "createProfessionalTypeState/createProfessionalType",
   async (professionalType: CreateProfessionalTypeModel, { rejectWithValue }) => {
      try {
         const response = await FileUploadApi.uploadImage({
            file_name: new Date().toString() + "." + professionalType.image.fileFormat,
            base64url: (professionalType.image.fileValue as string).split(",")[1],
            imagepath: "professional-type",
            location: "professional-type",
         });
         if (response.status === 200) {
            let formData = professionalType;
            formData.iconURL = response.data.imagepath;
            return await ProfessionalTypeApi.createProfessionalType(formData);
         }
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getProfessionalTypeList = createAsyncThunk(
   "professionalTypeState/professionalTypeList",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await ProfessionalTypeApi.getProfessionalTypeList();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getProfessionalTypeSingleItem = createAsyncThunk(
   "professionalTypeState/professionalTypeSingleItem",
   async (ProfessionalTypeId: string, { rejectWithValue }) => {
      try {
         return await ProfessionalTypeApi.getSingleItem(ProfessionalTypeId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const updateProfessionalType = createAsyncThunk(
   "createProfessionalTypeState/updateProfessionalType",
   async (professionalType: CreateProfessionalTypeModel, { rejectWithValue }) => {
      try {
         if (professionalType.image.fromApi) {
            return await ProfessionalTypeApi.updateProfessionalType(professionalType);
         } else {
            const response = await FileUploadApi.uploadImage({
               file_name: new Date().toString() + "." + professionalType.image.fileFormat,
               base64url: (professionalType.image.fileValue as string).split(",")[1],
               imagepath: "professional-type",
               location: "professional-type",
            });
            if (response.status === 200) {
               let formData = professionalType;
               formData.iconURL = response.data.imagepath;
               return await ProfessionalTypeApi.updateProfessionalType(formData);
            }
         }
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const deleteProfessionalType = createAsyncThunk(
   "professionalTypeState/deleteProfessionalType",
   async (professionalTypeModel: ProfessionalTypeModel, { rejectWithValue }) => {
      try {
         return await ProfessionalTypeApi.deleteProfessionalType(professionalTypeModel);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const createProfessionalTypeSlice = createSlice({
   name: "createProfessionalTypeState",
   initialState: {} as CreateProfessionalTypeSlice,
   reducers: {
      setFilter: (state, action) => {
         state.list = action.payload;
      },
      resetSingle: (state) => {
         state.single = {} as ProfessionalTypeModel;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(createProfessionalType.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(createProfessionalType.fulfilled, (state, { payload }) => {
         state.name = new Date().toISOString();
         state.isLoading = false;
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(createProfessionalType.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(getProfessionalTypeSingleItem.pending, (state) => {
         state.single = undefined;
         state.isLoading = true;
      });
      builder.addCase(getProfessionalTypeSingleItem.fulfilled, (state, { payload }) => {
         state.single = payload;
         state.isLoading = false;
      });
      builder.addCase(getProfessionalTypeSingleItem.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(getProfessionalTypeList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getProfessionalTypeList.fulfilled, (state, { payload }) => {
         state.list = payload;
         state.isLoading = false;
      });
      builder.addCase(getProfessionalTypeList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(updateProfessionalType.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(updateProfessionalType.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.name = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(updateProfessionalType.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(deleteProfessionalType.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(deleteProfessionalType.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.name = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(deleteProfessionalType.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
   },
});

export const { setFilter, resetSingle, resetStatus } = createProfessionalTypeSlice.actions;
export default createProfessionalTypeSlice.reducer;
