import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import BlogCategoryForm from "../components/BlogCategoryForm";

import {
  createBlogCategory,
  getBlogCategorySingleItem,
  updateBlogCategory,
  resetStatus,
} from "../blogCategory.reducer";
import { BlogCategoryModel } from "../models";
import {useHistory, useParams} from "react-router-dom";
import { RootState } from "../../../../app/store";
import {ApiErrorResponse} from "../../../../index";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const BlogCategoryDetailPage = () => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();
  let history = useHistory();

  const [isEdit, setIsEdit] = useState(false);

  const singleDataState = useSelector(
    (state: RootState) => state.blogCategory.single ?? ({} as BlogCategoryModel)
  );
  const status = useSelector((state: RootState) => state.blogCategory.status);
  const error = useSelector((state: RootState) => state.blogCategory.error ?? ({} as ApiErrorResponse<any>));

  useEffect(() => {
    if (status !== undefined && status !== ApiResponseStatus.pending) {
      MySwal.fire(
          savedStatusOption(
              status === ApiResponseStatus.success,
              error.data?.message
          )
      ).then(() => {
        dispatch(resetStatus());
        if (status === ApiResponseStatus.success) {
          history.push("/master/blog-category/list");
        }
      });
    }
  }, [status, error]);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getBlogCategorySingleItem(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = (name: string, description: string) => {
    if (isEdit) {
      dispatch(
        updateBlogCategory({
          id: id,
          name,
          description,
        } as BlogCategoryModel)
      );
    } else {
      dispatch(
        createBlogCategory({
          name,
          description,
        } as BlogCategoryModel)
      );
    }
  };

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <h4 className="card-label">
            {isEdit ? "Edit" : "Tambah"} - Master Kategori Blog
          </h4>
          <hr />
          <Row>
            <Col md={8} xl={5}>
              <BlogCategoryForm
                onSubmit={onSubmit}
                data={singleDataState}
                isEdit={isEdit}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default BlogCategoryDetailPage;
