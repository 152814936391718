export enum MessageType {
   text,
   image,
   file,
   album,
   info,
   video,
}

export enum AttachmentType {
   video,
   image,
   file,
   album,
}

export interface UserModel {
   name: string;
   profileImage: string;
   unread: number;
   userId: string;
   role: string;
}

export interface RoomModel {
   id: string;
   lastMessage: string;
   lastMessageType: MessageType;
   lastTimeMessage: string;
   roomName: string;
   isSingleChat: boolean;
   projectId: string;
   userId: string[];
   users: UserModel[];
}

export interface CreateRoom {
   userId: string;
   destinationId: string;
   name: string;
   destinationName: string;
}

export interface GroupTextMessageModel {
   content: string;
   createdAt: string;
   readById: string[];
   senderId: string;
   senderName: string;
   messageType: MessageType;
   isImageGrouping?: boolean;
}

export interface TextMessageModel {
   content: string;
   createdAt: string;
   readById: string[];
   senderId: string;
   senderName: string;
   messageType: MessageType;
   isImageGrouping?: boolean;
   isGroupedChat?: boolean;
   groupedChat?: GroupTextMessageModel[];
}

export interface MessageModel {
   content: string;
   fileType: string;
   fileName: string;
   albumName: string;
   createdAt: string;
   readById: string[];
   senderId: string;
   senderName: string;
   totalLastAdded: number;
   messageType: MessageType;
   isGroupedChat?: boolean;
   groupedChat?: GroupTextMessageModel[];
}

export interface GroupMessageModel {
   date: string;
   messages: MessageModel[];
}
export interface FileMessageModel {
   content: string;
   fileType: string;
   fileName: string;
   createdAt: string;
   readById: string[];
   senderId: string;
   senderName: string;
   messageType: MessageType;
}

export interface AlbumMessageModel {
   content: string;
   albumName: string;
   totalLastAdded: number;
   createdAt: string;
   readById: string[];
   senderId: string;
   senderName: string;
   messageType: MessageType;
}

export interface ChatAttachmentModel {
   id: string;
   roomId: string;
   userId: string;
   url: string;
   type: AttachmentType;
   album: string;
}

export interface AlbumModel {
   album: string;
   thumbnail: string;
   count: number;
}

export enum FileType {
   Video,
   File,
}
