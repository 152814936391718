import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import CityTable from "../components/CityTable";
import { getCityList, resetSingle } from "../city.reducer";
import { RootState } from "../../../../app/store";

const CityPage = () => {
  const dispatch = useDispatch();
  const statusState = useSelector((state: RootState) => state.city.name);

  useEffect(() => {
    dispatch(getCityList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusState]);

  useEffect(() => {
    dispatch(resetSingle());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <h4 className="card-label">Master Kota</h4>
          <CityTable />
        </Col>
      </Row>
    </Container>
  );
};

export default CityPage;
