import React from 'react';
import {Container, Row} from "react-bootstrap";
import DetailDataProject from "../components/DetailDataProject";
import DetailDataResult from "../components/DetailDataResult";

const DataProjectDetailPage = () => {
    return (
        <Container className="content-body" style={{backgroundColor: "#f9f9f8", height: "100vh"}} fluid>
            <Row>
                <DetailDataResult />
            </Row>
        </Container>
    );
};

export default DataProjectDetailPage;
