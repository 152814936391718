import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { LanguageModel } from "./models";
import LanguageApi from "./language.api";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";

export interface CreateLanguageSlice {
   name?: string;
   list?: LanguageModel[];
   single?: LanguageModel;
   isLoading?: boolean;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
}

export const createLanguage = createAsyncThunk(
   "createLanguageState/createLanguage",
   async (languageModel: LanguageModel, { rejectWithValue }) => {
      try {
         return await LanguageApi.createLanguage(languageModel);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getLanguageList = createAsyncThunk(
   "languageState/languageList",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await LanguageApi.getLanguageList();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getLanguageSingleItem = createAsyncThunk(
   "languageState/languageSingleItem",
   async (LanguageId: string, { rejectWithValue }) => {
      try {
         return await LanguageApi.getSingleItem(LanguageId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const updateLanguage = createAsyncThunk(
   "languageState/updateLanguage",
   async (languageModel: LanguageModel, { rejectWithValue }) => {
      try {
         return await LanguageApi.updateLanguage(languageModel);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const deleteLanguage = createAsyncThunk(
   "languageState/deleteLanguage",
   async (languageModel: LanguageModel, { rejectWithValue }) => {
      try {
         return await LanguageApi.deleteLanguage(languageModel);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const createLanguageSlice = createSlice({
   name: "createLanguageState",
   initialState: {} as CreateLanguageSlice,
   reducers: {
      setFilter: (state, action) => {
         state.list = action.payload;
      },
      resetSingle: (state) => {
         state.single = {} as LanguageModel;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(createLanguage.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(createLanguage.fulfilled, (state) => {
         state.name = new Date().toISOString();
         state.isLoading = false;
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(createLanguage.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(getLanguageSingleItem.pending, (state) => {
         state.single = undefined;
         state.isLoading = true;
      });
      builder.addCase(getLanguageSingleItem.fulfilled, (state, { payload }) => {
         state.single = payload;
         state.isLoading = false;
      });
      builder.addCase(getLanguageSingleItem.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(getLanguageList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getLanguageList.fulfilled, (state, { payload }) => {
         state.list = payload;
         state.isLoading = false;
      });
      builder.addCase(getLanguageList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(updateLanguage.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(updateLanguage.fulfilled, (state) => {
         state.isLoading = false;
         state.name = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(updateLanguage.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(deleteLanguage.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(deleteLanguage.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.name = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(deleteLanguage.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
   },
});

export const { setFilter, resetSingle, resetStatus } = createLanguageSlice.actions;
export default createLanguageSlice.reducer;
