import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {useHistory} from "react-router-dom";
import {CurrentBalance, UserBalance, UserType} from "../models";
import DataTable, {TableColumn} from "react-data-table-component";
import DefaultSubHeader from "../../../components/DataTable/DefaultSubHeader";
import {TableCustomStyles} from "../../../components/DataTable/TableCustomStyles";
import {ElementPropModel} from "../../../components/DataTable/models";
import {getList} from "../userBalance.reducer";
import NumberFormat from "react-number-format";

const UserBalanceTable = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const balanceState = useSelector((state: RootState) => state.userBalance.list ?? {} as CurrentBalance);

    // datatable components

    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = balanceState.list?.filter(
        (item) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    );

    const onSetterFilterText = (value: string) => {
        setFilterText(value);
    };

    const elementProps = {
        placeholder: "cari berdasarkan nama",
        buttonName: "",
        createUrl: "",
        subHeaderLine: true,
    } as ElementPropModel;

    const columns: TableColumn<UserBalance>[] = [
        {
            name: "NO.",
            width: "60px",
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: "NAMA",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "USERNAME",
            selector: (row) => row.username,
            sortable: true,
        },
        {
            name: "TOTAL SALDO (Rp)",
            cell: (data: UserBalance) => <Fragment>
                    <NumberFormat
                        value={data.value}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                    />
                </Fragment>,
            sortable: true,
        },
        {
            name: "TIPE AKUN",
            cell: (data: UserBalance) => <Fragment>
                {data.type === UserType.Mitra && <span>Mitra</span>}
                {data.type === UserType.Customer && <span>Customer</span>}
            </Fragment>,
            sortable: true,
        },
        // {
        //    name: "AKSI",
        //    selector: (d: UserModel) => (
        //       <>
        //          <button className="btn button-primary mr-2" onClick={() => onDetailClicked(d)}>
        //             DETAIL
        //          </button>
        //
        //          <button
        //             onClick={() => {
        //                if (window.confirm("Apakah kamu yakin akan mengubah status akun ini?"))
        //                   onChangeStatus(d.id, d.isActive);
        //             }}
        //             className={`btn ${d.isActive ? "button-danger" : "btn-success"} `}>
        //             {d.isActive ? "NON AKTIFKAN" : "AKTIFKAN"}
        //          </button>
        //       </>
        //    ),
        //    width: "400px",
        // },
    ];

    // end of datatable components

    useEffect(() => {
        dispatch(getList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                noHeader
                subHeader
                selectableRows
                subHeaderComponent={DefaultSubHeader(
                    filterText,
                    resetPaginationToggle,
                    onSetterFilterText,
                    elementProps
                )}
                customStyles={TableCustomStyles}
                persistTableHead
            />
        </>
    );
};

export default UserBalanceTable;
