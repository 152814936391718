import React from "react";
import { Modal, Form, Image } from "react-bootstrap";
import {MitraCancelProjectModel} from "../models";
import {getMediaEndpoint} from "../../../helpers/imageManipulation";

const MitraCancelProjectModal = ({
   show = false,
    data = {} as MitraCancelProjectModel,
   ...props
}: RequestCancelProjectModalProps) => {

   return (
      <Modal
         show={show}
         onHide={props.handleClose}
         size="lg"
         aria-labelledby="contained-modal-title-vcenter"
         centered>
         <Modal.Header closeButton>
            <Modal.Title className="h6" >Detail Pembatalan Mitra</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <Form.Group className="mb-4">
               <Form.Label>Nama Project</Form.Label>
               <Form.Control plaintext readOnly value={data.projectName} />
            </Form.Group>
            <Form.Group>
               <Form.Label>Alasan</Form.Label>
               <Form.Control as="textarea" rows={3} value={data.reason} readOnly/>
            </Form.Group>
            {/*<Form.Group>*/}
            {/*   <Form.Label>Foto</Form.Label><br/>*/}
            {/*   <Image src={getMediaEndpoint(data.photo)} style={{width: "100%"}} />*/}
            {/*</Form.Group>*/}
         </Modal.Body>
      </Modal>
   );
};

export interface RequestCancelProjectModalProps {
   show: boolean;
   data: MitraCancelProjectModel;
   handleClose: () => void;
}

export default MitraCancelProjectModal;
