import React, {Fragment, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {
  deleteStoreProduct,
  getStoreProductList,
  resetStatus,
} from "../storeProduct.reducer";
import { Link } from "react-router-dom";
import DefaultSubHeader from "../../../../components/DataTable/DefaultSubHeader";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import DataTable, {TableColumn} from "react-data-table-component";
import { ElementPropModel } from "../../../../components/DataTable/models";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiErrorResponse} from "../../../../index";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {deletedStatusOption, deletedWarnOption} from "../../../../helpers/alertContent";
import {StoreProductModel, StoreProductRequest, StoreProductTypeEnum} from "../models";
import NumberFormat from "react-number-format";

const StoreProductTable = () => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();

  const [filterText, setFilterText] = useState("");

  const storeProductList = useSelector(
    (state: RootState) => state.storeProduct.list ?? []
  );
  const status = useSelector((state: RootState) => state.storeProduct.status);
  const error = useSelector((state: RootState) => state.storeProduct.error ?? ({} as ApiErrorResponse<any>));
  const isGetLoading = useSelector((state: RootState) => state.storeProduct.isGetLoading);

  const filteredItems = storeProductList.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    dispatch(getStoreProductList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status !== undefined && status !== ApiResponseStatus.pending) {
      MySwal.fire(deletedStatusOption(status === ApiResponseStatus.success, error.data?.message)).then(() => {
        dispatch(resetStatus());
        dispatch(getStoreProductList());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error]);

  const onSetterFilterText = (value: string) => {
    setFilterText(value);
  };

  const onDelete = (id: string) => {
    MySwal.fire(deletedWarnOption).then((result) => {
      if (result.isConfirmed) {
        dispatch(
            deleteStoreProduct({
              id: id,
              name: '',
              type: StoreProductTypeEnum.Product,
              price: 0,
              link: '',
              location: '',
              description: '',
              path: '',
            } as StoreProductRequest)
        );
      }
    });
  };

  const elementProps = {
    placeholder: "cari berdasarkan nama",
    buttonName: "tambah produk",
    createUrl: "/store/product/create",
    subHeaderLine: true,
  } as ElementPropModel;

  const columns: TableColumn<StoreProductModel>[] = [
    {
      name: "NO.",
      width: "60px",
      cell: (_d: any, index: number) => index + 1,
    },
    {
      name: "NAMA",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "HARGA",
      selector: (row) => row.price,
      format: (data: StoreProductModel) => <Fragment>
        <NumberFormat
            value={data.price}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
        />
      </Fragment>,
      sortable: true,
    },
    {
      name: "JENIS",
      selector: (row) => row.type,
      format: (data: StoreProductModel) => <Fragment>
        {data.type === StoreProductTypeEnum.Product && <span>BARANG</span>}
        {data.type === StoreProductTypeEnum.Class && <span>KELAS</span>}
      </Fragment>,
      sortable: true,
    },
    {
      name: "AKSI",
      cell: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/store/product/edit/${d.id}`,
            }}
          >
            <button className="btn button-primary mr-2">EDIT</button>
          </Link>
          <button
            onClick={() => onDelete(d.id)}
            className="btn button-danger"
          >
            HAPUS
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={false}
        noHeader
        subHeader
        subHeaderComponent={DefaultSubHeader(
          filterText,
          false,
          onSetterFilterText,
          elementProps
        )}
        customStyles={TableCustomStyles}
        persistTableHead
        progressPending={isGetLoading}
      />
    </>
  );
};

export default StoreProductTable;
