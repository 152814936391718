export interface WithdrawModel {
   id: string;
   vendorId: string;
   vendorName: string;
   customerId: string;
   customerName: string;
   professionalId: string;
   professionalName: string;
   value: number;
   createdDate: string;
   createdDateFormatted: string;
   approveDate: string;
   approveDateFormatted: string;
   status: number;
   accountBankNo: string;
   bankName: string;
   ownerName: string;
}

export enum WithdrawSaldoStatusEnum {
   request,
   approve,
   reject,
}

export interface ChangeWithdrawStatus {
   withdrawSaldoId: string;
   status: WithdrawSaldoStatusEnum;
}
