import React from "react";
import LoginForm from "../components/LoginForm";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { Redirect } from "react-router-dom";

const AdminLoginPage = () => {
  const userState = useSelector((state: RootState) => state.user);
  if (userState.token) {
    return <Redirect to="/dashboard" />;
  }
  return <LoginForm />;
};

export default AdminLoginPage;
