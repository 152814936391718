export interface MitraPointModel {
    id: string;
    tier: PointTierEnum;
    totalTransaction: number;
    totalNominal: number;
}

export enum PointTierEnum {
    Verified,
    Experienced,
    Professional,
    Award
}