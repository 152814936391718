import React, {useEffect, useState} from 'react';
import DataTable, {TableColumn} from "react-data-table-component";
import {TableCustomStyles} from "../../../components/DataTable/TableCustomStyles";
import {MAX_PAGING_OPTIONS} from "../../../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {deletePortfolio, getProSquadPortfolioList, reset} from "../proSquadPortfolio.reducer";
import {RootState} from "../../../app/store";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {deletedStatusOption} from "../../../helpers/alertContent";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiErrorResponse} from "../../../index";
import {ProSquadPortfolio} from "../models";
import moment from "moment/moment";

const ProSquadPortfolioTable = () => {
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);

    const [filterText, setFilterText] = useState("");
    const [filteredPortfolio, setFilteredPortfolio] = useState<ProSquadPortfolio[]>([]);

    const portfolioList = useSelector((state: RootState) => state.proSquadPortfolio.list ?? []);
    const deleteStatus = useSelector((state: RootState) => state.proSquadPortfolio.deleteStatus);
    const error = useSelector((state: RootState) => state.proSquadPortfolio.error ?? ({} as ApiErrorResponse<any>));

    useEffect(() => {
        dispatch(getProSquadPortfolioList());
    }, []);

    useEffect(() => {
        if (deleteStatus !== undefined && deleteStatus !== ApiResponseStatus.pending) {
            MySwal.fire(deletedStatusOption(deleteStatus === ApiResponseStatus.success, error.data?.message)).then(() => {
                dispatch(getProSquadPortfolioList());
                dispatch(reset());
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteStatus, error]);

    useEffect(() => {
        setFilteredPortfolio(portfolioList);
    }, [portfolioList]);

    const onSearchChange = (e: any) => {
        let filterValue = e.target.value;
        setFilterText(filterValue);
        setFilteredPortfolio(portfolioList.filter((portfolio) => portfolio.name?.toLowerCase().includes(filterValue.toLowerCase())));
        // clearTimeout(timer);
        // setTimer(
        //     setTimeout((value = filterValue) => {
        //
        //     }, 1000)
        // );
    }

    const onDeleteClicked = (row: ProSquadPortfolio) => {
        MySwal.fire({
            text: `Apakah kamu yakin akan menghapus portfolio ini?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deletePortfolio({...row, expertiseList: []}));
            }
        });
    }

    const columns: TableColumn<ProSquadPortfolio>[] = [
        {
            name: "NOMOR",
            width: "60px",
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: "NAMA PORTFOLIO",
            selector: (row) => row.name ?? "-",
            sortable: true,
        },
        {
            name: "TANGGAL DIBUAT",
            selector: (row) => row.createdDate ?? "",
            format: (row: any) => moment(row.createdDate).format("DD/MM/YYYY"),
            sortable: true,
        },
        {
            name: "NAMA MITRA",
            selector: (row) => row.mitraName ?? "-",
            sortable: true,
        },
        {
            name: "AKSI",
            width: "400px",
            cell: (row) => (
                <>
                    <Link
                        to={{
                            pathname: `/mitra/portfolio/detail/${row.id}`,
                        }}
                    >
                        <button className="btn button-primary mx-1">EDIT</button>
                    </Link>
                    <button className="btn button-danger" onClick={() => onDeleteClicked(row)}>
                        HAPUS
                    </button>
                </>
            )
        }
    ]

    return (
        <div>
            <div className="d-flex" style={{
                borderBottom: "1px solid #a0a0a0",
            }}>
                <div style={{width: "300px", paddingBottom: "15px"}}>
                    <div>
                        <div className="d-flex">
                            <div className="input-field-group">
                                <FontAwesomeIcon
                                    className="input-field-absolut-icon"
                                    icon={faSearch}
                                />
                                <input
                                    id="search"
                                    type="search"
                                    placeholder={"Filter berdasarkan nama"}
                                    className="input-field-group-text"
                                    style={{width: "18rem"}}

                                    value={filterText}
                                    onChange={onSearchChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-grow-1 d-flex justify-content-end align-items-end mb-3">
                    <div className="d-inline">
                        <Link
                            to={{
                                pathname: `/mitra/portfolio/create`,
                            }}
                        >
                            <button className="btn button-primary mx-1">Buat Portfolio</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={"my-3"}>
                <DataTable
                    columns={columns}
                    data={filteredPortfolio}
                    pagination
                    // paginationServer
                    // paginationTotalRows={eventRows}
                    // onChangeRowsPerPage={handlePerRowsChange}
                    // onChangePage={handlePageChange}
                    noHeader
                    customStyles={TableCustomStyles}
                    progressPending={false}
                    persistTableHead
                    paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
                />
            </div>
        </div>
    );
};

export default ProSquadPortfolioTable;
