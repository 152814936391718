import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {
  getSocialMediaTypeList,
  deleteSocialMediaType,
  resetStatus,
} from "../socialMediaType.reducer";
import { Link } from "react-router-dom";
import { SocialMediaTypeModel } from "../models";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import DefaultSubHeader from "../../../../components/DataTable/DefaultSubHeader";
import DataTable, {TableColumn} from "react-data-table-component";
import { ElementPropModel } from "../../../../components/DataTable/models";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiErrorResponse} from "../../../../index";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {deletedStatusOption, deletedWarnOption} from "../../../../helpers/alertContent";

const SocialMediaTypeTable = () => {
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const socialMediaTypeState = useSelector(
    (state: RootState) => state.socialMediaType.list ?? []
  );
  const status = useSelector((state: RootState) => state.socialMediaType.status);
  const error = useSelector((state: RootState) => state.socialMediaType.error ?? ({} as ApiErrorResponse<any>));

  const filteredItems = socialMediaTypeState.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.link && item.link.toLowerCase().includes(filterText.toLowerCase()))
  );

  useEffect(() => {
    dispatch(getSocialMediaTypeList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status !== undefined && status !== ApiResponseStatus.pending) {
      MySwal.fire(deletedStatusOption(status === ApiResponseStatus.success, error.data?.message)).then(() => {
        dispatch(resetStatus());
        dispatch(getSocialMediaTypeList());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error]);

  const onSetterFilterText = (value: string) => {
    setFilterText(value);
  };

  const onDelete = (id: string) => {
    MySwal.fire(deletedWarnOption).then((result) => {
      if (result.isConfirmed) {
        dispatch(
            deleteSocialMediaType({
              id: id,
              name: "deleted",
              description: "deleted",
              link: "deleted",
            } as SocialMediaTypeModel)
        );
      }
    });
  };

  const elementProps = {
    placeholder: "cari berdasarkan nama / tautan",
    buttonName: "tambah sosial media",
    createUrl: "/master/socialmedia/create",
    subHeaderLine: true,
  } as ElementPropModel;

  const columns: TableColumn<SocialMediaTypeModel>[] = [
    {
      name: "NO.",
      width: "60px",
      cell: (_d: any, index: number) => index + 1,
    },
    {
      name: "NAMA SOSIAL MEDIA",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "DESKRIPSI",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "TAUTAN",
      selector: (row) => row.link,
      sortable: true,
    },
    {
      name: "AKSI",
      cell: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/master/socialmedia/edit/${d.id}`,
            }}
          >
            <button className="btn button-primary mr-2">Edit</button>
          </Link>
          <button
            onClick={() => onDelete(d.id)}
            className="btn button-danger"
          >
            Hapus
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        noHeader
        subHeader
        subHeaderComponent={DefaultSubHeader(
          filterText,
          resetPaginationToggle,
          onSetterFilterText,
          elementProps
        )}
        customStyles={TableCustomStyles}
        persistTableHead
      />
    </>
  );
};

export default SocialMediaTypeTable;
