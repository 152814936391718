import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import DataDiriTable from "../components/DataMitraTable";
import {getUserList, resetSingleUser} from "../dataMitra.reducer";
import {Link, useHistory} from "react-router-dom";
import { RootState } from "../../../../app/store";
import { resetPortfolio } from "../../portfolio/portfolio.reducer";
import { setEmptyDataDiri } from "../../dataDiri/dataDiri.reducer";
import {RegistrationStatus, UserTypeEnum} from "../models";

const DataMitraPage = () => {
   const dispatch = useDispatch();
   const statusState = useSelector((state: RootState) => state.dataDiri.name);
   const listDataState = useSelector((state: RootState) => state.dataDiri.userList);
   const history = useHistory();

   const onFilterSubmit = (name: string) => {
      // const dataFilter = listDataState ?? [];
      // const filteredData = dataFilter.filter((find: { name: string }) =>
      //   find.name.includes(name)
      // );
      // dispatch(setFilter(filteredData));
   };

   const onCreateClicked = () => {
      history.push("/mitra/account/personal-data/create-official-partner")
   }

   useEffect(() => {
      dispatch(resetPortfolio());
      dispatch(setEmptyDataDiri());
      dispatch(resetSingleUser());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      dispatch(getUserList({
         userType: UserTypeEnum.Mitra,
         registerStatus: undefined,
         skip: 0,
         take: 10,
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [statusState]);

   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <div>
               <h4 className="card-label d-inline">Data Akun Mitra</h4>
               <button className="float-right btn button-primary" onClick={onCreateClicked}>Buat Offical Partner</button>
               </div>
               <DataDiriTable />
            </Col>
         </Row>
      </Container>
   );
};

export default DataMitraPage;
