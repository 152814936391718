import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { Link } from "react-router-dom";
import { UserModel} from "../models";
import DataTable, {TableColumn} from "react-data-table-component";
import DefaultSubHeader from "../../../../components/DataTable/DefaultSubHeader";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { ElementPropModel } from "../../../../components/DataTable/models";
import {changeSuspendedStatus, getUserList, resetStatus} from "../dataCustomer.reducer";
import {inActiveAdmin, resetStatus as resetAdminStatus} from "../../../dataAdmin/dataAdmin.reducer";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";
import {ApiErrorResponse} from "../../../../index";
import {UserTypeEnum} from "../../../dataMitra/dataMitra/models";
import {MAX_PAGING_OPTIONS} from "../../../../constants";

const DataCustomerTable = () => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const customerState = useSelector(
    (state: RootState) => state.dataCustomer.userList ?? []
  );
    const rows = useSelector(
        (state: RootState) => state.dataCustomer.rows
    );
    const isLoading = useSelector(
        (state: RootState) => state.dataCustomer.isLoading
    );
    const suspendStatus = useSelector(
        (state: RootState) => state.dataCustomer.status
    );
    const suspendError = useSelector((state: RootState) => state.dataCustomer.error ?? ({} as ApiErrorResponse<any>));
    const isActiveStatus = useSelector(
        (state: RootState) => state.dataAdmin.status
    );
    const isActiveError = useSelector((state: RootState) => state.dataAdmin.error ?? ({} as ApiErrorResponse<any>));
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [timer, setTimer] = useState(setTimeout(() => {}, 1000));

  // datatable components

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {
        if (suspendStatus !== undefined && suspendStatus !== ApiResponseStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    suspendStatus === ApiResponseStatus.success,
                    suspendError.data?.message
                )
            ).then(() => {
                dispatch(resetStatus());
                if (suspendStatus === ApiResponseStatus.success) {
                    dispatch(getUserList({
                        userType: UserTypeEnum.Customer,
                        registerStatus: undefined,
                        name: filterText === "" ? undefined : filterText,
                        skip: 0,
                        take: rowsPerPage,
                    }));
                }
            });
        }
    }, [suspendStatus, suspendError, rowsPerPage, filterText]);

    useEffect(() => {
        if (isActiveStatus !== undefined && isActiveStatus !== ApiResponseStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    isActiveStatus === ApiResponseStatus.success,
                    isActiveError.data?.message
                )
            ).then(() => {
                dispatch(resetAdminStatus());
                if (isActiveStatus === ApiResponseStatus.success) {
                    dispatch(getUserList({
                        userType: UserTypeEnum.Customer,
                        registerStatus: undefined,
                        name: filterText === "" ? undefined : filterText,
                        skip: 0,
                        take: rowsPerPage,
                    }));
                }
            });
        }
    }, [isActiveStatus, isActiveError, rowsPerPage, filterText]);

  const onSetterFilterText = (value: string) => {
      let filterValue = value;
      setFilterText(filterValue);
      clearTimeout(timer);
      setTimer(
          setTimeout((value = filterValue) => {
              dispatch(getUserList({
                  userType: UserTypeEnum.Customer,
                  registerStatus: undefined,
                  name: value === "" ? undefined : value,
                  skip: 0,
                  take: rowsPerPage,
              }));
          }, 1000)
      );
  };

  const elementProps = {
    placeholder: "cari berdasarkan nama",
    buttonName: "",
    createUrl: "",
    subHeaderLine: true,
  } as ElementPropModel;

  const onChangeStatus = (id: string, isActive: boolean) => {
      MySwal.fire({
          text: `Apakah kamu yakin akan mengubah status akun ini?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
      }).then((result) => {
          if (result.isConfirmed) {
              dispatch(
                  inActiveAdmin({
                      userId: id,
                      isActive: !isActive,
                  })
              );
          }
      });
  };

    const onChangeSuspended = (customerId: string, isSuspend: boolean) => {
        MySwal.fire({
            text: `Ubah penangguhan akun ini?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(
                    changeSuspendedStatus({
                        id: customerId,
                        isSuspended: !isSuspend,
                    })
                );
            }
        })
    };

  const columns: TableColumn<UserModel>[] = [
    {
      name: "NO.",
      width: "60px",
      cell: (_d: any, index: number) => index + 1,
    },
    {
      name: "NAMA",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "E-MAIL",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "AKSI",
      width: "400px",
      cell: (d: UserModel) => (
        <>
          <Link
            to={{
              pathname: `/customer/account/detail/${d.id}`,
            }}
          >
            <button className="btn button-primary mr-2">DETAIL</button>
          </Link>
          <button
              onClick={() => onChangeSuspended(d.customerId, d.isSuspended)}
              className={`btn mr-2 ${d.isSuspended ? "btn-success" : "button-danger" } `}>
              {d.isSuspended ? "DIIJINKAN" : "DITANGGUHKAN"}
          </button>
          <button
              onClick={() => onChangeStatus(d.id, d.isActive)}
              className={`btn ${d.isActive ? "button-danger" : "btn-success"} `}>
            {d.isActive ? "NON AKTIFKAN" : "AKTIFKAN"}
          </button>
        </>
      ),
    },
  ];

  // end of datatable components

  useEffect(() => {
    dispatch(getUserList({
        userType: UserTypeEnum.Customer,
        registerStatus: undefined,
        name: undefined,
        skip: 0,
        take: rowsPerPage,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setRowsPerPage(newPerPage);
        dispatch(getUserList({
            userType: UserTypeEnum.Customer,
            registerStatus: undefined,
            name: filterText === "" ? undefined : filterText,
            skip: newPerPage * (page - 1),
            take: newPerPage,
        }));
    };

    const handlePageChange = (page: number) => {
        dispatch(getUserList({
            userType: UserTypeEnum.Customer,
            registerStatus: undefined,
            name: filterText === "" ? undefined : filterText,
            skip: rowsPerPage * (page - 1),
            take: rowsPerPage,
        }));
    };


    return (
    <>
      <DataTable
        columns={columns}
        data={customerState}
        pagination
        paginationServer
        paginationTotalRows={rows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        noHeader
        subHeader
        subHeaderComponent={DefaultSubHeader(
          filterText,
          resetPaginationToggle,
          onSetterFilterText,
          elementProps
        )}
        customStyles={TableCustomStyles}
        progressPending={isLoading}
        persistTableHead
        paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
      />
    </>
  );
};

export default DataCustomerTable;
