export enum RequestDeleteEnum {
    request,
    approve,
    reject,
}

export enum UserTypeEnum
{
    Mitra,
    Customer,
    Admin
}

export interface RequestDeleteModel {
    id: string;
    createDate: string;
    createdDate: string;
    name: string;
    username: string;
    userId: string;
    countProject: number;
    totalSaldo: number;
    status: RequestDeleteEnum;
    type: UserTypeEnum;
}

export interface ApproveDeleteRequest {
    id: string;
    status: RequestDeleteEnum
}


