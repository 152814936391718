import React, {Fragment, useEffect, useState} from 'react';
import "../../../assets/styles/project.css";
import {Badge, Col, Container, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {
    ActivityDetailRequestStatus,
    ProjectDetailModel,
    ResultProjectModel,
    SingleDetailActivityModel,
    DetailAttachmentType
} from "../models";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faExternalLinkAlt, faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import {
    getActivityByProject, getListFinalFile,
    getResultByDetailActivity, getResultReview,
    getSingleDetail,
} from "../dataProject.reducer";
import {useParams} from "react-router-dom";
import {getMediaEndpoint} from "../../../helpers/imageManipulation";
import {addDefaultProfileImage} from "../../../helpers/defaultBrokenImage";
import {BaseTypeEnum} from "../../deleteMitraService/models";
import ProjectReviewModal from "./ProjectReviewModal";
import mediaIcon from "../../../assets/icons/project_result_media_icon.svg";
import DetailMediaModal from "./DetailMediaModal";

const DetailDataResult = () => {
    const dispatch = useDispatch();

    let {id} = useParams<{ id: string }>();

    const singleProject = useSelector((state: RootState) => state.project.singleItem ?? {} as ProjectDetailModel);
    const singleDetailActivity = useSelector((state: RootState) => state.project.singleActivity ?? {} as SingleDetailActivityModel);
    const projectResult = useSelector((state: RootState) => state.project.projectResult ?? {} as ResultProjectModel);
    const finalResult = useSelector((state: RootState) => state.project.finalResult ?? []);

    const [selectedFile, setSelectedFile] = useState<string[]>([]);
    const [isResultHasFinal, setIsResultHasFinal] = useState<boolean>(false);
    const [showReviewModal, setShowReviewModal] = useState<boolean>(false);
    const [showExternalLinkModal, setShowExternalLinkModal] =
        useState<boolean>(false);
    const [detailMediaIndex, setDetailMediaIndex] = useState<number>(0);
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);


    const isMobile = window.innerWidth <= 768;

    const isPaidProject = false;

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getResultByDetailActivity(id));
            dispatch(getSingleDetail(id));
        }
    }, [id]);

    useEffect(() => {
        if (projectResult.id !== undefined) {
            dispatch(getListFinalFile(projectResult.id));
            dispatch(getResultReview(projectResult.id));
        }
    }, [projectResult]);

    useEffect(() => {
        if (finalResult.length !== 0) {
            setIsResultHasFinal(true);
        } else {
            setIsResultHasFinal(false);
        }
    }, [finalResult]);


    // useEffect(() => {
    //     if (singleProject.id !== undefined) {
    //         console.log("singleProject.id", singleProject.id);
    //         dispatch(getActivityByProject(singleProject.id));
    //     }
    // }, [singleProject]);

    const refreshData = () => {
        dispatch(getActivityByProject(singleProject.id));
    };

    const onMediaDownload = () => {
        if (selectedFile.length !== 0) {
            // DownloadToZip(projectResult.packageProductName, selectedFile);
        } else {
            alert('Pilih minimal 1 media untuk di unduh');
        }
    };

    const toggleReviewModal = () => {
        setShowReviewModal((prevState) => !prevState);
    };

    const toggleExternalLinkModal = () => {
        setShowExternalLinkModal((prevState) => !prevState);
    };

    const onDetailMediaClick = (index: number) => {
        setDetailMediaIndex(index);
        setShowDetailModal(true);
    };

    const checkMediaFormat = (url: string) => {
        let fileFormat = url.substring(url.lastIndexOf('.') + 1, url.length);
        if (fileFormat === 'webm' || fileFormat === 'mp4') {
            return DetailAttachmentType.Video;
        } else {
            return DetailAttachmentType.Picture;
        }
    };

    const onFileIsAvailable = (fileId: string | undefined) => {
        return selectedFile.some((item) => item === fileId);
    };

    const onFileChange = (fileId: string | undefined) => {
        const isAvailable = onFileIsAvailable(fileId);
        if (isAvailable) {
            const removedAvailable = selectedFile.filter(
                (item) => item !== fileId
            );
            setSelectedFile(removedAvailable);
        } else {
            setSelectedFile((prevState) => [...prevState, fileId ?? '']);
        }
    };

    const toggleDetailModal = () => {
        setShowDetailModal((prevState) => !prevState);
    };

    return (
        <Container className={`create_project_container p-4 p-md-5`}>
            <Row>
                <Col md={12}>
                    <h1 className={`d-inline project_vendors_title`}>
                        {projectResult.packageProductName}
                    </h1>
                </Col>
                <Col md={12} className="mt-2">
                    <small className={`project_activity_small_label`}>
                        <FontAwesomeIcon
                            icon={faCalendarAlt}
                            width="12"
                            className="mr-2"
                        />{' '}
                        <Moment
                            date={new Date(projectResult.activityDate)}
                            format="DD-MMM-YYYY"
                        />
                        {', '}
                        {projectResult.startActivity}
                        {' - '}
                        {projectResult.endActivity}
                    </small>
                    <small className={`ml-3 project_activity_small_label`}>
                        {' '}
                        <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            width="12"
                            className="mr-2"
                        />
                        {projectResult.location}
                    </small>
                    <div className="d-inline-block ml-3">
                        <div
                            className={`d-inline-block mb-2 project_activity_subtitle`}
                        >
                            <div
                                className={`d-inline-block mr-1 project_activity_profile_image_frame`}
                            >
                                <img
                                    className={`project_activity_profile_image`}
                                    src={`${getMediaEndpoint(projectResult.mitraPicture, true)}`}
                                    onError={addDefaultProfileImage}
                                    alt="profile"
                                ></img>
                            </div>
                            <span>{projectResult.mitraName}</span>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12} className="mb-3 mt-5">
                    <div className={'d-flex'}>
                        <div className={"d-flex flex-grow-1"}>
                            <img
                                src={mediaIcon}
                                alt="project_calendar_icon"
                                className="d-inline-block"
                            />
                            <h2
                                className={`d-inline-block ml-2 detail_activity_title`}
                            >
                                Pratinjau Hasil Kerja
                            </h2>
                        </div>

                        <div className={'d-flex'}>
                            {projectResult.canUploadFile &&
                                <>
                                    {isResultHasFinal && (
                                        <Fragment>
                                            {singleDetailActivity.status ===
                                                ActivityDetailRequestStatus.Completed && (
                                                    <button
                                                        className="d-inline btn button-primary"
                                                        onClick={toggleReviewModal}
                                                    >
                                                        Lihat Penilaian
                                                    </button>
                                                )}
                                        </Fragment>
                                    )}
                                </>
                            }
                        </div>
                    </div>
                    <hr/>
                </Col>

                {isResultHasFinal && (
                    <Col md={12} className="pl-4 pr-4">
                        <div className={`d-flex result_album_content`}>
                            {finalResult.map((item, index) => (
                                <Fragment>
                                    {item.isExternalLink ? (
                                        <div
                                            className="m-1"
                                            onClick={toggleExternalLinkModal}
                                        >
                                            <div
                                                className="file-upload-frame"
                                                style={{minHeight: 0}}
                                            >
                                                <div>
                                                    <div
                                                        className={`text-center file_upload_label`}
                                                    >
                                                        <div>
                                                            <FontAwesomeIcon
                                                                icon={faExternalLinkAlt}
                                                                width="34"
                                                                className="file-upload-icon mb-3"
                                                            />
                                                            <br/>
                                                            <small>
                                                                {isResultHasFinal
                                                                    ? 'Lihat Link'
                                                                    : 'Add Link'}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div
                                                className={`m-1 position-relative result_media_item`}
                                                onClick={() => onDetailMediaClick(index)}
                                                style={
                                                    checkMediaFormat(item.url) ===
                                                    DetailAttachmentType.Picture
                                                        ? {
                                                            background: `url(${
                                                                getMediaEndpoint(item.url)
                                                            }?width=120&height=180)`,
                                                        }
                                                        : {}
                                                }
                                            >
                                                {checkMediaFormat(item.url) ===
                                                    DetailAttachmentType.Video && (
                                                        <video
                                                            id="playVideoPreview"
                                                            className={"result_media_item"}
                                                        >
                                                            <source
                                                                src={`${
                                                                    getMediaEndpoint(item.url)
                                                                }#t=2.4`}
                                                                type="video/mp4"
                                                            />
                                                        </video>
                                                    )}
                                                {item.isFinal && (
                                                    <Badge
                                                        pill
                                                        className={"selected_file_badge"}
                                                    >
                                                        Final
                                                    </Badge>
                                                )}
                                            </div>
                                            <div
                                                className={`position-absolute media-checkbox`}
                                                style={{top: 0}}
                                            >
                                                <input
                                                    type="checkbox"
                                                    className={`custom-control-input`}
                                                    id={'file' + item.id}
                                                    checked={onFileIsAvailable(
                                                        getMediaEndpoint(item.url)
                                                    )}
                                                    onChange={() =>
                                                        onFileChange(getMediaEndpoint(item.url))
                                                    }
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor={'file' + item.id}
                                                >
                                                    <div
                                                        className={"check_result_file"}
                                                    ></div>
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                </Fragment>
                            ))}
                        </div>
                    </Col>
                )}
            </Row>
            <ProjectReviewModal
                showModal={showReviewModal}
                onModalHide={toggleReviewModal}
                data={projectResult}
            />
            <DetailMediaModal
                showModal={showDetailModal}
                onModalHide={toggleDetailModal}
                detailMediaIndex={detailMediaIndex}
                resultId={projectResult.id}
                fileMedia={finalResult}
            />
        </Container>
    );
};

export default DetailDataResult;
