import React from "react";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import {convertBase64} from "../Base64";
import {ImageUploadModel} from "../../features/chat/models";
import {resizeImage} from "../imageResize";

const UploadImage = ({ selectedImage = {} as ImageUploadModel, ...props }: UploadImageProps) => {
   const handleAcceptedProfileFiles = (files: any[]) => {
      files.map((file) => {
         let _fileFormat = file.type.split("/")[1];
         let _name = file.name;
         let _size = file.size;
         convertBase64(file, (result: any) => {
            resizeImage(result, async (img) => {
               props.uploadImageResult({ fileFormat: _fileFormat, fileValue: img, name: _name, size: _size });
            });
         });
      });
   };

   return (
      <Dropzone onDrop={(acceptedFiles) => handleAcceptedProfileFiles(acceptedFiles)}>
         {({ getRootProps, getInputProps }) => (
            <div className="dropzone file-upload-frame w-100">
               <div className="dz-message needsclick" {...getRootProps()}>
                  <input {...getInputProps()} />

                  <div className="text-center file-upload-label">
                     <div>
                        {selectedImage.fileValue !== undefined && (
                           <>
                              <img
                                 src={selectedImage.fileValue}
                                 className="file-upload-image mb-3"
                              />
                              <br />
                           </>
                        )}
                        {selectedImage.fileValue === undefined && (
                           <>
                              <FontAwesomeIcon
                                 icon={faUpload}
                                 width="48"
                                 className="file-upload-icon mb-3"
                              />
                              <br />
                           </>
                        )}
                        <span>Upload Picture</span>
                     </div>
                  </div>
               </div>
            </div>
         )}
      </Dropzone>
   );
};

export interface UploadImageProps {
   selectedImage?: ImageUploadModel;
   uploadImageResult: (file: ImageUploadModel) => void;
}

export default UploadImage;
