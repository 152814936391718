import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import {
   CurrentBalance,
} from "./models";
import UserBalanceApi from "./userBalance.api";

export interface UserBalanceSlice {
   name?: string;
   list?: CurrentBalance;
   isLoading?: boolean;
   error?: ApiErrorResponse<any>;
   status?: string;
}

export const getList = createAsyncThunk(
   "dataAdminState/dataAdminList",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await UserBalanceApi.getList();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);


const userBalanceSlice = createSlice({
   name: "UserBalanceState",
   initialState: {} as UserBalanceSlice,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(getList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getList.fulfilled, (state, { payload }) => {
         state.list = payload.data;
         state.isLoading = false;
      });
      builder.addCase(getList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
   },
});

// export const { setSingle } = userBalanceSlice.actions;
export default userBalanceSlice.reducer;
