import axios from "axios";
import {endpoint} from "../../constants";
import {GetSaldoHistoryModel, SaldoType} from "./models";

export default class AdminBalanceApi {
    static getTotalSaldo() {
        return axios.get<any>(endpoint + "/api/ResultReview/GetTotalSaldo?adminId=e9a0b82b-4c79-4476-846b-aed4e64c3943").then((res) => res.data.data);
    }

    static getListSaldoHistory(req: GetSaldoHistoryModel) {
        return axios.get<any>(endpoint + "/api/ResultReview/GetListSaldoHistory",
            {
                params: {
                    adminId: req.mitraId,
                    type: req.saldoType === SaldoType.None ? "" : req.saldoType,
                    startDate: req.startDate,
                    endDate: req.endDate
                }
            }
            ).then((res) => res.data.data);
    }
}