import axios from "axios";
import { endpoint } from "../../../constants";
import {
  ChangePasswordAsyncRequest,
  ChangeSuspendedStatus,
  CreateOfficialPartnerModel, GetUserList,
  RevisionLogModel, UpdateEmailAndPhoneRequest
} from "./models";

export default class DataMitraApi {
  static getUserList(args: GetUserList) {
    return axios
      .get<any>(endpoint + "/api/User/GetAll", {
        params: args
      })
      .then((res) => res.data);
  }
  static getUserSingle(userId: string) {
    return axios
        .get<any>(endpoint + "/api/User/GetSingle", {
            params: {
                userId: userId
            }
        })
        .then((res) => res.data);
  }
  static getProfessionalSingle(userId: string) {
    return axios
      .post<any>(endpoint + "/api/Professional/GetSingleItem?userId=" + userId)
      .then((res) => res.data.data);
  }
  static getVendorSingle(userId: string) {
    return axios
      .post<any>(endpoint + "/api/Vendor/GetSingleItem?userId=" + userId)
      .then((res) => res.data.data);
  }
  static createRevisionLog(data: RevisionLogModel) {
    return axios
      .post<any>(endpoint + "/api/AdminRevisionLog/Create", data)
      .then((res) => res.data.data);
  }
  static registerOfficialPartner(data: CreateOfficialPartnerModel) {
    return axios
        .post<any>(endpoint + "/api/User/RegisterOfficialPartner", data)
        .then((res) => res.data.data);
  }
  static changeProfessionalSuspendedStatus(data: ChangeSuspendedStatus) {
    return axios.post<any>(endpoint + `/api/Professional/ChangeSuspendedStatus`, data).then((res) => res.data);
  }
  static changeVendorSuspendedStatus(data: ChangeSuspendedStatus) {
    return axios.post<any>(endpoint + `/api/Vendor/ChangeSuspendedStatus`, data).then((res) => res.data);
  }
  static changePasswordAsync(data: ChangePasswordAsyncRequest) {
    return axios.post<any>(endpoint + `/api/User/ChangePasswordAsync`, data).then((res) => res.data);
  }

  static updateEmailAndPhone(data: UpdateEmailAndPhoneRequest) {
    return axios.post<any>(endpoint + `/api/User/UpdateEmailAndPhone`, data).then((res) => res.data);
  }
}
