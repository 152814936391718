import React, { forwardRef, Fragment, useState } from 'react';
import { Row, Col, Dropdown, Badge, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClock, faEllipsisH, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import 'moment/locale/id';
import {ActivityDetailRequestStatus, ActivityModel, ProjectStatusEnum} from "../models";
import NumberFormat from "react-number-format";
import EmptyPortfolioImage from "../../../assets/images/empty_portfolio.svg";
import productPackageIcon from "../../../assets/icons/icon_package_product_and_package.svg";
import {getMediaEndpoint} from "../../../helpers/imageManipulation";
import {useHistory} from "react-router-dom";


const ActivityCard = ({
   activity = {} as ActivityModel,
   projectStatus = ProjectStatusEnum.None,
   expertiseId = '',
    projectId = '',
    isPaidProject = false,
}: ActivityCardProps) => {
   const [tempId, setTempId] = useState<string>('');
   const isMobile = false;

   const history = useHistory();


   const onAddClicked = () => {
      // router.push(
      //    '/project/project-vendor/add-vendor?expertiseId=' + expertiseId
      // );
   };

   type Props = {
      onClick: (e: any) => void;
   };

   type RefType = number;

   const onServiceClicked = (activityDetailId: string) => {
         history.push(`/project/result/${activityDetailId}`);
   };

   const CustomToggle = forwardRef<RefType, Props>(
      ({ children, onClick }, ref: any) => (
         <a
            href=""
            ref={ref}
            className={`project_vendors_dots_link`}
            onClick={(e) => {
               e.preventDefault();
               onClick(e);
            }}
         >
            {children}
         </a>
      )
   );

   const badgeStatus = (status: ActivityDetailRequestStatus) => (
      <Badge pill className={`feed-badge mb-0`}>
         {status === ActivityDetailRequestStatus.Pending &&
            'Menunggu Respon Mitra'}
         {status === ActivityDetailRequestStatus.Accept && 'Diterima Mitra'}
         {status === ActivityDetailRequestStatus.Reject && 'Ditolak Mitra'}
         {status === ActivityDetailRequestStatus.Completed && 'Selesai'}
         {status === ActivityDetailRequestStatus.Cancel && 'Dibatalkan'}
      </Badge>
   );

   const DropdownMenuActivity = (id: string, isPaid: boolean) => (
      <Dropdown className="d-inline float-right">
         <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <FontAwesomeIcon
               className={`project_vendors_dots`}
               icon={faEllipsisH}
               width="20"
            />
         </Dropdown.Toggle>
         <Dropdown.Menu alignRight>
            <Dropdown.Item onClick={() => onServiceClicked(id)}>
               <small>Lihat Hasil</small>
            </Dropdown.Item>
         </Dropdown.Menu>
      </Dropdown>
   );

   const onChatClicked = () => {
        // router.push(`/customer/chat?projectId=${projectId}`);
   }

   return (
      <Row className={`create_project_activity_card pt-2 pb-2 mb-4`}>
         <Col md={12}>
            {/*<h3 className={`mt-2 ${styles.project_vendors_title_activity}`}>*/}
            {/*   {activity.name}*/}
            {/*</h3>*/}
            <small className={`project_activity_small_label`}>
               <FontAwesomeIcon icon={faClock} width="12" className="mr-2" />{' '}
               Waktu Proyek : {activity.startActivity}
               {' - '}
               {activity.endActivity}
            </small>
            <p className={`project_activity_description`}>
               {activity.note}
            </p>
            <hr />
         </Col>

         <Col md={12} className="mb-3">
            <div className="d-md-flex">
               <div className="d-flex">
                  <img
                     src={productPackageIcon}
                     alt="project_calendar_icon"
                     className="d-inline-block"
                     width="32"
                  />
                  <div className="d-flex align-items-center">
                     <h2
                        className={`d-inline-block ml-2 mb-0 detail_activity_title`}
                     >
                        Mitra & Layanan
                     </h2>
                  </div>
               </div>

               {/*<div className="d-md-flex flex-grow-1 justify-content-end mt-3 mt-md-0">*/}
               {/*   {*/}
               {/*      isPaidProject && (*/}
               {/*           <button*/}
               {/*               onClick={onChatClicked}*/}
               {/*               className={`btn button-primary d-block d-md-inline-block mt-2 mt-md-0 mx-md-1 ${*/}
               {/*                   isMobile ? 'w-100' : 'w-auto'*/}
               {/*               }`}*/}
               {/*           >*/}
               {/*              <img*/}
               {/*                  src={chatIcon}*/}
               {/*                  alt="chat_icon"*/}
               {/*                  className="d-inline-block mr-2"*/}
               {/*                  width="18"*/}
               {/*              />*/}
               {/*              Chat*/}
               {/*           </button>*/}
               {/*       )*/}
               {/*   }*/}
               {/*</div>*/}
            </div>
         </Col>
         {activity.activityDetailList.length === 0 && (
            <Fragment>
               <Col md={12} className="text-center">
                  <Image
                     className={`justify-content-center empty_data_image`}
                     src={EmptyPortfolioImage}
                  />
               </Col>
               <Col md={12} className="text-center mt-4 mb-4">
                  <h6>
                     Customer belum menambahkan layanan apapun pada proyek ini
                  </h6>
               </Col>
            </Fragment>
         )}
         {activity.activityDetailList.map((detail) => (
            <Col md={12} key={detail.id}>
               <div
                  className={`mb-4 project_horizontal_card flex-column flex-md-row`}
                  onClick={() => onServiceClicked(detail.id)}
               >
                  <div className={`project_activity_thumbnail_image`}>
                     <div></div>
                     <img
                        src={getMediaEndpoint(detail.itemDetail.picture)}
                        alt="product_package"
                        // onError={addDefaultCardImage}
                        className={`project_activity_image`}
                     />
                     <div className="card-location">
                        <FontAwesomeIcon icon={faMapMarkerAlt} width="10px" />
                        <span>{detail.itemDetail.lokasi}</span>
                     </div>
                     {/* <div className="best-price">
                        <div className="icon-best-price">
                           <FontAwesomeIcon icon={faStar} width="10px" />
                        </div>
                        <span>4,5</span>
                     </div> */}
                  </div>

                  <div
                     className={`project_vendors_description ${
                        isMobile ? 'my-2' : 'ml-4'
                     }`}
                     style={{ flex: '1' }}
                  >
                     <div className="d-flex">
                        <div
                           className="flex-grow-1 text-truncate"
                           style={{ width: '80px' }}
                        >
                           <h4
                              className={`d-inline project_activity_title`}
                           >
                              {detail.itemDetail.name}{' '}
                              {detail.qty > 1 && (
                                 <span>({detail.qty} Qty)</span>
                              )}
                           </h4>
                        </div>

                        {isMobile && (
                           <div>
                              <div className="mb-2 ml-2">
                                 {badgeStatus(detail.status)}
                              </div>
                           </div>
                        )}

                        {/*{!isMobile && (*/}
                        {/*   <div className="ml-2">*/}
                        {/*      {DropdownMenuActivity(detail.id, detail.isPaid)}*/}
                        {/*   </div>*/}
                        {/*)}*/}
                     </div>

                     <div
                        className={`d-block project_activity_subtitle`}
                     >
                        <div
                           className={`d-flex align-items-center mb-2 mb-md-0`}
                        >
                           <div
                              className={`d-inline-block mr-1 project_activity_profile_image_frame`}
                           >
                              <img
                                 className={`project_activity_profile_image mt-0`}
                                 src={getMediaEndpoint(detail.itemDetail.profile)}
                                 // onError={addDefaultProfileImage}
                                 alt="profile"
                              ></img>
                           </div>

                           <div className="d-flex flex-grow-1">
                              <div
                                 className={`d-flex align-items-center flex-grow-1 ml-2 ml-md-0`}
                                 style={{ width: '80px' }}
                              >
                                 <span className="text-truncate">
                                    {detail.itemDetail.mitraName}
                                 </span>
                              </div>
                              {/*{isMobile && (*/}
                              {/*   <div className="ml-2">*/}
                              {/*      {DropdownMenuActivity(*/}
                              {/*         detail.id,*/}
                              {/*         detail.isPaid*/}
                              {/*      )}*/}
                              {/*   </div>*/}
                              {/*)}*/}
                              {!isMobile && (
                                 <div className={'d-flex align-items-center'}>
                                    <span
                                       className={`project_activiry_expertise w-100`}
                                    >
                                       {detail.itemDetail.expertiseName}
                                    </span>
                                    {badgeStatus(detail.status)}
                                 </div>
                              )}
                           </div>
                        </div>
                     </div>

                     <div className="d-block mb-2">
                        <h4
                           className={`d-inline project_activity_title`}
                        >
                           <NumberFormat
                              value={detail.itemDetail.price}
                              displayType={'text'}
                              prefix="Rp. "
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                           />
                           {` x ${detail.qty} = `}
                           <b className="font-weight-bolder">
                              <NumberFormat
                                 value={detail.itemDetail.price * detail.qty}
                                 displayType={'text'}
                                 prefix="Rp. "
                                 thousandSeparator={'.'}
                                 decimalSeparator={','}
                              />
                           </b>
                        </h4>
                     </div>

                     {/*   <div className="d-block">*/}
                     {/*   <h4*/}
                     {/*       className={`d-inline ${styles.project_activity_title}`}*/}
                     {/*   >*/}
                     {/*      <NumberFormat*/}
                     {/*          value={detail.itemDetail.price}*/}
                     {/*          displayType={"text"}*/}
                     {/*          prefix="Rp. "*/}
                     {/*          thousandSeparator={"."}*/}
                     {/*          decimalSeparator={","}*/}
                     {/*      />*/}
                     {/*      {` x ${detail.qty} = `}*/}
                     {/*      <NumberFormat*/}
                     {/*          value={detail.itemDetail.price * detail.qty}*/}
                     {/*          displayType={"text"}*/}
                     {/*          prefix="Rp. "*/}
                     {/*          thousandSeparator={"."}*/}
                     {/*          decimalSeparator={","}*/}
                     {/*      />*/}
                     {/*   </h4>*/}
                     {/*</div>*/}

                     {isMobile && (
                        <div>
                           <div
                              className={`d-flex align-items-center flex-grow-1`}
                           >
                              <span
                                 className={`project_activity_expertise`}
                              >
                                 {detail.itemDetail.expertiseName}
                              </span>
                           </div>
                        </div>
                     )}
                  </div>
               </div>
            </Col>
         ))}
      </Row>
   );
};

export interface ActivityCardProps {
    activity: ActivityModel;
    expertiseId: string;
    refreshData: () => void;
    projectStatus: ProjectStatusEnum;
    projectId?: string;
   isPaidProject?: boolean;
}

export default ActivityCard;
