import React, {useEffect, useState} from 'react';
import {Badge, Col, Form, OverlayTrigger, Popover, Row} from "react-bootstrap";
import {ErrorInputEvent, InputEvent, MarketingEventImageTypeEnum, MarketingEventModel} from "../../models";
import DatePicker from "react-datepicker";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import {getUserOptionList as getMitraOptionList} from "../../../dataMitra/dataMitra/dataMitra.reducer";
import {UserTypeEnum} from "../../../dataMitra/dataMitra/models";
import {OptionModel} from "../../../../@core/models/types";
import {useHistory} from "react-router-dom";
import FileDropzone from "../../../../components/FileUpload/FileDropzone";
import Select from "react-select";
import {
    deleteMarketingEvent,
    reset,
    updateMarketingEvent,
    uploadMarketingEventFile
} from "../../marketingEvent.reducer";
import {checkObjectIsNotEmpty} from "../../../../helpers/checkEmptyObject";
import {customDeletedWarnOption, deletedStatusOption} from "../../../../helpers/alertContent";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {ApiErrorResponse} from "../../../../index";
import {convertBase64} from "../../../../helpers/Base64";
import {removeUnnecessaryComma} from "../../../../helpers/convert";
import {getExpertiseChildCategoryList} from "../../../master/tagExpertise/tagExpertise.reducer";

const EventForm = ({isEdit = false}: EventFormProps) => {
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const history = useHistory();
    const specialChars = ['\\', '/', ':', '*', '?', '"', '<', '>', '|'];

    const singleEvent = useSelector((state: RootState) => state.marketingEvent.singleEvent ?? {} as MarketingEventModel);
    const status = useSelector((state: RootState) => state.marketingEvent.eventDeleteStatus);
    const error = useSelector((state: RootState) => state.marketingEvent.error ?? ({} as ApiErrorResponse<any>));

    const mitraUsernameOptions = useSelector((state: RootState) => state.dataMitra.usernameOptions ?? []);
    const isMitraOptionLoading = useSelector((state: RootState) => state.dataMitra.isLoading);
    const uploadStatus = useSelector((state: RootState) => state.marketingEvent.uploadStatus ?? []);
    const uploadProgresses = useSelector(
        (state: RootState) => state.marketingEvent.uploadProgress ?? []
    );
    const expertiseCategoryOptions = useSelector((state: RootState) => state.tagExpertise.expertiseCategoryOptions ?? []);
    const isExpertiseOptionLoading = useSelector((state: RootState) => state.tagExpertise.isLoading);

    const [validated, setValidated] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<InputEvent>({
        mitraUsername: [] as OptionModel[],
        expertiseList: [] as OptionModel[],
        openDate: new Date().toISOString(),
        closeDate: new Date().toISOString(),
    } as InputEvent);
    const [errors, setErrors] = useState<ErrorInputEvent>({
        name: '',
        openDate: '',
        closeDate: '',
        mitraUsername: '',
        expertiseList: ''
    });
    const [localFiles, setLocalFiles] = useState<Array<File>>([]);
    const [base64Local, setBase64Local] = useState<string>("");
    const [localWatermarkFiles, setLocalWatermarkFiles] = useState<Array<File>>([]);
    const [uploadedFiles, setUploadedFiles] = useState<Array<string>>([]);
    const [uploadedWatermarkFiles, setUploadedWatermarkFiles] = useState<Array<string>>([]);

    useEffect(() => {
        dispatch(getExpertiseChildCategoryList());
        dispatch(getMitraOptionList({
            userType: UserTypeEnum.Mitra,
            registerStatus: undefined,
            name: undefined,
            skip: 0,
            take: 100000
        }));
    }, []);

    useEffect(() => {
        if (isEdit && checkObjectIsNotEmpty(singleEvent)) {
            setInputValue({
                name: singleEvent.name,
                description: singleEvent.description,
                openDate: singleEvent.openDate,
                closeDate: singleEvent.closeDate,
                mitraUsername: singleEvent.mitraList?.split(",").map((item: string, index: number) => {
                    return {
                        value: item,
                        label: singleEvent.mitraListName?.split(",")[index]
                    }
                }),
                expertiseList: singleEvent.expertiseList ?  singleEvent.expertiseList?.split(",").map((item: string, index: number) => {
                    return {
                        value: item,
                        label: singleEvent.expertiseListName?.split(",")[index]
                    }
                }) : []
            });
            setUploadedFiles(singleEvent.imageUrl ? [singleEvent.imageUrl] : []);
            setUploadedWatermarkFiles(singleEvent.imageUrl ? [singleEvent.imageUrl] : []);
        }
    }, [singleEvent, isEdit]);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(deletedStatusOption(status === ApiResponseStatus.success, error.data?.message)).then(() => {
                dispatch(reset());
                history.push("/mitra/event/list");
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        const newErrors = { ...errors };
        const form = e.currentTarget;

        const containsSpecialChars = specialChars.some(char => inputValue.name?.includes(char));

        if (containsSpecialChars) {
            e.preventDefault();
            newErrors.name = `Nama tidak boleh mengandung spesial karakter \\ / : * ? " < > |`;
            setErrors(newErrors);
            return  null;
        }

        if (!form.checkValidity()) {
            e.stopPropagation();
        } else {
            e.preventDefault();
            const {mitraUsername, ...rest} = inputValue;
            const mitraIds = inputValue.mitraUsername.map(item => item.value).toString();
            const expertiseIds = inputValue.expertiseList.map(item => item.value).toString();
            const _localFiles = [];

            if (localFiles.length > 0) {
                _localFiles.push({
                    imageType: MarketingEventImageTypeEnum.Banner,
                    file: localFiles[0]
                })
            }

            if (localWatermarkFiles.length > 0) {
                _localFiles.push({
                    imageType: MarketingEventImageTypeEnum.Watermark,
                    file: localWatermarkFiles[0]
                })
            }

            if (isEdit) {
                if (localFiles.length > 0 || localWatermarkFiles.length > 0) {
                    dispatch(uploadMarketingEventFile({
                        ...rest,
                        id: singleEvent.id,
                        local_files: _localFiles,
                        mitraList: removeUnnecessaryComma(mitraIds),
                        imageUrl: singleEvent.imageUrl,
                        watermarkUrl: singleEvent.watermarkUrl,
                        isEdit: isEdit,
                        expertiseList: removeUnnecessaryComma(expertiseIds),
                    }))
                } else {
                    dispatch(updateMarketingEvent({
                        ...rest,
                        id: singleEvent.id,
                        mitraList: removeUnnecessaryComma(mitraIds),
                        imageUrl: singleEvent.imageUrl,
                        watermarkUrl: singleEvent.watermarkUrl,
                        expertiseList: removeUnnecessaryComma(expertiseIds),
                    }))
                }
            } else {
                dispatch(uploadMarketingEventFile({
                    ...rest,
                    local_files: _localFiles,
                    mitraList: removeUnnecessaryComma(mitraIds),
                    imageUrl: "",
                    watermarkUrl: "",
                    isEdit: isEdit,
                    expertiseList: removeUnnecessaryComma(expertiseIds),
                }))
            }
        }
        setValidated(true);
    }

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "name") {
            const containsSpecialChars = specialChars.some(char => e.target.value.includes(char));
            if (containsSpecialChars) {
                const newErrors = { ...errors };
                newErrors.name = `Nama tidak boleh mengandung spesial karakter \\ / : * ? " < > |`;
                setErrors(newErrors);
            } else {
                const newErrors = { ...errors };
                newErrors.name = "";
                setErrors(newErrors);
            }
        }
        setInputValue((old) => {
            return {
                ...old,
                [e.target.name]: e.target.value
            }
        });
    }

    const onInputDateChange = (name: string, value: string) => {
        setInputValue((old) => {
            return {
                ...old,
                [name]: value
            }
        });
    }

    const onOptionUsernamChangeHandler = (e: OptionModel | null) => {
        let newArr: OptionModel[] = [];
        if (e && !inputValue.mitraUsername.includes(e)) {
            newArr = [...inputValue.mitraUsername, e];
        } else if (e) {
            newArr = inputValue.mitraUsername.filter((option) => option !== e);
        }
        setInputValue({
            ...inputValue,
            mitraUsername: newArr
        });
    };

    const onUsernameTagCancelHandler = (option: OptionModel) => {
        if (inputValue.mitraUsername.includes(option)) {
            const newArr = inputValue.mitraUsername.filter((a) => a !== option);
            setInputValue({
                ...inputValue,
                mitraUsername: newArr
            });
        }
    };

    const onOptionExpertiseChangeHandler = (e: OptionModel | null) => {
        let newArr: OptionModel[] = [];
        if (e && !inputValue.expertiseList.includes(e)) {
            newArr = [...inputValue.expertiseList, e];
        } else if (e) {
            newArr = inputValue.expertiseList.filter((option) => option !== e);
        }
        setInputValue({
            ...inputValue,
            expertiseList: newArr
        });
    };

    const onExpertiseTagCancelHandler = (option: OptionModel) => {
        if (inputValue.expertiseList.includes(option)) {
            const newArr = inputValue.expertiseList.filter((a) => a !== option);
            setInputValue({
                ...inputValue,
                expertiseList: newArr
            });
        }
    };

    const onFileChange = (files: Array<File>) => {
        setLocalFiles(files);
        convertBase64(files[0], (result: any) => {
            setBase64Local(result);
        });
    };

    const onWatermarkFileChange = (files: Array<File>) => {
        setLocalWatermarkFiles(files);
    };

    const onCancelHandler = () => {
        history.push("/mitra/event/list");
    };

    const MitraTag = () => {
        return (
            <>
                {inputValue.mitraUsername?.map((item: any) => (
                    <Badge pill className="grey-tag mb-2 mx-1 text-left" key={item.value}>
                        <div className="grey-tag-container">
                            <span className="grey-tag-label">{item.label}</span>
                            <span
                                className="material-icons grey-tag-close ml-1"
                                onClick={() => onUsernameTagCancelHandler(item)}
                            >cancel</span>
                        </div>
                    </Badge>
                ))}
            </>
        );
    };

    const onDelete = () => {
        MySwal.fire(customDeletedWarnOption("Apakah Anda yakin akan menghapus event ini?")).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteMarketingEvent(singleEvent.id));
            }
        });
    };

    const onManualShareButtonClicked = () => {
        let mainUrl = 'https://aramata.id/mitra/event/' + singleEvent.slug;
        navigator.clipboard.writeText(mainUrl);
    };

    const ExpertiseTag = () => {
        return (
            <>
                {inputValue.expertiseList?.map((item: any) => (
                    <Badge pill className="grey-tag mb-2 mx-1 text-left" key={item.value}>
                        <div className="grey-tag-container">
                            <span className="grey-tag-label">{item.label}</span>
                            <span
                                className="material-icons grey-tag-close ml-1"
                                onClick={() => onExpertiseTagCancelHandler(item)}
                            >cancel</span>
                        </div>
                    </Badge>
                ))}
            </>
        );
    };

    return (
        <Row>
            <Col md={6} className="mb-3">
                <Form
                    noValidate
                    validated={validated}
                    onSubmit={onSubmit}>

                    <Form.Group className="mb-4">
                        <Form.Label>Nama Event</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="Masukkan Nama Event"
                            onChange={onInputChange}
                            value={inputValue.name}
                            required
                        />
                        <small className="text-danger">{errors.name}</small>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label as="legend" column sm={12}>
                            Deskripsi Event
                        </Form.Label>
                        <Col sm={12}>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="description"
                                placeholder="Deskripsi Event"
                                onChange={onInputChange}
                                defaultValue={""}
                                value={inputValue.description}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label>Tanggal Open</Form.Label>
                        <DatePicker
                            className="form-control"
                            selected={new Date(inputValue.openDate)}
                            onChange={(date) => onInputDateChange('openDate', (date as Date).toISOString())}
                        />
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label>Tanggal Closed</Form.Label>
                        <DatePicker
                            className="form-control"
                            selected={new Date(inputValue.closeDate)}
                            onChange={(date) => onInputDateChange('closeDate', (date as Date).toISOString())}
                        />
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label>
                            Username Mitra
                        </Form.Label>

                        <div>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder="Ketik untuk mencari username mitra"
                                isSearchable={true}
                                name="color"
                                options={mitraUsernameOptions}
                                isLoading={isMitraOptionLoading}
                                onChange={onOptionUsernamChangeHandler}
                                hideSelectedOptions={true}
                            />
                            <div className="mt-2"><MitraTag/></div>
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label>
                            Keahlian
                        </Form.Label>

                        <div>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder="Ketik untuk mencari keahlian"
                                isSearchable={true}
                                name="expertise"
                                options={expertiseCategoryOptions}
                                isLoading={isExpertiseOptionLoading}
                                onChange={onOptionExpertiseChangeHandler}
                                hideSelectedOptions={true}
                            />
                            <div className="mt-2"><ExpertiseTag/></div>
                        </div>
                    </Form.Group>

                    <FileDropzone
                        label="Upload Gambar"
                        onChange={onFileChange}
                        uploadProgress={uploadProgresses}
                        files={localFiles}
                        uploadStatus={uploadStatus}
                        uploadedFiles={uploadedFiles}
                        isSingleFile={true}
                        disabledPreview
                        disabledThumbnail
                    />

                        <div>
                            <div className="my-3 default-event" style={{backgroundImage: `url(${localFiles.length > 0 ? base64Local : uploadedFiles[0]})`}}>
                                    <>
                                        <span className="default-event-title">{inputValue.name}</span>
                                    </>
                                <div className="default-event-mask"></div>
                            </div>
                        </div>

                    <FileDropzone
                        label="Upload Watermark"
                        onChange={onWatermarkFileChange}
                        uploadProgress={uploadProgresses}
                        files={localWatermarkFiles}
                        uploadStatus={uploadStatus}
                        uploadedFiles={uploadedWatermarkFiles}
                        isSingleFile={true}
                        disabledPreview
                    />

                    <div>
                        {isEdit && (
                            <img
                                src={singleEvent.watermarkPreviewUrl}
                                alt="event"
                                className="w-100 mt-3"
                            />
                        )}
                    </div>

                    <div className="mt-5">
                        <button
                            className="btn button-outline-primary mx-1"
                            type="reset"
                            onClick={onCancelHandler}>
                            Batalkan
                        </button>
                        <button className="btn btn-outline-danger mx-1" type="button" onClick={onDelete}>
                            Hapus
                        </button>
                        <button className="btn button-primary mx-1" type="submit">
                            Simpan
                        </button>
                    </div>

                </Form>
            </Col>
            <Col md={4} className="mb-3">
                <div className="mt-4 mx-3">
                    {
                        isEdit &&
                        // @ts-ignore
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            overlay={
                                <Popover id={`popover-positioned-bottom`}>
                                    <Popover.Title as="h3">{`Berhasil`}</Popover.Title>
                                    <Popover.Content>
                                        Berhasil Menyalin URL : <strong>{'https://aramata.id/mitra/event/' + singleEvent.slug}</strong>
                                    </Popover.Content>
                                </Popover>
                            }
                        >
                            <button
                                className="btn button-primary" onClick={onManualShareButtonClicked}>
                                Copy Link Mitra
                            </button>
                        </OverlayTrigger>
                    }
                </div>
            </Col>
        </Row>
    );
};

export interface EventFormProps {
    isEdit?: boolean;
}

export default EventForm;
