import axios from "axios";
import { LanguageModel, CreateLanguageSuccessResponse } from "./models";
import { endpoint } from "../../../constants";

export default class LanguageApi {
  static createLanguage(createLanguage: LanguageModel) {
    return axios
      .post<any>(endpoint + "/api/Language/Create", createLanguage)
      .then((res) => res.data.data);
  }

  static getLanguageList() {
    return axios
      .get<any>(endpoint + "/api/Language/GetList")
      .then((res) => res.data.data);
  }

  static getSingleItem(languageId: string) {
    return axios
      .get<any>(endpoint + "/api/Language/GetSingleItem?id=" + languageId)
      .then((res) => res.data.data);
  }

  static updateLanguage(language: LanguageModel) {
    return axios
      .post<any>(endpoint + "/api/Language/Update", language)
      .then((res) => res.data.data);
  }

  static deleteLanguage(language: LanguageModel) {
    return axios
      .post<any>(endpoint + "/api/Language/Delete", language)
      .then((res) => [{ response: res.data.data }, { id: language.id }]);
  }
}
