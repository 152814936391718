import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import requestExpertiseApi from "./expertiseRequest.api";
import { RequestExpertiseModel } from "./models";

export interface RequestExpertiseSlice {
  date?: string;
  list?: RequestExpertiseModel[];
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
}

export const getRequestExpertiseList = createAsyncThunk(
  "otherExpertise/otherExpertiseList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await requestExpertiseApi.getRequestExpertiseList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteRequestExpertise = createAsyncThunk(
  "otherExpertise/deleteOtherExpertise",
  async (id: string, { rejectWithValue }) => {
    try {
      return await requestExpertiseApi.deleteRequestExpertise(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const requestExpertiseSlice = createSlice({
  name: "requestExpertiseState",
  initialState: {} as RequestExpertiseSlice,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRequestExpertiseList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRequestExpertiseList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getRequestExpertiseList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(deleteRequestExpertise.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteRequestExpertise.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.date = new Date().toISOString();
    });
    builder.addCase(deleteRequestExpertise.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });
  },
});

export default requestExpertiseSlice.reducer;
