import axios from "axios";
import { endpoint } from "../../constants";

export default class DashboardApi {
   static getDashboardData() {
      return axios.get<any>(endpoint + "/api/Home/GetDashboardData").then((res) => res.data.data);
   }

   static getReportMonth() {
      return axios.get<any>(endpoint + "/api/Home/GetReportMonth").then((res) => res.data.data);
   }

   static getReportWeek() {
      return axios.get<any>(endpoint + "/api/Home/GetReportWeek").then((res) => res.data.data);
   }

   static getReportDay() {
      return axios.get<any>(endpoint + "/api/Home/GetReportDay").then((res) => res.data.data);
   }

   static getListBestPackage() {
      return axios
         .get<any>(endpoint + "/api/Package/GetListBestPackage")
         .then((res) => res.data.data);
   }

   static getListBestProduct() {
      return axios
         .get<any>(endpoint + "/api/Product/GetListBestProduct")
         .then((res) => res.data.data);
   }

   static getListBestMitra() {
      return axios
         .get<any>(endpoint + "/api/Product/GetListBestMitra")
         .then((res) => res.data.data);
   }

}
