
export const FileTypeFormat = [
    {
        type: "word",
        format: "doc|docx|ms-doc|msword|vnd.openxmlformats-officedocument.wordprocessingml.document",
        icon: "/assets/icons/attach-icon/word_icon.svg",
    },
    {
        type: "excel",
        format:
            "excel|xls|xlsx|vnd.ms-excel|x-excel|x-msexcel|vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        icon: "/assets/icons/attach-icon/excel_icon.svg",
    },
    {
        type: "powerpoint",
        format:
            "mspowerpoint|ppt|pptx|powerpoint|vnd.ms-powerpoint|x-mspowerpoint|vnd.openxmlformats-officedocument.presentationml.presentation",
        icon: "/assets/icons/attach-icon/ppt_icon.svg",
    },
    { type: "pdf", format: "pdf", icon: "/assets/icons/attach-icon/pdf_icon.svg" },
];
export const getIcon = (fileType: string) => {
    var _src = "/assets/icons/attach-icon/other_file_icon.svg";
    FileTypeFormat.map((type) => {
        const [...format] = type.format.split("|");
        if (format.some((item) => item === fileType)) {
            _src = type.icon;
        }
    });
    return _src;
};