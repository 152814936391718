import axios from "axios";
import {CityModel, RequestCityModel} from "./models";
import { endpoint } from "../../../constants";

export default class CityApi {
  static createCity(createCity: RequestCityModel) {
    return axios
      .post<any>(endpoint + "/api/City/Create", createCity)
      .then((res) => res.data.data);
  }

  static getCityList() {
    return axios
      .get<any>(endpoint + "/api/City/GetList")
      .then((res) => res.data.data);
  }

  static getIslandList() {
    return axios
        .get<any>(endpoint + "/api/City/GetIslandList")
        .then((res) => res.data.data);
  }

  static getProvinceList(islandId: string) {
    return axios
        .get<any>(endpoint + "/api/City/GetProvinceList")
        .then((res) => res.data.data);
  }

  static getSingleItem(cityId: string) {
    return axios
      .get<any>(endpoint + "/api/City/GetSingleItem?id=" + cityId)
      .then((res) => res.data.data);
  }

  static updateCity(city: RequestCityModel) {
    return axios
      .post<any>(endpoint + "/api/City/Update", city)
      .then((res) => res.data.data);
  }

  static deleteCity(city: CityModel) {
    return axios
      .post<any>(endpoint + "/api/City/Delete", city)
      .then((res) => [{ response: res.data.data }, { id: city.id }]);
  }
}
