import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import FileUploadApi from "../../media/fileUpload/fileUpload.api";
import StoreProductApi from "./storeProduct.api";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {CreateStoreProductModel, StoreProductModel, StoreProductRequest} from "./models";

export interface StoreProductSlice {
   name?: string;
   list?: StoreProductModel[];
   single?: StoreProductModel;
   isLoading?: boolean;
   isGetLoading?: boolean;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
}

export const createStoreProduct = createAsyncThunk(
   "storeProduct/create",
   async (args: CreateStoreProductModel, { rejectWithValue }) => {
      try {
         const response = await FileUploadApi.uploadImage({
            file_name: new Date().toString() + "." + args.image.fileFormat,
            base64url: (args.image.fileValue as string).split(",")[1],
            imagepath: "store-product",
            location: "store-product",
         });
         if (response.status === 200) {
            let formData = args;
            formData.path = response.data.imagepath;
            return await StoreProductApi.createStoreProduct(formData);
         }
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getStoreProductList = createAsyncThunk(
   "storeProduct/getList",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await StoreProductApi.getStoreProductList();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getSingleStoreProduct = createAsyncThunk(
   "storeProduct/getSingle",
   async (id: string, { rejectWithValue }) => {
      try {
         return await StoreProductApi.getSingleItem(id);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const updateStoreProduct = createAsyncThunk(
   "storeProduct/update",
   async (args: CreateStoreProductModel, { rejectWithValue }) => {
      try {
         if (args.image.fromApi) {
            return await StoreProductApi.updateStoreProduct(args);
         } else {
            const response = await FileUploadApi.uploadImage({
               file_name: new Date().toString() + "." + args.image.fileFormat,
               base64url: (args.image.fileValue as string).split(",")[1],
               imagepath: "professional-type",
               location: "professional-type",
            });
            if (response.status === 200) {
               let formData = args;
               formData.path = response.data.imagepath;
               return await StoreProductApi.updateStoreProduct(formData);
            }
         }
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const deleteStoreProduct = createAsyncThunk(
   "storeProduct/delete",
   async (args: StoreProductRequest, { rejectWithValue }) => {
      try {
         return await StoreProductApi.deleteStoreProduct(args);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const storeProductSlice = createSlice({
   name: "storeProductState",
   initialState: {} as StoreProductSlice,
   reducers: {
      setFilter: (state, action) => {
         state.list = action.payload;
      },
      resetSingle: (state) => {
         state.single = {} as StoreProductModel;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(createStoreProduct.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(createStoreProduct.fulfilled, (state) => {
         state.name = new Date().toISOString();
         state.isLoading = false;
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(createStoreProduct.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(getSingleStoreProduct.pending, (state) => {
         state.single = undefined;
         state.isGetLoading = true;
      });
      builder.addCase(getSingleStoreProduct.fulfilled, (state, { payload }) => {
         state.single = payload;
         state.isGetLoading = false;
      });
      builder.addCase(getSingleStoreProduct.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isGetLoading = false;
      });
      builder.addCase(getStoreProductList.pending, (state) => {
         state.isGetLoading = true;
      });
      builder.addCase(getStoreProductList.fulfilled, (state, { payload }) => {
         state.list = payload;
         state.isGetLoading = false;
      });
      builder.addCase(getStoreProductList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isGetLoading = false;
      });
      builder.addCase(updateStoreProduct.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(updateStoreProduct.fulfilled, (state) => {
         state.isLoading = false;
         state.name = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(updateStoreProduct.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(deleteStoreProduct.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(deleteStoreProduct.fulfilled, (state) => {
         state.isLoading = false;
         state.name = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(deleteStoreProduct.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
   },
});

export const { resetSingle, resetStatus } = storeProductSlice.actions;
export default storeProductSlice.reducer;
