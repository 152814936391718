import {ApiErrorResponse} from "../../index";
import {ApiResponseStatus} from "../../@core/models/apiResponseStatus/apiResponseStatus";
import {
    ActivityModel,
    AdminProjectModel, CommentResultModel, FileResultModel,
    GetProjectRequest,
    ProjectDetailModel,
    ProjectModel, ProjectStatusEnum, ResultProjectModel, ResultReview, SingleDetailActivityModel,
    TotalProject
} from "./models";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import DataProjectApi from "./dataProject.api";

export interface ProjectSlice {
    list?: AdminProjectModel[];
    rows?: number;
    totalProject?: TotalProject;
    singleItem?: ProjectDetailModel;
    activityList?: ActivityModel[];
    singleActivity?: SingleDetailActivityModel;
    projectResult?: ResultProjectModel;
    finalResult?: FileResultModel[];
    resultReview?: ResultReview[];
    commentResult?: CommentResultModel[];
    activeTag?: ProjectStatusEnum;
    isLoading?: boolean;
    dateCreated?: string;
    error?: ApiErrorResponse<any>;
    status?: ApiResponseStatus;
}

export const getProjectList = createAsyncThunk(
    "project/getProjectList",
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await DataProjectApi.getProjectList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getProjectListWithFilter = createAsyncThunk(
    "project/getProjectListWithFilter",
    async (args: GetProjectRequest, { rejectWithValue }) => {
        try {
            return await DataProjectApi.getProjectListWithFilter(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getTotalProject = createAsyncThunk(
    "project/getTotalProject",
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await DataProjectApi.getTotalProject();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getSingleProject = createAsyncThunk(
    "project/getSingleProject",
    async (id: string, { rejectWithValue }) => {
        try {
            return await DataProjectApi.getSingleProject(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getSingleDetail = createAsyncThunk(
    "project/getSingleDetail",
    async (id: string, { rejectWithValue }) => {
        try {
            return await DataProjectApi.getSingleDetail(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getResultByDetailActivity = createAsyncThunk(
    "project/getResultByDetailActivity",
    async (detailActivityId: string, { rejectWithValue }) => {
        try {
            return await DataProjectApi.getResultByDetailActivity(detailActivityId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getActivityByProject = createAsyncThunk(
    "project/getActivityByProject",
    async (projectId: string, { rejectWithValue }) => {
        try {
            return await DataProjectApi.getActivityByProject(projectId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getListFinalFile = createAsyncThunk(
    "project/getListFinalFile",
    async (resultId: string, { rejectWithValue }) => {
        try {
            return await DataProjectApi.getListFinalFile(resultId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getResultReview = createAsyncThunk(
    "project/getResultReview",
    async (resultId: string, { rejectWithValue }) => {
        try {
            return await DataProjectApi.getResultReview(resultId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getCommentResult = createAsyncThunk(
    "project/getCommentResult",
    async (resultId: string, { rejectWithValue }) => {
        try {
            return await DataProjectApi.getCommentResult(resultId);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

const projectSlice = createSlice({
    name: "projectState",
    initialState: {} as ProjectSlice,
    reducers: {
        setFilter: (state, action) => {
            state.list = action.payload;
        },
        resetStatus: (state) => {
            state.status = ApiResponseStatus.pending;
        },
        resetError: (state) => {
            state.error = {} as ApiErrorResponse<any>;
        },
        setActiveTag: (state, action) => {
            state.activeTag = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProjectList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getProjectList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.list = payload;
        });
        builder.addCase(getProjectList.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        });
        builder.addCase(getProjectListWithFilter.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getProjectListWithFilter.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.list = payload.data;
            state.rows = payload.total;
        });
        builder.addCase(getProjectListWithFilter.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        });
        builder.addCase(getTotalProject.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getTotalProject.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.totalProject = payload;
        });
        builder.addCase(getTotalProject.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        });
        builder.addCase(getSingleProject.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSingleProject.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.singleItem = payload;
        });
        builder.addCase(getSingleProject.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        });
        builder.addCase(getSingleDetail.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSingleDetail.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.singleActivity = payload;
        });
        builder.addCase(getSingleDetail.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        });
        builder.addCase(getResultByDetailActivity.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getResultByDetailActivity.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.projectResult = payload;
        });
        builder.addCase(getResultByDetailActivity.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        });
        builder.addCase(getListFinalFile.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getListFinalFile.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.finalResult = payload;
        });
        builder.addCase(getListFinalFile.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        });
        builder.addCase(getResultReview.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getResultReview.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.resultReview = payload;
        });
        builder.addCase(getResultReview.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        });
        builder.addCase(getCommentResult.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getCommentResult.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            const _comment = payload ?? [];
            _comment.sort(function (
                a: { createdDate: string | number | Date },
                b: { createdDate: string | number | Date }
            ) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
            });
            state.commentResult = _comment;
        });
        builder.addCase(getCommentResult.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        });
        builder.addCase(getActivityByProject.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getActivityByProject.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.activityList = payload;
        });
        builder.addCase(getActivityByProject.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        });
    }
});

export const { setActiveTag, setFilter, resetStatus, resetError } = projectSlice.actions;
export default projectSlice.reducer;