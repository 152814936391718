import React, {useEffect, useState} from 'react';
import {Row, Container, Col} from "react-bootstrap";
import {BsArrowRightCircle} from "react-icons/bs";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import EventTable from "../components/event/EventTable";

const EventPage = () => {
    const dispatch = useDispatch();

    const eventTotalDownload = useSelector((state: RootState) => state.marketingEvent.eventTotalDownload ?? 0);
    const eventTotalView = useSelector((state: RootState) => state.marketingEvent.eventTotalView ?? 0);

    return (
        <Container className="content-body" fluid>
            <Row>
                <Col lg={3} xs={6} className="mb-3">
                    <div className="card-counter card-warning-color text-light">
                        <div className="flex-grow-1 p-3">
                            <div className="card-counter-value">
                                <h1 className="mb-0">{eventTotalView}</h1>
                            </div>
                            <div className="card-counter-label">
                                <span>Viewers</span>
                            </div>
                        </div>
                        <div style={{cursor: "pointer"}} className="p-2">
                            <BsArrowRightCircle size={18}/>
                        </div>
                    </div>
                </Col>
                <Col lg={3} xs={6} className="mb-3">
                    <div className="card-counter card-success-color text-light">
                        <div className="flex-grow-1 p-3">
                            <div className="card-counter-value">
                                <h1 className="mb-0">{eventTotalDownload}</h1>
                            </div>
                            <div className="card-counter-label">
                                <span>Download</span>
                            </div>
                        </div>
                        <div  style={{cursor: "pointer"}} className="p-2">
                            <BsArrowRightCircle size={18}/>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className={"my-3"}>
                <Col md={12} className="mb-3">
                    <h4 className="card-label d-inline">Data Event</h4>
                </Col>
                <Col md={12}>
                    <EventTable />
                </Col>
            </Row>
        </Container>
    );
};

export default EventPage;
