import React, {ChangeEvent, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Form} from "react-bootstrap";
import {convertBase64} from "../../../../helpers/Base64";
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import {BannerModel} from "../models";
import {checkObjectIsNotEmpty} from "../../../../helpers/checkEmptyObject";
import Dropzone from "react-dropzone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/free-solid-svg-icons";
import AsyncSelect from "react-select/async";
import useAsyncBlogOption from "../../../../hooks/blogs/useAsyncBlogOption";
import {OptionModel} from "../../../../@core/models/types";
import {getMediaEndpoint} from "../../../../helpers/imageManipulation";

const BannerForm = ({isEdit = false, ...props}: BannerFormProps) => {
    let history = useHistory();

    const {getBlogOptions} = useAsyncBlogOption();

    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [linkBanner, setLinkBanner] = useState("");
    const [validated, setValidated] = useState(false);
    const [bannerFile, setBannerFile] = useState("");
    const [fileFormat, setFileFormat] = useState("");
    const [filterBlogId, setFilterBlogId] = useState<string | null>(null);
    const [blogName, setBlogName] = useState<string | null>(null);
    const [isShowBeranda, setIsShowBeranda] = useState<boolean>(false);
    const [isShowHome, setIsShowHome] = useState<boolean>(false);
    const [timer, setTimer] = useState(setTimeout(() => {
    }, 1000));

    const singleBanner = useSelector(
        (state: RootState) => state.banner.single ?? ({} as BannerModel)
    );

    const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const onSubTitleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setSubTitle(e.target.value);
    };

    const onLinkBannerChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLinkBanner(e.target.value);
    };

    const onCancelHandler = () => {
        history.push("/customer/banner/list");
    };

    const handleAcceptedImageFiles = (files: any[]) => {
        setFileFormat(files[0].type.split("/")[1]);
        convertBase64(files[0], (result: any) => {
            setBannerFile(result);
        });
    };

    useEffect(() => {
        if (isEdit && checkObjectIsNotEmpty(singleBanner)) {
            setTitle(singleBanner.title);
            setSubTitle(singleBanner.subTitle);
            setLinkBanner(singleBanner.linkBanner);
            setFilterBlogId(singleBanner.blogId);
            setBlogName(singleBanner.blogName ?? "");
            setIsShowBeranda(singleBanner.isShowBeranda);
            setIsShowHome(singleBanner.isShowHome);
        }
    }, [isEdit, singleBanner]);

    const onSelectedProductChange = (data: any) => {
        setFilterBlogId(data.value);
        setBlogName(data.label);
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<OptionModel[]>((resolve) => {
            clearTimeout(timer);
            setTimer(
                setTimeout(() => {
                    resolve(getBlogOptions(inputValue));
                }, 1000)
            );
        });

    const onShowStoreChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.checked ? setIsShowBeranda(true) : setIsShowBeranda(false);
    };

    const onShowHomeChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.checked ? setIsShowHome(true) : setIsShowHome(false);
    };

    return (
        <>
            <Form
                noValidate
                validated={validated}
                onSubmit={(e) => {
                    const form = e.currentTarget;
                    if (form.checkValidity() === false) {
                        e.preventDefault();
                        e.stopPropagation();
                    } else {
                        e.preventDefault();
                        if (bannerFile === "" && !isEdit) {
                            alert("Mohon upload file terlebih dahulu");
                        } else {
                            props.onSubmit(
                                singleBanner.id,
                                singleBanner.url,
                                title,
                                subTitle,
                                linkBanner,
                                bannerFile,
                                fileFormat,
                                filterBlogId,
                                isShowBeranda,
                                isShowHome,
                            );
                            setTitle("");
                            setSubTitle("");
                            setBannerFile("");
                            setFileFormat("");
                            setLinkBanner("");
                            history.replace("/customer/banner/list");
                        }
                    }
                    setValidated(true);
                }}>
                <Form.Group className="mb-4">
                    <Form.Label>Gambar Banner</Form.Label>

                    <Dropzone onDrop={(acceptedFiles) => handleAcceptedImageFiles(acceptedFiles)}>
                        {({getRootProps, getInputProps}) => (
                            <div className="dropzone file-upload-frame w-100">
                                <div className="dz-message needsclick" {...getRootProps()}>
                                    <input {...getInputProps()} />

                                    <div className="text-center file-upload-label">
                                        <div>
                                            {(bannerFile !== "" || isEdit) && (
                                                <>
                                                    <img
                                                        src={
                                                            isEdit
                                                                ? bannerFile === ""
                                                                    ? getMediaEndpoint(singleBanner.url)
                                                                    : bannerFile
                                                                : bannerFile
                                                        }
                                                        className="file-upload-image mb-3"
                                                    />
                                                    <br/>
                                                </>
                                            )}
                                            {bannerFile === "" && !isEdit && (
                                                <>
                                                    <FontAwesomeIcon
                                                        icon={faUpload}
                                                        width="48"
                                                        className="file-upload-icon mb-3"
                                                    />
                                                    <br/>
                                                </>
                                            )}
                                            <span>Upload File</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Judul Banner</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Masukkan Judul Banner"
                        onChange={onTitleChange}
                        value={title}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Blog</Form.Label>
                    <AsyncSelect cacheOptions defaultOptions loadOptions={promiseOptions}
                                 value={filterBlogId !== null ? {
                                     value: filterBlogId,
                                     label: blogName,
                                 } : null}
                                 onChange={onSelectedProductChange}/>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Sub Judul Banner</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Masukkan Sub Judul Banner"
                        onChange={onSubTitleChange}
                        value={subTitle}
                        required
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>URL Banner</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Masukkan URL Banner"
                        onChange={onLinkBannerChange}
                        value={linkBanner}
                        required
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        type="switch"
                        inline
                        className="mr-5"
                        id="publish-switch"
                        label="Tampilkan di Store"
                        checked={isShowBeranda}
                        onChange={onShowStoreChangeHandler}
                    />
                    <Form.Check
                        type="switch"
                        inline
                        id="show-switch"
                        label="Tampilkan di Beranda"
                        checked={isShowHome}
                        onChange={onShowHomeChangeHandler}
                    />
                </Form.Group>
                <div className="text-center mt-5">
                    <button
                        className="btn button-outline-primary mr-3"
                        type="reset"
                        onClick={onCancelHandler}>
                        BATALKAN
                    </button>
                    <button className="btn button-primary" type="submit">
                        {isEdit ? "EDIT BANNER" : "TAMBAH BANNER"}
                    </button>
                </div>
            </Form>
        </>
    );
};

export interface BannerFormProps {
    onSubmit: (
        id: string,
        defaultBanner: string,
        title: string,
        subTitle: string,
        linkBanner: string,
        bannerFile: string,
        fileFormat: string,
        blogId: string | null,
        isShowBeranda: boolean,
        isShowHome: boolean
    ) => void;
    isEdit: boolean;
}

export default BannerForm;
