export const convertToMB = (bytes: number) => {
    return (bytes / (1024*1024)).toFixed(2);
}

export function fileToBlob(file: File): Promise<Blob> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.result instanceof ArrayBuffer) {
                resolve(new Blob([reader.result]));
            } else {
                reject(new Error('Expected ArrayBuffer'));
            }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
}