import React, { useEffect } from "react";
import {Container, Row, Col, Card, Badge} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import { addDefaultCardImage } from "../../../../helpers/defaultBrokenImage";
import { changePinBlog, getBlogSingleItem, resetStatus, setHomepageBlog } from "../blog.reducer";
import { SingleBlogModel } from "../models";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";
import {ApiErrorResponse} from "../../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {getMediaEndpoint} from "../../../../helpers/imageManipulation";

const BlogDetail = ({ singleBlog = {} as SingleBlogModel }: BlogDetailProps) => {
   const dispatch = useDispatch();
   const MySwal = withReactContent(Swal);
   let { id } = useParams<{ id: string }>();

   const homepageCreateStatus = useSelector((state: RootState) => state.blogAdmin.status);
   const pinStatus = useSelector((state: RootState) => state.blogAdmin.pinStatus);
   
   const singleDataState = useSelector(
      (state: RootState) => state.blogAdmin.single ?? ({} as SingleBlogModel)
   );

   const changeDate = useSelector((state: RootState) => state.blogAdmin.name);
   const error = useSelector((state: RootState) => state.blogAdmin.error ?? ({} as ApiErrorResponse<any>));

   // useEffect(() => {
   //    if (homepageCreateStatus === ApiResponseStatus.success) {
   //       alert("Berhasil Mengubah Homepage");
   //    } else if (homepageCreateStatus === ApiResponseStatus.failed) {
   //       alert("gagal Mengubah Homepage");
   //    }
   // }, [homepageCreateStatus]);

   useEffect(() => {
      if (homepageCreateStatus !== undefined && homepageCreateStatus !== ApiResponseStatus.pending) {
         MySwal.fire(
             savedStatusOption(
                 homepageCreateStatus === ApiResponseStatus.success,
                 error.data?.message
             )
         ).then(() => {
            dispatch(resetStatus());
         });
      }
   }, [homepageCreateStatus, error]);

   useEffect(() => {
      if (pinStatus !== undefined && pinStatus !== ApiResponseStatus.pending) {
         MySwal.fire(
             savedStatusOption(
                 pinStatus === ApiResponseStatus.success,
                 error.data?.message
             )
         ).then(() => {
            dispatch(resetStatus());
         });
      }
   }, [pinStatus, error]);

   //
   // useEffect(() => {
   //    if (pinStatus === ApiResponseStatus.success) {
   //       alert("Berhasil Mengubah Pin Blog");
   //    } else if (pinStatus === ApiResponseStatus.failed) {
   //       alert("Gagal Mengubah Pin Blog");
   //    }
   // }, [pinStatus]);

   const onShowAtHomepageClicked = (showAtHome: boolean) => {
      const _blogCategoryArray = singleBlog.blogCategory.map((item) => {
         return item.id;
      });

      let blogData = {
         id: singleBlog.id,
         blogCategory: _blogCategoryArray,
         thumbnailPath: singleBlog.thumbnailPath,
         metaKeyword: singleBlog.metaKeyword,
         title: singleBlog.title,
         subtitle: singleBlog.subtitle,
         content: singleBlog.content,
         isPublished: singleBlog.isPublished,
         isAdmin: singleBlog.isAdmin,
         showAtHome: true,
         userId: singleBlog.userId,
         marketingEventList: singleBlog.marketingEventList,
      };

      if (showAtHome) {
         blogData.showAtHome = false;
         dispatch(setHomepageBlog(blogData));
      } else {
         blogData.showAtHome = true;
         dispatch(setHomepageBlog(blogData));
      }
   };

   const onChangePinBlogClicked = (isPinned: boolean) => {
      dispatch(
         changePinBlog({
            id: singleBlog.id,
            isPinned: !isPinned,
         })
      );
   };

   useEffect(() => {
      dispatch(getBlogSingleItem(id));
   }, [changeDate]);

   return (
      <Container className="blog-page">
         <Row className="justify-content-center">
            <Col md={8}>
               <img
                  src={getMediaEndpoint(singleBlog.thumbnailPath)}
                  alt="blog"
                  className="w-100 mt-5"
                  onError={addDefaultCardImage}
               />
               <div className="mt-3 mb-2">
                  <p className="blog-date d-inline">
                     <Moment format="DD MMMM YYYY">{new Date(singleBlog.createdDate)}</Moment>
                  </p>
                  <button
                     className="btn button-primary float-right"
                     onClick={() => onShowAtHomepageClicked(singleBlog.showAtHome)}>
                     {singleDataState.showAtHome ? "Hilangkan dari Beranda" : "Tampilkan di Beranda"}
                  </button>
                  <button
                     className="btn button-primary float-right mr-2"
                     onClick={() => onChangePinBlogClicked(singleBlog.isPinned)}>
                     {singleDataState.isPinned ? "Unpin Blog" : "Pin Blog"}
                  </button>
               </div>
               {
                  singleBlog.metaKeyword?.map((meta) => (
                      <Badge
                          pill
                          className="badge-tag font-weight-normal ml-1 mr-1 mb-3 badge-sm text-capitalize"
                      >
                         {meta}
                      </Badge>
                  ))
               }
               <h4 className="mb-3">{singleBlog.title}</h4>
               <div>{ReactHtmlParser(singleBlog.content)}</div>
            </Col>
            {/* <Col md={4}>
               <h5 className="mt-5 mb-3 other-blog-title">Blog Lainnya</h5>
               <Card className="other-blog-card" style={{ width: "18rem" }}>
                  <Card.Img variant="top" src="https://placeimg.com/540/280/tech" />
                  <Card.Body>
                     <p className="mt-1 mb-3 blog-date">31 Maret 2021</p>
                     <Card.Title>Memilih foto yang cocok untuk desain website</Card.Title>
                  </Card.Body>
               </Card>
            </Col> */}
         </Row>
      </Container>
   );
};

export interface BlogDetailProps {
   singleBlog: SingleBlogModel;
}

export default BlogDetail;
