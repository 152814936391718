export interface RequestAdminModel {
   id: string;
   roomId: string;
   projectId: string;
   projectName: string;
   reason: string;
   status: RequestAdminStatus;
}

export enum RequestAdminStatus {
   Pending,
   Accept,
   Reject,
}

export interface PersonalChatModel {
   name: string;
   profileImage: string;
   unread: number;
   userId: string;
}

export interface AddProfileChatModel {
   personal: PersonalChatModel;
   requestAdmin: RequestAdminModel;
   roomId: string;
}
