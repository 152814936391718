import React, {Fragment, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import DataTable, {TableColumn} from "react-data-table-component";
import {TableCustomStyles} from "../../../components/DataTable/TableCustomStyles";
import {AdminProjectModel, ProjectStatusEnum, TotalProject} from "../models";
import moment from "moment/moment";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {
    getProjectListWithFilter, setActiveTag
} from "../dataProject.reducer";
import {Link, useHistory} from "react-router-dom";

import {MAX_PAGING_OPTIONS} from "../../../constants";
import NumberFormat from "react-number-format";

const DataProjectTable = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [filterText, setFilterText] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [timer, setTimer] = useState(setTimeout(() => {
    }, 1000));

    const totalProject = useSelector((state: RootState) => state.project.totalProject ?? {} as TotalProject);
    const projectList = useSelector((state: RootState) => state.project.list ?? []);
    const activeTag = useSelector((state: RootState) => state.project.activeTag ?? ProjectStatusEnum.Prepare);
    const rows = useSelector(
        (state: RootState) => state.project.rows
    );

    const [totalCost, setTotalCost] = useState<number>(0);
    const [commission, setCommission] = useState<number>(0);

    useEffect(() => {
        dispatch(getProjectListWithFilter({
            name: filterText === "" ? undefined : filterText,
            status: activeTag,
            skip: 0,
            take: 10,
        }));
    }, []);

    useEffect(() => {
        dispatch(getProjectListWithFilter({
            name: filterText === "" ? undefined : filterText,
            status: activeTag,
            skip: 0,
            take: rowsPerPage,
        }));
    }, [activeTag, rowsPerPage]);

    useEffect(() => {
        if (totalProject) {
            switch (activeTag) {
                case ProjectStatusEnum.Prepare:
                    setTotalCost(totalProject.totalBiayaPrepare);
                    setCommission(totalProject.totalKomisiPrepare);
                    break;
                case ProjectStatusEnum.Active:
                    setTotalCost(totalProject.totalBiayaActive);
                    setCommission(totalProject.totalKomisiActive);
                    break;
                case ProjectStatusEnum.Complete:
                    setTotalCost(totalProject.totalBiayaComplete);
                    setCommission(totalProject.totalKomisiComplete);
                    break;
                case ProjectStatusEnum.Cancel:
                    setTotalCost(totalProject.totalBiayaCancel);
                    setCommission(totalProject.totalKomisiCancel);
                    break;
                default:
                    setTotalCost(0);
                    setCommission(0);
                    break;
            }
        }
    }, [activeTag, totalProject]);

    const columns: TableColumn<AdminProjectModel>[] = [
        {
            name: "NO.",
            width: "60px",
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: "NAMA PROYEK",
            selector: (row) => row.name ?? "-",
            sortable: true,
        },
        {
            name: "NAMA PENGGUNA",
            selector: (row) => row.customerName ?? "-",
            sortable: true,
        },
        {
            name: "TANGGAL ACARA",
            selector: (row) => row.activityDate ?? "",
            format: (row: any) => moment(row.activityDate).format("DD/MM/YYYY"),
            sortable: true,
        },
        {
            name: "MITRA TERLIBAT",
            selector: (row) => row.mitraName ?? "-",
            sortable: true,
        },
        {
            name: <div>
                <div>TOTAL BIAYA</div>
                <div className={"text-right"}><NumberFormat
                    prefix={"Rp "}
                    value={totalCost}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                /></div>
            </div>,
            selector: (row) => row.totalBiaya ?? "0",
            format: (row) => <Fragment>
                <NumberFormat
                    value={row.totalBiaya}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                />
            </Fragment>,
            sortable: true,
        },
        {
            name: <div>
                <div>KOMISI</div>
                <div className={"text-right"}><NumberFormat
                    prefix={"Rp "}
                    value={commission}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                /></div>
            </div>,
            selector: (row) => row.komisi ?? "0",
            format: (row) => <Fragment>
                <NumberFormat
                    value={row.komisi}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                />
            </Fragment>,
            sortable: true,
        },
        {
            name: "AKSI",
            width: "400px",
            cell: (row) => (
                <>
                    <button
                        onClick={() => history.push(`/project/chat/${row.id}`)}
                        className="btn button-primary mr-2"
                    >
                        CHAT GROUP
                    </button>
                    <Link
                        to={{
                            pathname: `/project/detail/${row.id}`,
                        }}
                    >
                        <button className="btn button-danger">PRATINJAU</button>
                    </Link>

                </>
            )
        }
    ]

    const onSearchChange = (e: any) => {
        let filterValue = e.target.value;
        setFilterText(filterValue);
        clearTimeout(timer);
        setTimer(
            setTimeout((value = filterValue) => {
                dispatch(getProjectListWithFilter({
                    name: value === "" ? undefined : value,
                    status: activeTag,
                    skip: 0,
                    take: rowsPerPage,
                }));
            }, 1000)
        );
    }

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setRowsPerPage(newPerPage);
        dispatch(getProjectListWithFilter({
            name: filterText === "" ? undefined : filterText,
            status: activeTag,
            skip: newPerPage * (page - 1),
            take: newPerPage,
        }));
    };

    const handlePageChange = (page: number) => {
        dispatch(getProjectListWithFilter({
            name: filterText === "" ? undefined : filterText,
            status: activeTag,
            skip: rowsPerPage * (page - 1),
            take: rowsPerPage,
        }));
    };

    return (
        <div>
            <div className="d-flex" style={{
                borderBottom: "1px solid #a0a0a0",
            }}>
                <div style={{width: "300px", paddingBottom: "15px"}}>
                    <div>
                        <div className="d-flex">
                            <div className="input-field-group">
                                <FontAwesomeIcon
                                    className="input-field-absolut-icon"
                                    icon={faSearch}
                                />
                                <input
                                    id="search"
                                    type="search"
                                    placeholder={"Filter berdasarkan nama"}
                                    className="input-field-group-text"
                                    style={{width: "18rem"}}

                                    value={filterText}
                                    onChange={onSearchChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex-grow-1 d-flex justify-content-end align-items-end">
                    <div className="d-inline">
                        <button
                            onClick={() => {
                                dispatch(setActiveTag(ProjectStatusEnum.Prepare));
                            }}
                            className={`btn mr-1 tab-button ${
                                activeTag === ProjectStatusEnum.Prepare ? "active" : ""
                            }`}
                        >
                            MEMPERSIAPKAN
                        </button>
                        <button
                            onClick={() => {
                                dispatch(setActiveTag(ProjectStatusEnum.Active));
                            }}
                            className={`btn mr-1 tab-button ${
                                activeTag === ProjectStatusEnum.Active ? "active" : ""
                            }`}
                        >
                            SEDANG BERJALAN
                        </button>
                        <button
                            onClick={() => {
                                dispatch(setActiveTag(ProjectStatusEnum.Complete));
                            }}
                            className={`btn mr-1 tab-button ${
                                activeTag === ProjectStatusEnum.Complete ? "active" : ""
                            }`}
                        >
                            SELESAI
                        </button>
                        <button
                            onClick={() => {
                                dispatch(setActiveTag(ProjectStatusEnum.Cancel));
                            }}
                            className={`btn mr-1 tab-button ${
                                activeTag === ProjectStatusEnum.Cancel ? "active" : ""
                            }`}
                        >
                            BATAL
                        </button>
                    </div>
                </div>
            </div>
            <div className={"my-3"}>
                <DataTable
                    columns={columns}
                    data={projectList}
                    pagination
                    paginationServer
                    paginationTotalRows={rows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    noHeader
                    selectableRows
                    customStyles={TableCustomStyles}
                    progressPending={false}
                    persistTableHead
                    paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
                />
            </div>
        </div>
    );
};

export default DataProjectTable;
