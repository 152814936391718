import React from "react";
import LoginForm from "../components/LoginForm";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { Redirect } from "react-router-dom";
import ForgotPasswordForm from "../components/ForgotPasswordForm";

const AdminForgotPasswordPage = () => {
  const userState = useSelector((state: RootState) => state.user);

  return <ForgotPasswordForm />;
};

export default AdminForgotPasswordPage;
