import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TagExpertiseTypeModel } from "../models";
import DefaultSubHeader from "../../../../components/DataTable/DefaultSubHeader";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import DataTable, {TableColumn} from "react-data-table-component";
import { ElementPropModel } from "../../../../components/DataTable/models";
import {getMediaEndpoint} from "../../../../helpers/imageManipulation";

const TagExpertiseTypeTable = (props: { data: TagExpertiseTypeModel[] }) => {
  const [filterText, setFilterText] = useState("");

  const filteredItems = props.data.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const onSetterFilterText = (value: string) => {
    setFilterText(value);
  };

  const elementProps = {
    placeholder: "cari berdasarkan nama expertise",
    buttonName: "Tambah Expertise",
    createUrl: "/master/tag-expertise/create",
    subHeaderLine: false,
  } as ElementPropModel;

  const columns: TableColumn<TagExpertiseTypeModel>[] = [
    {
      name: "NO.",
      width: "60px",
      cell: (_d: any, index: number) => index + 1,
    },
    {
      name: "NAMA EXPERTISE",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "DESKRIPSI",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "IKON",
      cell: (d: { iconUrl: string }) => (
        <>
          <Image src={getMediaEndpoint(d.iconUrl)} width="32" />
        </>
      ),
    },
    {
      name: "AKSI",
      cell: (d: { id: string; type: number }) => (
        <>
          <Link
            to={{
              pathname: `/master/tag-expertise/edit/${d.type}/${d.id}`,
            }}
          >
            <button className="btn button-primary mr-2">EDIT</button>
          </Link>
          {/* <button
            onClick={() => {
              if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
                onDelete(d.id);
            }}
            className="btn button-danger"
          >
            Delete
          </button> */}
        </>
      ),
    },
  ];

  // end of datatable components

  // useEffect(() => {
  //   // dispatch(getTagExpertiseTypeList());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const onDelete = (id: string) => {
  //   dispatch(
  //     deleteTagExpertiseType({
  //       id: id,
  //       name: "deleted",
  //       description: "deleted",
  //       link: "deleted",
  //     } as TagExpertiseTypeModel)
  //   );
  //   console.log("deleted");
  // };

  return (
    <>
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={false}
        noHeader
        subHeader
        subHeaderComponent={DefaultSubHeader(
          filterText,
          false,
          onSetterFilterText,
          elementProps
        )}
        customStyles={TableCustomStyles}
      />
    </>
  );
};

export default TagExpertiseTypeTable;
