import { useState } from "react";

import db from "../../utils/firebaseInit";
import moment from "moment/moment";
import {DataStatus} from "../../@core/models/types";
import {
   AlbumMessageModel,
   AlbumModel,
   AttachmentType,
   ChatAttachmentModel, FileMessageModel,
   MessageType,
   TextMessageModel, UserModel
} from "../../@core/models/chat/models";
import {FileUploadModel, ImageUploadModel} from "../../features/chat/models";
import ChatApi from "../../features/chat/chat.api";
import {uploadImageFunction} from "../../features/chat/chat.reducer";
const messageRef = db.collection("DIALOG_MESSAGES");

const useChatAttachment = () => {
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [attachmentList, setAttachmentList] = useState<ChatAttachmentModel[]>([]);
   const [albumList, setAlbumList] = useState<AlbumModel[]>([]);
   const [mediaProgress, setMediaProgress] = useState<number>(0);
   const [status, setStatus] = useState<string>("");
   const [sendStatus, setSendStatus] = useState<DataStatus>(DataStatus.pending);

   const sendImageAttachment = async (
      image: ImageUploadModel,
      uid: string,
      message: TextMessageModel
   ) => {
      setIsLoading(true);
      setSendStatus(DataStatus.pending);
      const _uploadImage = await uploadImageFunction({
         file_name: "image_chat_attachment." + image.fileFormat,
         base64url: (image.fileValue as string).split(",")[1],
         imagepath: "chatAttachment",
         location: "chatAttachment",
      });

      if (_uploadImage.status === "success") {
         let _message = message;
         _message.content = _uploadImage.data.imagepath;
         sendChatAttachment(
            {
               id: "",
               roomId: uid,
               userId: message.senderId,
               url: _uploadImage.data.imagepath,
               type: AttachmentType.image,
               album: "",
            },
            uid,
            message
         );
      } else {
         alert("Error when uploading attachment, please try again later");
         setIsLoading(false);
      }
   };

   const sendMultipleImageAttachment = async (
       image: ImageUploadModel[],
       uid: string,
       message: TextMessageModel
   ) => {
      setIsLoading(true);
      setSendStatus(DataStatus.pending);

      const promises = image.map(async (item) => {
         const _uploadImage = await uploadImageFunction({
            file_name: "image_chat_attachment." + item.fileFormat,
            base64url: (item.fileValue as string).split(",")[1],
            imagepath: "chatAttachment",
            location: "chatAttachment",
         });
         if (_uploadImage.status === 'success') {

            const _chatAttachment = await ChatApi.createChatAttachment({
               id: "",
               roomId: uid,
               userId: message.senderId,
               url: _uploadImage.data.imagepath,
               type: AttachmentType.image,
               album: "",
            });
            if (_chatAttachment.data.status) {
               return _uploadImage.data.imagepath
            } else {
               alert("Error when creating attachment, please try again later");
            }
         } else {
            alert(
                'An error occurred while uploading photos, please try again later'
            );
         }
      });

      Promise.all(promises).then(async (resultPath: Array<string>) => {
         if (resultPath.every((item) => item !== undefined)) {
           let _messageGroup = resultPath.map((item) => {
               return {
                  content: item,
                  createdAt: new Date().toISOString(),
                  readById: [],
                  senderId: message.senderId,
                  senderName: message.senderName,
                  messageType: MessageType.image,
               }
            });
            let _message = {
               content: '',
               createdAt: new Date().toISOString(),
               readById: [],
               senderId: message.senderId,
               senderName: message.senderName,
               messageType: MessageType.image,
               isGroupedChat: true,
               groupedChat: _messageGroup
            }
            sendAttachmentMessage(uid, _message);
            setIsLoading(false);
         } else {
            alert(
                '*Salah satu gambar mungkin mengalami kegagalan ketika disimpan, mohon ganti gambar anda dan upload ulang kembali'
            );
               setIsLoading(false);
         }
         //    alert("Error when uploading attachment, please try again later");
         //    setIsLoading(false);
      })

      // if (_uploadImage.status === "success") {
      //    let _message = message;
      //    _message.content = _uploadImage.data.imagepath;
      //    sendChatAttachment(
      //        {
      //           id: "",
      //           roomId: uid,
      //           userId: message.senderId,
      //           url: _uploadImage.data.imagepath,
      //           type: AttachmentType.image,
      //           album: "",
      //        },
      //        uid,
      //        message
      //    );
      // } else {
      //    alert("Error when uploading attachment, please try again later");
      //    setIsLoading(false);
      // }
   };

   const sendFileAttachment = async (
      file: FileUploadModel,
      uid: string,
      message: FileMessageModel,
      attachmentType: AttachmentType
   ) => {
      setIsLoading(true);
      setSendStatus(DataStatus.pending);
      const _uploadFile = await ChatApi.uploadFile(file);
      if (_uploadFile.status === 200) {
         let _message = message;
         _message.content = _uploadFile.data.dbPath;
         sendChatAttachment(
            {
               id: "",
               roomId: uid,
               userId: message.senderId,
               url: _uploadFile.data.dbPath,
               type: attachmentType,
               album: "",
            },
            uid,
            message
         );
      } else {
         alert("Error when uploading attachment, please try again later");
         setIsLoading(false);
      }
   };

   const sendAlbumFileAttachment = async (
      file: FileUploadModel,
      albumName: string,
      uid: string,
      message: AlbumMessageModel,
      attachmentType: AttachmentType,
      lastFile: boolean
   ) => {
      setSendStatus(DataStatus.pending);
      setIsLoading(true);
      const _uploadFile = await ChatApi.uploadFile(file);
      if (_uploadFile.status === 200) {
         let _message = message;
         _message.content = _uploadFile.data.dbPath;
         {
            lastFile
               ? sendChatAttachment(
                    {
                       id: "",
                       roomId: uid,
                       userId: message.senderId,
                       url: _uploadFile.data.dbPath,
                       type: attachmentType,
                       album: albumName,
                    },
                    uid,
                    message
                 )
               : createAlbumAttachment({
                    id: "",
                    roomId: uid,
                    userId: message.senderId,
                    url: _uploadFile.data.dbPath,
                    type: attachmentType,
                    album: albumName,
                 });
         }
      } else {
         alert("Error when uploading attachment, please try again later");
         setIsLoading(false);
      }
   };

   const createAlbumAttachment = async (chatAttachment: ChatAttachmentModel) => {
      const _chatAttachment = await ChatApi.createChatAttachment(chatAttachment);
      if (_chatAttachment.data.status) {
         setMediaProgress((prevState) => prevState + 1);
      } else {
         alert("Error when creating attachment, please try again later");
      }
   };

   const sendChatAttachment = async (
      chatAttachment: ChatAttachmentModel,
      uid: string,
      message: any
   ) => {
      const _chatAttachment = await ChatApi.createChatAttachment(chatAttachment);
      if (_chatAttachment.data.status) {
         sendAttachmentMessage(uid, message);
      } else {
         alert("Error when creating attachment, please try again later");
      }
   };

   const sendAttachmentMessage = (uid: string, message: any) => {
      messageRef
         .doc(uid)
         .get()
         .then((querySnapshot) => {
            let _users: UserModel[] = querySnapshot.data()?.users;

            const _filteredUsers = _users.map((user) => {
               const _user = user;
               if (user.userId !== message.senderId) {
                  _user.unread = _user.unread + 1;
                  return _user;
               } else {
                  return _user;
               }
            });

            messageRef
               .doc(uid)
               .collection("MESSAGES")
               .add(message)
               .then(() => console.log("success"));
            messageRef
               .doc(uid)
               .update({
                  lastMessage: message.content,
                  lastMessageType: message.messageType,
                  lastTimeMessage: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSSS[Z]'),
                  users: _filteredUsers,
               })
               .then(() => {
                  console.log("success");
               });
            setIsLoading(false);
            setSendStatus(DataStatus.success);
         })
         .catch((error: any) => {
            console.log("Error getting document:", error);
            alert("Error when sending attachment, please try again later: " + error);
            setIsLoading(false);
            setSendStatus(DataStatus.failed);
         });
   };

   const loadListAttachmentByAlbum = async (roomId: string, albumName: string) => {
      setStatus("pending");
      const _listAttachment = await ChatApi.getListByAlbum(roomId, albumName);
      if (_listAttachment.data.status) {
         setStatus("success");
         setAttachmentList(_listAttachment.data.data);
         setIsLoading(false);
      } else {
         setStatus("failed");
         setIsLoading(false);
      }
   };

   const loadAlbumList = async (roomId: string) => {
      setStatus("pending");
      const _listAlbum = await ChatApi.getListAlbumByRoom(roomId);
      if (_listAlbum.data.status) {
         setStatus("success");
         setAlbumList(_listAlbum.data.data);
         setIsLoading(false);
      } else {
         setStatus("failed");
         setIsLoading(false);
      }
   };

   const loadListByType = async (roomId: string, type: AttachmentType) => {
      setStatus("pending");
      const _listAttachment = await ChatApi.getListByType(roomId, type);
      if (_listAttachment.data.status) {
         setStatus("success");
         setAttachmentList(_listAttachment.data.data);
         setIsLoading(false);
      } else {
         setStatus("failed");
         setIsLoading(false);
      }
   };

   const resetAttachmentList = () => {
      setAttachmentList([]);
   };

   return {
      sendImageAttachment,
      sendMultipleImageAttachment,
      sendFileAttachment,
      sendAlbumFileAttachment,
      loadListAttachmentByAlbum,
      attachmentList,
      resetAttachmentList,
      loadAlbumList,
      loadListByType,
      albumList,
      isLoading,
      sendStatus,
      mediaProgress,
      status
   } as const;
};

export default useChatAttachment;
