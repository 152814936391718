import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { convertBase64 } from "../../../../helpers/Base64";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { LoginBannerModel } from "../models";
import { checkObjectIsNotEmpty } from "../../../../helpers/checkEmptyObject";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import {getMediaEndpoint} from "../../../../helpers/imageManipulation";

const LoginBannerForm = ({ isEdit = false, ...props }: LoginBannerFormProps) => {
   let history = useHistory();
   const [title, setTitle] = useState("");
   const [subTitle, setSubTitle] = useState("");
   const [validated, setValidated] = useState(false);
   const [bannerFile, setBannerFile] = useState("");
   const [fileFormat, setFileFormat] = useState("");

   const singleBanner = useSelector(
      (state: RootState) => state.loginBanner.single ?? ({} as LoginBannerModel)
   );

   const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setTitle(e.target.value);
   };

   const onSubTitleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setSubTitle(e.target.value);
   };

   const onCancelHandler = () => {
      history.push("/master/login-banner/list");
   };

   const handleAcceptedImageFiles = (files: any[]) => {
      setFileFormat(files[0].type.split("/")[1]);
      convertBase64(files[0], (result: any) => {
         setBannerFile(result);
      });
   };

   useEffect(() => {
      if (isEdit && checkObjectIsNotEmpty(singleBanner)) {
         setTitle(singleBanner.title);
         setSubTitle(singleBanner.subTitle);
      }
   }, [isEdit, singleBanner]);

   return (
      <>
         <Form
            noValidate
            validated={validated}
            onSubmit={(e) => {
               const form = e.currentTarget;
               if (!form.checkValidity()) {
                  e.preventDefault();
                  e.stopPropagation();
               } else {
                  e.preventDefault();
                  if (bannerFile === "" && !isEdit) {
                     alert("Mohon upload file terlebih dahulu");
                  } else {
                     props.onSubmit(
                        singleBanner.id,
                        singleBanner.url,
                        title,
                        subTitle,
                        bannerFile,
                        fileFormat
                     );
                     setTitle("");
                     setSubTitle("");
                     setBannerFile("");
                     setFileFormat("");
                     history.replace("/master/login-banner/list");
                  }
               }
               setValidated(true);
            }}>
            <Form.Group className="mb-4">
               {/* <Image src={bannerFile} fluid />
               <Form.File
                  label="File Banner"
                  className="text-truncate"
                  onChange={(event: any) => {
                     setBannerFile("");
                     setFileFormat("");
                     if (event.target.files[0] !== undefined) {
                        setFileFormat(event.target.files[0].type.split("/")[1]);
                        convertBase64(event.target.files[0], (result: any) => {
                           setBannerFile(result);
                        });
                     } else {
                        console.log("error");
                     }
                  }}
               /> */}
               <Form.Label>Gambar Banner</Form.Label>

               <Dropzone onDrop={(acceptedFiles) => handleAcceptedImageFiles(acceptedFiles)}>
                  {({ getRootProps, getInputProps }) => (
                     <div className="dropzone file-upload-frame w-100">
                        <div className="dz-message needsclick" {...getRootProps()}>
                           <input {...getInputProps()} />

                           <div className="text-center file-upload-label">
                              <div>
                                 {(bannerFile !== "" || isEdit) && (
                                    <>
                                       <img
                                          src={
                                             isEdit
                                                ? bannerFile === ""
                                                   ? getMediaEndpoint(singleBanner.url)
                                                   : bannerFile
                                                : bannerFile
                                          }
                                          alt={"banner-login"}
                                          className="file-upload-image mb-3"
                                       />
                                       <br />
                                    </>
                                 )}
                                 {bannerFile === "" && !isEdit && (
                                    <>
                                       <FontAwesomeIcon
                                          icon={faUpload}
                                          width="48"
                                          className="file-upload-icon mb-3"
                                       />
                                       <br />
                                    </>
                                 )}
                                 <span>Upload File</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  )}
               </Dropzone>
            </Form.Group>
            <Form.Group className="mb-4">
               <Form.Label>Judul Banner</Form.Label>
               <Form.Control
                  type="text"
                  placeholder="Masukkan Title Banner"
                  onChange={onTitleChange}
                  value={title}
                  required
               />
            </Form.Group>
            <Form.Group>
               <Form.Label>Sub Judul Banner</Form.Label>
               <Form.Control
                  type="text"
                  placeholder="Masukkan Sub Title Banner"
                  onChange={onSubTitleChange}
                  value={subTitle}
                  required
               />
            </Form.Group>
            <div className="text-center mt-5">
               <button
                  className="btn button-outline-primary mr-3"
                  type="reset"
                  onClick={onCancelHandler}>
                  BATALKAN
               </button>
               <button className="btn button-primary" type="submit">
                  {isEdit ? "EDIT BANNER" : "TAMBAH BANNER"}
               </button>
            </div>
         </Form>
      </>
   );
};

export interface LoginBannerFormProps {
   onSubmit: (
      id: string,
      defaultBanner: string,
      title: string,
      subTitle: string,
      bannerFile: string,
      fileFormat: string
   ) => void;
   isEdit: boolean;
}

export default LoginBannerForm;
