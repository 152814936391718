import React, {ChangeEvent, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Container, Row, Col, Form} from "react-bootstrap";
import WalletTable from "../components/WalletTable";
import {useHistory} from "react-router-dom";

const UserBalancePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const balanceState = useSelector(
  //   (state: RootState) => state.userBalance.list ?? {} as CurrentBalance
  // );

  useEffect(() => {
    // dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateClicked = () => {
    history.push("/admin/create-send-balance")
  }

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <div>
            <h4 className="card-label d-inline">Kirim Saldo</h4>
            <button className="float-right btn button-primary" onClick={onCreateClicked}>Kirim Saldo</button>
          </div>
          <WalletTable />
        </Col>
      </Row>
    </Container>
  );
};

export default UserBalancePage;
