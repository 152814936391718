import {useDispatch} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";
import {OptionModel} from "../../@core/models/types";
import {getBlogAutoComplete} from "../../features/master/blog/blog.reducer";
import {AppDispatch} from "../../app/store";

const useAsyncBlogOption = () => {
    const dispatch : AppDispatch = useDispatch();

    const getBlogOptions = async (inputValue: string) => {
        const resultAction = await dispatch(getBlogAutoComplete(
            inputValue.toLowerCase() === "" ? "a" : inputValue.toLowerCase(),
        ));

        const originalPromiseResult = unwrapResult(resultAction);
        let _option = originalPromiseResult?.map((item: any) => {
            return {
                value: item.id,
                label: item.title,
            }
        });
        return _option as OptionModel[];
    };

    return {getBlogOptions} as const;
};

export default useAsyncBlogOption;
