import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {
  getRevisionOptionList,
  deleteRevisionOption, resetStatus,
} from "../revisionOption.reducer";
import { Link } from "react-router-dom";
import { RevisionStatusModel } from "../models";
import DataTable, {TableColumn} from "react-data-table-component";
import DefaultSubHeader from "../../../../components/DataTable/DefaultSubHeader";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { ElementPropModel } from "../../../../components/DataTable/models";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {deletedStatusOption, deletedWarnOption} from "../../../../helpers/alertContent";
import {ApiErrorResponse} from "../../../../index";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";

const RevisionOptionTable = () => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const revisionOptionState = useSelector(
    (state: RootState) => state.revisionOption.list ?? []
  );
  const status = useSelector((state: RootState) => state.revisionOption.status);
  const error = useSelector((state: RootState) => state.revisionOption.error ?? ({} as ApiErrorResponse<any>));

  // datatable components

  const [filterText, setFilterText] = useState("");

  const filteredItems = revisionOptionState.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    dispatch(getRevisionOptionList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status !== undefined && status !== ApiResponseStatus.pending) {
      MySwal.fire(deletedStatusOption(status === ApiResponseStatus.success, error.data?.message)).then(() => {
        dispatch(resetStatus());
        dispatch(getRevisionOptionList());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error]);

  const onSetterFilterText = (value: string) => {
    setFilterText(value);
  };

  const onDelete = (id: string) => {
    MySwal.fire(deletedWarnOption).then((result) => {
      if (result.isConfirmed) {
        dispatch(
            deleteRevisionOption({
              id: id,
              name: "deleted",
              description: "deleted",
            } as RevisionStatusModel)
        );
      }
    });
  };

  const elementProps = {
    placeholder: "cari berdasarkan nama",
    buttonName: "tambah opsi revisi",
    createUrl: "/master/revision-option/create",
    subHeaderLine: true,
  } as ElementPropModel;
  const columns: TableColumn<RevisionStatusModel>[] = [
    {
      name: "NO.",
      width: "60px",
      cell: (_d: any, index: number) => index + 1,
    },
    {
      name: "NAMA OPSI",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "DESKRIPSI",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "AKSI",
      cell: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/master/revision-option/edit/${d.id}`,
            }}
          >
            <button className="btn button-primary mr-2">EDIT</button>
          </Link>
          <button
            onClick={() => onDelete(d.id)}
            className="btn button-danger"
          >
            HAPUS
          </button>
        </>
      ),
    },
  ];
  // end of datatable components
  return (
    <>
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={false}
        noHeader
        subHeader
        subHeaderComponent={DefaultSubHeader(
          filterText,
          false,
          onSetterFilterText,
          elementProps
        )}
        customStyles={TableCustomStyles}
        persistTableHead
      />
    </>
  );
};

export default RevisionOptionTable;
