import axios from "axios";
import { GearOptionModel } from "./models";
import { endpoint } from "../../../constants";

export default class GearOptionApi {
  static create(data: GearOptionModel) {
    return axios
      .post<any>(endpoint + "/api/Gear/Create", data)
      .then((res) => res.data.data);
  }

  static getList() {
    return axios
      .get<any>(endpoint + "/api/Gear/GetList")
      .then((res) => res.data.data);
  }

  static getSingleItem(id: string) {
    return axios
      .get<any>(endpoint + "/api/Gear/GetSingleItem?id=" + id)
      .then((res) => res.data.data);
  }

  static update(data: GearOptionModel) {
    return axios
      .post<any>(endpoint + "/api/Gear/Update", data)
      .then((res) => res.data.data);
  }

  static delete(data: GearOptionModel) {
    return axios
      .post<any>(endpoint + "/api/Gear/Delete", data)
      .then((res) => res.data.data);
  }
}
