import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import ReactHtmlParser from "react-html-parser";

const AboutMePage = () => {
  const DataProfessionalState = useSelector(
    (state: RootState) => state.dataDiri.professionalSingle?.aboutMePage ?? ""
  );

  const DataVendorState = useSelector(
    (state: RootState) => state.dataDiri.vendorSingle?.aboutMePage ?? ""
  );

  const checkAboutMe = () => {
    let stringHtml: string;
    if (DataProfessionalState !== "") {
      stringHtml = DataProfessionalState;
    } else if (DataVendorState !== "") {
      stringHtml = DataVendorState;
    } else {
      stringHtml = "Belum ada data yang dibuat";
    }
    return stringHtml;
  };

  return (
    <>
      <h5 className="mb-4 mt-4 data_diri_tab_menu_title">TENTANG SAYA</h5>
      <div className="aboutme-content">{ReactHtmlParser(checkAboutMe())}</div>
    </>
  );
};

export default AboutMePage;
