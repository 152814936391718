import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import DataAdminTable from "../components/DataAdminTable";
import { RootState } from "../../../app/store";
import { getUserList } from "../dataAdmin.reducer";
import {UserTypeEnum} from "../../dataMitra/dataMitra/models";

const DataAdminPage = () => {
  const dispatch = useDispatch();
  const statusState = useSelector(
    (state: RootState) => state.dataAdmin.dateCreated
  );

  useEffect(() => {
    dispatch(getUserList({
      userType: UserTypeEnum.Admin,
      registerStatus: undefined,
      skip: 0,
      take: 10,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusState]);

  useEffect(() => {
    // dispatch(resetSingle());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <h4 className="card-label">Data Admin</h4>
          <DataAdminTable />
        </Col>
      </Row>
    </Container>
  );
};

export default DataAdminPage;
