import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Row, Col, Image, Card, Badge, Modal, Carousel} from "react-bootstrap";
import {getPortfolio} from "../portfolio.reducer";
import {PortfolioModel} from "../models";
import {RootState} from "../../../../app/store";
import "./portfolio.css";
import EmptyPortfolioImage from "../../../../assets/images/empty_portfolio.svg";
import {getExpertise} from "../../tagExpertise/tagExpertise.reducer";
import {ProfessionalModel, VendorModel} from "../../dataMitra/models";
import {MitraType} from "../../dataDiri/models";
import {checkObjectIsNotEmpty} from "../../../../helpers/checkEmptyObject";
import {getMediaEndpoint} from "../../../../helpers/imageManipulation";

const PortfolioPage = () => {
    const dispatch = useDispatch();

    const [mitraType, setMitraType] = useState<MitraType>(0);

    const portfolioList = useSelector((state: RootState) => state.portfolio.list ?? []);
    const professionalState = useSelector(
        (state: RootState) => state.dataDiri.professionalSingle ?? ({} as ProfessionalModel)
    );
    const vendorState = useSelector(
        (state: RootState) => state.dataDiri.vendorSingle ?? ({} as VendorModel)
    );

    const [isShow, setIsShow] = useState(false);

    const [singlePortfolio, setSinglePortfolio] = useState({} as PortfolioModel);

    const toggleModal = () => (isShow ? setIsShow(false) : setIsShow(true));

    const onPortfolioClicked = (item: PortfolioModel) => {
        toggleModal();
        setSinglePortfolio(item);
    };

    useEffect(() => {
        if (vendorState.id === "false") {
            setMitraType(MitraType.professional);
        } else if (professionalState.id === "false") {
            setMitraType(MitraType.vendor);
        }
    }, [vendorState, professionalState]);

    useEffect(() => {
        if (mitraType === MitraType.professional) {
            if (checkObjectIsNotEmpty(professionalState)) {
                dispatch(getExpertise(professionalState.id));
                dispatch(getPortfolio(professionalState.id));
            }
        } else if (mitraType === MitraType.vendor) {
            if (checkObjectIsNotEmpty(vendorState)) {
                dispatch(getExpertise(vendorState.id));
                dispatch(getPortfolio(vendorState.id));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [professionalState, vendorState, mitraType]);

    return (
        <>
            <h5 className="mb-4 mt-4 data_diri_tab_menu_title">PORTOFOLIO</h5>
            {portfolioList.length !== 0 && (
                <Row className="empty_portfolio">
                    <Col md={12}>
                        {portfolioList.map((item) => {
                            return (
                                <Card
                                    className="portfolio_card d-inline-block"
                                    key={item.id}
                                    onClick={() => onPortfolioClicked(item)}>
                                    <Card.Img
                                        variant="top"
                                        src={getMediaEndpoint(item.path[0])}
                                        className="portfolio_card_image"
                                    />
                                    <Card.Body style={{padding: "0"}}>
                                        <div className="pt-3 pl-3 text-left">
                                            <h6>{item.name === null ? "Portofolio" : item.name}</h6>
                                        </div>
                                        <div className=" text-left">
                                            {item.expertiseList.map((itemTag) => (
                                                <Badge
                                                    pill
                                                    className="badge-tag ml-2 mr-1 mb-2 mt-2 badge-sm"
                                                >
                                                    {itemTag.expertiseName}
                                                </Badge>
                                            ))}
                                        </div>
                                    </Card.Body>
                                </Card>
                            );
                        })}
                    </Col>
                </Row>
            )}
            {portfolioList.length === 0 && (
                <Row className="empty_portfolio">
                    <Col md={12} className="text-center">
                        <Image
                            className="empty_portfolio_image justify-content-center"
                            src={EmptyPortfolioImage}
                        />
                    </Col>
                    <Col md={12} className="text-center mt-4 mb-4">
                        <h6 className="empty_portfolio_title">User belum mempunyai portofolio apapun</h6>
                    </Col>
                </Row>
            )}
            <Modal
                show={isShow}
                backdrop="static"
                keyboard={false}
                size="lg"
                className="profile_modal">
                <Modal.Body>
                    <Row>
                        <Col md={12} className="mb-3">
                            <h5>
                                {singlePortfolio.name === null ? "Detail Portofolio" : singlePortfolio.name}
                                <div className="float-right" onClick={toggleModal}>
                                    <span className="material-icons modal-close">close</span>
                                </div>
                            </h5>
                        </Col>
                    </Row>
                    <Col md={12}>
                        {/*<Image src={endpoint + singlePortfolio.path} className="w-100" />*/}
                        <Carousel
                            fade
                            indicators={false}
                            nextIcon={<span aria-hidden="true" className={`slider_next_icon`} />}
                            prevIcon={<span aria-hidden="true" className={`slider_prev_icon`} />}
                            interval={null}>
                            {singlePortfolio.path?.map((media, index) => {
                                return (
                                    <Carousel.Item key={"portfolio-image" + index}>
                                        <img
                                            className="w-100"
                                            src={getMediaEndpoint(media)}
                                            alt="attachment"
                                            height={500}
                                            style={{objectFit: "contain"}}
                                        />
                                    </Carousel.Item>
                                )
                            })}
                        </Carousel>
                    </Col>
                    <Col md={12}>
                        <p className="mt-4 portfolio-description">
                            {singlePortfolio.description === null
                                ? "Tidak ada deskripsi"
                                : singlePortfolio.description}
                        </p>
                    </Col>
                    <Col md={12} className='mb-2'>
                        <div className=" text-left">
                            {singlePortfolio.expertiseList?.map((itemTag) => (
                                <Badge
                                    pill
                                    className="badge-tag mr-1 badge-sm"
                                >
                                    {itemTag.expertiseName}
                                </Badge>
                            ))}
                        </div>
                    </Col>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PortfolioPage;
