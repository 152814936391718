import React, { Fragment, useEffect, useState } from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../../app/store";
import DefaultSubHeader from "../../../../components/DataTable/DefaultSubHeader";
import { ElementPropModel } from "../../../../components/DataTable/models";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import {deleteDiscount, getDiscountList, resetStatus} from "../discount.reducer";
import {deletedStatusOption, deletedWarnOption} from "../../../../helpers/alertContent";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {ApiErrorResponse} from "../../../../index";
import {VoucherModelList} from "../models";

const DiscountTable = () => {
   const dispatch = useDispatch();
   const MySwal = withReactContent(Swal);

   const [filterText, setFilterText] = useState<string>("");

   const discountList = useSelector((state: RootState) => state.discount.list ?? []);
   const dateCreated = useSelector((state: RootState) => state.discount.dateCreated);
   const status = useSelector((state: RootState) => state.discount.status);
   const error = useSelector((state: RootState) => state.discount.error ?? ({} as ApiErrorResponse<any>));

   const elementProps = {
      placeholder: "cari berdasarkan nama",
      buttonName: "tambah voucher",
      createUrl: "/master/discount/create",
      subHeaderLine: true,
   } as ElementPropModel;

   useEffect(() => {
      if (status !== undefined && status !== ApiResponseStatus.pending) {
         MySwal.fire(deletedStatusOption(status === ApiResponseStatus.success, error.data?.message)).then(() => {
            dispatch(resetStatus());
            dispatch(getDiscountList());
         });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [status, error]);

   const onDelete = (voucherId: string) => {
      MySwal.fire(deletedWarnOption).then((result) => {
         if (result.isConfirmed) {
            dispatch(deleteDiscount(voucherId));
         }
      });
   };

   const onSetterFilterText = (value: string) => {
      setFilterText(value);
   };

   const filteredDiscountItems = discountList.filter(
      (item) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
   );

   useEffect(() => {
      if (dateCreated !== "") {
         dispatch(getDiscountList());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dateCreated]);

   const dateSort = (rowA: { formattedExpiredDate: string }, rowB: { formattedExpiredDate: string }) => {
      const a = rowA.formattedExpiredDate.split('/').reverse().join('');
      const b = rowB.formattedExpiredDate.split('/').reverse().join('');

      return a.localeCompare(b);
   };

   const columns: TableColumn<VoucherModelList>[] = [
      {
         name: "NO.",
         width: "60px",
         cell: (_d: any, index: number) => index + 1,
      },
      {
         name: "NAMA VOUCHER",
         selector: (row) => row.name,
         sortable: true,
      },
      {
         name: "DESKRIPSI",
         selector: (row) => row.description,
         sortable: true,
      },
      {
         name: "TANGGAL KADALUARSA",
         selector: (row) => row.formattedExpiredDate,
         sortable: true,
         sortFunction: dateSort,
      },
      {
         name: "AKSI",
         cell: (d: { id: any }) => (
            <>
               <Link
                  to={{
                     pathname: `/master/discount/edit/${d.id}`,
                  }}>
                  <button className="btn button-primary mr-2">EDIT</button>
               </Link>
               <button
                  onClick={() => onDelete(d.id)}
                  className="btn button-danger">
                  HAPUS
               </button>
            </>
         ),
      },
   ];

   return (
      <Fragment>
         <DataTable
            columns={columns}
            data={filteredDiscountItems}
            pagination
            paginationResetDefaultPage={false}
            noHeader
            subHeader
            subHeaderComponent={DefaultSubHeader(
               filterText,
               false,
               onSetterFilterText,
               elementProps
            )}
            customStyles={TableCustomStyles}
            persistTableHead
         />
      </Fragment>
   );
};

export default DiscountTable;
