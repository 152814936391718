import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import StoreReportDetailTable from "../components/StoreReportDetailTable";

const StoreReportPage = () => {
    return (
        <Container className="content-body" fluid>
            <Row>
                <Col md={12}>
                    <h4 className="card-label">Penjualan</h4>
                    <StoreReportDetailTable/>
                </Col>
            </Row>
        </Container>
    );
};

export default StoreReportPage;
