import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import FileUploadApi from "../../media/fileUpload/fileUpload.api";
import MitraTierApi from "./mitraTier.api";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {CreateMitraTierModel, MitraTierModel, MitraTierRequest} from "./models";

export interface MitraTierSlice {
   name?: string;
   list?: MitraTierModel[];
   single?: MitraTierModel;
   isLoading?: boolean;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
}

export const createMitraTier = createAsyncThunk(
   "mitraTier/createMitraTier",
   async (args: CreateMitraTierModel, { rejectWithValue }) => {
      try {
         const response = await FileUploadApi.uploadImage({
            file_name: new Date().toString() + "." + args.image.fileFormat,
            base64url: (args.image.fileValue as string).split(",")[1],
            imagepath: "professional-type",
            location: "professional-type",
         });
         if (response.status === 200) {
            let formData = args;
            formData.icon = response.data.imagepath;
            return await MitraTierApi.createMitraTier(formData);
         }
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getMitraTierList = createAsyncThunk(
   "mitraTier/getMitraTierList",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await MitraTierApi.getMitraTierList();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getSingleMitraTier = createAsyncThunk(
   "mitraTier/getSingleMitraTier",
   async (id: string, { rejectWithValue }) => {
      try {
         return await MitraTierApi.getSingleItem(id);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const updateMitraTier = createAsyncThunk(
   "mitraTier/updateMitraTier",
   async (args: CreateMitraTierModel, { rejectWithValue }) => {
      try {
         if (args.image.fromApi) {
            return await MitraTierApi.updateMitraTier(args);
         } else {
            const response = await FileUploadApi.uploadImage({
               file_name: new Date().toString() + "." + args.image.fileFormat,
               base64url: (args.image.fileValue as string).split(",")[1],
               imagepath: "professional-type",
               location: "professional-type",
            });
            if (response.status === 200) {
               let formData = args;
               formData.icon = response.data.imagepath;
               return await MitraTierApi.updateMitraTier(formData);
            }
         }
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const deleteMitraTier = createAsyncThunk(
   "mitraTier/deleteMitraTier",
   async (args: MitraTierRequest, { rejectWithValue }) => {
      try {
         return await MitraTierApi.deleteProfessionalType(args);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const mitraTierSlice = createSlice({
   name: "mitraTierState",
   initialState: {} as MitraTierSlice,
   reducers: {
      setFilter: (state, action) => {
         state.list = action.payload;
      },
      resetSingle: (state) => {
         state.single = {} as MitraTierModel;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(createMitraTier.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(createMitraTier.fulfilled, (state, { payload }) => {
         state.name = new Date().toISOString();
         state.isLoading = false;
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(createMitraTier.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(getSingleMitraTier.pending, (state) => {
         state.single = undefined;
         state.isLoading = true;
      });
      builder.addCase(getSingleMitraTier.fulfilled, (state, { payload }) => {
         state.single = payload;
         state.isLoading = false;
      });
      builder.addCase(getSingleMitraTier.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(getMitraTierList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getMitraTierList.fulfilled, (state, { payload }) => {
         state.list = payload;
         state.isLoading = false;
      });
      builder.addCase(getMitraTierList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
      builder.addCase(updateMitraTier.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(updateMitraTier.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.name = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(updateMitraTier.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
      builder.addCase(deleteMitraTier.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(deleteMitraTier.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.name = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(deleteMitraTier.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
   },
});

export const { setFilter, resetSingle, resetStatus } = mitraTierSlice.actions;
export default mitraTierSlice.reducer;
