import axios from "axios";
import {MitraTierRequest,} from "./models";
import {endpoint} from "../../../constants";

export default class MitraTierApi {
    static createMitraTier(args: MitraTierRequest) {
        return axios
            .post<any>(
                endpoint + "/api/Tier/Create",
                args
            )
            .then((res) => res.data.data);
    }

    static getMitraTierList() {
        return axios
            .get<any>(endpoint + "/api/Tier/GetList")
            .then((res) => res.data.data);
    }

    static getSingleItem(id: string) {
        return axios
            .get<any>(
                endpoint +
                "/api/Tier/GetSingleItem", {
                    params: {
                        id: id
                    }
                }
            )
            .then((res) => res.data.data);
    }

    static updateMitraTier(args: MitraTierRequest) {
        return axios
            .post<any>(endpoint + "/api/Tier/Update", args)
            .then((res) => res.data.data);
    }

    static deleteProfessionalType(args: MitraTierRequest) {
        return axios
            .post<any>(endpoint + "/api/Tier/Delete", args)
            .then((res) => [
                {response: res.data.data},
                {id: args.id},
            ]);
    }
}
