import React, { useEffect, useState } from "react";
import { Col, Row, Image, FormControl, Badge } from "react-bootstrap";
import Moment from "react-moment";
import "moment/locale/id";
import { addDefaultProfileImage } from "../../../../../helpers/defaultBrokenImage";
import { RoomModel } from "../../../models";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { checkObjectIsNotEmpty } from "../../../../../helpers/checkEmptyObject";
import {useParams} from "react-router-dom";

const RoomList = ({ singleRoom = {} as RoomModel, ...props }: RoomListProps) => {
   const [unread, setUnread] = useState<number>(0);
   let {projectId} = useParams<{ projectId: string }>();

   const onRoomSelected = (uid: string) => {
      props.selectedRoom(uid);
   };

   const userId = useSelector((state: RootState) => state.user.id);

   useEffect(() => {
      if (checkObjectIsNotEmpty(singleRoom) && userId !== "") {
         const _user = singleRoom.users.find((user) => user.userId === userId);
         setUnread(_user !== undefined ? _user.unread : 0);
      }
   }, [singleRoom, userId]);

   useEffect(() => {
      if (projectId !== undefined && checkObjectIsNotEmpty(singleRoom)) {
         if (!singleRoom.isSingleChat && singleRoom.projectId === projectId.toString()) {
            props.selectedRoom(singleRoom.id);
         }
      }
   }, [projectId, singleRoom]);

   return (
      <div className="d-block" onClick={() => onRoomSelected(singleRoom.id)}>
         <div className={`d-flex mb-2 chat_room_list`}>
            <div className={`mr-3 d-inline`}>
               <img
                  className={`profile_picture`}
                  src="https://placeimg.com/640/480/people"
                  onError={addDefaultProfileImage}
                  alt="profile"></img>
            </div>
            <div className={`room_name`}>
               <div className="project_name_group">
                  <div className="project_name">
                     <span>{singleRoom.roomName}</span>
                  </div>
                  <div className="last_chat_time">
                     {new Date(singleRoom.lastTimeMessage).toLocaleDateString() ===
                     new Date().toLocaleDateString() ? (
                        <Moment format="HH:mm" withTitle>
                           {singleRoom.lastTimeMessage}
                        </Moment>
                     ) : (
                        <Moment format="DD/MM/YYYY">{singleRoom.lastTimeMessage}</Moment>
                     )}
                  </div>
               </div>
               <div className="d-flex">
                  <span className={`last_chat`}>{singleRoom.lastMessage}</span>
                  {unread > 0 && (
                     <Badge pill variant="danger" className="chat_unread">
                        {unread < 99 ? unread : 99 + "+"}
                     </Badge>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

export interface RoomListProps {
   singleRoom: RoomModel;
   selectedRoom: (uid: string) => void;
}

export default RoomList;
