import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { RootState } from "../../../app/store";
import ReplyReportIssue from "../components/ReplyReportIssue";
import { ReportIssueModel } from "../models";
import {
   getIssueAttachmentItem, getReportIssueList,
   getReportIssueSingleItem,
   resetStatus,
} from "../reportIssue.reducer";
import ReactPlayer from "react-player";
import Viewer from "react-viewer";
import {getMediaEndpoint} from "../../../helpers/imageManipulation";
import {customSavedOption} from "../../../helpers/alertContent";
import {ApiErrorResponse} from "../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const ReportIssueDetailPage = () => {
   const MySwal = withReactContent(Swal);

   const dispatch = useDispatch();
   let { id } = useParams<{ id: string }>();

   let history = useHistory();

   const [showReplyModal, setShowReplyModal] = useState<boolean>(false);
   const [imagePreviewUrl, setImagePreviewUrl] = useState<string>("");
   const [imgVisible, setImgVisible] = useState<boolean>(false);

   const onCancelHandler = () => {
      history.push("/master/report-issue/list");
   };

   const onReplyClicked = () => {
      setShowReplyModal(true);
   };

   const showReplyModalHandler = () => {
      setShowReplyModal((prevState) => !prevState);
   };

   const singleReportIssue = useSelector(
      (state: RootState) => state.reportIssue.single ?? ({} as ReportIssueModel)
   );

   const reportIssueAttachment = useSelector(
      (state: RootState) => state.reportIssue.listAttachment ?? []
   );

   const status = useSelector((state: RootState) => state.reportIssue.status ?? "");
   const error = useSelector((state: RootState) => state.reportIssue.error ?? {} as ApiErrorResponse<any>);

   useEffect(() => {
      if (status !== undefined && status !== '') {
         MySwal.fire(customSavedOption(status === 'success', error.data?.message, 'Berhasil Mengirim Balasan', 'Gagal Mengirim Balasan: ' + error.data?.message)).then(() => {
            dispatch(resetStatus());
            dispatch(getReportIssueList());
            setShowReplyModal(false);
         });
      }
   }, [status, error]);

   useEffect(() => {
      if (id !== undefined) {
         dispatch(getReportIssueSingleItem(id));
         dispatch(getIssueAttachmentItem(id));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id]);

   const onImageClicked = (path: string) => {
      setImagePreviewUrl(getMediaEndpoint(path));
      setImgVisible(true);
   };

   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <h4 className="card-label">PRATINJAU - LAPORAN ISU</h4>
               <hr />
               <Row>
                  <Col md={8} xl={5}>
                     <Form.Group className="mb-4">
                        <Form.Label>Judul Pratinjau</Form.Label>
                        <Form.Control plaintext readOnly defaultValue={singleReportIssue.title} />
                     </Form.Group>
                     <Form.Group className="mb-4">
                        <Form.Label>E-Mail Pengirim</Form.Label>
                        <Form.Control plaintext readOnly defaultValue={singleReportIssue.email} />
                     </Form.Group>
                     <Form.Group className="mb-4">
                        <Form.Label>Deskripsi Isu</Form.Label>
                        <Form.Control
                           as="textarea"
                           rows={3}
                           plaintext
                           readOnly
                           defaultValue={singleReportIssue.description}
                        />
                     </Form.Group>

                     <Form.Group className="mb-4">
                        <Form.Label>Gambar Isu</Form.Label>
                        <br />
                        {reportIssueAttachment.map((attachment) => (
                           <>
                              {attachment.path.split(".")[1] === "mp4" ||
                              attachment.path.split(".")[1] === "webm" ? (
                                 <>
                                    <ReactPlayer controls url={getMediaEndpoint(attachment.path)} /> <br />
                                 </>
                              ) : (
                                 <>
                                    <img
                                       src={getMediaEndpoint(attachment.path)}
                                       alt="issue"
                                       onClick={() => onImageClicked(attachment.path)}
                                       className="w-100"
                                    />
                                    <br />
                                 </>
                              )}
                           </>
                        ))}
                        <Viewer
                           visible={imgVisible}
                           onClose={() => {
                              setImgVisible(false);
                           }}
                           images={[
                              {
                                 src: imagePreviewUrl,
                                 alt: "",
                              },
                           ]}
                        />
                     </Form.Group>
                     <div className="text-center mt-5">
                        <button
                           className="btn button-outline-primary mr-2"
                           type="reset"
                           onClick={onCancelHandler}>
                           BATALKAN
                        </button>
                        <button
                           className="btn button-primary"
                           type="button"
                           onClick={onReplyClicked}>
                           KIRIM BALASAN
                        </button>
                     </div>
                  </Col>
               </Row>
            </Col>
         </Row>
         <ReplyReportIssue
            singleReportIssue={singleReportIssue}
            show={showReplyModal}
            handleClose={showReplyModalHandler}
         />
      </Container>
   );
};

export default ReportIssueDetailPage;
