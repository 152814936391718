import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { RevisionStatusModel } from "./models";
import RevisionOptionApi from "./revisionOption.api";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";

export interface CreateRevisionOptionSlice {
  date?: string;
  list?: RevisionStatusModel[];
  single?: RevisionStatusModel;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: ApiResponseStatus;
}

export const createRevisionOption = createAsyncThunk(
  "revisionOptionState/createRevisionOption",
  async (data: RevisionStatusModel, { rejectWithValue }) => {
    try {
      return await RevisionOptionApi.create(data);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getRevisionOptionList = createAsyncThunk(
  "revisionOptionState/revisionOptionList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await RevisionOptionApi.getList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getRevisionOptionSingleItem = createAsyncThunk(
  "revisionOptionState/revisionOptionSingleItem",
  async (id: string, { rejectWithValue }) => {
    try {
      return await RevisionOptionApi.getSingleItem(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateRevisionOption = createAsyncThunk(
  "revisionOptionState/updateRevisionOption",
  async (data: RevisionStatusModel, { rejectWithValue }) => {
    try {
      return await RevisionOptionApi.update(data);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteRevisionOption = createAsyncThunk(
  "revisionOptionState/deleteRevisionOption",
  async (data: RevisionStatusModel, { rejectWithValue }) => {
    try {
      return await RevisionOptionApi.delete(data);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const createRevisionOptionSlice = createSlice({
  name: "createRevisionOptionState",
  initialState: {} as CreateRevisionOptionSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as RevisionStatusModel;
    },
    resetStatus: (state) => {
      state.status = ApiResponseStatus.pending;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createRevisionOption.pending, (state) => {
      state.isLoading = true;
      state.status = ApiResponseStatus.pending;
    });
    builder.addCase(createRevisionOption.fulfilled, (state) => {
      state.date = new Date().toISOString();
      state.isLoading = false;
      state.status = ApiResponseStatus.success;
    });
    builder.addCase(createRevisionOption.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = ApiResponseStatus.failed;
    });
    builder.addCase(getRevisionOptionSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(
      getRevisionOptionSingleItem.fulfilled,
      (state, { payload }) => {
        state.single = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(
      getRevisionOptionSingleItem.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
      }
    );
    builder.addCase(getRevisionOptionList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRevisionOptionList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getRevisionOptionList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateRevisionOption.pending, (state) => {
      state.isLoading = true;
      state.status = ApiResponseStatus.pending;
    });
    builder.addCase(updateRevisionOption.fulfilled, (state) => {
      state.isLoading = false;
      state.date = new Date().toISOString();
      state.status = ApiResponseStatus.success;
    });
    builder.addCase(updateRevisionOption.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
      state.status = ApiResponseStatus.failed;
    });
    builder.addCase(deleteRevisionOption.pending, (state) => {
      state.isLoading = true;
      state.status = ApiResponseStatus.pending;
    });
    builder.addCase(deleteRevisionOption.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.date = new Date().toISOString();
      state.status = ApiResponseStatus.success;
    });
    builder.addCase(deleteRevisionOption.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
      state.status = ApiResponseStatus.failed;
    });
  },
});

export const { setFilter, resetSingle, resetStatus } = createRevisionOptionSlice.actions;
export default createRevisionOptionSlice.reducer;
