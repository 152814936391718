import React, { useMemo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { ElementPropModel } from "./models";

const DefaultSubHeader = (
  filterText: string,
  resetPaginationToggle: boolean,
  setterFilterText: (value: string) => void,
  props: ElementPropModel
) => {
  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div className="w-100">
        {props.subHeaderLine && <hr />}
        <div className="d-inline w-50">
          <div className="input-field-group">
            <FontAwesomeIcon
              className="input-field-group-icon"
              icon={faSearch}
              // style={{ color: "#160E25" }}
            />
            <input
              id="search"
              type="search"
              placeholder={props.placeholder}
              className="input-field-group-text"
              style={{ width: "18rem" }}
              value={filterText}
              onChange={(e) => {
                setterFilterText(e.target.value);
              }}
              onKeyDown={props.isEnterAction ? props?.enterAction : () => {}}
            />
          </div>
        </div>
        {props.buttonName !== "" && (
          <div className="d-inline float-right">
            <Link
              to={{
                pathname: props.createUrl,
              }}
            >
              <button
                className="btn button-primary text-uppercase"
                style={{ height: "45px" }}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />{" "}
                {props.buttonName}
              </button>
            </Link>
          </div>
        )}
        {props.subHeaderLine && <hr />}
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  return subHeaderComponentMemo;
};

export default DefaultSubHeader;
