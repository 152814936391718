import React from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import RequestAdminTable from "../components/RequestAdminTable";

const RequestAdminPage = () => {
   const dispatch = useDispatch();

   // const statusState = useSelector(
   //   (state: RootState) => state.blogCategory.name
   // );

   // useEffect(() => {
   //   dispatch(getBlogCategoryList());
   //   // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [statusState]);

   // useEffect(() => {
   //   dispatch(resetSingle());
   //   // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, []);

   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <h4 className="card-label">Permintaan Chat Admin</h4>
               <RequestAdminTable />
            </Col>
         </Row>
      </Container>
   );
};

export default RequestAdminPage;
