import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import ReportIssueTable from "../components/ReportIssueTable";
import { Container, Row, Col } from "react-bootstrap";
import {resetSingle} from "../reportIssue.reducer";

const ReportIssuePage = () => {
  const dispatch = useDispatch();

  // const statusState = useSelector(
  //   (state: RootState) => state.blogCategory.name
  // );

  // useEffect(() => {
  //   dispatch(getBlogCategoryList());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [statusState]);

  useEffect(() => {
    dispatch(resetSingle());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <h4 className="card-label">Laporan Isu</h4>
          <ReportIssueTable />
        </Col>
      </Row>
    </Container>
  );
};

export default ReportIssuePage;
