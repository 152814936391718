import React, { Fragment } from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { LoginBannerModel } from "../models";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import { Link } from "react-router-dom";
import {getMediaEndpoint} from "../../../../helpers/imageManipulation";

const LoginBannerTable = () => {
   // const dispatch = useDispatch();

   //   Redux States
   const DataBannerState = useSelector((state: RootState) => state.loginBanner.list ?? []);

   // datatable components

   // const [filterText, setFilterText] = useState("");

   // const filteredItems = DataBannerState.filter(
   //    (item) =>
   //       (item.title && item.title.toLowerCase().includes(filterText.toLowerCase())) ||
   //       (item.subTitle && item.subTitle.toLowerCase().includes(filterText.toLowerCase()))
   // );

   const columns: TableColumn<LoginBannerModel>[] = [
      {
         name: "NO.",
         width: "60px",
         cell: (_d: any, index: number) => index + 1,
      },
      {
         name: "JUDUL",
         selector: (row) => row.title,
         sortable: true,
      },
      {
         name: "SUB JUDUL",
         selector: (row) => row.subTitle,
         sortable: true,
      },
      {
         name: "GAMBAR",
         cell: (d: { url: string }) => <Image src={getMediaEndpoint(d.url)} width="25%" />,
         sortable: false,
      },
      {
         name: "AKSI",
         width: "400px",
         cell: (d: { id: string }) => (
            <Fragment>
               <Link
                  to={{
                     pathname: `/master/login-banner/detail/${d.id}`,
                  }}>
                  <button className="btn button-primary mr-2">EDIT</button>
               </Link>
            </Fragment>
         ),
      },
   ];

   return (
      <>
         <DataTable
            columns={columns}
            data={DataBannerState}
            pagination
            paginationResetDefaultPage={false} // optionally, a hook to reset pagination to page 1
            noHeader
            subHeader
            customStyles={TableCustomStyles}
            persistTableHead
         />
      </>
   );
};

export default LoginBannerTable;
