import React, { Fragment } from "react";
import Moment from "react-moment";
import {endpoint} from "../../../../../../../constants";
import {addDefaultVideoImage} from "../../../../../../../helpers/defaultBrokenImage";

const AlbumMessage = ({
   senderName = "User",
   totalLastAdded = 0,
   content = "",
   createdAt = "",
   albumName = "",
   ...props
}: AlbumMessageProps) => {
   return (
      <Fragment>
         <div className={`message_list message_out`}>
            <div className={`message_list_item message_out`}>
               <div className={`message_box`}>
                  <div className={"message_image"} onClick={props.onMessageClick}>
                     <div className={`border}`}></div>
                     <img
                        className={`profile_image`}
                        src={endpoint + content}
                        onError={addDefaultVideoImage}
                        alt="profile"></img>
                     <div className={"message_album"}>
                        <span className={`d-block mb-2 album_name`}>{albumName}</span>
                        <span className={`d-block last_added`}>
                           {senderName} Menambahkan {totalLastAdded} Item
                        </span>
                     </div>
                  </div>
                  <span className={`message_time float-right`}>
                     <Moment format="HH:mm" withTitle>
                        {createdAt}
                     </Moment>
                  </span>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export interface AlbumMessageProps {
   content: string;
   createdAt: string;
   senderName: string;
   totalLastAdded: number;
   albumName: string;
   onMessageClick: () => void;
}

export default AlbumMessage;
