import { ImageUpload } from "../../../@core/models/media/ImageUpload";

export interface DiscountFormModel {
   id: string;
   name: string;
   description: string;
   termAndCondition: string;
   banner: string;
   code: string;
   expiredDate: string;
   isUnlimited: boolean;
   type: number;
   nominal: number;
   percentage: number;
   minimumTransaction: number;
   quota: number;
   image: ImageUpload;
   isActive: boolean;
}

export interface CreateVoucherModel {
   name: string;
   description: string;
   termAndCondition: string;
   banner: string;
   code: string;
   expiredDate: string;
   isUnlimited: boolean;
   type: number;
   nominal: number;
   percentage: number;
   minimumTransaction: number;
   quota: number;
}

export interface VoucherModel {
   id: string;
   name: string;
   description: string;
   termAndCondition: string;
   banner: string;
   code: string;
   expiredDate: string;
   isUnlimited: boolean;
   type: number;
   nominal: number;
   percentage: number;
   minimumTransaction: number;
   quota: number;
   isActive: boolean;
}

export interface VoucherModelList {
   id: string;
   name: string;
   description: string;
   termAndCondition: string;
   banner: string;
   code: string;
   expiredDate: string;
   formattedExpiredDate: string;
   isUnlimited: boolean;
   type: number;
   nominal: number;
   percentage: number;
   minimumTransaction: number;
   quota: number;
   isActive: boolean;
}

export enum discountType {
   percentage,
   nominal,
}
