import axios from "axios";
import { endpoint } from "../../constants";
import {
   CustomerCancellationFeeModel,
   MitraFeeModel,
   PartnerLevelModel,
   PartnerPointModel,
   PenaltyCancellationFee,
   UpdateResultLimitModel,
} from "./models";

export default class ProjectSettingApi {
   static getListCancellationFee() {
      return axios.get<any>(endpoint + "/api/Setting/GetList").then((res) => res.data.data);
   }

   static updateCancellationFee(fee: PenaltyCancellationFee) {
      return axios.post<any>(endpoint + `/api/Setting/Update`, fee).then((res) => res.data.data);
   }

   static getStoreCommission() {
      return axios.get<any>(endpoint + "/api/Setting/GetKomisi").then((res) => res.data.data);
   }

   static getListCustomerCancellationFee() {
      return axios.get<any>(endpoint + "/api/MitraFee/GetListCustomerFee").then((res) => res.data.data);
   }

   static updateCustomerCancellationFee(fee: CustomerCancellationFeeModel) {
      return axios.post<any>(endpoint + `/api/MitraFee/UpdateCustomerFee`, fee).then((res) => res.data.data);
   }

   static getListMitraFee() {
      return axios.get<any>(endpoint + "/api/MitraFee/GetList").then((res) => res.data.data);
   }

   static updateMitraFee(fee: MitraFeeModel) {
      return axios.post<any>(endpoint + `/api/MitraFee/Update`, fee).then((res) => res.data.data);
   }

   static getListPartnerPointSetting() {
      return axios
         .get<any>(endpoint + "/api/PartnerLevel/GetListPartnerPointSetting")
         .then((res) => res.data.data);
   }

   static updatePartnerPointSetting(point: PartnerPointModel) {
      return axios
         .post<any>(endpoint + `/api/PartnerLevel/UpdatePartnerPointSetting`, point)
         .then((res) => res.data.data);
   }

   static getListPartnerLevel() {
      return axios
         .get<any>(endpoint + "/api/PartnerLevel/GetList")
         .then((res) => res.data.data);
   }

   static updatePartnerLevel(level: PartnerLevelModel) {
      return axios
         .post<any>(endpoint + `/api/PartnerLevel/Update`, level)
         .then((res) => res.data.data);
   }

   static getListResultTimeLimit() {
      return axios.get<any>(endpoint + "/api/Setting/GetListSettingResult").then((res) => res.data.data);
   }

   static updateResultTimeLimit(limit: UpdateResultLimitModel) {
      return axios.post<any>(endpoint + `/api/Setting/UpdateSettingResult`, limit).then((res) => res.data.data);
   }
}
