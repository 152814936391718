import axios from "axios";
import { PersonalChatModel, RequestAdminModel } from "./models";
import { endpoint } from "../../constants";

import db from "../../utils/firebaseInit";
import firebase from "firebase";
import { MessageType } from "../../@core/models/chat/messageType";

const roomChatRef = db.collection("DIALOG_MESSAGES");

export default class RequestAdminApi {
   static getRequestAdminList() {
      return axios.get<any>(endpoint + "/api/RequestAdmin/GetList").then((res) => res.data.data);
   }

   static getRequestAdminSingleItem(issueId: string) {
      return axios
         .get<any>(endpoint + "/api/RequestAdmin/GetSingleItem?id=" + issueId)
         .then((res) => res.data.data);
   }

   static updateRequestAdmin(request: RequestAdminModel) {
      return axios
         .post<any>(endpoint + "/api/RequestAdmin/Update", request)
         .then((res) => res.data.data);
   }

   static deleteRequestAdmin(request: RequestAdminModel) {
      return axios
         .post<any>(endpoint + "/api/RequestAdmin/Delete", request)
         .then((res) => res.data.data);
   }

   static addAdminToChat(roomId: string, data: PersonalChatModel) {
      return roomChatRef
         .doc(roomId)
         .update({
            userId: firebase.firestore.FieldValue.arrayUnion(data.userId),
            users: firebase.firestore.FieldValue.arrayUnion(data),
         })
         .then(() => {
            roomChatRef
               .doc(roomId)
               .collection("MESSAGES")
               .add({
                  content: `${data.name} Telah Ditambahkan`,
                  createdAt: new Date().toISOString(),
                  messageType: MessageType.info,
               })
               .then(() => {});
            roomChatRef
               .doc(roomId)
               .update({
                  lastMessage: `${data.name} Telah Ditambahkan`,
                  lastTimeMessage: new Date().toISOString(),
               })

               .then(() => console.log("success"));
            return { status: true, message: "success" };
         })
         .catch((error: any) => {
            return { status: false, message: error };
         });
   }
}
