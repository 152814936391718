import axios from "axios";
import {endpoint} from "../../constants";
import {MitraPointModel} from "./models";

export default class MitraPointApi {
    static getMitraPointList() {
        return axios.get<any>(endpoint + "/api/PointLevel/GetList").then((res) => res.data.data);
    }

    static updateMitraPoint(args: MitraPointModel) {
        return axios.post<any>(endpoint + `/api/PointLevel/Update`, args).then((res) => res.data.data);
    }
}
