import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ApiErrorResponse} from "../../..";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {
    StoreSalesDetailStatisticModel,
    StoreSalesDetailStatisticRequestModel,
    StoreSalesStatisticModel,
    StoreSalesStatisticRequestModel
} from "./models";
import StoreReportApi from "./storeReport.api";

export interface StoreReportSlice {
    list?: StoreSalesStatisticModel[];
    rows?: number;
    detailList?: StoreSalesDetailStatisticModel[];
    detailRows?: number;
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: ApiResponseStatus;
}

export const getList = createAsyncThunk(
    "storeReport/getList",
    async (args: StoreSalesStatisticRequestModel, {rejectWithValue}) => {
        try {
            return await StoreReportApi.getList(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getDetailList = createAsyncThunk(
    "storeReport/getDetail",
    async (args: StoreSalesDetailStatisticRequestModel, {rejectWithValue}) => {
        try {
            return await StoreReportApi.getDetailList(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

const storeReportSlice = createSlice({
    name: "storeReportState",
    initialState: {} as StoreReportSlice,
    reducers: {
        resetStatus: (state) => {
            state.status = ApiResponseStatus.pending;
        },
        resetError: (state) => {
            state.error = {} as ApiErrorResponse<any>;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getList.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.list = payload.list;
            state.rows = payload.total;
        });
        builder.addCase(getList.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        });
        builder.addCase(getDetailList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getDetailList.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.detailList = payload.list;
            state.detailRows = payload.total;
        });
        builder.addCase(getDetailList.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
        });
    },
});

export const {resetStatus, resetError} = storeReportSlice.actions;
export default storeReportSlice.reducer;
