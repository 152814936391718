import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import DataCustomerForm from "../components/DataCustomerForm";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import { UserModel } from "../models";
import { getSingleCustomer } from "../dataCustomer.reducer";
import ChangePasswordModal from "../../../dataMitra/dataMitra/components/ChangePasswordModal";
import {getUserSingle} from "../../../dataMitra/dataMitra/dataMitra.reducer";

const DataCustomerDetailPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const [isEdit, setIsEdit] = useState(false);
  const [isModalPasswordShow, setIsModalPasswordShow] = useState(false);

  let singleDataState = {} as UserModel;

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getSingleCustomer(id));
      dispatch(getUserSingle(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = (name: string, description: string) => {
    // if (isEdit) {
    //   dispatch(
    //     updateRevisionOption({
    //       id: id,
    //       name,
    //       description,
    //     } as RevisionStatusModel)
    //   );
    // } else {
    //   dispatch(
    //     createRevisionOption({
    //       name,
    //       description,
    //     } as RevisionStatusModel)
    //   );
    // }
  };

  const handleModalPassword = () => {
    setIsModalPasswordShow(prevState => !prevState);
  };

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <div className="d-flex">
            <div className="flex-grow-1">
              <h4 className="card-label">
                Detail Data Konsumen
              </h4>
            </div>
            <div>
              <button
                  className="float-right btn button-primary mr-2"
                  onClick={handleModalPassword}
              >
                Ganti Password
              </button>
            </div>
          </div>
          <hr />
          <Row>
            <Col md={8} xl={5}>
              <DataCustomerForm
                onSubmit={onSubmit}
                data={singleDataState}
                isEdit={isEdit}
              />
              <ChangePasswordModal
                  isShow={isModalPasswordShow}
                  onCloseHandler={handleModalPassword}
                  userId={id}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default DataCustomerDetailPage;
