import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import {
  ChangeSuspendedStatus,
  CustomerModel,
  UserModel,
} from "./models";
import DataCustomerApi from "./dataCustomer.api";
import {OptionModel} from "../../../@core/models/types";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import DataMitraApi from "../../dataMitra/dataMitra/dataMitra.api";
import {GetUserList} from "../../dataMitra/dataMitra/models";

export interface DataCustomerSlice {
  name?: string;
  userList?: UserModel[];
  rows?: number;
  single?: CustomerModel;
  userOptions?: OptionModel[];
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: ApiResponseStatus;
  dateCreated?: string;
}

export const getUserList = createAsyncThunk(
  "dataCustomerState/dataCustomerList",
  async (args: GetUserList, { rejectWithValue }) => {
    try {
      return await DataMitraApi.getUserList(args);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getUserOptionList = createAsyncThunk(
    "dataCustomerState/dataCustomerOptionList",
    async (args: GetUserList, { rejectWithValue }) => {
      try {
        return await DataMitraApi.getUserList(args);
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);
export const getSingleCustomer = createAsyncThunk(
  "dataCustomerState/getSingleCustomer",
  async (userId: string, { rejectWithValue }) => {
    try {
      return await DataCustomerApi.getSingleCustomer(userId);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const changeSuspendedStatus = createAsyncThunk(
    "dataCustomerState/changeSuspendedStatus",
    async (data: ChangeSuspendedStatus, { rejectWithValue }) => {
      try {
        return await DataCustomerApi.changeSuspendedStatus(data);
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

const dataCustomerSlice = createSlice({
  name: "DataCustomerState",
  initialState: {} as DataCustomerSlice,
  reducers: {
    // setFilter: (state, action) => {
    //   state.list = action.payload;
    // },
    resetStatus: (state) => {
      state.status = ApiResponseStatus.pending;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserList.fulfilled, (state, { payload }) => {
      state.userList = payload.data;
      state.rows = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getUserList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getUserOptionList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserOptionList.fulfilled, (state, { payload }) => {
      state.userOptions = payload.data.map((item: UserModel) => {
        return {value: item.id, label: item.name + " - " + item.email}
      });
      state.isLoading = false;
    });
    builder.addCase(getUserOptionList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getSingleCustomer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSingleCustomer.fulfilled, (state, { payload }) => {
      state.single = payload.data;
      state.isLoading = false;
    });
    builder.addCase(getSingleCustomer.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(changeSuspendedStatus.pending, (state) => {
      state.status = ApiResponseStatus.pending;
      state.isLoading = true;
    });
    builder.addCase(changeSuspendedStatus.fulfilled, (state, { payload }) => {
      state.status = ApiResponseStatus.success;
      state.isLoading = false;
    });
    builder.addCase(changeSuspendedStatus.rejected, (state, { payload }) => {
      state.status = ApiResponseStatus.failed;
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
  },
});

export const { resetStatus } = dataCustomerSlice.actions;
export default dataCustomerSlice.reducer;
