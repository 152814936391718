import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import VendorTypeTable from "../components/VendorTypeTable";
import {
  getVendorTypeList,
  resetSingle,
  setFilter,
} from "../vendorType.reducer";
import { RootState } from "../../../../../app/store";

const VendorTypePage = () => {
  const dispatch = useDispatch();
  const statusState = useSelector((state: RootState) => state.vendorType.name);
  const listDataState = useSelector(
    (state: RootState) => state.vendorType.list
  );

  const onFilterSubmit = (name: string) => {
    const dataFilter = listDataState ?? [];
    const filteredData = dataFilter.filter((find: { name: string }) =>
      find.name.includes(name)
    );
    dispatch(setFilter(filteredData));
  };

  useEffect(() => {
    dispatch(getVendorTypeList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusState]);

  useEffect(() => {
    dispatch(resetSingle());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <h4 className="card-label">Master Tipe Vendor</h4>
          <VendorTypeTable />
        </Col>
      </Row>
    </Container>
  );
};

export default VendorTypePage;
