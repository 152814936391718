import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { GearOptionModel } from "./models";
import GearOptionApi from "./gearOption.api";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";

export interface GearOptionSlice {
  date?: string;
  list?: GearOptionModel[];
  single?: GearOptionModel;
  isLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: ApiResponseStatus;
}

export const createGearOption = createAsyncThunk(
  "gearOptionState/createGearOption",
  async (data: GearOptionModel, { rejectWithValue }) => {
    try {
      return await GearOptionApi.create(data);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getGearOptionList = createAsyncThunk(
  "gearOptionState/getGearOptionList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await GearOptionApi.getList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getGearOptionSingleItem = createAsyncThunk(
  "gearOptionState/getGearOptionSingleItem",
  async (id: string, { rejectWithValue }) => {
    try {
      return await GearOptionApi.getSingleItem(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateGearOption = createAsyncThunk(
  "gearOptionState/updateGearOption",
  async (data: GearOptionModel, { rejectWithValue }) => {
    try {
      return await GearOptionApi.update(data);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteGearOption = createAsyncThunk(
  "gearOptionState/deleteGearOption",
  async (data: GearOptionModel, { rejectWithValue }) => {
    try {
      return await GearOptionApi.delete(data);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

const createGearOptionSlice = createSlice({
  name: "gearOptionState",
  initialState: {} as GearOptionSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingle: (state) => {
      state.single = {} as GearOptionModel;
    },
    resetStatus: (state) => {
      state.status = ApiResponseStatus.pending;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createGearOption.pending, (state) => {
      state.isLoading = true;
      state.status = ApiResponseStatus.pending;
    });
    builder.addCase(createGearOption.fulfilled, (state) => {
      state.date = new Date().toISOString();
      state.isLoading = false;
      state.status = ApiResponseStatus.success;
    });
    builder.addCase(createGearOption.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = ApiResponseStatus.failed;
    });
    builder.addCase(getGearOptionSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(
        getGearOptionSingleItem.fulfilled,
      (state, { payload }) => {
        state.single = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(
        getGearOptionSingleItem.rejected,
      (state, { payload }) => {
        state.error = payload as ApiErrorResponse<any>;
        state.isLoading = false;
      }
    );
    builder.addCase(getGearOptionList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getGearOptionList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getGearOptionList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateGearOption.pending, (state) => {
      state.isLoading = true;
      state.status = ApiResponseStatus.pending;
    });
    builder.addCase(updateGearOption.fulfilled, (state) => {
      state.isLoading = false;
      state.date = new Date().toISOString();
      state.status = ApiResponseStatus.success;
    });
    builder.addCase(updateGearOption.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
      state.status = ApiResponseStatus.failed;
    });
    builder.addCase(deleteGearOption.pending, (state) => {
      state.isLoading = true;
      state.status = ApiResponseStatus.pending;
    });
    builder.addCase(deleteGearOption.fulfilled, (state) => {
      state.isLoading = false;
      state.date = new Date().toISOString();
      state.status = ApiResponseStatus.success;
    });
    builder.addCase(deleteGearOption.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
      state.status = ApiResponseStatus.failed;
    });
  },
});

export const { setFilter, resetSingle, resetStatus } = createGearOptionSlice.actions;
export default createGearOptionSlice.reducer;
