import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import {ApiErrorResponse} from "../../index";
import {ApiResponseStatus} from "../../@core/models/apiResponseStatus/apiResponseStatus";
import MarketingEventApi from "./proSquadPortfolio.api";
import {UploadProgress, UploadStatus} from "../media/fileAttachment/models";
import FileAttachmentApi from "../media/fileAttachment/fileAttachment.api";
import {
    CreateProSquadPortfolioRequest,
    CreateProSquadPortfolioWithFileRequest,
    ProSquadPortfolio,
    UpdatePortfolioRequest
} from "./models";
import ProSquadPortfolioApi from "./proSquadPortfolio.api";

export interface ProSquadPortfolioSlice {
    list?: ProSquadPortfolio[];
    single?: ProSquadPortfolio;
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: ApiResponseStatus;
    deleteStatus?: ApiResponseStatus;
    uploadProgress?: UploadProgress[];
    uploadStatus?: UploadStatus[];
}

export const uploadPortfolioFile = createAsyncThunk(
    'proSquadPortfolio/uploadPortfolioFile',
    async (args: CreateProSquadPortfolioWithFileRequest, {getState, rejectWithValue, dispatch}) => {
        try {
            dispatch(
                setUploadProgress(
                    args.local_files.map((file, index) => {
                        return {index: index, progress: 0};
                    })
                )
            );
            dispatch(
                setUploadStatus(
                    args.local_files.map((attachment, index) => {
                        return {
                            index: index,
                            filename: attachment.name,
                            status: ApiResponseStatus.pending,
                            createStatus: ApiResponseStatus.pending,
                            message: "",
                        };
                    })
                )
            );

            const uploadResponse = args.local_files.map(async (attachment, index) => {
                let formData = new FormData();
                formData.append('file', attachment);
                formData.append('location', 'pro_squad_portfolio');
                const config = {
                    // params: {
                    //     location: 'marketing_event',
                    // },
                    onUploadProgress: (progressEvent: ProgressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        dispatch(
                            updateUploadProgress({
                                index: index,
                                progress: percentCompleted,
                            })
                        );
                    },
                };
                return await FileAttachmentApi.uploadFile(formData, config).then((data) => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: ApiResponseStatus.success,
                            message: "",
                        })
                    );
                    return {
                        name: attachment.name,
                        path: data[0].filePath,
                    }
                }).catch((e: any) => {
                    dispatch(
                        updateUploadStatus({
                            index: index,
                            status: ApiResponseStatus.failed,
                            message: e.message,
                        })
                    );
                    return {
                        name: "",
                        path: "",
                        type: undefined
                    }
                });
            });

            Promise.all(uploadResponse).then(async (attachment) => {
                const {local_files, ...rest} = args;
                if (attachment[0].path !== "") {
                    if (args.isEdit) {
                        dispatch(updatePortfolio({
                            ...rest,
                            path: [ ...rest.uploaded_files ?? [], ...attachment.map((file) => file.path)],
                            mitraId: rest.userId,
                        }));
                    } else {
                        dispatch(createProSquadPortfolio({
                            ...rest,
                            path: attachment.map((file) => file.path),
                        }));
                    }
                }
            });
        } catch (e) {
            return rejectWithValue(e as ApiResponseStatus);
        }
    }
);

export const createProSquadPortfolio = createAsyncThunk(
    "proSquadPortfolio/createProSquadPortfolio",
    async (args: CreateProSquadPortfolioRequest, {rejectWithValue}) => {
        try {
            return await ProSquadPortfolioApi.createProSquadPortfolio(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const updatePortfolio = createAsyncThunk(
    "proSquadPortfolio/updatePortfolio",
    async (args: UpdatePortfolioRequest, {rejectWithValue}) => {
        try {
            return await ProSquadPortfolioApi.updatePortfolio(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const deletePortfolio = createAsyncThunk(
    "proSquadPortfolio/deletePortfolio",
    async (args: UpdatePortfolioRequest, {rejectWithValue}) => {
        try {
            return await ProSquadPortfolioApi.deletePortfolio(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getProSquadPortfolioList = createAsyncThunk(
    "proSquadPortfolio/getProSquadPortfolioList",
    async (_: undefined, {rejectWithValue}) => {
        try {
            return await ProSquadPortfolioApi.getProSquadPortfolio();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getSinglePortfolio = createAsyncThunk(
    "proSquadPortfolio/getSinglePortfolio",
    async (id: string, {rejectWithValue}) => {
        try {
            return await ProSquadPortfolioApi.getSinglePortfolio(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

const proSquadPortfolioSlice = createSlice({
    name: "proSquadPortfolioState",
    initialState: {} as ProSquadPortfolioSlice,
    reducers: {
        setFilter: (state, action) => {
            state.list = action.payload;
        },
        reset: (state) => {
            state.status = ApiResponseStatus.pending;
            state.deleteStatus = ApiResponseStatus.pending;
            state.error = {} as ApiErrorResponse<any>;
            state.uploadStatus = [];
            state.uploadProgress = [];
        },
        setUploadProgress: (state, payload: PayloadAction<Array<UploadProgress>>) => {
            state.uploadProgress = payload.payload;
        },
        setUploadStatus: (state, payload: PayloadAction<Array<UploadStatus>>) => {
            state.uploadStatus = payload.payload;
        },
        updateUploadProgress: (state, payload: PayloadAction<UploadProgress>) => {
            let progress = state.uploadProgress ?? [];
            let objIndex = state.uploadProgress?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            progress[objIndex as number].progress = payload.payload.progress;
            state.uploadProgress = progress;
        },
        updateUploadStatus: (state, payload: PayloadAction<UploadStatus>) => {
            let status = state.uploadStatus ?? [];
            let objIndex = state.uploadStatus?.findIndex(
                (obj) => obj.index === payload.payload.index
            );
            status[objIndex as number].status = payload.payload.status;
            state.uploadStatus = status;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createProSquadPortfolio.pending, (state) => {
            state.isLoading = true;
            state.status = ApiResponseStatus.pending;
        });
        builder.addCase(createProSquadPortfolio.fulfilled, (state) => {
            state.isLoading = false;
            state.status = ApiResponseStatus.success;
        });
        builder.addCase(createProSquadPortfolio.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = ApiResponseStatus.failed;
        });
        builder.addCase(updatePortfolio.pending, (state) => {
            state.isLoading = true;
            state.status = ApiResponseStatus.pending;
        });
        builder.addCase(updatePortfolio.fulfilled, (state) => {
            state.isLoading = false;
            state.status = ApiResponseStatus.success;
        });
        builder.addCase(updatePortfolio.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = ApiResponseStatus.failed;
        });
        builder.addCase(deletePortfolio.pending, (state) => {
            state.isLoading = true;
            state.deleteStatus = ApiResponseStatus.pending;
        });
        builder.addCase(deletePortfolio.fulfilled, (state) => {
            state.isLoading = false;
            state.deleteStatus = ApiResponseStatus.success;
        });
        builder.addCase(deletePortfolio.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.deleteStatus = ApiResponseStatus.failed;
        });
        builder.addCase(getProSquadPortfolioList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getProSquadPortfolioList.fulfilled, (state, {payload}) => {
            state.list = payload;
            state.isLoading = false;
        });
        builder.addCase(getProSquadPortfolioList.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(getSinglePortfolio.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSinglePortfolio.fulfilled, (state, {payload}) => {
            state.single = payload;
            state.isLoading = false;
        });
        builder.addCase(getSinglePortfolio.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
    },
});

export const {
    reset,
    setUploadProgress,
    setUploadStatus,
    updateUploadProgress,
    updateUploadStatus,
} = proSquadPortfolioSlice.actions;
export default proSquadPortfolioSlice.reducer;
