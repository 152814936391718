import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import { ApiResponseStatus } from "../../@core/models/apiResponseStatus/apiResponseStatus";
import {ApproveDeleteRequest, RequestDeleteModel} from "./models";
import RequestDeleteAccountApi from "./requestDeleteAccount.api";

export interface RequestDeleteAccountSlice {
   list?: RequestDeleteModel[];
   isLoading?: boolean;
   dateCreated?: string;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
}

export const getListDeleteRequest = createAsyncThunk(
   "deleteAccount/getListDeleteRequest",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await RequestDeleteAccountApi.getListDeleteRequest();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const approveDeleteRequest = createAsyncThunk(
   "deleteAccount/approveDeleteRequest",
   async (args: ApproveDeleteRequest, { rejectWithValue }) => {
      try {
         return await RequestDeleteAccountApi.approveDeleteRequest(args);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const requestDeleteAccountSlice = createSlice({
   name: "requestDeleteAccountState",
   initialState: {} as RequestDeleteAccountSlice,
   reducers: {
      setFilter: (state, action) => {
         state.list = action.payload;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
      },
      resetError: (state) => {
         state.error = {} as ApiErrorResponse<any>;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(getListDeleteRequest.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getListDeleteRequest.fulfilled, (state, { payload }) => {
         state.list = payload;
      });
      builder.addCase(getListDeleteRequest.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
      });

      builder.addCase(approveDeleteRequest.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(approveDeleteRequest.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.dateCreated = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(approveDeleteRequest.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
   },
});

export const { setFilter, resetStatus, resetError } = requestDeleteAccountSlice.actions;
export default requestDeleteAccountSlice.reducer;
