import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Row, Col, Tab, Nav, Alert, Badge} from "react-bootstrap";
import {ProfessionalModel, MitraType} from "../models";
import {useParams} from "react-router-dom";
import {
    getPersonalAttachment,
    getPersonalCertificate,
    getPersonalCity,
    getPersonalLanguage,
    getProfessionalSingle,
    getSocialMedia,
    getVendorAttachment,
    getVendorSingle,
    verifyUser,
} from "../dataDiri.reducer";
import {RootState} from "../../../../app/store";
import {useSelector} from "react-redux";
import DataPribadiList from "../components/DataPribadiList";
import KotaLayananList from "../components/KotaLayananList";
import ProfilUmumList from "../components/ProfilUmumList";
import SocialMediaList from "../components/SocialMediaList";
import {useHistory} from "react-router-dom";
import {VendorModel} from "../../dataMitra/models";
import DataVendorList from "../components/DataVendorList";
import BankAccountList from "../components/BankAccountList";
import LegalitasList from "../components/LegalitasList";
import MitraTypeList from "../components/MitraTypeList";

const DataDiriPage = () => {
    const dispatch = useDispatch();
    let history = useHistory();

    let {id} = useParams<{ id: string }>();

    const [mitraType, setMitraType] = useState<MitraType>(0);

    const professionalState = useSelector(
        (state: RootState) => state.dataDiri.professionalSingle ?? ({} as ProfessionalModel)
    );

    const vendorState = useSelector(
        (state: RootState) => state.dataDiri.vendorSingle ?? ({} as VendorModel)
    );

    useEffect(() => {
        if (professionalState.id === "false") {
            setMitraType(MitraType.vendor);
        } else {
            setMitraType(MitraType.professional);
        }
    }, [professionalState]);

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getProfessionalSingle(id));
            dispatch(getVendorSingle(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const onDataPribadiClicked = () => {
        if (mitraType !== MitraType.none) {
            if (mitraType === MitraType.professional) {
                dispatch(getPersonalAttachment(professionalState.id));
            } else if (mitraType === MitraType.vendor) {
                dispatch(getVendorAttachment(vendorState.id));
            }
        }
    };

    const onLegalitasClicked = () => {
        dispatch(getVendorAttachment(vendorState.id));
    };

    const onProfilUmumClicked = () => {
        if (mitraType !== MitraType.none) {
            let mitraId =
                mitraType === MitraType.professional
                    ? professionalState.id
                    : mitraType === MitraType.vendor
                        ? vendorState.id
                        : "";
            dispatch(getPersonalCertificate(mitraId));
            dispatch(getPersonalLanguage(mitraId));
        }
    };

    const onKotaLayananClicked = () => {
        if (mitraType !== MitraType.none) {
            let mitraId =
                mitraType === MitraType.professional
                    ? professionalState.id
                    : mitraType === MitraType.vendor
                        ? vendorState.id
                        : "";
            dispatch(getPersonalCity(mitraId));
        }
    };

    const onSocialMediaClicked = () => {
        if (mitraType !== MitraType.none) {
            let mitraId =
                mitraType === MitraType.professional
                    ? professionalState.id
                    : mitraType === MitraType.vendor
                        ? vendorState.id
                        : "";
            dispatch(getSocialMedia(mitraId));
        }
    };

    return (
        <Tab.Container id="left-tabs-example">
            <Row>
                <Col sm={4} className="data_diri_tab_menu pb-5">
                    <h5 className="mb-4 data_diri_tab_menu_title">DATA DIRI {" "}
                        {(vendorState.id !== "false" && professionalState.id === "false") && (
                            <Badge
                                pill
                                className="feed-badge text-white ml-2"
                            >
                                Vendor
                            </Badge>
                        )}
                    </h5>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item className="data_diri_tab_title">
                            <Nav.Link eventKey="first" onClick={onDataPribadiClicked}>
                                Data Pribadi
                            </Nav.Link>
                        </Nav.Item>
                        {(vendorState.id !== "false" && professionalState.id === "false") && (
                            <Nav.Item className="data_diri_tab_title">
                                <Nav.Link eventKey="sixth" onClick={onLegalitasClicked}>
                                    Legalitas
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        <Nav.Item className="data_diri_tab_title">
                            <Nav.Link eventKey="second" onClick={onProfilUmumClicked}>
                                Profil Umum
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="data_diri_tab_title">
                            <Nav.Link eventKey="third" onClick={onKotaLayananClicked}>
                                Kota Layanan
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="data_diri_tab_title">
                            <Nav.Link eventKey="fourth" onClick={onSocialMediaClicked}>
                                Sosial Media
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="data_diri_tab_title">
                            <Nav.Link eventKey="fifth">Akun Bank</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="data_diri_tab_title">
                            <Nav.Link eventKey="seventh">Tipe Mitra</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={8} className="data_diri_tab_body pb-3">
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            {professionalState.id === "false" && vendorState.id === "false" && (
                                <Alert variant="danger">Pengguna Belum Mengisi data diri apapun</Alert>
                            )}
                            {professionalState.id !== "false" && (
                                <DataPribadiList personalData={professionalState}/>
                            )}
                            {(vendorState.id !== "false" && professionalState.id === "false") && <DataVendorList personalData={vendorState}/>}
                        </Tab.Pane>
                        {(vendorState.id !== "false" && professionalState.id === "false")  && (
                            <Tab.Pane eventKey="sixth">
                                <LegalitasList/>
                            </Tab.Pane>
                        )}
                        <Tab.Pane eventKey="second">
                            <ProfilUmumList mitraType={mitraType}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <KotaLayananList/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                            <SocialMediaList/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fifth">
                            <BankAccountList/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="seventh">
                            <MitraTypeList isVendor={vendorState.id !== "false" && professionalState.id === "false"} professionalTypeList={professionalState.professionalTypeList} vendorType={vendorState.vendorType}/>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    );
};

export default DataDiriPage;
