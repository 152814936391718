import axios from "axios";
import { endpoint } from "../../constants";

export default class CustomerCancelProjectApi {
   static getListAll() {
      return axios
         .get<any>(endpoint + "/api/Activity/GetListCustomerCancel")
         .then((res) => res.data.data);
   }
}
