import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import DashboardApi from "./dashboard.api";
import { BestReportModel, DashboardDataModel, GraphReportModel } from "./models";

export interface DashboardSlice {
   dashboardData: DashboardDataModel;
   graphMonth: GraphReportModel[];
   graphWeek: GraphReportModel[];
   graphDay: GraphReportModel[];
   bestPackage?: BestReportModel[];
   bestProduct?: BestReportModel[];
   bestMitra?: BestReportModel[];
   isLoading?: boolean;
   error?: ApiErrorResponse<any>;
   status?: string;
}

export const getDashboardData = createAsyncThunk(
   "dashboardState/getDashboardData",
   async (_id: string | undefined, { rejectWithValue }) => {
      try {
         return await DashboardApi.getDashboardData();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getReportMonth = createAsyncThunk(
   "dashboardState/getReportMonth",
   async (_id: string | undefined, { rejectWithValue }) => {
      try {
         return await DashboardApi.getReportMonth();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getReportWeek = createAsyncThunk(
   "dashboardState/getReportWeek",
   async (_id: string | undefined, { rejectWithValue }) => {
      try {
         return await DashboardApi.getReportWeek();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getReportDay = createAsyncThunk(
   "dashboardState/getReportDay",
   async (_id: string | undefined, { rejectWithValue }) => {
      try {
         return await DashboardApi.getReportDay();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getListBestPackage = createAsyncThunk(
   "dashboardState/getListBestPackage",
   async (_id: string | undefined, { rejectWithValue }) => {
      try {
         return await DashboardApi.getListBestPackage();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getListBestProduct = createAsyncThunk(
   "dashboardState/getListBestProduct",
   async (_id: string | undefined, { rejectWithValue }) => {
      try {
         return await DashboardApi.getListBestProduct();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getListBestMitra = createAsyncThunk(
   "dashboardState/getListBestMitra",
   async (_id: string | undefined, { rejectWithValue }) => {
      try {
         return await DashboardApi.getListBestMitra();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const userSlice = createSlice({
   name: "dashboardState",
   initialState: {} as DashboardSlice,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(getDashboardData.pending, (state) => {
         state.isLoading = true;
         state.status = "pending";
      });

      builder.addCase(getDashboardData.fulfilled, (state, { payload }) => {
         state.dashboardData = payload;
         state.isLoading = false;
         state.status = "success";
      });

      builder.addCase(getDashboardData.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = "failed";
      });

      builder.addCase(getReportMonth.pending, (state) => {
         state.isLoading = true;
         state.status = "pending";
      });

      builder.addCase(getReportMonth.fulfilled, (state, { payload }) => {
         state.graphMonth = payload;
         state.isLoading = false;
         state.status = "success";
      });

      builder.addCase(getReportMonth.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = "failed";
      });

      builder.addCase(getReportWeek.pending, (state) => {
         state.isLoading = true;
         state.status = "pending";
      });

      builder.addCase(getReportWeek.fulfilled, (state, { payload }) => {
         state.graphWeek = payload;
         state.isLoading = false;
         state.status = "success";
      });

      builder.addCase(getReportWeek.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = "failed";
      });

      builder.addCase(getReportDay.pending, (state) => {
         state.isLoading = true;
         state.status = "pending";
      });

      builder.addCase(getReportDay.fulfilled, (state, { payload }) => {
         state.graphDay = payload;
         state.isLoading = false;
         state.status = "success";
      });

      builder.addCase(getReportDay.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = "failed";
      });

      builder.addCase(getListBestPackage.pending, (state) => {
         state.isLoading = true;
         state.status = "pending";
      });

      builder.addCase(getListBestPackage.fulfilled, (state, { payload }) => {
         state.bestPackage = payload;
         state.isLoading = false;
         state.status = "success";
      });

      builder.addCase(getListBestPackage.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = "failed";
      });

      builder.addCase(getListBestProduct.pending, (state) => {
         state.isLoading = true;
         state.status = "pending";
      });

      builder.addCase(getListBestProduct.fulfilled, (state, { payload }) => {
         state.bestProduct = payload;
         state.isLoading = false;
         state.status = "success";
      });

      builder.addCase(getListBestProduct.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = "failed";
      });

      builder.addCase(getListBestMitra.pending, (state) => {
         state.isLoading = true;
         state.status = "pending";
      });

      builder.addCase(getListBestMitra.fulfilled, (state, { payload }) => {
         state.bestMitra = payload;
         state.isLoading = false;
         state.status = "success";
      });

      builder.addCase(getListBestMitra.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = "failed";
      });
   },
});

export const {} = userSlice.actions;

export default userSlice.reducer;
