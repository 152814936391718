import React, { Fragment, useEffect, useState } from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../app/store";
import DefaultSubHeader from "../../../components/DataTable/DefaultSubHeader";
import { ElementPropModel } from "../../../components/DataTable/models";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import { RequestCancelProjectModel, RequestProjectStatusEnum } from "../models";
import { changeRequestCancelProjectStatus, getListAllRequestCancelProject, resetStatus } from "../requestCancelProject.reducer";

const RequestCancelProjectTable = () => {
   const dispatch = useDispatch();

   const requestCancelProjectList = useSelector((state: RootState) => state.requestCancelProject.list ?? []);
   const [requestId, setRequestId] = useState<string>("");
   const [showDetail, setShowDetail] = useState<boolean>(false);

   const dateCreated = useSelector((state: RootState) => state.requestCancelProject.dateCreated);
   // const [singleWithdraw, setSingleWithdraw] = useState<RequestCancelProjectModel>({} as RequestCancelProjectModel);

   const [filterText, setFilterText] = useState("");
   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

   const filteredItems = requestCancelProjectList.filter(
      (item) =>
         item.projectName && item.projectName.toLowerCase().includes(filterText.toLowerCase())
   );

   const onSetterFilterText = (value: string) => {
      setFilterText(value);
   };

   // const onDetailShowHandler = (data: RequestCancelProjectModel) => {
   //    setShowDetail(true);
   //    setSingleWithdraw(data);
   // };

   const onDetailModalToggler = () => {
      setShowDetail((prevState) => !prevState);
      setRequestId("");
   };

   const elementProps = {
      placeholder: "cari berdasarkan nama",
      buttonName: "",
      createUrl: "/master/issue-category/create",
      subHeaderLine: true,
   } as ElementPropModel;

   const dateSort = (rowA: { createdDate: string }, rowB: { createdDate: string }) => {
      const a = rowA.createdDate.split("/").reverse().join("");
      const b = rowB.createdDate.split("/").reverse().join("");

      return a.localeCompare(b);
   };

   const columns: TableColumn<RequestCancelProjectModel>[] = [
      {
         name: "NO.",
         width: "60px",
         cell: (_d: any, index: number) => index + 1,
      },
      {
         name: "NAMA EVENT",
         selector: (row) => row.projectName,
         sortable: true,
      },
      {
         name: "ALASAN",
         selector: (row) => row.reason,
         sortable: true,
      },
      {
         name: "STATUS",
         cell: (row: RequestCancelProjectModel) => (
            <Fragment>
               <span>
                  {row.status === RequestProjectStatusEnum.request && "Request"}
                  {row.status === RequestProjectStatusEnum.approve && "Approve"}
                  {row.status === RequestProjectStatusEnum.reject && "Rejected"}
               </span>
            </Fragment>
         ),
         sortable: true,
      },
      {
         name: "AKSI",
         width: "350px",
         cell: (row: RequestCancelProjectModel) => (
            <>
               {/* <button
                  className="btn button-outline-primary mr-2"
                  onClick={() => onDetailShowHandler(row)}>
                  Preview
               </button> */}

               {row.status !== RequestProjectStatusEnum.approve && (
                  <button
                     onClick={() => {
                        if (window.confirm("Apakah kamu yakin untuk menkonfimasi pembatalan ini?"))
                           onConfirm(row.id);
                     }}
                     className="btn button-primary">
                     TERIMA
                  </button>
               )}
            </>
         ),
      },
   ];

   // end of datatable components

   useEffect(() => {
      dispatch(getListAllRequestCancelProject());
      dispatch(resetStatus());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      dispatch(getListAllRequestCancelProject());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dateCreated]);

   const onConfirm = (id: string) => {
      dispatch(
         changeRequestCancelProjectStatus({
            id: id,
            status: RequestProjectStatusEnum.approve,
         })
      );
   };

   return (
      <>
         <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            subHeader
            subHeaderComponent={DefaultSubHeader(
               filterText,
               resetPaginationToggle,
               onSetterFilterText,
               elementProps
            )}
            customStyles={TableCustomStyles}
            persistTableHead
         />
         {/* <WithdrawDetailModal
            singleWithdraw={singleWithdraw}
            show={showDetail}
            handleClose={onDetailModalToggler}
         /> */}
      </>
   );
};

export default RequestCancelProjectTable;
