import React, {Fragment, useEffect, useState} from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import NumberFormat from "react-number-format";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {TableCustomStyles} from "../../../components/DataTable/TableCustomStyles";
import {
    ResultLimitModel,
    SettingResultEnum,
} from "../models";
import {
    getListResultTimeLimit,
    resetStatus,
    updateResultTimeLimit,
} from "../projectSetting.reducer";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../helpers/alertContent";
import {ApiErrorResponse} from "../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const ResultUploadTimeLimit = () => {
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);

    const [updatedResultTimeLimit, setUpdatedResultTimeLimit] = useState<ResultLimitModel[]>([]);

    const [resetPaginationToggle] = useState(false);
    const resultTimeLimitList = useSelector(
        (state: RootState) => state.projectSetting.resultTimeLimitList ?? []
    );
    const status = useSelector((state: RootState) => state.projectSetting.updateLimitStatus);
    const error = useSelector((state: RootState) => state.projectSetting.error ?? ({} as ApiErrorResponse<any>));

    useEffect(() => {
        dispatch(getListResultTimeLimit());
        dispatch(resetStatus());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (resultTimeLimitList.length !== 0) {
            setUpdatedResultTimeLimit(resultTimeLimitList);
        }
    }, [resultTimeLimitList]);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(savedStatusOption(status === ApiResponseStatus.success, error.data?.message)).then(() => {
                dispatch(resetStatus());
                dispatch(getListResultTimeLimit());
            });
        }
    }, [status, error]);

    const checkTimeLimitValue = (id: string) => {
        const _updatedTimeLimit = updatedResultTimeLimit.find((item) => item.id === id);
        return _updatedTimeLimit?.timeLimit ?? 0;
    };

    const checkReminderValue = (id: string) => {
        const _updatedReminder = updatedResultTimeLimit.find((item) => item.id === id);
        return _updatedReminder?.reminder ?? 0;
    };

    const InputLimit = (data: ResultLimitModel) => {
        const [timeLimit, setTimeLimit] = useState<number>(checkTimeLimitValue(data.id));
        return (
            <NumberFormat
                value={timeLimit}
                defaultValue={data.timeLimit}
                className="form-control"
                thousandSeparator={"."}
                decimalSeparator={","}
                allowNegative={false}
                suffix={" Hari"}
                onBlur={() => {
                    let _updatedTimeLimit = updatedResultTimeLimit.map((item) => {
                        if (item.id === data.id) {
                            return {
                                id: item.id,
                                type: item.type,
                                name: item.name,
                                timeLimit: timeLimit ?? 0,
                                reminder: item.reminder,
                            } as ResultLimitModel;
                        } else {
                            return item;
                        }
                    });

                    setUpdatedResultTimeLimit(_updatedTimeLimit);
                }}
                onValueChange={(values) => {
                    let val = values.floatValue ?? 0;
                    setTimeLimit(val);
                }}
            />
        );
    };

    const InputReminder = (data: ResultLimitModel) => {
        const [reminder, setReminder] = useState<number>(checkTimeLimitValue(data.id));
        return (
            <NumberFormat
                value={reminder}
                defaultValue={data.reminder}
                className="form-control"
                thousandSeparator={"."}
                decimalSeparator={","}
                allowNegative={false}
                suffix={" Hari"}
                onBlur={() => {
                    let _updatedTimeLimit = updatedResultTimeLimit.map((item) => {
                        if (item.id === data.id) {
                            return {
                                id: item.id,
                                type: item.type,
                                name: item.name,
                                timeLimit: item.timeLimit,
                                reminder: reminder ?? 0,
                            } as ResultLimitModel;
                        } else {
                            return item;
                        }
                    });

                    setUpdatedResultTimeLimit(_updatedTimeLimit);
                }}
                onValueChange={(values) => {
                    let val = values.floatValue ?? 0;
                    setReminder(val);
                }}
            />
        );
    };

    const onUpdateClicked = (data: ResultLimitModel) => {
        let _timeLimitvalue = checkTimeLimitValue(data.id);
        let _reminderValue = checkReminderValue(data.id);
        dispatch(
            updateResultTimeLimit({
                id: data.id,
                timeLimit: _timeLimitvalue,
                reminder: _reminderValue,
            })
        );
    };

    const columns: TableColumn<ResultLimitModel>[] = [
        {
            name: "NO.",
            width: "60px",
            cell: (_d: any, index: number) => index + 1,
        },
        {
            name: "KATEGORI HASIL",
            cell: (row: ResultLimitModel) => (
                <Fragment>
               <span>
                  {row.type === SettingResultEnum.UploadHasilAwal && "upload hasil awal (mitra)"}
                   {row.type === SettingResultEnum.UploadRevisi1 && "upload revisi 1"}
                   {row.type === SettingResultEnum.UploadRevisi2 && "upload revisi 2"}
                   {row.type === SettingResultEnum.UploadHasilAkhir && "upload hasil akhir (final)"}
                   {row.type === SettingResultEnum.SelesaiLayanan && "selesai layanan (customer)"}
               </span>
                </Fragment>
            ),
            sortable: true,
        },
        {
            name: "BATAS WAKTU (HARI)",
            selector: (row) => row.timeLimit,
            sortable: true,
        },
        {
            name: "REMINDER (HARI)",
            selector: (row) => row.reminder,
            sortable: true,
        },
        {
            name: "UBAH LIMIT (HARI)",
            cell: (row) => InputLimit(row),
            sortable: true,
        },
        {
            name: "UBAH REMINDER (HARI)",
            cell: (row) => InputReminder(row),
            sortable: true,
        },
        {
            name: "AKSI",
            width: "350px",
            cell: (row: ResultLimitModel) => (
                <>
                    {((checkTimeLimitValue(row.id) > row.timeLimit || checkTimeLimitValue(row.id) < row.timeLimit) || (checkReminderValue(row.id) > row.reminder || checkReminderValue(row.id) < row.reminder)) && (

                        <button className="btn button-outline-primary mr-2"
                                onClick={() => onUpdateClicked(row)}>
                            UBAH
                        </button>
                    )}
                </>
            ),
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={resultTimeLimitList}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            customStyles={TableCustomStyles}
        />
    );
};

export default ResultUploadTimeLimit;
