
import React, { Fragment, useState } from "react";
import { Col, Modal, Row, Image } from "react-bootstrap";
import ReactPlayer from "react-player";
import Viewer from "react-viewer";

export enum DetailAttachmentType {
   Picture,
   Video,
}

const DetailAttachmentModal = ({
   showModal = false,
   detailAttachmentType = DetailAttachmentType.Picture,
   mediaPath = "",
   ...props
}: DetailAttachmentModalProps) => {
   const [showDetailImage, setShowDetailImage] = useState<boolean>(false);

   const onModalClose = () => {
      setShowDetailImage(false);
      props.onModalHide();
   };

   const onDetailImageClicked = () => {
      setShowDetailImage(true);
      props.onModalHide();
   };

   return (
      <Fragment>
         <Modal
            show={showModal}
            onHide={props.onModalHide}
            className="discount_modal"
            size="lg"
            centered>
            <Modal.Body>
               <Row>
                  <Col md={12} className={`request_admin_modal_header`}>
                     <h1 className={`d-inline request_admin_modal_title`}>
                        Detail Media
                     </h1>
                     <span
                        className={`material-icons-outlined float-right request_admin_close`}
                        onClick={onModalClose}>
                        close
                     </span>
                  </Col>
                  <Col md={12}>
                     <div className={"detail_attachment_content"}>
                        {detailAttachmentType === DetailAttachmentType.Video && (
                           <ReactPlayer controls url={mediaPath} />
                        )}
                        {detailAttachmentType === DetailAttachmentType.Picture && (
                           <div>
                              <Image
                                 src={mediaPath}
                                 onClick={onDetailImageClicked}
                                 alt="attachment"
                                 width="600"
                                 height="400"
                              />
                           </div>
                        )}
                     </div>
                  </Col>
               </Row>
            </Modal.Body>
         </Modal>
         <Viewer
            visible={showDetailImage}
            onClose={() => {
               setShowDetailImage(false);
            }}
            images={[
               {
                  src: mediaPath,
                  alt: "",
               },
            ]}
         />
      </Fragment>
   );
};

export interface DetailAttachmentModalProps {
   showModal: boolean;
   onModalHide: () => void;
   detailAttachmentType: DetailAttachmentType;
   mediaPath: string;
}

export default DetailAttachmentModal;
