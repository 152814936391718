import { PaymentGatewayEnum, PaymentMethodCategoryEnum } from "./models";

export const paymentGatewayOption = [
   {
      label: "Midtrans",
      value: 0,
   },
   {
      label: "Xendit",
      value: 1,
   },
];

export const midtransPaymentCategoryOption = [
   {
      label: "Bank Transfer",
      value: 0,
   },
   {
      label: "E-Wallet",
      value: 1,
   },
   {
      label: "Cardless Credit",
      value: 5,
   },
   {
      label: "QR Code",
      value: 4,
   },
];

export const midtransBankTransferOption = [
   {
      label: "BRI",
      value: 0,
   },
   {
      label: "BNI",
      value: 1,
   },
   {
      label: "Mandiri",
      value: 2,
   },
   {
      label: "Permata",
      value: 3,
   },
   {
      label: "BCA",
      value: 4,
   },
];

export const midtransEWalletOption = [
   {
      label: "GoPay",
      value: 11,
   },
   {
      label: "ShopeePay",
      value: 15,
   },
];

export const midtransCardlessCreditOption = [
   {
      label: "AkuLaku",
      value: 41,
   },
];

export const midtransQRCodeOption = [
   {
      label: "QRIS",
      value: 31,
   },
];

export const xenditPaymentCategoryOption = [
   {
      label: "Bank Transfer",
      value: 0,
   },
   {
      label: "E-Wallet",
      value: 1,
   },
   {
      label: "Retail Outlet",
      value: 2,
   },
   {
      label: "Cardless Credit",
      value: 5,
   },
];

export const xenditBankTransferOption = [
   {
      label: "BRI",
      value: 0,
   },
   {
      label: "BNI",
      value: 1,
   },
   {
      label: "Mandiri",
      value: 2,
   },
   {
      label: "Permata",
      value: 3,
   },
   {
      label: "BCA",
      value: 4,
   },
   {
      label: "Sampoerna",
      value: 5,
   },
   {
      label: "Danamon",
      value: 6,
   },
];

export const xenditEWalletOption = [
   {
      label: "OVO",
      value: 12,
   },
   {
      label: "DANA",
      value: 13,
   },
   {
      label: "LinkAja",
      value: 14,
   },
];

export const xenditRetailOutletOption = [
   {
      label: "Alfamart",
      value: 21,
   },
   {
      label: "Indomaret",
      value: 22,
   },
];

export const xenditCardlessCreditOption = [
   {
      label: "Kredivo",
      value: 42,
   },
];

export const feeTypeOption = [
   {
      label: "Nominal",
      value: 0,
   },
   {
      label: "Percentage",
      value: 1,
   },
];

export const defaultFeeTypeValue = {
   label: "Nominal",
   value: 0,
};

export const paymentMethodOption = [
   {
      label: "Bank BRI",
      value: 0,
   },
   {
      label: "Bank BNI",
      value: 1,
   },
   {
      label: "Bank Mandiri",
      value: 2,
   },
   {
      label: "Bank Permata",
      value: 3,
   },
   {
      label: "Bank BCA",
      value: 4,
   },
   {
      label: "Bank Sampoerna",
      value: 5,
   },
   {
      label: "Bank Danamon",
      value: 6,
   },
   {
      label: "Bank BJB",
      value: 7,
   },
   {
      label: "Bank BSI",
      value: 8,
   },
   {
      label: "GoPay",
      value: 11,
   },
   {
      label: "OVO",
      value: 12,
   },
   {
      label: "DANA",
      value: 13,
   },
   {
      label: "Link Aja",
      value: 14,
   },
   {
      label: "Shopeepay",
      value: 15,
   },

   {
      label: "Alfamart",
      value: 21,
   },
   {
      label: "Indomaret",
      value: 22,
   },

   {
      label: "QRIS",
      value: 31,
   },

   {
      label: "AkuLaku",
      value: 41,
   },
   {
      label: "Kredivo",
      value: 42,
   },
   {
      label: "Visa",
      value: 51,
   },
   {
      label: "MasterCard",
      value: 52,
   },
   {
      label: "JCB",
      value: 53,
   },
];

export const fillPaymentMethodOption = (
   paymentGateway: number | undefined,
   paymentCategory: number | undefined
) => {
   if (paymentGateway === PaymentGatewayEnum.Midtrans) {
      switch (paymentCategory) {
         case PaymentMethodCategoryEnum.BankTransfer:
            return midtransBankTransferOption;
            break;
         case PaymentMethodCategoryEnum.EWallet:
            return midtransEWalletOption;
            break;
         case PaymentMethodCategoryEnum.CardlessCredit:
            return midtransCardlessCreditOption;
            break;
         case PaymentMethodCategoryEnum.QRCode:
            return midtransQRCodeOption;
            break;
      }
   } else if (paymentGateway === PaymentGatewayEnum.Xendit) {
      switch (paymentCategory) {
         case PaymentMethodCategoryEnum.BankTransfer:
            return xenditBankTransferOption;
            break;

         case PaymentMethodCategoryEnum.EWallet:
            return xenditEWalletOption;
            break;

         case PaymentMethodCategoryEnum.RetailOutlet:
            return xenditRetailOutletOption;
            break;

         case PaymentMethodCategoryEnum.CardlessCredit:
            return xenditCardlessCreditOption;
            break;
      }
   } else {
      return undefined;
   }
};
