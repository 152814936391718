import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {
    FileEventModel,
    MarketingEventModel,
    MarketingFileLogOrderByEnum,
    MarketingFileLogSortByEnum
} from "../../models";
import {getThumbnailImage} from '../../../../helpers/imageManipulation';
import '../../../../assets/styles/event.css';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import {useParams} from "react-router-dom";
import {
    deleteMarketingEvent,
    deleteMultipleUploadedFile,
    getListUploadedFile,
    getMitraStatistic, reset
} from "../../marketingEvent.reducer";
import Viewer from "react-viewer";
import ImageViewer from "../../../../components/Image/ImageViewer";
import {BsTrash} from "react-icons/bs";
import {customDeletedWarnOption, deletedStatusOption, deletedWarnOption} from "../../../../helpers/alertContent";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {AiOutlineCheckCircle, AiOutlineDownload} from "react-icons/ai";
import {fileToZip} from "../../../../helpers/fileToZip";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {ApiErrorResponse} from "../../../../index";

const PhotoList = () => {
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    let {id, userId} = useParams<{ id: string, userId: string }>();

    const listUploadedFile = useSelector((state: RootState) => state.marketingEvent.listUploadedFile ?? []);
    const listDetailFile = useSelector((state: RootState) => state.marketingEvent.listDetailFile ?? []);
    const status = useSelector((state: RootState) => state.marketingEvent.fileDeleteStatus);
    const error = useSelector((state: RootState) => state.marketingEvent.error ?? ({} as ApiErrorResponse<any>));

    const singleEvent = useSelector((state: RootState) => state.marketingEvent.singleEvent ?? {} as MarketingEventModel);

    const [imgVisible, setImgVisible] = useState<boolean>(false);
    const [defaultIndex, setDefaultIndex] = useState(0)

    const [filePicked, setFilePicked] = useState<
        FileEventModel[]
    >([]);

    useEffect(() => {
        if (id !== undefined && userId !== undefined) {
            dispatch(getListUploadedFile({
                marketingEventId: id,
                userId: userId,
                sortBy: MarketingFileLogSortByEnum.Nama,
                orderBy: MarketingFileLogOrderByEnum.Asc,
            }));
        }
    }, [id, userId]);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(deletedStatusOption(status === ApiResponseStatus.success, error.data?.message)).then(() => {
                dispatch(reset());
                dispatch(getListUploadedFile({
                    marketingEventId: id,
                    userId: userId,
                    sortBy: MarketingFileLogSortByEnum.Nama,
                    orderBy: MarketingFileLogOrderByEnum.Asc,
                }));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onFileIsPicked = (fileId: string) => {
        return filePicked.some((item) => item.id === fileId);
    };

    const onFilePickedChange = (file: FileEventModel) => {
        const isAvailable = onFileIsPicked(file.id ?? '');
        if (isAvailable) {
            const removedAvailable = filePicked.filter(
                (item) => item.id !== file.id
            );
            setFilePicked(removedAvailable);
        } else {
            setFilePicked((prevState) => [
                ...prevState,
                file,
            ]);
        }
    };

    const onImageClicked = (index: number) => {
        setImgVisible(true);
        setDefaultIndex(index);
    }

    const onDelete = () => {
        MySwal.fire(customDeletedWarnOption("Apakah Anda yakin akan menghapus file ini?")).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteMultipleUploadedFile(filePicked.map((item) => item.id ?? '')));
            }
        });
    }

    const onSelectAll = () => {
        if (filePicked.length === listUploadedFile.length) {
            setFilePicked([]);
        } else {
            setFilePicked(listUploadedFile);
        }
    }

    const onDownloadClicked = () => {
        fileToZip(`${singleEvent.name}_${new Date().toLocaleDateString()}.zip`, filePicked.map((item) => item.path ?? ''));
    }

    return (
        <div>
            <div className="d-flex">
                <h4 className="heading-title d-inline flex-grow-1">{singleEvent.name}</h4>
                <div>
                    <button className="btn button-primary mx-1" type="button" onClick={onDownloadClicked}>
                        <AiOutlineDownload fontSize={18}/> Unduh Foto
                    </button>
                    <button className="btn button-outline-primary mx-1" type="button" onClick={onSelectAll}>
                        <AiOutlineCheckCircle
                            fontSize={18}/> {filePicked.length === listUploadedFile.length ? 'Batal Pilih Semua' : 'Pilih Semua'}
                    </button>
                    <button className="btn btn-outline-danger mx-1" type="button" onClick={onDelete} disabled={filePicked.length === 0}>
                        <BsTrash fontSize={18}/> Hapus
                    </button>
                </div>
            </div>
            <div className={"my-3"}>
                <Row>
                    <Col
                        md={12}
                    >
                        <div
                            className={`d-flex mt-3 result_album_content`}
                        >
                            {listUploadedFile.map((item, index) => (
                                <div className="d-flex flex-column" key={item.id}>
                                    <>
                                        <div className="d-flex">
                                            <div
                                                className={`m-1 position-relative result_media_item`}
                                                style={{
                                                    background: `url("${item.path}")`,
                                                }}
                                                onClick={() => onImageClicked(index)}
                                            ></div>
                                            <div
                                                className={`position-absolute media-checkbox`}
                                            >
                                                <input
                                                    type="checkbox"
                                                    className={`custom-control-input`}
                                                    id={
                                                        index.toString()
                                                    }
                                                    checked={onFileIsPicked(
                                                        item.id ??
                                                        ''
                                                    )}
                                                    onChange={() =>
                                                        onFilePickedChange(
                                                            item
                                                        )
                                                    }
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor={
                                                        index.toString()
                                                    }
                                                >
                                                    <div
                                                        className={"check_result_file"}
                                                    ></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="px-2">
                                            <div className="text-truncate filename_label">{item.fileName}</div>
                                        </div>

                                    </>
                                    <>

                                    </>
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>
            </div>

            <ImageViewer defaultIndex={defaultIndex} images={listDetailFile} visible={imgVisible} onClose={() => {
                setImgVisible(false);
            }}/>

        </div>
    );
};

export default PhotoList;
