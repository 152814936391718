import React, { Fragment } from "react";

const ComponentLoader = ({ children, loading, loadingName }: FormLoaderProps) => {
    return (
        <Fragment>
            {loading ? (
                <div className="laoding-bar-container">
                    <div>
                        <div className="text-center">
                            <div className="lds-facebook">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div className="text-center">
                            <small>{loadingName}</small>
                        </div>
                    </div>
                </div>
            ) : (
                <div>{children}</div>
            )}
        </Fragment>
    );
};

export interface FormLoaderProps {
    children: React.ReactElement;
    loading?: boolean;
    loadingName?: string;
}

export default ComponentLoader;
