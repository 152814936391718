import React, {useState, useEffect} from "react";
import {Col, Form, Row} from "react-bootstrap";
import Moment from "react-moment";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Viewer from "react-viewer";
import {RootState} from "../../../../app/store";
import {checkObjectIsNotEmpty} from "../../../../helpers/checkEmptyObject";
import {addDefaultProfileImage} from "../../../../helpers/defaultBrokenImage";
import {CustomerGender, CustomerModel, UserModel} from "../models";
import UpdateBasicAccountModal from "../../../dataMitra/dataMitra/components/UpdateBasicAccountModal";
import {getMediaEndpoint} from "../../../../helpers/imageManipulation";

const DataCustomerForm = ({
                              isEdit = false,
                              data = {} as UserModel,
                              ...props
                          }: DataCustomerFormProps) => {
    let history = useHistory();

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [validated, setValidated] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string>("");
    const [imgVisible, setImgVisible] = useState<boolean>(false);
    const [gender, setGender] = useState<string>("");
    const [isModalUpdateShow, setIsModalUpdateShow] = useState(false);

    const singleUser = useSelector(
        (state: RootState) =>
            state.dataMitra.singleUser ?? ({} as UserModel)
    );

    const singleCustomerState = useSelector(
        (state: RootState) => state.dataCustomer.single ?? {} as CustomerModel
    );

    const onCancelHandler = () => {
        history.push("/customer/account/list");
    };

    const onImageClicked = (path: string) => {
        setImagePreviewUrl(getMediaEndpoint(path));
        setImgVisible(true);
    };

    useEffect(() => {
        if (checkObjectIsNotEmpty(singleCustomerState)) {
            if (singleCustomerState.gender === CustomerGender.Man) {
                setGender("Pria")
            } else if (singleCustomerState.gender === CustomerGender.Woman) {
                setGender("Wanita")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleCustomerState]);
    const handleModalUpdate = () => {
        setIsModalUpdateShow(prevState => !prevState);
    };
    return (
        <Form
            noValidate
            validated={validated}
            onSubmit={(e) => {
                const form = e.currentTarget;
                if (!form.checkValidity()) {
                    e.preventDefault();
                    e.stopPropagation();
                } else {
                    e.preventDefault();
                    props.onSubmit(name, description);
                    setName("");
                    setDescription("");
                }
                setValidated(true);
            }}>
            <Form.Group className="mb-4">
                <Form.Label>Nama Lengkap</Form.Label>
                <Form.Control type="text" value={singleCustomerState.fullname} required readOnly/>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintext" className="mb-4">
                <Form.Label column sm="12" className="field_plaint_text">E-Mail</Form.Label>
                <Col sm="12" className="d-flex">
                    <Form.Control type="text" value={singleUser.email} required readOnly/>
                    <button
                        className="btn button-primary mx-2"
                        onClick={handleModalUpdate}
                        type="button"
                    >
                        Ubah
                    </button>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintext" className="mb-4">
                <Form.Label column sm="12" className="field_plaint_text">Nomor Telepon</Form.Label>
                <Col sm="12" className="d-flex">
                    <Form.Control type="text" value={singleCustomerState.phoneNumber} required readOnly/>
                    <button
                        className="btn button-primary mx-2"
                        onClick={handleModalUpdate}
                        type="button"
                    >
                        Ubah
                    </button>
                </Col>
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Tanggal Lahir</Form.Label>
                <br/>
                <Moment format="DD/MM/YYYY">{singleCustomerState.birthDate}</Moment>
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Jenis Kelamin</Form.Label>
                <Form.Control type="text" value={gender} required readOnly/>
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Customer Image</Form.Label>
                <br/>
                <img
                    src={getMediaEndpoint(singleCustomerState.customerPicture)}
                    alt="profile"
                    onClick={() => onImageClicked(singleCustomerState.customerPicture)}
                    onError={addDefaultProfileImage}
                    className="w-100"
                />
                <br/>
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>KTP Image</Form.Label>
                <br/>
                <img
                    src={getMediaEndpoint(singleCustomerState.ktpImage)}
                    alt="ktp"
                    onClick={() => onImageClicked(singleCustomerState.ktpImage)}
                    onError={addDefaultProfileImage}
                    className="w-100"
                />
                <br/>

            </Form.Group>
            <Viewer
                visible={imgVisible}
                onClose={() => {
                    setImgVisible(false);
                }}
                images={[
                    {
                        src: imagePreviewUrl,
                        alt: "",
                    },
                ]}
            />
            <div className="text-center mt-5">
                <button
                    className="btn button-outline-primary mr-3"
                    type="reset"
                    onClick={onCancelHandler}>
                    CANCEL
                </button>
                {/* <button className="btn button-primary" type="submit">
          {isEdit ? "UPDATE REVISION OPTION" : "CREATE REVISION OPTION"}
        </button> */}
            </div>
            <UpdateBasicAccountModal
                isShow={isModalUpdateShow}
                onCloseHandler={handleModalUpdate}
                data={
                    {
                        userId: singleUser.id,
                        email: singleUser.email,
                        phoneNumber: singleCustomerState.phoneNumber,
                    }
                }
            />
        </Form>
    );
};

export interface DataCustomerFormProps {
    onSubmit: (name: string, description: string) => void;
    data: UserModel;
    isEdit: boolean;
}

export default DataCustomerForm;
