export const convertFileSize = (bytes: number) => {
    return (bytes / (1024*1024)).toFixed(2);
}

export const getRangeTotal = (start:number, end:number):number=> {
   return Array.from({ length: end - start + 1 }, (_, i) => i).length;
}

export const groupBy = (arr: any[], property: string | number) => {
    return arr.reduce(function (memo, x) {
        if (!memo[x[property]]) {
            memo[x[property]] = [];
        }
        memo[x[property]].push(x);
        return memo;
    }, []);
};

export const groupByExt =  (data: any[], ...keys: any[]) =>
{
    // Ex: {values: {color: 'red'}}, ['values', 'color'] => 'red'
    // @ts-ignore
    const getGroupFromItem = (item, keys) =>
    {
        return (keys.length > 1)
            ? getGroupFromItem(item[keys[0]], keys.slice(1))
            : item[keys[0]]
    }

    return data.reduce((results, item) =>
        {
            // Get the first instance of the key by which we're grouping
            var group = getGroupFromItem(item, keys);

            // Ensure that there's an array to hold our results for this group
            results[group] = results[group] || [];

            // Add this item to the appropriate group within results
            results[group].push(item);

            // Return the updated results object to be passed into next reduce call
            return results;
        },

        // Initial value of the results object
        {}
    );
};

export function removeUnnecessaryComma(inputString: string) {
    // Ekspresi reguler untuk mencocokkan pola UUID (ID) di dalam string
    var regex = /\b[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\b/g;

    // Menggunakan metode replace() untuk menghapus koma yang berada di depan atau di belakang UUID
    var hasil = inputString.replace(/(^,)|(,$)/g, '');

    return hasil;
}