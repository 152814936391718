import React, { Fragment } from "react";
import Moment from "react-moment";
import {endpoint} from "../../../../../../../constants";

const VideoMessage = ({ content = "", createdAt = "", ...props }: VideoMessageProps) => {
   return (
      <Fragment>
         <div className={`message_list message_out`}>
            <div
               className={`message_list_item message_out}`}
            >
               <div className={`message_box`}>
                  <div className={"message_video"}>
                     {/*<img*/}
                     {/*   onClick={props.onMessageClick}*/}
                     {/*   className={`profile_image}`}*/}
                     {/*   src="/assets/images/default_video_thumbnail.png"*/}
                     {/*   onError={addDefaultVideoImage}*/}
                     {/*   alt="profile"*/}
                     {/*   width={220}></img>*/}

                     <video id="playVideoPreview" width={220} onClick={props.onMessageClick} className={`profile_image`}>
                        <source
                           src={`${endpoint + content}#t=2.4`}
                           type="video/mp4"
                        />
                     </video>
                  </div>
                  <span className={`message_time float-right`}>
                     <Moment format="HH:mm" withTitle>
                        {createdAt}
                     </Moment>
                  </span>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export interface VideoMessageProps {
   content: string;
   createdAt: string;
   onMessageClick: () => void;
}

export default VideoMessage;
