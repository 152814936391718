import React, { useEffect, useState } from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../app/store";
import DefaultSubHeader from "../../../components/DataTable/DefaultSubHeader";
import { ElementPropModel } from "../../../components/DataTable/models";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import { deleteRequestAdmin, getRequestAdminList, resetStatus } from "../requestAdmin.reducer";
import RequestAdminDetailModel from "./RequestAdminDetailModal";
import {RequestAdminModel} from "../models";

const RequestAdminTable = () => {
   const dispatch = useDispatch();

   const requestAdminList = useSelector((state: RootState) => state.requestAdmin.list ?? []);
   const [requestId, setRequestId] = useState<string>("");
   const [showDetail, setShowDetail] = useState<boolean>(false);

   const dateCreated = useSelector((state: RootState) => state.requestAdmin.dateCreated);

   // const [reportIssueState, setReportIssueState] = useState([
   //   {
   //     id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
   //     title: "string",
   //     description: "string",
   //     reportIssueCategoryId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
   //     customerId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
   //   },
   // ]);

   const [filterText, setFilterText] = useState("");
   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

   const filteredItems = requestAdminList.filter(
      (item) =>
         item.projectName && item.projectName.toLowerCase().includes(filterText.toLowerCase())
   );

   const onSetterFilterText = (value: string) => {
      setFilterText(value);
   };

   const onDetailShowHandler = (id: string) => {
      setShowDetail(true);
      setRequestId(id);
   };

   const onDetailModalToggler = () => {
      setShowDetail((prevState) => !prevState);
      setRequestId("");
   };

   const elementProps = {
      placeholder: "cari berdasarkan nama",
      buttonName: "",
      createUrl: "/master/issue-category/create",
      subHeaderLine: true,
   } as ElementPropModel;

   const columns: TableColumn<RequestAdminModel>[] = [
      {
         name: "NO.",
         width: "60px",
         cell: (_d: any, index: number) => index + 1,
      },
      {
         name: "NAMA PEMINTA",
         selector: (row) => row.projectName,
         sortable: true,
      },
      {
         name: "ALASAN",
         selector: (row) => row.reason,
         sortable: true,
      },
      {
         name: "AKSI",
         cell: (d: { id: string }) => (
            <>
               <button
                  className="btn button-primary mr-2"
                  onClick={() => onDetailShowHandler(d.id)}>
                  TINJAU
               </button>

               <button
                  onClick={() => {
                     if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
                        onDelete(d.id);
                  }}
                  className="btn button-danger">
                  HAPUS
               </button>
            </>
         ),
      },
   ];

   // end of datatable components

   useEffect(() => {
      dispatch(getRequestAdminList());
      dispatch(resetStatus());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      dispatch(getRequestAdminList());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dateCreated]);

   const onDelete = (id: string) => {
      dispatch(
         deleteRequestAdmin({
            id: id,
            roomId: "string",
            projectName: "string",
            projectId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            reason: "string",
            status: 0,
         })
      );
   };

   return (
      <>
         <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            noHeader
            subHeader
            selectableRows
            subHeaderComponent={DefaultSubHeader(
               filterText,
               resetPaginationToggle,
               onSetterFilterText,
               elementProps
            )}
            customStyles={TableCustomStyles}
            persistTableHead
         />
         <RequestAdminDetailModel
            requestId={requestId}
            show={showDetail}
            handleClose={onDetailModalToggler}
         />
      </>
   );
};

export default RequestAdminTable;
