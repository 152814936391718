import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import { ChangeStatusModal, CustomerModel, RegisterAdminModel, UpdateAdminModel, UserModel } from "./models";
import DataAdminApi from "./dataAdmin.api";
import {ApiResponseStatus} from "../../@core/models/apiResponseStatus/apiResponseStatus";
import DataMitraApi from "../dataMitra/dataMitra/dataMitra.api";
import {GetUserList} from "../dataMitra/dataMitra/models";

export interface DataAdminSlice {
   name?: string;
   userList?: UserModel[];
   rows?: number;
   single?: UserModel;
   isLoading?: boolean;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
   dateCreated?: string;
}

export const getUserList = createAsyncThunk(
   "dataAdminState/dataAdminList",
   async (args: GetUserList, { rejectWithValue }) => {
      try {
         return await DataMitraApi.getUserList(args);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const registerAdmin = createAsyncThunk(
   "dataAdminState/registerAdmin",
   async (userData: RegisterAdminModel, { rejectWithValue }) => {
      try {
         return await DataAdminApi.registerAdmin(userData);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const updateAdmin = createAsyncThunk(
   "dataAdminState/updateAdmin",
   async (userData: UpdateAdminModel, { rejectWithValue }) => {
      try {
         return await DataAdminApi.updateAdmin(userData);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const inActiveAdmin = createAsyncThunk(
   "dataAdminState/inActiveAdmin",
   async (data: ChangeStatusModal, { rejectWithValue }) => {
      try {
         return await DataAdminApi.inActiveAdmin(data);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const dataAdminSlice = createSlice({
   name: "DataAdminState",
   initialState: {} as DataAdminSlice,
   reducers: {
      setSingle: (state, action) => {
        state.single = action.payload;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(getUserList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getUserList.fulfilled, (state, { payload }) => {
         state.userList = payload.data;
         state.rows = payload.total;
         state.isLoading = false;
      });
      builder.addCase(getUserList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(registerAdmin.pending, (state) => {
         state.status = ApiResponseStatus.pending;
         state.isLoading = true;
      });
      builder.addCase(registerAdmin.fulfilled, (state, { payload }) => {
         state.status = ApiResponseStatus.success;
         state.dateCreated = new Date().toISOString();
         state.isLoading = false;
      });
      builder.addCase(registerAdmin.rejected, (state, { payload }) => {
         state.status = ApiResponseStatus.failed;
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(updateAdmin.pending, (state) => {
         state.status = ApiResponseStatus.pending;
         state.isLoading = true;
      });
      builder.addCase(updateAdmin.fulfilled, (state, { payload }) => {
         state.status = ApiResponseStatus.success;
         state.dateCreated = new Date().toISOString();
         state.isLoading = false;
      });
      builder.addCase(updateAdmin.rejected, (state, { payload }) => {
         state.status = ApiResponseStatus.failed;
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(inActiveAdmin.pending, (state) => {
         state.status = ApiResponseStatus.pending;
         state.isLoading = true;
      });
      builder.addCase(inActiveAdmin.fulfilled, (state, { payload }) => {
         state.status = ApiResponseStatus.success;
         state.dateCreated = new Date().toISOString();
         state.isLoading = false;
      });
      builder.addCase(inActiveAdmin.rejected, (state, { payload }) => {
         state.status = ApiResponseStatus.failed;
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });
   },
});

export const { setSingle, resetStatus } = dataAdminSlice.actions;
export default dataAdminSlice.reducer;
