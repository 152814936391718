import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import LanguageTable from "../components/RevisionOptionTable";
import { getRevisionOptionList, resetSingle } from "../revisionOption.reducer";
import { RootState } from "../../../../app/store";

const RevisionOptionPage = () => {
  const dispatch = useDispatch();
  const statusState = useSelector(
    (state: RootState) => state.revisionOption.date
  );

  useEffect(() => {
    dispatch(getRevisionOptionList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusState]);

  useEffect(() => {
    dispatch(resetSingle());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <h4 className="card-label">Master Opsi Revisi</h4>
          <LanguageTable />
        </Col>
      </Row>
    </Container>
  );
};

export default RevisionOptionPage;
