import React, {useEffect} from 'react';
import {Row, Container, Col} from "react-bootstrap";
import {BsArrowRightCircle} from "react-icons/bs";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import MitraStatisticTable from "../components/mitraStatistic/MitraStatisticTable";
import {EventSummaryModel} from "../models";
import {getEventSummary} from "../marketingEvent.reducer";
import {useParams} from "react-router-dom";

const MitraStatisticPage = () => {
    const dispatch = useDispatch();
    let { id } = useParams<{ id: string }>();

    const eventSummary = useSelector((state: RootState) => state.marketingEvent.eventSummary ?? {} as EventSummaryModel);

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getEventSummary(id));
        }
    }, [id]);

    return (
        <Container className="content-body" fluid>
            <Row>
                <Col lg={3} xs={6} className="mb-3">
                    <div className="card-counter card-warning-color text-light">
                        <div className="flex-grow-1 p-3">
                            <div className="card-counter-value">
                                <h1 className="mb-0">{eventSummary.totalView}</h1>
                            </div>
                            <div className="card-counter-label">
                                <span>Viewers</span>
                            </div>
                        </div>
                        <div style={{cursor: "pointer"}} className="p-2">
                            <BsArrowRightCircle size={18}/>
                        </div>
                    </div>
                </Col>
                <Col lg={3} xs={6} className="mb-3">
                    <div className="card-counter card-success-color text-light">
                        <div className="flex-grow-1 p-3">
                            <div className="card-counter-value">
                                <h1 className="mb-0">{eventSummary.totalDownload}</h1>
                            </div>
                            <div className="card-counter-label">
                                <span>Download</span>
                            </div>
                        </div>
                        <div  style={{cursor: "pointer"}} className="p-2">
                            <BsArrowRightCircle size={18}/>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className={"my-3"}>
                <Col md={12} className="mb-3">
                    <h4 className="card-label d-inline">Statistik Mitra</h4>
                </Col>
                <Col md={12}>
                    <MitraStatisticTable />
                </Col>
            </Row>
        </Container>
    );
};

export default MitraStatisticPage;
