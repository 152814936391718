import React from "react";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";
import {checkObjectIsNotEmpty} from "../checkEmptyObject";
import { VideoUploadModel } from "../../features/chat/models";

const UploadVideo = ({ selectedVideo = {} as VideoUploadModel, ...props }: UploadVideoProps) => {
   const handleAcceptedProfileFiles = (files: any[]) => {
      props.uploadVideoResult({
         file: files[0],
         type: files[0].type.split("/")[1],
         localPath: URL.createObjectURL(files[0]),
         fileName: files[0].name,
      });
   };

   return (
      <Dropzone onDrop={(acceptedFiles) => handleAcceptedProfileFiles(acceptedFiles)}>
         {({ getRootProps, getInputProps }) => (
            <div
               className="dropzone file-upload-frame w-100"
               style={
                  checkObjectIsNotEmpty(selectedVideo)
                     ? { minHeight: "450px" }
                     : { minHeight: "auto" }
               }>
               <div className="dz-message needsclick" {...getRootProps()}>
                  <input {...getInputProps()} />

                  <div className="text-center file-upload-label">
                     <div>
                        {selectedVideo.file !== undefined && (
                           <>
                              {/* <FontAwesomeIcon
                                 icon={faFileVideo}
                                 width="48"
                                 className="file-upload-icon mb-3"
                              /> */}
                              <div className="d-flex justify-content-center">
                                 <ReactPlayer
                                    controls
                                    url={selectedVideo.localPath}
                                    style={{ width: "100%" }}
                                 />
                              </div>
                              <br />
                           </>
                        )}
                        {selectedVideo.file === undefined && (
                           <>
                              <FontAwesomeIcon
                                 icon={faUpload}
                                 width="48"
                                 className="file-upload-icon mb-3"
                              />
                              <br />
                           </>
                        )}
                        <span>Upload Video</span>
                     </div>
                  </div>
               </div>
            </div>
         )}
      </Dropzone>
   );
};

export interface UploadVideoProps {
   selectedVideo: VideoUploadModel;
   uploadVideoResult: (file: VideoUploadModel) => void;
}

export default UploadVideo;
