import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import { RootState } from "../../../app/store";
import { uploadSingleImage } from "../../media/fileUpload/fileUpload";
import { uploadStatus } from "../../media/fileUpload/models";
import DiscountApi from "./discount.api";
import { DiscountFormModel, VoucherModel, VoucherModelList } from "./models";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";

export interface DiscountSlice {
   list?: VoucherModelList[];
   single?: VoucherModel;
   dateCreated?: string;
   isLoading?: boolean;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
}

export const getDiscountList = createAsyncThunk(
   "masterDiscountState/getMasterDiscountList",
   async (id: string | undefined = undefined, { getState, rejectWithValue }) => {
      const { token } = (getState() as RootState).user;
      try {
         return await DiscountApi.getDiscountList(token as string);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const getSingleDiscount = createAsyncThunk(
   "masterDiscountState/getSingleDiscount",
   async (voucherId: string, { getState, rejectWithValue }) => {
      const { token } = (getState() as RootState).user;
      try {
         return await DiscountApi.getDiscountSingleItem(token as string, voucherId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const createDiscount = createAsyncThunk(
   "masterIconRewardState/createMasterIconReward",
   async (formData: DiscountFormModel, { getState, rejectWithValue }) => {
      const { token } = (getState() as RootState).user;
      try {
         const response = await uploadSingleImage(token as string, formData.image, "voucher");
         if (response.status === uploadStatus.success) {
            const _formData = formData;
            _formData.banner = response.path;
            return await DiscountApi.createDiscount(token as string, _formData);
         }
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const updateDiscount = createAsyncThunk(
   "masterDiscountState/updateMasterDiscount",
   async (formData: VoucherModel, { getState, rejectWithValue }) => {
      const { token } = (getState() as RootState).user;
      try {
         return await DiscountApi.updateDiscount(token as string, formData);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const updateDiscountWithImage = createAsyncThunk(
   "masterDiscountState/updateMasterDiscountWithImage",
   async (formData: DiscountFormModel, { getState, rejectWithValue }) => {
      const { token } = (getState() as RootState).user;
      try {
        try {
            const response = await uploadSingleImage(token as string, formData.image, "voucher");
            if (response.status === uploadStatus.success) {
               const _formData = formData;
               _formData.banner = response.path;
               return await DiscountApi.updateDiscount(token as string, _formData);
            }
         } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
         }
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const deleteDiscount = createAsyncThunk(
   "masterDiscountState/deleteMasterDiscount",
   async (voucherId: string, { getState, rejectWithValue }) => {
      const { token } = (getState() as RootState).user;
      try {
         return await DiscountApi.deleteDiscount(token as string, voucherId);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const discountSlice = createSlice({
   name: "masterDiscountState",
   initialState: {} as DiscountSlice,
   reducers: {
      resetSingle: (state) => {
         state.single = {} as VoucherModel;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
      },
   },
   extraReducers: (builder) => {
      // get list reducer
      builder.addCase(getDiscountList.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getDiscountList.fulfilled, (state, { payload }) => {
         let _discountResult = payload;
         _discountResult = _discountResult.map((discount: VoucherModelList) => {
            const _discount = discount;
            _discount.formattedExpiredDate = new Date(discount.expiredDate).toLocaleDateString('en-GB');
            return _discount;
         });
         state.list = _discountResult;
         state.isLoading = false;
      });
      builder.addCase(getDiscountList.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      // get single reducer
      builder.addCase(getSingleDiscount.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getSingleDiscount.fulfilled, (state, { payload }) => {
         state.single = payload;
         state.isLoading = false;
      });
      builder.addCase(getSingleDiscount.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      // create reducer
      builder.addCase(createDiscount.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(createDiscount.fulfilled, (state) => {
         state.dateCreated = new Date().toString();
         state.isLoading = false;
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(createDiscount.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = ApiResponseStatus.failed;
      });

      // update reducer
      builder.addCase(updateDiscount.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(updateDiscount.fulfilled, (state) => {
         state.dateCreated = new Date().toString();
         state.isLoading = false;
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(updateDiscount.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = ApiResponseStatus.failed;
      });

      // update with upload image reducer
      builder.addCase(updateDiscountWithImage.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(updateDiscountWithImage.fulfilled, (state) => {
         state.dateCreated = new Date().toString();
         state.isLoading = false;
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(updateDiscountWithImage.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = ApiResponseStatus.failed;
      });

      // delete reducer
      builder.addCase(deleteDiscount.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(deleteDiscount.fulfilled, (state) => {
         state.dateCreated = new Date().toString();
         state.isLoading = false;
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(deleteDiscount.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
         state.status = ApiResponseStatus.failed;
      });
   },
});

export const { resetSingle, resetStatus } = discountSlice.actions;
export default discountSlice.reducer;
