import React, { Fragment } from "react";
import { Col, Modal, Row, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import ComponentLoader from "../../../components/ComponentLoader";
import {getMediaEndpoint} from "../../../helpers/imageManipulation";
import {addDefaultCardImage, addDefaultProfileImage} from "../../../helpers/defaultBrokenImage";
import {ResultProjectModel} from "../models";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/store";

const ProjectReviewModal = ({
   showModal = false,
    data = {} as ResultProjectModel,
   ...props
}: ProjectReviewModalProps) => {
   const resultReview = useSelector((state: RootState) => state.project.resultReview ?? []);

   return (
      <Fragment>
         <Modal show={showModal} onHide={props.onModalHide} className="discount_modal" centered>
            <Modal.Body>
               <ComponentLoader loading={false} loadingName="Checking Review ...">
                  <Row>
                     <Col md={12} className={`result_modal_header`}>
                        <h1 className={`d-inline result_modal_title`}>
                           Lihat Penilaian
                        </h1>
                        <span
                           className={`material-icons-outlined float-right result_modal_close`}
                           onClick={props.onModalHide}>
                           close
                        </span>
                     </Col>
                     <Col md={12}>
                        <div className="p-3">
                           <div className="d-flex mb-5">
                              <div>
                                 <img
                                    className={"review_activity_image"}
                                    src={`${getMediaEndpoint(data.image)}`}
                                    onError={addDefaultCardImage}
                                    alt="product"
                                 />
                              </div>
                              <div className="ml-3" style={{ flex: "auto" }}>
                                 <span className={"review_activity_title"}>
                                    {data.packageProductName}
                                 </span>
                                 <br />
                                 <div>
                                    <div
                                       className={`d-block mb-1 ${"project_activity_subtitle"}`}>
                                       <div className="d-flex align-items-center mb-1">
                                          <div
                                             className={`d-inline-block mr-1 project_activity_profile_image_frame`}>
                                             <img
                                                className={`mt-0 project_activity_profile_image`}
                                                src={`${getMediaEndpoint(data.mitraPicture, true)}`}
                                                onError={addDefaultProfileImage}
                                                alt="profile"></img>
                                          </div>
                                          <span>{data.mitraName}</span>
                                       </div>
                                       {resultReview.length !== 0 && (
                                           <div>
                                             <span>
                                                Dinilai Pada{" "}
                                                <Moment format="DD MMMM YYYY">
                                                   {resultReview[0]?.createdDate}
                                                </Moment>
                                             </span>
                                           </div>
                                       )}
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="d-flex justify-content-center">
                              {[1,2,3,4,5].map((number) => (
                                 <div
                                    className={`mr-2 ml-2 ${
                                        resultReview[0]?.score >= number
                                          ? "selected_star"
                                          : "unselected_star"
                                    }`}>
                                    <FontAwesomeIcon icon={faStar} width="48" />
                                 </div>
                              ))}
                           </div>

                           <Form.Group className="mt-5">
                              <Form.Label>Deskripsi Penilaian</Form.Label>
                              <Form.Control
                                 as="textarea"
                                 rows={5}
                                 value={resultReview[0]?.comment}
                                 placeholder="Deskripsi"
                                 readOnly
                              />
                           </Form.Group>
                        </div>
                     </Col>
                  </Row>
               </ComponentLoader>
            </Modal.Body>
         </Modal>
      </Fragment>
   );
};

export interface ProjectReviewModalProps {
   showModal: boolean;
   onModalHide: () => void;
   data: ResultProjectModel;
}

export default ProjectReviewModal;
