import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";

const SocialMediaList = () => {
  const socialMediaState = useSelector(
    (state: RootState) => state.dataDiri.socialMedia ?? []
  );
  return (
    <>
      <h6 className="mb-4 data_diri_tab_body_title d-inline-block">
        Sosial Media{" "}
      </h6>

      {socialMediaState.map((item) => {
        return (
          item.username !== "" && (
            <Form.Group as={Row} controlId="formPlaintext">
              <Form.Label column sm="12" className="field_plaint_text">
                {item.name}
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  readOnly
                  defaultValue={item.link + "/" + item.username}
                  className="field_plaint_text"
                  style={{ resize: "none" }}
                />
              </Col>
              <Col sm="12">
                <hr className="tab_body_underline" />
              </Col>
            </Form.Group>
          )
        );
      })}
    </>
  );
};

export default SocialMediaList;
