import React, {ChangeEvent, useState} from 'react';
import {Form} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {CreateOfficialPartnerModel} from "../models";

const OfficialPartnerForm = ({...props}: OfficialPartnerFormProps) => {
    let history = useHistory();

    const [validated, setValidated] = useState<boolean>(false);

    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");

    const [passwordShown, setPasswordShown] = useState<boolean>(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState<boolean>(false);

    const [confirmPasswordMessage, setConfirmPasswordMessage] = useState<string>('');
    const [isConfirmPasswordError, setIsConfirmPasswordError] = useState<boolean>(false);

    const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const onUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
    };

    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const onConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsConfirmPasswordError(false);
        setConfirmPasswordMessage('');
        setConfirmPassword(e.target.value);
    };

    const togglePasswordVisiblity = () => {
        setPasswordShown((prevState) => !prevState);
    };

    const toggleConfirmPasswordVisiblity = () => {
        setConfirmPasswordShown((prevState) => !prevState);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        const form = e.currentTarget;
        if (password !== confirmPassword) {
            e.preventDefault();
            e.stopPropagation();
            setIsConfirmPasswordError(true);
            setConfirmPasswordMessage("password dan konfirm password harus sama")
            return null;
        }
        if (!form.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
            props.onSubmit({
                name: name,
                email: email,
                userName: username,
                password: password,
                confirmPassword: confirmPassword
            } as CreateOfficialPartnerModel);
        }
        setValidated(true);
    }

    const onCancelHandler = () => {
        history.push("/mitra/account/personal-data/list");
    };

    return (
        <Form
            noValidate
            validated={validated}
            onSubmit={onSubmit}>

            <Form.Group className="mb-4">
                <Form.Label>Nama</Form.Label>
                <Form.Control type="text" value={name} onChange={onNameChange} required/>
            </Form.Group>

            <Form.Group className="mb-4">
                <Form.Label>E-Mail</Form.Label>
                <Form.Control type="email" value={email} onChange={onEmailChange}/>
            </Form.Group>

            <Form.Group className="mb-4">
                <Form.Label>Username</Form.Label>
                <Form.Control type="text" value={username} onChange={onUsernameChange} required/>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                    placeholder="Password"
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    required
                    onChange={onPasswordChange}
                />
                <FontAwesomeIcon
                    icon={faEye}
                    width="22"
                    onClick={togglePasswordVisiblity}
                    className="float-right"
                    style={{
                        marginTop: "-28px",
                        color: passwordShown ? "#F37334" : "#DFE3E6",
                        marginRight: "10px",
                    }}
                />
                <Form.Control.Feedback type="invalid">Masukkan password</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                    placeholder="Confirm Password"
                    type={confirmPasswordShown ? "text" : "password"}
                    name="confirmPassword"
                    required
                    onChange={onConfirmPasswordChange}
                />
                <FontAwesomeIcon
                    icon={faEye}
                    width="22"
                    onClick={toggleConfirmPasswordVisiblity}
                    className="float-right"
                    style={{
                        marginTop: "-28px",
                        color: confirmPasswordShown ? "#F37334" : "#DFE3E6",
                        marginRight: "10px",
                    }}
                />
                {isConfirmPasswordError && (
                    <small className="text-danger">{confirmPasswordMessage}</small>
                )}

                <Form.Control.Feedback type="invalid">
                    {confirmPasswordMessage}
                </Form.Control.Feedback>
            </Form.Group>

            <div className="text-center mt-5">
                <button
                    className="btn button-outline-primary mr-3"
                    type="reset"
                    onClick={onCancelHandler}>
                    CANCEL
                </button>
                <button className="btn button-primary" type="submit">
                    BUAT OFFICIAL PARTNER
                </button>
            </div>

        </Form>
    )
};

export interface OfficialPartnerFormProps {
    onSubmit: (formData: CreateOfficialPartnerModel) => void;
}

export default OfficialPartnerForm;