import React, { forwardRef, Fragment, useEffect, useState } from "react";
import {
   Col,
   Modal,
   Row,
   Image,
   OverlayTrigger,
   Tooltip,
   Alert,
   Dropdown,
   Form,
   Carousel,
} from "react-bootstrap";
import ReactPlayer from "react-player";
import { saveAs } from "file-saver";
import SwiperCore, { Navigation } from "swiper";
// import { useProjectResult } from "@/hooks/project";
// import { FileResultModel } from "../../../../../types/projectResult/models";
// import { useProfilePicture } from "@/hooks/user";
import Moment from "react-moment";
import {FileResultModel} from "../models";
import {getMediaEndpoint} from "../../../helpers/imageManipulation";
import {checkObjectIsNotEmpty} from "../../../helpers/checkEmptyObject";
import {addDefaultProfileImage} from "../../../helpers/defaultBrokenImage";
import {useDispatch, useSelector} from "react-redux";
import {getCommentResult} from "../dataProject.reducer";
import {RootState} from "../../../app/store";
import commentIcon from "../../../assets/icons/project_result_media_icon.svg";
// import { checkObjectIsNotEmpty } from "@/helpers/checkEmptyObject";
// import { DataStatus } from "../../../../../types/general/@types/status";
// import { getMediaEndpoint} from "@/helpers/imageManipulation";
// import { addDefaultProfileImage } from "@/helpers/defaultBrokenImage";

SwiperCore.use([Navigation]);

export enum DetailAttachmentType {
   Picture,
   Video,
}

const DetailMediaModal = ({
   showModal = false,
   detailMediaIndex = 0,
   resultId = "",
   userId = "",
   fileMedia = [],
   ...props
}: DetailMediaModalProps) => {
   const dispatch = useDispatch();
   const [singleMedia, setSingleMedia] = useState<FileResultModel>({} as FileResultModel);
   const [profileImage, setProfileImage] = useState<ProfileImage[]>([]);
   const [_showEmoji, setShowEmoji] = useState<boolean>(false);
   const [messageContent, setMessageContent] = useState<string>("");
   const [index, setIndex] = useState(0);

   const commentResult = useSelector((state: RootState) => state.project.commentResult ?? []);

   const onDowloadClicked = () => {
      saveAs(getMediaEndpoint(singleMedia.url));
   };

   // const {
   //    addCommentResult,
   //    loadCommentResult,
   //    commentResult,
   //    status,
   //    resetStatus,
   // } = useProjectResult();
   // const { getProfilePicture } = useProfilePicture();

   // useEffect(() => {
   //    if (resultId !== "") {
   //       if (detailDownloadMode) {
   //          loadListPickedFile(resultId);
   //       } else {
   //          loadFileResult(resultId);
   //       }
   //    }
   // }, [resultId]);

   useEffect(() => {
      if (checkObjectIsNotEmpty(singleMedia)) {
         dispatch(getCommentResult(singleMedia.id ?? ''));
      }
   }, [singleMedia]);

   useEffect(() => {
      setIndex(detailMediaIndex);
      if (fileMedia.length !== 0) {
         setSingleMedia(fileMedia[detailMediaIndex]);
      }
   }, [detailMediaIndex, fileMedia]);

   useEffect(() => {
      if (commentResult.length !== 0) {
         const _commentResult = commentResult.filter((value, index) => {
            const _value = JSON.stringify(value.userId);
            return (
               index ===
               commentResult.findIndex((obj) => {
                  return JSON.stringify(obj.userId) === _value;
               })
            );
         });
         _commentResult.map(async (item) => {
            // const url = await getProfilePicture(item.userId);
            const url = "";
            setProfileImage((prevState) => [
               ...prevState,
               {
                  userId: item.userId,
                  url: url as string,
               },
            ]);
         });
      }
   }, [commentResult]);

   const checkMediaType = (url: string) => {
      let fileFormat = url.substring(url.lastIndexOf(".") + 1, url.length);
      if (fileFormat === "webm" || fileFormat === "mp4") {
         return DetailAttachmentType.Video;
      } else {
         return DetailAttachmentType.Picture;
      }
   };

   const handleSelect = (selectedIndex: number, _e: any) => {
      setIndex(selectedIndex);
      const _media = fileMedia[selectedIndex];
      setSingleMedia(_media);
   };

   const getProfileImage = (userId: string) => {
      const _profileImage = profileImage.find((item) => item.userId === userId);
      return _profileImage?.url;
   };

   return (
      <Modal
         show={showModal}
         onHide={props.onModalHide}
         className="discount_modal"
         size="xl"
         centered>
         <Modal.Body>
            <Row>
               <Col md={12} className={`request_admin_modal_header`}>
                  <h1 className={`d-inline request_admin_modal_title`}>Detail Media</h1>

                  <span
                     className={`material-icons-outlined float-right request_admin_close`}
                     onClick={props.onModalHide}>
                     close
                  </span>
               </Col>
               <Col md={12} className="p-2">
                  <div className="px-4 media_carousel_container">
                     <Carousel
                        fade
                        activeIndex={index}
                        onSelect={handleSelect}
                        indicators={false}
                        nextIcon={<span aria-hidden="true" className={"slider_next_icon"} />}
                        prevIcon={<span aria-hidden="true" className={"slider_prev_icon"} />}
                        interval={null}>
                        {fileMedia.map((media) => {
                           if (checkMediaType(media.url) === DetailAttachmentType.Picture) {
                              return (
                                 <Carousel.Item key={media.id}>
                                    <img
                                       className="w-100"
                                       src={media.isFinal ? getMediaEndpoint(media.url) : getMediaEndpoint(media.url)}
                                       alt="attachment"
                                       height={500}
                                       style={{ objectFit: "contain" }}
                                    />
                                 </Carousel.Item>
                              );
                           } else if (checkMediaType(media.url) === DetailAttachmentType.Video) {
                              return (
                                 <Carousel.Item key={media.id}>
                                    <ReactPlayer
                                       controls
                                       src={`${getMediaEndpoint(media.url)}`}
                                       width={"100%"}
                                       height={500}
                                       light
                                    />
                                 </Carousel.Item>
                              );
                           }
                        })}
                     </Carousel>
                  </div>

                  <div className="px-5 mb-4">
                     <Row>
                        <Col md={12} className="mb-3 mt-5">
                           <img
                              src={commentIcon}
                              alt="project_calendar_icon"
                              className="d-inline-block"
                           />

                           <h2 className={`d-inline-block ml-2 detail_activity_title`}>
                              Komentar
                           </h2>
                           <hr />
                        </Col>
                        <Col md={12}>
                           {commentResult.length === 0 && (
                              <Alert variant="light" className="text-center">
                                 Belum ada komentar untuk media ini
                              </Alert>
                           )}
                           {commentResult.map((comment) => (
                              <div className="mb-2" key={comment.id}>
                                 <div className={`d-flex comment_box`}>
                                    <div>
                                       <Image
                                          src={getMediaEndpoint(getProfileImage(comment.userId) as string, true)}
                                          className="customer_review_profile_frame"
                                          onError={addDefaultProfileImage}
                                          width={48}
                                       />
                                    </div>
                                    <div className="ml-3">
                                       <div>
                                          <h1 className={`d-inline comment_name`}>
                                             {comment.name}
                                          </h1>
                                          <span className={`comment_time`}>
                                             {" "}
                                             <Moment format="DD/MM/YYYY HH:mm" withTitle>
                                                {comment.createdDate}
                                             </Moment>
                                          </span>
                                       </div>
                                       <div>
                                          <p className={`comment_description`}>
                                             {comment.message}
                                          </p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           ))}
                        </Col>
                     </Row>
                  </div>
               </Col>
            </Row>
         </Modal.Body>
      </Modal>
   );
};

type ProfileImage = {
   userId: string;
   url: string;
};

export interface DetailMediaModalProps {
   showModal: boolean;
   onModalHide: () => void;
   detailMediaIndex?: number;
   resultId: string;
   userId?: string;
   fileMedia: FileResultModel[];
}

export default DetailMediaModal;
