import React, {ChangeEvent, useEffect, useState} from "react";
import {Modal, Row, Col, Button, Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {changePasswordAsync, resetStatus} from "../dataMitra.reducer";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";
import {RootState} from "../../../../app/store";
import {ApiErrorResponse} from "../../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ComponentCircleLoader from "../../../../components/ComponentCircleLoader";

type ShowText = {
    password: boolean;
    confirmPassword: boolean;
}

type InputForm = {
    password: string;
    confirmPassword: string;
}

type ErrorForm = {
    password: {
        isError: boolean;
        message: string;
    };
    confirmPassword: {
        isError: boolean;
        message: string;
    }
}

const ChangePasswordModal = ({isShow = false, userId = '', ...props}: ChangePasswordModalProps) => {
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();

    const status = useSelector((state: RootState) => state.dataMitra.changePasswordStatus);
    const error = useSelector((state: RootState) => state.dataMitra.changePasswordError ?? ({} as ApiErrorResponse<any>));
    const isLoading = useSelector((state: RootState) => state.dataMitra.isLoading ?? false);

    const [modalShow, setModalShow] = useState(false);
    const [validated, setValidated] = useState<boolean>(false);

    const [showText, setShowTest] = useState<ShowText>({
        password: false,
        confirmPassword: false
    });
    const [inputForm, setInputForm] = useState<InputForm>({
        password: '',
        confirmPassword: ''
    });
    const [errorForm, setErrorForm] = useState<ErrorForm>({} as ErrorForm);

    useEffect(() => {
        setModalShow(isShow);
    }, [isShow]);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === ApiResponseStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(resetStatus());
                if (status === ApiResponseStatus.success) {
                    setModalShow(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setErrorForm({} as ErrorForm);
        setInputForm((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value
            }
        });
    };

    const togglePasswordVisiblity = (param: 'password' | 'confirmPassword') => {
        setShowTest((prevState) => {
            return {
                ...prevState,
                [param]: !prevState[param as keyof typeof prevState]
            }
        });
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        const form = e.currentTarget;
        console.log(inputForm.password, inputForm.confirmPassword);
        if (inputForm.password !== inputForm.confirmPassword) {
            e.preventDefault();
            e.stopPropagation();
            setErrorForm({
                password: {
                    isError: true,
                    message: "password dan konfirmasi password tidak sesuai"
                },
                confirmPassword: {
                    isError: true,
                    message: "password dan konfirmasi password tidak sesuai"
                }
            } as ErrorForm);
            return null;
        }
        if (!form.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
            dispatch(changePasswordAsync({
                id: userId,
                password: inputForm.password
            }));
        }
        setValidated(true);
    }

    return (
        <>
            <Modal
                show={modalShow}
                backdrop="static"
                keyboard={false}
                centered
                className="profile_modal">
                <Modal.Body>
                    <ComponentCircleLoader loading={isLoading} loadingName={`Menyimpan Perubahan`}>
                        <Row>
                            <Col md={12} className="mb-3">
                                <h5>
                                    Ganti Password
                                    <div className="float-right" onClick={props.onCloseHandler}>
                                        <span className="material-icons modal-close">close</span>
                                    </div>
                                </h5>
                            </Col>
                            <Col md={12} className="mb-3">
                                <Form
                                    noValidate
                                    id="changePasswordForm"
                                    validated={validated}
                                    onSubmit={onSubmit}>
                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            placeholder="Password"
                                            type={showText.password ? "text" : "password"}
                                            name="password"
                                            required
                                            onChange={onPasswordChange}
                                        />
                                        <FontAwesomeIcon
                                            icon={faEye}
                                            width="22"
                                            onClick={() => togglePasswordVisiblity('password')}
                                            className="float-right"
                                            style={{
                                                marginTop: "-28px",
                                                color: showText.password ? "#F37334" : "#DFE3E6",
                                                marginRight: "10px",
                                            }}
                                        />
                                        {errorForm.password?.isError && (
                                            <small className="text-danger">{errorForm.password?.message}</small>
                                        )}
                                        <Form.Control.Feedback type="invalid">Masukkan password</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control
                                            placeholder="Confirm Password"
                                            type={showText.confirmPassword ? "text" : "password"}
                                            name="confirmPassword"
                                            required
                                            onChange={onPasswordChange}
                                        />
                                        <FontAwesomeIcon
                                            icon={faEye}
                                            width="22"
                                            onClick={() => togglePasswordVisiblity('confirmPassword')}
                                            className="float-right"
                                            style={{
                                                marginTop: "-28px",
                                                color: showText.confirmPassword ? "#F37334" : "#DFE3E6",
                                                marginRight: "10px",
                                            }}
                                        />
                                        {errorForm.confirmPassword?.isError && (
                                            <small className="text-danger">{errorForm.confirmPassword?.message}</small>
                                        )}

                                        <Form.Control.Feedback type="invalid">
                                            Masukkan Konfirmasi Password
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col md={12} className="mb-3">
                                <Button
                                    className="float-right button-primary mr-2"
                                    type="submit" form="changePasswordForm"
                                >
                                    Simpan
                                </Button>
                            </Col>
                        </Row>
                    </ComponentCircleLoader>
                </Modal.Body>
            </Modal>
        </>
    );
};

export interface ChangePasswordModalProps {
    isShow: boolean;
    onCloseHandler: () => void;
    userId: string;
}

export default ChangePasswordModal;
