import React, { Fragment } from "react";
import Moment from "react-moment";
import {addDefaultProfileImage} from "../../../../../../../helpers/defaultBrokenImage";
import {endpoint} from "../../../../../../../constants";
const AlbumMessage = ({
   profileImage = "",
   senderName = "",
   totalLastAdded = 0,
   content = "",
   createdAt = "",
   albumName = "",
   ...props
}: AlbumMessageProps) => {
   return (
      <Fragment>
         <div className={`message_list message_in`}>
            <div className={`message_list_item message_in`}>
               <div className={`message_box`}>
                  <div>
                     <div className={`d-block mb-2 mitra_profile`}>
                        <div className={`d-inline-block mr-1 image_frame`}>
                           <img
                              className={`profile_image`}
                              src="https://placeimg.com/640/480/people"
                              onError={addDefaultProfileImage}
                              alt="profile"></img>
                        </div>
                        <span>{senderName}</span>
                     </div>
                  </div>
                  <div>
                     <div className={"message_image"} onClick={props.onMessageClick}>
                        <div className={`border`}></div>
                        <img
                           src={endpoint + content}
                           onError={addDefaultProfileImage}
                           alt="profile"></img>
                        <div className={"message_album"}>
                           <span className={`d-block mb-2 album_name`}>{albumName}</span>
                           <span className={`d-block last_added text-capitalize`}>
                              {senderName} Menambahkan {totalLastAdded} Item
                           </span>
                        </div>
                     </div>
                  </div>
                  <span className={`message_time float-left`}>
                     <Moment format="HH:mm" withTitle>
                        {createdAt}
                     </Moment>
                  </span>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export interface AlbumMessageProps {
   profileImage: string;
   senderName: string;
   content: string;
   albumName: string;
   createdAt: string;
   totalLastAdded: number;
   onMessageClick: () => void;
}

export default AlbumMessage;
