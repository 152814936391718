import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import { RootState } from "../../../../app/store";
import BlogForm from "../components/BlogForm";
import {createBlog, getBlogSingleItem, resetStatus, updateBlog} from "../blog.reducer";
import {
   CreateBlogWithUploadModel,
   SingleBlogModel,
   UpdateBlogWithUploadModel,
} from "../models";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiErrorResponse} from "../../../../index";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";

const BlogDetailPage = () => {
   const MySwal = withReactContent(Swal);
   let history = useHistory();
   const dispatch = useDispatch();
   let { id } = useParams<{ id: string }>();

   const singleDataState = useSelector(
      (state: RootState) => state.blogAdmin.single ?? ({} as SingleBlogModel)
   );
   const status = useSelector((state: RootState) => state.blogAdmin.status);
   const error = useSelector((state: RootState) => state.blogAdmin.error ?? ({} as ApiErrorResponse<any>));
   const userId = useSelector((state: RootState) => state.user.id ?? "");

   const [isEdit, setIsEdit] = useState(false);

   useEffect(() => {
      if (
         singleDataState &&
         Object.keys(singleDataState).length !== 0 &&
         singleDataState.constructor === Object
      ) {
         setIsEdit(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [singleDataState]);

   useEffect(() => {
      if (id !== undefined) {
         dispatch(getBlogSingleItem(id));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id]);

   useEffect(() => {
      if (status !== undefined && status !== ApiResponseStatus.pending) {
         MySwal.fire(
             savedStatusOption(
                 status === ApiResponseStatus.success,
                 error.data?.message
             )
         ).then(() => {
            dispatch(resetStatus());
            if (status === ApiResponseStatus.success) {
               history.push("/master/blog/list");
            }
         });
      }
   }, [status, error]);

   const onSubmit = (
      isPublish: boolean,
      name: string,
      subtitle: string,
      selectedBlogCategory: string,
      metaKeyword: Array<string>,
      content: string,
      thumbnail: string,
      showAtHome: boolean,
      eventIds: string,
      fileFormat: string
   ) => {
      if (isEdit) {
         dispatch(
            updateBlog({
               blogData: {
                  id: id,
                  blogCategory: [selectedBlogCategory],
                  metaKeyword: metaKeyword,
                  title: name,
                  subtitle: subtitle,
                  content: content,
                  thumbnailPath: singleDataState.thumbnailPath,
                  isPublished: isPublish,
                  isAdmin: true,
                  showAtHome: showAtHome,
                  userId: userId,
                  marketingEventList: eventIds,
               },
               fileData: {
                  file_name: "blog." + fileFormat,
                  base64url: (thumbnail as string).split(",")[1],
                  imagepath: "blog",
                  location: "blog",
               },
            } as UpdateBlogWithUploadModel)
         );
      } else {
         dispatch(
            createBlog({
               blogData: {
                  blogCategory: [selectedBlogCategory],
                  metaKeyword: metaKeyword,
                  title: name,
                  subtitle: subtitle,
                  content: content,
                  thumbnailPath: "",
                  isPublished: isPublish,
                  isAdmin: true,
                  showAtHome: showAtHome,
                  userId: userId,
                  marketingEventList: eventIds,
               },
               fileData: {
                  file_name: "blog" + "." + fileFormat,
                  base64url: (thumbnail as string).split(",")[1],
                  imagepath: "blog",
                  location: "blog",
               },
            } as CreateBlogWithUploadModel)
         );
      }
   };

   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <h4 className="card-label">{isEdit ? "Edit" : "Tambah"} - Blog Admin</h4>
               <hr />
               <Row>
                  <Col md={8} xl={8}>
                     <BlogForm onSubmit={onSubmit} data={singleDataState} isEdit={isEdit} />
                  </Col>
               </Row>
            </Col>
         </Row>
      </Container>
   );
};

export default BlogDetailPage;
