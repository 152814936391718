import React, { Fragment } from "react";
import Moment from "react-moment";
import {addDefaultProfileImage} from "../../../../../../../helpers/defaultBrokenImage";
import {getMediaEndpoint} from "../../../../../../../helpers/imageManipulation";
const ImageMessage = ({
   profileImage = "",
   senderName = "",
   content = "",
   createdAt = "",
   ...props
}: ImageMessageProps) => {
   return (
      <Fragment>
         <div className={`message_list message_in`}>
            <div className={`message_list_item message_in`}>
               <div className={`message_box`}>
                  <div>
                     <div className={`d-block mb-2 mitra_profile`}>
                        <div className={`d-inline-block mr-1 image_frame`}>
                           <img
                              className={`profile_image`}
                              src="https://placeimg.com/640/480/people"
                              onError={addDefaultProfileImage}
                              alt="profile"></img>
                        </div>
                        <span>{senderName}</span>
                     </div>
                  </div>
                  <div>
                     <div className={"message_image"}>
                        <img
                           onClick={props.onMessageClick}
                           className={`profile_image`}
                           src={getMediaEndpoint(content)}
                           onError={addDefaultProfileImage}
                           alt="profile"></img>
                     </div>
                  </div>
                  <span className={"message_time"}>
                     <Moment format="HH:mm" withTitle>
                        {createdAt}
                     </Moment>
                  </span>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export interface ImageMessageProps {
   profileImage: string;
   senderName: string;
   content: string;
   createdAt: string;
   onMessageClick: () => void;
}

export default ImageMessage;
