export interface UserModel {
   name: string;
   isAdminVerified: boolean;
   id: string;
   email: string;
   emailConfirmed: boolean;
   username: string;
   submitDate: string;
   registerStatus: number;
   isActive: boolean;
}

export interface RegisterAdminModel {
   name: string;
   email: string;
   password: string;
   confirmPassword: string;
}

export interface UpdateAdminModel {
   userId: string;
   name: string;
   email: string;
   password: string;
}

export interface CustomerModel {
   id: string;
   userId: string;
   fullname: string;
   identityNumber: string;
   phoneNumber: string;
   address: string;
   bio: string;
   website: string;
   bankAccount: string;
   bankName: string;
   bankOnBehalf: string;
   isVerified: boolean;
   type: number;
   customerInfo: string;
   birthDate: string;
   customerPicture: string;
   gender: CustomerGender;
}

export enum CustomerGender {
   Man,
   Woman,
}

export interface ChangeStatusModal {
   userId: string;
   isActive: boolean;
}

export enum UserType {Mitra, Customer}

export interface  UserBalance {
   id: string;
   name: string;
   value: number;
   username: string;
   type: UserType;
}

export interface CurrentBalance {
   totalSaldo: number,
   list: Array<UserBalance>
}