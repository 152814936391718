import axios from "axios";
import { endpoint } from "../../../constants";

export default class ProductApi {
  // Product API

  static getProduct(userId: string) {
    return axios
      .get<any>(endpoint + "/api/Product/GetListByUser?userId=" + userId)
      .then((res) => res.data.data);
  }

  static getProductAttachment(productId: string) {
    return axios
      .post<any>(
        endpoint +
          "/api/ProductAttachment/GetListByProduct?productId=" +
          productId
      )
      .then((res) => res.data.data);
  }

  static getSingleProduct(productId: string) {
    return axios
      .get<any>(endpoint + "/api/Product/GetSingleItem?id=" + productId)
      .then((res) => res.data.data);
  }

  // Package API

  static getPackage(userId: string) {
    return axios
      .get<any>(endpoint + "/api/Package/GetListByUser?userId=" + userId)
      .then((res) => res.data.data);
  }

  static getSinglePackage(packageId: string) {
    return axios
      .get<any>(endpoint + "/api/Package/GetSingleItem?id=" + packageId)
      .then((res) => res.data.data);
  }

  static getPackageAttachment(packageId: string) {
    return axios
      .post<any>(
        endpoint +
          "/api/PackageAttachment/GetListByPackage?packageId=" +
          packageId
      )
      .then((res) => res.data.data);
  }

  static getPackageDetail(packageId: string) {
    return axios
      .get<any>(
        endpoint + "/api/PackageDetail/GetListByPackage?packageId=" + packageId
      )
      .then((res) => res.data.data);
  }

  static getPackageFacility(packageId: string) {
    return axios
      .get<any>(
        endpoint +
          "/api/PackageFacility/GetListByPackage?packageId=" +
          packageId
      )
      .then((res) => res.data.data);
  }

  static getPackageInclusion(packageId: string) {
    return axios
      .get<any>(
        endpoint +
          "/api/PackageInclusion/GetListByPackage?packageId=" +
          packageId
      )
      .then((res) => res.data.data);
  }
}
