import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import { ApiResponseStatus } from "../../@core/models/apiResponseStatus/apiResponseStatus";
import { RequestCancelProjectModel, RequestProjectStatus } from "./models";
import RequestCancelProjectApi from "./requestCancelProject.api";

export interface RequestCancelProjectSlice {
   list?: RequestCancelProjectModel[];
   isLoading?: boolean;
   dateCreated?: string;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
}

export const getListAllRequestCancelProject = createAsyncThunk(
   "requestCancelProjectState/getListAllRequestCancelProject",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await RequestCancelProjectApi.getListAllRequestCancelProject();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const changeRequestCancelProjectStatus = createAsyncThunk(
   "RequestCancelProjectState/changeRequestStatus",
   async (projectRequest: RequestProjectStatus, { rejectWithValue }) => {
      try {
         return await RequestCancelProjectApi.changeRequestProjectStatus(projectRequest);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const requestCancelProjectSlice = createSlice({
   name: "requestCancelProjectState",
   initialState: {} as RequestCancelProjectSlice,
   reducers: {
      setFilter: (state, action) => {
         state.list = action.payload;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
      },
      resetError: (state) => {
         state.error = {} as ApiErrorResponse<any>;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(getListAllRequestCancelProject.fulfilled, (state, { payload }) => {
         // state.list = payload.map((withdraw: WithdrawModel) => {
         //    const _withdraw = withdraw;
         //    withdraw.createdDateFormatted = new Date(withdraw.createdDate).toLocaleDateString(
         //       "en-GB"
         //    );
         //    withdraw.approveDateFormatted =
         //       withdraw.approveDate !== "0001-01-01T00:00:00"
         //          ? new Date(withdraw.approveDate).toLocaleDateString("en-GB")
         //          : "No Approval";
         //    return _withdraw;
         // });
         state.list = payload;
      });
      builder.addCase(getListAllRequestCancelProject.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
      });

      builder.addCase(changeRequestCancelProjectStatus.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(changeRequestCancelProjectStatus.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.dateCreated = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(changeRequestCancelProjectStatus.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
   },
});

export const { setFilter, resetStatus, resetError } = requestCancelProjectSlice.actions;
export default requestCancelProjectSlice.reducer;
