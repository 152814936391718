import React, { useEffect, useState } from "react";
import { Col, Container, FormControl, Row, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import EmptyPortfolioImage from "../../../assets/images/empty_portfolio.svg";
import chatIcon from "../../../assets/images/icons/chat_icon.svg";
import { readMessage } from "../chat.reducer";
import MessageList from "./SubComponents/RoomList/MessageList";
import RoomList from "./SubComponents/RoomList/RoomList";
import {MediaType} from "./SubComponents/Attachment/ChooseAttachmentModal";
import DetailAttachmentModal, {DetailAttachmentType} from "./SubComponents/Attachment/DetailAttachmentModal";
import DetailPhotoAttachment, {MediaPathModel} from "./SubComponents/Attachment/DetailPhotoAttachment";
import PictureAttachmentModal from "./SubComponents/Attachment/AddAttachment/PictureAttachmentModal";
import VideoAttachmentModal from "./SubComponents/Attachment/AddAttachment/VideoAttachmentModal";
import FileAttachmentModal from "./SubComponents/Attachment/AddAttachment/FileAttachmentModal";
import AlbumAttachmentModal from "./SubComponents/Attachment/AddAttachment/AlbumAttachmentModal";

const RoomSection = ({ isReadOnly = false }: RoomSectionProps) => {
   const dispatch = useDispatch();
   // const { getRooms, rooms } = useChat();
   const [roomId, setRoomId] = useState<string>("");
   const [albumName, setAlbumName] = useState<string>('');
   const [isExistedAlbum, setIsExistedAlbum] = useState<boolean>(false);
   const [selectedAttachment, setSelectedAttachment] = useState<MediaType>(
       MediaType.None
   );
   const [showDetailAttachment, setShowDetailAttachment] =
       useState<boolean>(false);
   const [otherPath, setOtherPath] = useState<MediaPathModel[]>([]);
   const [showPhotoDetail, setShowPhotoDetail] = useState<boolean>(false);
   const [detailAttachmentPreview, setDetailAttachmentPreview] =
       useState<DetailAttachmentType>(DetailAttachmentType.Picture);
   const [detailAttachmentPath, setDetailAttachmentPath] = useState<string>('');
   const [isMultipleImage, setIsMultipleImage] = useState<boolean>(false);

   const rooms = useSelector((state: RootState) => state.chat.rooms ?? []);
   const userId = useSelector((state: RootState) => state.user.id ?? "");
   const username = useSelector((state: RootState) => state.user.username ?? "");


   const onRoomSelected = (uid: string) => {
      setRoomId(uid);
      dispatch(readMessage(uid, userId));
   };

   const resetAddAlbum = () => {
      setAlbumName('');
      setIsExistedAlbum(false);
   };

   const onShowAddAttachment = (media: MediaType) => {
      setSelectedAttachment(media);
   };

   const toggleDetailAttachmentModal = () => {
      setShowDetailAttachment((prevState) => !prevState);
   };

   const toggleShowDetail = () => {
      setShowPhotoDetail((prevState) => !prevState);
   };

   const toggleAddAttachmentModal = () => {
      setAlbumName('');
      setIsExistedAlbum(false);
      setSelectedAttachment(MediaType.None);
   };

   const onMessageClicked = (
       showDetail: boolean,
       path: string,
       type: DetailAttachmentType
   ) => {
      setDetailAttachmentPreview(type);
      setDetailAttachmentPath(path);
      if (type === DetailAttachmentType.Picture) {
         setIsMultipleImage(false);
         toggleShowDetail();
      } else if (type === DetailAttachmentType.Video) {
         setShowDetailAttachment(showDetail);
      }
   };

   const onMessageAlbumClicked = (albumName: string) => {
      setAlbumName(albumName);
      setSelectedAttachment(MediaType.Album);
      setIsExistedAlbum(true);
   };

   const onOtherMessageClicked = (
       path: MediaPathModel[]
   ) => {
      setIsMultipleImage(true);
      setOtherPath(path);
      toggleShowDetail();
   };

   return (
      <Container>
         <Row>
            <Col md={12} className={`mt-2 mb-5 chat_container}`}>
               <Row className="chat_room_container">
                  <Col md={4} className={`pt-3 pb-3 chat_room}`}>
                     <div className="mb-4">
                        <div className="d-block">
                           <div className="d-inline-block">
                              <img
                                 className="d-inline-block"
                                 src={chatIcon}
                                 alt="chat"
                                 width="30"
                              />
                           </div>
                           <span className={`ml-2 chat_label`}>Message</span>
                        </div>
                     </div>

                     <div>
                        <FormControl
                           type="text"
                           placeholder="Search"
                           // onFocus={handleInputFocus}
                           // onBlur={handleInputBlur}
                           // onChange={onSearchChange}
                           // onKeyPress={onKeyUp}
                           className="mr-sm-2 search-input"
                        />
                     </div>

                     <div className="mt-4 mb-3">
                        {rooms.map((room) => (
                           <RoomList singleRoom={room} selectedRoom={onRoomSelected} />
                        ))}
                     </div>
                  </Col>
                  <Col md={8} className={`chat_room_content`}>
                     {roomId !== "" ? (
                        <MessageList userId={userId} name={username} roomId={roomId} isReadOnly={isReadOnly}  resetAddAlbum={() => resetAddAlbum()}
                                     showAddAttachment={onShowAddAttachment} onMessageClicked={onMessageClicked}
                                     onMessageAlbumClicked={
                                        onMessageAlbumClicked
                                     }
                                     onOtherMessageClicked={onOtherMessageClicked}
                        />
                     ) : (
                        <Row className="mt-5">
                           <Col md={12} className="text-center">
                              <Image
                                 className={`justify-content-center`}
                                 src={EmptyPortfolioImage}
                              />
                           </Col>
                           <Col md={12} className="text-center mt-4 mb-4">
                              <h6>Pilih Chat terlebih dahulu</h6>
                           </Col>
                        </Row>
                     )}
                  </Col>
               </Row>
            </Col>
         </Row>
         <DetailAttachmentModal
             showModal={showDetailAttachment}
             onModalHide={toggleDetailAttachmentModal}
             detailAttachmentType={detailAttachmentPreview}
             mediaPath={detailAttachmentPath}
         />
         <DetailPhotoAttachment
             showDetail={showPhotoDetail}
             toggleShowDetail={toggleShowDetail}
             mediaPath={detailAttachmentPath}
             multiplePath={otherPath}
             isMultipleImage={isMultipleImage}
         />
         <PictureAttachmentModal
             showModal={selectedAttachment === MediaType.Picture}
             onModalHide={toggleAddAttachmentModal}
             roomId={roomId}
             userId={userId}
             userName={username}
         />
         <VideoAttachmentModal
             showModal={selectedAttachment === MediaType.Video}
             onModalHide={toggleAddAttachmentModal}
             roomId={roomId}
             userId={userId}
             userName={username}
         />
         <FileAttachmentModal
             showModal={selectedAttachment === MediaType.File}
             onModalHide={toggleAddAttachmentModal}
             roomId={roomId}
             userId={userId}
             userName={username}
         />
         <AlbumAttachmentModal
             showModal={selectedAttachment === MediaType.Album}
             onModalHide={toggleAddAttachmentModal}
             roomId={roomId}
             userId={userId}
             userName={username}
             defaultAlbumName={albumName}
             isExistedAlbum={isExistedAlbum}
             onMediaClicked={onMessageClicked}
         />
      </Container>
   );
};

export interface RoomSectionProps {
    isReadOnly?: boolean;
}

export default RoomSection;
