import {ApiErrorResponse} from "../../index";
import {ApiResponseStatus} from "../../@core/models/apiResponseStatus/apiResponseStatus";
import {BalanceHistoryModel, GetSaldoHistoryModel} from "./models";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import AdminBalanceApi from "./adminBalance.api";


export interface AdminBalanceSlice {
    balanceTotal?: number;
    balanceHistory?: BalanceHistoryModel[];
    isLoading?: boolean;
    dateCreated?: string;
    error?: ApiErrorResponse<any>;
    status?: ApiResponseStatus;
}

export const getTotalSaldo = createAsyncThunk(
    "adminBalanceState/getTotalSaldo",
    async (_: string | undefined = undefined, { rejectWithValue }) => {
        try {
            return await AdminBalanceApi.getTotalSaldo();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getListSaldoHistory = createAsyncThunk(
    "adminBalanceState/getListSaldoHistory",
    async (req: GetSaldoHistoryModel, { rejectWithValue }) => {
        try {
            return await AdminBalanceApi.getListSaldoHistory(req);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

const adminBalanceSlice = createSlice({
    name: "adminBalanceState",
    initialState: {} as AdminBalanceSlice,
    reducers: {
        resetStatus: (state) => {
            state.status = ApiResponseStatus.pending;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTotalSaldo.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getTotalSaldo.fulfilled, (state, { payload }) => {
            state.balanceTotal = payload;
            state.isLoading = false;
        });
        builder.addCase(getTotalSaldo.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });

        builder.addCase(getListSaldoHistory.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getListSaldoHistory.fulfilled, (state, { payload }) => {
            state.balanceHistory = payload;
            state.isLoading = false;
        });
        builder.addCase(getListSaldoHistory.rejected, (state, { payload }) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });

    }
});

export const { resetStatus } = adminBalanceSlice.actions;
export default adminBalanceSlice.reducer;