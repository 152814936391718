import styled from "styled-components";
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
  NavInfoPane,
} from "@trendmicro/react-sidenav";

// SideNav
const StyledSideNav = styled(SideNav)`
  background-color: #160e25;
  border-right: 1px solid #ddd;
  z-index: 1000;
`;
StyledSideNav.defaultProps = SideNav.defaultProps;

// Toggle
const StyledToggle = styled(Toggle)`
  background-color: #db3d44;
`;
StyledToggle.defaultProps = Toggle.defaultProps;

// Nav
const StyledNav = styled(Nav)`
  background-color: #fff;
  &&[class*="expanded--"] {
    [class*="sidenav-subnav--"] {
      > [class*="sidenav-subnavitem--"],
      > [class*="sidenav-subnavitem--"]:hover {
        > [class*="navitem--"] {
          color: #222;
        }
      }
      > [class*="sidenav-subnavitem--"]:hover {
        > [class*="navitem--"] {
          background-color: #eee;
        }
      }
      > [class*="sidenav-subnavitem--"][class*="selected--"] {
        > [class*="navitem--"] {
          color: #db3d44;
        }
        > [class*="navitem--"]::before {
          border-left: 2px solid #db3d44;
        }
      }
    }
  }
  && > [class*="sidenav-navitem--"] {
    > [class*="navitem--"] {
      background-color: inherit;
      color: #fff;
    }
  }
  && > [class*="sidenav-navitem--"]:hover {
    > [class*="navitem--"] {
      background-color: #eee;
    }
  }
  && > [class*="sidenav-navitem--"],
  && > [class*="sidenav-navitem--"]:hover {
    > [class*="navitem--"] {
      [class*="navicon--"] {
        &,
        > * {
          color: #666;
        }
      }
      [class*="sidenav-nav-text--"] {
        &,
        > * {
          color: #222;
        }
      }
    }
  }
  && > [class*="sidenav-navitem--"][class*="highlighted--"]
    > [class*="navitem--"] {
      [class*="navicon--"],
      [class*="navtext--"] {
        &,
        > * {
          color: #ffffff;
        }
      }
      [class*="sidenav-nav-text--"] {
        font-weight: 700;
      }
    }`;
StyledNav.defaultProps = Nav.defaultProps;

// NavItem
const StyledNavItem = styled(NavItem)`
  &&&:hover {
    [class*="navtext--"] {
      color: #EB9749;
      background-color: #160e25;
    }
  }
  &&&[class*="highlighted--"] {
    border-left: 2px solid #EB9749;
  }
  &&&[class*="highlighted--"] > [class*="navitem--"] {
    [class*="navicon--"],
    [class*="navtext--"] {
      &,
      > * {
        color: #EB9749;
      }
    }
  &&& > [class*="navitem--"] {
    [class*="navicon--"],
    [class*="navtext--"] {
      &,
      > * {
        color: #ffffff !important;
      }
    }
  &&&[class*="highlighted--"] > [class*="navitem--"] {
    [class*="navicon--"],
    [class*="navtext--"] {
      &,
      > * {
        color: #ffffff;
      }
    }
  
`;
StyledNavItem.defaultProps = NavItem.defaultProps;

// NavIcon
const StyledNavIcon = styled(NavIcon)`
  color: #222;
`;
StyledNavIcon.defaultProps = NavIcon.defaultProps;

// NavText
const StyledNavText = styled(NavText)`
  color: #222;
`;
StyledNavText.defaultProps = NavText.defaultProps;

export {
  StyledToggle as Toggle,
  StyledNav as Nav,
  StyledNavItem as NavItem,
  StyledNavIcon as NavIcon,
  StyledNavText as NavText,
};
export default StyledSideNav;
