import axios from "axios";
import {
  SocialMediaTypeModel,
  CreateSocialMediaTypeSuccessResponse,
} from "./models";
import { endpoint } from "../../../constants";

export default class SocialMediaTypeApi {
  static createSocialMediaType(createSocialMediaType: SocialMediaTypeModel) {
    return axios
      .post<any>(
        endpoint + "/api/SocialMediaType/Create",
        createSocialMediaType
      )
      .then((res) => res.data.data);
  }

  static getSocialMediaTypeList() {
    return axios
      .get<any>(endpoint + "/api/SocialMediaType/GetList")
      .then((res) => res.data.data);
  }

  static getSingleItem(socialMediaTypeId: string) {
    return axios
      .get<any>(
        endpoint + "/api/SocialMediaType/GetSingleItem?id=" + socialMediaTypeId
      )
      .then((res) => res.data.data);
  }

  static updateSocialMediaType(SocialMediaType: SocialMediaTypeModel) {
    return axios
      .post<any>(endpoint + "/api/SocialMediaType/Update", SocialMediaType)
      .then((res) => res.data.data);
  }

  static deleteSocialMediaType(socialMediaType: SocialMediaTypeModel) {
    return axios
      .post<any>(endpoint + "/api/SocialMediaType/Delete", socialMediaType)
      .then((res) => [{ response: res.data.data }, { id: socialMediaType.id }]);
  }
}
