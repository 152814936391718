import React from "react";
import {SweetAlertIcon} from "sweetalert2";
import TrashImage from "../assets/images/alert_trash.png";
import CheckmarkImage from "../assets/images/alert_checkmark.png";
import ErrorImage from "../assets/images/error.png";

export const savedSuccessText = 'Berhasil menyimpan perubahan!';
export const savedErrorText = 'Gagal menyimpan perubahan: ';
export const deletedSuccessTitle = 'Dihapus!';
export const deletedErrorTitle = 'Perubahan tidak disimpan!';
export const deletedSuccessText = 'Data berhasil dihapus.';
export const deletedErrorText = 'Terjadi kesalahan ketika menghapus: ';

export const savedStatusOption = (status: boolean, error: string) => {
    return {
        html: (
            <span>
                {status
                    ? savedSuccessText
                    : savedErrorText + error}
            </span>
        ),
        icon: status
            ? ('success' as SweetAlertIcon)
            : ('error' as SweetAlertIcon),
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn button-primary px-5 mx-1',
            cancelButton: 'btn btn-outline-danger px-5 mx-1',
            title: 'h6',
        },
    };
};

export const deletedStatusOption = (status: boolean, error: string) => {
    return {
        title: status ? deletedSuccessTitle : deletedErrorTitle,
        text: status ? deletedSuccessText : deletedErrorText + error,
        imageUrl: status
            ? CheckmarkImage
            : ErrorImage,
        imageWidth: 200,
        imageAlt: 'Trash',
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn button-primary px-5 mx-1',
            cancelButton: 'btn btn-outline-danger px-5 mx-1',
        },
    };
};

export const deletedWarnOption = {
    title: 'Apakah kamu yakin?',
    text: 'Data yang sudah dihapus tidak dapat dikembalikan!',
    imageUrl: TrashImage,
    imageWidth: 300,
    imageAlt: 'Trash',
    showCancelButton: true,
    confirmButtonText: 'Ya, hapus data!',
    cancelButtonText: 'Batalkan',
};

export const customDeletedWarnOption = (title: string) => {
    return {
        title: title,
        imageUrl: TrashImage,
        imageWidth: 300,
        imageAlt: 'Trash',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn button-primary px-5 mx-1',
            cancelButton: 'btn btn-outline-danger px-5 mx-1',
            title: 'h6',
        },
    }
};

export const customStatusOption = ( status: boolean, error: string, successText: string, failedText: string) => {
    return {
        html: (
            <span>
                {status
                    ? successText
                    : failedText + error}
            </span>
        ),
        imageUrl: status
            ? CheckmarkImage
            : ErrorImage,
        imageWidth: 300,
        imageAlt: 'Trash',
        customClass: {
            confirmButton: 'btn button-primary px-5 mx-1',
            cancelButton: 'btn btn-outline-danger px-5 mx-1',
            title: 'h6',
        },
    };
};

export const customSavedOption = ( status: boolean, error: string, successText: string, failedText: string) => {
    return {
        html: (
            <span>
                {status
                    ? successText
                    : failedText + error}
            </span>
        ),
        icon: status
            ? ('success' as SweetAlertIcon)
            : ('error' as SweetAlertIcon),
        customClass: {
            confirmButton: 'btn button-primary px-5 mx-1',
            cancelButton: 'btn btn-outline-danger px-5 mx-1',
            title: 'h6',
        },
    };
};