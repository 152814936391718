import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import SocialMediaTypeForm from "../components/SocialMediaTypeForm";

import {
  createSocialMediaType,
  getSocialMediaTypeSingleItem,
  updateSocialMediaType,
  resetStatus,
} from "../socialMediaType.reducer";
import { SocialMediaTypeModel } from "../models";
import {useHistory, useParams} from "react-router-dom";
import { RootState } from "../../../../app/store";
import {ApiErrorResponse} from "../../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";

const SocialMediaTypeDetailPage = () => {
  const MySwal = withReactContent(Swal);
  let history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  const [isEdit, setIsEdit] = useState(false);

  const singleDataState = useSelector(
    (state: RootState) =>
      state.socialMediaType.single ?? ({} as SocialMediaTypeModel)
  );
  const status = useSelector((state: RootState) => state.socialMediaType.status);
  const error = useSelector((state: RootState) => state.socialMediaType.error ?? ({} as ApiErrorResponse<any>));

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getSocialMediaTypeSingleItem(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      singleDataState &&
      Object.keys(singleDataState).length !== 0 &&
      singleDataState.constructor === Object
    ) {
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDataState]);

  useEffect(() => {
    if (status !== undefined && status !== ApiResponseStatus.pending) {
      MySwal.fire(
          savedStatusOption(
              status === ApiResponseStatus.success,
              error.data?.message
          )
      ).then(() => {
        dispatch(resetStatus());
        if (status === ApiResponseStatus.success) {
          history.push("/master/socialmedia/list");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error]);

  const onSubmit = (name: string, description: string, link: string) => {
    if (isEdit) {
      dispatch(
        updateSocialMediaType({
          id: id,
          name,
          description,
          link,
        } as SocialMediaTypeModel)
      );
    } else {
      dispatch(
        createSocialMediaType({
          name,
          description,
          link,
        } as SocialMediaTypeModel)
      );
    }
  };

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <h4 className="card-label">
            {isEdit ? "Edit" : "Tambah"} - Master Sosial Media
          </h4>
          <hr />
          <Row>
            <Col md={8} xl={5}>
              <SocialMediaTypeForm
                onSubmit={onSubmit}
                data={singleDataState}
                isEdit={isEdit}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SocialMediaTypeDetailPage;
