import React, { Fragment } from "react";
import Moment from "react-moment";
import {addDefaultProfileImage} from "../../../../../../../helpers/defaultBrokenImage";
import {getMediaEndpoint} from "../../../../../../../helpers/imageManipulation";

const ImageMessage = ({ content = "", createdAt = "", ...props }: ImageMessageProps) => {
   return (
      <Fragment>
         <div className={`message_list message_out`}>
            <div className={`message_list_item message_out`}>
               <div className={`message_box`}>
                  <div className={"message_image"}>
                     <img
                        onClick={props.onMessageClick}
                        className={`profile_image}`}
                        src={getMediaEndpoint(content)}
                        onError={addDefaultProfileImage}
                        alt="profile"></img>
                  </div>
                  <span className={`message_time float-right`}>
                     <Moment format="HH:mm" withTitle>
                        {createdAt}
                     </Moment>
                  </span>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export interface ImageMessageProps {
   content: string;
   createdAt: string;
   onMessageClick: () => void;
}

export default ImageMessage;
