import axios from "axios";
import { endpoint } from "../../constants";
import { RequestProjectStatus } from "./models";

export default class RequestCancelProjectApi {
   static getListAllRequestCancelProject() {
      return axios
         .get<any>(endpoint + "/api/RequestCancelProject/GetList")
         .then((res) => res.data.data);
   }

   static changeRequestProjectStatus(projectRequest: RequestProjectStatus) {
      return axios
         .post<any>(endpoint + `/api/RequestCancelProject/ChangeStatus`, projectRequest)
         .then((res) => res.data.data);
   }
}
