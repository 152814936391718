import axios from "axios";
import { endpoint } from "../../../constants";
import {ChangeSuspendedStatus} from "./models";

export default class DataCustomerApi {
  static getSingleCustomer(userId: string) {
    return axios
      .get<any>(endpoint + `/api/Customer/GetSingleItem?userId=${userId}`)
      .then((res) => res.data);
  }
  static changeSuspendedStatus(data: ChangeSuspendedStatus) {
    return axios.post<any>(endpoint + `/api/Customer/ChangeSuspendedStatus`, data).then((res) => res.data);
  }
}
