import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const ChatDetailPage = () => {
   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <h4 className="card-label">Detail Chat</h4>
               {/* <ChatSection /> */}
            </Col>
         </Row>
      </Container>
   );
};

export default ChatDetailPage;
