import axios from "axios";
import { LoginCredential, LoginSuccessResponse } from "./models";
import { endpoint } from "../../constants";

export default class UserApi {
   static login(userLogin: LoginCredential) {
      return axios.post<any>(endpoint + "/api/User/LoginAdmin", userLogin).then((res) => res.data.data);
   }

   static resetPassword(email: string) {
      return axios
         .post<any>(endpoint + `/api/User/SendResetPasswordEmail?email=${email}`)
         .then((res) => res);
   }
}
