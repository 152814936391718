import {OptionModel} from "../../@core/models/types";

export interface AdminEventModel {
    id: string;
    name: string;
    availableDate: string;
    expiredDate: string;
    freqTotal: number;
    uniqueTotal: number;
    download: number;
    isPublished: boolean;
}

export interface MitraStatisticModel {
    id: string;
    username: string;
    upload: number;
    download: number;
    views: number;
    isPublished: boolean;
}

export enum EventStaticEnum {
    Date, User
}

export interface EventStatisticByDateModel {
    date: string;
    totalView: number;
    totalVisit: number;
    totalDownload: number;
}

export interface EventStatisticByUserModel {
    userId: string;
    username: string;
    totalView: number;
    totalVisit: number;
    totalDownload: number;
}

export interface EventSummaryModel {
    totalView: number;
    totalDownload: number;
    totalUpload: number;
    totalVisit: number;
}

export interface InputEvent {
    name: string;
    description: string;
    openDate: string;
    closeDate: string;
    mitraUsername: OptionModel[];
    expertiseList: OptionModel[];
}

export interface ErrorInputEvent {
    name: string;
    openDate: string;
    closeDate: string;
    mitraUsername: string;
    expertiseList: string;
}

export interface FileEventModel {
    id: string;
    fileName: string;
    path: string;
    userId: string;
    name: string;
    createDate: string;
    marketingEventId: string;
    type: number;
    totalDownload: number;
    totalView: number;
}

export interface DetailFileEventModel {
    id: string;
    src: string;
    alt: string;
}

export enum MarketingFileLogSortByEnum
{
    Waktu,
    Nama,
    View,
    Download
}
export enum MarketingFileLogOrderByEnum
{
    Asc,
    Desc
}

export interface GetListUploadedFileReq {
    marketingEventId: string;
    userId?: string;
    sortBy?: MarketingFileLogSortByEnum;
    orderBy?: MarketingFileLogOrderByEnum;
}

export enum MarketingEventImageTypeEnum {
    Banner,
    Watermark
}

export interface MarketingEventImageModel {
    imageType: MarketingEventImageTypeEnum;
    file: File;
}

export interface CreateMarketingEventFileModel {
    id?: string;
    name: string;
    description: string;
    openDate: string;
    closeDate: string;
    mitraList: string;
    expertiseList: string;
    imageUrl: string;
    watermarkUrl: string;
    local_files: Array<MarketingEventImageModel>;
    isEdit: boolean;
}

export interface CreateMarketingEventModel {
    id?: string;
    name: string;
    description: string;
    openDate: string;
    closeDate: string;
    mitraList: string;
    expertiseList: string;
    imageUrl: string;
    watermarkUrl: string;
}

export interface MarketingEventModel {
    id: string;
    name: string;
    description: string;
    openDate: string;
    closeDate: string;
    mitraList: string;
    mitraListName: string;
    expertiseList: string;
    expertiseListName: string;
    imageUrl: string;
    watermarkUrl: string;
    totalAksesFreq: number;
    totalAksesUnique: number;
    totalDownload: number;
    totalView: number;
    slug: string;
    watermarkPreviewUrl: string;
}

export interface GetMarketingEventReq {
    skip: number;
    take: number;
    name?: string;
}

export interface MitraStatisticModel {
    userId: string;
    username: string;
    downloadedPhotoCount: number;
    uploadedPhotoCount: number;
    viewedPhotoCount: number;
}

export interface GetMitraStatisticReq {
    marketingEventId: string;
    username?: string;
}

export interface PublishEventReq {
    id: string;
    isPublish: boolean;
}