import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import ProfessionalTypeTable from "../components/ProfessionalTypeTable";
import {
  getProfessionalTypeList,
  resetSingle,
  setFilter,
} from "../professionalType.reducer";
import { RootState } from "../../../../../app/store";

const ProfessionalTypePage = () => {
  const dispatch = useDispatch();
  const statusState = useSelector(
    (state: RootState) => state.professionalType.name
  );
  const listDataState = useSelector(
    (state: RootState) => state.professionalType.list
  );

  const onFilterSubmit = (name: string) => {
    const dataFilter = listDataState ?? [];
    const filteredData = dataFilter.filter((find: { name: string }) =>
      find.name.includes(name)
    );
    dispatch(setFilter(filteredData));
  };

  useEffect(() => {
    dispatch(getProfessionalTypeList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusState]);

  useEffect(() => {
    dispatch(resetSingle());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <h4 className="card-label">Master Tipe Profesional</h4>
          <ProfessionalTypeTable />
        </Col>
      </Row>
    </Container>
  );
};

export default ProfessionalTypePage;
