import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import ProfessionalTypeForm from "../components/ProfessionalTypeForm";
import {
   createProfessionalType,
   getProfessionalTypeSingleItem,
   updateProfessionalType,
   resetStatus,
} from "../professionalType.reducer";
import { CreateProfessionalTypeModel, ProfessionalTypeModel } from "../models";
import {useHistory, useParams} from "react-router-dom";
import { RootState } from "../../../../../app/store";
import { ImageUpload } from "../../../../../@core/models/media/ImageUpload";
import {ApiErrorResponse} from "../../../../../index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiResponseStatus} from "../../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../../helpers/alertContent";

const ProfessionalTypeDetailPage = () => {
   const MySwal = withReactContent(Swal);
   let history = useHistory();
   const dispatch = useDispatch();
   let { id } = useParams<{ id: string }>();

   const [isEdit, setIsEdit] = useState(false);

   const singleDataState = useSelector(
      (state: RootState) => state.professionalType.single ?? ({} as ProfessionalTypeModel)
   );
    const status = useSelector((state: RootState) => state.professionalType.status);
    const error = useSelector((state: RootState) => state.professionalType.error ?? ({} as ApiErrorResponse<any>));

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getProfessionalTypeSingleItem(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (
            singleDataState &&
            Object.keys(singleDataState).length !== 0 &&
            singleDataState.constructor === Object
        ) {
            setIsEdit(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleDataState]);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === ApiResponseStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(resetStatus());
                if (status === ApiResponseStatus.success) {
                    history.push("/mitra/professional/type/list");
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error]);

   const onSubmit = (
      name: string,
      description: string,
      image: ImageUpload,
      iconURL: string,
      canUploadFile: boolean
   ) => {
      if (isEdit) {
         dispatch(
            updateProfessionalType({
               id: id,
               name,
               description,
               image,
               iconURL: iconURL,
               canUploadFile,
            } as CreateProfessionalTypeModel)
         );
      } else {
         dispatch(
            createProfessionalType({
               name,
               description,
               image,
               iconURL: "",
               canUploadFile,
            } as CreateProfessionalTypeModel)
         );
      }
   };

   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <h4 className="card-label">{isEdit ? "Edit" : "Tambah"} - Tipe Profesional</h4>
               <hr />
               <Row>
                  <Col md={8} xl={5}>
                     <ProfessionalTypeForm
                        onSubmit={onSubmit}
                        data={singleDataState}
                        isEdit={isEdit}
                     />
                  </Col>
               </Row>
            </Col>
         </Row>
      </Container>
   );
};

export default ProfessionalTypeDetailPage;
