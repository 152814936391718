import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import UserBalanceTable from "../components/UserBalanceTable";
import { RootState } from "../../../app/store";
import { getList } from "../userBalance.reducer";
import NumberFormat from "react-number-format";
import {CurrentBalance} from "../models";

const UserBalancePage = () => {
  const dispatch = useDispatch();
  const balanceState = useSelector(
    (state: RootState) => state.userBalance.list ?? {} as CurrentBalance
  );

  useEffect(() => {
    dispatch(getList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <h4 className="card-label">Saldo Pengguna</h4>
          <Container className={`create_project_container px-3 pt-3`}>
            <Row>
              <Col md={12}>
                <div className="d-flex">
                  <img
                      src="https://aramata.id/assets/icons/balance_icon.svg"
                      alt="project_calendar_icon"
                      className="d-inline-block"
                      width="48"
                  />
                  <div className="ml-3">
                    <span className={"active_balance_label"}>Total Saldo</span>
                    <h1 className={"active_balance"}>
                      <NumberFormat
                          value={balanceState.totalSaldo}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={"Rp "}
                      />
                    </h1>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <UserBalanceTable />
        </Col>
      </Row>
    </Container>
  );
};

export default UserBalancePage;
