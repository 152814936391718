import JSZip from "jszip";
import axios from "axios";
import { saveAs } from "file-saver";

export const fileToZip = (zipFilename: string, urls: string[]) => {
    let zip = new JSZip();
    let count = 0;
    const cors = "https://secure-cors-proxy.herokuapp.com/";
    // let progressStatus: DownloadStatusModel[] = [];
    // setIsDownloadLoading(true);
    // setDownloadProgress(0);
    urls.forEach(function (url, index) {
        // progressStatus.push({
        //     filename: url,
        //     percentage: 0,
        // });
        axios({
            url: url,
            responseType: "blob",
            onDownloadProgress(progressEvent) {
                let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                // progressStatus.splice(index, 1, {
                //     filename: url,
                //     percentage: progress,
                // });
                // const _progress = progressStatus.reduce((acc, item) => acc + item.percentage, 0);
                // let totalProgress: number = _progress / urls.length;
                // setDownloadProgress(parseFloat(totalProgress.toFixed(2)));
            },
        })
            .then(async (data) => {
                var filename = url.substring(url.lastIndexOf("/") + 1, url.length);
                zip.file(filename, data.data, { binary: false });
                count++;
                if (count == urls.length) {
                    var zipFile = await zip.generateAsync({ type: "blob" });
                    saveAs(zipFile, zipFilename);
                    // setIsDownloadLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    });
};
