import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../..";
import { ApiResponseStatus } from "../../@core/models/apiResponseStatus/apiResponseStatus";
import { ChangeWithdrawStatus, WithdrawModel } from "./models";
import WithdrawApi from "./withdraw.api";

export interface WithdrawSlice {
   list?: WithdrawModel[];
   isLoading?: boolean;
   dateCreated?: string;
   error?: ApiErrorResponse<any>;
   status?: ApiResponseStatus;
}

export const getListAllWithdrawSaldo = createAsyncThunk(
   "withdrawState/getListAllWithdrawSaldo",
   async (_: string | undefined = undefined, { rejectWithValue }) => {
      try {
         return await WithdrawApi.getListAllWithdrawSaldo();
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

export const changeWithdrawSaldoStatus = createAsyncThunk(
   "requestAdminState/requestAdminSingleItem",
   async (changeWithdraw: ChangeWithdrawStatus, { rejectWithValue }) => {
      try {
         return await WithdrawApi.changeWithdrawSaldoStatus(changeWithdraw);
      } catch (e) {
         return rejectWithValue(e as ApiErrorResponse<any>);
      }
   }
);

const withdrawSlice = createSlice({
   name: "withdrawState",
   initialState: {} as WithdrawSlice,
   reducers: {
      setFilter: (state, action) => {
         state.list = action.payload;
      },
      resetStatus: (state) => {
         state.status = ApiResponseStatus.pending;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(getListAllWithdrawSaldo.pending, (state) => {
         state.isLoading = true;
      });
      builder.addCase(getListAllWithdrawSaldo.fulfilled, (state, { payload }) => {
         state.list = payload.map((withdraw: WithdrawModel) => {
            const _withdraw = withdraw;
            withdraw.createdDateFormatted = new Date(withdraw.createdDate).toLocaleDateString(
               "en-GB"
            );
            withdraw.approveDateFormatted =
               withdraw.approveDate !== "0001-01-01T00:00:00"
                  ? new Date(withdraw.approveDate).toLocaleDateString("en-GB")
                  : "No Approval";
            return _withdraw;
         });
         state.isLoading = false;
      });
      builder.addCase(getListAllWithdrawSaldo.rejected, (state, { payload }) => {
         state.error = payload as ApiErrorResponse<any>;
         state.isLoading = false;
      });

      builder.addCase(changeWithdrawSaldoStatus.pending, (state) => {
         state.isLoading = true;
         state.status = ApiResponseStatus.pending;
      });
      builder.addCase(changeWithdrawSaldoStatus.fulfilled, (state, { payload }) => {
         state.isLoading = false;
         state.dateCreated = new Date().toISOString();
         state.status = ApiResponseStatus.success;
      });
      builder.addCase(changeWithdrawSaldoStatus.rejected, (state, { payload }) => {
         state.isLoading = false;
         state.error = payload as ApiErrorResponse<any>;
         state.status = ApiResponseStatus.failed;
      });
   },
});

export const { setFilter, resetStatus } = withdrawSlice.actions;
export default withdrawSlice.reducer;
