import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import ExpertiseRequestTable from "../components/ExpertiseRequestTable";
import { getRequestExpertiseList } from "../expertiseRequest.reducer";

const ExpertiseRequestPage = () => {
  const dispatch = useDispatch();
  const dateStatus = useSelector(
    (state: RootState) => state.requestExpertise.date
  );

  useEffect(() => {
    dispatch(getRequestExpertiseList());
  }, [dateStatus]);

  return (
    <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <h4 className="card-label">Permintaan Expertise</h4>
          <ExpertiseRequestTable />
        </Col>
      </Row>
    </Container>
  );
};

export default ExpertiseRequestPage;
