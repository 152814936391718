import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import DataAdminForm from "../components/DataAdminForm";
import {useHistory, useParams} from "react-router-dom";
import { RootState } from "../../../app/store";
import { UserModel } from "../models";
import { registerAdmin, updateAdmin } from "../dataAdmin.reducer";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiErrorResponse} from "../../../index";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../helpers/alertContent";
import {resetStatus} from "../../master/discount/discount.reducer";

const DataCustomerDetailPage = () => {
    const MySwal = withReactContent(Swal);
    let history = useHistory();
   const dispatch = useDispatch();
   let { id } = useParams<{ id: string }>();

    const status = useSelector((state: RootState) => state.dataAdmin.status);
    const error = useSelector((state: RootState) => state.dataAdmin.error ?? ({} as ApiErrorResponse<any>));

   const [isEdit, setIsEdit] = useState(false);

   let singleDataState = {} as UserModel;

   useEffect(() => {
      if (id !== undefined) {
         setIsEdit(true);
      }
   }, [id]);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === ApiResponseStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(resetStatus());
                if (status === ApiResponseStatus.success) {
                    history.push("/admin/account/list");
                }
            });
        }
    }, [status, error]);

    const onSubmit = (name: string, email: string, password: string, confirmPassword: string) => {
      if (isEdit) {
         dispatch(
            updateAdmin({
               userId: id,
               name,
               email,
               password,
            })
         );
      } else {
         dispatch(
            registerAdmin({
               name,
               email,
               password,
               confirmPassword,
            })
         );
      }
   };

   return (
      <Container className="content-body" fluid>
         <Row>
            <Col md={12}>
               <h4 className="card-label">Detail Data Admin</h4>
               <hr />
               <Row>
                  <Col md={8} xl={5}>
                     <DataAdminForm onSubmit={onSubmit} data={singleDataState} isEdit={isEdit} />
                  </Col>
               </Row>
            </Col>
         </Row>
      </Container>
   );
};

export default DataCustomerDetailPage;
