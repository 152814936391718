import React, { useState, ChangeEvent, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { TagExpertiseTypeEnum, TagExpertiseTypeModel } from "../models";
import { useHistory } from "react-router-dom";

import { convertBase64 } from "../../../../components/Library/Base64";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { checkObjectIsNotEmpty } from "../../../../helpers/checkEmptyObject";
import { deleteExpertiseType } from "../tagExpertise.reducer";
import {getMediaEndpoint} from "../../../../helpers/imageManipulation";

const TagExpertiseTypeForm = ({
   isEdit = false,
   data = {} as TagExpertiseTypeModel,
   ...props
}: TagExpertiseTypeFormProps) => {
   let history = useHistory();
   const dispatch = useDispatch();

   const [name, setName] = useState("");
   const [description, setDescription] = useState("");
   const [type, setType] = useState("none");
   const [parent, setParent] = useState("none");
   const [validated, setValidated] = useState(false);
   const [iconFile, setIconFile] = useState("");
   const [fileFormat, setFileFormat] = useState("");
   const [isParent, setIsParent] = useState<boolean>(false);

   const [tagExpertiseParentState, setTagExpertiseParentState] = useState<TagExpertiseTypeModel[]>(
      []
   );

   const [tagExpertiseTypeState, setTagExpertiseTypeState] = useState<TagExpertiseTypeModel[]>([]);

   const professionalTypeState = useSelector(
      (state: RootState) => state.tagExpertise.professionalList ?? []
   );
   const vendorTypeState = useSelector((state: RootState) => state.tagExpertise.vendorList ?? []);

   // get data tag expertise parent for select option value
   useEffect(() => {
      if (professionalTypeState.length !== 0 || vendorTypeState.length !== 0) {
         const filteredTagExpertiseProfessional = professionalTypeState.filter(
            (item) => item.parentId === "00000000-0000-0000-0000-000000000000"
         );

         const filteredTagExpertiseVendor = vendorTypeState.filter(
            (item) => item.parentId === "00000000-0000-0000-0000-000000000000"
         );
         console.log(filteredTagExpertiseProfessional);
         console.log(filteredTagExpertiseVendor);

         setTagExpertiseParentState([
            ...filteredTagExpertiseProfessional,
            ...filteredTagExpertiseVendor,
         ]);
      }
   }, [professionalTypeState, vendorTypeState]);

   // Form field change handler

   // file icon change
   //  const onIconFileChange = (event: any) => {
   //     setIconFile("");
   //     setFileFormat("");
   //     if (event.target.files[0] !== undefined) {
   //        setFileFormat(event.target.files[0].type.split("/")[1]);
   //        convertBase64(event.target.files[0], (result: any) => {
   //           setIconFile(result);
   //        });
   //     } else {
   //        console.log("error");
   //     }
   //  };

   const handleAcceptedIconFiles = (files: any[]) => {
      setFileFormat(files[0].type.split("/")[1]);
      convertBase64(files[0], (result: any) => {
         setIconFile(result);
      });
   };

   console.log(iconFile);

   // tag expertise name change
   const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
   };

   // tag expertise description change
   const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(e.target.value);
   };

   const getExpertiseByType = (type: TagExpertiseTypeEnum, id: string) => {
      if (type === TagExpertiseTypeEnum.Professional) {
         const filteredTagExpertiseProfessional = professionalTypeState.filter(
            (item) => item.parentId === id
         );
         setTagExpertiseTypeState(filteredTagExpertiseProfessional);
      } else if (type === TagExpertiseTypeEnum.Vendor) {
         const filteredTagExpertiseVendor = vendorTypeState.filter((item) => item.parentId === id);
         setTagExpertiseTypeState(filteredTagExpertiseVendor);
      }
   };

   // tag expertise type change
   const onTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
      setTagExpertiseTypeState([]);
      let onType = e.target.value;
      setType(onType);
      getExpertiseByType(parseInt(onType.split("&")[0]), onType.split("&")[1]);
   };

   // from paret of change
   const onParentChange = (e: ChangeEvent<HTMLSelectElement>) => {
      setParent(e.target.value);
   };

   // button onClick submit handler
   const onSubmitHandler = (e: {
      currentTarget: any;
      preventDefault: () => void;
      stopPropagation: () => void;
   }) => {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
         e.preventDefault();
         e.stopPropagation();
      } else {
         e.preventDefault();
         if (iconFile === "" && !isEdit) {
            alert("Mohon upload file terlebih dahulu");
         } else {
            props.onSubmit(
               data.id,
               name,
               description,
               type,
               parent,
               iconFile,
               fileFormat,
               data.iconUrl
            );
            setName("");
            setDescription("");
            setType("Personal");
            setParent("none");
            // history.replace("/master/tag-expertise/list");
         }
      }
      setValidated(true);
   };

   const onCancelHandler = () => {
      history.push("/master/tag-expertise/list");
   };

   useEffect(() => {
      if (checkObjectIsNotEmpty(data)) {
         setName(data.name);
         setDescription(data.description);

         if (data.type === TagExpertiseTypeEnum.Professional) {
            let temp = professionalTypeState.find((item) => item.id === data.parentId);
            console.log(temp);
            setType(data.type + "&" + temp?.parentId);
            getExpertiseByType(data.type, temp?.parentId ?? "");
            setParent(temp?.id ?? "none");
            if (temp?.parentId === "00000000-0000-0000-0000-000000000000") {
               setIsParent(true);
            } else {
               setIsParent(false);
            }
         } else if (data.type === TagExpertiseTypeEnum.Vendor) {
            let temp = vendorTypeState.find((item) => item.id === data.parentId);
            setType(data.type + "&" + temp?.parentId);
            getExpertiseByType(data.type, temp?.parentId ?? "");
            setParent(temp?.id ?? "none");
            if (temp?.parentId === "00000000-0000-0000-0000-000000000000") {
               setIsParent(true);
            } else {
               setIsParent(false);
            }
         }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data, professionalTypeState]);

   const onDelete = (id: string) => {
      dispatch(deleteExpertiseType(id));
      history.push("/master/tag-expertise/list");
   };

   return (
      <Form noValidate validated={validated} onSubmit={onSubmitHandler}>
         <Form.Group className="mb-4">
            {/* <Image src={iconFile} fluid />
            <Form.File label="File Icon" className="text-truncate" onChange={onIconFileChange} /> */}
            <Form.Label>Ikon</Form.Label>

            <Dropzone onDrop={(acceptedFiles) => handleAcceptedIconFiles(acceptedFiles)}>
               {({ getRootProps, getInputProps }) => (
                  <div className="dropzone file-upload-frame w-100">
                     <div className="dz-message needsclick" {...getRootProps()}>
                        <input {...getInputProps()} />

                        <div className="text-center file-upload-label">
                           <div>
                              {(iconFile !== "" || isEdit) && (
                                 <>
                                    <img
                                       src={
                                          isEdit
                                             ? iconFile === ""
                                                ? getMediaEndpoint(data.iconUrl)
                                                : iconFile
                                             : iconFile
                                       }
                                       alt="expertise-icon"
                                       className="file-upload-image mb-3"
                                    />
                                    <br />
                                 </>
                              )}
                              {iconFile === "" && !isEdit && (
                                 <>
                                    <FontAwesomeIcon
                                       icon={faUpload}
                                       width="48"
                                       className="file-upload-icon mb-3"
                                    />
                                    <br />
                                 </>
                              )}
                              <span>Upload File</span>
                           </div>
                        </div>
                     </div>
                  </div>
               )}
            </Dropzone>
         </Form.Group>
         <Form.Group className="mb-4">
            <Form.Label>Nama Expertise</Form.Label>
            <Form.Control
               type="text"
               placeholder="Masukkan Nama Expertise"
               onChange={onNameChange}
               value={name}
               required
            />
         </Form.Group>
         <Form.Group className="mb-4">
            <Form.Label>Deskripsi Expertise</Form.Label>
            <Form.Control
               as="textarea"
               rows={3}
               placeholder="Isi detail tag expertise (contoh : Kegiatan outdoor foto saat sunset)"
               onChange={onDescriptionChange}
               value={description}
               required
            />
         </Form.Group>
         <Form.Group className="mb-4">
            <Form.Label>Tipe Expertise</Form.Label>
            <Form.Control
               as="select"
               value={type}
               onChange={onTypeChange}
               required
               disabled={isParent}>
               <option value="none">-- Pilih Tipe Expertise --</option>
               {tagExpertiseParentState.map((item) => {
                  return <option value={`${item.type}&${item.id}`}>{item.name}</option>;
               })}
            </Form.Control>
         </Form.Group>
         <Form.Group className="mb-4">
            <Form.Label>Induk Dari</Form.Label>
            <Form.Control
               as="select"
               value={parent}
               onChange={onParentChange}
               required
               disabled={isParent}>
               <option value="none">-- Jadikan Sebagai Induk --</option>
               {tagExpertiseTypeState.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
               })}
            </Form.Control>
         </Form.Group>
         <div className="text-center mt-5">
            <button
               className="btn button-outline-primary mr-2"
               type="reset"
               onClick={onCancelHandler}>
               BATALKAN
            </button>
            <button
               className="btn button-danger mr-2"
               type="button"
               onClick={() => {
                  if (window.confirm("Apakah kamu yakin akan mengarsipkan item ini?"))
                     onDelete(data.id);
               }}>
               ARSIPKAN
            </button>
            <button className="btn button-primary" type="submit">
               {isEdit ? "EDIT EXPERTISE" : "TAMBAH EXPERTISE"}
            </button>
         </div>
      </Form>
   );
};

export interface TagExpertiseTypeFormProps {
   onSubmit: (
      id: string,
      name: string,
      description: string,
      type: string,
      parent: string,
      iconFile: string,
      fileFormat: string,
      defaultIcon: string
   ) => void;
   data: TagExpertiseTypeModel;
   isEdit: boolean;
}

export default TagExpertiseTypeForm;
