import React, { useEffect, useState } from "react";
import DataTable, {TableColumn} from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../../app/store";
import DefaultSubHeader from "../../../../components/DataTable/DefaultSubHeader";
import { ElementPropModel } from "../../../../components/DataTable/models";
import { TableCustomStyles } from "../../../../components/DataTable/TableCustomStyles";
import {
  deleteRequestExpertise,
  getRequestExpertiseList,
} from "../expertiseRequest.reducer";
import {RequestExpertiseModel} from "../models";

const ExpertiseRequestTable = () => {
  const dispatch = useDispatch();

  const requestExpertise = useSelector(
    (state: RootState) => state.requestExpertise.list ?? []
  );

  useEffect(() => {
    dispatch(getRequestExpertiseList());
  }, []);

  const onDeleteClicked = (id: string) => {
    if (window.confirm("Apakah kamu yakin akan menghapus item ini?"))
      dispatch(deleteRequestExpertise(id));
  };

  // datatable components

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = requestExpertise.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const onSetterFilterText = (value: string) => {
    setFilterText(value);
  };

  const elementProps = {
    placeholder: "cari berdasarkan nama",
    buttonName: "",
    createUrl: "",
    subHeaderLine: true,
  } as ElementPropModel;

  const columns: TableColumn<RequestExpertiseModel>[] = [
    {
      name: "NO.",
      width: "60px",
      cell: (_d: any, index: number) => index + 1,
    },
    {
      name: "NAMA EXPERTISE",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "DESKRIPSI",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "AKSI",
      cell: (d: { id: any }) => (
        <>
          <Link
            to={{
              pathname: `/master/tag-expertise/create`,
            }}
          >
            <button className="btn button-primary mr-2">TAMBAH EXPERTISE</button>
          </Link>
          <button
            onClick={() => onDeleteClicked(d.id)}
            className="btn button-danger"
          >
            HAPUS
          </button>
        </>
      ),
    },
  ];

  return (
    <div>
      <>
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          noHeader
          subHeader
          subHeaderComponent={DefaultSubHeader(
            filterText,
            resetPaginationToggle,
            onSetterFilterText,
            elementProps
          )}
          customStyles={TableCustomStyles}
          persistTableHead
        />
      </>
    </div>
  );
};

export default ExpertiseRequestTable;
