import axios from "axios";
import { endpoint } from "../../../constants";

export default class DataDiriApi {
  static getUserList() {
    return axios
      .get<any>(endpoint + "/api/User/GetAll")
      .then((res) => res.data.data);
  }
  static getProfessionalSingle(userId: string) {
    return axios
      .post<any>(endpoint + "/api/Professional/GetSingleItem?userId=" + userId)
      .then((res) => res.data.data);
  }
  static getVendorSingle(userId: string) {
    return axios
      .post<any>(endpoint + "/api/Vendor/GetSingleItem?userId=" + userId)
      .then((res) => res.data.data);
  }
  static getPersonalAttachment(personalId: string) {
    return axios
      .post<any>(
        endpoint +
          "/api/PersonalAttachment/GetListByProfessional?ProfessionalId=" +
          personalId
      )
      .then((res) => res.data.data);
  }
  static getVendorAttachment(personalId: string) {
    return axios
      .post<any>(
        endpoint +
          "/api/VendorAttachment/GetListByProfessional?ProfessionalId=" +
          personalId
      )
      .then((res) => res.data.data);
  }
  static getPersonalCertificate(personalId: string) {
    return axios
      .post<any>(
        endpoint +
          "/api/PersonalCertificate/GetListByProfessional?ProfessionalId=" +
          personalId
      )
      .then((res) => res.data.data);
  }
  static getPersonalLanguage(personalId: string) {
    return axios
      .post<any>(
        endpoint +
          "/api/PersonalLanguage/GetListByProfessional?professionalId=" +
          personalId
      )
      .then((res) => res.data.data);
  }
  static getPersonalCity(personalId: string) {
    return axios
      .post<any>(
        endpoint +
          "/api/PersonalCity/GetListByProfessional?professionalId=" +
          personalId
      )
      .then((res) => res.data.data);
  }
  static getSocialMedia(personalId: string) {
    return axios
      .post<any>(
        endpoint +
          "/api/SocialMedia/GetListByProfessional?professionalId=" +
          personalId
      )
      .then((res) => res.data.data);
  }
  static verifyUser(userId: string) {
    return axios
      .post<any>(endpoint + "/api/User/AdminVerified?userId=" + userId)
      .then((res) => res.data.data);
  }
}
