import axios from "axios";
import { IssueCategoryModel, CreateIssueCategoryModel } from "./models";
import { endpoint } from "../../../constants";

export default class IssueCategoryApi {
  static createIssueCategory(issueCategory: CreateIssueCategoryModel) {
    return axios
      .post<any>(endpoint + "/api/ReportIssue/CreateCategory", issueCategory)
      .then((res) => res.data.data);
  }

  static getIssueCategoryList() {
    return axios
      .get<any>(endpoint + "/api/ReportIssue/GetListCategory")
      .then((res) => res.data.data);
  }

  static getCategorySingleItem(issueCategoryId: string) {
    return axios
      .get<any>(
        endpoint + "/api/ReportIssue/GetSingleCategory?id=" + issueCategoryId
      )
      .then((res) => res.data.data);
  }

  static updateIssueCategory(data: IssueCategoryModel) {
    return axios
      .post<any>(endpoint + "/api/ReportIssue/UpdateCategory", data)
      .then((res) => res.data.data);
  }

  static deleteIssueCategory(data: IssueCategoryModel) {
    return axios
      .post<any>(endpoint + "/api/ReportIssue/DeleteCategory", data)
      .then((res) => [{ response: res.data.data }, { id: data.id }]);
  }
}
