import axios from "axios";
import {ReplyModel, ReportIssueModel} from "./models";
import {endpoint} from "../../constants";

export default class ReportIssueApi {
    static getReportIssueList() {
        return axios.get<any>(endpoint + "/api/ReportIssue/GetList").then((res) => res.data.data);
    }

    static getReportIssueSingleItem(issueId: string) {
        return axios
            .get<any>(endpoint + "/api/ReportIssue/GetSingleItem?id=" + issueId)
            .then((res) => res.data.data);
    }

    static deleteReportIssue(issue: ReportIssueModel) {
        return axios
            .post<any>(endpoint + "/api/ReportIssue/Delete", issue)
            .then((res) => res.data.data);
    }

    static replyToEmail(reply: ReplyModel) {
        return axios
            .post<any>(endpoint + "/api/ReportIssue/ReplyIssue", reply)
            .then((res) => res.data.data);
    }

    static getAttachment(issueId: string) {
        return axios
            .post<any>(
                endpoint + "/api/ReportIssueAttachment/GetListByReportIssue?ReportIssueId=" + issueId
            )
            .then((res) => res.data.data);
    }

    static completeIssue(id: string) {
        return axios
            .post<any>(endpoint + "/api/ReportIssue/Complete", null, {
                params: {
                    id: id,
                },
            })
            .then((res) => res.data.data);
    }
}
