import React, { Fragment } from "react";
import Moment from "react-moment";
import {endpoint} from "../../../../../../../constants";
import {addDefaultProfileImage} from "../../../../../../../helpers/defaultBrokenImage";
import {GroupTextMessageModel} from "../../../../../../../@core/models/chat/models";
import {MediaPathModel} from "../../../Attachment/DetailPhotoAttachment";

const ImageMessage = ({
   profileImage = "",
   senderName = "",
   content = [],
   createdAt = "",
   ...props
}: ImageMessageProps) => {

   const onOtherMessageClick = () => {
      let _content = content.map((item) => {
         return {
            src: endpoint + item.content,
            alt: endpoint + item.content,
         };
      });
      props.onOtherMessageClick(_content);
   }

   return (
      <Fragment>
         <div className={`message_list message_in`}>
            <div className={`message_list_item message_in`}>
               <div className={`message_box`}>
                  <div>
                     <div className={`d-block mb-2 mitra_profile`}>
                        <div className={`d-inline-block mr-1 image_frame`}>
                           <img
                              className={`profile_image`}
                              src="https://placeimg.com/640/480/people"
                              onError={addDefaultProfileImage}
                              alt="profile"></img>
                        </div>
                        <span>{senderName}</span>
                     </div>
                  </div>
                  <div>
                     <div
                         className={"message_multiple_image"}
                     >
                        <div style={{ display: 'flex', flexFlow: "wrap" }}>
                           {content?.slice(0, 4).map((message, index) => (
                               <div>
                                  <div
                                      style={{
                                         flexBasis: '50%',
                                         width: '115px',
                                         height: '115px',
                                         margin: '5px',
                                         display: 'flex',
                                         justifyContent: 'center',
                                         alignItems: 'center',
                                      }}
                                  >
                                     <div style={{ position: 'relative' }}>
                                        {(content?.length > 4 && index === 3) && (
                                            <div className={"image_overlay"} onClick={onOtherMessageClick}>
                                               <span>+{content?.length - 4}</span>
                                            </div>
                                        )}
                                        <img
                                            onClick={() => props.onMessageClick(message.content)}
                                            className={`profile_image`}
                                            src={endpoint + message.content}
                                            onError={addDefaultProfileImage}
                                            alt="profile"
                                        ></img>
                                     </div>
                                  </div>
                               </div>
                           ))}
                        </div>
                     </div>
                  </div>
                  <span className={"message_time"}>
                     <Moment format="HH:mm" withTitle>
                        {createdAt}
                     </Moment>
                  </span>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export interface ImageMessageProps {
   profileImage: string;
   senderName: string;
   content: GroupTextMessageModel[] | undefined;
   createdAt: string;
   onMessageClick: (path: string) => void;
   onOtherMessageClick: (path: MediaPathModel[]) => void;
}

export default ImageMessage;
