import React, { ChangeEvent, SetStateAction, useEffect, useRef, useState } from "react";
import {Form, Row, Col} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { RootState } from "../../../../app/store";
import { getBlogCategoryList } from "../../blogCategory/blogCategory.reducer";
import { SingleBlogModel } from "../models";
import Dropzone from "react-dropzone";
import { convertBase64 } from "../../../../helpers/Base64";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import "../blog.css";
import { isBlank } from "../../../../helpers/checkEmptyValue";
import Keyword from "./Keyword";
import {OptionModel} from "../../../../@core/models/types";
import {getMarketingEventList} from "../../../marketingEvent/marketingEvent.reducer";
import {checkObjectIsNotEmpty} from "../../../../helpers/checkEmptyObject";
import {getMediaEndpoint} from "../../../../helpers/imageManipulation";
import {MyUploadAdapter} from "../../../../helpers/MyUploadAdapter";

type BlogCategoryOptionModel = {
   label: string;
   value: string;
};

type errorModel = {
   isError: boolean;
   message: string;
};

const BlogForm = ({ isEdit = false, data = {} as SingleBlogModel, ...props }: BlogFormProps) => {
   let history = useHistory();
   const dispatch = useDispatch();
   const editorRef = useRef();

   const [editorLoaded, setEditorLoaded] = useState<boolean>(false);
   const [validated, setValidated] = useState<boolean>(false);
   const [name, setName] = useState<string>("");
   const [subtitle, setSubtitle] = useState<string>("");
   const [subtitleError, setSubtitleError] = useState<errorModel>({} as errorModel);
   const [categoryError, setCategoryError] = useState<errorModel>({} as errorModel);
   const [contentError, setContentError] = useState<errorModel>({} as errorModel);
   const [thumbnailError, setThumbnailError] = useState<errorModel>({} as errorModel);
   const [content, setContent] = useState<string>("");
   const [isPublish, setIsPublish] = useState<boolean>(false);
   const [thumbnail, setThumbnail] = useState<string>("");
   const [fileFormat, setFileFormat] = useState<string>("");
   const [showAtHome, setShowAtHome] = useState<boolean>(false);
   const [selectedBlogCategory, setSelectedBlogCategory] = useState<BlogCategoryOptionModel>({} as BlogCategoryOptionModel);
   const [keywordValue, setKeywordValue] = useState<Array<string>>([]);
   // const [defaultCategoryValue, setDefaultCategoryValue] = useState<BlogCategoryOptionModel>({
   //    label: "",
   //    value: "",
   // });
   const [blogCategoryList, setBlogCategoryList] = useState<BlogCategoryOptionModel[]>([]);
   const [selectedEvent, setSelectedEvent] = useState<OptionModel[]>([])

   // const isEventLoading = useSelector((state: RootState) => state.marketingEvent.isLoading);
   // const eventOptions = useSelector((state: RootState) => state.marketingEvent.eventOptions ?? []);
   const blogCategory = useSelector((state: RootState) => state.blogCategory.list ?? []);

   const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
   };

   const onSubtitleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setSubtitle(e.target.value);
   };

   const onCancelHandler = () => {
      history.push("/master/blog/list");
   };

   const handleAcceptedBlogFiles = (files: any[]) => {
      setFileFormat(files[0].type.split("/")[1]);
      convertBase64(files[0], (result: any) => {
         setThumbnail(result);
      });
   };

   const getKeywordValue = (value: Array<string>) => {
      setKeywordValue(value);
   }

   useEffect(() => {
      if (blogCategory.length !== 0) {
         const newListOption = blogCategory.map((item: { name: string; id: string }) => {
            return {
               label: item.name,
               value: item.id,
            };
         });
         setBlogCategoryList(newListOption);
      }
   }, [blogCategory]);

   useEffect(() => {
      if (data && Object.keys(data).length !== 0 && data.constructor === Object) {
         setName(data.title);
         setContent(data.content ?? "");
         setSubtitle(data.subtitle);
         setSelectedBlogCategory({
            label: data.blogCategory[0].name,
            value: data.blogCategory[0].id,
         });
         // setDefaultCategoryValue({
         //    label: data.blogCategory[0].name,
         //    value: data.blogCategory[0].id,
         // });
         setKeywordValue(data.metaKeyword);
         const defaultEvent = data.marketingEvent?.map((item: any) => {
            return {
               label: item.name,
               value: item.id,
            };
         });
         setSelectedEvent(defaultEvent ?? []);
         setIsPublish(data.isPublished);
         setShowAtHome(data.showAtHome);
      }
   }, [data]);

   useEffect(() => {
      editorRef.current = {
         // CKEditor: require('@ckeditor/ckeditor5-react'), // depricated in v3
         CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
         Editor: require("ckeditor5-custom-build/build/ckeditor"),
      } as any;
      setEditorLoaded(true);
      dispatch(getBlogCategoryList());
      dispatch(getMarketingEventList({
         skip: 0, take: 10000
      }))
   }, []);

   const { CKEditor, Editor } = editorRef.current || ({} as any);

   const toolbarConfig = {
      extraPlugins: [ MyCustomUploadAdapterPlugin ],
   };

   function MyCustomUploadAdapterPlugin( editor: any ) {
      editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader: any ) => {
         // Configure the URL to the upload script in your back-end here!
         return new MyUploadAdapter( loader );
      };
   }

   const onContentChangeHandler = (
      _event: any,
      editor: { getData: () => SetStateAction<string> }
   ) => {
      setContent(editor.getData());
   };

   const onCategoryChangeHandler = (e: OptionModel | null) => {
      setSelectedBlogCategory(e ?? {} as BlogCategoryOptionModel);
      // setDefaultCategoryValue(data);
   };

   const onPublishChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
      e.target.checked ? setIsPublish(true) : setIsPublish(false);
   };

   const onShowAtHomeChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
      e.target.checked ? setShowAtHome(true) : setShowAtHome(false);
   };

   // const onOptionEventChangeHandler = (e: OptionModel | null) => {
   //    let newArr: OptionModel[] = [];
   //    if (e && !selectedEvent.includes(e)) {
   //       newArr = [...selectedEvent, e];
   //    } else if (e) {
   //       newArr = selectedEvent.filter((option) => option !== e);
   //    }
   //    setSelectedEvent(newArr);
   // };

   const onFormSubmit = (e: {
      currentTarget: any;
      preventDefault: () => void;
      stopPropagation: () => void;
   }) => {
      setSubtitleError({
         isError: false,
         message: "",
      });
      setContentError({
         isError: false,
         message: "",
      });
      setThumbnailError({
         isError: false,
         message: "",
      });
      setCategoryError({
         isError: false,
         message: "",
      });
      if (
         !checkObjectIsNotEmpty(selectedBlogCategory) ||
         isBlank(content) ||
         (!isEdit && isBlank(thumbnail)) ||
         subtitle.length <= 100 ||
         subtitle.length >= 150
      ) {
         e.preventDefault();
         e.stopPropagation();
         !checkObjectIsNotEmpty(selectedBlogCategory) &&
            setCategoryError({
               isError: true,
               message: "Mohon pilih kategori blog",
            });
         isBlank(content) &&
            setContentError({
               isError: true,
               message: "konten blog tidak boleh kosong",
            });
         isBlank(thumbnail) &&
            !isEdit &&
            setThumbnailError({
               isError: true,
               message: "Gambar wajib diisi",
            });
         (subtitle.length <= 100 || subtitle.length >= 150) &&
            setSubtitleError({
               isError: true,
               message:
                  "(*Untuk hasil terbaik, Panjang Subtitle minimal 100 dan maksimal 150 karakter)",
            });
         // subtitle.length >= 150 &&
         //    setSubtitleError({
         //       isError: true,
         //       message:
         //          "(*Untuk hasil terbaik, Panjang Subtitle minimal 100 dan maksimal 150 karakter)",
         //    });
      } else {
         const form = e.currentTarget;
         if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
         } else {
            e.preventDefault();
            const eventIds = selectedEvent.map(item => item.value).toString();
            props.onSubmit(
               isPublish,
               name,
               subtitle,
               selectedBlogCategory.value,
               keywordValue,
               content,
               thumbnail,
               showAtHome,
               eventIds.toString(),
               fileFormat
            );
            // history.replace("/master/blog/list");
         }
         setValidated(true);
      }
   };

   // const onEventTagCancelHandler = (option: OptionModel) => {
   //    if (selectedEvent.includes(option)) {
   //       const newArr = selectedEvent.filter((a) => a !== option);
   //       setSelectedEvent(newArr);
   //    }
   // };

   // const EventTag = () => {
   //    return (
   //        <>
   //           {selectedEvent?.map((item: any) => (
   //               <Badge pill className="grey-tag mb-2 mx-1 text-left" key={item.value}>
   //                  <div className="grey-tag-container">
   //                     <span className="grey-tag-label">{item.label}</span>
   //                     <span
   //                         className="material-icons grey-tag-close ml-1"
   //                         onClick={() => onEventTagCancelHandler(item)}
   //                     >cancel</span>
   //                  </div>
   //               </Badge>
   //           ))}
   //        </>
   //    );
   // };

   return (
      <Form noValidate validated={validated} onSubmit={onFormSubmit}>
         <Form.Group>
            <Form.Check
               type="switch"
               inline
               className="mr-5"
               id="publish-switch"
               label="Terbitkan Blog"
               checked={isPublish}
               onChange={onPublishChangeHandler}
            />
            <Form.Check
               type="switch"
               inline
               id="show-switch"
               label="Tampilkan di Beranda"
               checked={showAtHome}
               onChange={onShowAtHomeChangeHandler}
            />
         </Form.Group>
         <Form.Group className="mb-4">
            <Form.Label>Judul Blog</Form.Label>
            <Form.Control
               type="text"
               placeholder="Masukkan Judul Blog"
               onChange={onNameChange}
               defaultValue={data.title}
               value={name}
               required
            />
         </Form.Group>
         <Form.Group as={Row}>
            <Form.Label as="legend" column sm={12}>
               Sub Judul{" "}
               {subtitleError.isError && (
                  <small className="float-right text-danger">{subtitleError.message}</small>
               )}
            </Form.Label>
            <Col sm={12}>
               <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Subjudul Blog"
                  onChange={onSubtitleChange}
                  defaultValue={data.subtitle}
                  value={subtitle}
               />
               <small>Jumlah Karakter: {subtitle.length}</small>
               <small className="float-right">
                  Minimal dan Maksimal Karakter: 100 - 150 Karakter
               </small>
            </Col>
         </Form.Group>
         <Form.Group>
            <Form.Label as="legend">
               Kategori{" "}
               {categoryError.isError && (
                  <small className="float-right text-danger">{categoryError.message}</small>
               )}
            </Form.Label>
            <Select
               className="basic-single"
               classNamePrefix="select"
               placeholder="Ketik untuk mencari kategori blog"
               isSearchable={true}
               name="blogCategory"
               options={blogCategoryList}
               onChange={onCategoryChangeHandler}
               value={selectedBlogCategory}
            />
         </Form.Group>
         <Form.Group as={Row}>
            <Form.Label as="legend" column sm={12}>
               Keyword
            </Form.Label>
            <Col sm={12}>
               <Keyword getKeywordValue={getKeywordValue} value={keywordValue}/>
            </Col>
         </Form.Group>

         {/*<Form.Group className="mb-4">*/}
         {/*   <Form.Label>*/}
         {/*      Event*/}
         {/*   </Form.Label>*/}

         {/*   <div>*/}
         {/*      <Select*/}
         {/*          className="basic-single"*/}
         {/*          classNamePrefix="select"*/}
         {/*          placeholder="Ketik untuk mencari username mitra"*/}
         {/*          isSearchable={true}*/}
         {/*          name="color"*/}
         {/*          options={eventOptions}*/}
         {/*          isLoading={isEventLoading}*/}
         {/*          onChange={onOptionEventChangeHandler}*/}
         {/*          hideSelectedOptions={true}*/}
         {/*      />*/}
         {/*      <div className="mt-2"><EventTag/></div>*/}
         {/*   </div>*/}
         {/*</Form.Group>*/}

         <Form.Group className="mb-4">
            <Form.Label as="legend">
               Konten Blog{" "}
               {contentError.isError && (
                  <small className="float-right text-danger">{contentError.message}</small>
               )}
            </Form.Label>
            {editorLoaded ? (
               <CKEditor
                  editor={Editor}
                  config={toolbarConfig}
                  data={content}
                  onChange={onContentChangeHandler}
               />
            ) : (
               <div>Editor loading</div>
            )}
         </Form.Group>
         <Form.Group as={Row} className="pb-5">
            <Form.Label column sm="8">
               Thumbnail{" "}
               {thumbnailError.isError && (
                  <small className="float-right text-danger">{thumbnailError.message}</small>
               )}
            </Form.Label>

            <Col sm="8">
               <Dropzone onDrop={(acceptedFiles) => handleAcceptedBlogFiles(acceptedFiles)}>
                  {({ getRootProps, getInputProps }) => (
                     <div className="dropzone file-upload-frame w-100">
                        <div className="dz-message needsclick" {...getRootProps()}>
                           <input {...getInputProps()} />

                           <div className="text-center file-upload-label">
                              <div>
                                 {thumbnail !== "" && (
                                    <>
                                       <img src={thumbnail} className="file-upload-image mb-3" alt="thumbnail" />
                                       <br />
                                    </>
                                 )}
                                 {thumbnail === "" && (
                                    <>
                                       <FontAwesomeIcon
                                          icon={faUpload}
                                          width="48"
                                          className="file-upload-icon mb-3"
                                       />
                                       <br />
                                    </>
                                 )}
                                 <span>Upload File</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  )}
               </Dropzone>

               <div>
                  <img
                      src={getMediaEndpoint(data.thumbnailPath)}
                      alt="event"
                      className="w-100 mt-3"
                  />
               </div>
            </Col>
         </Form.Group>
         <div className="text-center mt-5">
            <button
               className="btn button-outline-primary mr-3"
               type="reset"
               onClick={onCancelHandler}>
               BATALKAN
            </button>
            <button className="btn button-primary" type="submit">
               {isEdit ? "EDIT BLOG" : "TAMBAH BLOG"}
            </button>
         </div>
      </Form>
   );
};

export interface BlogFormProps {
   onSubmit: (
      isPublish: boolean,
      name: string,
      subtitle: string,
      selectedBlogCategory: string,
      metaKeyword: Array<string>,
      content: string,
      thumbnail: string,
      showAtHome: boolean,
      eventIds: string,
      fileFormat: string
   ) => void;
   isEdit: boolean;
   data: SingleBlogModel;
}

export default BlogForm;
