import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { Link, useHistory } from "react-router-dom";
import { UserModel } from "../models";
import DataTable, {TableColumn} from "react-data-table-component";
import DefaultSubHeader from "../../../components/DataTable/DefaultSubHeader";
import { TableCustomStyles } from "../../../components/DataTable/TableCustomStyles";
import { ElementPropModel } from "../../../components/DataTable/models";
import {getUserList, inActiveAdmin, resetStatus as resetAdminStatus, setSingle} from "../dataAdmin.reducer";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiErrorResponse} from "../../../index";
import {ApiResponseStatus} from "../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../helpers/alertContent";
import {MAX_PAGING_OPTIONS} from "../../../constants";
import {UserTypeEnum} from "../../dataMitra/dataMitra/models";

const DataAdminTable = () => {
   const MySwal = withReactContent(Swal);
   const history = useHistory();
   const dispatch = useDispatch();
   const adminState = useSelector((state: RootState) => state.dataAdmin.userList ?? []);
   const rows = useSelector(
       (state: RootState) => state.dataAdmin.rows
   );
   const status = useSelector(
       (state: RootState) => state.dataAdmin.status
   );
   const error = useSelector((state: RootState) => state.dataAdmin.error ?? ({} as ApiErrorResponse<any>));

   // datatable components

   const [filterText, setFilterText] = useState("");
   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

   const filteredItems = adminState.filter(
      (item) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
   );
   const [rowsPerPage, setRowsPerPage] = useState<number>(10);
   const [timer, setTimer] = useState(setTimeout(() => {}, 1000));

   useEffect(() => {
      if (status !== undefined && status !== ApiResponseStatus.pending) {
         MySwal.fire(
             savedStatusOption(
                 status === ApiResponseStatus.success,
                 error.data?.message
             )
         ).then(() => {
            dispatch(resetAdminStatus());
            if (status === ApiResponseStatus.success) {
               dispatch(getUserList({
                  userType: UserTypeEnum.Admin,
                  registerStatus: undefined,
                  skip: 0,
                  take: rowsPerPage,
               }));
            }
         });
      }
   }, [status, error, rowsPerPage]);

   const onSetterFilterText = (value: string) => {
      let filterValue = value;
      setFilterText(filterValue);
      clearTimeout(timer);
      setTimer(
          setTimeout((value = filterValue) => {
             dispatch(getUserList({
                userType: UserTypeEnum.Admin,
                registerStatus: undefined,
                name: value === "" ? undefined : value,
                skip: 0,
                take: rowsPerPage,
             }));
          }, 1000)
      );
   };

   const elementProps = {
      placeholder: "cari berdasarkan nama",
      buttonName: "Tambah Akun",
      createUrl: "/admin/account/create",
      subHeaderLine: true,
   } as ElementPropModel;

   const onDetailClicked = (data: UserModel) => {
      dispatch(setSingle(data));
      history.push(`/admin/account/edit/${data.id}`);
   };

   const columns: TableColumn<UserModel>[] = [
      {
         name: "NO.",
         width: "60px",
         cell: (_d: any, index: number) => index + 1,
      },
      {
         name: "NAMA",
         selector: (row) => row.name,
         sortable: true,
      },
      {
         name: "E-MAIL",
         selector: (row) => row.email,
         sortable: true,
      },
      {
         name: "STATUS",
         cell: (data: UserModel) => <Fragment>{data.isActive ? "Aktif" : "Tidak Aktif"}</Fragment>,
         sortable: true,
      },
      {
         name: "AKSI",
         cell: (d: UserModel) => (
            <>
               <button className="btn button-primary mr-2" onClick={() => onDetailClicked(d)}>
                  DETAIL
               </button>

               <button
                  onClick={() => onChangeStatus(d.id, d.isActive)}
                  className={`btn ${d.isActive ? "button-danger" : "btn-success"} `}>
                  {d.isActive ? "NON AKTIFKAN" : "AKTIFKAN"}
               </button>
            </>
         ),
         width: "400px",
      },
   ];

   // end of datatable components

   useEffect(() => {
      dispatch(getUserList({
         userType: UserTypeEnum.Admin,
         registerStatus: undefined,
         skip: 0,
         take: rowsPerPage,
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const onChangeStatus = (id: string, isActive: boolean) => {
      MySwal.fire({
         text: `Apakah kamu yakin akan mengubah status akun ini?`,
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Ya',
      }).then((result) => {
         if (result.isConfirmed) {
            dispatch(
                inActiveAdmin({
                   userId: id,
                   isActive: !isActive,
                })
            );
         }
      });
   };

   const handlePerRowsChange = async (newPerPage: number, page: number) => {
      setRowsPerPage(newPerPage);
      dispatch(getUserList({
         userType: UserTypeEnum.Admin,
         registerStatus: undefined,
         skip: newPerPage * (page - 1),
         take: newPerPage,
      }));
   };

   const handlePageChange = (page: number) => {
      dispatch(getUserList({
         userType: UserTypeEnum.Admin,
         registerStatus: undefined,
         skip: rowsPerPage * (page - 1),
         take: rowsPerPage,
      }));
   };


   return (
      <>
         <DataTable
            columns={columns}
            data={adminState}
            pagination
            paginationServer
            paginationTotalRows={rows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            noHeader
            subHeader
            selectableRows
            subHeaderComponent={DefaultSubHeader(
               filterText,
               resetPaginationToggle,
               onSetterFilterText,
               elementProps
            )}
            customStyles={TableCustomStyles}
            persistTableHead
            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
         />
      </>
   );
};

export default DataAdminTable;
