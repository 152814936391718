import React, {useEffect} from 'react'
import {Col, Container, Row} from "react-bootstrap";
import OfficialPartnerForm from "../components/OfficialPartnerForm";
import {CreateOfficialPartnerModel} from "../models";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import {registerOfficialPartner, resetStatus} from "../dataMitra.reducer";
import {useHistory} from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {ApiResponseStatus} from "../../../../@core/models/apiResponseStatus/apiResponseStatus";
import {savedStatusOption} from "../../../../helpers/alertContent";
import {ApiErrorResponse} from "../../../../index";

const CreateOfficialPartnerPage = () => {
    const MySwal = withReactContent(Swal);
    let history = useHistory();
    const dispatch = useDispatch();
    const status = useSelector((state: RootState) => state.dataMitra.status);
    const error = useSelector((state: RootState) => state.dataMitra.error ?? ({} as ApiErrorResponse<any>));

    // useEffect(() => {
    //     if (status === "success") {
    //         alert("Berhasil membuat partner official");
    //         history.push("/mitra/account/personal-data/list");
    //         dispatch(resetStatus());
    //     } else if (status === "failed") {
    //         alert("Gagal membuat partner official");
    //     }
    // }, [status]);

    useEffect(() => {
        if (status !== undefined && status !== ApiResponseStatus.pending) {
            MySwal.fire(
                savedStatusOption(
                    status === ApiResponseStatus.success,
                    error.data?.message
                )
            ).then(() => {
                dispatch(resetStatus());
                if (status === ApiResponseStatus.success) {
                    history.push("/mitra/account/personal-data/list");
                }
            });
        }
    }, [status, error]);

    const onSubmit = (formData: CreateOfficialPartnerModel) => {
        dispatch(registerOfficialPartner(formData))
    }

    return (
        <Container className="content-body" fluid>
            <Row>
                <Col md={12}>
                    <h4 className="card-label">Buat Official Partner</h4>
                    <hr/>
                    <Row>
                        <Col md={8} xl={5}>
                            <OfficialPartnerForm onSubmit={onSubmit}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default CreateOfficialPartnerPage