import React, { useEffect } from "react";
import {Container, Row, Col, Badge} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../app/store";
import { getBlogSingle } from "../blog.reducer";
import { BlogModel } from "../models";
import ReactHtmlParser from "react-html-parser";
import Moment from "react-moment";
import {getMediaEndpoint} from "../../../../helpers/imageManipulation";

const BlogDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== "" || id !== undefined) {
      dispatch(getBlogSingle(id));
    }
  }, [id]);

  const singleBlog = useSelector(
    (state: RootState) => state.blog.single ?? ({} as BlogModel)
  );

  return (
    <Container className="content-body" fluid>
      <Row className="justify-content-center">
        <Col md={8}>
          <img
            src={getMediaEndpoint(singleBlog.thumbnailPath)}
            alt="blog"
            className="w-100 mt-5"
          />
          <p className="mt-3 blog-date"><span><Moment format="DD MMMM YYYY">{new Date(singleBlog.createdDate)}</Moment></span></p>
          {
            singleBlog.metaKeyword?.map((meta) => (
                <Badge
                    pill
                    className="badge-tag font-weight-normal ml-1 mr-1 mb-3 badge-sm text-capitalize"
                >
                  {meta}
                </Badge>
            ))
          }
          <h4 className="mb-3">{singleBlog.title}</h4>
          <div>{ReactHtmlParser(singleBlog.content)}</div>
        </Col>
      </Row>
    </Container>
  );
};

export default BlogDetailPage;
